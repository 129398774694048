import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import reduxReducer from "./ReduxReducer";

let finalReducers = reduxReducer;
const DEBUG = process.env.NODE_ENV === "development" ? true : false;
const logger = createLogger({});
const middlewares = [thunk, DEBUG && logger].filter(Boolean);
const reduxStore = createStore(finalReducers, applyMiddleware(...middlewares));
const store = reduxStore;

export default {
  store,
};
