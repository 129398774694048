import { Image } from "react-bootstrap";
import React from "react";
import "./EnglishCourseCard.css";
// import thumbnail from "./../../assets/images/english/thumbnail.webp";
import book from "./../../assets/images/book-open.png";
import clock from "./../../assets/images/clock.png";
import instructor from "./../../assets/images/english/instructor.png";
import { convertHours, formatRupiah, slugify } from "../../helper/functions";
import { Link } from "react-router-dom";
export default function EnglishCourseCard(props) {
  const { type, item, status } = props;
  return (
    <Link
      id="english"
      to={`${
        type === "english"
          ? `/${slugify(item?.title)}/${item?.path_id}`
          : type === "brevet"
          ? `/brevet/${slugify(item?.title)}/${item?.course_id}`
          : `/${
              item.course_type === "Pelatihan" ? "course" : "certification"
            }/${slugify(item?.title)}/${item?.course_id}`
      }`}
    >
      <div className="english-card">
        <div className="wrap">
          <div className={`thumbnail-card ${type === "other" ? "other" : ""}`}>
            <Image src={item.thumbnail ? item.thumbnail : "./../../assets/images/english/thumbnail.webp"} />
          </div>
          {type === "english" ? (
            <>
              {status ? null : (
                <div className="price-card">
                  <span className="currency">Rp</span>
                  {item?.price ? formatRupiah(item.price).substr(3) : 0}
                  <span className="month">{item?.price && "/12 bulan"}</span>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="wrap-info">
          {type === "english" && (
            <div className="d-flex">
              <div className="english-label">
                {item.category[0] ? item.category[0]?.category : "-"}
              </div>
              {status && <div className="english-label-subs">Berlangganan</div>}
            </div>
          )}
          <div className="english-title">{item?.title}</div>
          <div className="english-instructor">
            <div className="image">
              <Image src={instructor} />
            </div>
            <div className="english-info">
              <div className="name">
                {item?.instructors?.length > 0
                  ? item.instructors[0]?.name
                  : "Satukelas"}
              </div>
              <div className="level">Instruktur</div>
            </div>
          </div>
          {/* <div className={`english-desc ${type === "other" && "border-total"}`}>
            <div className="total-lesson">
              <span>
                <img alt="book" src={book} className="mr-1" />
              </span>{" "}
              {item?.total_courses ? item.total_courses : item?.lessons_count}{" "}
              Pelajaran
            </div>
            <div className="total-duration">
              <span>
                <img alt="clock" src={clock} className="mr-1" />
              </span>{" "}
              {item.total_duration ? convertHours(item.total_duration) : "-"}
            </div>
          </div> */}
        </div>
      </div>
    </Link>
  );
}
