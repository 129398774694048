export const paymentsMethod = {
  bca: [
    {
      title: "Melalui ATM BCA",
      steps: [
        "Pilih Transaksi Lainnya > Transfer > Ke Rek BCA Virtual Account",
        "Masukkan nomor Virtual Account xxxxxxx dan pilih Benar.",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, pilih Ya.",
      ],
    },
    {
      title: "Melalui Mobile Banking BCA",
      steps: [
        "Pilih m-Transfer > BCA Virtual Account",
        "Masukkan nomor Virtual Account xxxxxx dan pilih Send.",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, pilih Ya",
        "Masukkan PIN m-BCA Anda dan pilih OK.",
      ],
    },
    {
      title: "Melalui Internet Banking BCA",
      steps: [
        "Pilih Transfer Dana > Transfer ke BCA Virtual Account",
        "Centang nomor Virtual Account dan masukkan xxxxx dan pilih Lanjutkan.",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, pilih Lanjutkan",
        "Masukkan respon KeyBCA Anda dan klik Kirim.",
      ],
    },
  ],
  mandiri: [
    {
      title: "Melalui ATM Mandiri",
      steps: [
        "Pilih Bayar/Beli",
        "Pilih Lainnya > Lainnya > Multi Payment.",
        "Masukkan kode perusahaan 70012 dan pilih Benar.",
        "Masukkan nomor Virtual Account xxxxx dan pilih Benar.",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Ya.",
        "Periksa layar konfirmasi dan pilih Ya.",
      ],
    },
    {
      title: "Melalui Mobile Banking Mandiri",
      steps: [
        "Login ke mBanking anda, Pilih Bayar, kemudian pilih Multipayment.",
        "Pilih Penyedia layanan: Midtrans, dan masukkan nomor Virtual Account xxxxxxxx , kemudian pilih lanjut.",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, masukkan PIN anda dan pilih OK.",
      ],
    },
    {
      title: "Melalui Internet Banking Mandiri",
      steps: [
        "Pilih Bayar > Multi Payment.",
        "Pilih Dari Rekening: Rekening Anda dan Penyedia Jasa: Midtrans, lalu klik Lanjutkan.",
        "Masukkan nomor Virtual Account xxxxxx dan pilih Lanjutkan.",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, centang tagihan dan klik Lanjutkan.",
        "Masukkan PIN token anda dan klik Kirim.",
      ],
    },
  ],
  permata: [
    {
      title: "Melalui ATM Permata",
      steps: [
        "Pilih Transaksi Lainnya.",
        "Pilih Pembayaran > Pembayaran Lainnya.",
        "Pilih Virtual Account",
        "Masukkan kode bayar xxxx kemudian pilih Benar",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Benar.",
      ],
    },
    {
      title: "Melalui PermataMobile X",
      steps: [
        "Pilih Pembayaran Bayar Tagihan",
        "Pilih Virtual Account",
        "Masukkan kode bayar xxxx kemudian pilih OK",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Selanjutnya.",
        "Pilih nomor rekening yang akan kamu gunakan.",
        "Masukkan PIN Anda, kemudian pilih Selanjutnya.",
      ],
    },
    {
      title: "Melalui PermataNet",
      steps: [
        "Pilih Pembayaran Bayar Tagihan",
        "Pilih Virtual Account",
        "Masukkan kode bayar xxxx kemudian pilih Lanjutkan",
        "Periksa informasi tertera di layar. Pastikan Penyedia Jasa adalah Midtrans, dan Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Selanjutnya.",
        "Masukkan Kode Respon Anda, kemudian pilih Lanjutkan.",
      ],
    },
  ],
  bni: [
    {
      title: "Melalui ATM BNI",
      steps: [
        "Pilih Menu Lain > Transfer",
        "Pilih jenis rekening asal dan pilih Virtual Account Billing.",
        "Masukkan nomor Virtual Account xxxx dan pilih Benar.",
        "Tagihan yang harus dibayar akan muncul pada layar konfirmasi",
        "Periksa informasi yang tertera di layar. Pastikan Merchant adalah Midtrans, Total tagihan sudah benar, termasuk username kamu. Jika benar, pilih Ya.",
      ],
    },
    {
      title: "Melalui Mobile Banking BNI",
      steps: [
        "Pilih Transfer > Virtual Account Billing",
        "Pilih Rekening Debet > Masukkan nomor Virtual Account xxxx pada menu Input Baru",
        "Tagihan yang harus dibayar akan muncul pada layar konfirmasi.",
        "Periksa informasi dan pastikan merchant adalah Midtrans, Total tagihan sudah benar, termasuk username kamu. Jika benar, masukkan password transaksi dan pilih Lanjut.",
      ],
    },
    {
      title: "Melalui Internet Banking BNI",
      steps: [
        "Pilih Transfer > Virtual Account BIlling",
        "Masukkan nomor Virtual Account xxxx",
        "Pilih Rekening Debet dan pilih Lanjut",
        "Tagihan yang harus dibayar akan muncul pada layar konfirmasi",
        "Periksa informasi yang tertera di layar. Pastikan Merchant adalah Midtrans, Total tagihan sudah benar, termasuk username kamu.",
        "Masukkan Kode Otentikasi Token Anda lalu klik Proses.",
      ],
    },
    {
      title: "SMS Banking",
      steps: [
        "Kirim SMS “TRANSFER nomor_virtual_account jumlah_tagihan” ke 3346",
        "Balas SMS yang masuk dengan benar.",
      ],
    },
  ],
};
