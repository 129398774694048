import React from "react";
import { Button, Image } from "react-bootstrap";
import ctaImageFooter from "../../assets/images/atpi/cta/bg-cta-footer.png";

const CtaFooter = (props) => {
  const { typePage } = props;
  return (
    <div className="d-block d-lg-flex">
      <div className="col-12 col-lg-7 mb-3 d-flex justify-content-xl-center">
        <div>
          {typePage === "atpi" ? (
            <h1 className="wording__cta-footer">
              Yuk, dapatkan <span>Gelar Profesi</span> dari <span>ATPI</span> dengan mengikuti pelatihan <span>Brevet Pajak</span> di satukelas!
            </h1>
          ) : (
            <h1 className="wording__cta-footer">
              Yuk, dapatkan <b>Sertifikat Kompetensi BNSP</b> dari <b>LSP APINDO</b> dengan mengikuti pelatihannya di satukelas!
            </h1>
          )}
          <div className="mt-3">
            <Button className="btn__cta-footer">Dapatkan Sekarang</Button>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-5" style={{ position: "relative", right: "0", paddingRight: "unset" }}>
        <Image src={ctaImageFooter} width="100%" height="100%" />
      </div>
    </div>
  );
};

export default CtaFooter;
