export const USERS_DATA_LINK = "users";
export const USERS_DATA_LINK_V2 = "v2/users";
export const USERS_STUDENT_DATA_LINK = "users/student/register";
export const USERS_GET_INSTRUCTOR_LINK = "users/instructors";
export const USERS_VERIFY_TOKEN_LINK = "users/verify";
export const USERS_RESEND_VERIFICATION_LINK = "users/send_verification";
export const USERS_CHANGE_PASSWORD_LINK = "users/change_password";
export const FORGOT_PASSWORD_LINK = "users/send_email_reset";
export const RESET_PASSWORD_LINK = "users/reset_password";

//get
export const USERS_DATA = "USERS_DATA";
export const USERS_DATA_ID_SUCCESS = "USERS_DATA_ID_SUCCESS";
export const USERS_DATA_SUCCESS = "USERS_DATA_SUCCESS";
export const USERS_DATA_FAIL = "USERS_DATA_FAIL";

//post
export const USERS_DATA_POST = "USERS_DATA_POST";
export const USERS_DATA_POST_SUCCESS = "USERS_DATA_POST_SUCCESS";
export const USERS_DATA_POST_FAIL = "USERS_DATA_POST_FAIL";

//put
export const USERS_DATA_PUT = "USERS_DATA_PUT";
export const USERS_DATA_PUT_SUCCESS = "USERS_DATA_PUT_SUCCESS";
export const USERS_DATA_PUT_FAIL = "USERS_DATA_PUT_FAIL";

//get instructor
export const USERS_GET_INSTRUCTOR_DATA = "USERS_GET_INSTRUCTOR_DATA";
export const USERS_GET_INSTRUCTOR_DATA_SUCCESS =
  "USERS_GET_INSTRUCTOR_DATA_SUCCESS";
export const USERS_GET_INSTRUCTOR_DATA_FAIL = "USERS_GET_INSTRUCTOR_DATA_FAIL";

//verify token
export const USERS_VERIFY_TOKEN = "USERS_VERIFY_TOKEN";
export const USERS_VERIFY_TOKEN_SUCCESS = "USERS_VERIFY_TOKEN_SUCCESS";
export const USERS_VERIFY_TOKEN_FAIL = "USERS_VERIFY_TOKEN_FAIL";

//resend verification
export const USERS_RESEND_VERIFICATION = "USERS_RESEND_VERIFICATION";
export const USERS_RESEND_VERIFICATION_SUCCESS =
  "USERS_RESEND_VERIFICATION_SUCCESS";
export const USERS_RESEND_VERIFICATION_FAIL = "USERS_RESEND_VERIFICATION_FAIL";

//remove userdata
export const USERS_DATA_ID_REMOVE = "USERS_DATA_ID_REMOVE";

// change password
export const USERS_CHANGE_PASSWORD = "USERS_CHANGE_PASSWORD";
export const USERS_CHANGE_PASSWORD_SUCCESS = "USERS_CHANGE_PASSWORD_SUCCESS";
export const USERS_CHANGE_PASSWORD_FAIL = "USERS_CHANGE_PASSWORD_FAIL";

// forgot password
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

// verify password
export const VERIFY_PASSWORD_TOKEN = "VERIFY_PASSWORD_TOKEN";
export const VERIFY_PASSWORD_TOKEN_SUCCESS = "VERIFY_PASSWORD_TOKEN_SUCCESS";
export const VERIFY_PASSWORD_TOKEN_FAIL = "VERIFY_PASSWORD_TOKEN_FAIL";

// new password
export const NEW_PASSWORD_ACCOUNT = "NEW_PASSWORD_ACCOUNT";
export const NEW_PASSWORD_ACCOUNT_SUCCESS = "NEW_PASSWORD_ACCOUNT_SUCCESS";
export const NEW_PASSWORD_ACCOUNT_FAIL = "NEW_PASSWORD_ACCOUNT_FAIL";