import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { AuthIsLogin } from "../../helper/auth";
import "../../assets/css/MyCompany/mycompanyprofil.css";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { UserService } from "../../http/UserHttp";
import { AuthData } from "../../helper/auth";
import CourseCard from "../../components/CourseCard";

function MyCompanyProfil() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [mandatoryCourses, setMandatoryCourses] = useState(null);
  const [userData, setUserData] = useState(null);
  // const headerData = header?.headersData;

  // run only on the first render
  useEffect(() => {
    // check auth
    AuthIsLogin(history);
    // if logged in, get membership data
    if (AuthIsLogin("", true)) {
      getUserV2();
      getCourses();
    }
  }, []);

  /** hit API: GET /membership **/
  const getUserV2 = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getUserV2(AuthData().userId);
      setUserData(response);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  // get courses from API
  const getCourses = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getCourses();
      const listMandatoryCourse = response.mandatory_courses;
      setMandatoryCourses(listMandatoryCourse);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  return (
    <Container>
      {userData &&
        (!userData.company_id ? (
          <div className="not_found__data flex-column ">
            <div className="not_found__course text-center mb-3">
              Perusahaan Anda Belum Terdaftar
            </div>
            <Button href="/korporasi" className="width-fit percentage__button">
              Daftarkan Segera
            </Button>
          </div>
        ) : (
          <Row className="my-5">
            <Col md={9}>
              <div className="company-profil__container mb-4">
                <h2 className="h2 company-profil__title">
                  Wajib Untuk Diikuti
                </h2>
                <div className="row">
                  {mandatoryCourses?.length > 0
                    ? mandatoryCourses.map((value, index) => {
                        return (
                          <CourseCard hidden={true} key={index} item={value} status={''}/>
                        );
                      })
                    : "Data Belum Tersedia"}
                </div>
                {/* <div className="company-profil__mandatory-course"></div> */}
              </div>
              <div className="company-profil__container">
                <Card className="company-profil__contact-card">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2 className="h2 company-profil__title text-white">
                        Kontak Admin?
                      </h2>
                      <Button
                        href={`tel:${userData.cp_phone}`}
                        className="company-profil__membership-title__detail-white"
                      >
                        Hubungi admin
                      </Button>
                    </div>
                    <div></div>
                  </div>
                </Card>
              </div>
            </Col>
            <Col md={3}>
              <div className="company-profil__container">
                <div className="company-profil__wrapper-company">
                  <div className="company-profil__image-profil">
                    {userData.company_logo && (
                      <img
                        className="logo_company__size lazyload"
                        alt="Logo"
                        width="55"
                        height="55"
                        data-src={userData.company_logo}
                      />
                    )}
                  </div>
                  <div className="company-profil__company-name">
                    {userData.company_name}
                  </div>
                </div>
                <Card className="company-profil__membership-card">
                  <div className="company-profil__membership-title">
                    Membership
                  </div>
                  <div className="company-profil__membership-wrapper__list">
                    {!userData.membership_expired ? (
                      <div className="not-found__membership">
                        Belum ada membership yang aktif
                      </div>
                    ) : (
                      <>
                        <div className="company-profil__membership-image"></div>
                        <div className="company-profil__membership-info__wrapper">
                          <div className="company-profil__membership-title__detail">
                            {userData.membership_title}
                            <span className="company-profil__membership-badge">
                              {/* compare today with userData.membership_expired */}
                              {moment(userData.membership_expired).isAfter(
                                moment()
                              )
                                ? "Aktif"
                                : "Kedaluwarsa"}
                            </span>
                          </div>
                          <div className="company-profil__membership-subtitle__detail">
                            {moment(userData.membership_expired).format(
                              "DD MMMM YYYY"
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
                <div className="company-profil__wrapper-leaderboard">
                  <div className="company-profil__wrapper-title">
                    <div className="company-profil__container-leaderboard">
                      <div className="company-profil__leaderboard-title">
                        Leaderboard
                      </div>
                    </div>
                  </div>
                  <Card className="company-profil__leaderboard-container">
                    Data Belum Tersedia
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        ))}
    </Container>
  );
}

export default MyCompanyProfil;
