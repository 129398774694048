import React from "react";
import { Col, Container } from "react-bootstrap";

const PersiapanSertifikasiAccordion = (props) => {
  return (
    <section className="my-5">
      <Container className="ml-xl-5">
        <div className="pl-0 pr-0 col-xl-8">
          <h4 className="fw-700">{props.title}</h4>
          <Col sm={12} className="pl-0 pr-0">
            {props.children}
          </Col>
        </div>
      </Container>
    </section>
  );
};
export default PersiapanSertifikasiAccordion;
