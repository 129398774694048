import React, { useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AuthIsLogin } from "../../helper/auth";
import { getNotificationsDataThunk } from "../../redux/thunks/NotificationThunks";
import "../../assets/css/Personal/notification.css";

function NotificationPage() {
  const history = useHistory();

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    dispatch(getNotificationsDataThunk());
  }, [])

  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const notificationsData = notifications.notificationsData;
  const notificationDataId = notifications.notificationDataId;

  const handleRedirect = (url, id) => {
    const redirect = url.split("/")[5];
    dispatch(getNotificationsDataThunk({ param: `/${id}` }));
    if (notificationDataId !== "error") {
      history.push(`/webinar/webinar-course/${redirect}`);
    }
  };

  return (
    <Container className="dashboard_page__container">
      <Row>
        <Col lg={8}>
          <div>
            <h2 className="h2 dashboard__section_title mb-1">Notifikasi</h2>
            <div className="mb-3">
              {notificationsData?.items?.length} Notifikasi
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="wrapper-notif">
            <div className="related_material__title"></div>
            {notificationsData?.items?.map((item, index) => {
              return (
                <Card key={index} className="mb-4 notification_card">
                  <div className="wrapper-padding">
                    <div className="notification_content__main">
                      <div className="notification_content">
                        <div
                          className={`wrapper-subtitle mb-3 ${item.is_read === 0 ? null : "text-muted"
                            } `}
                        >
                          {item.message}
                        </div>
                        <Button
                          className="mb-2 d-none d-sm-block"
                          onClick={() =>
                            handleRedirect(
                              item.launch_url,
                              item.notification_id
                            )
                          }
                        >
                          <div>Lihat Selengkapnya</div>
                        </Button>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <div>
                          <Button
                            className="mb-2 d-block d-sm-none detail_notification"
                            onClick={() =>
                              handleRedirect(
                                item.launch_url,
                                item.notification_id
                              )
                            }
                          >
                            Selengkapnya
                          </Button>
                        </div>
                        <div className="notification_receipt mt-2 mt-md-0">
                          {moment(item.created_at, "YYYY-MM-DD HH:mm:ss")
                            .startOf("second")
                            .fromNow()}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotificationPage;
