import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/LearningPath/learningpathsingle.css";
import CourseCard from "../../components/CourseCard";
import { useDispatch } from "react-redux";
import { slugify } from "../../helper/functions";
import { brevet } from "../../helper/constant";
import { brevetService } from "../../http/BrevetHttp";

function BrevetLanding() {
  const history = useHistory();
  const addressURL = history.location.pathname.split("/");
  const detailAddress = addressURL[2].split("-");
  const [courseDetail, setCourseDetail] = useState();
  const [loaded, setLoaded] = useState(false);

  const [coursesDataTryOut, setCoursesDataTryOut] = useState();
  const [coursesExplain, setCoursesExplain] = useState();
  const [coursesTutorial, setCoursesTutorial] = useState();
  const dispatch = useDispatch();
  /**
   * function get data course by url param
   */
  useEffect(() => {
    if (addressURL[2] === "brevet-c") {
      getCourseDetail(brevet[1]);
    } else {
      getCourseDetail(brevet[0]);
    }
  }, []);

  useEffect(() => {
    getTryOutDetail("try%20out", "try%20out", detailAddress[1]);
    getExplainDetail("pembahasan%20soal", "soal", detailAddress[1]);
    getTutorialDetail("tutorial", "tutorial", detailAddress[1]);
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, []);

  /**
   * function get data course by course id
   */
  const getCourseDetail = async (course_id) => {
    try {
      const response = await brevetService.getCourseDetail(course_id);
      if (response !== undefined) {
        setCourseDetail(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * function get data course by course type, discussion topic, and scheme
   */
  const getTryOutDetail = async (type, discussion, value) => {
    try {
      const response = await brevetService.getCourseBrevetList(type, discussion, value);
      if (response.items.length > 0) {
        let tempStatus = response.items;
        setCoursesDataTryOut(tempStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * function get data course by course type, discussion topic, and scheme
   */
  const getExplainDetail = async (type, discussion, value) => {
    try {
      const response = await brevetService.getCourseBrevetList(type, discussion, value);
      if (response.items.length > 0) {
        let tempStatus = response.items;
        setCoursesExplain(tempStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * function get data course by course type, discussion topic, and scheme
   */
  const getTutorialDetail = async (type, discussion, value) => {
    try {
      const response = await brevetService.getCourseBrevetList(type, discussion, value);
      if (response.items.length > 0) {
        let tempStatus = response.items;
        setCoursesTutorial(tempStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * function show data with splide.js
   */
  function renderSplideExplain() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-2 mb-2">
          <div id="explain" className="category-card overflow-visible ">
            <div>
              <Splide
                id="custom-height"
                options={{
                  rewind: true,
                  perPage: 3,
                  perMove: 1,
                  gap: "1rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}>
                {coursesExplain?.length > 0
                  ? coursesExplain?.map((item, i) => {
                      return (
                        <SplideSlide key={i}>
                          <CourseCard hidden={true} disable={true} key={i} item={item} />
                        </SplideSlide>
                      );
                    })
                  : null}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  /**
   * function show data with splide.js
   */
  function renderSplideTryOut() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-2 mb-2">
          <div id="tryout" className="category-card overflow-visible ">
            <div>
              <Splide
                id="custom-height"
                options={{
                  rewind: true,
                  perPage: 3,
                  perMove: 1,
                  gap: "1rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}>
                {coursesDataTryOut?.length > 0
                  ? coursesDataTryOut?.map((item, i) => {
                      return (
                        <SplideSlide key={i}>
                          <CourseCard hidden={true} disable={true} key={i} item={item} />
                        </SplideSlide>
                      );
                    })
                  : null}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  /**
   * function show data with splide.js
   */
  function renderSplideTutorial() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-2 mb-2">
          <div id="tutorial" className="category-card overflow-visible ">
            <div>
              <Splide
                id="custom-height"
                options={{
                  rewind: true,
                  perPage: 3,
                  perMove: 1,
                  gap: "1rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}>
                {coursesTutorial?.length > 0
                  ? coursesTutorial?.map((item, i) => {
                      return (
                        <SplideSlide key={i}>
                          <CourseCard hidden={true} disable={true} key={i} item={item} />
                        </SplideSlide>
                      );
                    })
                  : null}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
  /**
   * function show data with splide.js
   */
  function renderSplidePrepare() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-2 mb-2">
          <div id="tutorial" className="category-card overflow-visible ">
            <div>
              <Splide
                id="custom-height"
                options={{
                  rewind: true,
                  perPage: 3,
                  perMove: 1,
                  gap: "1rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}>
                {courseDetail?.prerequisites?.length > 0
                  ? courseDetail?.prerequisites?.map((item, i) => {
                      return (
                        <SplideSlide key={i}>
                          <CourseCard hidden={true} mandatory={true} disable={true} key={i} item={item} />
                        </SplideSlide>
                      );
                    })
                  : null}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Container className="course_front__container dashboard_page__container">
      <Row className="my-4">
        <Col md={9} className="mb-3">
          <Card className="card_wrapper">
            <div className="card_title__wrap">
              <h1 className="h1 card_title__title_main mb-4">{courseDetail?.title}</h1>
              <div className="card_title__description mb-3">
                {detailAddress[1] === "ab" ? (
                  <>Sertifikasi ini merupakan sertifikasi yang membahas kompetensi perpajakan dasar hingga menengah yang terkait dengan ketentuan perpajakan penghasilan orang pribadi hingga ketentuan perpajakan badan/perusahaan.</>
                ) : (
                  <>
                    Sertifikasi ini merupakan sertifikasi yang membahas kompetensi perpajakan menengah hingga lanjut. Selain membahas mengenai PPh Orang Pribadi dan PPh Badan, pada sertifikasi ini juga membahas tentang Pajak Internasional
                    Bank dan Akuntansi Pajak.
                  </>
                )}
              </div>
              <div className="mt-5">
                <div className="text-right">
                  <a className="button-start__course mt-2 mb-0 px-5" href={`/course/${slugify(courseDetail?.title)}/${courseDetail?.course_id}`}>
                    Mulai!
                  </a>
                </div>
              </div>
            </div>
          </Card>
          <div className="assesment_skill__wrapper">
            <div className="assesment_skill__wrapper">
              <h2 className="h2 assesment_skill__pengantar">Pelatihan Persiapan Sertifikasi</h2>

              <>{loaded ? <>{renderSplidePrepare()}</> : null}</>
            </div>
            <h2 className="h2 assesment_skill__pengantar">Try Out</h2>

            <>{loaded ? <>{renderSplideTryOut()}</> : null}</>
          </div>
          <div className="assesment_skill__wrapper">
            <h2 className="h2 assesment_skill__pengantar">Pembahasan Soal</h2>
            <>{loaded ? <>{renderSplideExplain()}</> : null}</>
          </div>
          <div className="assesment_skill__wrapper">
            <h2 className="h2 assesment_skill__pengantar">Tutorial Pajak</h2>

            <>{loaded ? <>{renderSplideTutorial()}</> : null}</>
          </div>

          {/* <div className="assesment_skill__wrapper">
            <Card className="card_wrapper ">
              <div className="d-flex justify-content-between">
                <h2 className="h2 assesment_skill__pengantar text-white">
                  Ujian
                </h2>
                
              </div>
            </Card>
          </div> */}
        </Col>
        <Col md={3} className="mb-3">
          <h2 className="h2 assesment_skill__pengantar">Terkait Pelatihan Ini</h2>
          <div>
            {courseDetail?.related_material?.map((item, id) => {
              return (
                <Link to={`/course/${slugify(item.course)}/${item.course_id}`} key={id} className="related_material__item_wrapper">
                  {" "}
                  {item?.thumbnail === "" ? (
                    <div className="related_material__img_wrapper"></div>
                  ) : (
                    <div className="related_material__img_wrapper">
                      <img className="related_material__thumbnail" src={item?.thumbnail} />
                    </div>
                  )}
                  <div className="recommend_wrap">
                    <div>
                      <div className="related_material__item_title">{item?.course}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default BrevetLanding;
