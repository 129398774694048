import { Col, Image, Row } from "react-bootstrap";
import country from "./../../assets/images/english/country.png";
import React from "react";

export default function PromoBottom() {
  return (
    <Row className="promo-wrap">
      <Col lg={6}>
        <div className="title-promo">
          <span>Ini Title</span> Butuh Brief Wording Untuk Melengkapi Section
          Ini
        </div>
        <div className="dev-promo">
          Ini deskripsi <span>butuh brief</span> wording untku melengkapi
          section ini
        </div>
        <div className="src-promo">*Sumber: www.statista.com</div>
      </Col>
      <Col lg={6}>
        <Image src={country} alt="country" />
      </Col>
    </Row>
  );
}
