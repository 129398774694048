import {
  ASSETS_DATA,
  ASSETS_DATA_SUCCESS,
  ASSETS_DATA_FAIL,
  ASSETS_DATA_POST,
  ASSETS_DATA_POST_SUCCESS,
  ASSETS_DATA_POST_FAIL,
  //remove
  REMOVE_ASSETS_DATA_POST,
} from "../config/AssetsConfig";

//get
export const getAssetsData = (value) => ({
  type: ASSETS_DATA,
  value,
});
export const getAssetsDataSuccess = (value) => ({
  type: ASSETS_DATA_SUCCESS,
  value,
});
export const getAssetsDataFail = (value) => ({
  type: ASSETS_DATA_FAIL,
  value,
});

//post
export const postAssetsData = (value) => ({
  type: ASSETS_DATA_POST,
  value,
});
export const postAssetsDataSuccess = (value) => ({
  type: ASSETS_DATA_POST_SUCCESS,
  value,
});
export const postAssetsDataFail = (value) => ({
  type: ASSETS_DATA_POST_FAIL,
  value,
});

export const removeAssetsDataPost = (value) => ({
  type: REMOVE_ASSETS_DATA_POST,
  value,
});
