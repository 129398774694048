export const QUIZ_DATA_LINK = "quizzes";
//quiz get
export const QUIZ_DATA = "QUIZ_DATA";
export const QUIZ_DATA_ID_SUCCESS = "QUIZ_DATA_SUCCESS";
export const QUIZ_DATA_FAIL = "QUIZ_DATA_FAIL";

//quiz start
export const QUIZ_START = "QUIZ_START";
export const QUIZ_START_SUCCESS = "QUIZ_START_SUCCESS";
export const QUIZ_START_FAIL = "QUIZ_START_FAIL";

//quiz attempt
export const QUIZ_ATTEMPT = "QUIZ_ATTEMPT";
export const QUIZ_ATTEMPT_SUCCESS = "QUIZ_ATTEMPT_SUCCESS";
export const QUIZ_ATTEMPT_FAIL = "QUIZ_ATTEMPT_FAIL";

//quiz score
export const QUIZ_SCORE = "QUIZ_SCORE";
export const QUIZ_SCORE_SUCCESS = "QUIZ_SCORE_SUCCESS";
export const QUIZ_SCORE_FAIL = "QUIZ_SCORE_FAIL";

//quiz remove

export const QUIZ_REMOVE_ATTEMPT_SCORE = "QUIZ_REMOVE_ATTEMPT_SCORE";
