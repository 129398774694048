import { PromiseService } from "../../services/PromiseService";
import { ASSETS_DATA_LINK } from "../config/AssetsConfig";
import {
  //get
  getAssetsData,
  getAssetsDataSuccess,
  getAssetsDataFail,
  //post
  postAssetsData,
  postAssetsDataSuccess,
  postAssetsDataFail,
} from "../actions/AssetsActions";

export const getAssetsDataThunk = (data) => async (dispatch) => {
  dispatch(getAssetsData());
  try {
    const response = await PromiseService.getData(`${ASSETS_DATA_LINK}${data}`);
    if (response.status === 200 && response.data) {
      dispatch(getAssetsDataSuccess(response.data));
    } else {
      dispatch(getAssetsDataFail(response));
    }
  } catch (error) {
    dispatch(getAssetsDataFail(error));
  }
};

export const postAssetsDataThunk = (data) => async (dispatch) => {
  dispatch(postAssetsData());
  const body = data.body;
  const url = data.url;
  try {
    const response = await PromiseService.postFormData(
      `${ASSETS_DATA_LINK}${url}`,
      body
    );
    if (response.status === 200 && response.data) {
      dispatch(postAssetsDataSuccess(response.data));
    } else {
      dispatch(postAssetsDataFail(response));
    }
  } catch (error) {
    dispatch(postAssetsDataFail(error));
  }
};
