import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../../../assets/css/Courses/CourseDetailTitle.css";
import courselogo from "./../../../assets/images/course_logo.png";
function CourseTitle({
  id,
  item,
  titles,
}) {
  let tags = "";
  if (item?.learning_paths) {
    for (let i = 0; i < item.learning_paths.length; i++) {
      if (i === 0) tags = item.learning_paths[i].title;
      else tags = tags + ", " + item.learning_paths[i].title;
    }
  }
  return (
    <>
      {id ? (
        <Container>
          <Row className="align-items-baseline">
            <Col md={12}>
              <div className="d-flex mt-4 justify-content-between align-items-center">
                <h2 className="h2  course_front_big__title_detail">{titles}</h2>
                <div>
                  <img alt="course logo" src={courselogo} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}
    </>
  );
}
export default CourseTitle;
