import React, { useEffect, useState } from "react";
import { slugify } from "../../helper/functions";
import { Link } from "react-router-dom";
import iconRight from "../../assets/images/icon-right.png";
import iconRightHover from "../../assets/images/icon-right-hover.png";
import { env } from "../../helper/constant";
export default function CertificateMenu(props) {
  const {
    id,
    handleMouseHover,
    handleMouse,
    toggleSubMenu,
    hoverIcon,
    brevetNav,
  } = props;
  const path_chro = env === "development" ? "co68651931" : "co78129874";

  return (
    <div
      id="katalog"
      className={`position-inherit main header_left__item header_left__item-sertifikasi position-relative ${
        id === "katalog" ? "interaction-active" : id === "search" ? "" : ""
      } dropdown mx-1`}
      onMouseOver={handleMouse}
      // onClick={() => handleClickNav(1)}
      // to="#"
    >
      Sertifikasi
      <ul
        id="mega"
        className={`mega-menu__container pt-3`}
        style={{ top: "65px" }}
      >
        <li
          className="list_menu_nav border-bottom p-3 menu-hover"
          onMouseEnter={() => handleMouseHover("brevet")}
          onMouseLeave={handleMouseHover} // onClick={() => toggleSubMenu(1)}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <Link to="#">Brevet Pajak</Link>
            <span>
              <img
                src={hoverIcon === "brevet" ? iconRightHover : iconRight}
                alt="icon-right"
              />
            </span>
          </div>
          <ul
            id="sub_menu"
            className={`submenu pt-3                                `}
          >
            {brevetNav &&
              brevetNav?.items?.map((item, index) => (
                <li className="border-bottom p-3" key={index}>
                  <Link
                    to={`/brevet/${slugify(item.title)}/${item.course_id}`}
                    className="p-0"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
        <li
          className="list_menu_nav border-bottom p-3 menu-hover"
          onClick={() => toggleSubMenu(3)}
          onMouseEnter={() => handleMouseHover("bnsp")}
          onMouseLeave={handleMouseHover}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <p>BNSP - Manajemen SDM</p>
            <span>
              <img
                src={hoverIcon === "bnsp" ? iconRightHover : iconRight}
                alt="icon-right"
              />
            </span>
          </div>
          <ul id="sub_menu" className={`submenu p-3`} style={{ top: "60%" }}>
            <li className="border-bottom">
              <Link
                to={`/brevet/${slugify(
                  "Manajemen Sumber Daya Manusia: Level Staf"
                )}/${path_chro}`}
              >
                BNSP MSDM Lv. Staf
              </Link>
            </li>
            <li className="border-bottom d-flex justify-content-between align-items-center">
              <Link to="/">BNSP MSDM Lv. Supervisor</Link>
              <span className=" blink_text">soon !</span>
            </li>
            <li className="border-bottom d-flex justify-content-between align-items-center">
              <Link to="/">BNSP MSDM Lv. Manager</Link>
              <span className=" blink_text">soon !</span>
            </li>
          </ul>
        </li>
        <li
          className="list_menu_nav border-bottom p-3 menu-hover"
          onClick={() => toggleSubMenu(4)}
          onMouseEnter={() => handleMouseHover("magangmandiri")}
          onMouseLeave={handleMouseHover}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <p>Magang Mandiri</p>
            <span>
              <img
                src={hoverIcon === "magangmandiri" ? iconRightHover : iconRight}
                alt="icon-right"
              />
            </span>
          </div>
          <ul id="sub_menu" className={`submenu p-3`} style={{ top: "60%" }}>
            <li>
              <Link
                to={`/magang/${slugify("Magang Mandiri Digital Marketing")}/${
                  env === "production" ? "co81117055" : "co19430941"
                }`}
              >
                Digital Marketing
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
