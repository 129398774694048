import { PromiseService } from "../../services/PromiseService";
import { INBOX_DATA_LINK } from "../config/InboxConfig";
import {
  //put
  putInboxData,
  putInboxDataSuccess,
  putInboxDataFail,
  //post
  postInboxData,
  postInboxDataSuccess,
  postInboxDataFail,
  //delete
  deleteInboxData,
  deleteInboxDataSuccess,
  deleteInboxDataFail,
  //get
  getInboxData,
  getInboxDataSuccess,
  getInboxDataIdSuccess,
  getInboxDataFail,
  //fail\
} from "../actions/InboxActions";

export const postInboxDataThunk = (data) => async (dispatch) => {
  dispatch(postInboxData());
  const body = data;
  try {
    const response = await PromiseService.postData(INBOX_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postInboxDataSuccess(response.data));
    } else {
      dispatch(postInboxDataFail(response));
    }
  } catch (error) {
    dispatch(postInboxDataFail(error));
  }
};
export const deleteInboxDataThunk = (data) => async (dispatch) => {
  dispatch(deleteInboxData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${INBOX_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteInboxDataSuccess(response.data));
    } else {
      dispatch(deleteInboxDataFail(response));
    }
  } catch (error) {
    dispatch(deleteInboxDataFail(error));
  }
};

export const putInboxDataThunk = (data) => async (dispatch) => {
  dispatch(putInboxData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${INBOX_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putInboxDataSuccess(response.data));
    } else {
      dispatch(putInboxDataFail(response));
    }
  } catch (error) {
    dispatch(putInboxDataFail(error));
  }
};

export const getInboxDataThunk = (data) => async (dispatch) => {
  dispatch(getInboxData());
  if (data) {
    try {
      const response = await PromiseService.getData(
        `${INBOX_DATA_LINK}/${data}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getInboxDataIdSuccess(response.data));
      } else {
        dispatch(getInboxDataFail(response));
      }
    } catch (error) {
      dispatch(getInboxDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(INBOX_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getInboxDataSuccess(response.data));
      } else {
        dispatch(getInboxDataFail(response));
      }
    } catch (error) {
      dispatch(getInboxDataFail(error));
    }
  }
};
