import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import thumbnaillp from "./../assets/images/thumbnaillp.png";
function LearningPathSection() {
  return (
    <div className="wrapper-learning__path ">
      <Container>
        <Row>
          <Col md={9}>
            <div className="main-header__learning-path">Learning Path</div>
            <div className="sub-header__learning-path">
              Disini kamu akan belajar mengenai hal-hal bidang yang dapat
              membantu Anda dalam bekerja dan memberikan dampak positif terhadap
              bisnis perusahaan
            </div>
          </Col>
          {[...Array(6)].map((value, index) => {
            return (
              <Col md={4} key={index}>
                <div className="container-kearning__path">
                  <div>
                    <img alt="thumbnail" className="image-learning__path" src={thumbnaillp} />
                  </div>
                  <div className="info-learning__path">
                    <div className="header-learning__path">Learning Path</div>
                    <div className="title-learning__path">
                      Expert Pajak PPh 21
                    </div>
                    <div className="desc-learning__path">
                      Jadilah seorang expert dalam perpajakan, ikuti learning
                      path ini dengan mengikuti beberapa kelas.
                    </div>
                    <div className="link-learning__path">
                      Lihat detail pembelajaran
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
export default LearningPathSection;
