import React, { useEffect, useState } from "react";
// import Aside from "./Aside";
import Main from "./Main";
import StickyNav from "./StickyNav";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesBrevetDataThunk } from "../../redux/thunks/CoursesThunks";

function LayoutNoSidebar({ setLocale, InnerComponent, isError, noFooter }) {
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);

  // useEffect(() => {
  //   if (courses?.courseBrevetData === null)
  //     dispatch(
  //       getCoursesBrevetDataThunk({
  //         param: `?keyword=brevet&course_type=sertifikasi`,
  //       })
  //     );
  // }, [courses.courseBrevetData]);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? "ar" : "en");
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
      <Main
        image={image}
        toggled={toggled}
        collapsed={collapsed}
        rtl={rtl}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleRtlChange={handleRtlChange}
        handleImageChange={handleImageChange}
        InnerComponent={InnerComponent}
        isError={isError}
        noFooter={noFooter}
      />
      <StickyNav />
    </div>
  );
}

export default LayoutNoSidebar;
