import React, { useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "../../assets/css/Account/index.css";
import { SkValidate } from "../../components/common/SkValidate";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory, useLocation } from "react-router";
import { loadingFinish, loadingStart } from "../../redux/reducers/CommonReducers";
import { AuthService } from "../../http/AuthHttp";
const MySwal = withReactContent(Swal);

function NewPassword() {
  const location = useLocation();
  const parameter = new URLSearchParams(location.search);
  const token = parameter.get("token");
  const [data, setData] = useState({
    email: "",
    password: "",
    password_re: "",
    token: "",
  });
  const [show, setShow] = useState(true);
  const [showRe, setShowRe] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const sha256 = require("js-sha256");
  const handleShowPassword = (value) => {
    if (value === "1") {
      setShow(!show);
      show
        ? (document.getElementById("password").type = "text")
        : (document.getElementById("password").type = "password");
    } else {
      setShowRe(!showRe);
      showRe
        ? (document.getElementById("password_re").type = "text")
        : (document.getElementById("password_re").type = "password");
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let payload = { ...data };
      payload.password = sha256(data.password);
      payload.password_re = sha256(data.password_re);
      payload.token = token;
      try {
        const response = await AuthService.postResetPassword(payload);
        if (response) {
          displayPopup(false, "Berhasil mengubah password", true, "Ok");
          history.push("/login");
        }
      } catch (error) {
        displayPopup(true, "Terjadi masalah saat edit data", false);
      }
    }
    dispatch(loadingFinish());
  };
  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const constraints = {
    password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
    password_re: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 100,
      },
      equality: "password",
    },
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => { });
  }

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={10} md={5} lg={5}>
          <div className="text-center">
            <div className="login-title">Password Baru</div>
          </div>{" "}
          <Card className="p-5">
            <Form onSubmit={onSubmitHandler}>
              <Form.Group className="mb-4">
                <div className="login-label">Email</div>
                <div className="position-absolute p-3 d-flex">
                  {/* <FaLock /> */}
                </div>
                <Form.Control
                  className="input100"
                  type="text"
                  placeholder="Masukkan Email Anda"
                  id="email"
                  name="email"
                  value={data.email}
                  onChange={onChangeHandler}
                />
                <div className="messages"></div>
              </Form.Group>
              <Form.Group className="mb-4">
                <div className="login-label">Atur Kata Sandi Baru</div>
                <div className="position-absolute p-3 d-flex">
                  {/* <FaLock /> */}
                </div>
                <div className="password_input">
                  <Form.Control
                    className="input100"
                    type="password"
                    placeholder="Masukkan Kata Sandi Anda"
                    id="password"
                    name="password"
                    value={data.password}
                    onChange={onChangeHandler}
                  />
                  {show ? (
                    <span
                      onClick={() => handleShowPassword("1")}
                      className="icon-eye position_eye"
                    ></span>
                  ) : (
                    <span
                      onClick={() => handleShowPassword("1")}
                      className="icon-eye-coret position_eye"
                    ></span>
                  )}
                </div>
                <div className="messages"></div>
              </Form.Group>
              <Form.Group className="mb-5">
                <div className="login-label">Konfirmasi Kata Sandi</div>
                <div className="position-absolute p-3">{/* <FaLock /> */}</div>
                <div className="password_input">
                  <Form.Control
                    className="mb-3 input100"
                    type="password"
                    placeholder="Konfirmasi Kata Sandi"
                    id="password_re"
                    name="password_re"
                    value={data.password_re}
                    onChange={onChangeHandler}
                  />
                  {showRe ? (
                    <span
                      onClick={() => handleShowPassword("2")}
                      className="icon-eye position_eye"
                    ></span>
                  ) : (
                    <span
                      onClick={() => handleShowPassword("2")}
                      className="icon-eye-coret position_eye"
                    ></span>
                  )}
                </div>
                <div className="messages"></div>
              </Form.Group>
              <Button type="submit" className="w-100 login-button">
                <div className="login-text">Simpan Password Baru</div>
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default NewPassword;
