import Icon from "./../../assets/images/home-certificationpage/icon/Frame-user.png";
import Icon2 from "./../../assets/images/home-certificationpage/icon/Frame-save.png";
import Icon3 from "./../../assets/images/home-certificationpage/icon/Frame-clock.png";
import Icon4 from "./../../assets/images/home-certificationpage/icon/Frame-guarantee.png";
import Icon5 from "./../../assets/images/home-certificationpage/icon/Frame-certificat.png";
import magang1 from "./../../assets/images/magang/magang1.svg";
import magang2 from "./../../assets/images/magang/magang2.svg";
import magang3 from "./../../assets/images/magang/magang3.svg";
import magang4 from "./../../assets/images/magang/magang4.svg";
import magang5 from "./../../assets/images/magang/magang5.svg";
import Icon6 from "./../../assets/images/home-certificationpage/icon/star.png";

import client from "./../../assets/images/home-certificationpage/client-1.png";
import client2 from "./../../assets/images/home-certificationpage/client-2.png";
import client3 from "./../../assets/images/home-certificationpage/client-3.png";
import client4 from "./../../assets/images/home-certificationpage/client-4.png";
import client5 from "./../../assets/images/home-certificationpage/client-5.png";
import client6 from "./../../assets/images/home-certificationpage/client-6.png";
import client7 from "./../../assets/images/home-certificationpage/client-7.png";
import client8 from "./../../assets/images/home-certificationpage/client-8.png";
import client9 from "./../../assets/images/home-certificationpage/client-9.png";
import client10 from "./../../assets/images/home-certificationpage/client-10.png";
import client11 from "./../../assets/images/home-certificationpage/client-11.png";
import client12 from "./../../assets/images/home-certificationpage/client-12.png";
import client13 from "./../../assets/images/home-certificationpage/client-13.png";
import client14 from "./../../assets/images/home-certificationpage/client-14.png";
import client15 from "./../../assets/images/home-certificationpage/client-15.png";
import client16 from "./../../assets/images/home-certificationpage/client-16.png";
import client17 from "./../../assets/images/home-certificationpage/client-17.png";

import rating from "./../../assets/images/home-certificationpage/Group176.png";
import rating2 from "./../../assets/images/home-certificationpage/Group177.png";
import rating3 from "./../../assets/images/home-certificationpage/Group178.png";

import testi from "./../../assets/images/home-certificationpage/testi-1.png";
import testi2 from "./../../assets/images/home-certificationpage/testi-2.png";
import testi3 from "./../../assets/images/home-certificationpage/testi-3.png";
import testiB1 from "./../../assets/images/home-certificationpage/subur.png";
import testiB3 from "./../../assets/images/home-certificationpage/yoga.png";

import coursesSlider from "./../../assets/images/home-certificationpage/image-slider1.png";
import coursesSlider2 from "./../../assets/images/home-certificationpage/image-slider2.png";

import iconChro1 from "./../../assets/images/home-certificationpage/icon-chro-1.png";
import iconChro3 from "./../../assets/images/home-certificationpage/icon-chro-2.png";

export const dataBenefit = [];
export const ratings = [
  {
    image: rating,
  },
  {
    image: rating2,
  },
  {
    image: rating3,
  },
];

export const clients = [
  {
    image: client,
  },
  {
    image: client2,
  },
  {
    image: client3,
  },
  {
    image: client4,
  },
  {
    image: client5,
  },
  {
    image: client6,
  },
  {
    image: client7,
  },
  {
    image: client8,
  },
  {
    image: client9,
  },
  {
    image: client10,
  },
  {
    image: client11,
  },
  {
    image: client12,
  },
  {
    image: client13,
  },
  {
    image: client14,
  },
  {
    image: client15,
  },
  {
    image: client16,
  },
  // {
  //   image: client17,
  // },
];

export const benefitMagang = [
  {
    img: magang1,
    title: "Mendapatkan 3 sertifikat yaitu, sertifikat pelatihan, sertifikat magang dan sertifikat BNSP",
  },
  {
    img: magang2,
    title: "Langsung diterima, No Screening",
  },{
    img: magang3,
    title: "Bimbingan rutin dari mentor berpengalaman",
  },{
    img: magang4,
    title: "Pengalaman langsung mengerjakan proyek nyata",
  },{
    img: magang5,
    title: "Insentif sesuai hasil kerja dengan kesempatan mendapatkan full cashback",
  },
]

export const benefitBrevet = {
  listBenefit: [
    {
      image: Icon5,
      desc: "Sertifikat Brevet diakui dan diterbitkan oleh LPK resmi yang terdaftar.",
    },
    {
      image: Icon3,
      desc: ">20 jam konten pembelajaran oleh ahli dan praktisi perpajakan berpengalaman.",
    },
    {
      image: Icon6,
      desc: "Masa langganan 12 bulan, termasuk*: Ujian Brevet yang bisa diulang, tryout, pembahasan soal, >700 pelatihan, webinar dan konsultasi rutin.",
    },
    // {
    //   image: Icon4,
    //   desc: "Garansi nilai tes terbaik",
    // },
    // {
    //   image: Icon5,
    //   desc: "Bersertifikasi",
    // },
  ],
};
export const persiapanSertifkasiAB = {
  path_id: "1",
  title: "Sertifikasi Brevet AB",
  description:
    "Sertifikasi ini merupakan sertifikasi yang membahas kompetensi perpajakan dasar hingga menengah yang terkait dengan ketentuan perpajakan penghasilan orang pribadi hingga ketentuan perpajakan badan/perusahaan.",
  tujuanPembelajaran: [
    {
      text: "Memahami Pajak Penghasilan (PPh) Orang Pribadi - Brevet AB",
    },
    {
      text: "Memahami Pajak Penghasilan (PPh) Pasal 22 - Brevet AB",
    },
    {
      text: "Memahami Pajak Penghasilan (PPh) Pasal 26 - Brevet AB",
    },
    {
      text: "Memahami Pajak Penghasilan (PPh) Pasal 23 - Brevet AB",
    },
    {
      text: "Memahami Ketentuan Umum dan Tata Cara Perpajakan - Brevet AB",
    },
    {
      text: "Memahami Pajak Bumi dan Bangunan (PBB) - Brevet AB",
    },
    {
      text: "Memahami Akuntansi Pajak - Brevet AB",
    },
    {
      text: "Memahami Pajak Penghasilan (PPh) Badan - Brevet AB ",
    },
  ],
  benefit: [
    {
      price: 1500000,
      listBenefit: [
        {
          image: Icon,
          desc: "Pengalaman belajar personal dan interaktif",
        },
        {
          image: Icon2,
          desc: "Kurikulum paling relevan",
        },
        {
          image: Icon3,
          desc: "Lebih banyak pengalaman belajar personal dan interaktif",
        },
        {
          image: Icon4,
          desc: "Garansi nilai tes terbaik",
        },
        {
          image: Icon5,
          desc: "Bersertifikasi",
        },
      ],
    },
  ],
  courses: [
    {
      title: "Pajak Penghasilan (PPh) Orang Pribadi - Brevet AB",
      subtitle: "17 Materi",
    },
    {
      title: "Ketentuan Umum dan Tata Cara Perpajakan - Brevet AB",
      subtitle: "60 Materi",
    },
    {
      title: "Pajak Penghasilan (PPh) Pasal 22 - Brevet AB",
      subtitle: "7 Materi",
    },
    {
      title: "Pajak Bumi dan Bangunan (PBB) - Brevet AB",
      subtitle: "30 Materi",
    },
    {
      title: "Pajak Penghasilan (PPh) Pasal 26 - Brevet AB",
      subtitle: "7 Materi",
    },
    { title: "Akuntansi Pajak - Brevet AB", subtitle: "89 Materi" },
    {
      title: "Pajak Penghasilan (PPh) Pasal 23 - Brevet AB",
      subtitle: "15 Materi",
    },
    {
      title: "Pajak Penghasilan (PPh) Badan - Brevet AB ",
      subtitle: "26 Materi",
    },
    {
      title: "Pajak Penghasilan (PPh) Pasal 4(2) - Brevet AB",
      subtitle: "23 Materi",
    },
    { title: "PPN dan PPN Barang Mewah - Brevet AB", subtitle: "52 Materi" },
    {
      title: "Pajak Penghasilan (PPh) Pasal 15 - Brevet AB",
      subtitle: "6 Materi",
    },
    {
      title:
        "Bea Materai (BM) dan bea perolehan atas hak tanah dan bangunan BPHTB - Brevet AB",
      subtitle: "38 Materi",
    },
  ],
};
export const persiapanSertifkasiC = {
  path_id: "2",
  title: "Sertifikasi: Brevet C",
  description:
    "Sertifikasi ini merupakan sertifikasi yang membahas kompetensi perpajakan dasar hingga menengah yang terkait dengan ketentuan perpajakan penghasilan orang pribadi hingga ketentuan perpajakan badan/perusahaan.",
  tujuanPembelajaran: [
    {
      text: "Pemotongan dan Pemungutan PPh - Brevet C",
    },
    {
      text: "PPh Badan Bentuk Usaha Tetap & SPT BUT - Brevet C",
    },
    {
      text: "Akuntansi Pajak Internasional - Brevet C",
    },
    {
      text: "Perpajakan Internasional - Brevet C",
    },
  ],
  benefit: [
    {
      price: 1500000,
      listBenefit: [
        {
          image: Icon,
          desc: "Pengalaman belajar personal dan interaktif",
        },
        {
          image: Icon2,
          desc: "Kurikulum paling relevan",
        },
        {
          image: Icon3,
          desc: "Lebih dari 100 jam konten pembelajaran",
        },
        {
          image: Icon4,
          desc: "Garansi nilai tes terbaik",
        },
        {
          image: Icon5,
          desc: "Bersertifikasi",
        },
      ],
    },
  ],
  courses: [
    {
      title: "Pemotongan dan Pemungutan PPh - Brevet C",
      subtitle: "17 Materi",
    },
    {
      title: "PPh Badan Bentuk Usaha Tetap & SPT BUT - Brevet C",
      subtitle: "60 Materi",
    },
    { title: "Akuntansi Pajak Internasional - Brevet C", subtitle: "7 Materi" },
    { title: "Perpajakan Internasional - Brevet C", subtitle: "30 Materi" },
  ],
};
export const ujianSertifikasi = {
  path_id: "co001",
  title: "Ujian Sertifikasi",
  courses: [
    {
      title: "Ujian Sertifikasi",
      subtitle: "3j 20m",
    },
  ],
};

export const benefitScorm = {
  listBenefit: [
    {
      image: Icon,
      desc: "Pengalaman belajar personal dan interaktif",
    },
    {
      image: Icon2,
      desc: "Kurikulum paling relevan",
    },
    {
      image: Icon3,
      desc: "Lebih dari 12 jam konten pembelajaran",
    },
    {
      image: Icon4,
      desc: "Garansi nilai tes terbaik",
    },
    {
      image: Icon5,
      desc: "Bersertifikasi",
    },
  ],
};
export const benefitChro = {
  listBenefit: [
    {
      image: iconChro1,
      desc: "Cocok untuk staf HRD, personalia, atau staf, supervisor, dan manager non-HRD",
    },
    {
      image: Icon3,
      desc: "Masa belajar panjang (12 bulan) sehingga tidak menggangu waktu kerja karyawan",
    },
    {
      image: iconChro3,
      desc: "Membantu implementasi Keputusan Menteri Ketenagakerjaan RI Nomor 115 tahun 2022 tentang pemberlakukan wajib sertifikasi kompetensi kerja bagi tenaga kerja bidang manajemen SDM",
    },
  ],
};
export const ujianSertifikasiScorm = {
  path_id: "lv001",
  title: "Tes TOEFL iTP® Prediction Test",
  courses: [
    {
      title: "Tes TOEFL iTP® Prediction Test",
      subtitle: "1j 55m",
    },
  ],
};

export const materiSoal = [
  {
    title: "Pembahasan Soal Brevet AB - PPh Pasal 15",
    subtitle: "30 Video Pembahasan",
    materi: [
      {
        text: "Objek PPh Pasal 15",
        result: "00:37",
      },
      {
        text: "Tarif PPh Pasal 15 Perwakilan Dagang Asing",
        result: "01:52",
      },
      {
        text: "Tarif PPh Pasal 15 Perusahaan Pelayaran Dalam Negeri",
        result: "01:55",
      },
      {
        text: "Jatuh Tempo Pembayaran PPh Pasal 15 Perusahaan Pelayaran",
        result: "01:38",
      },
      {
        text: "Tarif PPh Pasal 15 WP Kemitraan Dalam Bentuk Perjanjian Build Operate Transfer",
        result: "00:43",
      },
      {
        text: "PPh Pasal 15 Atas Usaha Jasa Pelayaran",
        result: "01:15",
      },
      {
        text: "PPh Pasal 15 Atas Biaya Sewa Atau Carter Pesawat",
        result: "00:50",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan BUT",
        result: "01:40",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan Perusahaan Pelayaran Dalam Negeri",
        result: "01:05",
      },
    ],
  },
  {
    title: "Pembahasan Soal Brevet AB - PPh Pasal 23",
    subtitle: "30 Video Pembahasan",
    materi: [
      {
        text: "Objek PPh Pasal 15",
        result: "00:37",
      },
      {
        text: "Tarif PPh Pasal 15 Perwakilan Dagang Asing",
        result: "01:52",
      },
      {
        text: "Tarif PPh Pasal 15 Perusahaan Pelayaran Dalam Negeri",
        result: "01:55",
      },
      {
        text: "Jatuh Tempo Pembayaran PPh Pasal 15 Perusahaan Pelayaran",
        result: "01:38",
      },
      {
        text: "Tarif PPh Pasal 15 WP Kemitraan Dalam Bentuk Perjanjian Build Operate Transfer",
        result: "00:43",
      },
      {
        text: "PPh Pasal 15 Atas Usaha Jasa Pelayaran",
        result: "01:15",
      },
      {
        text: "PPh Pasal 15 Atas Biaya Sewa Atau Carter Pesawat",
        result: "00:50",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan BUT",
        result: "01:40",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan Perusahaan Pelayaran Dalam Negeri",
        result: "01:05",
      },
    ],
  },
  {
    title: "Pembahasan Soal Brevet AB - PPh Badan",
    subtitle: "30 Video Pembahasan",
    materi: [
      {
        text: "Objek PPh Pasal 15",
        result: "00:37",
      },
      {
        text: "Tarif PPh Pasal 15 Perwakilan Dagang Asing",
        result: "01:52",
      },
      {
        text: "Tarif PPh Pasal 15 Perusahaan Pelayaran Dalam Negeri",
        result: "01:55",
      },
      {
        text: "Jatuh Tempo Pembayaran PPh Pasal 15 Perusahaan Pelayaran",
        result: "01:38",
      },
      {
        text: "Tarif PPh Pasal 15 WP Kemitraan Dalam Bentuk Perjanjian Build Operate Transfer",
        result: "00:43",
      },
      {
        text: "PPh Pasal 15 Atas Usaha Jasa Pelayaran",
        result: "01:15",
      },
      {
        text: "PPh Pasal 15 Atas Biaya Sewa Atau Carter Pesawat",
        result: "00:50",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan BUT",
        result: "01:40",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan Perusahaan Pelayaran Dalam Negeri",
        result: "01:05",
      },
    ],
  },
  {
    title: "Pembahasan Soal Brevet AB - Akuntansi Pajak",
    subtitle: "109 Video Pembahasan",
    materi: [
      {
        text: "Objek PPh Pasal 15",
        result: "00:37",
      },
      {
        text: "Tarif PPh Pasal 15 Perwakilan Dagang Asing",
        result: "01:52",
      },
      {
        text: "Tarif PPh Pasal 15 Perusahaan Pelayaran Dalam Negeri",
        result: "01:55",
      },
      {
        text: "Jatuh Tempo Pembayaran PPh Pasal 15 Perusahaan Pelayaran",
        result: "01:38",
      },
      {
        text: "Tarif PPh Pasal 15 WP Kemitraan Dalam Bentuk Perjanjian Build Operate Transfer",
        result: "00:43",
      },
      {
        text: "PPh Pasal 15 Atas Usaha Jasa Pelayaran",
        result: "01:15",
      },
      {
        text: "PPh Pasal 15 Atas Biaya Sewa Atau Carter Pesawat",
        result: "00:50",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan BUT",
        result: "01:40",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan Perusahaan Pelayaran Dalam Negeri",
        result: "01:05",
      },
    ],
  },
  {
    title: "Pembahasan Soal Brevet AB - KUP",
    subtitle: "30 Video Pembahasan",
    materi: [
      {
        text: "Objek PPh Pasal 15",
        result: "00:37",
      },
      {
        text: "Tarif PPh Pasal 15 Perwakilan Dagang Asing",
        result: "01:52",
      },
      {
        text: "Tarif PPh Pasal 15 Perusahaan Pelayaran Dalam Negeri",
        result: "01:55",
      },
      {
        text: "Jatuh Tempo Pembayaran PPh Pasal 15 Perusahaan Pelayaran",
        result: "01:38",
      },
      {
        text: "Tarif PPh Pasal 15 WP Kemitraan Dalam Bentuk Perjanjian Build Operate Transfer",
        result: "00:43",
      },
      {
        text: "PPh Pasal 15 Atas Usaha Jasa Pelayaran",
        result: "01:15",
      },
      {
        text: "PPh Pasal 15 Atas Biaya Sewa Atau Carter Pesawat",
        result: "00:50",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan BUT",
        result: "01:40",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan Perusahaan Pelayaran Dalam Negeri",
        result: "01:05",
      },
    ],
  },
  {
    title: "Pembahasan Soal Brevet AB - PPN/PPnBM",
    subtitle: "102 Video Pembahasan",
    materi: [
      {
        text: "Objek PPh Pasal 15",
        result: "00:37",
      },
      {
        text: "Tarif PPh Pasal 15 Perwakilan Dagang Asing",
        result: "01:52",
      },
      {
        text: "Tarif PPh Pasal 15 Perusahaan Pelayaran Dalam Negeri",
        result: "01:55",
      },
      {
        text: "Jatuh Tempo Pembayaran PPh Pasal 15 Perusahaan Pelayaran",
        result: "01:38",
      },
      {
        text: "Tarif PPh Pasal 15 WP Kemitraan Dalam Bentuk Perjanjian Build Operate Transfer",
        result: "00:43",
      },
      {
        text: "PPh Pasal 15 Atas Usaha Jasa Pelayaran",
        result: "01:15",
      },
      {
        text: "PPh Pasal 15 Atas Biaya Sewa Atau Carter Pesawat",
        result: "00:50",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan BUT",
        result: "01:40",
      },
      {
        text: "PPh Pasal 15 Atas Penghasilan Perusahaan Pelayaran Dalam Negeri",
        result: "01:05",
      },
    ],
  },
];

export const testiomial = [
  {
    description:
      "Saya kira belajar Bahasa Inggris gak bisa semurah dan semudah ini. Program-program singkat dari Satukelas membantu saya untuk berkomunikasi ketika menawarkan jasa saya ke calon klien dari luar negeri. Recommended!",
    name: "Muammar Farouk",
    role: "Video Editor",
    image: testi,
  },
  {
    description:
      "Sangat bermanfaat, ada kelas training, ada live konsultasi, dan bisa lihat record dr setiap sesi. Adminnya juga sangat helpful.",
    name: "Anice Alvina",
    role: "Head of Tax Management Unit",
    image: testi2,
  },
  {
    description:
      "Gue rasa, selain membantu untuk nyiapin gue tes TOEIC untuk lamar kerja, program Satukelas juga ngasih banyak banget kosakata baru. Jadinya bacaan dan referensi gue lebih luas karena gue lebih jago bahasa inggris.",
    name: "Darient Megantara",
    role: "Data Analyst",
    image: testi3,
  },
];
export const testiomialBrevet = [
  {
    testimoni:
      "Jika anda ingin belajar tentang Pajak, tentu di SATUKELAS.COM. Pengajarnya enak, berwawasan luas, pengetahuan tentang UU Pajak rekomended sekali, mau tanya apapun tentang pajak pasti dijawab, wes pokoke satukelas.com 👍👍👍",
    name: "Subur,",
    company: "SE - Staff Administrasi",
    photo: testiB1,
  },
  {
    testimoni:
      "Sangat bermanfaat, ada kelas training, ada live konsultasi, dan bisa lihat record dr setiap sesi. Adminnya juga sangat helpful.",
    name: "Anice Alvina",
    company: "Head of Tax Management Unit",
    photo: testi2,
  },
  {
    testimoni:
      "Satukelas memberikan pengalaman baru yang menyenangkan untuk belajar dan mendapatkan sertifikat Brevet AB dan C yang saya butuhkan, baru kali ini saya menemukan pelatihan tentang pajak yang komplit dari mulai Video pelatihan, Tryout hingga pembahasan soal, mantap satukelas!",
    name: "Yoga Prasetya",
    company: "Staff Pajak",
    photo: testiB3,
  },
];

export const dataCoursesSlider = [
  {
    thumbnail: coursesSlider,
    title: "CHRO Melakukan Administrasi Pengupahan",
    instructors: [
      {
        name: "Drs. Kasno Abdul Rachman, SE, ...",
      },
    ],
    lessons_count: "23",
    total_duration: "12000",
  },
  {
    thumbnail: coursesSlider2,
    title: "CHRO Melakukan Administrasi Pengupahan",
    instructors: [
      {
        name: "Drs. Kasno Abdul Rachman, SE, ...",
      },
    ],
    lessons_count: "23",
    total_duration: "12000",
  },
  {
    thumbnail: coursesSlider,
    title: "CHRO Melakukan Administrasi Pengupahan",
    instructors: [
      {
        name: "Drs. Kasno Abdul Rachman, SE, ...",
      },
    ],
    lessons_count: "23",
    total_duration: "12000",
  },
  {
    image: coursesSlider2,
    title: "CHRO Melakukan Administrasi Pengupahan",
    instructors: [
      {
        name: "Drs. Kasno Abdul Rachman, SE, ...",
      },
    ],
    lessons_count: "23",
    total_duration: "12000",
  },
];

export const dataBenefitScorm = [
  {
    image: Icon,
    desc: "Pengalaman belajar personal dan interaktif",
  },
  {
    image: Icon2,
    desc: "Kurikulum paling relevan",
  },
  {
    image: Icon3,
    desc: "Lebih dari 12 jam konten pembelajaran",
  },
  {
    image: Icon4,
    desc: "Garansi nilai tes terbaik",
  },
  {
    image: Icon5,
    desc: "Bersertifikasi",
  },
];

export const tujuanPembelajaranScorm1 = {};
export const tujuanPembelajaranScorm2 = {
  Text: "Structure & Written Expression (4 course)",
  ListTujuan: [],
};
export const tujuanPembelajaranScorm3 = {
  Text: "Reading Comprehension (5 course)",
  ListTujuan: [],
};

export const kickStartToefl = {
  data_id: "1",
  benefit: [
    {
      no: 1,
      text: "Booking a college course",
    },
    {
      no: 2,
      text: "The Statue of Liberty",
    },
    {
      no: 3,
      text: "Statements",
    },
    {
      no: 4,
      text: "Conversations in stores 2",
    },
    {
      no: 5,
      text: "Three speakers",
    },
    {
      no: 6,
      text: "Meet the neighbors",
    },
    {
      no: 7,
      text: "Reality television in the U.S.",
    },
    {
      no: 8,
      text: "The secret of success",
    },
    {
      no: 9,
      text: "Making plans",
    },
    {
      no: 10,
      text: "Parts of speech 1",
    },
    {
      no: 11,
      text: "Verb forms 2",
    },
    {
      no: 12,
      text: "Relative pronouns",
    },
    {
      no: 13,
      text: "The oil industry",
    },
    {
      no: 14,
      text: "Islands",
    },
    {
      no: 15,
      text: "TOEFL iTP Practice Test (L1)",
    },
  ],
};
export const predictionTestToefl = {
  data_id: "2",
  benefit: [
    {
      text: "Tes TOEFL iTP® Prediction Test",
      result: "1j 55m",
    },
  ],
};
