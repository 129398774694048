import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { AuthLoginRedirected } from "../../helper/auth";

function HandleRedirect() {
  const history = useHistory();
  const { auth, destination } = useParams();
  useEffect(() => {
    const decoded = Buffer.from(auth, "base64").toString("utf-8");
    AuthLoginRedirected(JSON.parse(decoded), destination, history);
  }, []);
  return <div>Mohon Tunggu</div>;
}

export default HandleRedirect;
