import React from "react";
import { Col } from "react-bootstrap";
// import { FaDoorOpen } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../assets/css/Error/NotFound.css";

function NotFound() {
  // const errorTitle = "Ups! Kamu keluar batas.";
  // const errorSubtitle =
  //   "Kembali ke halaman utama aja yuk. Klik tombol di bawah ini ya!";
  // const errorCode = 404;
  // const errorCodeDesc = "TIDAK DITEMUKAN";

  return (
    <div className="error_page__container">
      <Col xs={12} md={6} className="error_page__left_container">
        <div className="error_page__icon">
          {/* <FaDoorOpen /> */}
        </div>
        <div className="error_page__title">Ups! Kamu keluar batas.</div>
        <div className="error_page__subtitle">
          Kembali ke halaman utama aja yuk. Klik tombol di bawah ini ya!
        </div>
        <Link to="/" className="error_page__btn_home">
          Halaman Utama
        </Link>
      </Col>
      <Col xs={12} md={6} className="error_page__right_container">
        <div className="error_page__code">404</div>
        <div className="error_page__code_desc">TIDAK DITEMUKAN</div>
        <div className="error_page__logo">Satukelas</div>
      </Col>
    </div>
  );
}

export default NotFound;
