import { PromiseService } from "../../services/PromiseService";
import { LESSONS_DATA_LINK } from "../config/LessonsConfig";
import {
  //get
  getLessonsData,
  getLessonsDataIdSuccess,
  //fail
  getLessonsDataFail,
} from "../actions/LessonsActions";

export const getLessonsDataThunk = (data) => async (dispatch) => {
  dispatch(getLessonsData());
  try {
    const response = await PromiseService.getData(
      `${LESSONS_DATA_LINK}/${data}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getLessonsDataIdSuccess(response.data));
    } else {
      dispatch(getLessonsDataFail(response));
    }
  } catch (error) {
    dispatch(getLessonsDataFail(error));
  }
};
