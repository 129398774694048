import React, { useEffect, useState } from "react";
import { Row, Col, Container, Tabs, Tab, Button, Modal } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Personal/myCourse.css";
import "../../assets/css/Personal/progressPage.css";
import CourseCard from "../../components/CourseCard";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import { AuthIsLogin } from "../../helper/auth";
import { getCoursesByRecommendDataThunk } from "../../redux/thunks/CoursesThunks";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import Info from "../../components/common/Info";
function MyCourse() {
  const [detailModal, setDetailModal] = useState(false);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  const [modal, setModal] = useState(false);
  // const [trigger, setTrigger] = useState(true);
  const [company, setCompany] = useState([]);
  const coursesRecommendData = courses?.coursesByRecommendData?.items;
  const [myCourses, setMyCourses] = useState({
    ongoing: [],
    completed: [],
  });
  const [mandatoryCourses, setMandatoryCourses] = useState(null);

  useEffect(() => {
    AuthIsLogin(history);
    getCourses();
  }, []);

  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };
  // get courses from API
  const getCourses = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getCourses();
      const listMandatoryCourse = response.mandatory_courses;
      setMandatoryCourses(listMandatoryCourse);

      const listCourse = response.my_courses;
      const groupedCourses = { ...myCourses };

      listCourse.forEach((e) => {
        if (e.status === "ongoing") groupedCourses.ongoing.push(e);
        if (e.status === "completed") groupedCourses.completed.push(e);
      });
      setMyCourses(groupedCourses);

      // if (courses && trigger) {
      // setTrigger(false);
      // }
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  useEffect(() => {
    const companies = users.usersDataId.company_id;
    setCompany(companies);
  }, [users.usersDataId]);

  /** CHART CONFIGURATION */
  const option = {
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c} ({d}%)",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        label: {
          show: false,
          position: "center",
        },

        emphasis: {
          label: {
            show: true,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: myCourses.ongoing.length, name: "Sedang Berlangsung" },
          { value: myCourses.completed.length, name: "Sudah Selesai" },
        ],
      },
    ],
  };

  function onChartClick(param, echarts) {
    setCount(count + 1);
  }

  function onChartLegendselectchanged(param, echarts) {}

  const handleModal = () => {
    setModal(!modal);
  };

  /**
   * @function for get data course by divisi and minat
   * @returns course list
   * @param {*} e
   */
  const handleSelect = (e) => {
    if (e === "divisi" || e === "minat") {
      dispatch(
        getCoursesByRecommendDataThunk({
          type: "filter",
          param: `?recommend=${e}&size=100`,
        })
      );
    }
  };

  /** render HTML tab pelatihan dari perusahaan */
  /** takes no parameters */
  /** returns HTML element */
  const renderTabMandatoryCourses = () => {
    // NEED TO CHECK WITH API V2
    if (company === "") {
      return (
        <div className="course_min__height flex-column">
          <div className="not_found__course mb-3">
            Perusahaan Anda Belum Terdaftar
          </div>
          <Button className="percentage__button " href="/korporasi">
            Daftarkan Segera
          </Button>
        </div>
      );
    } else {
      return mandatoryCourses?.length > 0
        ? mandatoryCourses.map((value, index) => {
            return (
              <CourseCard
                hidden={true}
                mandatory={true}
                key={index}
                item={value}
              />
            );
          })
        : renderEmptyData();
    }
  };

  /** render HTML if no data found  */
  /** takes no parameters */
  /** returns HTML element */
  const renderEmptyData = () => {
    return (
      <Col className="course_min__height mb-4 not_found__course">
        Data Belum Tersedia
      </Col>
    );
  };

  return (
    <>
      {/* <Info
        detailModal={detailModal}
        setDetailModal={setDetailModal}
        seeMoreInformation={seeMoreInformation}
      /> */}
      <Container className="my_course__page">
        <Row>
          <Col lg={12} xl={12}>
            <BreadcrumbPage title="Pelatihan Saya" />
            <div className="d-flex justify-content-between">
              <h2 className="h2 my_course__section_title mb-3 ">
                Pelatihan Saya
              </h2>
              <Button
                onClick={handleModal}
                className="py-2 percentage__button "
              >
                Persentase Progress
              </Button>
            </div>

            <Tabs
              defaultActiveKey="progress"
              transition={false}
              id="noanim-tab-example"
              onSelect={(e) => handleSelect(e)}
            >
              {/* ONGOING */}
              <Tab
                tabClassName="text-blacks"
                eventKey="progress"
                title="Berlangsung"
              >
                <Row>
                  {myCourses.ongoing?.length > 0
                    ? myCourses.ongoing.map((value, index) => {
                        return (
                          <CourseCard
                            hidden={true}
                            key={index}
                            progress={myCourses.ongoing}
                            status={myCourses.ongoing}
                            item={value}
                          />
                        );
                      })
                    : renderEmptyData()}
                </Row>
              </Tab>
              {/* FINISH */}
              <Tab tabClassName="text-blacks" eventKey="end" title="Selesai">
                <Row>
                  {myCourses.completed?.length > 0
                    ? myCourses.completed.map((value, index) => {
                        return (
                          <CourseCard
                            hidden={true}
                            key={index}
                            progress={myCourses.completed}
                            status={myCourses.completed}
                            item={value}
                          />
                        );
                      })
                    : renderEmptyData()}
                </Row>
              </Tab>
              {/* MANDATORY */}
              <Tab
                tabClassName="text-blacks"
                eventKey="perusahaan"
                title="Pelatihan dari Perusahaan"
              >
                <Row className="justify-content-center">
                  {renderTabMandatoryCourses()}
                </Row>
              </Tab>
              {/* RECOMMENDATION FROM MINAT */}
              <Tab
                tabClassName="text-blacks"
                eventKey="minat"
                title="Rekomendasi dari Minat"
              >
                <Row>
                  {coursesRecommendData?.length > 0
                    ? coursesRecommendData?.map((value, index) => {
                        return (
                          <CourseCard hidden={true} key={index} item={value} />
                        );
                      })
                    : renderEmptyData()}
                </Row>
              </Tab>
              {/* RECOMMENDATION FROM DIVISION */}
              <Tab
                tabClassName="text-blacks"
                eventKey="divisi"
                title="Rekomendasi dari Divisi"
              >
                <Row>
                  {coursesRecommendData?.length > 0
                    ? coursesRecommendData?.map((value, index) => {
                        return (
                          <CourseCard hidden={true} key={index} item={value} />
                        );
                      })
                    : renderEmptyData()}
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <Modal
          dialogClassName="modal_progress"
          show={modal}
          onHide={() => setModal(!modal)}
          centered
        >
          <div className="card_inside__padding">
            <div className="dashboard__title_wrapper">
              <h2 className="dashboard__section_title h2">Persentase</h2>
            </div>
            <div>
              <div>
                <div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="progress_course mr-2"></div>{" "}
                    <div>Sedang Berlangsung : {myCourses.ongoing.length}</div>
                  </div>{" "}
                  <div className="d-flex align-items-center mb-2">
                    <div className="finish_course mr-2"></div>{" "}
                    <div>Sudah Selesai : {myCourses.completed.length}</div>
                  </div>
                </div>
              </div>
              <div>
                <ReactECharts
                  className="chart_display"
                  option={option}
                  style={{ height: 280 }}
                  onEvents={{
                    click: onChartClick,
                    legendselectchanged: onChartLegendselectchanged,
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Container>
    </>
  );
}

export default MyCourse;
