import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/Home/newHomePage.css";
import EnglishCourseCard from "../../components/EnglishCourse/EnglishCourseCard";
import Testimonial from "../../components/Testimonial";
import { AuthIsLogin } from "../../helper/auth";
import { env, membershipIdEnglish } from "../../helper/constant";
import { slugify } from "../../helper/functions";
import { LearningPathService } from "../../http/LearningPathHttp";
import { UserService } from "../../http/UserHttp";
import { removeLoginData } from "../../redux/actions/LoginActions";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import Client from "../CourseFront/Client";
import TutorialModal from "../Tutorial/TutorialModal";
import whatsapp from "./../../assets/images/WhatsApp.png";
import cdlc from "./../../assets/images/cdlc/cdlc.png";
import ellipsis from "./../../assets/images/ellipse.png";
import ellipsis2 from "./../../assets/images/elipsis-client.png";
import BenefitHome from "./BenefitHome";
import CardChro from "./CardChro";
import IconReallyEnglish from "./../../assets/images/english/reallyenglish.png";
import { getCoursesBrevetDataThunk } from "../../redux/thunks/CoursesThunks";

const titleBtn = [
  { title: "Keuangan", link: "keuangan" },
  { title: "Perpajakan", link: "perpajakan" },
  { title: "Akuntansi", link: "akuntansi" },
  { title: "Pemasaran", link: "pemasaran" },
  { title: "Penjualan", link: "penjualan" },
  { title: "Manajemen SDM", link: "manajemen%20sdm" },
  { title: "Pengembangan Diri", link: "pengembangan%20diri" },
  { title: "Manajemen Risiko", link: "manajemen%20risiko" },
  { title: "Manajemen Aset", link: "manajemen%20aset" },
  { title: "Bahasa Inggris", link: "bahasa%20inggris" },
];
function NewHomePage() {
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [dataPath, setDataPath] = useState();
  const [counter, setCounter] = useState(1);
  const [activeButton, setActiveButton] = useState("brevetPajak");
  const [userMembership, setUserMembership] = useState(null);
  const [isElementBrevet, setElementBrevet] = useState(true);
  const [isElementBnsp, setElementBnsp] = useState(false);
  const [isElementPath, setElementPath] = useState(false);
  const [dataChro, setDataChro] = useState();
  const [brevetPath, setBrevetPath] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const login = useSelector((state) => state.login);
  const courses = useSelector((state) => state.courses);

  useEffect(() => {
    if (courses?.courseBrevetData === null)
      dispatch(
        getCoursesBrevetDataThunk({
          param: `?keyword=brevet&course_type=sertifikasi`,
        })
      );
  }, [courses.courseBrevetData]);

  useEffect(() => {
    if (courses?.courseBrevetData) setBrevetPath(courses.courseBrevetData);
  }, [courses?.courseBrevetData]);

  useEffect(() => {
    if (activeButton === "bahasaInggris") {
      getEnglishPath();
    } else if (activeButton === "bnspMsdm") {
      if (env === "development") {
        getLearningPathChro("co68651931");
      } else {
        getLearningPathChro("co78129874");
      }
    }
  }, [activeButton]);

  useEffect(() => {
    if (login?.loginData?.data.first_login === 1) setShow(true);
  }, [login]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (counter > 0) {
      if (counter === 400) {
        return false;
      } else {
        setTimeout(() => setCounter(counter + 1), 25);
      }
    }
  }, [counter]);

  const onClose = () => {
    dispatch(removeLoginData());
    setShow(!show);
  };

  const getEnglishPath = async () => {
    dispatch(loadingStart());
    try {
      const response = await LearningPathService.getDetailLearningPath(
        `?category=bahasa%20inggris&size=6`
      );
      if (response) {
        setDataPath(response?.items);
        dispatch(loadingFinish());
      }
    } catch (err) {
      console.log(err);
      dispatch(loadingFinish());
    }
  };

  const getLearningPathChro = async (path_id) => {
    dispatch(loadingStart());
    try {
      const response = await LearningPathService.getChroPathId(path_id);
      if (response) {
        setDataChro(response);
        dispatch(loadingFinish());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickButton = (e, param) => {
    const clickedButton = e.target;
    const buttonId = clickedButton.id;

    switch (param) {
      case "brevetPajak":
        setElementBrevet(true);
        setElementBnsp(false);
        setElementPath(false);
        break;
      case "bnspMsdm":
        setElementBrevet(false);
        setElementBnsp(true);
        setElementPath(false);
        break;
      case "bahasaInggris":
        setElementBrevet(false);
        setElementBnsp(false);
        setElementPath(true);
        break;
      default:
        setElementBrevet(true);
        setElementBnsp(false);
        setElementPath(false);
    }

    setActiveButton(buttonId);
  };

  function renderSplideCourse() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Splide
        className="align-items-center"
        options={{
          rewind: true,
          perPage: 4,
          perMove: 4,
          gap: "1.5rem",
          pagination: false,
          arrows: true,
          breakpoints: {
            992: {
              perPage: 4,
            },
            768: {
              perPage: 2,
            },
            500: {
              perPage: 1,
            },
          },
        }}
      >
        {dataPath &&
          dataPath?.map((value, index) => {
            return (
              <SplideSlide key={index}>
                <EnglishCourseCard status={true} item={value} type="english" />
              </SplideSlide>
            );
          })}
      </Splide>
    );
  }

  const coIdC = env === "development" ? "co34028467" : "co97744206";
  const coIdAb = env === "development" ? "co92113052" : "co73258264";

  return (
    <section className="">
      <div className="wrapper-hero">
        <Carousel
          id="home"
          controls={true}
          indicators={false}
          className="position-relative"
        >
          <Carousel.Item interval={4000} className="new_banner_bg_home">
            <div className="h-100 px-2 px-xl-5 pb-5">
              <Row className="h-inherit">
                <Col
                  md={10}
                  lg={10}
                  xl={8}
                  sm={12}
                  className="align-items-center d-flex"
                >
                  <div className="w-100 wrapper-hero new_wrapper-hero border-0  d-block">
                    <h6 className="hastag_banner_home">
                      #Sertifikasi<span>Pajak</span>
                    </h6>
                    <h2
                      className="new_title-home__wrapper h2"
                      style={{ marginBottom: "unset", lineHeight: "unset" }}
                    >
                      Raih Sertifikat Brevet Pajak
                    </h2>
                    <h2 className="new_subtile-home__wrapper">
                      GRATIS <span>Gelar Profesi!</span>
                    </h2>
                    <div className="">
                      <p className="new_desc-home__subtitle  mt-3 ">
                        Yuk belajar dan ujian <span>Brevet Pajak AB</span> di
                        Satukelas dan dapatkan gelar profesi{" "}
                        <span>Certified Tax Technician</span> dari{" "}
                        <span>Asosiasi Teknisi Pajak Indonesia</span> (ATPI)
                        secara gratis! Di Satukelas,
                        <span> sertifikatnya diakui</span> di dunia kerja,
                        durasi belajarnya bisa diatur selama{" "}
                        <span>12 bulan</span> berlangganan dan juga{" "}
                        <span>ujiannya bisa diulang!</span>
                      </p>
                      <div className="d-flex mb-3 btn_new_home_banner">
                        <Link
                          to={`/brevet/${slugify("Sertifikasi : BREVET AB")}/${
                            env === "development" ? "co92113052" : "co73258264"
                          }`}
                          className="btn-outline mt-xl-4 border-0 text-white  read_more__button rounded-lg d-inline-block mt-1 mr-3"
                        >
                          Lihat Selengkapnya
                        </Link>
                      </div>
                    </div>
                    <p className="new_desc-home__footer mt-5">
                      Cek juga:{" "}
                      <Link
                        to={`/brevet/${slugify("Sertifikasi : BREVET C")}/${
                          env === "development" ? "co34028467" : "co97744206"
                        }`}
                      >
                        Paket Brevet Pajak C gratis Gelar Profesi Certified
                        Professional Tax Technician dari ATPI.
                      </Link>
                    </p>
                  </div>
                </Col>{" "}
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={4000} className="new_banner_bg_home2">
            <div className="h-100 px-2 px-lg-5 py-5">
              <Row className="h-inherit">
                <Col
                  md={10}
                  lg={10}
                  xl={8}
                  sm={12}
                  className="align-items-center d-flex"
                >
                  <div className="w-100 wrapper-hero new_wrapper-hero border-0  d-block">
                    <h6 className="hastag_banner_home">
                      #Kelas<span>BahasaInggris</span>
                    </h6>
                    <h2
                      className="new_title-home__wrapper"
                      style={{ marginBottom: "unset", lineHeight: "unset" }}
                    >
                      Belajar <span>Bahasa Inggris </span>
                      Bersertifikat tapi Murah dan Mudah?
                    </h2>
                    <div className="">
                      <p className="new_desc-home__subtitle  mt-3 new_desc-home__subtitle-1">
                        Kini di Satukelas kamu bisa <span>belajar</span> dan{" "}
                        <span>tes prediksi TOEFL, TOEIC</span> dan{" "}
                        <span>IELTS</span> untuk meningkatkan kemampuanmu
                        berbahasa Inggris! Harganya murah, belajarnya pun{" "}
                        <span>mudah</span> karena bisa kapan saja dan dimana
                        saja. <br />
                        <span>
                          <i>Be fluent in English, now!</i>{" "}
                        </span>
                      </p>
                      <div className="d-flex mb-3 btn_new_home_banner">
                        <Link
                          to={`/english-program`}
                          className="btn-outline mt-xl-4 border-0 text-white  read_more__button_english rounded-lg d-inline-block mt-1 mr-3"
                        >
                          Lihat Selengkapnya
                        </Link>
                      </div>
                    </div>
                    <p className="new_desc-home__footer my-4">
                      <a
                        href="https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Konsultasi gratis untuk pembelian grup bagi perusahaan,
                        universitas, atau sekolah.
                      </a>
                    </p>
                    <div className="d-md-flex align-items-center mb-3">
                      <div className="col-12 col-md-2 pl-0 mb-3 mb-md-0">
                        <img
                          src={IconReallyEnglish}
                          alt=""
                          width="100%"
                          height={40}
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div className="col-12 col-md-10">
                        <em className="text-white fw-400 text footer__desc-home">
                          Course materials are developed by{" "}
                          <b>Reallyenglish.com</b> in partnership with{" "}
                          <b>
                            Pearson, British Council, Cambridge University
                            Press,
                          </b>{" "}
                          and other leading ELT experts.
                        </em>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
              </Row>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item interval={4000} className="new_banner_bg_home3">
            <div className="h-100 px-2 px-lg-5 py-5">
              <Row className="h-inherit">
                <Col
                  md={10}
                  lg={10}
                  xl={8}
                  sm={12}
                  className="align-items-center d-flex"
                >
                  <div className="w-100 wrapper-hero new_wrapper-hero border-0  d-block">
                    <h6 className="hastag_banner_home">
                      #Sertifikasi<span>Pajak</span>
                    </h6>
                    <h2
                      className="new_title-home__wrapper h2"
                      style={{ marginBottom: "unset", lineHeight: "unset" }}
                    >
                      Raih Sertifikat Brevet Pajak
                    </h2>
                    <h2 className="new_subtile-home__wrapper">
                      GRATIS <span>Gelar Profesi!</span>
                    </h2>
                    <div className="">
                      <p className="new_desc-home__subtitle  mt-3 new_desc-home__subtitle-2">
                        Yuk belajar dan ujian <span>Brevet Pajak AB</span> di
                        Satukelas dan dapatkan gelar profesi{" "}
                        <span>Certified Tax Technician</span> dari{" "}
                        <span>Asosiasi Teknisi Pajak Indonesia</span> (ATPI)
                        secara gratis! Di Satukelas,
                        <span> sertifikatnya diakui</span> di dunia kerja,
                        durasi belajarnya bisa diatur selama{" "}
                        <span>12 bulan</span> berlangganan dan juga{" "}
                        <span>ujiannya bisa diulang!</span>
                      </p>
                      <div className="d-flex mb-3 btn_new_home_banner">
                        <a
                          href={`https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas
                          }`}
                          className="btn-outline mt-xl-4 border-0 text-white  read_more__button_sales rounded-lg d-inline-block mt-1 mr-3"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Hubungi Sales
                        </a>
                        <Link
                          to={`/brevet/${slugify(
                            "Sertifikasi : BREVET AB"
                          )}/${coIdAb}`}
                          className="mt-xl-4 border-0 text-white read_more__button_outline rounded-lg d-inline-block mt-1 mr-3"
                        >
                          Brevet AB
                        </Link>
                        <Link
                          to={`/brevet/${slugify(
                            "Sertifikasi : BREVET C"
                          )}/${coIdC}`}
                          className="btn-outline mt-xl-4 border-0 text-white  read_more__button_outline rounded-lg d-inline-block mt-1 mr-3"
                        >
                          Brevet C
                        </Link>
                      </div>
                    </div>
                    <p className="new_desc-home__footer mt-5">
                      Cek juga:{" "}
                      <Link
                        to={`/brevet/${slugify("Sertifikasi : BREVET C")}/${
                          env === "development" ? "co34028467" : "co97744206"
                        }`}
                      >
                        Paket Brevet Pajak C gratis Gelar Profesi Certified
                        Professional Tax Technician dari ATPI.
                      </Link>
                    </p>
                  </div>
                </Col>{" "}
              </Row>
            </div>
          </Carousel.Item> */}
          {/* <Carousel.Item interval={4000} className="new_banner_bg_home4">
            <div className="h-100 px-2 px-xl-5 py-5 position-relative">
              <Row className="h-inherit">
                <Col sm={4} md={4} xl={4}>
                  <div className="speaker-photo">
                    <Image src={webinarPhoto} alt="photo-1" />
                  </div>
                  <div className="detail-speaker">
                    <div className="detail-name__speaker">
                      Agus Supriyanto, SE.
                    </div>
                    <div className="detail-desc__speaker">
                      Professional Trainer and Tax Expert
                    </div>
                  </div>
                </Col> */}
          {/* <Col sm={8} md={8} xl={8}>
                  <div className="join-img">
                    <Image src={joinText} alt="join" />
                  </div>
                  <div className="webinar-schelude">
                    <div className="webinar-left">
                      14 November 2023 | 13.30 WIB
                    </div>
                    <div className="webinar-right">Free E-Cerficate</div>
                  </div>
                  <div className="webinar-title">
                    Peraturan Terbaru <b>PMK No 66 Tahun 2023</b>
                  </div>
                  <div className="webinar-description">
                    Kupas tuntas strategi, tips, dan informasi terbaru terkait
                    Peraturan terbaru PMK No 66
                  </div>
                  <div className="cta-wrap">
                    <a
                      href={`https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ flexShrink: 0 }}
                      className="read_more__button_sales btn-outline"
                    >
                      Daftar Sekarang
                    </a>
                    <div className="cta-description">
                      Webinar akan dilaksanakan via zoom. Link akan dikirimkan
                      melalui email yang didaftarkan 2 hari sebelum acara.
                    </div>
                  </div>
                </Col>
              </Row>
              <Image
                className="ribbon-free"
                src={ribbonFree}
                alt="ribbon-free"
              />
              <div className="cta-description-mobile">
                Webinar akan dilaksanakan via zoom. Link akan dikirimkan melalui
                email yang didaftarkan 2 hari sebelum acara.
              </div>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </div>
      <Container className="home_page_client position-relative">
        <div className="my-4">
          <h2 className="h2 home_container__title-section mt-5 mb-5 d-block">
            Telah Digunakan oleh lebih dari +{counter} perusahaan
          </h2>
        </div>
        <Row className="align-items-center justify-content-center">
          <Client col={12} />
        </Row>
        <div className="position-absolute d-none d-md-block bg__half-circle">
          <img data-src={ellipsis2} className="lazyload" alt="ellipsis 2" />
        </div>
      </Container>
      {/* <PopularCourse /> */}
      <div className="py-5 mt-5" style={{ background: "#F5F7FB" }}>
        <div className="container__section">
          <h2 className="page_title h2">
            Sertifikasi Pilihan Terpopuler di satukelas
          </h2>
          <h5 className="mb-5">
            Sertifikasi ini lagi banyak dicari, mulai belajar yuk!
          </h5>
          <div className="d-flex justify-content-between align-items-center wrapp_terpopular_section position-relative flex-wrap">
            <div
              className="col-12 col-lg-8 pb-2 pb-lg-0 mb-3 mb-lg-0 d-flex"
              style={{ overflowX: "auto" }}
            >
              <div className="col-8 col-md-4 btn__class-terpopuler-home">
                <Button
                  id="brevetPajak"
                  onClick={(e) => handleClickButton(e, "brevetPajak")}
                  className={`btn_courses_popular ${
                    activeButton === "brevetPajak" ? "active-btn" : ""
                  }`}
                >
                  Brevet Pajak
                </Button>
              </div>
              <div className="col-8 col-md-4 btn__class-terpopuler-home">
                <Button
                  id="bnspMsdm"
                  onClick={(e) => handleClickButton(e, "bnspMsdm")}
                  className={`btn_courses_popular ${
                    activeButton === "bnspMsdm" ? "active-btn" : ""
                  }`}
                >
                  BNSP MSDM
                </Button>
              </div>
              <div className="col-8 col-md-4 btn__class-terpopuler-home">
                <Button
                  id="bahasaInggris"
                  onClick={(e) => handleClickButton(e, "bahasaInggris")}
                  className={`btn_courses_popular ${
                    activeButton === "bahasaInggris" ? "active-btn" : ""
                  }`}
                >
                  Bahasa Inggris
                </Button>
              </div>
              <div className="col-8 col-xl-4 d-flex d-lg-none align-items-center justify-content-xl-end">
                <Link
                  to="/search/sertifikasi/course_type=sertifikasi"
                  className={`btn_courses_popular_link`}
                >
                  Lihat semua sertifikasi
                </Link>
              </div>
            </div>
            <div className="d-none d-lg-flex col-4 align-items-center justify-content-lg-end">
              <Link
                to="/search/sertifikasi/course_type=sertifikasi"
                className={`btn_courses_popular_link`}
              >
                Lihat semua sertifikasi
              </Link>
            </div>
          </div>
          <Row id="homeSertifikasi">
            {isElementBrevet && (
              <>
                {brevetPath?.items?.map((item, index) => (
                  <Col key={index} md={6} lg={3} className="px-2">
                    <EnglishCourseCard
                      status={true}
                      item={item}
                      type="brevet"
                    />
                  </Col>
                ))}
              </>
            )}
            {isElementBnsp && (
              <Col md={6} lg={3} className="px-2">
                <CardChro item={dataChro} />
              </Col>
            )}
            {isElementPath && (
              <Col col={12}>{loaded ? <>{renderSplideCourse()}</> : null}</Col>
            )}
          </Row>
        </div>
      </div>
      <div className="mb-5 mt-5">
        <div className="container__section">
          <Row>
            <Col lg={6}>
              <div>
                <h2 className="title_left_topik">
                  Temukan pelatihan yang cocok untuk meningkatkan skillmu!
                </h2>
                <p className="desc_left_topik">
                  Terdapat lebih dari <b>700+ video</b> pembelajaran yang dapat
                  kamu <b>akses.</b>
                </p>
              </div>
            </Col>
            <Col lg={6} className="info-container">
              <h3 className="h3 py-3 text-left info-title__text">
                TOPIK PELATIHAN
              </h3>
              <div>
                {titleBtn.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={`/search/semua/course_type=semua&category=${item.link}`}
                      className="link_topik_pelathan"
                    >
                      {item.title}
                    </Link>
                  );
                })}
                <Link
                  to="/search/semua/course_type=semua"
                  className="d-flex align-items-center link_topik_pelathan_lainnya"
                >
                  Lihat Selengkapnya
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="mt-5 bg_img_setion_getpat" style={{ height: "624px" }}>
        <div className="container__section">
          <Row>
            <div className="d-flex flex-md-column col-12 col-xl-6 c">
              <div className="pt-4">
                <div className="desc_text_banner">
                  Dapatkan Learning Center untuk Perusahaan atau Sekolah Lewat
                  Beragam Metode
                </div>
                <div className="desc_text_banner_subtitle">
                  Learning Management System siap pakai & customizable sesuai
                  kebutuhan perusahaan Anda
                </div>
                <div className="wrapp__btn">
                  <Link to="/cdlc">
                    <Button
                      className=" mb-5 btn_new_home_path"
                      style={{ marginRight: "26px" }}
                    >
                      Untuk Perusahaan
                    </Button>
                  </Link>
                  <Link to="/dlcs">
                    <Button className=" mb-5 btn_new_home_path">
                      Untuk Instansi Pendidikan
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-none d-xl-block col-6 col-xl-6">
              <img data-src={cdlc} className="lazyload w-100" alt="cdlc" />
            </div>
          </Row>
        </div>
      </div>
      <div className="mt-5">
        <Container>
          <BenefitHome />
        </Container>
      </div>
      <div className="position-relative">
        <Container>
          <div className="promotion-container">
            <h2 className="home_container__title-section">
              Solusi Pelatihan untuk Semua
            </h2>
            <Row className="justify-content-center">
              <Col lg={12} className="mb-5 text-center ">
                <Row className="justify-content-between">
                  <Col lg={5}>
                    <div className="bg-korporasi"></div>
                  </Col>
                  <Col lg={6} className="info-container">
                    <h3 className="h3 py-3 text-left info-title__text">
                      Untuk Perusahaan
                    </h3>

                    <p className="home_container__subtitle text-left">
                      Dapatkan <span>penawaran spesial</span> dan{" "}
                      <span>fitur khusus</span> perusahaan/institusi yang sesuai
                      kebutuhan perusahaan/institusi Anda.
                    </p>
                    <div className="width-fit">
                      <Button
                        href="https://api.whatsapp.com/send?phone=6282130070040"
                        className="w-100 btn_new_home"
                        target="_blank"
                      >
                        Konsultasi Gratis
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} className="mb-5 text-center ">
                <Row>
                  <Col lg={6} className="info-container">
                    <h3 className="h3 py-3 text-left info-title__text">
                      Untuk Individu
                    </h3>
                    <p className="home_container__subtitle text-left">
                      Akses tanpa batas ke 700+ pelatihan profesional, serta
                      live webinar dan konsultasi rutin, mulai dari <br />{" "}
                      <span>Rp 100.000/bulan/akun. </span>
                    </p>
                    <div className="width-fit">
                      <Button
                        href="https://api.whatsapp.com/send?phone=6282130070040"
                        className="w-100 btn_new_home"
                        target="_blank"
                      >
                        Hubungi Sales
                      </Button>
                    </div>
                  </Col>
                  <Col lg={5} className="">
                    <div className="bg-individu"> </div>
                  </Col>
                </Row>
                <Card></Card>
              </Col>
            </Row>
          </div>
        </Container>
        <img
          data-src={ellipsis}
          style={{ right: "0", bottom: "0" }}
          className="lazyload position-absolute"
          alt="ellipsis 2"
        />
      </div>
      <section className="py-3" style={{ background: "#F5F7FB" }}>
        <h2 className="home_container__title-section h2 pb-3 mt-3 d-block lh-3">
          Apa Kata Mereka?
        </h2>
        <Container className="mb-5">
          <Testimonial />
        </Container>
      </section>

      <Button
        href="https://api.whatsapp.com/send?phone=6282130070040"
        className="call-whatsapp_container"
      >
        <div className="call-whatsapp_wrapper">
          <div className="call-whatsapp_logo">
            <img
              className="lazyload"
              data-src={whatsapp}
              width={46}
              height={46}
              alt="whatsapp"
            />
          </div>
        </div>
      </Button>
      {/* tutorial first time user login */}
      <TutorialModal show={show} onClose={onClose} />
    </section>
  );
}

export default NewHomePage;
