export const INBOX_DATA_LINK = "inbox";

//get
export const INBOX_DATA = "INBOX_DATA";
export const INBOX_DATA_SUCCESS = "INBOX_DATA_SUCCESS";
export const INBOX_DATA_ID_SUCCESS = "INBOX_DATA_ID_SUCCESS";
export const INBOX_DATA_FAIL = "INBOX_DATA_FAIL";

//post
export const INBOX_DATA_POST = "INBOX_DATA_POST";
export const INBOX_DATA_POST_SUCCESS = "INBOX_DATA_POST_SUCCESS";
export const INBOX_DATA_POST_FAIL = "INBOX_DATA_POST_FAIL";

// put
export const INBOX_DATA_PUT = "INBOX_DATA_PUT";
export const INBOX_DATA_PUT_SUCCESS = "INBOX_DATA_PUT_SUCCESS";
export const INBOX_DATA_PUT_FAIL = "INBOX_DATA_PUT_FAIL";

// delete
export const INBOX_DATA_DELETE = "INBOX_DATA_DELETE";
export const INBOX_DATA_DELETE_SUCCESS = "INBOX_DATA_DELETE_SUCCESS";
export const INBOX_DATA_DELETE_FAIL = "INBOX_DATA_DELETE_FAIL";
