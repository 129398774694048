import {
  //get
  QUIZ_DATA,
  QUIZ_DATA_ID_SUCCESS,
  QUIZ_DATA_FAIL,
  //start
  QUIZ_START,
  QUIZ_START_SUCCESS,
  QUIZ_START_FAIL,
  //attempt
  QUIZ_ATTEMPT,
  QUIZ_ATTEMPT_SUCCESS,
  QUIZ_ATTEMPT_FAIL,
  //score
  QUIZ_SCORE,
  QUIZ_SCORE_SUCCESS,
  QUIZ_SCORE_FAIL,
  //remove
  QUIZ_REMOVE_ATTEMPT_SCORE,
} from "../config/QuizConfig";

//get
export const getQuizData = (value) => ({
  type: QUIZ_DATA,
  value,
});
export const getQuizDataIdSuccess = (value) => ({
  type: QUIZ_DATA_ID_SUCCESS,
  value,
});
export const getQuizDataFail = (value) => ({
  type: QUIZ_DATA_FAIL,
  value,
});

//start
export const postStartQuiz = (value) => ({
  type: QUIZ_START,
  value,
});
export const postStartQuizSuccess = (value) => ({
  type: QUIZ_START_SUCCESS,
  value,
});
export const postStartQuizFail = (value) => ({
  type: QUIZ_START_FAIL,
  value,
});

//attempt
export const getQuizAttempt = (value) => ({
  type: QUIZ_ATTEMPT,
  value,
});
export const getQuizAttemptSuccess = (value) => ({
  type: QUIZ_ATTEMPT_SUCCESS,
  value,
});
export const getQuizAttemptFail = (value) => ({
  type: QUIZ_ATTEMPT_FAIL,
  value,
});

//score
export const postQuizScore = (value) => ({
  type: QUIZ_SCORE,
  value,
});
export const postQuizScoreSuccess = (value) => ({
  type: QUIZ_SCORE_SUCCESS,
  value,
});
export const postQuizScoreFail = (value) => ({
  type: QUIZ_SCORE_FAIL,
  value,
});

//attempt score
export const removeAttemptScoreQuiz = (value) => ({
  type: QUIZ_REMOVE_ATTEMPT_SCORE,
  value,
});
