import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Library from "./Library";
import { postAssignmentDataThunk } from "../../redux/thunks/AssignmentThunks";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { AuthData } from "../../helper/auth";
import "../../assets/css/Courses/fileuploader.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { removeAssetsDataPost } from "../../redux/actions/AssetsActions";
// import { FaFileAlt } from "react-icons/fa";
const MySwal = withReactContent(Swal);

function FileUploader() {
  const [fileInfo, setFileInfo] = useState();
  const [trigger, setTrigger] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [assignment, setAssignment] = useState([]);
  const [selectedLibary, setSelectedLibary] = useState();
  const { id_material } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const assignments = useSelector((state) => state.assignments);

  const assets = useSelector((state) => state.assets);
  const assetsPost = assets.assetsDataPost;
  const users = useSelector((state) => state.users);

  useEffect(() => {
    if (assetsPost.status === 201) {
      handleClose();
      setIsSuccess(true);
      dispatch(removeAssetsDataPost());
    } else {
      if (!assetsPost?.error?.response) setIsSuccess(false);
    }
  }, [assets]);

  useEffect(() => {
    if (assignments.assignmentDataId)
      setAssignment(assignments.assignmentDataId);

    if (assignments.assignmentDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat submit data", false);
    }
    if (assignments.assignmentDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil submit data", true, "Ok");
    }
  }, [assignments]);

  useEffect(() => {
    if (
      users.usersDataId.my_tasks?.filter(
        (e) =>
          e.assignment_id === id_material.split("_")[0] &&
          e.status === "completed"
      ).length > 0
    ) {
      setCompleted(true);
    } else setCompleted(false);
  }, [location, users]);

  const libraryHandler = (newValue) => {
    setSelectedLibary(newValue);
  };
  const handleClose = () => {
    setModalShow(false);
    setSelectedLibary("");
  };
  const handleThumbnail = () => {
    setFileInfo(selectedLibary);
    setModalShow(false);
  };
  const submitHandler = () => {
    if (fileInfo) {
      setTrigger(true);
      const payload = {
        assignment_id: id_material.split("_")[0],
        student_upload: fileInfo,
        user_id: AuthData().userId,
      };
      dispatch(postAssignmentDataThunk(payload));
    } else {
      displayPopup(false, "Tugas tidak boleh kosong!", true, "Ok");
    }
  };
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }
  return (
    <div className="file-upload-wrapper">
      {assignment ? (
        <>
          <div className="file-upload-inner">
            <div className="file-upload-h1">
              <div
                dangerouslySetInnerHTML={{
                  __html: assignment.description,
                }}
              />
            </div>
          </div>
          {assignment.tutor_upload === "" ? null : (
            <div className="file-upload-inner">
              <div className="related_file__item_wrapper assignment-file">
                <div className="related_file__icon_wrapper">
                  <span className="icon-file size"></span>
                </div>
                <a href={assignment.tutor_upload}>Download Lampiran Tugas</a>
              </div>
            </div>
          )}

          {assignment.student_upload ? (
            <div className="file-upload-inner">
              <div className="file-upload-h1">
                Anda Telah Mengumpulkan Tugas
              </div>
              <a href={assignment.student_upload}>
                <div className="related_file__item_wrapper assignment-file">
                  <div className="related_file__icon_wrapper">
                    <span className="icon-file size"></span>
                  </div>
                  {/* {assignment.student_upload?.split(".com/")[1]} */}
                  <div className="related_file__filename">Tugas Anda</div>
                </div>
              </a>
            </div>
          ) : (
            <div className="file-upload-inner">
              <h1 className="file-upload-h1">Select File</h1>{" "}
              <Form.Control
                className="mb-3"
                type="text"
                id="thumbnail"
                value={fileInfo}
                placeholder="Masukkan Link Tugas Disini..."
                onClick={() => setModalShow(true)}
              />
              {fileInfo === undefined ? (
                <Button disabled>Kirim Tugas</Button>
              ) : (
                <Button onClick={submitHandler}>Kirim Tugas</Button>
              )}
            </div>
          )}
        </>
      ) : (
        ""
      )}
      <Modal
        show={modalShow}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
      >
        <Modal.Header className="px-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Pilih PDF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Library libraryHandler={libraryHandler} />
        </Modal.Body>
        <Modal.Footer className="px-4">
          <Button variant="danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleThumbnail}>
            Pilih
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default FileUploader;
