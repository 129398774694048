import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/Layout/aside.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";

const Aside = ({ image, collapsed, toggled, handleToggleSidebar }) => {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  let address = useLocation();
  let splitted = address.pathname.split("/");
  const users = useSelector((state) => state.users);
  useEffect(() => {
    const nameUser = users.usersDataId.name;
    setName(nameUser);
  }, [users]);
  const handleOpen = (value) => {
    setOpen(!value);
  };
  useEffect(() => {
    setLoaded(true);
  }, []);
  function renderSidebar() {
    // let {
    //   ProSidebar,
    //   Menu,
    //   MenuItem,
    //   SubMenu,
    //   SidebarHeader,
    //   SidebarContent,
    // } = require("react-pro-sidebar");
    return (
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="lg"
        onToggle={handleToggleSidebar}
        className="satukelas_sidebar__container"
      >
        {collapsed ? (
          ""
        ) : (
          <SidebarHeader>
            <div className="greeting_msg">Halo, {name}</div>
          </SidebarHeader>
        )}

        <SidebarContent className="my-3">
          <Menu iconShape={false}>
            <SubMenu
              title={"Dashboard"}
              onOpenChange={(e) => handleOpen(e)}
              defaultOpen={
                splitted[2] === "course" ||
                // splitted[2] === "path" ||
                splitted[2] === "calendar" ||
                splitted[2] === "certificate" ||
                splitted[2] === "membership" ||
                splitted[2] === "task" ||
                splitted[2] === "my-bookmark" ||
                splitted[2] === "webinar" ||
                splitted[2] === "scorm-score" ||
                splitted[2] === "learning-path"
                  ? true
                  : false
              }
              // icon={<FaUserAlt />}
            >
              {/* <b></b> */}
              <MenuItem
                id="course"
                className={splitted[2] === "course" ? "active" : ""}
              >
                <b></b>
                <b></b>
                {/* <FaBookOpen /> */}
                Pelatihan Saya
                <Link to="/dashboard/course/" />
              </MenuItem>
              <MenuItem
                id="webinar"
                className={splitted[2] === "webinar" ? "active" : ""}
              >
                <b></b>
                <b></b>
                {/* <FaBookOpen /> */}
                Webinar Saya
                <Link to="/dashboard/webinar/" />
              </MenuItem>
              {/* <MenuItem
                id="path"
                className={splitted[2] === "path" ? "active" : ""}
              >
                <b></b>
                <b></b>
                Learning Path Saya
                <Link to="/dashboard/path/" />
              </MenuItem>  */}
              <MenuItem
                id="calendar"
                className={splitted[2] === "calendar" ? "active" : ""}
              >
                <b></b>
                <b></b>
                {/* <FaRegCalendarAlt /> */}
                Kalender Saya
                <Link to="/dashboard/calendar/" />
              </MenuItem>
              {/* <MenuItem
                id="progress"
                className={splitted[2] === "progress" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaCircleNotch />
                Progress Saya
                <Link to="/dashboard/progress/" />
              </MenuItem> */}
              <MenuItem
                id="certificate"
                className={splitted[2] === "certificate" ? "active" : ""}
              >
                <b></b>
                <b></b>
                {/* <FaClipboardList /> */}
                Sertifikat Saya
                <Link to="/dashboard/certificate/" />
              </MenuItem>
              {/* <MenuItem
                id="learning-path"
                className={splitted[2] === "learning-path" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaClipboardList />
                Learning Path Saya
                <Link to="/dashboard/learning-path/" />
              </MenuItem> */}
              <MenuItem
                id="membership"
                className={splitted[2] === "membership" ? "active" : ""}
              >
                <b></b>
                <b></b>
                {/* <FaClipboardList /> */}
                Membership Saya
                <Link to="/dashboard/membership/" />
              </MenuItem>
              <MenuItem id="task" className={splitted[2] === "task" ? "active" : ""}>
                <b></b>
                <b></b>
                Tugas Saya
                <Link to="/dashboard/task" />
              </MenuItem>{" "}
              {/* <MenuItem id="bookmark" className={splitted[2] === "my-bookmark" ? "active" : ""}>
                <b></b>
                <b></b>
                Wishlist Saya
                <Link to="/dashboard/my-bookmark/" />
              </MenuItem> */}
              {/* <MenuItem
                id="scorm"
                className={splitted[2] === "scorm-score" ? "active" : ""}
              >
                <b></b>
                <b></b>
                Nilai Scorm Saya
                <Link to="/dashboard/scorm-score/" />
              </MenuItem> */}
              {/* <MenuItem
                id="chat"
                className={splitted[2] === "chat" ? "active" : ""}
              >
                <b></b>
                <b></b>
                Chat
                <Link to="/dashboard/chat/" />
              </MenuItem> */}
              {/* <MenuItem
                id="leaderboard"
                className={splitted[2] === "leaderboard" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaMedal />
                Leaderboard
                <Link to="/dashboard/leaderboard/" />
              </MenuItem> */}
              {/* <MenuItem
                id="point"
                className={splitted[2] === "point" ? "active" : ""}
              >
                <b></b>
                <b></b>
                <FaClipboardList />
                Poin Saya
                <Link to="/dashboard/point/" />
              </MenuItem> */}
              {/* <MenuItem>
                <FaBook />
                Kumpulan Peraturan Perundang-Undangan
                <Link to="/peraturan/" />
              </MenuItem> */}
            </SubMenu>
            <MenuItem
              id="courses"
              className={splitted[2] === "courses" ? "active" : ""}
              // icon={<FaBookOpen />}
            >
              Pelatihan
              <Link to="/search/semua/" />
            </MenuItem>
            <SubMenu
              onOpenChange={(e) => handleOpen(e)}
              defaultOpen={
                splitted[2] === "cart" || splitted[2] === "orders"
                  ? true
                  : false
              }
              title={"Transaksi Saya"}
            >
              {/* <MenuItem
                id="cart"
                className={splitted[2] === "cart" ? "active" : ""}
                // icon={<FaShoppingCart />}
              >
                {" "}
                <b></b>
                <b></b>
                Keranjang
                <Link to="/dashboard/cart/" />
              </MenuItem> */}
              <MenuItem
                id="orders"
                className={splitted[2] === "orders" ? "active" : ""}
              >
                {" "}
                <b></b>
                <b></b>
                Riwayat Transaksi
                <Link to="/dashboard/orders/" />
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    );
  }
  return (
    <StyledWrapperMain>
      {loaded ? <>{renderSidebar()}</> : null}
    </StyledWrapperMain>
  );
};

export default Aside;

const StyledWrapperMain = styled.section`
  .pro-sidebar-inner {
    background: #ffffff;
  }
  .pro-sidebar {
    color: #000;
    font-weight: bold;
  }
  svg {
    fill: #677ca7 !important;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #fafafa;
  }
  .pro-sidebar {
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #00000011;
  }
`;
