import React, { useState } from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import CourseFrontNextButton from "../CourseFrontNextButton";
import "../../assets/css/Courses/CourseDetailTitle.css";
import logoSatukelas from "./../../assets/images/logo-satukelas.png";
import { convertHours } from "../../helper/functions";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RatingCourse from "../RatingCourse";

const MySwal = withReactContent(Swal);
function CourseDetailTitle({
  id,
  item,
  title,
  titles,
  type,
  enrolled,
  enrollCourse,
  purchased,
  statusUser,
  continueCourse,
  anotherAttempt,
  isPassed,
  description,
}) {
  // const [trigger, setTrigger] = useState(false);
  let tags = "";
  if (item?.learning_paths) {
    for (let i = 0; i < item.learning_paths.length; i++) {
      if (i === 0) tags = item.learning_paths[i].title;
      else tags = tags + ", " + item.learning_paths[i].title;
    }
  }

  // const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const courses = useSelector((state) => state.courses);
  const coursesData = courses?.coursesDataId;
  // const [wishlist, setWishlist] = useState();
  // const [share, setShare] = useState();
  const [more, setMore] = useState(false);

  const handleSeeMore = () => {
    setMore(!more);
  };

  return (
    <>
      {id ? (
        <Container>
          <Row className="align-items-baseline mb-4">
            <Col md={12} className="d-flex justify-content-between mt-4 mb-4">
              <div
                id="course_detail__title"
                className="course_detail__title_front d-flex align-items-center"
              >
                {coursesData?.length >= 0 &&
                  coursesData?.instructors !== undefined && (
                    <>
                      {coursesData?.instructors[0]?.name ===
                      "Admin In-house" ? (
                        <div className="position-relative">
                          <Image
                            className="logo-satukelas__course course_detail__tutor_avatar__white"
                            roundedCircle
                          />
                          <div className="course_initial__instructor"></div>
                        </div>
                      ) : coursesData?.instructors[0]?.avatar === "" ? (
                        <div className="position-relative">
                          <Image
                            className="course_detail__tutor_avatar "
                            roundedCircle
                          />

                          <div className="course_initial__instructor">
                            {coursesData?.instructors &&
                            coursesData?.instructors?.length > 0
                              ? coursesData?.instructors[0]?.name ===
                                "Admin In-house"
                                ? null
                                : coursesData?.instructors[0]?.name.charAt(0)
                              : null}
                          </div>
                        </div>
                      ) : (
                        <div className="position-relative">
                          <Image
                            className="course_detail__tutor_avatar "
                            roundedCircle
                            src={coursesData?.instructors[0]?.avatar}
                          />
                        </div>
                      )}
                    </>
                  )}
                <div className="mx-2">
                  <span className="text-black">
                    {coursesData?.instructors !== undefined &&
                    item.instructors?.length > 0
                      ? item.instructors[0]?.name
                        ? item.instructors[0].name === "Admin In-house"
                          ? "Satukelas"
                          : item.instructors[0].name
                        : "Satukelas"
                      : "-"}
                  </span>
                  <div className="instructor_division">Instruktur</div>
                </div>
              </div>
              {/* <div className="d-flex">
                <div
                  onClick={() => handleAddWishlist(item.course_id)}
                  className={`${
                    // wishlist.includes(item.course_id)
                    //   ? "btn_satukelas_bookmark_wishlist"
                    // :
                    "btn_satukelas_bookmark"
                    } d-flex align-items-center`}
                >
                  <span className="icon-bookmark"></span>
                </div>
              </div> */}
            </Col>
            <Col>
              <div>{item.summary}</div>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <div
            className="py-5 background-contain"
            style={{
              marginBottom: "40px",
              background: `radial-gradient(81.63% 978.13% at 4.9% 54.21%, #FFFFFF 0%, #FFFFFF 58.16%, rgba(255, 255, 255, 0) 100%), url(${item?.thumbnail}), no-repeat`,
            }}
          >
            <Container>
              <Row>
                <Col md={6}>
                  {" "}
                  <div className="course_detail__course_title__black mb-2">
                    {item?.title}
                  </div>
                  {description === "" ? (
                    <div className="text_none__data course_desc__course mb-4">
                      Belum Tersedia
                    </div>
                  ) : description?.length > 175 ? (
                    <>
                      {more ? (
                        <div
                          className="course_desc__course mb-4"
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      ) : (
                        <div
                          className="course_desc__course mb-4"
                          dangerouslySetInnerHTML={{
                            __html: description.substr(0, 180) + "...",
                          }}
                        />
                      )}

                      <div
                        className="mb-4 see_more__description"
                        onClick={handleSeeMore}
                      >
                        {more ? "Lihat Lebih Sedikit" : "Lihat Selengkapnya"}
                      </div>
                    </>
                  ) : (
                    <div
                      className="course_desc__course mb-4"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  )}
                  {type === "Sertifikasi" && (
                    <iframe src="https://drive.google.com/file/d/1gkl2P70Jg8Us2gZqTi3qvaskEuNQ31EN/preview" />
                  )}
                  <div className="course_detail__tutor__wrapper d-flex mb-4">
                    {item?.instructors?.length > 0 ? (
                      item.instructors.map((item, index) => (
                        <div
                          key={index}
                          className="course_detail__tutor__item mr-3"
                        >
                          {/* <div
                          alt="Satukelas"
                          className="satu-kelas-logo__main"
                        ></div> */}
                          {item?.name === "Admin In-house" ? (
                            <div className="position-relative">
                              <Image
                                className="logo-satukelas__course course_detail__tutor_avatar__white"
                                roundedCircle
                              />
                              <div className="course_initial__instructor"></div>
                            </div>
                          ) : (
                            <div className="position-relative">
                              {item?.avatar === "" ? (
                                <>
                                  <Image
                                    className="course_detail__tutor_avatar"
                                    roundedCircle
                                  />
                                  <div className="course_initial__instructor">
                                    {item?.name === "Admin In-house"
                                      ? null
                                      : item?.name.charAt(0)}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Image
                                    className="course_detail__tutor_avatar"
                                    roundedCircle
                                    src={item?.avatar}
                                  />
                                </>
                              )}
                            </div>
                          )}

                          <div className="mx-2">
                            <div className="course_detail__tutor_name">
                              {item.name === "Admin In-house"
                                ? "Satukelas"
                                : item.name}
                            </div>
                            <div className="instructor_division">
                              Instruktur
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="course_detail__tutor__item mr-3">
                        <Image
                          className="logo-satukelas__course course_detail__tutor_avatar__white"
                          roundedCircle
                        />
                        <div className="course_detail__tutor_name">
                          Satukelas
                        </div>
                      </div>
                    )}
                    {item.name === "Admin In-house" && (
                      <div className="ml-2 d-flex align-items-center">
                        <div>
                          <Image src={logoSatukelas} />
                        </div>
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#383838",
                          }}
                          className="ml-2"
                        >
                          Pelatihan Oleh{" "}
                          <span style={{ color: "#0F59C7" }}>satukelas</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="course_duration__title">Durasi Pelatihan</div>
                  <div className="mb-5 course_duration__text">
                    {item?.total_duration === 0
                      ? "-"
                      : convertHours(item?.total_duration)}
                  </div>
                  <div className="d-flex mb-4">
                    <RatingCourse item={item} />
                  </div> */}
                  <CourseFrontNextButton
                    main="flex"
                    statusUser={statusUser}
                    passingPrereq={true}
                    item={item}
                    purchased={purchased}
                    enrollCourse={enrollCourse}
                    enrolled={enrolled}
                    anotherAttempt={anotherAttempt}
                    continueCourse={continueCourse}
                    styleButton="btn_satukelas_primary font-size__button"
                    isPassed={isPassed}
                  />
                </Col>
                <Col md={5} className="offset-md-1 d-flex align-items-center">
                  <img
                    alt="thumbnail"
                    className="thumbnail_section"
                    src={item?.thumbnail}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
}
export default CourseDetailTitle;
