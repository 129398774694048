import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import ProgressCourse from "./ProgressCourse";
import { useSelector } from "react-redux";
import { env, redirectWa } from "../helper/constant";
import { slugify } from "../helper/functions";
import { AuthData } from "../helper/auth";

function CourseFrontNextButton({
  statusUser,
  item,
  purchased,
  enrollCourse,
  enrolled,
  anotherAttempt,
  continueCourse,
  styleButton,
  progress,
  isPassed,
  main,
}) {
  const users = useSelector((state) => state.users);
  const usersData = users.usersDataId;
  const currUrl = useLocation();
  const isLogin = AuthData();

  // console.log(currUrl.search?.split("="));
  const beliMembership = () => {
    return (
      <a
        href={redirectWa}
        className={styleButton}
        target="_blank"
        rel="noopener noreferrer"
      >
        Berlangganan Sekarang
      </a>
    );
  };

  const progressCourse = () => {
    return (
      <>
        <div className="price_card__status">Progress</div>
        <ProgressCourse now={progress} />
      </>
    );
  };

  const joinWebinar = () => {
    return (
      <Link to={`/webinar/${item.title}/${item.course_id}`}>
        <Button block={true} className={styleButton}>
          Join
        </Button>
      </Link>
    );
  };

  const isHaveAccess = () => {
    return (purchased || statusUser === "active") && statusUser !== "expired";
  };

  const belumDibeli = () => {
    return (
      <Button block={true} className={styleButton} disabled>
        {item.course_type} Belum Dibeli
      </Button>
    );
  };
  const lihatLanjutkan = () => {
    return (
      <Button
        onClick={continueCourse}
        block={true}
        className={`${styleButton} mb-3`}
      >
        {anotherAttempt ? `Lihat` : `Lanjutkan`} {item.course_type}
      </Button>
    );
  };

  const mulai = () => {
    return (
      <Button block={true} className={styleButton} onClick={enrollCourse}>
        Mulai {item.course_type}
      </Button>
    );
  };

  const selesaikanPrasyarat = () => {
    return (
      <a
        href="/"
        className="mr-3"
        style={{ textDecoration: "none", whiteSpace: "nowrap" }}
        onClick={(e) => {
          let prereq = document.getElementById("prereq");
          e.preventDefault();
          prereq &&
            prereq.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        }}
      >
        <Button block={true} className={`${styleButton}`}>
          Selesaikan Prasyarat
          {/* {item.course_type} */}
        </Button>
      </a>
    );
  };
  const prepareCertification = () => {
    return (
      <a
        href={
          item?.title?.toLowerCase().includes("brevet") &&
          item?.course_type === "Sertifikasi" &&
          `/certification/brevet-${
            env === "development"
              ? slugify(item?.title?.split(":")[1]?.split(" ")[2])
              : slugify(item?.title?.split(" ")[3])
          }`
        }
        className="w-100"
        style={{ textDecoration: "none", whiteSpace: "nowrap" }}
      >
        <Button block={true} className={`ghost-prepare__certificate`}>
          Pelajari Persiapan Sertifikasi
          {/* {item.course_type} */}
        </Button>
      </a>
    );
  };
  return (
    <>
      {/* jika status tidak aktif, beli membership */}
      {statusUser !== "active" && beliMembership()}
      {statusUser === "active" && (
        <>
          {/* jika ada progress */}
          {enrolled && progress && progressCourse()}
          {/* jika tipe webinar, redirect*/}
          {item.course_type === "Webinar" && joinWebinar()}
          {/* jika bukan webinar & sudah ada topic*/}
          {item.course_type !== "Webinar" && item.topics && (
            <div className={`${main === "flex" ? "d-flex" : ""}`}>
              {/* jika belum ada akses, tampilkan belum beli */}
              {!isHaveAccess() && belumDibeli()}

              {/* jika ada akses dan sudah enroll, lihat atau lanjutkan*/}
              {isHaveAccess && enrolled && lihatLanjutkan()}

              {/* jika ada akses dan belum enroll, cek prasyarat*/}

              {isHaveAccess &&
                !enrolled &&
                (isPassed
                  ? // jika prasyarat terpenuhi
                    mulai()
                  : selesaikanPrasyarat())}
              {/* {item.course_type === "Sertifikasi" && prepareCertification()} */}
            </div>
          )}
        </>
      )}
    </>
  );
}
export default CourseFrontNextButton;
