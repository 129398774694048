import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import imgInput2 from "../../assets/images/home/input-image-thumb-2.png";
import imgInput from "../../assets/images/home/input-image-thumb.png";
import { slugify } from "../../helper/functions";
import { Col, Image } from "react-bootstrap";
import { env, prodLearningPath } from "../../helper/constant";
export default function SearchCourseField(props) {
  const {
    handleChange,
    handleKeyDown,
    keyword,
    myRef,
    handleButtonClick,
    isSubMenuVisible,
    submenuRef,
    liveSearchData,
  } = props;
  const itemNewCourse = (img, link, title, badge) => {
    return (
      <li>
        <Link to={link} className="d-flex align-items-center">
          <div className="mr-3">
            <Image src={img} alt="TOEFL" />
          </div>
          <div>
            <p className="title_search_input mb-1">{title}</p>
            <span className="badge_input_seacrh">{badge}</span>
          </div>
        </Link>
      </li>
    );
  };
  return (
    <div className="new-search-form position-relative">
      <input
        type="text"
        placeholder="Cari..."
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={keyword}
        ref={myRef}
        className="new-search-bar-input"
        onClick={handleButtonClick}
      />
      {isSubMenuVisible && (
        <div
          className="position-absolute bg-white wrapp_list_input"
          ref={submenuRef}
        >
          {/* <Col sm={12} className="wrapp_input_popular">
            <div>
              <h5
                className="seacrh_new"
                style={{
                  marginBottom: "10px",
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
              >
                Pencarian Populer
              </h5>
              <ul className="list_input_type">
                {liveSearchData?.length > 0 ? (
                  liveSearchData.map((item, index) => {
                    // console.log(item);
                    return (
                      <li key={index} onClick={handleButtonClick}>
                        <Link
                          to={`/${
                            item?.course_id == "co34028467" ||
                            item?.course_id == "co92113052" ||
                            item?.course_id == "co97744206" ||
                            item?.course_id == "co73258264"
                              ? "brevet"
                              : "course"
                          }/${slugify(item.title)}/${item.course_id}`}
                          className="d-flex gap-3 align-items-center"
                        >
                          <div className="icon-search mr-3"></div>
                          <p className="btn_search_input mb-0">{item.title}</p>
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li className="text-center">Menunggu Data...</li>
                )}
              </ul>
            </div>
            <div>
              <h5
                className="seacrh_new"
                style={{
                  marginBottom: "10px",
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
              >
                Pelatihan Terbaru
              </h5>
              <ul className="list_input_type">
                {env === "development" ? (
                  <>
                    {itemNewCourse(
                      imgInput2,
                      "/test---toefl-for-apply-to-bumn-or-pns/lp79512159",
                      "Kickstart your TOEFL Preparation",
                      "Bahasa Inggris"
                    )}
                    {itemNewCourse(
                      imgInput,
                      "/test---toefl-for-apply-to-bumn-or-pns/lp79512159",
                      "TOEFL for Professionals",
                      "Bahasa Inggris"
                    )}
                  </>
                ) : (
                  <>
                    {prodLearningPath.items.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={`/${slugify(item.title)}/${
                            item.path_id
                          }?lp=english`}
                          className="d-flex align-items-center"
                        >
                          <div className="mr-3">
                            <Image
                              src={item.thumbnail}
                              alt="TOEFL"
                              width="80px"
                              height="80px"
                              style={{
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <div>
                            <p
                              style={{
                                marginBottom: "unset",
                              }}
                              className="title_search_input mb-1"
                            >
                              {item.title}
                            </p>
                            <span className="badge_input_seacrh">
                              Bahasa Inggris
                            </span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </Col> */}
        </div>
      )}
    </div>
  );
}
