import moment from "moment";
import { get } from "underscore";
import { gaTrackingID } from "./credentials";
import { WEB_URL } from "./constant";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export function CreateResult(data, type, id) {
  // function that use for multiselect result
  let result = [];
  for (let i = 0; i < data.length; i++) {
    if (type === "array") result.push(data[i]);
    else if (type === "json") result.push({ [`${id}_id`]: data[i] });
  }
  return result;
}
export function CreateSelected(data, id) {
  // function that use for multiselect selected
  let result = [];
  if (data) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        result.push(data[i][`${id}_id`]);
      }
    }
  }
  return result;
}

export function CreateOption(items, type, id, label) {
  // function that use for multiselect option
  let object = [];
  for (let i = 0; i < items.length; i++) {
    const cond = items[i].role === "instructor" ? true : false;
    const condition = type !== "instructors" ? true : cond;
    if (condition) object.push({ key: items[i][id], label: items[i][label] });
  }
  return object;
}

export function CheckIfEmpty(data) {
  // check wheter object have empty key value
  for (var key in data) {
    var value = data[key];
    if (value === []) return true;
    if (value === [""]) return true;
    if (value === "") return true;
    if (value) {
      if (value.length <= 0) return true;
    } else return true;
  }
  return false;
}
export function Clone(obj) {
  //clone object without change the original object
  if (null === obj || "object" !== typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export function Capitalize(s) {
  //used for capitalized first letter
  return s[0].toUpperCase() + s.slice(1);
}

export function ValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function convertHours(number, detail) {
  number = Number(number);
  var h = Math.floor(number / 3600);
  var m = Math.floor((number % 3600) / 60);
  var s = Math.floor((number % 3600) % 60);
  if (detail === "detail") {
    var hDisplay = h > 0 ? h + ":" : " ";
    var mDisplay =
      m > 0 ? (m.toString().length < 2 ? "0" + m : m) + ":" : "00:";
    var sDisplay = s > 0 ? (s.toString().length < 2 ? "0" + s : s) : "00";
    return hDisplay + mDisplay + sDisplay;
  } else if (detail === "webinar") {
    var hDisplay = h > 0 ? h + "jam" : " ";
    return hDisplay;
  } else {
    var hDisplay = h > 0 ? h + " j" : " ";
    var mDisplay = m > 0 ? m + " m" : " ";
    var sDisplay = s > 0 ? s + " s" : " ";
    return hDisplay + " " + mDisplay + " " + sDisplay;
  }
}
export function verifStep(name) {
  if (name === "") {
    <div>This should work cross-browser</div>;
  }
}
export function KeepSpesificKey(data) {
  let newItem = [];
  let keyToKeep = ["certificate_id"]; //old keyToKeep = ["course_id", "certificate_id"];
  const result = (array) =>
    array.map((o) =>
      keyToKeep.reduce((acc, curr) => {
        if (o[curr]) newItem.push(o[curr]);
        return newItem;
      }, {})
    );

  return result(data)[0];
}

export function formatRupiah(money) {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(money);
}

export function FormatCurrency(number) {
  const formatter = new Intl.NumberFormat(["ban", "id"], {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return formatter.format(number);
}

export function SanitizeMaterialTypeId(material) {
  // get special properties based on material type
  // short is like duration of video/audio, pages of pdf/document
  let tmp = {
    title: material.title,
    type: material.type,
    id: "",
    short: "",
  };
  switch (material.type) {
    case "quiz_themes":
      tmp = {
        ...tmp,
        title: material.title,
        type: "quiz",
        id: material.theme_id,
        short: "10 Soal",
      };
      break;
    case "quizzes":
      tmp = {
        ...tmp,
        title: material.title,
        type: "quiz",
        id: material.quiz_id,
        short: "10 Soal",
      };
      break;
    case "assignments":
      tmp = {
        ...tmp,
        title: material.title,
        id: material.assignment_id,
        short: "1 File",
      };
      break;
    case "lessons":
      // just a reminder: lesson can be AUDIO, PDF, VIDEO H5P, VIDEO DYNTUBE
      tmp = {
        ...tmp,
        title: material.title,
        id: material.lesson_id,
        short: moment.utc(material.duration).format("HH:mm"),
      };
      break;
    case "webinars":
      tmp = {
        ...tmp,
        title: material.title,
        id: material.webinar_id,
        short: moment.utc(material.duration).format("HH:mm"),
      };
      break;
    case "scorms":
      tmp = {
        ...tmp,
        title: material.title,
        id: material.id,
        short: moment.utc(material.duration).format("HH:mm"),
      };
      break;
    default:
      tmp = tmp;
      break;
  }
  return tmp;
}

export function slugify(content) {
  return content
    ?.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export function getCookie(cname) {
  if (typeof document === "undefined") return;
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  if (typeof document === "undefined") return;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
  if (typeof document === "undefined") return;
  let expires = "expires=" + "01 Jan 1970 00:00:00";
  document.cookie = cname + "=" + "" + ";" + expires + ";path=/";
}

export function shareSocial({ link, title }) {
  const baseUrl = `${WEB_URL}`;
  return {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}${link}/`,
    twitter: `https://twitter.com/intent/tweet?url=${baseUrl}${link}/&text=${encodeURI(
      title
    )}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURI(
      title
    )} ${baseUrl}${link}/`,
    line: `line://msg/text/${encodeURI(title)} ${baseUrl}${link}/`,
  };
}

export const getParameters = (route, params, url) => {
  if (route.component === undefined) {
    return null;
  }

  switch (route.name) {
    case "course-front":
      let result = { type: "id", param: `/${params.course_id}` };
      return params.course_id !== "undefined" ? result : null;
  }
  return null;
};

/**
 * @function to display sweet alert
 * @param {boolean} cancel
 * @param {string} title
 * @param {boolean} confirm
 * @param {string} confirmMsg
 * @returns nothing
 */
export function displayPopup(cancel, title, confirm, confirmMsg) {
  MySwal.fire({
    title: title,
    showCancelButton: cancel,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    showConfirmButton: confirm,
    confirmButtonText: confirmMsg,
  }).then((value) => {});
}

/**
 * function to check user's attribute
 * @param {Object} obj
 * @returns boolean
 */
export const isProfileCompleted = (obj) => {
  let result = true;
  const req = [
    "birthday",
    "address_subdistrict",
    "occupation",
    "division",
    "education_level",
    "university",
    "major",
    "favorite_topics",
  ];

  req.forEach((e) => {
    if (obj[e] === [] || !obj[e]) {
      result = false;
    }
  });
  return result;
  // tempat kerja
  // 1.company - independent
};

export const checkMembership = (data) => {
  let createdMembership =
    data?.length > 0 && moment(data[0].created_at).format("YYYY/MM/DD");
  let expiredMembership =
    data?.length > 0 && moment(data[0].expired_at).format("YYYY/MM/DD");
  let daysMembership =
    data?.length > 0 &&
    moment(expiredMembership).diff(createdMembership, "days");
  return daysMembership;
};

export const checkElementMembership = (data) => {
  let createdMembership = moment(data.created_at).format("YYYY/MM/DD");
  let expiredMembership = moment(data.expired_at).format("YYYY/MM/DD");
  let daysMembership = moment(expiredMembership).diff(
    createdMembership,
    "days"
  );
  return daysMembership;
};

/**
 * function to check if user profile is completed
 * @param {Object} obj
 */
const checkProfile = (obj) => {
  console.log(isProfileCompleted(obj));
};

/**
 * function to check if user has active membership
 * so they can see handout, ITR
 * @param {userData} obj
 * @returns
 */
export const isUserMembershipActive = (obj) => {
  if (obj?.length === 0) return false;

  if (obj?.length > 0) {
    const memberships = obj;
    for (let i = 0; i < memberships?.length; i++) {
      const e = memberships[i];
      if (e.status === "active") return true;
    }
  } else {
    return false;
  }
};

export function getCookieData(name) {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null; // Return null jika cookie tidak ditemukan
}

export function sortingArrayWithName(order, items, att) {
  const substringsMap = {};
  order.forEach((item, index) => {
    substringsMap[item] = index;
  });

  function getOrder(value, map) {
    for (let key in map) {
      if (value.includes(key)) {
        return map[key];
      }
    }
    return Infinity; // Return a large number if no substrings match
  }

  items.sort((a, b) => {
    return getOrder(a[att], substringsMap) - getOrder(b[att], substringsMap);
  });
}
