import { Col, Container, Image, Row } from "react-bootstrap";
// import person from "./../../assets/images/english/person.webp";
import reallyenglish from "./../../assets/images/english/reallyenglish.png";
import React from "react";

export default function Heroshot(props) {
  const { typePage } = props;
  return (
    <div className="hero_course">
      <Container>
        {typePage === "minibrevet" ? (
          <Row>
            <Col lg={8}>
              <div className="heading_course">
                Ini Title Mini Brevet <span>Butuh Wording</span> Mini Brevet
              </div>
              <div className="description_course">
                Ini deskripsi panjang paragraf pertama dari mini brevet, <span>butuh wording</span> mini brevet
              </div>
              <div className="description_course mb-4">
                Ini deskripsi panjang paragraf kedua dari mini brevet, <span>butuh wording</span> mini brevet
              </div>
              <div className="description_course yellow">
                Ini deskripsi panjang paragraf ketiga dari mini brevet, <span>butuh wording</span> mini brevet
              </div>
              <div className="author_course">
                {/* <div className="logo">
                <Image src={reallyenglish} />
              </div> */}
                <div className="text">Ini deskripsi, butuh brief</div>
              </div>
            </Col>
            <Col lg={4}>
              <Image className="hero_img" src="./../../assets/images/english/person.webp" />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={8}>
              <div className="heading_course">
                Ini Title Brevet <span>Butuh Wording</span> Brevet
              </div>
              <div className="description_course">
                Ini deskripsi panjang paragraf pertama dari brevet, <span>butuh wording</span> brevet
              </div>
              <div className="description_course mb-4">
                Ini deskripsi panjang paragraf kedua dari brevet, <span>butuh wording</span> brevet
              </div>
              <div className="description_course yellow">
                Ini deskripsi panjang paragraf ketiga dari brevet, <span>butuh wording</span> brevet
              </div>
              <div className="author_course">
                {/* <div className="logo">
                <Image src={reallyenglish} />
              </div> */}
                <div className="text">Ini deskripsi, butuh brief</div>
              </div>
            </Col>
            <Col lg={4}>
              <Image className="hero_img" src="./../../assets/images/english/person.webp" />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
