import {
  NOTIFICATIONS_DATA,
  NOTIFICATIONS_DATA_SUCCESS,
  NOTIFICATIONS_DATA_ID_SUCCESS,
  NOTIFICATIONS_DATA_FAIL,
  } from "../config/NotificationConfig";
  
  export const getNotificationsData = (value) => ({
    type: NOTIFICATIONS_DATA,
    value,
  });
  export const getNotificationsDataSuccess = (value) => ({
    type: NOTIFICATIONS_DATA_SUCCESS,
    value,
  });
  export const getNotificationsDataIdSuccess = (value) => ({
    type: NOTIFICATIONS_DATA_ID_SUCCESS,
    value,
  });
  export const getNotificationsDataFail = (value) => ({
    type: NOTIFICATIONS_DATA_FAIL,
    value,
  });
  