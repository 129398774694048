import React from "react";
const Button1 = (props) => {
  const { clas, disabled, handleClick, title, item } = props;
  return (
    <div>
      {/* silahkan tambahkan class sendiri */}
      <button
        type="button"
        className={clas}
        disabled={disabled}
        onClick={handleClick ? () => handleClick(item?.course_id) : ""}
      >
        {title}
      </button>
    </div>
  );
};

export default Button1;
