import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLoginDataThunk } from "../../redux/thunks/LoginThunks";
import { Clone } from "../../helper/functions";
import { AuthLogin, AuthIsNotLogin } from "../../helper/auth";
import "../../assets/css/Account/index.css";
import Swal from "sweetalert2";

import { SkValidate } from "../../components/common/SkValidate";
import withReactContent from "sweetalert2-react-content";
import { Link, useHistory } from "react-router-dom";
// import { getHeadersDataThunk } from "../../redux/thunks/HeadersThunks";

const MySwal = withReactContent(Swal);
const sha256 = require("js-sha256");

function Login() {
  const [show, setShow] = useState(true);
  const [trigger, setTrigger] = useState(false);
  const [isSinarmas, setIsSinarmas] = useState(false);
  const [data, setData] = useState({
    password: "",
    username: "",
  });
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    AuthIsNotLogin(history);
    const _isSinarmas = history.location.search.includes("techconnect");
    setIsSinarmas(_isSinarmas);
  }, []);
  useEffect(() => {
    if (trigger && login.loginData) {
      if (login.loginData.status === "success") {
        let newData = Clone(data);
        newData.userId = login.loginData.data.user_id;
        // newData.isBnsp = login.loginData.data.is_bnsp;
        AuthLogin(newData);
        var url = new URL(window.location.href);
        var targetUrl = url.searchParams.get("targetUrl");
        if (targetUrl) {
          history.push(targetUrl);
        } else {
          history.push("/");
        }
      } else {
        setTrigger(false);
        displayPopup(login.loginData.data, "error");
      }
    }
  }, [login.loginData]);

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.id === "price" ? parseInt(e.target.value) : e.target.value;
    setData(newData);
  };
  const constraints = {
    email: {
      presence: true,
      email: true,
    },
    password: {
      presence: true,
    },
  };
  function onSubmitHandler(e) {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let body = Clone(data);
      body.password = sha256(data.password);
      setTrigger(true);
      dispatch(getLoginDataThunk(body));
    }
  }

  function displayPopup(title, icon) {
    MySwal.fire({
      title: title,
      showConfirmButton: false,
      icon: icon,
      showCloseButton: true,
      timer: 1500,
    }).then((value) => {});
  }

  const handleShowPassword = () => {
    setShow(!show);
    if (show) {
      document.getElementById("password").type = "text";
    } else {
      document.getElementById("password").type = "password";
    }
  };

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={10} sm={10} md={8} lg={5}>
          {isSinarmas ? (
            <div className="logo-sinarmas"></div>
          ) : (
            <a href="/">
              <div className="logo-satukelas"></div>
            </a>
          )}
          <h1 className="h1 login-title">Masuk</h1>{" "}
          <Form onSubmit={onSubmitHandler}>
            {/* email field */}
            <Form.Group>
              <div className="login-label">Email</div>

              <Form.Control className="auth" type="text" placeholder="Masukkan Email Anda" id="username" name="email" value={data.username} onChange={onChangeHandler} />
              <div className="messages"></div>
            </Form.Group>

            {/* password field */}
            <Form.Group>
              <div className="login-label">Kata Sandi</div>

              <div className="password_input">
                <Form.Control className="auth" type="password" placeholder="Masukkan Kata Sandi Anda" id="password" name="password" value={data.password} onChange={onChangeHandler} />
                {show ? <span onClick={handleShowPassword} className="icon-eye position_eye"></span> : <span onClick={handleShowPassword} className="icon-eye-coret position_eye"></span>}
              </div>
              <div className="messages"></div>
            </Form.Group>

            {/* forgot password link */}
            {!isSinarmas ? (
              <div className="mb-3 ">
                <div className="text-right ">
                  <a href="/forgot-password/" className="text-decoration-none login-forgot-text">
                    Lupa Password?
                  </a>
                </div>
              </div>
            ) : null}

            {/* submit button */}
            <Button type="submit" className="w-100 login-button">
              <div className="login-text">Masuk</div>
            </Button>

            {/* register link */}
            {!isSinarmas ? (
              <div className="text-center fw-500 login-register-text">
                Belum berlangganan? &nbsp;
                <Link to="/register/" className="login-register-text__span">
                  Daftar Sekarang!
                </Link>
              </div>
            ) : null}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
