import { LoginReducers } from "./reducers/LoginReducers";
import { CoursesReducers } from "./reducers/CoursesReducers";
import { LessonsReducers } from "./reducers/LessonsReducers";
import { QuizReducers } from "./reducers/QuizReducers";
import { UsersReducers } from "./reducers/UsersReducers";
import { OrdersReducers } from "./reducers/OrdersReducers";
import { WebinarsReducers } from "./reducers/WebinarsReducers";
import { AssetsReducers } from "./reducers/AssetsReducers";
import { AssignmentReducers } from "./reducers/AssignmentReducers";
import { PurchasesReducers } from "./reducers/PurchasesReducers";
import { PaymentsReducers } from "./reducers/PaymentsReducers";
import { MembershipReducers } from "./reducers/MembershipReducers";
import { InboxReducers } from "./reducers/InboxReducers";
import { CompaniesReducers } from "./reducers/CompaniesReducers";
import { LawsReducers } from "./reducers/LawsReducers";
import { CertificatesReducers } from "./reducers/CertificatesReducers";
import { NotificationsReducers } from "./reducers/NotificationReducers";
import { CommonReducers } from "./reducers/CommonReducers";
import { DiplomaReducers } from "./reducers/DiplomaReducers";
import { HeadersReducers } from "./reducers/HeadersReducers";

export default {
  login: LoginReducers,
  courses: CoursesReducers,
  lessons: LessonsReducers,
  quizzes: QuizReducers,
  users: UsersReducers,
  orders: OrdersReducers,
  webinars: WebinarsReducers,
  assets: AssetsReducers,
  assignments: AssignmentReducers,
  purchases: PurchasesReducers,
  payments: PaymentsReducers,
  membership: MembershipReducers,
  inbox: InboxReducers,
  header: HeadersReducers,
  companies: CompaniesReducers,
  laws: LawsReducers,
  certificates: CertificatesReducers,
  notifications: NotificationsReducers,
  common: CommonReducers,
  diploma: DiplomaReducers
};
