import React, { useState, useEffect, useMemo } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { Container, Button, OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";
import "moment/locale/id";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../assets/css/Personal/MyCalendar.css";
import { slugify } from "../helper/functions";
import BreadcrumbPage from "./DashboardComponent/BreadcrumbPage";

const localizer = momentLocalizer(moment);

function TimelineCalendar({ data, padding, mywebinar, dateChanges }) {
  const [events, setEvents] = useState([]);
  const { views } = useMemo(
    () => ({
      views: [Views.MONTH, Views.WEEK, Views.DAY],
    }),
    []
  );
  const users = useSelector((state) => state.users);
  let address = useLocation();
  let splitted = address.pathname.split("/");

  useEffect(() => {
    const webinar = users.usersDataId.my_webinars;
    setEvents(webinar);
  }, [users.usersDataId]);

  useEffect(() => {
    if (data) {
      setEvents([]);
      for (let i = 0; i < data?.length; i++) {
        const id = i;
        const title = data[i]?.title;
        const start = moment(data[i]?.time_starts)._d;
        const end = moment(data[i]?.time_ends)._d;
        const detail = data[i]?.course_id;
        const course_slug = slugify(data[i]?.course);
        const instructor_name = data[i]?.instructors[0]?.name;
        const instructor_division = data[i]?.instructors[0]?.division;
        const instruction_avatar = data[i]?.instructors[0]?.avatar;
        setEvents((events) => [
          ...events,
          {
            id,
            title,
            start,
            end,
            detail,
            course_slug,
            instructor_name,
            instructor_division,
            instruction_avatar,
          },
        ]);
      }
    }
  }, [data]);

  useEffect(() => { }, [events]);
  const [cultureCalendar, setCultureCalendar] = useState("id");
  const [show, setShow] = useState(false);
  const [showEvents, setShowEvents] = useState([]);

  const handleShowInfo = (e) => {
    // setShow(!show);
    // setShowEvents(e);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColorContainer = `${event?.title?.length < 15
      ? "#C9D7ED"
      : event?.title?.length < 30
        ? "#F9DAD7"
        : "#F9ECD2"
      }`;
    let colorText = `${event?.title?.length < 15
      ? "#0F59C7"
      : event?.title?.length < 30
        ? "#FF685D"
        : "#A78234"
      }`;
    let style = {
      backgroundColor: backgroundColorContainer,
      borderRadius: "8px",
      opacity: 0.8,
      color: colorText,
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  function Event({ event }) {
    let popoverClickRootClose = (
      <Popover
        id="popover-trigger-click-root-close"
        className="p-3 container-webinar__detail"
        style={{ zIndex: 10000 }}
      >
        <div className="d-flex flex-column">
          <div className="title-webinar__detail">{event.title}</div>
          <div className="date-webinar__detail">
            {moment(event.start).format("DD MMMM YYYY")}
          </div>
          <div className="date-webinar__detail mb-3">
            {moment(event.start).format("HH:mm")} {"WIB"} -{" "}
            {moment(event.end).format("HH:mm")} {"WIB"}
          </div>
          {/* <div className="mb-4 d-flex">
            <div className="instructor-webinar__img mr-2">
              <img src={event.avatar}/>
            </div>
            <div>
              <div className="fw-bold instructor-webinar__name">
                {event.instructor_name}
              </div>
              <div>
                {event.instructor_division === ""
                  ? "-"
                  : event.instructor_division}
              </div>
            </div>
          </div> */}
          <Button
            href={`/webinar/${event.course_slug}/${event.detail}`}
            className="button-webinar__detail text-center"
          >
            Detail
          </Button>
        </div>
      </Popover>
    );

    return (
      <div>
        <div>
          <OverlayTrigger
            id="help"
            trigger="click"
            rootClose
            container={this}
            placement="bottom"
            overlay={popoverClickRootClose}
          >
            <div>{event.title}</div>
          </OverlayTrigger>
        </div>
      </div>
    );
  }

  return (
    <Container className={`dashboard_page__container ${padding}`}>
      {splitted[1] === "webinar-list" ? (
        ""
      ) : (
        <>
          <BreadcrumbPage title="Kalender Saya" />
          <h2 className=" h2 dashboard__section_title mb-3">Kalender Saya</h2>
        </>
      )}
      {splitted[1] === "dashboard" && splitted[2] === "calendar" ? (
        <div className="overflow_mobile">
          <Calendar
            popup
            selectable
            culture={cultureCalendar}
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={mywebinar}
            style={{ height: "100vh" }}
            eventPropGetter={eventStyleGetter}
            components={{ event: Event }}
            onSelectEvent={(e) => handleShowInfo(e)}
            views={views}
            messages={{
              month: "Bulan",
              week: "Minggu",
              day: "Hari",
              today: "Hari Ini",
              previous: "←",
              next: "→",
            }}
          />
        </div>
      ) : (
        <div className="overflow_mobile">
          <Calendar
            popup
            selectable
            culture={cultureCalendar}
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={events}
            views={views}
            messages={{
              month: "Bulan",
              week: "Minggu",
              day: "Hari",
              previous: "←",
              next: "→",
            }}
            style={{ height: "100vh" }}
            eventPropGetter={eventStyleGetter}
            components={{ event: Event }}
            onSelectEvent={(e) => handleShowInfo(e)}
            onRangeChange={(e, a) => dateChanges(e, a)}
          />
        </div>
      )}
    </Container>
  );
}
export default TimelineCalendar;