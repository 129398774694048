import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { postPaymentsVerifyDataThunk } from "../../redux/thunks/PaymentsThunks";
import { AuthIsLogin } from "../../helper/auth";
import { paymentsMethod } from "../../helper/payments";
import { formatRupiah } from "../../helper/functions";
import ModalPembayaran from "../../components/ModalPembayaran";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import Info from "../../components/common/Info";
import "../../assets/css/Personal/Dashboard.css";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";

const MySwal = withReactContent(Swal);

function PaymentStatus() {
  const [detailModal, setDetailModal] = useState(false);
  const [data, setData] = useState([]);
  const [modalContent, setModalContent] = useState({
    show: false,
    componentBody: "",
  });
  const [trigger, setTrigger] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.payments);

  // runs on the first render
  useEffect(() => {
    /** CHECK AUTH */
    AuthIsLogin(history);
    getPayment();
  }, []);
  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };
  /** API call get trx data */
  const getPayment = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getPayment();
      setData(response?.payments);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  /** handle button click info detail */
  const handleDetail = (value) => {
    setModalContent({
      ...modalContent,
      show: true,
      body: value,
    });
  };

  /** handle button click verifikasi pembayaran */
  const handleVerify = (value) => {
    setTrigger(true);
    const dataSend = {
      order_id: value,
    };
    dispatch(postPaymentsVerifyDataThunk(dataSend));
  };

  useEffect(() => {
    setModalContent({
      ...modalContent,
      show: false,
    });
    // update when payment status changes
    if (payments.paymentsVerifyDataPost) {
      if (payments.paymentsVerifyDataPost === "fail" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi kesalahan, coba lagi", true, "Oke");
      } else if (trigger) {
        setTrigger(false);
        displayPopup(
          false,
          `Status Transaksi Anda : ${
            payments.paymentsVerifyDataPost?.transaction_status ??
            payments.paymentsVerifyDataPost?.status
          }`,
          true,
          "Oke"
        );
      }
    }
  }, [payments.paymentsVerifyDataPost]);

  /** handle displaying popup */
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        getPayment();
      }
    });
  }

  // handle copy VA number
  function handleCopy() {
    let copyText = document.getElementById("va-number");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert(copyText.value);
  }

  return (
    <>
      {/* <Info
        detailModal={detailModal}
        setDetailModal={setDetailModal}
        seeMoreInformation={seeMoreInformation}
      /> */}
      <Container className="dashboard_page__container">
        <BreadcrumbPage title="Riwayat Transaksi" />
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h2 dashboard__section_title">Riwayat Transaksi</h2>
        </div>
        {data?.length > 0 ? (
          data?.map((item, index) => {
            return (
              <>
                {item.order_id ? (
                  <div key={index} className="dashboard__main_content mb-3">
                    <div className="payment_bundle__wrapper">
                      <div className="d-flex justify-content-between">
                        <div className=" mr-3">
                          <div className="dashboard__section_number ">
                            No. {item?.order_id}
                          </div>
                          <div className="dashboard__section_date">
                            Tanggal Order:{" "}
                            {moment(item?.created_at).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}{" "}
                            | Pengirim: {item?.origin}
                          </div>
                        </div>
                      </div>
                      <div className="payment_item">
                        <div className="payment_info">
                          <div className="payment_info__title">
                            {" "}
                            {item?.destination}
                            <span className="card_content__category">
                              Kurir: {item?.courier}
                            </span>
                          </div>
                          <div className="payment_info__price">
                            {" "}
                            {formatRupiah(item?.fee)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={index}>
                    <Card className="topup-item__container mb-3">
                      <div className="topup-item__wrapper">
                        <div className="topup-item__inside-container">
                          <div className="topup-item__info">
                            <div className="topup-item__info-top">
                              Total {item?.description}
                              <span
                                className={`${
                                  item?.status === "pending"
                                    ? "dangerStatus"
                                    : item?.status === "settlement"
                                    ? "succesStatus"
                                    : item.status === "expire"
                                    ? "grayStatus"
                                    : "primaryStatus"
                                }`}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item?.status}
                              </span>
                            </div>
                            <div className="transaction_time mb-4">
                              {moment(item?.created_at).format(
                                "DD MMMM YYYY HH:mm:ss"
                              )}
                            </div>
                            <div className="topup-item__info-bottom">
                              <div className="topup-item__virtual-acc mr-2">
                                {formatRupiah(item?.amount)}
                              </div>
                            </div>
                          </div>
                        </div>
                        {item?.va_number !== "" &&
                          item?.status === "pending" && (
                            <div
                              className="info_icon__check"
                              onClick={() => handleDetail(item)}
                            >
                              <span className="icon-info"></span>
                            </div>
                          )}
                      </div>
                    </Card>
                  </div>
                )}
              </>
            );
          })
        ) : (
          <div className="course_min__height mb-4 not_found__course">
            Data tidak tersedia
          </div>
        )}

        <ModalPembayaran
          id="check"
          handleCopy={handleCopy}
          content={modalContent}
          paymentsMethod={paymentsMethod}
          handleVerify={handleVerify}
        />
      </Container>
    </>
  );
}

export default PaymentStatus;
