import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../../assets/css/Courses/pdfloaderlesson.css";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFLoader({ url }) {
  const [render, setRender] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onClickButton(param) {
    if (param === "Prev") {
      let newPageNumber = pageNumber;
      if (newPageNumber <= 1) newPageNumber = numPages;
      else newPageNumber--;
      setPageNumber(newPageNumber);
    } else if (param === "Next") {
      let newPageNumber = pageNumber;
      if (newPageNumber >= numPages) newPageNumber = 1;
      else newPageNumber++;
      setPageNumber(newPageNumber);
    }
  }

  useEffect(() => {
    setRender(true);
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    handleResize();
  }, [render]);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  function loadPDF() {
    const width = dimensions.width * 0.6;
    return (
      <Document file={{ url: url }} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          renderMode="canvas"
          renderAnnotationLayer={false}
          width={width}
        />
      </Document>
    );
  }

  return (
    <Container className="lesson-pdf-main-div lesson-container">
      {render ? loadPDF() : null}
      <div className="lesson-pdf-control__wrapper">
        <div>
          <Button
            className="lesson-pdf-btn"
            onClick={() => onClickButton("Prev")}
          >
            {`<`}
          </Button>
          <Button
            className="lesson-pdf-btn next"
            onClick={() => onClickButton("Next")}
          >
            {`>`}
          </Button>
        </div>
        <div className="text-center lesson-pdf-number">
          Halaman {pageNumber}/{numPages}
        </div>
      </div>
    </Container>
  );
}
export default PDFLoader;
