import {
  WEBINARS_DATA,
  WEBINARS_DATA_SUCCESS,
  WEBINARS_DATA_ID_SUCCESS,
  WEBINARS_DATA_FAIL,
  WEBINARS_TYPE_LENGTH_DATA,
  WEBINARS_TYPE_LENGTH_DATA_SUCCESS,
  WEBINARS_TYPE_LENGTH_DATA_FAIL,
  WEBINARS_CONSULTATION_LENGTH_DATA,
  WEBINARS_CONSULTATION_LENGTH_DATA_SUCCESS,
  WEBINARS_CONSULTATION_LENGTH_DATA_FAIL,
} from "../config/WebinarsConfig";

export const getWebinarsData = (value) => ({
  type: WEBINARS_DATA,
  value,
});
export const getWebinarsDataSuccess = (value) => ({
  type: WEBINARS_DATA_SUCCESS,
  value,
});
export const getWebinarsDataIdSuccess = (value) => ({
  type: WEBINARS_DATA_ID_SUCCESS,
  value,
});
export const getWebinarsDataFail = (value) => ({
  type: WEBINARS_DATA_FAIL,
  value,
});

export const getWebinarsTypeData = (value) => ({
  type: WEBINARS_TYPE_LENGTH_DATA,
  value,
});
export const getWebinarsTypeDataSuccess = (value) => ({
  type: WEBINARS_TYPE_LENGTH_DATA_SUCCESS,
  value,
});
export const getWebinarsTypeDataFail = (value) => ({
  type: WEBINARS_TYPE_LENGTH_DATA_FAIL,
  value,
});

export const getWebinarsConsultationData = (value) => ({
  type: WEBINARS_CONSULTATION_LENGTH_DATA,
  value,
});
export const getWebinarsConsultationDataSuccess = (value) => ({
  type: WEBINARS_CONSULTATION_LENGTH_DATA_SUCCESS,
  value,
});
export const getWebinarsConsultationDataFail = (value) => ({
  type: WEBINARS_CONSULTATION_LENGTH_DATA_FAIL,
  value,
});
