import React from "react";
import { Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../assets/css/Error/NotFound.css";

function InternalServer() {
  // const errorTitle = "Ups! Kamu keluar batas.";
  // const errorSubtitle =
  //   "Kembali ke halaman utama aja yuk. Klik tombol di bawah ini ya!";
  // const errorCode = 404;
  // const errorCodeDesc = "TIDAK DITEMUKAN";
  const history = useHistory();

  return (
    <div className="error_page__container">
      <Col xs={12} md={6} className="error_page__left_container">
        <div className="error_page__icon">{/* <FaDoorOpen /> */}</div>
        <div className="error_page__title">
          Ups! Terjadi kesalahan saat mengambil data.
        </div>
        <div className="error_page__subtitle">
          Refresh halaman atau coba beberapa saat lagi!
        </div>
        <Button
          onClick={() => history.go(0)}
          className="error_page__btn_home"
        >
          Refresh Halaman
        </Button>
      </Col>
      <Col xs={12} md={6} className="error_page__right_container">
        <div className="error_page__code">505</div>
        <div className="error_page__code_desc">Internal Server Error</div>
        <div className="error_page__logo">Satukelas</div>
      </Col>
    </div>
  );
}

export default InternalServer;
