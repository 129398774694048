import React from "react";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { Card } from "react-bootstrap";
function Sertificate({ title, courseName, thumbTemp, detail }) {
  return (
    <>
      <Card className="sertificate_wrapper__container mb-4">
        <div className="course_front__kurikulum_title ">{title}</div>

        <div className="course_front__sertifikasi__wrapper">
          <div className="mb-4 course_front__sertifikasi_text">
            Dapatkan sertifikat partisipasi setelah mengikuti {courseName} ini,
            anda dapat menggunakan sertifikat ini untuk berbagai kebutuhan anda.
          </div>
          <div className="course_front__sertifikasi__item">
            <div className="course_front__sertifikasi__img__wrapper">
              {thumbTemp === "" ? null : detail === false ||
                detail === undefined ? (
                <img
                  height="240"
                  width="100"
                  alt={courseName}
                  data-src={thumbTemp}
                  className="lazyload sertifikasi_thumbnail__detail"
                />
              ) : (
                <img
                  height="240"
                  width="70"
                  alt={courseName}
                  data-src={thumbTemp}
                  className="lazyload sertifikasi_thumbnail__detail"
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
export default Sertificate;
