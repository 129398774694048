import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container, Accordion, Carousel } from "react-bootstrap";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "./../../assets/css/StaticPages/aboutus.css";
import { carouselAboutUs, dataPerson, historyData, historyCaroueselAboutUs, company_list } from "../../helper/constant";
import linkedin from "./../../assets/images/about/linkedin.png";


function AboutUs() {
  const [idx, setIdx] = useState();
  const [activeMenu, setActiveMenu] = useState(0);

  const profilePerson = (img, name, division, link) => {
    return (
      <div className="profile-card__wrapper">
        <img className="profile-img lazyload" data-src={img} alt="profile-img" />
        <div className="profile-name">{name}</div>
        <div className="profile-division">{division}</div>
        <div>
          <a href={link} target="_blank">
            <img className="lazyload" data-src={linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
    );
  };
  const handleShow = (index) => {
    setIdx(index)
    // if (idx.includes(index)) setIdx(idx.filter((i) => i !== index));
    // else setIdx([...idx, index]);
  };
  const handleSelect = (e) => {
    setActiveMenu(parseInt(e));
  }
  useEffect(() => {
  }, [idx])
  return (
    <>
      <div className="">
        <div className="hero-shot__box position-relative">
          <Carousel controls={false} indicators={false}>
            <Carousel.Item interval={2000}>
              <img src={carouselAboutUs[0].img} alt="aboutUs" style={{ height: '70vh', objectFit: 'cover' }} className="w-100" />
            </Carousel.Item>
          </Carousel>
          <Container className="hero-shot__text">
            <Row>
              <Col>
                <div className="hero-shot__heading">
                  Wujudkan Pengalaman Belajar yang Personal Bersama Kami
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="evolution-section__title">
              Evolusi satukelas.com
            </div>
            <div className="evolution-section__subtitle">
              Kini, kami siap menyajikan keahlian kami di bidang pelatihan dalam
              bentuk penyampaian digital.
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <div className="information-section__title">
              Satukelas adalah sebuah
              <br /> evolusi
            </div>

            <Accordion
              className="mb-0"
              defaultActiveKey="0"
              onSelect={handleSelect}
            >
              {historyData?.map((item, index) => {
                return (
                  <>
                    <div className="information-section__line"></div>
                    <div className="information-section__accordion">
                      <Accordion.Toggle
                        className="p-0"
                        as={Button}
                        variant="link"
                        onClick={() => handleShow(index)}
                        eventKey={`${index}`}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <div>{item.title}</div>
                          <div>
                            {activeMenu === index
                              ? <span className="icon-chevronup"></span>
                              : <span className="icon-chevrondown"></span>
                            }
                            {/* {idx.includes(index) ? (
                              index === 0 ? (
                                <span className="icon-chevrondown"></span>
                              ) : (
                                <span className="icon-chevronup"></span>
                              )
                            ) : index === 0 ? (
                              <span className="icon-chevronup"></span>
                            ) : (
                              <span className="icon-chevrondown"></span>
                            )} */}
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${index}`}>
                        <div className="information-section__description mt-2">
                          {item?.desc}
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </>
                );
              })}
            </Accordion>
          </Col>
          <Col lg={6} className="offset-lg-1">
            <div className="information-section__illustration">
              <Carousel controls={false} indicators={false} >
                {historyCaroueselAboutUs.map((item, index) => {
                  return (
                    <Carousel.Item interval={2000}>
                      <img src={item.img} alt="carousel" className="carousel-section__img" />
                    </Carousel.Item>
                  )
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} style={{ margin: "0 auto" }}>
            <div className="client-section__title blue-border mt-5">Client</div>
          </Col>
          <Col lg={12}>
            <Row className="justify-content-center mb-5">
              {company_list.map((item, index) => {
                return (
                  <Col md={2} className="client-section__list py-1 w-100 px-3">
                    <img className="lazyload w-100" data-src={item.img} alt="client-section" />
                  </Col>
                )
              })}
            </Row>
          </Col>
          <Col lg={12}>
            <div className="founder-section__title">Our Beloved Founders</div>
            <div className="founder-section__subtitle">
              Para inisiator digitalisasi pelatihan dan terbentuknya Learning
              Management System sebagai solusi pembelajaran digital
            </div>
          </Col>
          <Col lg={4} style={{ margin: "0 auto" }}>
            {" "}
            <div className="founder-section__main">satukelas Advisor</div>
            {profilePerson(
              "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/profile1.png",
              "Sundoro",
              "Chairman",
              "https://www.linkedin.com/in/sundorobeeone"
            )}
          </Col>
          <Col lg={12}>
            <div className="ketuakelas-section__title">Meet KetuaKelas</div>
            <div className="ketuakelas-section__subtitle">
              Mereka adalah yang berjuang mewujudkan cita-cita satukelas untuk
              masyarakat Indonesia
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {dataPerson?.map((item, index) => {
            return (
              <Col md={6} lg={3} key={index}>
                {profilePerson(
                  item?.img,
                  item?.name,
                  item?.division,
                  item?.link
                )}
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default AboutUs;
