import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

const BannerKatalog = (props) => {
  const { contentType } = props;
  return (
    <div>
      <Carousel controls={false} indicators={false} className="position-relative wrapp__banner-katalog-cdlc">
        <Carousel.Item interval={4000} className="hero__banner-katalog-cdlc">
          <div className="px-xl-5 my-5">
            <div className="d-flex align-items-center">
              {contentType === "dlcs" ? (
                <div className="col-12 col-md-10 col-lg-7 content__banner-katalog-dlcs">
                  <h2 className="wording__title-banner-katalog-dlcs">
                    Kami memberikan kemudahan bagi <span>Institusi Pendidikan</span> Anda untuk memiliki <em>Digital Learning Center</em> yang <span>siap pakai</span>,
                    <span>
                      <em>customizable</em>
                    </span>
                    , dan <span>terjangkau</span>.
                  </h2>
                  <div>
                    <Link to="#" className="btn__banner-katalog">
                      Dapatkan Sekarang!
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="col-12 col-md-6 content__banner-katalog-cdlc">
                  <h2 className="wording__title-banner-katalog-cdlc">Ratusan Konten Belajar dari Berbagai Bidang Keilmuan</h2>
                  <p className="desc__banner-katalog-cdlc">
                    Satukelas memiliki ratusan konten pelatihan yang nyaman dan mudah digunakan dalam CDLC. Sehingga perusahaan dapat meningkatkan kinerja dan performa setiap karwayan yang sesuai dengan minat dan kariernya masing-masing.
                  </p>
                  <div>
                    <Link to="#" className="btn__banner-katalog">
                      Dapatkan Sekarang!
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default BannerKatalog;
