import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion, Form } from "react-bootstrap";
import "../../assets/css/Courses/index.css";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";
import FilterCard from "../../components/CoursePage/FilterCard";
import CourseCard from "../../components/CourseCard";
import Pagination from "../../components/Layout/Pagination";
import { useLocation } from "react-router-dom";

function CourseSearch() {
  const { data_type, filter_param } = useParams();
  const [show, setShow] = useState(false);
  const [filterCourseDuration, setFilterCourseDuration] = useState([]);
  const [filterCourseLive, setFilterCourseLive] = useState([]);
  const [filterCourse, setFilterCourse] = useState([]);
  const [filterCategory, setFilterCategory] = useState();
  const [filter, setFilter] = useState("none");
  const [dataType, setDataType] = useState(data_type);
  const [keywords, setKeywords] = useState("");
  const [categoriesFilter, setCategoriesFilter] = useState("");
  const [coursePagination, setCoursePagination] = useState();
  const [coursesData, setCoursesData] = useState();
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(9); //pagination size
  const history = useHistory();
  const courses = useSelector((state) => state.courses);
  const coursesCertification =
    courses?.courseCertificationLengthData?.pagination?.totalItems;
  const dispatch = useDispatch();
  const [allFilter, setAllFilter] = useState("");
  let currRoute = useLocation();
  let defaultCheck = currRoute?.pathname.split("/");

  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (filter !== "none" && filter !== "?") {
      let newDataType;
      if (filter_param === "keyword=brevet") {
        newDataType = `?course_type=${data_type}&${filter_param}&size=${size}&page=1`;
      } else {
        newDataType = `?${filter_param}&size=${size}&page=1`;
      }
      if (filter_param !== undefined) {
        dispatch(
          getCoursesDataThunk({
            type: "filter",
            param: newDataType,
          })
        );
      }
    }
  }, [filter]);

  useEffect(() => {
    if (data_type) setDataType(data_type);
  }, [data_type]);

  useEffect(() => {
    setCoursePagination(courses.coursesData?.pagination);
    if (courses.coursesData?.items) setCoursesData(courses.coursesData);
  }, [courses]);

  useEffect(() => {
    if (filter_param) {
      const current = getCurrentState(filter_param);
      // console.log(filter_param, getCurrentState(filter_param), "filter param");
      const newFilter = "?" + filter_param;
      if (current.category) {
        setCategoriesFilter(current.category);
        // setFilterCourse([]);
      } else {
        setCategoriesFilter("semua");
      }
      // else setCategoriesFilter("semua");
      if (current.keyword) setKeywords(current.keyword);
      setFilter(newFilter);
    } else {
      dispatch(
        getCoursesDataThunk({
          type: "filter",
          param: `?course_type=${data_type}&size=${size}&page=${active}`,
        })
      );
    }
  }, [filter_param]);

  /**
   * @function for filter live class and redirect to webinar catalogue
   */
  useEffect(() => {
    if (filter_param !== "keyword=brevet" && filterCourseLive.length > 0) {
      let counter = 0;
      let newFilter = "";
      if (filterCourseLive.length > 0) {
        counter++;
        newFilter += filterTemplate(
          counter,
          `course_type=${filterCourseLive.map((item) => item)}`
        );
      }
      history.push(
        `/webinar-list/${dataType.toLowerCase()}/${
          newFilter ? newFilter.split("?")[1] : ""
        }`
      );
      setActive(1);
      setFilter(newFilter);
    }
  }, [filterCourseLive]);

  /**
   * @function for filter online course and redirect to course catalogue
   */
  useEffect(() => {
    if (filter_param !== "keyword=brevet" && filterCourse.length > 0) {
      let counter = 0;
      let newFilter = "";
      counter++;
      let filter = filterCourse.filter((item) => item !== "semua");
      newFilter += filterTemplate(
        counter,
        `${
          filter?.length > 0 ? `course_type=${filter.map((item) => item)}` : ""
        }`
      );

      if (categoriesFilter !== "semua") {
        history.push(
          `/search/${data_type.toLowerCase()}/${
            newFilter ? newFilter.split("?")[1] : ""
          }&category=${categoriesFilter}`
        );
      } else {
        history.push(
          `/search/${data_type.toLowerCase()}/${
            newFilter ? newFilter.split("?")[1] : ""
          }&category=`
        );
      }
      setActive(1);
    }
    // else {
    //   history.push(`/search/${data_type.toLowerCase()}/${newFilter ? newFilter.split("?")[1] : ""}`);
    // }
  }, [filterCourse, data_type, filterCategory, categoriesFilter]);

  /**
   * @function for trigger filtering data course duration
   * @param {*} e to get value from id in form check
   * @returns
   */

  const handleFilterCourse = (e, type) => {
    let newData = [...filterCourse];
    console.log(e);
    newData.push(e.target.id);
    if (filterCourse.includes(e.target.id)) {
      let newData = [...filterCourse];
      let idx = newData.indexOf(e.target.id);
      newData?.splice(idx, 1);
      setFilterCourse(newData);
    } else {
      setFilterCourse(newData);
    }
  };
  /**
   * @function for trigger filtering data live class
   * @param {*} e to get value from id in form check
   * @returns
   */
  const handleFilterCourseLive = (e) => {
    let newData = [...filterCourseLive];
    newData.push(e.target.id);
    if (filterCourseLive.includes(e.target.id)) {
      let newData = [...filterCourseLive];
      let idx = newData.indexOf(e.target.id);
      newData?.splice(idx, 1);
      setFilterCourseLive(newData);
    } else {
      setFilterCourseLive(newData);
      setDataType(e.target.id);
    }
  };

  const handleRemoveFilter = (idx) => {
    let newData = [...filterCourse];
    newData?.splice(idx, 1);
    setFilterCourse(newData);
  };

  const handleRemoveLiveFilter = (idx) => {
    let newData = [...filterCourseLive];
    newData?.splice(idx, 1);
    setFilterCourseLive(newData);
  };

  function radioHandleChange(newValue) {
    let counter = 0;
    let newFilter = "";
    if (newValue !== "semua") {
      counter++;
      newFilter = filterTemplate(counter, `category=${newValue}`);
    }
    history.push(
      `/search/${dataType.toLowerCase()}/${
        newFilter ? newFilter.split("?")[1] : ""
      }`
    );
    setCategoriesFilter(newValue);
    setActive(1);
    setFilterCategory(newFilter);
  }

  const handleRemoveAllFilter = () => {
    history.push("/search/semua/course_type=semua");
    setFilterCourse([]);
    setFilterCourseDuration([]);
  };

  function paginationSelect(newActive) {
    setActive(newActive);
    setCoursesData();
    let params = `${filter}&course_type=${dataType}&size=${size}&page=${newActive}`;
    if (filter == "none" || filter == "")
      params = `?course_type=${dataType}&size=${size}&page=${newActive}`;
    dispatch(
      getCoursesDataThunk({
        type: "filter",
        param: params,
      })
    );
  }
  const handleChangeSort = (e) => {
    e.preventDefault();
    dispatch(
      getCoursesDataThunk({
        type: "filter",
        param: `?course_type=${data_type}${
          categoriesFilter !== "semua" ? `&category=${categoriesFilter}` : ""
        }&sort=${e.target.value}&size=${size}&page=1`,
      })
    );
  };
  return (
    <StyledWrapperMain>
      <Container className="search">
        <Row>
          <Col className="d-none d-md-block" md={3}>
            <FilterCard
              dataType={dataType}
              handleFilterCourseLive={handleFilterCourseLive}
              handleFilterCourse={handleFilterCourse}
              filterCourseLive={filterCourseLive}
              filterCourseDuration={filterCourseDuration}
              filterCourse={filterCourse}
              radioHandleChange={radioHandleChange}
              setCategoriesFilter={setCategoriesFilter}
              categoriesFilter={categoriesFilter}
              filterTemplate={filterTemplate}
              allFilter={allFilter}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={9}
            lg={9}
            className="p-1 p-md-0 mb-3 align-items-center"
          >
            <div className="course-list">
              {history.location?.pathname
                ?.split("/")[3]
                ?.includes("keyword") ? (
                <h3 className="h3 fw-700 subtitle_course__info">
                  {coursesData?.pagination
                    ? history.location.search === "?profesi"
                      ? `${
                          coursesData.pagination.totalItems -
                          coursesCertification
                        }`
                      : `${coursesData.pagination.totalItems}`
                    : "0"}{" "}
                  hasil untuk "{keywords}"
                </h3>
              ) : (
                <div>
                  <h3 className="h3 subtitle_course__info_title mb-2 fw-700">
                    Pelatihan Mandiri
                  </h3>
                  <div className="subtitle_course__info mb-3">
                    Belajar di manapun secara online dengan mudah dan gampang!
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <h3 className="h3 fw-700 mr-3 mb-0 pl-3">Urutkan </h3>
                    <Form.Group
                      className="mb-0"
                      controlId="exampleForm.ControlSelect1"
                    >
                      <Form.Control
                        className="form-select"
                        as="select"
                        onChange={(e) => handleChangeSort(e)}
                      >
                        <option value="latest">Terbaru</option>
                        <option value="popular">Terpopuler</option>
                        <option value="alphabet">Sesuai Abjad (A-Z)</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="d-flex pl-3">
                    {filterCourse?.length >= 2 && (
                      <div
                        onClick={handleRemoveAllFilter}
                        className="mb-4 mr-2 badge-filter__course-reset"
                      >
                        Hapus Semua
                      </div>
                    )}
                    {filterCourse?.length > 1 ? (
                      filterCourse.map((item, index) => {
                        return item === "semua" ? (
                          ""
                        ) : (
                          <div className="mb-4 mr-2 badge-filter__course">
                            {item === "pelatihan"
                              ? "Pelatihan"
                              : item === "studi%20kasus"
                              ? "Studi Kasus"
                              : item === "try%20out"
                              ? "Try Out"
                              : item === "sertifikasi" && "Sertifikasi"}
                            <span
                              onClick={() => handleRemoveFilter(index)}
                              className="badge-filter__remove"
                            >
                              ×
                            </span>
                          </div>
                        );
                      })
                    ) : filterCourse?.length === 0 ? (
                      <>
                        {(filterCourse?.length === 0 &&
                          defaultCheck[3].includes("sertifikasi")) ||
                        defaultCheck[3].includes("try out") ||
                        defaultCheck[3].includes("studi kasus") ||
                        defaultCheck[3].includes("pelatihan") ? (
                          <div
                            onClick={handleRemoveAllFilter}
                            className="mb-4 mr-2 badge-filter__course-reset"
                          >
                            Hapus Semua
                          </div>
                        ) : null}
                        {defaultCheck[3].includes("sertifikasi") && (
                          <div className={`mb-4 mr-2 badge-filter__course`}>
                            Sertifikasi
                          </div>
                        )}
                        {defaultCheck[3].includes("try out") && (
                          <div className={`mb-4 mr-2 badge-filter__course`}>
                            Try Out
                          </div>
                        )}
                        {defaultCheck[3].includes("studi kasus") && (
                          <div className={`mb-4 mr-2 badge-filter__course`}>
                            Studi Kasus
                          </div>
                        )}
                        {defaultCheck[3].includes("pelatihan") && (
                          <div className={`mb-4 mr-2 badge-filter__course`}>
                            Pelatihan
                          </div>
                        )}
                      </>
                    ) : null}
                    {filterCourseLive.map((item, index) => {
                      return (
                        <div
                          className="mb-4 mr-2 badge-filter__course"
                          key={index}
                        >
                          {item === "webinar" ? "Webinar" : "Konsultasi"}
                          <span
                            onClick={() => handleRemoveLiveFilter(index)}
                            className="badge-filter__remove"
                          >
                            ×
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="d-md-none text-right">
                <Accordion className="mb-4">
                  <Accordion.Toggle
                    className="show_filter__accordion"
                    eventKey="0"
                    onClick={handleShow}
                  >
                    Filter
                  </Accordion.Toggle>
                </Accordion>
              </div>
            </div>
            {show && (
              <Row>
                <Col xs={12}>
                  <Accordion.Collapse className="show mb-4" eventKey="0">
                    <FilterCard
                      dataType={dataType}
                      handleFilterCourseLive={handleFilterCourseLive}
                      handleFilterCourse={handleFilterCourse}
                      filterCourseLive={filterCourseLive}
                      filterCourseDuration={filterCourseDuration}
                      filterCourse={filterCourse}
                      radioHandleChange={radioHandleChange}
                      setCategoriesFilter={setCategoriesFilter}
                      categoriesFilter={categoriesFilter}
                      filterTemplate={filterTemplate}
                      allFilter={allFilter}
                    />
                  </Accordion.Collapse>
                </Col>
              </Row>
            )}
            <div className="course-result__container" id="course_search">
              {coursesData?.items?.length > 0
                ? coursesData.items.map((item, i) => {
                    return history.location.search === "?profesi" ? (
                      item?.title.toLowerCase().includes("brevet") ? null : (
                        <CourseCard
                          key={i}
                          item={item}
                          widthFit="fit-content"
                          isWebinar={false}
                        />
                      )
                    ) : item?.title
                        .toLowerCase()
                        .includes("bootcamp") ? null : (
                      <CourseCard
                        key={i}
                        item={item}
                        widthFit="fit-content"
                        isWebinar={false}
                      />
                    );
                  })
                : null}
            </div>
            {coursePagination ? (
              <Pagination
                size={size}
                totalRows={coursePagination.totalItems}
                totalPages={coursePagination.totalPages}
                paginationSelect={paginationSelect}
                active={active}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default CourseSearch;

function filterTemplate(counter, data) {
  return counter <= 0 ? data : counter === 1 ? `?${data}` : `&${data}`;
}

function getCurrentState(str) {
  const splitted = str.split(/=|&/);
  let newData = {};
  for (let i = 0; i < (splitted.length - 1) / 2; i++) {
    newData[splitted[i * 2]] = splitted[i * 2 + 1];
  }
  return newData;
}

const StyledWrapperMain = styled.section`
  .form-check-label {
    overflow: hidden;
    display: -webkit-box;
  }
`;
