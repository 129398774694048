import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AuthIsLogin } from "../../helper/auth";
import { DiplomaService } from "../../http/DiplomaHttp";
import {
  loadingStart,
  loadingFinish,
} from "./../../redux/reducers/CommonReducers";
import { hasUpload } from "../../redux/reducers/DiplomaReducers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
const MySwal = withReactContent(Swal);

function ATPIIjazah() {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.users);
  const usersDataId = users.usersDataId;
  const [diplomaStatus, setDiplomaStatus] = useState({
    url_s1: "",
    verified_status_s1: "",
    url_sma: "",
    verified_status_sma: "",
  });

  /**
   * @function to check login
   * @param nothing
   * @returns nothing
   */
  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  /**
   * @function to get diploma when rendered
   * @param userDataId from redux
   * @returns nothing
   */
  useEffect(() => {
    if (usersDataId.user_id) {
      getDiplomasByUserId(usersDataId.user_id);
    }
  }, [usersDataId]);

  /**
   * @function to get diploma by user id from api
   * @param {integer} user_id
   * @returns nothing
   */
  const getDiplomasByUserId = async (user_id) => {
    dispatch(loadingStart());
    try {
      const response = await DiplomaService.getDiplomaByUserId(user_id);
      if (response.items.length > 0) {
        let tempStatus = response.items[0];
        setDiplomaStatus(tempStatus);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  /**
   * @function to handle form submit
   * @param {Object} e
   * @param {string} diploma_type
   * @returns nothing
   */
  const handleSubmit = (e, diploma_type) => {
    e.preventDefault();
    const form = e.target;
    let formData = new FormData();
    formData.append("upfile", form.upfile.files[0]);
    let payload = {
      diploma_type: diploma_type,
      user_id: usersDataId.user_id,
    };
    submitDiploma(payload, formData);
  };

  /**
   * @function to upload diploma to api
   * @param {Object} payload
   * @param {Object} formData
   */
  const submitDiploma = async (payload, formData) => {
    dispatch(loadingStart());
    try {
      const response = await DiplomaService.uploadDiploma(
        payload.diploma_type,
        payload.user_id,
        formData
      );
      document.querySelector(".form-control-file").value = "";
      getDiplomasByUserId(usersDataId.user_id);
      dispatch(hasUpload(true))
    } catch (error) {
      console.log(error);
      error?.data?.detail
        ? displayPopup(false, error.data.detail, true, "OK")
        : displayPopup(false, "Terjadi Kesalahan", true, "OK");
    }
    dispatch(loadingFinish());
  };

  /**
   * @function to display form upload ijazah
   * @param {string} type
   * @returns HTML DOM
   */
  const formUploadIjazah = (type) => {
    if (type.toLowerCase() === "s1") {
      return (
        <form
          onSubmit={(e) => handleSubmit(e, "S1")}
          encType="multipart/form-data"
        >
          <div className="form-group">
            <label htmlFor="upload-ijazah-s1">Ijazah S1</label>
            <input
              type="file"
              className="form-control-file"
              id="upload-ijazah-s1"
              name="upfile"
              required
            />
          </div>
          <input type="submit" className="btn btn-primary" value={`Upload`} />
        </form>
      );
    }
    return (
      <form
        onSubmit={(e) => handleSubmit(e, "SMA")}
        encType="multipart/form-data"
      >
        <div className="form-group">
          <label htmlFor="upload-ijazah-SMA-SMK">Ijazah SMA/SMK</label>
          <input
            type="file"
            className="form-control-file"
            id="upload-ijazah-SMA-SMK"
            name="upfile"
            required
          />
        </div>
        <input type="submit" className="btn btn-primary" value={`Upload`} />
      </form>
    );
  };

  /**
   * @function to display status waiting ijazah verification
   * @param nothing
   * @returns HTML DOM
   */
  const waitVerification = () => {
    return (
      <p>
        Ijazah dalam proses verifikasi. <br />
        Anda bisa melihat hasil verifikasi di halaman ini.
      </p>
    );
  };

  /**
   * @function to display status failed ijazah verification
   * @param {string} type
   * @returns nothing
   */
  const failedVerification = (type) => {
    return (
      <>
        <p>Verifikasi ijazah gagal. Silakan upload kembali.</p>
        {type.toLowerCase() === "s1"
          ? formUploadIjazah("s1")
          : formUploadIjazah("sma")}
      </>
    );
  };

  /**
   * @function to display msg in sweet alert
   * @param {boolean} cancel
   * @param {string} title
   * @param {boolean} confirm
   * @param {string} confirmMsg
   * @returns nothing
   */
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => { });
  }

  return (
    <Container className="dashboard_page__container">
      <Row>
        <Col md={8}>
          <div className="course_front__deskripsi__wrapper">
            <h2 className="course_front__inner_title h2">Ijazah Saya</h2>
            {/* ijazah SMA/SMK */}
            <Card className="course_front__benefit_container">
              <h3 className="" id="sma">
                Ijazah SMA
              </h3>

              {/* belum upload */}
              {!diplomaStatus.url_sma && (
                <>
                  <p>Anda belum mengunggah ijazah SMA/SMK.</p>
                  {formUploadIjazah("sma")}
                </>
              )}

              {/* menunggu verif */}
              {diplomaStatus.verified_status_sma === "checking" &&
                waitVerification()}

              {/* failed */}
              {diplomaStatus.verified_status_sma === "rejected" &&
                failedVerification("sma")}

              {/* sukses */}
              {diplomaStatus.verified_status_sma === "accepted" && (
                <div style={{ maxWidth: "600px", height: "auto" }}>
                  <img alt="diploma" src={diplomaStatus.url_sma} width={"100%"} />
                </div>
              )}
            </Card>

            {/* ijazah S1 */}
            <Card className="course_front__benefit_container">
              <h3 className="" id="s1">
                Ijazah S1
              </h3>

              {/* belum upload */}
              {!diplomaStatus.url_s1 && (
                <>
                  <p>Anda belum mengunggah ijazah S1.</p>
                  {formUploadIjazah("S1")}
                </>
              )}

              {/* menunggu */}
              {diplomaStatus.verified_status_s1 === "checking" &&
                waitVerification()}

              {/* gagal */}
              {diplomaStatus.verified_status_s1 === "rejected" &&
                failedVerification("s1")}

              {/* sukses */}
              {diplomaStatus.verified_status_s1 === "accepted" && (
                <div style={{ maxWidth: "600px", height: "auto" }}>
                  <img alt="diploma" src={diplomaStatus.url_s1} width={"100%"} />
                </div>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ATPIIjazah;
