/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Toast,
  Tooltip,
} from "react-bootstrap";
import { materiSoal } from "./Data";
import Button1 from "../CourseFront/Button/Button1";
import {
  convertHours,
  slugify,
  sortingArrayWithName,
} from "../../helper/functions";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postEnrollCoursesThunk } from "../../redux/thunks/CoursesThunks";
import { AuthData } from "../../helper/auth";
import { env } from "../../helper/constant";
import { brevetService } from "../../http/BrevetHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";

const MateriAccordion = (props) => {
  const {
    data,
    title,
    subtitle,
    isLogin,
    type,
    isSubscribe,
    exam,
    continueCourse,
    // totalDuration,
    coId,
    getCourse,
    co_chro,
  } = props;
  const disableBtn = data.some((item) => item.is_completed === 0);
  const history = useHistory();
  const addressURL = history.location.pathname.split("/");
  const detailAddress = addressURL[1].split("-");
  const [idx, setIdx] = useState();
  const [activeMenu, setActiveMenu] = useState(0);
  const [courseId, setCourseId] = useState(null);
  const courses = useSelector((state) => state.courses);
  const course = courses?.coursesDataId;
  const dispatch = useDispatch();
  const coIdAb = env === "development" ? "co92113052" : "co73258264";
  const coIdC = env === "development" ? "co34028467" : "co97744206";
  const [displayToast, setDisplayToast] = useState(false);
  const [continueComplete, setContinueComplete] = useState(false);
  const [checked, setChecked] = useState(false);
  const [localCheck, setLocalCheck] = useState("");
  const [getLocalStorage, setLocalStorage] = useState(false);

  useEffect(() => {
    if (checked) {
      localStorage.setItem("hideToast", "true");
    }
  }, [checked]);

  useEffect(() => {
    // setLocalCheck(hideToastValue);
    if (co_chro && data?.length) {
      sortingArrayWithName(
        [
          "Uraian Jabatan",
          "Penerapan Kebijakan",
          "Administrasi Pengupahan",
          "Jaminan Sosial",
        ],
        data,
        "course"
      );
    }
  }, [data]);

  useEffect(() => {
    // const hideToastValue = localStorage.getItem("hideToast");
    // setLocalCheck(hideToastValue);
  }, [checked]);

  const handleEnroll = async (id) => {
    let payload = {
      user_id: AuthData().userId,
      course_id: id,
    };

    // if (continueComplete) {
    dispatch(loadingStart());

    try {
      const response = await brevetService.enrollCourseBrevet(payload);
      if (response) {
        setDisplayToast(false);
        dispatch(loadingFinish());
        getCourse();
        // if (checked) {
        //   localStorage.setItem("hideToast", "true");
        //   setLocalStorage(true);
        // }
      }
    } catch (err) {
      console.log(err);
      setDisplayToast(false);
      // setDisplayToast(!displayToast);
      dispatch(loadingFinish());
    }
    // } else {
    //   console.log("error");
    // }

    // setDisplayToast(!displayToast);
  };

  const handleComplete = (type, id) => {
    const hideToastValue = localStorage.getItem("hideToast");
    console.log(hideToastValue);
    if (hideToastValue === "true") {
      // setContinueComplete(true);
      handleEnroll(id);
    } else {
      if (type === "continueToast") {
        setDisplayToast(true);
        setCourseId(id);
        // setContinueComplete(true);
      } else {
        setDisplayToast(!displayToast);
      }
    }
    // if (!localCheck) {
    //     setDisplayToast(true);
    //     setCourseId(id);
    //     setContinueComplete(true);
    //   } else {
    //     setDisplayToast(!displayToast);
    //   }
    // } else {
    //   setDisplayToast(false);
    // }
  };

  useEffect(() => {
    const closeModalOnOutsideClick = (e) => {
      if (displayToast) {
        const toastContainer = document.querySelector(".toast-container");
        if (toastContainer && !toastContainer.contains(e.target)) {
          handleComplete("closeToast");
        }
      }
    };

    document.addEventListener("click", closeModalOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeModalOnOutsideClick);
    };
  }, [displayToast]);

  const handleSelect = (e) => {
    setActiveMenu(parseInt(e));
  };
  const handleShow = (index) => {
    setIdx(index);
  };

  const handleChangeSkip = (e) => {
    const checked = e.target.checked;
    setChecked(checked);
  };

  const showToast = (item) => {
    return (
      <Toast
        className="_toast toast__checked"
        show={displayToast}
        onClose={() => setDisplayToast(false)}
      >
        <Toast.Header className="justify-content-start"></Toast.Header>
        <Toast.Body className="p-0">
          <div>
            <p class="text-center desc__toast">
              Apakah anda yakin untuk menyelesaikan course ini?
            </p>
            <div class="d-flex input-group group__hide-toast mb-3">
              <input
                class="ml-2"
                type="checkbox"
                id="checkSkip"
                onChange={(e) => {
                  handleChangeSkip(e);
                }}
              />
              <label htmlFor="checkSkip" className="mb-0">
                Jangan tampilkan pilihan ini lagi
              </label>
            </div>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-primary w-50 btn__skip-enrol mr-0"
              onClick={() => handleEnroll(item.course_id)}
            >
              Ya
            </button>
            <button
              className=" w-50 btn__close-toast mr-0"
              onClick={() => handleComplete("closeToast")}
            >
              Tidak
            </button>
          </div>
        </Toast.Body>
      </Toast>
      // <div class="toast-container toast__checked">
      //   {" "}
      //   <div
      //     class="toast"
      //     role="alert"
      //     aria-live="assertive"
      //     aria-atomic="true"
      //   >
      //     <div class="toast-header d-block mb-2">
      //       <div>
      //         <p class="text-center desc__toast">
      //           Apakah anda yakin untuk menyelesaikan course ini?
      //         </p>
      //       </div>
      //       <div class="d-flex input-group group__hide-toast ">
      //         <input
      //           class="ml-2"
      //           type="checkbox"
      //           id="checkSkip"
      //           onChange={(e) => {
      //             handleChangeSkip(e);
      //           }}
      //         />
      //         <label htmlFor="checkSkip" className="mb-0">
      //           Jangan tampilkan pilihan ini lagi
      //         </label>
      //       </div>
      //     </div>
      //     <div className="d-flex">
      //       <button
      //         className="btn btn-primary w-50 btn__skip-enrol mr-0"
      //         onClick={() => handleEnroll(item.course_id)}
      //       >
      //         Ya
      //       </button>
      //       <button
      //         className=" w-50 btn__close-toast mr-0"
      //         onClick={() => handleComplete("closeToast")}
      //       >
      //         Tidak
      //       </button>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const overlayChecker = (item) => {
    return item.done ? (
      <div className="position-relative parent__check">
        <input
          className="check__done"
          type="checkbox"
          checked={true}
          aria-label="Checkbox for following text input"
          style={{
            width: "0",
            height: "0",
          }}
          readOnly
        />
        <button
          class="checkmark__done"
          // onClick={() => handleComplete("continueToast", item.course_id)}
          disabled={true}
        ></button>
      </div>
    ) : (
      // <OverlayTrigger
      //   overlay={
      //     <Tooltip>
      //       Tandai ceklis ini apabila anda telah mengerti dan ingin melewati{" "}
      //       <b>pelatihan persiapan</b> ini
      //     </Tooltip>
      //   }
      // >
      <div className="position-relative parent__check">
        <input
          className="my__checkbox"
          type="checkbox"
          checked={true}
          aria-label="Checkbox for following text input"
          style={{
            width: "0",
            height: "0",
          }}
          readOnly
        />
        <button
          class="checkmark__done"
          // onClick={() => handleComplete("continueToast", item.course_id)}
          disabled={false}
        ></button>
      </div>
      // </OverlayTrigger>
    );
  };

  return (
    <section className="my-5">
      <Container className="ml-xl-5">
        <div className="pl-0 pr-0 col-xl-8">
          <Col sm={12} className="pl-0 pr-0">
            <h4 className="fw-700">{title}</h4>

            {/* {type === "prereq" && (
              <p className="mt-4 fw-400">
                {data.length} Pelatihan Persiapan | {data.length} Video
                pembelajaran | 1 Ujian Sertifikasi |{" "}
                {convertHours(exam?.total_duration + totalDuration)} total
                durasi
              </p>
            )}
            {type === "chro" && (
              <p className="mt-4 fw-400">
                4 Pelatihan Persiapan | {data.length} Video pembelajaran | 1
                Ujian Sertifikasi |{" "}
                {convertHours(exam?.total_duration + totalDuration)} total
                durasi
              </p>
            )} */}

            <Accordion
              className="mb-0"
              defaultActiveKey="0"
              onSelect={handleSelect}
            >
              <>
                <div className="border position-relative">
                  <div className="information-section__accordion heading__acordion">
                    <Accordion.Toggle
                      className="p-0"
                      as={Button}
                      variant="link"
                      onClick={() => handleShow(0)}
                      eventKey={`${0}`}
                    >
                      <div className="d-block d-md-flex w-100 justify-content-between align-items-center">
                        <div className="d-flex">
                          <div className="mr-2">
                            {activeMenu === 0 ? (
                              <span className="icon-chevronup"></span>
                            ) : (
                              <span className="icon-chevrondown"></span>
                            )}
                          </div>
                          <div>
                            <div className="fw-700">
                              {type === "chro" ? (
                                <>
                                  {type === "chro" &&
                                    "Pelatihan Persiapan Sertifikasi Manajemen Sumber Daya Manusia: Level Staf"}
                                </>
                              ) : type === "magang" ? (
                                "Pelatihan Persiapan Magang & Sertifikasi"
                              ) : (
                                <>
                                  {coId === coIdC ? (
                                    <>
                                      {type === "prereq" &&
                                        "Pelatihan Persiapan Brevet Pajak C"}
                                    </>
                                  ) : (
                                    <>
                                      {type === "prereq" &&
                                        "Pelatihan Persiapan Brevet Pajak AB"}
                                    </>
                                  )}
                                  {coId === coIdC ? (
                                    <>
                                      {type === "pembahasan" &&
                                        "Pembahasan Soal Brevet C"}
                                    </>
                                  ) : (
                                    <>
                                      {type === "pembahasan" &&
                                        "Pembahasan Soal Brevet AB"}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="small-notes mt-0">
                              {type !== "chro" && type !== "magang" && (
                                <>{subtitle}</>
                              )}
                            </div>
                          </div>
                        </div>
                        {type === "chro" ? null : type === "pembahasan" ? (
                          <></>
                        ) : (
                          // <p className="mb-0">
                          //   {data?.length}{" "}
                          //   {type === "prereq"
                          //     ? `Video Pelajaran | ${
                          //         totalDuration
                          //           ? convertHours(totalDuration)
                          //           : "0"
                          //       }`
                          //     : "Video Pembahasan"}
                          // </p>
                          ""
                        )}
                      </div>
                    </Accordion.Toggle>
                  </div>

                  <Accordion.Collapse eventKey={`${0}`}>
                    <div className="container my-3 overflow-auto">
                      <table className="w-100 table">
                        <tbody className="row flex-column flex-nowrap">
                          {data &&
                            data.map((item, index) => (
                              <tr
                                className="w-100 d-flex mb-3 justify-content-between"
                                key={index}
                              >
                                <div className="col-12 col-lg-7">
                                  <th scope="row">{index + 1}.</th>
                                  <td style={{ width: "inherit" }}>
                                    <Link
                                      to={`/course/${slugify(
                                        item.title ? item.title : item.course
                                      )}/${item.course_id}/`}
                                      className="d-block fs-1 desc__prereq"
                                      key={index}
                                      onClick={() => handleShow(index)}
                                    >
                                      {item.title ? item.title : item.course}
                                    </Link>

                                    {isLogin &&
                                      isSubscribe &&
                                      type === "prereq" && (
                                        <div className="wrap__progress mt-1">
                                          <div
                                            className="progress__bar-materi"
                                            style={{
                                              width: `${
                                                item.progress
                                                  ? item.progress
                                                  : "3"
                                              }%`,
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                  </td>
                                </div>
                                <div
                                  style={{ gap: "20px" }}
                                  className={`col-12 col-lg-5 d-flex align-items-center justify ${
                                    isLogin && isSubscribe
                                      ? "justify-content-between"
                                      : "justify-content-end"
                                  }`}
                                >
                                  {/* {!isSubscribe && (
                                    <td className="fw-400">
                                      <p className="fw-600">
                                        {convertHours(item?.total_duration)}
                                      </p>
                                    </td>
                                  )} */}
                                  {isLogin && isSubscribe ? (
                                    <>
                                      <td className="p-0">
                                        {/* {addressURL[1] === "brevet" ? (
                                          type === "prereq" ? (
                                            <div className="fw-600 percentage-progress">
                                              {item.is_completed === 0
                                                ? "0%"
                                                : "100%"}
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        ) : ( */}
                                        {type === "prereq" && (
                                          // <Link
                                          //   to={`/course/${slugify(
                                          //     item.title
                                          //       ? item.title
                                          //       : item.course
                                          //   )}/${item.course_id}/`}
                                          //   className="paremt__btn-detail"
                                          // >
                                          //   <button
                                          //     type="button"
                                          //     className="btn__detail mr-0"
                                          //   >
                                          //     Detail
                                          //   </button>
                                          // </Link>
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip>
                                                Ini adalah persentase (%) dari
                                                progres pelatihan anda.
                                              </Tooltip>
                                            }
                                          >
                                            <p className="mb-0 percentage__progress-acordion">
                                              {item.progress
                                                ? parseInt(
                                                    item.progress
                                                  ).toFixed(0)
                                                : 0}
                                              %
                                            </p>
                                          </OverlayTrigger>
                                        )}
                                        {/* )} */}
                                      </td>
                                      <td className="fw-400 p-0">
                                        <Link
                                          to={`/course/${slugify(
                                            item.title
                                              ? item.title
                                              : item.course
                                          )}/${item.course_id}/`}
                                          className="parent__btn-pembahasan"
                                        >
                                          {item.done && (
                                            <button
                                              type="button"
                                              className="btn btn-secondary mr-0"
                                              disabled
                                            >
                                              Selesai
                                            </button>
                                          )}
                                          {!item.enroll && !item.done && (
                                            <button
                                              type="button"
                                              className="btn__start mr-0"
                                            >
                                              Mulai
                                            </button>
                                          )}

                                          {item.enroll && !item.done && (
                                            <button
                                              type="button"
                                              className="btn__continue mr-0"
                                            >
                                              Lanjutkan
                                            </button>
                                          )}
                                        </Link>
                                      </td>
                                      {type === "prereq" && (
                                        <td className="fw-400 p-0 position-relative">
                                          {overlayChecker(item)}
                                          {courseId === item.course_id &&
                                            showToast(item)}
                                        </td>
                                      )}
                                    </>
                                  ) : // ) : null
                                  null}
                                  {/* {isSubscribe &&
                                  isLogin ? null : item.total_duration ? (
                                    <td className="fw-400">
                                      <p class="fw-600">
                                        {convertHours(item.total_duration)}
                                      </p>
                                    </td>
                                  ) : null}

                                  {type === "prereq" ? (
                                    isLogin ? (
                                      <>
                                        <td className="fw-400 pr-0">
                                          <Link
                                            to={`${`/course/${slugify(
                                              item.title
                                            )}/${item.course_id}`}`}
                                          >
                                            <Button1
                                              title="Detail"
                                              class="btn btn-outline-primary fw-600"
                                            />
                                          </Link>
                                        </td>
                                        <td className="fw-400 pr-0">
                                          <Link
                                            to={`${`/course/${slugify(
                                              item.title
                                            )}/${item.course_id}`}`}
                                          >
                                            {item.done && (
                                              <Button1
                                                title="Selesai"
                                                class={`btn btn-secondary`}
                                              />
                                            )}

                                            {item.enroll && !item.done && (
                                              <Button1
                                                title="Lanjutkan"
                                                class={`btn btn-primary`}
                                              />
                                            )}

                                            {!item.enroll && !item.done && (
                                              <Button1
                                                title="Mulai"
                                                class={`btn btn-success`}
                                              />
                                            )}
                                          </Link>
                                        </td>
                                        <td className="fw-400 position-relative">
                                          <input
                                            class="my__checkbox"
                                            type="checkbox"
                                            checked={item.done ? true : false}
                                            aria-label="Checkbox for following text input"
                                          />
                                        </td>
                                      </>
                                    ) : null
                                  ) : (
                                    <td>
                                      <Button1
                                        class="btn bg-white rounded-3 fw-700 btn__2 p-3 border-0"
                                        title="Mulai Ujian"
                                      />
                                    </td>
                                  )} */}
                                </div>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Accordion.Collapse>
                </div>
              </>
            </Accordion>
            {type === "chro" ? (
              <>
                {exam && isSubscribe && (
                  <Accordion
                    className="mb-0"
                    defaultActiveKey="0"
                    onSelect={handleSelect}
                  >
                    <>
                      <div className="border">
                        <div className="information-section__accordion heading__acordion">
                          <Accordion.Toggle
                            className="p-0"
                            as={Button}
                            variant="link"
                            onClick={() => handleShow(1)}
                            eventKey={`${1}`}
                          >
                            <div className="d-block d-md-flex w-100 justify-content-between align-items-center">
                              <div className="d-flex">
                                <div className="mr-2">
                                  {activeMenu === 1 ? (
                                    <span className="icon-chevronup"></span>
                                  ) : (
                                    <span className="icon-chevrondown"></span>
                                  )}
                                </div>
                                <div className="fw-700">
                                  Asesment Sertifikasi
                                </div>
                              </div>
                            </div>
                          </Accordion.Toggle>
                        </div>

                        <Accordion.Collapse eventKey={`${1}`}>
                          <div className="container my-3 overflow-auto">
                            <table className="w-100 table">
                              <tbody className="row flex-column flex-nowrap">
                                <tr className="w-100 d-flex justify-content-between">
                                  <div className="col-12 col-lg-7 d-flex align-items-center">
                                    <th scope="row">1.</th>
                                    <td style={{ width: "inherit" }}>
                                      <a
                                        onClick={continueCourse}
                                        className="d-block fs-1"
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {exam.title ? exam.title : exam.course}
                                      </a>
                                    </td>
                                  </div>
                                  <div className="col-4 col-lg-5 d-flex justify-content-end">
                                    {/* {isSubscribe && (
                                      <td className="fw-400">
                                        <p className="fw-600">
                                          {convertHours(totalDuration)}
                                        </p>
                                      </td>
                                    )} */}
                                  </div>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </>
                  </Accordion>
                )}
              </>
            ) : type === "magang" ? (
              ""
            ) : (
              <>
                {exam && (
                  <Accordion
                    className="mb-0"
                    defaultActiveKey="0"
                    onSelect={handleSelect}
                  >
                    <>
                      <div className="border">
                        <div className="information-section__accordion heading__acordion">
                          <Accordion.Toggle
                            className="p-0"
                            as={Button}
                            variant="link"
                            onClick={() => handleShow(1)}
                            eventKey={`${1}`}
                          >
                            <div className="d-block d-md-flex w-100 justify-content-between align-items-center">
                              <div className="d-flex">
                                <div className="mr-2">
                                  {activeMenu === 1 ? (
                                    <span className="icon-chevronup"></span>
                                  ) : (
                                    <span className="icon-chevrondown"></span>
                                  )}
                                </div>
                                <div>
                                  <div className="fw-700">
                                    Ujian Sertifikasi
                                  </div>
                                  <div className="small-notes mt-0">
                                    {subtitle}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Toggle>
                        </div>

                        <Accordion.Collapse eventKey={`${1}`}>
                          <div className="container my-3 overflow-auto">
                            <table className="w-100 table">
                              <tbody className="row flex-column flex-nowrap">
                                <tr className="w-100 d-flex justify-content-between">
                                  <div className="col-12 col-lg-7 d-flex align-items-center">
                                    <th scope="row">1.</th>
                                    <td style={{ width: "inherit" }}>
                                      <a
                                        onClick={() =>
                                          continueCourse(disableBtn)
                                        }
                                        className="d-block fs-1"
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {exam.title ? exam.title : exam.course}
                                      </a>
                                    </td>
                                  </div>
                                  <div className="col-4 col-lg-5 d-flex justify-content-end">
                                    {/* {(isLogin || !isLogin) && !isSubscribe && (
                                      <td className="fw-400">
                                        <p className="fw-600">
                                          {convertHours(exam?.total_duration)}
                                        </p>
                                      </td>
                                    )} */}
                                    {isLogin && isSubscribe ? (
                                      // type === "prereq" ? (
                                      <>
                                        <td className="fw-400 pr-0">
                                          {/* <Link
                                            to={`/course/${slugify(
                                              exam.title
                                                ? item.title
                                                : item.course
                                            )}/${item.course_id}/`}
                                          >
                                            <Button1
                                              title="Detail"
                                              class="btn btn-outline-primary fw-600"
                                            />
                                          </Link> */}
                                        </td>
                                        <td className="fw-400 pr-0">
                                          {exam.is_completed !== 0 ? (
                                            <button
                                              className="btn btn-secondary"
                                              disabled
                                            >
                                              Selesai
                                            </button>
                                          ) : null}
                                          {exam.is_enrolled !== 0 &&
                                            exam.is_completed === 0 && (
                                              <div onClick={continueCourse}>
                                                <button className="btn btn-primary">
                                                  Lanjutkan
                                                </button>
                                              </div>
                                            )}
                                          {!exam.is_enrolled &&
                                            !exam.is_completed && (
                                              <div>
                                                <button
                                                  className="btn btn-success mr-0 sss fw-600"
                                                  disabled={disableBtn}
                                                  onClick={continueCourse}
                                                >
                                                  Mulai
                                                </button>
                                              </div>
                                            )}
                                        </td>
                                      </>
                                    ) : null}
                                  </div>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </>
                  </Accordion>
                )}
              </>
            )}
          </Col>
        </div>
      </Container>
    </section>
  );
};

export default MateriAccordion;
