import React, { useEffect, useState } from "react";
import { Button, Modal, Carousel } from "react-bootstrap";
import "../../assets/css/Tutorial/index.css";
import sinarmasLogo from "./../../assets/images/sinarmas_logo.png";
import slide2 from "./../../assets/images/sinarmas_slide2.png";
import slide3 from "./../../assets/images/sinarmas_slide3.png";

function SinarmasPopup({ show, onClose, courseName }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Modal show={show} size="lg" onHide={onClose} backdrop="static">
      <Modal.Header id="tutor-header" closeButton></Modal.Header>
      <Modal.Body className="p-4">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          className="d-flex py-3 min-height text-center"
        >
          <Carousel.Item id="1" className="">
            <div
              className="d-flex"
              style={{ justifyContent: "space-evenly", minHeight: "200px" }}
            >
              <div className="d-flex align-items-center">
                <img
                  src="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/satukelas-logo-1.webp"
                  height={`50px`}
                  width="auto"
                />
              </div>
              <div className="d-flex align-items-center">
                <img src={sinarmasLogo} height={`100px`} width="auto" />
              </div>
            </div>
            <h4 className="tutorial-modal">Selamat Datang di Satukelas!</h4>
            <div style={{ maxWidth: "600px", margin: "auto" }} className="mb-1">
              Kami adalah mitra TechConnect dalam bidang pembelajaran. <br />
              Sebelum mulai belajar, ada baiknya kami memperkenalkan alur
              belajar di Satukelas.
            </div>
            <Button
              onClick={() => {
                setIndex(1);
              }}
            >
              Lanjutkan
            </Button>
          </Carousel.Item>

          <Carousel.Item id="2" className="">
            <div className="d-flex justify-content-center mb-4">
              <img src={slide2} height={`200px`} width="auto" />
            </div>
            <div className="mb-1">
              <h4 className="tutorial-modal">Preview Video Materi</h4>
              <div style={{ maxWidth: "600px", margin: "auto" }}>
                Anda bisa mengecek terlebih dahulu pembelajaran yang tersedia.
                Klik “Pre-Test” untuk mencoba Pre-Test. Klik “Video Materi”,
                untuk menonton 3 video yang kami sajikan gratis sebagai contoh.
              </div>
            </div>
            <Button
              onClick={() => {
                setIndex(2);
              }}
            >
              Lanjutkan
            </Button>
          </Carousel.Item>

          <Carousel.Item id="3" className="">
            <div className="d-flex justify-content-center mb-4">
              <img src={slide3} height={`200px`} width="auto" />
            </div>
            <div className="mb-1">
              <h4 className="tutorial-modal">Konfirmasi Ikuti Pelatihan</h4>
              <div style={{ maxWidth: "600px", margin: "auto" }}>
                Setelah yakin dengan kelas yang ingin diambil, Anda bisa menekan
                tombol ini untuk mengkonfirmasi bahwa Anda mendaftarkan diri
                mengikuti kelas {courseName} dari Satukelas.
              </div>
            </div>
            <Button
              onClick={() => {
                setIndex(3);
              }}
            >
              Lanjutkan
            </Button>
          </Carousel.Item>

          <Carousel.Item id="4" className="">
            <div className="d-flex justify-content-center">
              <div className="placeholder_tutorial3"></div>
            </div>
            <div className="mb-1">
              <h4 className="tutorial-modal">Info Lebih Lanjut</h4>
              <div style={{ maxWidth: "600px", margin: "auto" }}>
                Selesai! Anda sudah mengetahui alur dasar belajar di Satukelas.
                Jika membutuhkan info lebih lanjut, kunjungi tutorial yang kami
                sediakan.
              </div>
            </div>
            {/* <a
              href="https://journal.satukelas.com/techconnect-tutorial"
              target={`_blank`}
              className="btn btn-primary mr-1"
            >
              Lihat Tutorial
            </a> */}
            <Button onClick={onClose}>Mulai Belajar</Button>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

export default SinarmasPopup;
