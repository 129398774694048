import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "../../assets/css/Account/index.css";

function SuccessRegistration() {
  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={6} lg={6}>
          {" "}
          <h1 className="h1 register-step__first-title">
            Terima Kasih Telah Memperkenalkan Diri Anda!
          </h1>
          <ul id="success">
            <li>Cek email anda untuk tautan aktivasi</li>
            <li>Masuk satukelas.com dengan email dan password anda</li>
            <li>Mulai Belajar</li>
          </ul>
          <div className="d-flex justify-content-end">
            <Button
              href="/"
              className="register-next__button position_text__button"
            >
              Halaman Utama
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SuccessRegistration;
