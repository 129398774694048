// sertifikat brevet prod
import { env } from "./constant";

export const courseATPI =
  env === "development"
    ? [
        {
          title: "Sertifikasi: CATT (ATPI)",
          course_id: "co95827008",
          alias: "catt",
        },
        {
          title: "Sertifikasi: CTT (ATPI)",
          course_id: "co58386977",
          alias: "ctt",
        },
        {
          title: "Sertifikasi: CPTT (ATPI)",
          course_id: "co44783890",
          alias: "cptt",
        },
        {
          title: "Sertifikasi: CTA (ATPI)",
          course_id: "co66863295",
          alias: "cta",
        },
      ]
    : [
        {
          title: "Sertifikasi: CATT (ATPI)",
          course_id: "co90920564",
          alias: "catt",
        },
        {
          title: "Sertifikasi: CTT (ATPI)",
          course_id: "co33612595",
          alias: "ctt",
        },
        {
          title: "Sertifikasi: CPTT (ATPI)",
          course_id: "co38526688",
          alias: "cptt",
        },
        {
          title: "Sertifikasi: CTA (ATPI)",
          course_id: "co60008978",
          alias: "cta",
        },
      ];
