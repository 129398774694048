import { satukelasApi } from "../constants";

export const UserService = {
  putUser(user_id, payload) {
    return satukelasApi.put(`/users/${user_id}`, payload);
  },
  getMembership() {
    return satukelasApi.get("/users/memberships");
  },
  getTask() {
    return satukelasApi.get("/users/tasks");
  },
  getWishlist() {
    return satukelasApi.get("/users/wishlist");
  },
  getPayment() {
    return satukelasApi.get("/users/payments");
  },
  getCalendar(queryString = "") {
    return satukelasApi.get(`/users/calendar?${queryString}`);
  },
  getCertificate() {
    return satukelasApi.get("/users/certificates");
  },
  getCart() {
    return satukelasApi.get("/users/cart");
  },
  getCourses() {
    return satukelasApi.get("/users/courses");
  },
  getUserV2(id) {
    return satukelasApi.get(`/v2/users/${id}`);
  },
};
