import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "../../assets/css/Courses/PrerequisitesCourse.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { slugify } from "../../helper/functions";
import { useSelector } from "react-redux";
import check from "./../../assets/images/outlinefill-circle.png";
import moment from "moment";
function PrerequisitesCourse({
  prereq,
  type,
  item,
  enrollCourse,
  isPassedPrereq,
}) {
  const [prereqNew, setPrereqNew] = useState([]);
  const [completeCourse, setCompleteCourse] = useState();
  // const [prereqWebinar, setPrereqWebinar] = useState();

  const users = useSelector((state) => state.users);

  useEffect(() => {
    if (isPassedPrereq?.length > 0) {
      let tmp = isPassedPrereq.map((item) => {
        return item?.length > 0 && item[0]?.status;
      });
      setCompleteCourse(tmp);
    }
  }, [isPassedPrereq]);

  useEffect(() => {
    const preq = prereq;
    if (preq.length > 0) {
      // looping di prasyarat course
      let tmp = [];
      for (let i = 0; i < preq.length; i++) {
        let currPreq = preq[i];
        let status;
        let enroll = true;
        let done = false;

        // compare dengan my webinar
        if (currPreq.course_type.toLowerCase() === "webinar") {
          if (currPreq?.is_enrolled) {
            // user sudah enroll
            if (
              moment().isAfter(
                moment(currPreq.time_ends).format("YYYY-MM-DD HH:mm:ss")
              )
            ) {
              status = "Webinar selesai";
              done = true;
            } else {
              // webinar belum mulai atau sedang berlangsung
              status = "Lihat webinar";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti webinar"
            status = "Ikuti webinar";
            enroll = false;
          }
        }

        if (currPreq.course_type.toLowerCase() === "pelatihan") {
          let flag = false;
          if (currPreq?.is_enrolled) {
            // user sudah enroll
            if (currPreq?.is_completed) {
              status = "Pelatihan selesai";
              done = true;
            } else {
              status = "Lanjutkan pelatihan";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti pelatihan"
            status = "Ikuti pelatihan";
            enroll = false;
          }
        }
        let obj = {
          ...currPreq,
          text: status,
          enroll,
          done,
        };
        tmp.push(obj);
      }
      setPrereqNew(tmp);
    }
  }, [prereq]);
  return (
    <>
      <div className="course_front__prereq__container">
        {prereq?.length > 0 ? (
          <>
            <div id="prereq" className="course_front__kurikulum_title h2">
              Persiapan {type}
            </div>
            <div className="course_front__kurikulum_subtitle">
              Selesaikan kursus dibawah ini untuk melakukan sertifikasi
            </div>
            <div>
              {prereqNew?.length > 0 &&
                prereqNew.map((items, index) => {
                  return (
                    <div
                      key={index}
                      className="course_front__prerequisite_item"
                    >
                      <div className="course_front__prerequisite_img__wrapper mr-3">
                        {items.thumbnail && (
                          <img
                            alt="prerequisite thumbnail"
                            data-src={items.thumbnail}
                            className="lazyload"
                          />
                        )}
                      </div>
                      <div style={{ display: "grid" }}>
                        <a
                          href={`/course/${slugify(items.course)}/${
                            items.course_id
                          }`}
                          target={`_blank`}
                          className={`course_front__prerequisite__course mb-1`}
                        >
                          {items.course}
                        </a>
                        {items.done && (
                          <div className="green-badge__done d-flex">
                            <div className="done-label mr-2">
                              {items.type} Sudah Selesai
                            </div>
                            <div style={{ flexShrink: 0 }}>
                              <img alt="check" src={check} />
                            </div>
                          </div>
                        )}
                        {items.enroll && !items.done && (
                          <Button
                            href={`/course/${slugify(items.course)}/${
                              items.course_id
                            }`}
                            style={{ width: "max-content" }}
                            className="course_front__enroll_btn"
                          >
                            Lanjutkan {items.course_type}
                          </Button>
                        )}
                        {!items.enroll && !items.done && (
                          <Button
                            onClick={() => enrollCourse(items.course_id)}
                            style={{ width: "max-content" }}
                            className="course_front__enroll_btn"
                          >
                            Ikuti {items.course_type}
                          </Button>
                        )}

                        {/* {items.progress && items?.progress?.end_date === undefined &&
                          (
                            <div className="green-badge__done d-flex">
                              <div className="done-label mr-2">{items.type} Sudah Selesai</div>
                              <div style={{ flexShrink: 0 }}>
                                <img alt="check" src={check} />
                              </div>
                            </div>
                          )
                        } */}
                        {/* jika waktu sekarang telah melewati batas akhir waktu webinar */}
                        {/* {moment().isAfter(
                          moment(
                            items.progress?.end_date).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                        ) &&
                          <div className="green-badge__done d-flex">
                            <div className="done-label mr-2">{items.type} Sudah Selesai</div>
                            <div style={{ flexShrink: 0 }}>
                              <img alt="check" src={check} />
                            </div>
                          </div>
                        } */}
                        {/* jika user belum atau sudah mengenroll course dan bukan tipe webinar */}
                        {/* {items.progress === false || items.progress === undefined &&
                          <>
                            {findMyCourse?.includes(items?.course_id) || findMyWebinars?.includes(items?.course_id) ? (
                              <Button
                                href={`/course/${slugify(items.course)}/${items.course_id
                                  }`}
                                style={{ width: "max-content" }}
                                className="course_front__enroll_btn"
                              >
                                Lanjutkan {items.type}
                              </Button>
                            ) : (
                              <Button
                                onClick={() => enrollCourse(items.course_id)}
                                style={{ width: "max-content" }}
                                className="course_front__enroll_btn"
                              >
                                Ikuti {items.type}
                              </Button>
                            )}
                          </>
                        } */}
                      </div>
                    </div>
                  );
                })}
              {/* <Button
                href={
                  item?.title?.toLowerCase().includes("brevet") &&
                  item?.course_type === "Sertifikasi"
                    ? `/certification/brevet-${
                        env === "development"
                          ? slugify(item?.title?.split(":")[1]?.split(" ")[2])
                          : slugify(item?.title?.split(" ")[3])
                      }`
                    : `/bootcamp/list`
                }
                className="ghost-prepare__certificate"
              >
                Pelajari Persiapan {item?.course_type}
              </Button> */}
            </div>
          </>
        ) : (
          <div className="course_front__target_audience__wrapper">
            <div className="text_none__data">Belum tersedia</div>
          </div>
        )}
      </div>
    </>
  );
}
export default PrerequisitesCourse;
