import React from "react";
import LiveWebinar from "./../assets/images/illustrasi/live_webinar.png";
import LiveConsultation from "./../assets/images/illustrasi/live_consultation.png";
import ExamPrepare from "./../assets/images/illustrasi/persiapan_ujian.png";
import tax from "./../assets/images/illustrasi/tax.png";
import tech from "./../assets/images/illustrasi/tech.png";
import softskills from "./../assets/images/illustrasi/softskills.png";
import money from "./../assets/images/illustrasi/money.png";
import market from "./../assets/images/illustrasi/market.png";
import k3 from "./../assets/images/illustrasi/k3.png";
import farouk from "./../assets/images/english/farouk.png";
import hr from "./../assets/images/illustrasi/hr.png";
import english from "./../assets/images/illustrasi/english.png";
import Vod from "./../assets/images/illustrasi/vod.png";
import logo1 from "./../assets/images/client/astra.svg";
import logo2 from "./../assets/images/client/badak.svg";
import logo3 from "./../assets/images/client/bank-china.svg";
import logo4 from "./../assets/images/client/bri-life.svg";
import logo5 from "./../assets/images/client/bumame.svg";
import logo6 from "./../assets/images/client/haliburton.svg";
import logo7 from "./../assets/images/client/izuzu.svg";
import logo8 from "./../assets/images/client/jasamarga.svg";
import logo9 from "./../assets/images/client/jhonson.svg";
import logo10 from "./../assets/images/client/lipo.svg";
import logo11 from "./../assets/images/client/nippon.svg";
import logo12 from "./../assets/images/client/pp.svg";
import logo13 from "./../assets/images/client/sucofindo.svg";
import logo14 from "./../assets/images/client/total.svg";
import logo15 from "./../assets/images/client/mnk.svg";
import logo16 from "./../assets/images/client/bursa.svg";
import carousel1 from "./../assets/images/about/1.jpg";
import carousel2 from "./../assets/images/about/history1.jpg";
import carousel3 from "./../assets/images/about/history2.jpg";
import carousel4 from "./../assets/images/about/history3.jpg";
import carousel5 from "./../assets/images/about/history4.jpg";
import carousel6 from "./../assets/images/about/history5.jpg";
import carousel7 from "./../assets/images/about/history6.jpg";
import carousel8 from "./../assets/images/about/history7.jpg";
import darient from "./../assets/images/home-certificationpage/darient.png";

export const historyCaroueselAboutUs = [
  {
    img: carousel2,
  },
  {
    img: carousel3,
  },
  {
    img: carousel4,
  },
  {
    img: carousel5,
  },
  {
    img: carousel6,
  },
  {
    img: carousel7,
  },
  {
    img: carousel8,
  },
];

export const redirectWa =
  "https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas";

export const carouselAboutUs = [
  {
    img: carousel1,
  },
];

export const company_list = [
  {
    img: logo1,
  },
  {
    img: logo2,
  },
  {
    img: logo3,
  },
  {
    img: logo4,
  },
  {
    img: logo5,
  },
  {
    img: logo6,
  },
  {
    img: logo7,
  },
  {
    img: logo8,
  },
  {
    img: logo9,
  },
  {
    img: logo10,
  },
  {
    img: logo11,
  },
  {
    img: logo12,
  },
  {
    img: logo13,
  },
  {
    img: logo14,
  },
  {
    img: logo15,
  },
  {
    img: logo16,
  },
];

export const learnBootcamp = [
  {
    title: "Critical Thinking",
    list: [
      "Modes of thinking",
      "Critical thinking",
      "Logical thinking",
      "Basics of critical thinking",
      "Element of critical thinking",
      "Characteristic of critical thinking",
      "Critical thinking strategy",
      "Intellectual tools for thinking critically",
      "How to build critical thinking skill",
      "How to improve critical thinking skill",
      "Barries of thinking critically",
    ],
  },
  {
    title: "Analytical Thinking",
    list: [
      "Concept of Analytical Thinking",
      "Analytical Thinking Process",
      "Analytical Thinking Skill List",
      "Analytical Thinking for Solving Problem",
      "Analytical Thinking for Making Decision",
      "Improving Analytical Skill Set",
    ],
  },
  {
    title: "Effective Decision Making",
    list: [
      "Mendefinisikan apa itu masalah",
      "Menentukan akar penyebab masalah",
      "Fishbone diagram dalam mengidentifikasi masalah",
      "Analisis masalah dengan teknik 5 whys",
      "Intuitive decision making",
    ],
  },
  {
    title: "Problem Solving",
    list: [
      "Concept of Creative thinking",
      "Stages of Creative Thinking",
      "Creative Thinking Techniques",
      "Creative Thinking Barrier",
      "How to Improve Creative Thinking Skill",
      "How to Highlight Creative Thinking Skill",
    ],
  },
];

export const faqMembership = [
  {
    title: "Apa itu satukelas?",
    desct:
      "Berdiri pada tahun 2021, satukelas merupakan inisiasi digitalisasi dari Lembaga Manajemen FORMASI yang telah ada sejak 1997. Satukelas menyediakan platform pembelajaran terpadu dengan learning management system(LMS) beserta pengalaman belajar yang disampaikan dalam konten, program, dan sertifikasi.",
  },
  {
    title: "Apa yang ditawarkan satukelas?",
    desct:
      "Berdiri pada tahun 2021, satukelas merupakan inisiasi digitalisasi dari Lembaga Manajemen FORMASI yang telah ada sejak 1997. Satukelas menyediakan platform pembelajaran terpadu dengan learning management system(LMS) beserta pengalaman belajar yang disampaikan dalam konten, program, dan sertifikasi.",
  },
  {
    title: "Apa saja pelatihan yang disediakan oleh satukelas?",
    desct:
      "Berdiri pada tahun 2021, satukelas merupakan inisiasi digitalisasi dari Lembaga Manajemen FORMASI yang telah ada sejak 1997. Satukelas menyediakan platform pembelajaran terpadu dengan learning management system(LMS) beserta pengalaman belajar yang disampaikan dalam konten, program, dan sertifikasi.",
  },
  {
    title: "Siapa saja yang mengajar di pelatihan satukelas?",
    desct:
      "Berdiri pada tahun 2021, satukelas merupakan inisiasi digitalisasi dari Lembaga Manajemen FORMASI yang telah ada sejak 1997. Satukelas menyediakan platform pembelajaran terpadu dengan learning management system(LMS) beserta pengalaman belajar yang disampaikan dalam konten, program, dan sertifikasi.",
  },
];

export const pricingPlan = [
  {
    title: "Bundling Brevet AB & Profesi CTT",
    subtitle: "Sertifikasi Brevet AB & Sertifikasi Profesi CTT",
    price: "Rp1.500.000",
    bundling: [
      "Akses belajar selama 12 Bulan penuh dari dapat di akses kapan saja, di mana saja melalui <b>satukelas.com</b>",
      "Peserta yang lulus ujian dengan nilai minimal 85.01 akan mendapatkan gelar profesi CPTT (Certified Professional Tax Technicial) dari ATPI (Asosiasi Teknisi Perpajakan Indonesia) tanpa tambahan biaya lagi.",
    ],
  },
  {
    title: "Bundling Brevet C & Profesi CPTT",
    subtitle: "Sertifikasi Brevet C & Sertifikasi Profesi CPTT",
    price: "Rp1.500.000",
    bundling: [
      "Bisa pakai Sertifikat Brevet AB dari mana saja. Akses belajar selama 12 bulan penuh dan dapat di akses kapan saja, di mana saja melalui <b>satukelas.com</b>",
      "Peserta yang lulus ujian dengan nilai minimal 85.01 akan mendapatkan gelar profesi CPTT (Certified Professional Tax Technicial) dari ATPI (Asosiasi Teknisi Perpajakan Indonesia) tanpa tambahan biaya lagi.",
    ],
  },
  {
    title: "Bundling Brevet AB + C & Profesi CPTT",
    subtitle: "Sertifikasi Brevet AB + C & Sertifikasi Profesi CPTT",
    price: "Rp2.000.000",
    bundling: [
      "Akses belajar selama 12 bulan penuh dan dapat di akses kapan saja, di mana saja melalui <b>satukelas.com</b>",
      "Peserta yang lulus ujian dengan nilai minimal 85.01 akan mendapatkan gelar profesi CPTT (Certified Professional Tax Technicial) dari ATPI (Asosiasi Teknisi Perpajakan Indonesia) tanpa tambahan biaya lagi.",
    ],
  },
];

export const testimoniUser = [
  {
    people: "Dharma Poniman, Konsultan",
    desc: "Saya senang punya saranan online yang membantu kita bertanya, sambil terus mengikuti pengetahuan mengenai pajak yang sadang berlaku di Indonesia. Selain itu ada pula pengetahuan lain seperti manajemen. Pokoknya berguna deh sembari mengisi waktu luang!",
  },
  {
    people: "Nurul Fitriani, Staff Account Payable Indosat Ooredoo Hutchison",
    desc: "Kita bisa belajar mandiri, terutama bagi karyawan yang terbatas waktunya untuk mengikuti kuliah/seminar/webinar offline. Adminnya juga fast response jika web bermasalah.",
  },
  {
    people: "Ageng, Direktur CV Strategi Selaras Performa",
    desc: "Layanannya online sehingga materi bisa dipelajari kapanpun dan di manapun.CS responsif serta memenuhi komitmen terhadap layanan yang dijanjikan.",
  },
];

export const topic1 = [
  "co84957978",
  "co69723503",
  "co38160750",
  "co74876908",
  "co72690388",
  "co67229659",
  "co15684189",
  "co29100474",
  "co68120960",
  "co65493834",
  "co51959964",
  "co34089932",
  "co27328700",
  "co73964713",
];
export const topic2 = ["co78219900"];
export const topic3 = [
  "co85781046",
  "co93867418",
  "co14147138",
  "co67699606",
  "co61773656",
  "co53882242",
  "co37626187",
  "co21023381",
  "co76595591",
  "co53201925",
  "co78909856",
  "co92572258",
  "co43856702",
  "co58904463",
];
export const topic4 = ["co74744654"];
export const defaultThumb =
  "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/7224175345683032.png";
export const serviceMembership = [
  {
    title: "Kelengkapan Learning Platform",
    button: "Mulai Belajar!",
    desc: "Nikmati kenyamanan belajar dengan metode yang interaktif, konten ter-update serta sertifikasi yang diakui lembaga resmi.",
  },
  {
    title: "Program Pelatihan Bervariasi",
    button: "Lihat Program",
    desc: "Pilih program pelatihan yang tepat untuk memenuhi kebutuhan Anda seperti <b>sertifikasi profesi.</b>",
  },
  {
    title: "Tersedia User Dashboard",
    button: "Cek Fitur User Dashboard",
    desc: "Pantau riwayat pembelajaran melalui <b>user dashboard</b> untuk mengukur keberhasilan belajar Anda di satukelas.",
  },
];

export const faqUser = [
  {
    title: "Apa perbedaan bootcamp dengan webinar reguler satukelas?",
    desc: "Jika mengikuti program Bootcamp, Anda akan mendapatkan rangkaian kelas atau webinar yang berkelanjutan berisikan materi yang lengkap dan juga akan diberikan tugas yang bisa dijadikan portfolio bagi peserta. Pada akhir kegiatan, peserta akan mendapatkan report individu. Sedangkan, tidak ada penugassan dan pemberian report jika anda mengikuti webinar reguler satukelas.",
  },
  {
    title: "Bagaimana jika saya tidak dapat menghadiri sesi pembelajaran?",
    desc: "Anda akan mendapatkan video recording dari sesi tersebut dan tetap mendapat sertifikat",
  },
  {
    title: "Bagaimana bentuk tugas yang akan diberikan?",
    desc: "Tugas yang diberikan dalam bertuk pre-tes, post-tes dan assigment di setiap akhir sesi webinar bootcamp",
  },
  {
    title:
      "Setelah saya melakukan pembayaran/menyelesaikan registrasi, apa langkah selanjutnya?",
    desc: "Anda akan bergabung ke grup yang digunakan sebagai pusat komunikasi kegiatan Bootcamp.",
  },
  {
    title: "Mengapa saya harus mempelajari program Soft Skill?",
    desc: "Dalam suatu organisasi dan lingkungan sosial sering terjadi permasalahan yang disebabkan kurangnya ketajaman pola pikir dan keputusan dalam bertindak. Belajar soft skill mampu menjawab permasalahan ini, karena di dalamnya banyak hal terkait yang akan dipelajari seperti, Critical Thinking, Analytical Thinking, Problem Solving, dan Effective Decision Making. Kemampuan ini pun nantinya akan berguna di kehidupan sehari-hari dan dunia kerja.",
  },
];

// endpoint

export const env = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : "development";

export const membershipIdEnglish =
  env === "development" ? "ca62605584" : "ca55895679";

export const membershipIdAll =
  env === "development" ? ["ca62605584", "ca50884771"] : "ca92553658";

// export const membership1Year = env === "development" ?  :;

export const ENDPOINT =
  env === "development"
    ? "https://api.satukelas.space/api/"
    : "https://api.satukelas.com/api/";

// array[brevet ab, brevet c]
export const brevet =
  env === "development"
    ? ["co92113052", "co34028467"]
    : ["co73258264", "co97744206"];
export const WEB_URL =
  env === "development"
    ? "https://student.satukelas.space/"
    : "https://student.satukelas.com/";
export const WEBINAR_URL =
  env === "development"
    ? "https://webinar.satukelas.space/"
    : "https://webinar.satukelas.com/";
export const KONEKETEMU_URL =
  env === "development"
    ? "https://meet.koneketemu.com/"
    : "https://meet.koneketemu.com/";
export const DM_COURSE_ID = env === "development" ? "co72717771" : "co56758937";
export const DM_COURSE_ID_POST =
  env === "development" ? "co79836724" : "co56758937";

export const MOU_LINK =
  env === "development"
    ? "https://satukelas-assignments.ap-south-1.linodeobjects.com/us79215453_3034650069662987.docx"
    : "https://satukelas-assignments.ap-south-1.linodeobjects.com/us10000001_3034650069662987.docx";

export const TYPEFORM_LINK =
  env === "development"
    ? "https://o6t1zfhvt35.typeform.com/to/jMwfnORv"
    : "https://o6t1zfhvt35.typeform.com/to/vbGcAscJ";

export const REGISTER_CAMPUSS_BNSP_LINK =
  env === "development"
    ? "https://o6t1zfhvt35.typeform.com/to/VK5qfORV"
    : "https://o6t1zfhvt35.typeform.com/to/VK5qfORV";

// registrasi
export const occupation = [
  { occup: "Pegawai Negeri", value: "pegawainegeri" },
  { occup: "Karyawan Swasta", value: "karyawanswasta" },
  { occup: "Magang", value: "magang" },
  { occup: "Freelance", value: "freelance" },
  { occup: "Entrepreneur", value: "entrepreneur" },
  { occup: "Mahasiswa / Pelajar", value: "mahasiswa" },
  { occup: "Fresh Graduate", value: "freshgraduate" },
  { occup: "Lainnya", value: "lainnya" },
];

export const division_name = [
  { divisi_name: "Administrasi", value: "administrasi" },
  { divisi_name: "Audit", value: "audit" },
  { divisi_name: "Corporate Secretary", value: "corporatesecretary" },
  { divisi_name: "Hubungan Masyarakat", value: "hubunganmasyarakat" },
  { divisi_name: "IT", value: "it" },
  { divisi_name: "Legal", value: "legal" },
  { divisi_name: "Keuangan dan Perpajakan", value: "keuangandanperpajakan" },
  { divisi_name: "K3LH", value: "k3lh" },
  { divisi_name: "Pemasaran", value: "pemasaran" },
  { divisi_name: "Penelitian dan Pengembangan (Litbang)", value: "litbang" },
  { divisi_name: "Pengadaan", value: "pengadaan" },
  { divisi_name: "Pengembangan Usaha", value: "pengembanganusaha" },
  { divisi_name: "Penjualan", value: "penjualan" },
  { divisi_name: "Pergudangan", value: "pergudangan" },
  { divisi_name: "Produksi", value: "produksi" },
  { divisi_name: "Sumber Daya Manusia (HRD)", value: "hrd" },
  { divisi_name: "Sistem Mutu", value: "sistemmutu" },
  { divisi_name: "Sistem Manajemen Informasi", value: "manajemeninformasi" },
  { divisi_name: "Umum", value: "umum" },
];

// export const carousel = [
//   {
//     title: "Makin Kompeten dengan Ikuti Bootcamp Soft Skills",
//     sub: "Daftarkan diri Anda dalam Program Bootcamp Soft Skill untuk mengasah pola pikir dan tingkatkan kompetensi dalam dunia kerja.",
//     link: "bootcamp",
//     cta: "Pelajari Bootcamp",
//     pict: "hero-shot4"
//   }
// ]

export const months = [
  { month: "Januari", date: "31", list: "01" },
  { month: "Febuari", date: "28", list: "02" },
  { month: "Maret", date: "31", list: "03" },
  { month: "April", date: "30", list: "04" },
  { month: "Mei", date: "31", list: "05" },
  { month: "Juni", date: "30", list: "06" },
  { month: "Juli", date: "31", list: "07" },
  { month: "Agustus", date: "31", list: "08" },
  { month: "September", date: "30", list: "09" },
  { month: "Oktober", date: "31", list: "10" },
  { month: "November", date: "30", list: "11" },
  { month: "Desember", date: "31", list: "12" },
];

export const educational = [
  { education: "SD" },
  { education: "SMP" },
  { education: "SMA/ SMK" },
  { education: "D3" },
  { education: "S1/D4/Setara" },
  { education: "S2" },
  { education: "S3" },
];

// blog
export const blog = [
  {
    id: "0",
    slug: "6-jenis-training-yang-wajib-diberikan-untuk-karyawan",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_2879699581269448.jpg",
    title: "6 Jenis Training yang Wajib Diberikan untuk Karyawan",
    desc: "Penerapan training membuat karyawan memiliki skill yang memang sesuai dengan kebutuhan perusahaan. Maka dari itu, training sangat penting bagi karyawan. Selain itu, beberapa jenis training dasar juga membantu karyawan untuk memahami job desc mereka, sehingga produktivitas kerja pun lebih mudah tercapai. Dari sisi perusahaan, training pun memiliki manfaat tersendiri. Karyawan yang terampil setelah mengikuti training akan lebih produktif sehingga target perusahaan pun lebih mudah dijangkau. Nah, bagi Anda pemilik perusahaan yang ingin mengadakan pelatihan untuk karyawan, berikut adalah beberapa jenis training yang wajib diberikan kepada karyawan.",
  },
  {
    id: "1",
    slug: "5-cara-meningkatkan-efektivitas-kerja-pada-era-digital",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_3083346495631938.jpg",
    title: "5 Cara Meningkatkan Efektivitas Kerja pada Era Digital",
    desc: "Perkembangan teknologi di era digital mengharuskan kita untuk terus beradaptasi. Beberapa hal yang sebelumnya dikerjakan secara manual kini bisa dilakukan secara otomatis menggunakan mesin atau sistem. Agar kinerja tetap efektif, Anda pun harus mengubah cara kerja. Melakukan perubahan jelas bukan hal mudah, namun bukan berarti tidak bisa dipelajari sama sekali. Untuk membantu perusahaan Anda menyesuaikan diri dengan situasi terkini, berikut lima cara meningkatkan efektivitas kerja pada era digital.",
  },
  {
    id: "2",
    slug: "pandemi-covid-19-mengubah-masa-depan-tenaga-kerja-indonesia",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_2930799511323688.jpg",
    title: "Pandemi COVID-19 Mengubah Masa Depan Tenaga Kerja Indonesia",
    desc: "Tanpa disadari, pandemi COVID-19 telah mengubah masa depan tenaga kerja Indonesia. Adanya pembatasan aktivitas manusia membuat perusahaan harus memutar otak untuk bisa tetap berproduksi. Pada akhirnya, pemanfaatan teknologi pun dijadikan solusi. Sayangnya, solusi tersebut berdampak kurang baik terhadap tenaga kerja. Permintaan terhadap tenaga kerja Indonesia pun menurun karena sudah tergantikan oleh mesin dan sistem digital. Meski begitu, bukan berarti tidak ada cara bagi tenaga kerja untuk bisa bertahan dalam situasi seperti sekarang. Bagaimana cara tenaga kerja Indonesia agar tetap relevan di tengah perubahan seperti sekarang?",
  },
];

export const blog_detail = [
  {
    id: "0",
    src_img: "www.rawpixel.com",
    date: "16 Desember 2021",
    writer: "Admin",
    slug: "6-jenis-training-yang-wajib-diberikan-untuk-karyawan",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_2879699581269448.jpg",
    title: "6 Jenis Training yang Wajib Diberikan untuk Karyawan",
    article: {
      first_paragraph:
        "Penerapan training membuat karyawan memiliki skill yang memang sesuai dengan kebutuhan perusahaan. Maka dari itu, training sangat penting bagi karyawan. Selain itu, beberapa jenis training dasar juga membantu karyawan untuk memahami job desc mereka, sehingga produktivitas kerja pun lebih mudah tercapai. Dari sisi perusahaan, training pun memiliki manfaat tersendiri. Karyawan yang terampil setelah mengikuti training akan lebih produktif sehingga target perusahaan pun lebih mudah dijangkau. Nah, bagi Anda pemilik perusahaan yang ingin mengadakan pelatihan untuk karyawan, berikut adalah beberapa jenis training yang wajib diberikan kepada karyawan.",
      list_paragraph: [
        {
          title_paragraph: "Training orientasi perusahaan",
          detail_paragraph: {
            detail_first_paragraph:
              "Training orientasi perusahaan adalah jenis training yang paling umum diterapkan di perusahaan. Biasanya, training ini dilakukan setelah perusahaan mengadakan rekrutmen karyawan baru. Pesertanya sudah jelas para karyawan yang baru direkrut. Tujuan dari training orientasi perusahaan adalah untuk mengenalkan karyawan baru kepada lingkungan kerja mereka. Biasanya, materi pelatihan berisi penjelasan terkait visi serta misi perusahaan, budaya perusahaan, SOP kerja, hingga aturan dan kebijakan yang diterapkan di perusahaan.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Training product knowledge",
          detail_paragraph: {
            detail_first_paragraph:
              "Berikutnya ada training product knowledge. Sama seperti training orientasi perusahaan, pelatihan ini pun sangat umum ditemukan di perusahaan. Biasanya, pelatihan ini diadakan saat perusahaan akan meluncurkan produk baru ke pasaran. Tujuan dari pelatihan product knowledge adalah agar karyawan bisa memahami produk yang akan diluncurkan dengan baik. Namun, ada kalanya training ini juga diberikan kepada karyawan baru. Untuk karyawan baru, pelatihan ini bertujuan untuk mengenalkan seluruh produk bisnis yang dikeluarkan oleh perusahaan. Dengan begitu, karyawan bisa benar-benar mengenal produk yang akan mereka jual nantinya.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Training manajerial",
          detail_paragraph: {
            detail_first_paragraph:
              "Jenis training wajib selanjutnya adalah training manajerial. Pelatihan yang satu ini menitikberatkan pada peningkatan soft skill, terutama hal-hal yang berkaitan dengan sifat dan cara kerja karyawan. Biasanya, training manajerial diberikan secara berkala agar performa karyawan tetap optimal. Banyak perusahaan yang menjadikan training manajerial sebagai agenda tahunan. Mengapa training manajerial penting? Jenis training yang satu ini melatih soft skill karyawan. Soft skill sendiri besar pengaruhnya pada produktivitas kerja karyawan. Saat produktivitas karyawan bagus, maka otomatis performa perusahaan pun ikut bagus.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Training skill dan kreativitas",
          detail_paragraph: {
            detail_first_paragraph:
              "Berikutnya ada training skill dan kreativitas. Sama seperti namanya, pelatihan ini membantu karyawan untuk mengembangkan skill serta kreativitas mereka. Apa saja jenis skill yang diajarkan dalam training ini? Jawabannya bergantung pada kebutuhan perusahaan. Misalnya, untuk perusahaan penyedia jasa penulisan, maka training skill yang diberikan bisa berupa pelatihan menulis. Dalam training model ini, biasanya karyawan akan diberi studi kasus untuk dipelajari. Cara ini akan memancing karyawan untuk berpikir kreatif dan out of the box. Karyawan diajak untuk berpikir di luar kebiasaan mereka sebelumnya. Agar lebih optimal, biasanya training skill dan kreativitas ini dilakukan per departemen.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Cross functional training",
          detail_paragraph: {
            detail_first_paragraph:
              "Cross functional training adalah pelatihan yang mengajak karyawan untuk mencoba tugas tertentu di luar bidang pekerjaan mereka. Biasanya, cross functional diterapkan pada perusahaan berskala kecil dengan total karyawan tak lebih dari 25 orang. Training ini penting guna memberikan pemahaman tentang sistem kerja perusahaan yang menyeluruh kepada karyawan. Misalnya, karyawan bidang penjualan diminta untuk membantu bagian keuangan. Usai training, karyawan bidang penjualan tersebut bisa mengetahui bagaimana departemen keuangan perusahaan bekerja.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Retraining",
          detail_paragraph: {
            detail_first_paragraph:
              "Jenis training yang terakhir adalah retraining. Mengapa retraining wajib diberikan kepada karyawan? Retraining bertujuan untuk memperbarui pengetahuan dan skill yang dimiliki karyawan di perusahaan. Seperti yang telah Anda ketahui, zaman sekarang berubah dengan cepat. Isu-isu yang berhubungan dengan perusahaan pun pasti bersifat dinamis. Dengan retraining, maka karyawan bisa menyesuaikan kemampuan mereka dengan situasi dan kondisi terbaru sehingga kinerja mereka tetap optimal.",
            list_first_paragraph: [],
          },
        },
      ],
      last_paragraph:
        "Dari enam jenis training yang dibahas dalam artikel ini, mana yang sudah Anda terapkan? Jika Anda kesulitan untuk melaksanakan training konvensional, metode training digital bisa dicoba. Melalui platform digital learning satukelas, Anda dapat menemukan beragam training digital yang bisa diterapkan di perusahaan. Gabung segera bersama satukelas dengan klik di sini!",
    },
  },
  {
    id: "1",
    src_img: "www.rawpixel.com",
    date: "16 Desember 2021",
    writer: "Admin",
    slug: "5-cara-meningkatkan-efektivitas-kerja-pada-era-digital",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_3083346495631938.jpg",
    title: "5 Cara Meningkatkan Efektivitas Kerja pada Era Digital",
    article: {
      first_paragraph:
        "Perkembangan teknologi di era digital mengharuskan kita untuk terus beradaptasi. Beberapa hal yang sebelumnya dikerjakan secara manual kini bisa dilakukan secara otomatis menggunakan mesin atau sistem. Agar kinerja tetap efektif, Anda pun harus mengubah cara kerja. Melakukan perubahan jelas bukan hal mudah, namun bukan berarti tidak bisa dipelajari sama sekali. Untuk membantu perusahaan Anda menyesuaikan diri dengan situasi terkini, berikut lima cara meningkatkan efektivitas kerja pada era digital.",
      list_paragraph: [
        {
          title_paragraph: "Harus ada SOP kerja yang jelas",
          detail_paragraph: {
            detail_first_paragraph:
              "Salah satu cara meningkatkan efektivitas kerja yang adalah dengan menentukan SOP (standard operational procedure). Pastikan perusahaan Anda memiliki SOP yang mengatur workload dan workflow karyawan. Apa hubungan antara SOP dengan efektivitas kerja karyawan pada era digital? Jika diibaratkan, SOP adalah buku panduan bagi karyawan. Dengan adanya SOP, maka karyawan bisa tahu apa saja tugas yang harus dikerjakan, target yang harus dicapai, dan ketentuan lain yang harus mereka penuhi. SOP akan membantu karyawan untuk mengatur strategi dalam mencapai target yang ditetapkan perusahaan. Adanya target yang jelas serta strategi untuk mencapainya akan membuat kinerja karyawan lebih teratur dan terarah.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Mulai biasakan sistem automasi",
          detail_paragraph: {
            detail_first_paragraph:
              "Era digital tidak selamanya harus dilihat sebagai hal negatif. Justru sebaliknya, ada banyak manfaat yang bisa Anda gali dari perkembangan era digital. Salah satunya adalah kehadiran sistem automasi yang bisa memudahkan pekerjaan karyawan. Contoh paling sederhana adalah transisi media penyimpanan. Jika dahulu perusahaan perlu menyediakan media penyimpanan eksternal untuk menyimpan data-data perusahaan, kini Anda bisa beralih pada komputasi awan atau cloud. Penerapan cloud untuk menyimpan data perusahaan ini bukan hanya praktis, tapi juga hemat. Cloud, atau komputasi awan, adalah metode penyimpanan berbagai data atau layanan secara digital. Istilah “cloud” sendiri dipakai sebagai metafora dari internet. Selain itu, sistem automasi juga bisa membantu meningkatkan keamanan perusahaan. Hal ini bisa dilihat dari penggunaan metode biometrik sebagai absensi perusahaan. Selain bisa menjadi cara meningkatkan efektivitas kerja karyawan karena memerlukan waktu yang lebih singkat, biometrik juga lebih aman karena sulit dipalsukan.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Kolaborasi menggunakan teknologi",
          detail_paragraph: {
            detail_first_paragraph:
              "Kolaborasi merupakan kunci utama untuk meningkatkan efektivitas kerja di era digital. Dengan kolaborasi, maka proyek berskala besar bisa dikerjakan dalam waktu yang lebih singkat. Terlebih, saat ini kolaborasi sangat mudah dilakukan. Anda bisa menggunakan software khusus kolaborasi atau software project management untuk mempermudah kolaborasi, sehingga jarak pun tak lagi jadi masalah. Kolaborasi pun tidak hanya terbatas untuk lingkungan internal perusahaan, tapi juga dengan pihak ketiga sehingga jangkauan Anda pun jadi lebih luas.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Terus tingkatkan pengetahuan",
          detail_paragraph: {
            detail_first_paragraph:
              "Cara meningkatkan efektivitas kerja berikutnya adalah dengan meningkatkan pengetahuan. Tidak pernah ada kata terlambat untuk belajar skill baru. Di era digital seperti sekarang ini, ada banyak sekali ilmu baru yang bisa dipelajari untuk meningkatkan efektivitas kerja. Anda bisa mengikuti webinar melalui satukelas. Di satukelas, Anda bisa menemukan berbagai ilmu yang terkait dengan era digital dan pengembangan diri. Nantinya, ilmu yang didapat bisa diterapkan untuk meningkatkan efektivitas kerja.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Sebaiknya hindari multitasking",
          detail_paragraph: {
            detail_first_paragraph:
              "Tips terakhir dalam ulasan mengenai cara meningkatkan efektivitas kerja ini adalah menghindari multitasking. Mengapa multitasking sebaiknya dihindari? Era digital dengan dinamisme tinggi kerap menuntut kita untuk bekerja dengan cepat. Tanpa disadari, kita mengerjakan beberapa hal dalam satu waktu sekaligus. Inilah yang kemudian disebut dengan multitasking. Jika dilihat sekilas, multitasking membuat beberapa pekerjaan selesai dalam satu waktu. Namun, sebaiknya hal ini tidak dilakukan terus-menerus karena multitasking bisa mengganggu kinerja otak. Saat Anda bekerja multitasking, ada banyak sekali memori jangka pendek yang digunakan. Padahal, memori jangka pendek sangat dibutuhkan dalam hal berpikir kreatif. Multitasking yang dilakukan dalam jangka waktu lama akan menurunkan kemampuan Anda dalam menemukan pemecahan masalah.",
            list_first_paragraph: [],
          },
        },
      ],
      last_paragraph:
        "Demikian pembahasan mengenai cara meningkatkan efektivitas kerja pada era digital seperti sekarang ini. Cara terbaik untuk bisa bekerja efektif adalah dengan terus meningkatkan kemampuan diri. Anda bisa meningkatkan kemampuan diri dengan mengikuti kelas online di satuselas. Belajar di satukelas menawarkan beragam kelas menarik untuk upgrade skill Anda. Daftar segera di sini!",
    },
  },
  {
    id: "2",
    src_img: "www.rawpixel.com",
    date: "16 Desember 2021",
    writer: "Admin",
    slug: "pandemi-covid-19-mengubah-masa-depan-tenaga-kerja-indonesia",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_2930799511323688.jpg",
    title: "Pandemi COVID-19 Mengubah Masa Depan Tenaga Kerja Indonesia",
    article: {
      first_paragraph:
        "Tanpa disadari, pandemi COVID-19 telah mengubah masa depan tenaga kerja Indonesia. Adanya pembatasan aktivitas manusia membuat perusahaan harus memutar otak untuk bisa tetap berproduksi. Pada akhirnya, pemanfaatan teknologi pun dijadikan solusi. Sayangnya, solusi tersebut berdampak kurang baik terhadap tenaga kerja. Permintaan terhadap tenaga kerja Indonesia pun menurun karena sudah tergantikan oleh mesin dan sistem digital. Meski begitu, bukan berarti tidak ada cara bagi tenaga kerja untuk bisa bertahan dalam situasi seperti sekarang. Bagaimana cara tenaga kerja Indonesia agar tetap relevan di tengah perubahan seperti sekarang?",
      list_paragraph: [
        {
          title_paragraph:
            "Pandemi COVID-19 mempercepat disrupsi teknologi pada sektor ketenagakerjaan",
          detail_paragraph: {
            detail_first_paragraph:
              "Adanya pandemi COVID-19 ternyata mendorong terjadinya disrupsi teknologi pada sektor ketenagakerjaan. Pembatasan aktivitas manusia membuat perusahaan menyadari bahwa teknologi bisa membantu menyelesaikan beberapa pekerjaan yang sebelumnya dikerjakan oleh manusia. Akhirnya, perusahaan pun semakin yakin untuk beralih pada teknologi dalam menjalankan proses produksi mereka. Sebaliknya, tenaga kerja manusia dianggap kurang relevan dengan kondisi sekarang yang serba digital.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Jutaan pekerjaan terancam digantikan oleh mesin",
          detail_paragraph: {
            detail_first_paragraph:
              "Dari sisi perusahaan, hal tersebut tentu menjadi solusi yang menguntungkan karena bisa menemukan alternatif pengganti yang lebih efisien. Namun, bagaimana dari sisi tenaga kerja Indonesia? Jika dilihat dari sisi tenaga kerja, pemanfaatan teknologi tentu akan menimbulkan dampak yang kurang baik. Ini karena penggunaan mesin dan teknologi lainnya akan menggeser tenaga manusia.            Akhirnya, manusia pun tersisihkan oleh mesin dan permintaan akan SDM jadi semakin menurun. Apabila kondisi ini dibiarkan, maka angka pengangguran negara akan meroket. Lalu, apa yang harus dilakukan?",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph:
            "Meningkatnya kebutuhan tenaga kerja di bidang digital",
          detail_paragraph: {
            detail_first_paragraph:
              "Meski pemanfaatan teknologi menggusur tenaga manusia, bukan berarti kondisi tersebut tidak membuka peluang baru. Misalnya, sebuah mesin produksi pasti memerlukan tenaga untuk melakukan instalasi, pengoperasian, dan juga perawatan. Padahal, posisi tersebut sebelumnya tidak ada. Selain itu, ada pula banyak sektor yang membutuhkan tenaga kerja manusia. Salah satunya adalah sektor digital. Data dari survei yang dilakukan World Economic Forum (WEF) bahkan menemukan bahwa ada 5 lima posisi kerja di bidang digital yang permintaannya tinggi di masa pandemi ini, yaitu: Data analyst dan data scientists, Spesialis big data, Digital marketing dan marketing strategy specialist, Spesialis kecerdasan buatan (artificial intelligence) dan machine learning, Insinyur bidang energi baru terbarukan (EBT). Kementerian Ketenagakerjaan (Kemnaker) juga melakukan survei serupa dan menemukan hasil yang tidak berbeda jauh. Dari survei Kemnaker, diketahui bahwa tiga jenis pekerjaan yang paling dibutuhkan di masa pandemi adalah profesional penjualan, pekerja penjualan, serta teknisi operasi TIK (teknologi informasi dan komunikasi). Jenis pekerjaan yang disebutkan di atas merupakan posisi kerja yang sebelumnya kurang begitu diperhitungkan. Namun, di masa pandemi dan selepas pandemi nanti justru permintaannya akan meningkat pada pasar tenaga kerja. Hal ini masih erat kaitannya dengan digitalisasi yang mulai diterapkan perusahaan sekarang.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Ketersediaan sumber daya belum mencukupi",
          detail_paragraph: {
            detail_first_paragraph:
              "Perubahan situasi tersebut tanpa disangka menimbulkan permasalahan baru, yaitu minimnya ketersediaan sumber daya manusia (SDM) yang kompeten dengan bidang kerja. Saat ini, masih belum banyak tenaga kerja Indonesia yang menguasai bidang digital, terlebih bidang digital yang sedang banyak dicari. Bagi tenaga kerja, hal ini bisa menjadi peluang bagus. Sekarang adalah waktu yang paling tepat untuk mengembangkan skill Anda. Coba perkaya curriculum vitae (CV) Anda dengan skill digital yang mumpuni. Tenang saja, skill digital bisa dipelajari dan tidak sulit untuk dipahami. Anda bisa mengikuti kelas pelatihan dari satukelas. Di satukelas, Anda bisa mendapatkan materi yang berhubungan dengan dunia digital. Seluruh pelatihan di satukelas diberikan oleh tutor dan instruktur berkualitas yang telah berpengalaman. Selain itu, tersedia juga kelas sertifikasi untuk upgrade kemampuan Anda.",
            list_first_paragraph: [],
          },
        },
      ],
      last_paragraph:
        "Pandemi COVID-19 tanpa disadari telah mengubah banyak hal, salah satunya adalah masa depan tenaga kerja Indonesia. Kondisi ini mendorong perusahaan melakukan digitalisasi yang membuat tenaga manusia tergusur. Meski begitu, bukan berarti peluang Anda tertutup. Anda masih bisa memperkaya diri dengan skill digital untuk bisa bersaing dalam pasar tenaga kerja. Update skill digital Anda bersama satukelas sekarang juga dengan klik di sini!",
    },
  },
  {
    id: "3",
    src_img: "www.rawpixel.com",
    date: "16 Desember 2021",
    writer: "Admin",
    slug: "learning-agility-kunci-utama-kesuksesan-pada-era-digital",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_2028951528719356.jpg",
    title: "Learning Agility: Kunci Utama Kesuksesan pada Era Digital",
    article: {
      first_paragraph:
        "Apa yang dimaksud dengan learning agility? Di era digital yang sangat dinamis seperti sekarang ini, mau tak mau Anda harus bisa menyesuaikan diri agar tidak tertinggal, termasuk dalam hal kompetensi kerja. Tidak menutup kemungkinan bahwa kompetensi yang Anda miliki saat ini tidak lagi relevan di masa depan. Di sinilah learning agility berperan. Secara sederhana, learning agility bisa dipahami sebagai kemampuan untuk bisa terus belajar meski dalam situasi yang terus berubah. Mari simak penjelasan selengkapnya di bawah ini.",
      list_paragraph: [
        {
          title_paragraph: "Apa itu learning agility?",
          detail_paragraph: {
            detail_first_paragraph:
              "Learning agility merupakan sebuah kemampuan untuk bisa terus belajar dalam situasi yang berubah-ubah. Tidak sekadar belajar, tapi juga mengetahui apa yang harus dipelajari dan apa yang harus dihindari. Bisa dipahami juga bahwa kemampuan ini berarti belajar dari pengalaman lalu dan kemudian menerapkannya dengan cara baru agar dapat beradaptasi dengan situasi serta kesempatan baru. Seseorang yang memiliki keterampilan learning agility tahu apa yang mestinya dilakukan dan tidak saat berhadapan dengan situasi baru. Meski terlihat sepele, hal ini hanya mungkin dilakukan jika Anda memiliki pengalaman pada suatu situasi serupa. Namun, learning ability sebenarnya bisa dilatih dan diasah.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Dimensi penting learning agility",
          detail_paragraph: {
            detail_first_paragraph:
              "Menurut profesor Psikologi dan Pendidikan Columbia University, W. Warner Burke, learning agility memiliki sembilan dimensi penting, yaitu:",
            list_first_paragraph: [
              {
                title_list: "Flexibility",
                desc_list:
                  "Flexibility di sini berarti kemampuan untuk bersikap luwes dan terbuka terhadap gagasan dan situasi baru. Dengan membiasakan diri terhadap gagasan dan situasi baru, maka Anda bisa memberikan solusi yang tidak hanya solutif, tapi juga adaptif saat diharuskan memecahkan masalah. Flexibility juga mendorong Anda untuk terus menyesuaikan diri dengan tiap perubahan yang terjadi di sekitar.",
              },
              {
                title_list: "Experimenting",
                desc_list:
                  " Dimensi learning agility berikutnya adalah experimenting. Apa yang dimaksud dengan experimenting di sini adalah kemampuan untuk tidak ragu dalam mencoba gagasan, perilaku, serta alternatif baru. Dengan experimenting, maka Anda bisa segera tahu mana pendekatan yang paling sesuai untuk memecahkan suatu permasalahan. Di samping itu, experimenting juga akan membantu Anda untuk menyerap lebih banyak ilmu baru.",
              },
              {
                title_list: "Speed",
                desc_list:
                  " Selanjutnya ada speed atau kecepatan. Seperti yang telah Anda ketahui, perubahan zaman terjadi begitu cepat. Untuk bisa tetap bertahan, maka Anda juga harus bertindak cepat dalam menghadapi perubahan situasi. Mengapa kecepatan diperlukan? Dengan bertindak cepat dan tepat, maka peluang untuk mencapai alternatif baru pun semakin terbuka lebar.",
              },
              {
                title_list: "Collaborating",
                desc_list:
                  " Learning agility tidak melulu soal diri sendiri. Justru sebaliknya, kemampuan untuk bisa belajar dalam segala situasi akan lebih mudah dicapai jika Anda bekerja sama dengan orang lain. Melakukan kerja sama atau kolaborasi dengan orang lain akan melatih Anda untuk berhadapan dengan sudut pandang yang berbeda. Dengan mengetahui banyak sudut pandang, Anda bisa lebih mudah menyelesaikan masalah karena tidak terpaku pada satu cara penyelesaian saja.",
              },
              {
                title_list: "Performance risk-taking",
                desc_list:
                  " Dimensi selanjutnya adalah performance risk-taking. Dimensi yang satu ini berkaitan dengan keberanian untuk mencoba beragam aktivitas baru. Dengan melakukan aktivitas baru, maka Anda pun akan semakin dekat dengan peluang baru. Misalnya, mengambil risiko untuk tampil di hadapan umum sebagai pembicara. Selain mengasah keterampilan public speaking, hal ini juga akan membuat Anda lebih dikenal hingga mendapat tawaran sebagai pembicara di tempat lain.",
              },
              {
                title_list: " Interpersonal risk-taking",
                desc_list:
                  " Kemampuan interpersonal risk-taking berhubungan dengan orang lain. Artinya, Anda memiliki kemampuan untuk berdiskusi dengan orang lain terkait perbedaan pendapat yang bisa menimbulkan perubahan. Perlu diingat, perbedaaan pendapat adalah suatu hal yang pasti karena setiap orang punya pemikiran yang berbeda-beda. Perbedaan justru bisa dijadikan pelajaran karena melatih Anda untuk terbuka dengan hal-hal baru.",
              },
              {
                title_list: " Information gathering",
                desc_list:
                  " Information gathering berarti kemampuan mengumpulkan informasi untuk tetap relevan dengan bidang yang Anda tekuni. Di era digital seperti sekarang, informasi memang mudah didapat, tapi untuk bisa mendapatkan informasi yang relevan adalah tantangan tersendiri. Untuk itu, usahakan untuk selalu memahami tiap perkembangan yang terjadi.",
              },
              {
                title_list: " Feedback seeking",
                desc_list:
                  " Proses belajar tidak akan berarti tanpa adanya feedback atau respons dari orang lain. Oleh karenanya, jangan ragu untuk meminta saran serta kritik dari orang lain. Respons dari orang lain sangat penting untuk mengukur kemampuan diri. Dari situ, kekurangan yang Anda miliki bisa dijadikan bahan pembelajaran.",
              },
              {
                title_list: "  Reflecting",
                desc_list:
                  'Dimensi learning agility yang terakhir adalah reflecting. Reflecting berarti kemampuan untuk bisa mengevaluasi performa diri. Dengan reflecting, Anda bisa tahu apa saja kelebihan diri yang harus dipertahankan dan kekurangan yang harus segera diperbaiki. Kemampuan ini juga akan membantu Anda untuk menentukan langkah yang harus diambil selanjutnya.",',
              },
            ],
          },
        },
      ],
      last_paragraph:
        "Bisa disimpulkan bahwa learning agility adalah kemampuan untuk tahu apa yang harus dilakukan dan tidak dilakukan saat berhadapan dengan situasi baru. Salah satu cara menerapkan learning agility adalah dengan selalu upgrade skill dan wawasan. Anda bisa mendapatkan wawasan baru dengan mengikuti webinar atau sertifikasi di satukelas. Daftar segera di sini!",
    },
  },
  {
    id: "4",
    src_img: "www.rawpixel.com",
    date: "16 Desember 2021",
    writer: "Admin",
    slug: "training-karyawan-dengan-digital-learning-mengapa-tidak",
    thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_2298910710845339.jpg",
    title: "Training Karyawan dengan Digital Learning, Mengapa Tidak?",
    article: {
      first_paragraph:
        "Training karyawan sangat dibutuhkan untuk mengembangkan skill mereka. Dengan skill yang selalu up-to-date, maka karyawan pun akan lebih siap dalam menghadapi perubahan zaman. Perusahaan juga akan diuntungkan karena bisa tetap memenuhi permintaan pasar. Metode training karyawan ada banyak bentuknya. Mulai dari metode konvensional yang menghadirkan instruktur di kelas hingga metode yang lebih modern seperti digital learning. Metode digital learning ini mulai banyak diterapkan perusahaan, terutama di masa transformasi digital seperti sekarang. Apa saja keuntungan training karyawan dengan metode digital learning ini?",
      list_paragraph: [
        {
          title_paragraph: "Akses belajar yang praktis dan fleksibel",
          detail_paragraph: {
            detail_first_paragraph:
              "Keuntungan pertama dari training karyawan dengan digital learning adalah akses belajar yang praktis dan fleksibel. Digital learning tidak mengharuskan karyawan untuk duduk diam dalam kelas dan mendengarkan penjelasan dari instruktur. Sebaliknya, digital learning bisa dilakukan di mana saja asal tersedia perangkat yang sesuai dan juga jaringan internet. Karyawan pun bisa leluasa menentukan jadwal training. Mereka dapat mengatur waktu belajar sesuai dengan keseharian  dan kesibukan masing-masing. Dengan begitu, karyawan bisa belajar kapan pun mereka punya waktu luang tanpa harus menunggu jadwal dari perusahaan. Mereka juga bisa belajar di mana saja hanya dengan berbekal handphone atau laptop. Metode ini sangat cocok diterapkan bagi perusahaan yang memiliki karyawan di berbagai tempat dengan zona waktu berbeda.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Mengurangi biaya pelatihan SDM bagi perusahaan",
          detail_paragraph: {
            detail_first_paragraph:
              "Kelebihan lain dari digital learning adalah biaya pelatihan yang lebih hemat. Dengan digital learning, perusahaan hanya perlu mengeluarkan biaya untuk membeli materi belajar karyawan. Hal ini berbeda jauh dengan metode training karyawan konvensional. Dalam metode training konvensional, perusahaan harus membayar jasa instruktur serta materinya. Belum lagi menyediakan ruang tempat pelatihan. Terkadang, perusahaan juga harus mengeluarkan biaya tambahan lain seperti konsumsi hingga transportasi dan akomodasi untuk instruktur. Tidak hanya hemat dan efisien bagi perusahaan, digital learning juga lebih ramah lingkungan. Dengan materi yang tersimpan dalam sistem komputer, maka perusahaan tidak perlu mencetak materi di kertas. Jejak karbon instruktur pun lebih kecil karena ia tidak lagi menggunakan transportasi untuk memberikan pelatihan.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Mendukung pemanfaatan teknologi",
          detail_paragraph: {
            detail_first_paragraph:
              "Saat ini, dunia sudah memasuki era digital, semua sektor industri pun sedikit banyak telah mengalami transformasi digital. Penerapan digital learning untuk pelatihan karyawan pun sangat sejalan dengan perkembangan tersebut. Digital learning memanfaatkan teknologi dalam berbagai prosesnya. Mulai dari tahap pendaftaran, penyampaian materi, hingga tahap penilaian di akhir, semua memanfaatkan bantuan teknologi digital. Penerapan digital learning juga akan membantu karyawan untuk terbiasa dengan pemanfaatan teknologi di sekitar mereka.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Sistem pembelajaran lebih efisien",
          detail_paragraph: {
            detail_first_paragraph:
              "Keuntungan lain dari digital learning untuk training karyawan datang dari sistem pembelajarannya. Sistem pembelajaran yang ditawarkan digital learning lebih efisien jika dibandingkan dengan model training konvensional. Bagaimana bisa? Ini karena digital learning biasanya sudah dilengkapi dengan silabus atau kurikulum digital. Nantinya, proses pelatihan dan penyampaian materi akan mengikuti silabus tersebut. Semuanya telah diatur dalam sebuah sistem perangkat lunak (software) sehingga perusahaan tidak perlu lagi mengatur sistem pembelajaran. Cukup dengan jaringan internet dan perangkat elektronik seperti smartphone atau laptop, maka pelatihan bisa dijalankan dengan timeline yang dikehendaki perusahaan.",
            list_first_paragraph: [],
          },
        },
        {
          title_paragraph: "Pengukuran hasil bisa dilakukan secara akurat",
          detail_paragraph: {
            detail_first_paragraph:
              "Terakhir, digital learning juga mampu memberikan hasil pelatihan yang akurat. Kebanyakan sistem digital learning telah dibekali dengan fitur evaluasi atau ujian. Dengan fitur tersebut, karyawan yang mengikuti training akan diukur pemahamannya terhadap materi yang sudah diberikan. Pengukuran hasil dilakukan secara otomatis oleh sistem. Beberapa sistem digital learning juga memungkinkan instruktur untuk mengoreksi hasil kerja karyawan. Dengan begitu, pengukuran terhadap pemahaman karyawan pun lebih akurat jika dibandingkan dengan metode konvensional.",
            list_first_paragraph: [],
          },
        },
      ],
      last_paragraph:
        "Training karyawan sangat penting untuk meningkatkan kualitas SDM perusahaan. Digital learning bisa menjadi solusi yang efisien untuk memberikan pelatihan kepada karyawan tanpa harus terikat tempat dan waktu. Belum menemukan platform digital learning yang sesuai dengan kebutuhan perusahaan Anda? satukelas bisa menjadi platform yang tepat untuk digital learning karyawan. Di satukelas, Anda bebas memilih materi yang sesuai dengan kebutuhan perusahaan. Selain itu, materi pelatihannya pun selalu ditambah dan di-update.  Klik di sini untuk informasi lebih lanjut mengenai digital learning dari satukelas!",
    },
  },
];

export const dataPerson = [
  {
    img: "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/profile3.png",
    name: "Kenzo Adhisatrio",
    division: "Chief Executive Officer",
    link: "https://www.linkedin.com/in/kenzo-adhisatrio-44710795/",
  },
  {
    img: "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/profile2.png",
    name: "Miqdad Darmawan",
    division: "Chief Experience Oficer",
    link: "https://www.linkedin.com/in/miqdad-darmawan-40bb98214/",
  },
  {
    img: "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/profile4.png",
    name: "Rheza Gemadipada",
    division: "Chief Technology Officer",
    link: "https://www.linkedin.com/in/gemadipada/",
  },
  {
    img: "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/profile6.png",
    name: "Eny Sulistiawati",
    division: "Head of Sales",
    link: "https://www.linkedin.com/in/eny-sulistyawati-3a17a828",
  },
];

export const historyData = [
  {
    title: "Awal Pencapaian",
    desc: "Bermula dari Lembaga Manajemen Formasi yang sejak tahun 1997 menyelenggarakan pelatihan tatap muka untuk profesional dan perusahaan-perusahaan di Indonesia.",
  },
  {
    title: "Pencapaian Kami Sebelumnya",
    desc: "Sejak tahun 1999 hingga 2005, kami telah merilis database peraturan pajak digital pertama di Indonesia bernama TAXES, majalah pajak pertama bertajuk Indonesian Tax Review dan kursus serta ujian Brevet Pajak AB & C digital pertama di Indonesia yang bernama MyBrevet.",
  },
  {
    title: "Inisiasi Digitalisasi",
    desc: "Teknologi semakin maju, manusia semakin dekat dengan dunia digital. Sehingga pada tahun 2020, kami melakukan inisiasi digitalisasi terhadap kegiatan pelatihan yang telah kami tawarkan kepada karyawan di Indonesia. Hingga muncul lah platform belajar satukelas.com pada April 2021",
  },
  {
    title: "Satukelas Kini",
    desc: "Kini kami telah merilis lebih dari 300 kelas pelatihan digital. Selain itu kami telah merilis versi baru satukelas.com dengan fitur dashboard, jamboard, dll. Kami juga telah melebarkan sayap dengan merilis program pelatihan yang dibuat untuk siswa/i sekolah menengah dan mahasiswa/i universitas.",
  },
];

// membership
export const membership_personal = [
  {
    membership_name: "Coba Gratis",
    jumlah_member: "Satu User",
    price: "Rp 0",
    benefit: [
      { benefit_info: "Tes Kompetensi Kerja" },
      { benefit_info: "Analisis Kompetensi Kerja" },
      { benefit_info: "Rekomendasi Pelatihan" },
      { benefit_info: "Akses beberapa video pelatihan" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
      { benefit_info: "Pre/Post Test" },
      { benefit_info: "Akses seluruh Pelatihan Live " },
      { benefit_info: "Live Consultation" },
      { benefit_info: "Webinar " },
      { benefit_info: "Akses Sertifikasi" },
      { benefit_info: "Kursus Persiapan Sertifikasi" },
      { benefit_info: "Assessment Sertifikasi" },
    ],
    desc: "Untuk Anda yang ingin mencoba layanan satukelas selama 1 bulan pertama.",
  },
  {
    membership_name: "Premium",
    jumlah_member: "Satu User",
    price: "100rb",
    benefit: [
      { benefit_info: "Tes Kompetensi Kerja" },
      { benefit_info: "Analisis Kompetensi Kerja" },
      { benefit_info: "Rekomendasi Pelatihan" },
      { benefit_info: "Akses seluruh Pelatihan On Demand" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
      { benefit_info: "Pre/Post Test" },
      { benefit_info: "Akses seluruh Pelatihan Live " },
      { benefit_info: "Live Consultation" },
      { benefit_info: "Webinar " },
      { benefit_info: "Akses Sertifikasi" },
      { benefit_info: "Kursus Persiapan Sertifikasi" },
      { benefit_info: "Assessment Sertifikasi" },
    ],
    desc: "",
  },
];
export const membership_korporat = [
  {
    membership_name: "Membership1",
    jumlah_member: "5 Pengguna",
    price: "3.6jt",
    benefit: [
      { benefit_info: "Analisa dan rekomendasi pelatihan" },
      { benefit_info: "Tes Kompetensi Kerja per pengguna" },
      { benefit_info: "Analisis Kompetensi Kerja per pengguna" },
      { benefit_info: "Rekomendasi Pelatihan per pengguna" },
      { benefit_info: "Akses seluruh Pelatihan On Demand" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
      { benefit_info: "Pre/Post Test" },
      { benefit_info: "Sertifikat Partisipasi Pelatihan" },
      { benefit_info: "Akses seluruh Pelatihan Live " },
      { benefit_info: "Live Consultation" },
      { benefit_info: "Webinar " },
      { benefit_info: "Sertifikat Partisipasi Webinar" },
      { benefit_info: "Akses Sertifikasi" },
      { benefit_info: "Kursus Persiapan Sertifikasi" },
      { benefit_info: "Assessment Sertifikasi" },
      { benefit_info: "Sertifikasi Kompetensi" },
      { benefit_info: "Sertifikasi Profesi BNSP" },
      { benefit_info: "Akses Dasbor Korporasi" },
      { benefit_info: "Unggah materi" },
      { benefit_info: "Sunting pelatihan" },
      { benefit_info: "Pantau pelatihan" },
      { benefit_info: "Laporan pembelajaran" },
      { benefit_info: "Komunikasi internal" },
    ],
  },
];
export const membership_size = [
  { size: 25 },
  { size: 100 },
  { size: 500 },
  { size: 1000 },
];
export const membership_korporat_price = [
  {
    benefit: [
      { benefit_info: "Analisa dan rekomendasi pelatihan" },
      { benefit_info: "Tes Kompetensi Kerja per pengguna" },
      { benefit_info: "Analisis Kompetensi Kerja per pengguna" },
      { benefit_info: "Rekomendasi Pelatihan per pengguna" },
      { benefit_info: "Akses seluruh Pelatihan On Demand" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
      { benefit_info: "Pre/Post Test" },
      { benefit_info: "Sertifikat Partisipasi Pelatihan" },
      { benefit_info: "Akses seluruh Pelatihan Live " },
      { benefit_info: "Live Consultation" },
      { benefit_info: "Webinar " },
      { benefit_info: "Sertifikat Partisipasi Webinar" },
      { benefit_info: "Akses Sertifikasi" },
      { benefit_info: "Kursus Persiapan Sertifikasi" },
      { benefit_info: "Assessment Sertifikasi" },
      { benefit_info: "Sertifikasi Kompetensi" },
      { benefit_info: "Sertifikasi Profesi BNSP" },
      { benefit_info: "Akses Dasbor Korporasi" },
      { benefit_info: "Unggah materi" },
      { benefit_info: "Sunting pelatihan" },
      { benefit_info: "Pantau pelatihan" },
      { benefit_info: "Laporan pembelajaran" },
      { benefit_info: "Komunikasi internal" },
    ],
  },
];
export const membership_title = [25, 100, 500, 1000];
export const membership_school = ["SMK", "SMA"];
export const membership_benefit = [
  {
    benefit: [
      { benefit_info: "Analisa dan rekomendasi pelatihan" },
      { benefit_info: "Tes Kompetensi Kerja per pengguna" },
      { benefit_info: "Analisis Kompetensi Kerja per pengguna" },
      { benefit_info: "Rekomendasi Pelatihan per pengguna" },
      { benefit_info: "Akses seluruh Pelatihan On Demand" },
      { benefit_info: "Video Pelatihan" },
      { benefit_info: "Modul Pelatihan" },
    ],
  },
];
// korporasi
export const benefit_korporat = [
  {
    title: "Siap Digunakan",
    subtitle:
      "Konten belajar siap digunakan. Tinggal log-in, kompetensi karyawan bisa ditingkatkan.",
  },
  {
    title: "Akses Mudah",
    subtitle:
      "Belajar di satukelas dari manapun dan kapanpun dengan akses dari perangkat gawai kesayangan Anda.",
  },
  {
    title: "Pelatihan yang Sesuai",
    subtitle:
      "Identifikasi pelatihan yang dibutuhkan karyawan Anda di satukelas dan berikan penugasan yang sesuai agar pelatihan lebih efektif.",
  },
  {
    title: "Ramah Perusahaan",
    subtitle:
      "Satukelas dirancang agar perusahaan bisa menyesuaikan pembelajaran serta mengakses, memantau, menugaskan karyawan dengan mudah.",
  },
  {
    title: "Peningkatan Kualifikasi yang Aplikatif",
    subtitle:
      "Materi yang didasarkan pada proses bisnis industri yang diakui dengan adanya sertifikasi untuk meningkatkan kualifikasi lintas industri seperti alat bantu kerja, bahasa Inggris, dan soft skills untuk memudahkan pekerjaan Anda.",
  },
];
export const info_korporat = [
  {
    desc: "50% pencari kerja saat ini adalah generasi Z dan milenial yang membawa tuntutan baru ke perusahaan untuk beralih ke era digital.",
  },
  {
    desc: "73% generasi tersebut percaya bahwa kesempatan pengembangan diri dan karir di perusahaan lebih berarti dibandingkan hanya remunerasi.",
  },
  {
    desc: "Keterampilan bekerja mengalami perubahan dari waktu ke waktu, terutama di era informasi seperti sekarang. 1 dari 3 keterampilan kerja inti akan berubah dalam 5 tahun.",
  },
];

// course and webinar types
export const COURSE_TYPES = [
  "Semua",
  "Pelatihan",
  "Sertifikasi",
  "Try Out",
  "Tutorial",
  "Studi Kasus",
  "Pembahasan Soal",
];
export const WEBINAR_TYPES = ["Semua", "Webinar", "Konsultasi"];

// course categories
export const COURSE_CATEGORIES = [
  {
    categories_id: "Keuangan",
    name: "Keuangan",
  },
  {
    categories_id: "Perpajakan",
    name: "Perpajakan",
  },
  {
    categories_id: "Akuntansi",
    name: "Akuntansi",
  },
  {
    categories_id: "Pemasaran",
    name: "Pemasaran",
  },
  {
    categories_id: "Penjualan",
    name: "Penjualan",
  },
  {
    categories_id: "Manajemen SDM",
    name: "Manajemen SDM",
  },
  {
    categories_id: "Pengembangan Diri",
    name: "Pengembangan Diri",
  },
  {
    categories_id: "Manajemen Risiko",
    name: "Manajemen Risiko",
  },
  {
    categories_id: "Manajemen Aset",
    name: "Manajemen Aset",
  },
  {
    categories_id: "Pengembangan Bisnis",
    name: "Pengembangan Bisnis",
  },
  {
    categories_id: "Legal",
    name: "Legal",
  },
  {
    categories_id: "Hubungan Eksternal",
    name: "Hubungan Eksternal",
  },
  {
    categories_id: "Soft Skills",
    name: "Soft Skills",
  },
  {
    categories_id: "K3",
    name: "K3",
  },
  {
    categories_id: "Digital Marketing",
    name: "Digital Marketing",
  },
  {
    categories_id: "Bahasa Inggris",
    name: "Bahasa Inggris",
  },
];

export const defaulThumbnail =
  "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/4270648989474070.jpeg";

// company size
export const company_size = [
  { size_company: 30 },
  { size_company: 100 },
  { size_company: 500 },
  { size_company: 1000 },
];

export const thumbnail = [
  {
    img_thumbnail:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/cm42036694_3846684037876354.png",
  },
];

// testimoni
export const testimoni = [
  {
    name: "Subur, SE, Staff Administrasi",
    company: "KKP Arie dan Paulus Harsono",
    photo:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/testimoni_2.webp",
    testimoni:
      "Jika anda ingin belajar tentang Pajak, tentu di SATUKELAS.COM. Pengajarnya enak, berwawasan luas, pengetahuan tentang UU Pajak rekomended sekali, mau tanya apapun tentang pajak pasti dijawab, hostnya cakep ramah, di tambah mbak Eva yang suka membantu disaat galau 😂, cepat respon dan expert dalam bidang masing2, wes pokoke satukelas.com 👍👍👍",
  },
  {
    name: "Anice Alvina, Head of Tax Management Unit",
    company: "Bank of China (Hong Kong) Limited Cabang Jakarta",
    photo:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/testimoni_1.webp",
    testimoni:
      "Sangat bermanfaat, ada kelas training, ada live konsultasi, dan bisa lihat record dr setiap sesi. Adminnya juga sangat helpful.",
  },
  {
    name: "Markus Joskamianto, Manajer Acc and Tax ",
    company: "PT Draco Internasional",
    photo: "",
    testimoni:
      "Satukelas merupakan ide yang sangat bagus sekali ditengah pandemi begini, sehingga tetap bisa menambah ilmu, apalagi ditambah dengan adanya live konsultasi untuk membantu kita menyelesaikan kendala yang kita hadapi selama bekerja. Intinya satukelas bagus sekali , saya puas Sekali. Sukses Selalu utk Satukelas",
  },
  {
    name: "Yudi Supriadi, Tax Officer",
    company: "PT. Quadran Energi Rekayasa",
    photo:
      "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/testimoni_4.webp",
    testimoni:
      "Platform satukelas memberikan pengetahuan, info update, kemudahan, dan solusi pajak. Pengetahuan pajak di Indonesia & Dunia, update peraturan pajak, kemudahan memahami pajak & solusi pajak disajikan dalam webinar/live konsultasi tiap hari. Harga langganan sangat murah, worth it & recommended...  yuk belajar di satukelas.com !!!",
  },
];

export const testimoniEnglish = [
  {
    name: "Muammar Farouk",
    company: "Video Editor",
    photo: farouk,
    testimoni:
      "Saya kira belajar Bahasa Inggris gak bisa semurah dan semudah ini. Program-program singkat dari Satukelas membantu saya untuk berkomunikasi ketika menawarkan jasa saya ke calon klien dari luar negeri. Recommended!",
  },
  {
    name: "Anice Alvina",
    company: "Head of Tax Management Unit",
    photo:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/testimoni_1.webp",
    testimoni:
      "Sangat bermanfaat, ada kelas training, ada live konsultasi, dan bisa lihat record dr setiap sesi. Adminnya juga sangat helpful.",
  },
  {
    name: "Darient Megantara",
    company: "Data Analyst",
    photo: darient,
    testimoni:
      "Gue rasa, selain membantu untuk nyiapin gue tes TOEIC untuk lamar kerja, program Satukelas juga ngasih banyak banget kosakata baru. Jadinya bacaan dan referensi gue lebih luas karena gue lebih jago bahasa inggris.",
  },
];

export const pros_membership = [
  {
    thumbnail:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/asset_1.jpg",
    name: "Pelatihan Tepat Sasaran",
    text: "Ada rekomendasi pelatihan yang bisa mengarahkan kamu ke jalur belajar yang cocok dengan kebutuhan. Teknologi di satukelas dapat mendeteksi minat disiplin kamu sehingga dapat memberikan rekomendasi pelatihan yang sesuai.",
  },
  {
    thumbnail:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/asset_2.jpg",
    name: "Kuasai Pelatihan",
    text: "Dilaksanakan secara digital, maka kamu lah yang memimpin pembelajaran. Pilih jadwal, pelatihan, dan cara belajar yang sesuai. Semua sesukamu.",
  },
  {
    thumbnail:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/asset_3.jpg",
    name: "Akses tanpa Batas",
    text: "Belajar kapan saja dan di mana saja melalui semua perangkat kesayangan kamu. Bisa belajar di web-browser, perangkat Android, dan iOS.",
  },
  {
    thumbnail:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/asset_4.jpg",
    name: "Konten Beragam",
    text: "Pembelajaran di satukelas disampaikan dengan berbagai fitur mulai dari video, modul, kuis, dan podcast. Konten dikemas berkala dan berkelanjutan sehingga mudah dipahami. Setiap bulannya, satukelas merilis pelatihan digital sehingga dapat mencakup seluruh kompetensi yang kamu butuhkan.",
  },
  {
    thumbnail:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/asset_5.jpg",
    name: "Atur dan Pantau Belajarmu",
    text: "Menggunakan dasbor personal dan perusahaan, kamu bisa mengatur progres belajarmu agar sesuai dengan target. Pastikan performa selalu naik dan raih kompetensi yang diinginkan.",
  },
  {
    thumbnail:
      "https://satukelas-fe-asset.ap-south-1.linodeobjects.com/asset_6.jpg",
    name: "Aplikatif dan Berdampak",
    text: "Konten belajar yang ada di satukelas didasarkan kepada proses bisnis dan keterampilan lintas fungsi yang bisa langsung dipraktekan ketika bekerja. Mulai dari hard skill, soft skill, kemampuan berbahasa, tutorial alat bantu pekerjaan, dan masih banyak lagi.",
  },
];

// categories homepage
export const categories = [
  {
    name: "Keuangan",
    slug: "keuangan",
  },
  {
    name: "Perpajakan",
    slug: "perpajakan",
  },
  {
    name: "Akuntansi",
    slug: "akuntansi",
  },
  {
    name: "Pemasaran",
    slug: "pemasaran",
  },
  {
    name: "Penjualan",
    slug: "penjualan",
  },
  {
    name: "Manajemen SDM",
    slug: "manajemen sdm",
  },
  {
    name: "Pengembangan Diri",
    slug: "pengembangan diri",
  },
  {
    name: "Manajemen Risiko",
    slug: "manajemen risiko",
  },
  {
    name: "Manajemen Aset",
    slug: "manajemen aset",
  },
  {
    name: "Pengembangan Bisnis",
    slug: "manajemen sdm",
  },
  {
    name: "Legal",
    slug: "legal",
  },
  {
    name: "Hubungan Eksternal",
    slug: "hubungan-eksternal",
  },
  {
    name: "Soft Skill",
    slug: "soft-skill",
  },
];

export const info = [
  {
    title: "Live Webinar",
    description: "Coba pelatihan dengan format live webinar!",
    img: LiveWebinar,
  },
  {
    title: "Live Consultation",
    description: "Konsultasi langsung dengan pakar bidang",
    img: LiveConsultation,
  },
  {
    title: "Pelatihan Mandiri",
    description: "Tonton video pelatihan di mana saja!",
    img: Vod,
  },
  {
    title: "Persiapan & Ujian",
    description: "Asah dan buktikan skill mu di sini!",
    img: ExamPrepare,
  },
];
export const categories_course = [
  { name: "Perpajakan", slug: "perpajakan", img: tax },
  { name: "Keuangan", slug: "keuangan", img: money },
  { name: "Bahasa Inggris", slug: "bahasa inggris", img: english },
  {
    name: "Pengembangan Diri",
    slug: "pengembangan diri",
    img: hr,
  },
  { name: "Teknologi", slug: "teknologi", img: tech },
  { name: "Softskill", slug: "softskill", img: softskills },
  { name: "Pemasaran & Penjualan", slug: "pemasaran & penjualan", img: market },
  { name: "K3", slug: "k3", img: k3 },
];

export const prodLearningPath = {
  items: [
    {
      category: [
        {
          category: "Bahasa Inggris",
          category_id: "ca29820744",
          skill_type: "Bahasa",
          skill_type_id: "st87209315",
          sub_category: "Bahasa Inggris",
          sub_category_id: "sc58427920",
        },
      ],
      created_at: "2023-05-13 15:11:56",
      delivery_methods: [],
      discussion_topics: [],
      instructors: [],
      path_id: "lp52248893",
      pathway_learning_levels: [],
      pathway_positions: [],
      pathway_specifications: [],
      price: 150000,
      products: [],
      schemes: [
        {
          scheme: "TOEFL for Professionals",
          scheme_id: "sc15001515",
        },
      ],
      summary: "",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1832046440489109.jpg",
      title: "TOEFL for Professionals",
      total_courses: 39,
      total_duration: 116400,
    },
    {
      category: [
        {
          category: "Bahasa Inggris",
          category_id: "ca29820744",
          skill_type: "Bahasa",
          skill_type_id: "st87209315",
          sub_category: "Bahasa Inggris",
          sub_category_id: "sc58427920",
        },
      ],
      created_at: "2023-05-13 15:11:45",
      delivery_methods: [],
      discussion_topics: [],
      instructors: [],

      path_id: "lp27759649",
      pathway_learning_levels: [],
      pathway_positions: [],
      pathway_specifications: [],
      price: 150000,
      products: [],
      schemes: [
        {
          scheme: "Kickstart Your TOEFL Preparation",
          scheme_id: "sc59421297",
        },
      ],
      summary: "",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1930574984868750.png",
      title: "Kickstart Your TOEFL Preparation",
      total_courses: 15,
      total_duration: 44700,
    },
  ],
};

export const titleBtnHeader = [
  {
    title: "Keuangan",
    link: "/search/semua/category=keuangan",
  },
  {
    title: "Perpajakan",
    link: "/search/semua/category=perpajakan",
  },
  {
    title: "Akuntansi",
    link: "/search/semua/category=akuntansi",
  },
  {
    title: "Soft Skill",
    link: "/search/semua/category=soft%20skills&keyword=&size=9&page=1",
  },
  { title: "Lihat Semua", link: "/search/semua/course_type=semua" },
];

export const courseBrevet = {
  category: [],
  certificate_template_id: "",
  certificate_template_url: "",
  courses: [
    {
      course_id: "co20143611",
      course_type: "Sertifikasi",
      course_type_id: "ct10000001",
      instructors: "us33376684",
      lessons_count: 19,
      progress: 0,
      status: "not enrolled",
      summary:
        "Kelas ini akan mempelajari tentang segala aspek pemotongan dan pemungutan pajak penghasilan. Kelas ini merupakan bagian dari Sertifikasi Pajak Brevet C\n",
      title: "Pemotongan dan Pemungutan PPh - Brevet C",
      total_duration: 4739,
      total_materials: 21,
    },
    {
      course_id: "co22046961",
      course_type: "Pelatihan",
      course_type_id: "ct10000001",
      instructors: "us33376684",
      lessons_count: 30,
      progress: 0,
      status: "not enrolled",
      summary:
        "Kelas ini akan mempelajari tentang segala aspek perpajakan Bentuk Usaha Tetap termasuk SPT PPh Badan. Kelas ini merupakan bagian dari Sertifikasi Pajak Brevet C\n",
      title: "PPh Badan Bentuk Usaha Tetap & SPT BUT - Brevet C",
      total_duration: 8234,
      total_materials: 32,
    },
    {
      course_id: "co74914520",
      course_type: "Sertifikasi",
      course_type_id: "ct10000001",
      instructors: "us33376684",
      lessons_count: 58,
      progress: 0,
      status: "not enrolled",
      summary:
        "Kelas ini akan mempelajari tentang segala aspek akuntansi pajak internasional. Kelas ini merupakan bagian dari Sertifikasi Pajak Brevet C\n",
      title: "Akuntansi Pajak Internasional - Brevet C",
      total_duration: 9950,
      total_materials: 60,
    },
    {
      course_id: "co96927320",
      course_type: "Sertifikasi",
      course_type_id: "ct10000001",
      instructors: "us33376684",
      lessons_count: 73,
      progress: 0,
      status: "not enrolled",
      summary:
        "Kelas ini akan mempelajari tentang segala aspek perpajakan Internasional. Kelas ini merupakan bagian dari Sertifikasi Pajak Brevet C\n",
      title: "Perpajakan Internasional - Brevet C",
      total_duration: 11718,
      total_materials: 75,
    },
    {
      course_id: "co97744206",
      course_type: "Sertifikasi",
      course_type_id: "ct10000006",
      instructors: "us51255535",
      lessons_count: 0,
      progress: 0,
      status: "not enrolled",
      summary: "",
      title: "Sertifikasi : Brevet C",
      total_duration: 12000,
      total_materials: 1,
    },
  ],
  created_at: "2023-09-07 18:43:30",
  delivery_methods: [],
  description: "",
  discussion_topics: [],
  instructors: [
    {
      avatar:
        "https://satukelas-profile-pictures.ap-south-1.linodeobjects.com/us14905832_2394750179242087.jpg",
      description:
        "Praktisi di bidang perpajakan yang saat ini sedang bekerja di Lembaga Manajemen Formasi. Beliau juga memiliki kesibukan sebagai konsultan pajak dan juga auditor pajak. Selain itu, beliau juga berkegiatan sebagai trainer pajak di salah satu EduTech di Indonesia.",
      division: "keuangandanperpajakan",
      name: "Agus Supriyanto",
      occupation: "",
      position: "",
      user_id: "us33376684",
    },
    {
      avatar: "",
      description: "Ekslusif dari Satukelas",
      division: "",
      name: "satukelas",
      occupation: "",
      position: "",
      user_id: "us51255535",
    },
  ],
  last_updated: "2023-09-07 18:54:28",
  level: "",
  objectives: [],
  path_id: "lp76946353",
  pathway_learning_levels: [],
  pathway_positions: [],
  pathway_specifications: [],
  price: 1500000,
  products: [],
  schemes: [
    {
      scheme: "Brevet AB+C",
      scheme_id: "sc11479001",
    },
    {
      scheme: "Brevet C",
      scheme_id: "sc73636687",
    },
  ],
  sub_category_id: "",
  summary: "",
  thumbnail: null,
  title: "Sertifikasi: Brevet C",
  total_courses: 5,
  total_duration: 46641,
};

export const lp_dev = {
  items: [
    {
      vel: null,
      path_id: "lp27759649",
      summary: "",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1930574984868750.png",
      title: "TOEFL iTP Test Preparation",
    },
    {
      path_id: "lp74858302",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1351985933466665.png",
      title: "TOEIC Preparation Course",
    },
    {
      path_id: "lp36347848",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/2823491861684702.png",
      title: "Introduction to IELTS",
    },
    {
      path_id: "lp93087372",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1832046440489109.jpg",
      title: "English for Job Application",
    },
  ],
};

export const lp_prod = {
  items: [
    {
      path_id: "lp27759649",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1930574984868750.png",
      title: "TOEFL iTP Test Preparation",
    },
    {
      path_id: "lp74858302",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1351985933466665.png",
      title: "TOEIC Preparation Course",
    },
    {
      path_id: "lp36347848",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/2823491861684702.png",
      title: "Introduction to IELTS",
    },
    {
      path_id: "lp93087372",
      thumbnail:
        "https://satukelas-thumbnails.ap-south-1.linodeobjects.com/1832046440489109.jpg",
      title: "English for Job Application",
    },
  ],
};

export const brevet_dev = {
  items: [
    {
      course_id: "co97744206",
      title: "Sertifikasi : Brevet Pajak C",
    },
    {
      course_id: "co73258264",
      title: "Sertifikasi : Brevet Pajak AB",
    },
  ],
};

export const brevet_prod = {
  items: [
    {
      course_id: "co97744206",
      title: "Sertifikasi : Brevet Pajak C",
    },
    {
      course_id: "co73258264",
      title: "Sertifikasi : Brevet Pajak AB",
    },
  ],
};
