import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { postAssetsDataThunk } from "../../redux/thunks/AssetsThunks";
import { useDispatch } from "react-redux";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import "../../assets/css/Account/fileuploader.css";
import { Capitalize } from "../../helper/functions";

function MediaUploader() {
  const [fileUpload, setFileUpload] = useState();
  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
  //   // Note how this callback is never invoked if drop occurs on the inner dropzone
  //   onDrop: (files) => setFileUpload(files),
  // });

  // const files = acceptedFiles.map((file) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));
  const [fileInfo, setFileInfo] = useState();
  const [allItems, setAllItems] = useState();
  const [bucket, setBucket] = useState("assignments");
  const [payload, setPayload] = useState({
    url: "?bucket=assignments",
    body: "",
  });

  const dispatch = useDispatch();
  // const assets = useSelector((state) => state.assets);
  // const assetsDataPost = assets.assetsDataPost;
  const formData = new FormData();

  function handleChange(e) {
    let obj = payload;
    obj.url = `?bucket=${e.target.value}`;
    setPayload(obj);
    setBucket(e.target.value);
  }
  function handleUpload() {
    if (payload.body !== "" && payload.url !== "")
      dispatch(postAssetsDataThunk(payload));
  }

  function handleDropzone(files) {
    // if (payload.body !== "" && payload.url !== "") {
    setFileUpload(files[0]);
    let obj = payload;
    formData.append("upfile", files[0]);
    obj.body = formData;

    setPayload(obj);
    // }
  }
  useEffect(() => {}, [fileUpload]);
  return (
    <Row className="height-inherit justify-content-center align-content-center">
      <Col xs={12}>
        {/* <div className="title_upload">Unggah Berkas Anda</div>
            <div className="subtitle_upload">Pilih File untuk di unggah</div>
            <Upload {...props} style={{ width: "100%" }}>
              <Button className="file-upload-btn py-3 mt-5 mb-4 btn-primary">
                {fileInfo
                  ? allItems?.length > 1
                    ? `${allItems?.length} berkas terpilih`
                    : fileInfo.name
                  : "Pilih Berkas Kamu"}
              </Button>
            </Upload> */}
        <div className="my-4">
          {/* <DropzoneArea
            dropzoneText={"Drop File atau Pilih File Anda"}
            onChange={handleDropzone}
            filesLimit={1}
          /> */}
          <Dropzone onDrop={handleDropzone}>
            {({ getRootProps, getInputProps }) => (
              <div className="container dropzone-style">
                <div
                  {...getRootProps({
                    className: "dropzone",
                  })}
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </div>
            )}
          </Dropzone>
          <div>{fileUpload !== undefined ? fileUpload?.path : null}</div>
        </div>
        <Form>
          <div className="d-flex justify-content-between">
            <Form.Group className="d-flex align-items-center">
              <div className="mb-0 w-100">Pilih Folder</div>
              <Form.Control
                required
                as="select"
                type="select"
                name="bucket"
                id="bucket"
                value={bucket}
                onChange={handleChange}
              >
                <option hidden value key="blankChoice">
                  Pilih Bucket...
                </option>
                {["assignments", "profile_pictures"].map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {Capitalize(item.replace(/_/g, " "))}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant="success"
                className="file-upload-btn my-2 px-3"
                onClick={handleUpload}
              >
                Unggah
              </Button>
            </div>
          </div>
        </Form>
      </Col>
    </Row>
  );
}
export default MediaUploader;
