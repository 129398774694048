import { satukelasApi } from "../constants";

export const scormService = {
  getScorm(id) {
    return satukelasApi.get(`/v2/scorms/${id ? id : ""}`);
  },
  postScore(payload) {
    return satukelasApi.post(`/v2/scorms/score`, payload);
  },
};
