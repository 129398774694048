import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/ContactUs/index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { division_name, company_size } from "../../helper/constant";
import {
  getProvincesDataThunk,
  getCitiesDataThunk,
} from "../../redux/thunks/OrdersThunks";
import { postInboxDataThunk } from "../../redux/thunks/InboxThunks";
const MySwal = withReactContent(Swal);
function ContactUs() {
  const history = useHistory();
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    work_division: "",
    company_size: 0,
    province: "",
    city: "",
    message: "",
    address: "",
  });
  useEffect(() => {}, [data.company_size]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const orders = useSelector((state) => state.orders);
  const inbox = useSelector((state) => state.inbox);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProvincesDataThunk());
  }, []);
  useEffect(() => {
    if (inbox.inboxDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terima kasih telah menghubungi kami", "Oke");

      setData({
        ...data,
        name: "",
        phone: "",
        email: "",
        work_division: "",
        company_size: 0,
        province: "",
        city: "",
        message: "",
        address: "",
      });
    }
    if (inbox.inboxDataPost === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Ada kesalahan", "Oke");
    }
  }, [inbox]);
  //get city
  useEffect(() => {
    if (data.province) {
      const newData = { ...data };
      newData.city = "";
      setData(newData);
      dispatch(getCitiesDataThunk(data.province.split("_")[0]));
    }
  }, [data.province]);
  useEffect(() => {
    if (orders.ordersProvinces) setProvinces(orders.ordersProvinces);
    if (orders.ordersCities) setCities(orders.ordersCities);
  }, [orders]);
  const handleSubmitData = (e) => {
    setTrigger(true);
    e.preventDefault();
    const dataSend = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      company_size: parseInt(data.company_size),
      address: `${data.province} ${data.city}`,
      work_division: data.work_division,
      message: data.message,
    };
    dispatch(postInboxDataThunk(dataSend));
  };
  const handleChange = (e) => {
    const newData = { ...data };
    if (newData[e.target.id] === "company_size") {
      newData[e.target.id] = parseInt(e.target.value);
    } else {
      newData[e.target.id] = e.target.value;
    }
    setData(newData);
  };
  function getOptionData(data, field) {
    return data
      .filter((item) => item[field] !== "")
      .map((item, index) => {
        const id = `${field}_id`;
        // console.log(`${item[id]}_${item[field]}`, "<<<<<<<<<");
        return (
          <option key={index} value={`${item[id]}_${item[field]}`}>
            {item[field]}
          </option>
        );
      });
  }
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }
  return (
    <>
      <Link
        to="/membership"
        className="dashboard_top__contact_us px-2 px-md-5 py-3 text-right"
      >
        Mencari paket langganan individual?
      </Link>
      <div className="dashboard_contact__us">
        <Container className="dashboard_page__container py-5">
          <Row>
            <Col lg={6}>
              <h2 className="h2 contact_us__title">Hubungi Sales Kami</h2>
              <h4 className="h4 contact_us__subtitle_top">
                Mulai pelatihan digital yang efektif dan hemat biaya untuk
                perusahaan Anda dengan satukelas.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={5}>
            <div id="list_contact" className="py-5">
              <div className="contact_us__left">
                Pelajari bagaimana Satukelas membantu peningkatan kompetensi
                karyawan Anda.
              </div>
              <div className="contact_us__left_bottom">
                Hubungi tim kami dan pelajari mengapa lebih dari 100 perusahaan
                telah mempercayai Satukelas untuk melatih karyawan mereka.
              </div>
              <ul>
                <li>
                  Ratusan kelas yang didasarkan kepada proses bisnis inti, dan
                  keterampilan aplikatif di pekerjaan.
                </li>
                <li>
                  Pembelajaran personal mulai dari tes kompetensi, rekomendasi
                  pelatihan, dan metode pelatihan yang bisa disesuaikan dengan
                  cara belajar masing-masing pengguna.
                </li>
                <li>
                  Sertifikasi partisipasi, kompetensi dan profesi yang diakui di
                  Indonesia.
                </li>
                <li>
                  Dasbor admin perusahaan untuk mengunggah materi, menyunting
                  kelas, memantau pelatihan, dan memaksimalkan ROTI (Return of
                  Training Investment) di perusahaan Anda.
                </li>
              </ul>
              <a
                href="https://wa.me/6282130070040?text=saya%20lihat%20website%20satukelas%20dan%20saya%20tertarik"
                className="button_whatsapp"
                target={`_blank`}
              >
                Hubungi langsung sales kami melalui WhatsApp
              </a>
            </div>
          </Col>
          <Col lg={6} className=" offset-lg-1 column-form">
            <Card className="p-4 p-md-5 card_contact__us">
              <h3 className="h3 mb-3"> Hubungi kami</h3>
              <Form onSubmit={handleSubmitData}>
                <Form.Group>
                  <div className="login-label">Nama</div>
                  <Form.Control
                    className="mb-3 input100"
                    onChange={handleChange}
                    value={data.name}
                    name="name"
                    id="name"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <div className="login-label">No. HP</div>
                  <Form.Control
                    className="mb-3 input100"
                    value={data.phone}
                    name="phone"
                    id="phone"
                    onChange={handleChange}
                    type="text"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <div className="login-label">Email</div>
                  <Form.Control
                    className="mb-3 input100"
                    value={data.email}
                    name="email"
                    id="email"
                    onChange={handleChange}
                    type="text"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <div className="login-label">Divisi Anda</div>
                  <div className="position-absolute p-3 d-flex">
                    {/* <FaEnvelope /> */}
                  </div>
                  <Form.Control
                    className="mb-3 input100"
                    value={data.work_division}
                    name="work_division"
                    id="work_division"
                    onChange={handleChange}
                    as="select"
                    type="select"
                  >
                    <option value="">--Pilih Divisi--</option>
                    {division_name?.map((item, idx) => (
                      <option key={idx} value={item.divisi_name}>
                        {item.divisi_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <div className="login-label">Jumlah Karyawan Perusahaan</div>
                  <div className="position-absolute p-3 d-flex"></div>
                  <Form.Control
                    className="mb-3 input100"
                    onChange={handleChange}
                    value={data.company_size}
                    name="company_size"
                    id="company_size"
                    as="select"
                    type="select"
                  >
                    <option value="">--Pilih Jumlah Karyawan--</option>
                    {company_size.map((item, idx) => (
                      <option key={idx} value={parseInt(item?.size_company)}>
                        {parseInt(item.size_company)} Orang
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="d-flex justify-content-between">
                  <Form.Group className="mr-2">
                    <div className="login-label">Provinsi</div>
                    <div className="position-absolute p-3 d-flex "></div>
                    <Form.Control
                      required
                      as="select"
                      type="select"
                      name="province"
                      id="province"
                      value={data.province}
                      onChange={handleChange}
                    >
                      <option hidden value key="blankChoice">
                        Pilih Provinsi...
                      </option>
                      {getOptionData(provinces, "province")}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <div className="login-label">Kota</div>
                    <div className="position-absolute p-3 d-flex"></div>
                    <Form.Control
                      required
                      as="select"
                      type="select"
                      name="city"
                      id="city"
                      className="mb-3 input100"
                      onChange={handleChange}
                      value={data.city}
                    >
                      <option hidden value key="blankChoice">
                        Pilih Kota/Kabupaten...
                      </option>
                      {cities.map((item, index) => {
                        return (
                          <option
                            value={`${item.city_id}_${item.type} ${item.city_name}`}
                            key={index}
                          >
                            {item.type} {item.city_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </div>

                <Form.Group>
                  <div className="login-label">Kebutuhan Anda</div>
                  <div className="position-absolute p-3 d-flex">
                    {/* <FaEnvelope /> */}
                  </div>
                  <textarea
                    onChange={handleChange}
                    value={data.message}
                    name="message"
                    id="message"
                    className="satukelas_input"
                    rows="4"
                    required
                  />
                </Form.Group>
                <div className="bottom-button__register justify-content-end mr-0">
                  <Button type="submit" className="register-next__button">
                    {" "}
                    Kirim
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactUs;
