import axios from "axios";
import https from "https";
import { ENDPOINT } from "../helper/constant";
import { AuthData } from "../helper/auth";

export const satukelasApi = axios.create({
  baseURL: ENDPOINT,
  httpsAgent: new https.Agent({
    rejectUnauthorized: true,
  }),
});

// Add a request interceptor
satukelasApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const auth = AuthData();
    if (auth?.authorization) {
      config.headers.Authorization = "Basic " + auth.authorization;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
satukelasApi.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response?.data ? response?.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response);
  }
);
