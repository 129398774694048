import React from "react";
import { Row, Col, Container } from "react-bootstrap";
function PrivacyPolice() {
  return (
    <Container>
      <Row>
        <Col lg={12} className="py-5">
          <h3 className="h3 ">Kebijakan Privasi</h3>
          <div>
            Kerahasian Informasi Pribadi adalah hal yang terpenting bagi PT
            Satukelas Adhyapana Nusantara ("<b>Kami</b>"). Kami berkomitmen
            untuk melindungi dan menghormati privasi pengguna ("<b>Anda</b>")
            saat mengakses dan menggunakan aplikasi, situs web (
            <a href="https://satukelas.com">www.satukelas.com</a>) dan situs web
            lain yang Kami kelola), fitur, teknologi, konten dan produk yang
            Kami sediakan (selanjutnya, secara bersama-sama disebut sebagai "
            <b>Platform</b>").
            <br />
            <br />
            <b>
              Harap membaca Syarat dan Ketentuan ini secara seksama sebelum Anda
              mulai menggunakan Platform Kami, karena peraturan ini berlaku pada
              penggunaan Anda terhadap Platform Kami.
            </b>
            Kebijakan Privasi ini mengatur landasan dasar mengenai bagaimana
            Kami menggunakan informasi pribadi yang Kami kumpulkan dan/atau atau
            Anda berikan ("<b>Informasi Pribadi</b>"). Kebijakan Privasi ini
            berlaku bagi seluruh pengguna Platform, kecuali diatur pada
            Kebijakan Privasi yang terpisah. Mohon membaca Kebijakan Privasi
            Kami dengan seksama sehingga Anda dapat memahami pendekatan dan cara
            Kami dalam menggunakan informasi tersebut.
            <br />
            <br />
            Kebijakan Privasi ini mencakup hal-hal sebagai berikut:
            <ol type="A">
              <li>Informasi Pribadi yang Dapat Kami Kumpulkan</li>
              <li>Penggunaan Informasi Pribadi</li>
              <li>Pengungkapan Informasi Pribadi</li>
              <li>Penyimpanan Informasi Pribadi</li>
              <li>Hak Anda</li>
              <li>Kebijakan Cookies</li>
              <li>Pengakuan dan Persetujuan</li>
              <li>Materi Pemasaran</li>
              <li>Perubahan dalam Kebijakan Privasi Kami</li>
              <li>Cara Menghubungi Kami</li>
            </ol>
            Dengan mengunjungi dan/atau mendaftar Akun pada Platform Kami, Anda
            dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia di
            bawah 18 [delapan belas] tahun) menerima dan menyetujui pendekatan
            dan cara-cara yang digambarkan dalam Kebijakan Privasi ini.
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyPolice;
