import { PromiseService } from "../../services/PromiseService";
import { WEBINARS_DATA_LINK } from "../config/WebinarsConfig";
import {
  //get
  getWebinarsData,
  getWebinarsDataSuccess,
  getWebinarsDataIdSuccess,
  getWebinarsDataFail,
  getWebinarsTypeData,
  getWebinarsTypeDataSuccess,
  getWebinarsTypeDataFail,
  getWebinarsConsultationData,
  getWebinarsConsultationDataSuccess,
  getWebinarsConsultationDataFail,
} from "../actions/WebinarsActions";

export const getWebinarsDataThunk = (data) => async (dispatch) => {
  dispatch(getWebinarsData(data));
  if (data.type === "id") {
    try {
      const response = await PromiseService.getData(
        `${WEBINARS_DATA_LINK}${data.param}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getWebinarsDataIdSuccess(response.data));
      } else {
        dispatch(getWebinarsDataFail(response));
      }
    } catch (error) {
      dispatch(getWebinarsDataFail(error));
    }
  } else if (data.type === "filter") {
    try {
      const response = await PromiseService.getData(
        `${WEBINARS_DATA_LINK}${data.param}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getWebinarsDataSuccess(response.data));
      } else {
        dispatch(getWebinarsDataFail(response));
      }
    } catch (error) {
      dispatch(getWebinarsDataFail(error));
    }
  } else dispatch(getWebinarsDataFail("error"));
};

export const getWebinarTypeLengthDataThunk = (data) => async (dispatch) => {
  dispatch(getWebinarsTypeData(data));

  try {
    const response = await PromiseService.getData(
      `${WEBINARS_DATA_LINK}?type=webinar`
    );
    if (response.status === 200 && response.data) {
      dispatch(getWebinarsTypeDataSuccess(response.data));
    } else {
      dispatch(getWebinarsTypeDataFail(response));
    }
  } catch (error) {
    dispatch(getWebinarsTypeDataFail(error));
  }
};
export const getWebinarConsultationLengthDataThunk =
  (data) => async (dispatch) => {
    dispatch(getWebinarsConsultationData(data));

    try {
      const response = await PromiseService.getData(
        `${WEBINARS_DATA_LINK}?type=konsultasi`
      );
      if (response.status === 200 && response.data) {
        dispatch(getWebinarsConsultationDataSuccess(response.data));
      } else {
        dispatch(getWebinarsConsultationDataFail(response));
      }
    } catch (error) {
      dispatch(getWebinarsConsultationDataFail(error));
    }
  };
