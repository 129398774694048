import React from "react";
import { Image } from "react-bootstrap";
import iconTiktik from "../../assets/images/atpi/certificate/icon-tiktik.png";
import bgCertifPropDesktop from "../../assets/images/atpi/certificate/bg-certif-prop.png";
import bgCertifProp1Mobile from "../../assets/images/atpi/certificate/certif-prop-1.png";
import bgCertifProp2Mobile from "../../assets/images/atpi/certificate/certif-prop-2.png";
import bgCertifProp3Mobile from "../../assets/images/atpi/certificate/certif-prop-3.png";
const CertificateAtpiProf = () => {
  return (
    <div className="position-relative">
      <h1 className="woding__certificate-prop">
        Gelar Profesi <em>Certified Professional Tax Technician</em> dari ATPI
      </h1>
      <em className="sub__wording-certificate-prop">(Bisa didapatkan jika Anda lulus Brevet Pajak AB di satukelas)</em>
      <div className="d-lg-flex justify-content-center mt-5 position-relative d-none" style={{ zIndex: "2" }}>
        <Image src={bgCertifPropDesktop} width="100%" height="95%" />
      </div>
      <div className="d-lg-none mt-5">
        <div className="mb-3 d-flex justify-content-center">
          <Image src={bgCertifProp1Mobile} className="w-100 w-md-50-apindo" height="100%" />
        </div>
        <div className="mb-3 d-flex justify-content-center">
          <Image src={bgCertifProp2Mobile} className="w-100 w-md-50-apindo" height="100%" />
        </div>
        <div className="mb-3 d-flex justify-content-center">
          <Image src={bgCertifProp3Mobile} className="w-100 w-md-50-apindo" height="100%" />
        </div>
      </div>
      <div className="bg__tiktik-certificate d-none d-lg-block">
        <Image src={iconTiktik} width="100%" height="100%" />
      </div>
    </div>
  );
};

export default CertificateAtpiProf;
