import { Col, Container, Image, Row } from "react-bootstrap";
// import person3 from "./../../assets/images/english/person3.webp";
import polkadot from "./../../assets/images/english/polkadot.png";
import { Link } from "react-router-dom";
import React from "react";

export default function PromoMiddle(props) {
  const { isLogin } = props;
  return (
    <div className="subs-promo__wrap">
      <div className="circle-ornament"></div>
      <div className="circle-ornament__purple"></div>
      <Container>
        <Row>
          <Col lg={5} className="position-relative">
            <Image src="./../../assets/images/english/person3.webp" id="person" />
            <Image src={polkadot} id="polkadot" />
          </Col>
          <Col lg={6} className="offset-lg-1">
            <div className="title-subs__promo">
              Ini Title <span>Butuh Brief</span> Untuk Wording Untuk Melengkapi
              Section Ini
            </div>
            <div className="subtitle-subs__promo">
              Ini subtitle <span>butuh brief</span> untuk wording untuk
              melengkapi pages showcase mini brevet ini. Ini subtitle{" "}
              <span>butuh brief</span> untuk wording untuk melengkapi pages
              showcase mini brevet ini.
            </div>
            <div className="cta-subs__promo">
              <Link to={isLogin?.userId ? "/dashboard/membership" : "/login"}>
                <button>Ini cta button</button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
