export const ORDERS_DATA_LINK = "orders";

//get provinces
export const ORDERS_PROVINCES = "ORDERS_PROVINCES";
export const ORDERS_PROVINCES_SUCCESS = "ORDERS_PROVINCES_SUCCESS";
export const ORDERS_PROVINCES_FAIL = "ORDERS_PROVINCES_FAIL";

//get cities
export const ORDERS_CITIES = "ORDERS_CITIES";
export const ORDERS_CITIES_SUCCESS = "ORDERS_CITIES_SUCCESS";
export const ORDERS_CITIES_FAIL = "ORDERS_CITIES_FAIL";

//get subdistricts
export const ORDERS_SUBDISTRICTS = "ORDERS_SUBDISTRICTS";
export const ORDERS_SUBDISTRICTS_SUCCESS = "ORDERS_SUBDISTRICTS_SUCCESS";
export const ORDERS_SUBDISTRICTS_FAIL = "ORDERS_SUBDISTRICTS_FAIL";

//get couriers
export const ORDERS_COURIERS = "ORDERS_COURIERS";
export const ORDERS_COURIERS_SUCCESS = "ORDERS_COURIERS_SUCCESS";
export const ORDERS_COURIERS_FAIL = "ORDERS_COURIERS_FAIL";

//get price
export const ORDERS_PRICE = "ORDERS_PRICE";
export const ORDERS_PRICE_SUCCESS = "ORDERS_PRICE_SUCCESS";
export const ORDERS_PRICE_FAIL = "ORDERS_PRICE_FAIL";

//post order
export const ORDERS_POST = "ORDERS_POST";
export const ORDERS_POST_SUCCESS = "ORDERS_POST_SUCCESS";
export const ORDERS_POST_FAIL = "ORDERS_POST_FAIL";
