import { Button, Col, Image, Row } from "react-bootstrap";
// import dots2 from "./../../assets/images/english/dots2.webp";
import { Link } from "react-router-dom";
import React from "react";

export default function Promo(props) {
  const { isLogin } = props;
  return (
    <Row className="position-relative">
      <Col lg={12}>
        <Row className="english-subs">
          <Col lg={9} className="pl-0 mb-3">
            <div className="title">
              Ini title <span> butuh brief </span> untuk <span>wording</span>
            </div>
            <div className="desc">Ini deskripsi butuh brief untuk wording</div>
          </Col>
          <Col lg={3} className="text-right">
            <a href="#" target="_blank">
              <Button className="cta">Ini CTA button</Button>
            </a>
          </Col>
        </Row>
      </Col>
      <Image className="position-absolute dots-img" src="./../../assets/images/english/dots2.webp" />
    </Row>
  );
}
