import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../assets/css/Account/index.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { isUserMembershipActive } from "../../helper/functions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { AuthData } from "../../helper/auth";

function TaxReview() {
  const [membershipData, setMembershipData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (AuthData().userId) getMembership();
  }, []);
  const getMembership = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getMembership();
      setMembershipData(response?.memberships);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };
  return (
    <Container className="my-5">
      <div className="tax-review__container">
        <div className="tax-review__placeholder">Indonesian Tax Review</div>
        {isUserMembershipActive(membershipData) ? (
          <iframe
            src="https://old.satukelas.com/indonesian-tax-review/"
            allowFullScreen={true}
            style={{ border: "none", width: "100%", height: "100vh" }}
            loading="lazy"
          ></iframe>
        ) : (
          <>
            <Link to="/membership">Beli membership</Link> untuk membaca konten
            ITR.
          </>
        )}
      </div>
    </Container>
  );
}

export default TaxReview;
