import React, { useState, useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import moment from "moment";
import { useInterval } from "react-interval-hook";
function QuizDetails({ quizTime, setDoneQuiz, title, minimizevalue }) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);
  const [mili, setMili] = useState(quizTime);
  useEffect(() => {
    setMili(moment.duration(quizTime).asMilliseconds());
  }, [quizTime]);
  const { start, stop, isActive } = useInterval(
    () => {
      if (mili <= 0) stop(true);
      const time = moment.utc(mili).format("DD:HH:mm:ss").split(":");
      const newMili = mili - 1000;
      setDays(time[0] - 1);
      setHours(time[1]);
      setMinutes(time[2]);
      setSeconds(time[3]);
      setMili(newMili);
    },
    1000,
    {
      onFinish: () => {
        // console.log("onFinish", stop, mili);
        if (mili <= 0) setDoneQuiz(true);
      },
    }
  );
  return (
    <>
      {minimizevalue ? (
        ""
      ) : (
        <Col xs={12} lg={12} className="quiz-details-main-col ">
          <div className="course_detail__quiz_wrapper mb-0">
            <a
              as={Button}
              variant="link"
              className="course_detail__quiz_wrapper__title mb-0"
              eventKey="0"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="course_detail__quiz__title">
                  {title.toUpperCase()}
                </div>
                <span style={{ flexShrink: 0 }}>
                  <div className="duration-text__quiz">
                    {/* {days} Hari */}
                    {hours} Jam {minutes} Menit {seconds} Detik
                  </div>

                </span>
              </div>
            </a>
          </div>
        </Col>
      )}
    </>
  );
}
export default QuizDetails;

// function useInterval(callback, delay) {
//   const savedCallback = useRef();

//   // Remember the latest callback.
//   useEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   // Set up the interval.
//   useEffect(() => {
//     let id = setInterval(() => {
//       savedCallback.current();
//     }, delay);
//     return () => clearInterval(id);
//   }, [delay]);
// }
