export const ASSIGNMENT_DATA_LINK = "assignments";

//get
export const ASSIGNMENT_DATA = "ASSIGNMENT_DATA";
export const ASSIGNMENT_DATA_SUCCESS = "ASSIGNMENT_DATA_SUCCESS";
export const ASSIGNMENT_DATA_ID_SUCCESS = "ASSIGNMENT_DATA_ID_SUCCESS";
export const ASSIGNMENT_DATA_FAIL = "ASSIGNMENT_DATA_FAIL";

//post
export const ASSIGNMENT_DATA_POST = "ASSIGNMENT_DATA_POST";
export const ASSIGNMENT_DATA_POST_SUCCESS = "ASSIGNMENT_DATA_POST_SUCCESS";
export const ASSIGNMENT_DATA_POST_FAIL = "ASSIGNMENT_DATA_POST_FAIL";

// put
export const ASSIGNMENT_DATA_PUT = "ASSIGNMENT_DATA_PUT";
export const ASSIGNMENT_DATA_PUT_SUCCESS = "ASSIGNMENT_DATA_PUT_SUCCESS";
export const ASSIGNMENT_DATA_PUT_FAIL = "ASSIGNMENT_DATA_PUT_FAIL";

// delete
export const ASSIGNMENT_DATA_DELETE = "ASSIGNMENT_DATA_DELETE";
export const ASSIGNMENT_DATA_DELETE_SUCCESS = "ASSIGNMENT_DATA_DELETE_SUCCESS";
export const ASSIGNMENT_DATA_DELETE_FAIL = "ASSIGNMENT_DATA_DELETE_FAIL";

// start
export const ASSIGNMENT_DATA_START = "ASSIGNMENT_DATA_START";
export const ASSIGNMENT_DATA_START_SUCCESS = "ASSIGNMENT_DATA_START_SUCCESS";
export const ASSIGNMENT_DATA_START_FAIL = "ASSIGNMENT_DATA_START_FAIL";
