import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Home from "../CourseFront/Home";
import Benefit from "../CourseFront/Benefit";
import {
  benefitBrevet,
  benefitScorm,
  persiapanSertifkasiScorm,
  testiomialBrevet,
  benefitChro,
} from "../CourseFront/Data";
import Purpose from "../CourseFront/Purpose";
import Client from "../CourseFront/Client";
import PersiapanSertifikasiAccordion from "../CourseFront/PersiapanSertifikasiAccordion";
import AccordionList from "../CourseFront/AccordionList";
import { LearningPathService } from "../../http/LearningPathHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import Certficate from "../CourseFront/Certficate";
import CoursesCard from "../CourseFront/TutorialCard";
import CoursesCardSlider from "../CourseFront/CoursesCardSlider";
import Contact from "../CourseFront/Contact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Image from "../../assets/images/home-certificationpage/certification-scorm-page/bg-home.png";
import BgIELTS from "../../assets/images/home-certificationpage/certification-scorm-page/bg-ielts.png";
import Icon from "../../assets/images/home-certificationpage/certification-scorm-page/icon.png";
// import ImageBenefitScorm from "../../assets/images/home-certificationpage/certification-scorm-page/bg-benefit.png";
import IconReallyEnglish from "../../assets/images/home-certificationpage/certification-scorm-page/really-english.png";
// import ImageCertificate from "../../assets/images/home-certificationpage/certification-scorm-page/CertificateTOEFLPreparation1.png";
import TryOut from "../CourseFront/TryOut";
import Icon2 from "../../assets/images/home-certificationpage/icon-ujian.png";
import { AuthData } from "../../helper/auth";
import { convertHours, slugify } from "../../helper/functions";
import {
  defaultThumb,
  env,
  membershipIdAll,
  membershipIdEnglish,
  testimoniEnglish,
  topic1,
  topic2,
  topic3,
  topic4,
} from "../../helper/constant";
import { UserService } from "../../http/UserHttp";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";
import { brevetService } from "../../http/BrevetHttp";
import CertficateATPI from "../CourseFront/CertificateATPI";
import imageChro from "../../../src/assets/images/certif-chro.png";
const MySwal = withReactContent(Swal);

const ScormPage = () => {
  const lpSelected = "lp27759649";
  const courseList = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { lp_id } = useParams();
  const [currIdx, setCurrIdx] = useState();
  const [topic1List, setTopic1List] = useState();
  const [topic2List, setTopic2List] = useState();
  const [topic3List, setTopic3List] = useState();
  const [topic4List, setTopic4List] = useState();
  const [activedMenu, handleActiveMenu] = useState(0);
  const [certificateCourse, setCertificateCourse] = useState([]);
  const [course, setCourse] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [activeKey, setActiveKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [userMembership, setUserMembership] = useState(null);
  const [coursesTutorial, setCoursesTutorial] = useState();
  const [isCompleteTopic1, setIsCompleteTopic1] = useState(true);
  const [isCompleteTopic2, setIsCompleteTopic2] = useState(true);
  const [isCompleteTopic3, setIsCompleteTopic3] = useState(true);
  const [isCompleteTopic4, setIsCompleteTopic4] = useState(true);
  const [learningPath, setLearningPath] = useState();
  const courses = useSelector((state) => state.courses);
  const coursesData = courses?.coursesData?.items;
  const checkParams = history.location.search.split("=");
  const isLogin = AuthData();
  const categoryId = env === "development" ? "ca65616695" : "ca29820744";
  const categoryIdChro = env === "development" ? "sc90670420" : "sc82075507";
  const [listSelected, setListSelected] = useState(benefitBrevet.listBenefit);
  const paramlpId = history.location.pathname.split("/")[2];

  useEffect(() => {
    if (topic1List?.length > 0) {
      for (let i = 0; i < topic1List.length; i++) {
        if (topic1List[i].progress !== 100) {
          setIsCompleteTopic1(false);
        } else {
          setIsCompleteTopic1(true);
        }
      }
    }
    if (topic2List?.length > 0) {
      for (let i = 0; i < topic2List.length; i++) {
        if (topic2List[i].progress !== 100) {
          setIsCompleteTopic2(false);
        } else {
          setIsCompleteTopic2(true);
        }
      }
    }
    if (topic3List?.length > 0) {
      for (let i = 0; i < topic3List.length; i++) {
        if (topic3List[i].progress !== 100) {
          setIsCompleteTopic3(false);
        } else {
          setIsCompleteTopic3(true);
        }
      }
    }
    if (topic4List?.length > 0) {
      for (let i = 0; i < topic4List.length; i++) {
        if (topic4List[i].progress !== 100) {
          setIsCompleteTopic4(false);
        } else {
          setIsCompleteTopic4(true);
        }
      }
    }
  }, [topic1List, topic2List, topic3List, topic4List]);

  // useEffect(() => {
  //   console.log("isCompleteTopic1", isCompleteTopic1);
  // }, [isCompleteTopic1]);

  useEffect(() => {
    if (learningPath) {
      const matchedCourses1 = [];
      const matchedCourses2 = [];
      const matchedCourses3 = [];
      const matchedCourses4 = [];
      for (const courseId of topic1) {
        const matchedCourse = learningPath?.courses.find(
          (course) => course.course_id === courseId
        );
        if (matchedCourse) {
          matchedCourses1.push(matchedCourse);
        }
      }
      setTopic1List(matchedCourses1);
      for (const courseId of topic2) {
        const matchedCourse = learningPath?.courses.find(
          (course) => course.course_id === courseId
        );
        if (matchedCourse) {
          matchedCourses2.push(matchedCourse);
        }
      }
      setTopic2List(matchedCourses2);
      for (const courseId of topic3) {
        const matchedCourse = learningPath?.courses.find(
          (course) => course.course_id === courseId
        );
        if (matchedCourse) {
          matchedCourses3.push(matchedCourse);
        }
      }
      setTopic3List(matchedCourses3);
      for (const courseId of topic4) {
        const matchedCourse = learningPath?.courses.find(
          (course) => course.course_id === courseId
        );
        if (matchedCourse) {
          matchedCourses4.push(matchedCourse);
        }
      }
      setTopic4List(matchedCourses4);
    }
  }, [learningPath]);

  useEffect(() => {
    getMembershipUser();
    // getTutorialDetail("tutorial", "tutorial", "c");
  }, []);

  const getTutorialDetail = async (type, discussion, value) => {
    try {
      const response = await brevetService.getCourseBrevetList(
        type,
        discussion,
        value,
        5,
        1
      );
      if (response.items.length > 0) {
        let tempStatus = response.items;
        setCoursesTutorial(tempStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (e) => {
    handleActiveMenu(parseInt(e));
  };

  const handleScroll = () => {
    courseList.current.scrollIntoView();
  };

  const handleShow = (index) => {
    setCurrIdx(index);
    setActiveKey(!activeKey);
  };

  const getMembershipUser = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      const response = await UserService.getMembership();
      setUserMembership(response.memberships);
    } catch (error) {}
  };

  const handleClick = () => {
    setActiveKey(!activeKey);
  };

  useEffect(() => {
    let newDataCertificate = [...certificateCourse];
    let newDataCourse = [...course];
    for (let i = 0; i < learningPath?.courses?.length; i++) {
      if (learningPath?.courses[i].course_type === "Sertifikasi") {
        newDataCertificate.push(learningPath?.courses[i]);
        setCertificateCourse(newDataCertificate);
      } else {
        newDataCourse.push(learningPath?.courses[i]);
        setCourse(newDataCourse);
      }
    }
  }, [learningPath]);

  useEffect(() => {
    dispatch(
      getCoursesDataThunk({
        type: "filter",
        param: "?size=6",
      })
    );
  }, []);

  useEffect(() => {
    if (userMembership?.length > 0) {
      const activeAllMembership = userMembership
        .filter((membership) => membership.status.toLowerCase() === "active")
        .reduce((mergedPaths, membership) => {
          const pathsArray =
            membership?.learning_path_ids?.length > 0
              ? membership.learning_path_ids.split(";")
              : "";
          mergedPaths.push(...pathsArray);
          return mergedPaths;
        }, []);

      if (activeAllMembership.includes(lp_id)) {
        setIsSubscribe(true);
      } else {
        setIsSubscribe(false);
      }

      // let caIdSelected =
      //   learningPath?.category[0]?.category_id === categoryId
      //     ? membershipIdEnglish
      //     : membershipIdAll;

      // console.log(caIdSelected);
      // console.log(userMembership);

      // for (let i = 0; i < userMembership.length; i++) {
      //   console.log(userMembership[i]);
      //   if (userMembership[i].catalogue_id === caIdSelected) {
      //     setIsSubscribe(true);
      //     console.log("haissssss");
      //   }
      // }
    }
  }, [userMembership, learningPath, isSubscribe]);

  useEffect(() => {
    getDetailLearningPathData(`/${lp_id}`);
  }, [lp_id]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (learningPath?.category[0]?.category_id === categoryId) {
      setListSelected(benefitScorm.listBenefit);
    } else if (learningPath?.sub_category_id === categoryIdChro) {
      setListSelected(benefitChro.listBenefit);
    } else {
      setListSelected(benefitBrevet.listBenefit);
    }
  }, [categoryId, learningPath]);

  const getDetailLearningPathData = async (id) => {
    dispatch(loadingStart());
    try {
      const response = await LearningPathService.getDetailLearningPath(
        `/${id}`
      );
      if (response) {
        setLearningPath(response);
        dispatch(loadingFinish());
      }
    } catch (error) {
      console.log(error);
    }
  };

  function renderSplideTestimoni() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <section className="my-5 position-relative">
        <Container className="ml-xl-5">
          <Col xl={9} className="mt-4 mb-5" id="testimoni">
            {learningPath?.schemes[0].scheme === "Brevet C" ||
            learningPath?.schemes[0].scheme === "Brevet AB" ||
            learningPath?.schemes[0].scheme === "Brevet AB+C" ||
            learningPath?.schemes[0].scheme === "CHRO BNSP (MSDM Lv Staff)" ? (
              <Splide
                options={{
                  rewind: true,
                  perPage: 1,
                  perMove: 1,
                  gap: "1.5rem",
                  pagination: true,
                  arrows: false,
                }}
              >
                {testiomialBrevet?.length > 0
                  ? testiomialBrevet?.map((value, index) => (
                      <SplideSlide key={index}>
                        <div className="testimoni-container">
                          <div className="testimoni-profile mb-3">
                            {value.photo === "" ? (
                              <div className="img-testimoni d-flex align-items-center justify-content-center align-items-center">
                                <span className="icon-user"></span>
                              </div>
                            ) : (
                              <img
                                alt="Profile User"
                                className="lazyload"
                                data-src={value.photo}
                              />
                            )}
                          </div>
                          <div className="testimoni-content">
                            <div className="text">{value.testimoni}</div>
                            <div className="info">
                              <div className="name">
                                {value.name} {value.company}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SplideSlide>
                    ))
                  : null}
              </Splide>
            ) : (
              <Splide
                options={{
                  rewind: true,
                  perPage: 1,
                  perMove: 1,
                  gap: "1.5rem",
                  pagination: true,
                  arrows: false,
                }}
              >
                {testimoniEnglish?.length > 0
                  ? testimoniEnglish?.map((value, index) => (
                      <SplideSlide key={index}>
                        <div className="testimoni-container">
                          <div className="testimoni-profile mb-3">
                            {value.photo === "" ? (
                              <div className="img-testimoni d-flex align-items-center justify-content-center align-items-center">
                                <span className="icon-user"></span>
                              </div>
                            ) : (
                              <img
                                alt="Profile User"
                                className="lazyload"
                                data-src={value.photo}
                              />
                            )}
                          </div>
                          <div className="testimoni-content">
                            <div className="text">{value.testimoni}</div>
                            <div className="info">
                              <div className="name">
                                {value.name} - {value.company}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SplideSlide>
                    ))
                  : null}
              </Splide>
            )}
          </Col>
        </Container>
      </section>
    );
  }

  const handleClaim = async () => {
    dispatch(loadingStart());
    if (
      learningPath.title.toLowerCase().includes("toeic") ||
      learningPath.title.toLowerCase().includes("toefl") ||
      learningPath.title.toLowerCase().includes("ielts")
    ) {
      try {
        const response = await LearningPathService.getClaimCertificateScorm(
          learningPath?.path_id
        );
        if (response) {
          displayPopup(
            false,
            `Berhasil Mengklaim Sertifikat ${
              learningPath.title.toLowerCase().includes("toeic")
                ? "TOEIC"
                : learningPath.title.toLowerCase().includes("toefl")
                ? "TOEFL"
                : "IELTS"
            }`,
            true,
            "Ok",
            "redirect"
          );
          dispatch(loadingFinish());
        }
      } catch (error) {
        console.log(error);
        dispatch(loadingFinish());
        displayPopup(
          false,
          `Gagal Mengklaim Sertifikat ${
            learningPath.title.toLowerCase().includes("toeic")
              ? "TOEIC"
              : learningPath.title.toLowerCase().includes("toefl")
              ? "TOEFL"
              : "IELTS"
          }`,
          true,
          "Ok"
        );
      }
    } else {
      let payload = {
        path_id: learningPath?.path_id,
        user_id: isLogin?.userId,
      };
      try {
        const response = await LearningPathService.postClaimCertificate(
          payload
        );
        if (response) {
          displayPopup(
            false,
            "Berhasil Mengklaim Sertifikat Learning Path",
            true,
            "Ok",
            "redirect"
          );
          dispatch(loadingFinish());
        }
      } catch (error) {
        console.log(error);
        dispatch(loadingFinish());
        displayPopup(
          false,
          "Gagal Mengklaim Sertifikat Learning Path",
          true,
          "Ok"
        );
      }
    }
  };

  function displayPopup(cancel, title, confirm, confirmMsg, command) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (command === "redirect") {
        if (value.isConfirmed) {
          history.push(
            `/download/${slugify(learningPath.title)}/${learningPath.path_id}/`
          );
        }
      }
    });
  }
  return (
    <Container className="lesson-container position-relative">
      <Home image={paramlpId === "lp36347848" ? BgIELTS : Image}>
        <Home.Header title={learningPath?.title}>
          {learningPath?.description ? learningPath?.description : "-"}
        </Home.Header>
        <Home.Content
          image={Icon}
          title="Satukelas"
          durationTitle="Durasi Pelatihan"
          time={
            learningPath?.total_duration
              ? convertHours(learningPath?.total_duration)
              : "-"
          }
        />
        <Home.Footer />
      </Home>
      <Benefit
        type="nonmember"
        image={learningPath?.thumbnail ? learningPath?.thumbnail : defaultThumb}
        // key={index}
      >
        <Benefit.Header isSubscribe={isSubscribe} price={learningPath?.price} />
        <Benefit.Content
          isLogin={isLogin}
          isSubscribe={isSubscribe}
          learningPath={learningPath}
          handleClaim={handleClaim}
        >
          <div className="title_floating-card">
            {learningPath?.category[0]?.category_id === categoryId
              ? "Benefit:"
              : "Keunggulan Program Ini :"}
          </div>
          {listSelected &&
            listSelected?.map((item, index) => (
              <div className="d-flex my-3" key={index}>
                <img
                  src={item.image}
                  className="mr-2 mt-1 img__icon-wrap"
                  alt=""
                  width={15}
                  height={15}
                />
                <p className="fw-400 mb-0 desc__benefit-sticky">{item.desc}</p>
              </div>
            ))}
          {learningPath?.category[0]?.category_id === categoryIdChro && (
            <p className="footer__benefit-chro w-100">
              Asesmen dan sertifikat profesi BNSP diberikan oleh
              <b>LSP Unggul Indonesia</b>
            </p>
          )}
        </Benefit.Content>
      </Benefit>
      <Purpose title="Tujuan Pembelajaran">
        {learningPath?.objectives.length > 0 ? (
          learningPath?.objectives?.map((e, i) => {
            return (
              <Col md={6} key={i}>
                <div className="d-block my-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: e }}
                    className="purpose__content"
                  />
                </div>
              </Col>
            );
          })
        ) : (
          <Col md={12} className="px-2">
            Tujuan pembelajaran tidak ditemukan
          </Col>
        )}
      </Purpose>
      <Client page="courses" col={8} />
      <PersiapanSertifikasiAccordion title={learningPath?.title}>
        {learningPath?.category[0]?.category_id === categoryId && (
          <div className="d-md-flex align-items-center mb-3" ref={courseList}>
            <div className="col-12 col-md-3 pl-0 mb-3 mb-md-0">
              <img
                src={IconReallyEnglish}
                alt=""
                width="100%"
                height={70}
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="col-12 col-md-9">
              <em className="mt-4 fw-400 text">
                Course materials are developed by <b>Reallyenglish.com</b> in
                partnership with{" "}
                <b>Pearson, British Council, Cambridge University Press,</b> and
                other leading ELT experts.
              </em>
            </div>
          </div>
        )}
        {lpSelected === learningPath?.path_id ? (
          <>
            {topic1List?.length > 0 ? (
              <AccordionList
                id="scorm"
                title="TOEFL iTP Prepartion Course Level 2"
                type="course"
                topicAccordion={1}
                idx={0}
                currIdx={currIdx}
                data={topic1List}
                list={learningPath}
                handleSelect={handleSelect}
                isSubscribe={isSubscribe}
                handleShow={handleShow}
                handleClick={handleClick}
                activeKey={activeKey}
              />
            ) : null}
            {topic2List?.length > 0 ? (
              <AccordionList
                id="scorm"
                title="TOEFL iTP Prediction Test Level 2"
                type="course"
                topicAccordion={2}
                isCompleteTopic1={isCompleteTopic1}
                idx={1}
                currIdx={currIdx}
                data={topic2List}
                list={learningPath}
                handleSelect={handleSelect}
                isSubscribe={isSubscribe}
                handleShow={handleShow}
                handleClick={handleClick}
                activeKey={activeKey}
              />
            ) : null}
            {topic3List?.length > 0 ? (
              <AccordionList
                id="scorm"
                title="TOEFL iTP Prepartion Course Level 1"
                type="course"
                topicAccordion={1}
                isCompleteTopic1={isCompleteTopic1}
                isCompleteTopic2={isCompleteTopic2}
                idx={2}
                currIdx={currIdx}
                data={topic3List}
                list={learningPath}
                handleSelect={handleSelect}
                isSubscribe={isSubscribe}
                handleShow={handleShow}
                handleClick={handleClick}
                activeKey={activeKey}
              />
            ) : null}
            {topic4List?.length > 0 ? (
              <AccordionList
                id="scorm"
                title="TOEFL iTP Prediction Test Level 1"
                type="course"
                topicAccordion={2}
                isCompleteTopic1={isCompleteTopic1}
                isCompleteTopic2={isCompleteTopic2}
                isCompleteTopic3={isCompleteTopic3}
                idx={3}
                currIdx={currIdx}
                data={topic4List}
                list={learningPath}
                handleSelect={handleSelect}
                isSubscribe={isSubscribe}
                handleShow={handleShow}
                handleClick={handleClick}
                activeKey={activeKey}
              />
            ) : null}
          </>
        ) : (
          <>
            {course?.length > 0 ? (
              <AccordionList
                id="scorm"
                title={learningPath?.title}
                type="course"
                idx={0}
                currIdx={currIdx}
                data={course}
                list={learningPath}
                handleSelect={handleSelect}
                isSubscribe={isSubscribe}
                handleShow={handleShow}
                handleClick={handleClick}
                activeKey={activeKey}
              />
            ) : null}
            {certificateCourse?.length > 0 ? (
              <AccordionList
                id="test"
                title={
                  learningPath?.path_id === "lp74858302"
                    ? "TOEIC Prediction Test"
                    : learningPath?.title
                }
                type="certification"
                idx={1}
                currIdx={currIdx}
                data={certificateCourse}
                list={learningPath}
                handleSelect={handleSelect}
                isSubscribe={isSubscribe}
                handleShow={handleShow}
                handleClick={handleClick}
                activeKey={activeKey}
              />
            ) : null}
          </>
        )}
      </PersiapanSertifikasiAccordion>
      <TryOut
        title="Ujian Sertifikasi"
        icon={Icon2}
        data={learningPath}
        heading="Habis Belajar, Saatnya Ujian!"
        subheading={`Ujian berdurasi 115 menit. Siapkan diri Anda. Ujian akan diulang jika Anda meninggalkan laman ujian. Selamat ujian!`}
        titleButton="Mulai Ujian!"
        type="scorm"
      />
      {/* {lp_id === chro ? <Certficate image={imageChro} /> : <> */}
      {learningPath?.certificate_template_url && (
        <Certficate image={learningPath.certificate_template_url} />
      )}
      <div>{loaded ? renderSplideTestimoni() : null}</div>
      {/* <CoursesCard type="scrom" data={coursesTutorial} /> */}
      {/* {coursesData?.length > 0 && <CoursesCardSlider data={coursesData} />} */}
      <Contact />
    </Container>
  );
};

export default ScormPage;
