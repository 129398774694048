import React, { useEffect, useState } from "react";
import "../../assets/css/Home/membershipIndividu.css";
import hero from '../../assets/images/membership/hero.png';
import description from '../../assets/images/membership/description.png';
import { Button, Accordion, Carousel } from "react-bootstrap";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { faqMembership, pricingPlan, pros_membership, serviceMembership, testimoni, testimoniUser } from "../../helper/constant";
import MembershipList from "../../components/Membership/MembershipList";
import { Link } from "react-router-dom";
import FooterMitra from "../../components/FooterMitra/FooterMitra";

function MembershipLanding() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);
  // 1140px
  return (
    <>
      <div className="hero-background">
        <div className="container-membership">
          <div className="membership-heroshot">
            <div className="hero-left__side">
              <div className="title-hero">
                Upgrade Skill untuk Karier yang Lebih Baik
              </div>
              <div className="subtitle-hero">
                Taklukan skill yang diperlukan di masa depan untuk perluas jenjang karier Anda melalui berbagai program pelatihan yang telah disesuaikan dengan kebutuhan industri
              </div>
              <div className="button-hero">
                <button className="blue-text">Langganan Sekarang</button>
              </div>
            </div>
            <div className="hero-right__side">
              <img src={hero} />
            </div>
          </div>
        </div>
      </div>
      <div className="container-membership program-width">
        <div className="program-list">
          {serviceMembership.map((item, index) => {
            return (
              <div key={index} className="program-item">
                <div className="program-title">
                  {item.title}
                </div>
                <div className="program-subtitle" dangerouslySetInnerHTML={{ __html: item.desc }} />
                <a className="program-link redirect">{item.button}</a>
              </div>
            )
          })}

        </div>
        <div className="program-list__mobile">
          <Carousel controls={false}>
            {serviceMembership.map((item, index) => {
              return (
                <Carousel.Item>
                  <div key={index} className="program-item">
                    <div className="program-title">
                      {item.title}
                    </div>
                    <div className="program-subtitle" dangerouslySetInnerHTML={{ __html: item.desc }} />
                    <a className="program-link redirect">{item.button}</a>
                  </div>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      </div>
      <div className="description-background">
        <div className="container-membership">
          <div className="description-image">
            <img src={description} />
          </div>
          <div className="description-wrapper">
            <div className="description-title">
              Pelatihan Online, Strategi Tepat untuk Peningkatan Kompetensi
            </div>
            <div className="description-subtitle">
              "Seiring makin cepatnya perubahan dunia ketenagakerjaan akibat proses otomasi industri dan dampak Covid-19, yang mendorong percepatan penggunaan teknologi digital dan online, pelatihan vokasi menjadi solusi tepat agar pasar kerja tetap link and match. Re-desain kurikulum serta melakukan pendekatan <span>
                human digital skill
              </span> {" "}
              dan <span>
                metode blended learning
              </span>{" "}
              menjadi salah satu strategi tepat dalam melakukan pelatihan vokasi tersebut."
            </div>
            <br />
            <br />
            <div className="description-person">
              {" "}Anwar Sanusi,
            </div>
            <div className="description-person">
              Sekretaris Jenderal Kemnaker
            </div>
          </div>
        </div>
      </div>
      <div className="testimoni-background">
        <div className="container-membership">
          <div className="testimoni-wrapper">
            <div className="testimoni-title">
              Lebih dari 400+ Perusahaan Bergabung satukelas
            </div>
            <div className="testimoni-subtitle">
              Ratusan perusahaan dari berbagai industri telah menjadi mitra satukelas
            </div>
            <div className="testimoni-desktop">
              {testimoniUser.map((item, index) => {
                return (
                  <div className="testimoni-card">
                    <div className="testimoni-text">
                      {item.desc}
                    </div>
                    <div className="testimoni-user">
                      {item.people}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="testimoni-mobile">
              <Carousel controls={false}>
                {testimoniUser.map((item, index) => {
                  return (

                    <Carousel.Item>
                      <div className="testimoni-card">
                        <div className="testimoni-text">
                          {item.desc}
                        </div>
                        <div className="testimoni-user">{item.people}
                        </div>
                      </div>
                    </Carousel.Item>
                  )
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="container-membership program-width">
        <div className="pricelist-wrapper">
          <div className="pricelist-title">
            Sudah siap upskilling?
          </div>
          <div className="pricelist-subtitle">Pilih Paket yang Tepat untuk Perusahaan
          </div>
          <div className="pricelist-card__wrapper">
            {pricingPlan.map((item, index) => {
              return (
                <div key={index} className={`pricelist-card ${index === 2 && 'pricelist-recommendation'}`}>
                  <div className="pricelist-text">
                    {item.title}
                  </div>
                  <div className="pricelist-subtitle__card">
                    {item.subtitle}
                  </div>
                  <div className={`pricelist-price ${index === 2 && 'pricelist-recommendation__price'}`}>

                    {item.price}<span>per tahun</span>
                  </div>
                  <div className="pricelist-button">
                    <button className={`white-text ${index === 2 && 'button-recommendation'}`}>
                      Berlangganan Sekarang
                    </button>
                  </div>
                  <div className="pricelist-info">Sudah Termasuk</div>
                  <div className="pricelist-benefit">
                    <ul id="membership" className={`${index === 2 && 'list-recommendation'}`}>
                      {item.bundling.map((e, idx) => {
                        return (
                          <li key={idx}>
                            <div dangerouslySetInnerHTML={{
                              __html: e,
                            }} />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/* <div className="container-membership program-width">
        <div className="faq-text">
          FAQ
        </div>
        <div className="faq-title">
          Pertanyaan yang sering muncul
        </div>
        <div className="faq-wrapper">
          {faqMembership.map((item, index) => {
            return (
              <div className="faq-card">

                <Accordion key={index} className="mb-0">
                  <Accordion.Toggle
                    as={Link}
                    className="faq-toggle"
                    eventKey="0"
                  >
                    <div className="faq-title__card">

                      {item.title}
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="faq-desc__card">
                      {item.desct}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            )
          })}
        </div>
      </div> */}
      <FooterMitra />
    </>
  );
}

export default MembershipLanding;
