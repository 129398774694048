import React from "react";
import iconRight from "../../assets/images/icon-right.png";
import { Link } from "react-router-dom";
import iconRightHover from "../../assets/images/icon-right-hover.png";
export default function AboutMenu(props) {
     const { id, handleMouseHover, handleMouse, toggleSubMenu, hoverIcon } = props;
    return (
        <div
        id="lainnya"
        className={`third header_left__item header_left__item-sertifikasi position-relative ${
          id === "lainnya" ? "interaction-active" : id === "search" ? "" : ""
        } dropdown mx-1`}
        to="#"
        onMouseOver={handleMouse}
      >
        Tentang Satukelas
        <ul
          id="menu"
          className={`mega-menu__container pt-3`}
          style={{ top: "55px" }}
        >
          <Link to="/about-us/" className="list_menu_nav border-bottom p-3">
            <div className="d-flex align-items-center justify-content-between w-100">
              <p>Sekilas Satukelas</p>
            </div>
          </Link>
          <li
            className="list_menu_nav border-bottom menu-hover p-3"
            onMouseEnter={() => handleMouseHover("dlc")}
            onMouseLeave={handleMouseHover}
          >
            <div className="d-flex align-items-center justify-content-between w-100">
              <Link to="#" className="d-block text-left">
                Digital Learning Center
              </Link>
              <span>
                <img
                  src={hoverIcon === "dlc" ? iconRightHover : iconRight}
                  alt="icon-right"
                />
              </span>
            </div>

            <ul id="sub_menu" className={`submenu pt-3`} style={{ top: "24%" }}>
              <li className="border-bottom">
                <Link to="/cdlc">Untuk Perusahaan</Link>
              </li>
              <li className="border-bottom">
                <Link to="/dlcs">Untuk Institusi Pendidikan</Link>
              </li>
            </ul>
          </li>
          <li
            className="list_menu_nav border-bottom menu-hover p-3"
            onClick={() => toggleSubMenu(6)}
            onMouseEnter={() => handleMouseHover("mitra")}
            onMouseLeave={handleMouseHover}
          >
            <div className="d-flex align-items-center justify-content-between w-100">
              <Link to="#" className="d-block text-left">
                Mitra Satukelas
              </Link>
              <span>
                <img
                  src={hoverIcon === "mitra" ? iconRightHover : iconRight}
                  alt="icon-right"
                />
              </span>
            </div>

            <ul id="sub_menu" className={`submenu pt-3`} style={{ top: "47%" }}>
              <li className="border-bottom">
                <Link to="/page-atpi">ATPI</Link>
              </li>
            </ul>
          </li>

          <li className="list_menu_nav p-3">
            <div className="d-flex align-items-center justify-content-between w-100">
              <p>Yang Sering Ditanya (FAQ)</p>
            </div>
          </li>
        </ul>
      </div>
    )
}