import React, { useEffect, useState } from "react";
import starDisable from "./../assets/images/star-disable.png";
import star from "./../assets/images/star.png";
import starHalf from './../assets/images/half-star.png';
function RatingCourse({ item, detail }) {

  const [rating, setRating] = useState();

  useEffect(() => {
    let ratingLength = parseInt(item?.rating?.score);
    let rating = 5;
    let resultRatingLength = rating - ratingLength;
    if (typeof resultRatingLength === "number", resultRatingLength) {
      setRating(resultRatingLength)
    }
  }, [item]);

  return (
    <div>
      <div className="d-flex mb-2 align-items-center">
        {/* this condition will show in feedback section at course detail */}
        {detail &&
          <>
            {item > 0
              && [...Array(parseInt(item))].map((value, index) => {
                return (
                  <div key={index} className="mr-3">
                    <img alt="star" src={star} width={24} height={24} />
                  </div>
                );
              })}
            {[...Array(5 - item)].map((value, index) => {
              return (
                <div key={index} className="mr-3">
                  <img alt="star-disable" src={starDisable} width={24} height={24} />
                </div>
              );
            })}
          </>
        }

        {/* this condition will show in rating section */}
        {!detail &&
          <>
            <div className="d-flex">
              {/* {parseInt(item?.rating?.score) > 0
                && [...Array(parseInt(item?.rating?.score?.toFixed(0)))].map((value, index) => {
                  return (
                    item?.rating?.score?.toFixed(1).split('.')[1] >= 5 &&
                      parseInt(item?.rating?.score?.toFixed(0)) - 1 === index
                      ?
                      <div key={index} className="mr-3">
                        <img alt="star-half" src={starHalf} width={24} height={24} />
                      </div>
                      :
                      <div key={index} className="mr-3">
                        <img alt="star" src={star} width={24} height={24} />
                      </div>
                  );
                })
              } */}

              {rating !== undefined &&
                [...Array(5 - parseInt(item?.rating?.score?.toFixed(0)))].map((value, index) => {
                  return (
                    <div key={index} className="mr-3">
                      <img alt="star-disable" src={starDisable} width={24} height={24} />
                    </div>
                  );
                })}
              <div className="score_title">
                {
                  item?.rating?.score === 0.0
                    ?
                    item?.rating?.score.toFixed(0)
                    :
                    item?.rating?.score.toFixed(1)
                }
              </div>
            </div>
          </>
        }
      </div>
      {
        !detail &&
        <>
          <div className="text_rating">Telah dirating oleh {item?.rating?.count} orang</div>
          <div className="d-flex mt-3 mb-4">
            {item.feedbacks?.slice(0, 3)?.map((e, i) => {
              return (
                <div className={`bubble-rating__course ${i === 0 ? null : i === 1 ? 'bubble-stick-1' : 'bubble-stick-2'}`}>{e?.name.charAt(0).toUpperCase()}</div>
              )
            })}

            {item.feedbacks?.length > 3 &&
              <div className='bubble-rating__course bubble-stick-3'>
                <b>+{item.feedbacks?.length - 3}</b>
              </div>
            }
          </div>
        </>
      }
    </div >
  )
}
export default RatingCourse;
