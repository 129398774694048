import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { removePostPaymentsData } from "../redux/actions/PaymentsActions";
import { postPaymentsDataThunk } from "../redux/thunks/PaymentsThunks";
import { AuthData } from "../helper/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function Topup({ setTopup }) {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    bank: "bni",
    gross_amount: "",
    custom_gross_amount: "",
    payment_type: "bank_transfer",
  });
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };
  const topupHandler = () => {
    let gross_amount;
    if (data.custom_gross_amount !== "") {
      gross_amount = parseInt(data.custom_gross_amount);
    } else {
      gross_amount = parseInt(data.gross_amount);
    }
    const payload = {
      user_id: AuthData().userId,
      gross_amount: gross_amount,
      bank: data?.bank,
    };
    dispatch(postPaymentsDataThunk(payload));
    setTopup(false);
  };

  return (
    <div className="modal-topup__container">
      <Form>
        <Form.Group className="modal-topup__section">
          <Form.Label className="modal-topup__title">
            Pilih Nominal Topup
          </Form.Label>
          <div className="modal-topup__selection">
            <Form.Check
              type="radio"
              id="1"
              value="20000"
              name="gross_amount"
              label="Rp. 20.000"
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="2"
              value="50000"
              name="gross_amount"
              label="Rp. 50.000"
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="3"
              name="gross_amount"
              value="100000"
              label="Rp. 100.000"
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              id="4"
              value="200000"
              name="gross_amount"
              label="Rp. 200.000"
              onChange={handleChange}
            />
            <Form.Check
              id="5"
              type="radio"
              name="gross_amount"
              value="300000"
              label="Rp. 300.000"
              onChange={handleChange}
            />
            <Form.Check
              id="6"
              type="radio"
              name="gross_amount"
              value="500000"
              label="Rp. 500.000"
              onChange={handleChange}
            />
            <Form.Check
              id="7"
              type="radio"
              name="gross_amount"
              value="1200000"
              label="Rp. 1.200.000"
              onChange={handleChange}
            />
          </div>

          <div className="modal-topup__input">
            <Form.Label className="modal-topup__title">
              Masukkan Nominal Topup
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Masukkan Jumlah Topup"
              name="custom_gross_amount"
              id="custom_gross_amount"
              onChange={handleChange}
              value={data.custom_gross_amount}
            />
          </div>
        </Form.Group>
        <Form.Group className="modal-topup__section">
          <Form.Label className="modal-topup__title">
            Pilih Metode Pembayaran
          </Form.Label>
          <Form.Check
            id="7"
            type="radio"
            value="bank_transfer"
            name="payment_type"
            className="modal-topup__input"
            label="Transfer Bank"
            onChange={handleChange}
            defaultChecked
          />
        </Form.Group>
        <Form.Group className="modal-topup__section mb-5">
          <Form.Label className="modal-topup__title">
            Pilih Jenis Bank
          </Form.Label>
          <Form.Check
            id="8"
            type="radio"
            name="bank"
            value="bni"
            className="modal-topup__input"
            label="Bank BNI"
            onChange={handleChange}
            defaultChecked
          />
          <Form.Check
            id="9"
            type="radio"
            name="bank"
            value="mandiri"
            className="modal-topup__input"
            label="Bank Mandiri"
            onChange={handleChange}
          />
          <Form.Check
            id="10"
            type="radio"
            name="bank"
            value="permata"
            className="modal-topup__input"
            label="Bank Permata"
            onChange={handleChange}
          />
        </Form.Group>
        <div className="modal-topup__container-button">
          <Button
            className="modal-topup__button-cancel"
            onClick={() => setTopup(false)}
          >
            Batal
          </Button>
          <Button className="modal-topup__button" onClick={topupHandler}>
            Isi Saldo
          </Button>
        </div>
      </Form>
    </div>
  );
}
export default Topup;
