import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion, Form } from "react-bootstrap";
import "../../assets/css/Courses/index.css";
import TimelineCalendar from "../../components/TimelineCalendar";
import CourseCard from "../../components/CourseCard";
import FilterCard from "../../components/CoursePage/FilterCard";
import Pagination from "../../components/Layout/Pagination";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWebinarsDataThunk } from "../../redux/thunks/WebinarsThunks";
import moment from "moment";
import { months } from "../../helper/constant";

function WebinarSearch() {
  const { data_type, filter_param } = useParams();
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(null);
  const [filterCourse, setFilterCourse] = useState([]);
  const [filterCategory, setFilterCategory] = useState();
  const [filterCourseLive, setFilterCourseLive] = useState([data_type]);
  const [dataType, setDataType] = useState(data_type);
  const [keywords, setKeywords] = useState("");
  const [categoriesFilter, setCategoriesFilter] = useState("Semua");
  const [tab, setTap] = useState("month");
  const [webinarsPagination, setWebinarsPagination] = useState();
  const [webinarsData, setWebinarsData] = useState();
  const [coursesData, setCoursesData] = useState();
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(9); //pagination size
  const [viewCert, setViewCert] = useState("grid");

  const history = useHistory();
  const webinars = useSelector((state) => state.webinars);

  const courses = useSelector((state) => state.courses);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(filterCourseLive, data_type);
  }, [data_type, filterCourseLive]);

  const handleShow = () => {
    setShow(!show);
  };
  const handleChangeView = (e) => {
    e.preventDefault();
    setViewCert(e.target.value);
  };
  const handleChangeSort = (e) => {
    dispatch(
      getWebinarsDataThunk({
        type: "filter",
        param: `?sort=${e.target.value}&size=${size}&page=1`,
      })
    );
  };
  const handleFilterWebinar = (e) => {
    const currentYear = new Date().getFullYear();
    dispatch(
      getWebinarsDataThunk({
        type: "filter",
        param: `?month=${currentYear}-${e.target.value}&size=9&${
          dataType !== "semua" && `type=${dataType}`
        }`,
      })
    );
  };

  function dateChanges(sDate, sTab) {
    let currentTab = sTab;
    if (currentTab) {
      setTap(sTab);
    } else {
      currentTab = tab;
    }
    let date_from = moment(new Date()).format("YYYY-MM-DD");
    let date_to = moment(new Date()).format("YYYY-MM-DD");
    switch (currentTab) {
      case "month":
        date_from = moment(sDate.start).format("YYYY-MM-DD");
        date_to = moment(sDate.end).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      case "week":
        date_from = moment(sDate[0]).format("YYYY-MM-DD");
        date_to = moment(sDate[6]).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      case "day":
        date_from = moment(sDate[0]).format("YYYY-MM-DD");
        date_to = moment(sDate[0]).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      case "agenda":
        date_from = moment(sDate.start).format("YYYY-MM-DD");
        date_to = moment(sDate.end).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      default:
        console.log("default");
        break;
    }
  }

  const getCurrentData = (date_from, date_to) => {
    if (data_type === "webinar") {
      dispatch(
        getWebinarsDataThunk({
          type: "filter",
          param: `?type=webinar&size=50`,
        })
      );
    } else if (data_type === "konsultasi") {
      dispatch(
        getWebinarsDataThunk({
          type: "filter",
          param: `?type=konsultasi&size=50`,
        })
      );
    } else if (data_type === "semua") {
      dispatch(
        getWebinarsDataThunk({
          type: "filter",
          param: `?size=50`,
        })
      );
    }
  };

  useEffect(() => {
    if (data_type) setDataType(data_type);
  }, [data_type]);

  useEffect(() => {
    // console.log(filter_param);
    if (filter_param) {
      const current = getCurrentState(filter_param);
      const newFilter = "?" + filter_param;
      setFilter(newFilter);
      if (current.category) setCategoriesFilter(current.category);
      if (current.keyword) setKeywords(current.keyword);
    }
  }, [filter_param]);

  useEffect(() => {
    let newDataType = `?size=${size}&page=1`;
    if (filter) newDataType = `&size=${size}&page=1`;
    // const currentMonth = 1 + moment(new Date()).month();
    // const currentYear = moment(new Date()).year();
    // const nextMonth = currentMonth == 12 ? 1 : currentMonth + 1;
    // const nextYear = currentMonth == 12 ? currentYear + 1 : currentYear;
    // const date_from = `${currentYear}-${currentMonth}-1`;
    // const date_to = `${nextYear}-${nextMonth}-1`;

    // if (filter) {
    //   console.log(filter, "<=== undefined filter");

    //   if (data_type === "webinar") {
    //     if (filterCategory === undefined) {
    //       dispatch(
    //         getWebinarsDataThunk({
    //           type: "filter",
    //           param: `?type=webinar&size=${
    //             viewCert === "grid" ? size : "50"
    //           }&page=1`,
    //         })
    //       );
    //     } else {
    //       dispatch(
    //         getWebinarsDataThunk({
    //           type: "filter",
    //           param: `?${
    //             filterCategory ? filterCategory.split("?")[1] : ""
    //           }&type=webinar&size=${viewCert === "grid" ? size : "50"}&page=1`,
    //         })
    //       );
    //     }
    //   }
    //   if (data_type === "konsultasi") {
    //     if (filterCategory === undefined) {
    //       dispatch(
    //         getWebinarsDataThunk({
    //           type: "filter",
    //           param: `?type=konsultasi&size=${
    //             viewCert === "grid" ? size : "50"
    //           }&page=1`,
    //         })
    //       );
    //     } else {
    //       dispatch(
    //         getWebinarsDataThunk({
    //           type: "filter",
    //           param: `?${
    //             filterCategory ? filterCategory.split("?")[1] : ""
    //           }&type=konsultasi&size=${
    //             viewCert === "grid" ? size : "50"
    //           }&page=1`,
    //         })
    //       );
    //     }
    //   }
    //   if (data_type === "semua") {
    //     if (filterCategory === undefined) {
    //       dispatch(
    //         getWebinarsDataThunk({
    //           type: "filter",
    //           param: `?size=${viewCert === "grid" ? size : "50"}&page=1`,
    //         })
    //       );
    //     } else {
    //       dispatch(
    //         getWebinarsDataThunk({
    //           type: "filter",
    //           param: `?${
    //             filterCategory ? filterCategory.split("?")[1] : ""
    //           }&size=${viewCert === "grid" ? size : "50"}&page=1`,
    //         })
    //       );
    //     }
    //   }
    // }
    if (filter) {
      if (filter === "?type=webinar") {
        // if (filterCategory === undefined) {
        //   dispatch(
        //     getWebinarsDataThunk({
        //       type: "filter",
        //       param: `?type=webinar&size=${
        //         viewCert === "grid" ? size : "50"
        //       }&page=1`,
        //     })
        //   );
        // } else {
        //   dispatch(
        //     getWebinarsDataThunk({
        //       type: "filter",
        //       param: `?${
        //         filterCategory ? filterCategory.split("?")[1] : ""
        //       }&type=webinar&size=${viewCert === "grid" ? size : "50"}&page=1`,
        //     })
        //   );
        // }
        dispatch(
          getWebinarsDataThunk({
            type: "filter",
            param: `${
              filterCategory ? `?${filterCategory.split("?")[1]}` : ""
            }${filterCategory ? "&" : "?"}type=webinar&size=${
              viewCert === "grid" ? size : "50"
            }&page=1`,
          })
        );
      }

      if (filter === "?type=konsultasi") {
        dispatch(
          getWebinarsDataThunk({
            type: "filter",
            param: `${
              filterCategory ? `?${filterCategory.split("?")[1]}` : ""
            }${filterCategory ? "&" : "?"}type=konsultasi&size=${
              viewCert === "grid" ? size : "50"
            }&page=1`,
          })
        );
      }

      if (
        filter === "?type=webinar,konsultasi" ||
        filter === "?type=konsultasi,webinar"
      ) {
        dispatch(
          getWebinarsDataThunk({
            type: "filter",
            param: `${
              filterCategory ? `?${filterCategory.split("?")[1]}` : ""
            }${filterCategory ? "&" : "?"}size=${
              viewCert === "grid" ? size : "50"
            }&page=1`,
          })
        );
      }
    }
  }, [filter, dataType, viewCert, filterCategory]);
  useEffect(() => {
    setWebinarsPagination(webinars.webinarsData?.pagination);
    if (webinars.webinarsData?.items) setWebinarsData(webinars.webinarsData);
  }, [webinars]);

  useEffect(() => {
    setWebinarsPagination(courses.coursesData?.pagination);
    if (courses.coursesData?.items) setCoursesData(courses.coursesData);
  }, [courses]);

  function searchBtnHandler() {
    let counter = 0;
    let newFilter = "";

    if (categoriesFilter !== "All") {
      counter++;
      newFilter += filterTemplate(counter, `category=${categoriesFilter}`);
    }

    if (keywords && keywords !== "") {
      counter++;
      newFilter += filterTemplate(counter, `keyword=${keywords}`);
    }
    history.push(
      `/webinar-list/${dataType.toLowerCase()}/${
        newFilter ? newFilter.split("?")[1] : ""
      }`
    );
    setActive(1);
    setFilter(newFilter);
  }

  function paginationSelect(newActive) {
    setActive(newActive);
    setWebinarsData();
    let params = `${filter}&size=${size}&page=${newActive}`;
    if (filter == "none" || filter == "")
      params = `?size=${size}&page=${newActive}`;
    if (dataType.toLowerCase() === "webinar") {
      dispatch(
        getWebinarsDataThunk({
          type: "filter",
          param: params + `&type=webinar`,
        })
      );
    }
    if (dataType.toLowerCase() === "semua") {
      dispatch(
        getWebinarsDataThunk({
          type: "filter",
          param: params,
        })
      );
    }
    if (dataType.toLowerCase() === "konsultasi") {
      dispatch(
        getWebinarsDataThunk({
          type: "filter",
          param: params + "&type=konsultasi",
        })
      );
    }
  }

  function radioHandleChange(newValue) {
    let counter = 0;
    let newFilter = "";
    if (newValue !== "Semua") {
      counter++;
      newFilter += filterTemplate(counter, `category=${newValue}`);
    }
    if (filter === "") {
      history.push(
        `/webinar-list/${dataType.toLowerCase()}/${
          newFilter ? newFilter.split("?")[1] : ""
        }`
      );
    } else {
      history.push(
        `/webinar-list/${dataType.toLowerCase()}/${
          filter ? filter.split("?")[1] : ""
        }&${newFilter ? newFilter.split("?")[1] : ""}`
      );
    }
    setCategoriesFilter(newValue);
    setActive(1);
    setFilterCategory(newFilter);
    // setFilter(newFilter);
  }

  useEffect(() => {
    let counter = 0;
    let newFilter = "";
    if (filterCourse.length > 0) {
      counter++;
      newFilter += filterTemplate(
        counter,
        `course_type=${filterCourse.map((item) => item)}`
      );
    }
    history.push(
      `/search/${dataType.toLowerCase()}/${
        newFilter ? newFilter.split("?")[1] : ""
      }`
    );
    setActive(1);
    setFilter(newFilter);
  }, [filterCourse]);

  useEffect(() => {
    let counter = 0;
    let newFilter = "";
    if (filterCourseLive.length > 0) {
      counter++;
      newFilter += filterTemplate(
        counter,
        `type=${filterCourseLive.map((item) => item)}`
      );
    }
    if (filterCategory === undefined) {
      history.push(
        `/webinar-list/${dataType.toLowerCase()}/${
          newFilter ? newFilter.split("?")[1] : ""
        }`
      );
    } else {
      history.push(
        `/webinar-list/${dataType.toLowerCase()}/${
          newFilter ? newFilter.split("?")[1] : ""
        }&${filterCategory ? filterCategory.split("?")[1] : ""}`
      );
    }
    setActive(1);
    setFilter(newFilter);
  }, [filterCourseLive, filterCategory]);

  useEffect(() => {}, [dataType]);

  const handleFilterCourse = (e) => {
    let newData = [...filterCourse];
    newData.push(e.target.id);
    if (filterCourse.includes(e.target.id)) {
      let newData = [...filterCourse];
      let idx = newData.indexOf(e.target.id);
      newData?.splice(idx, 1);
      setFilterCourse(newData);
    } else {
      setFilterCourse(newData);
      setDataType(e.target.id);
    }
  };

  const handleFilterCourseLive = (e) => {
    let newData = [...filterCourseLive];
    if (newData.find((item) => item === e.target.id)) {
      let idx = newData.indexOf(e.target.id);
      newData?.splice(idx, 1);
      setFilterCourseLive(newData);
    } else {
      newData.push(e.target.id);
      setFilterCourseLive(newData);
    }
  };

  const handleRemoveAllFilter = () => {
    setFilterCourseLive([]);
    dispatch(
      getWebinarsDataThunk({
        type: "filter",
        param: `?size=9&type=webinar`,
      })
    );
  };

  const handleRemoveLiveFilter = (idx) => {
    let newData = [...filterCourseLive];
    newData?.splice(idx, 1);
    setFilterCourseLive(newData);
  };

  return (
    <StyledWrapperMain>
      <Container className="search">
        <Row>
          <Col className="d-none d-sm-block" md={3}>
            <FilterCard
              dataType={dataType}
              handleFilterCourse={handleFilterCourse}
              handleFilterCourseLive={handleFilterCourseLive}
              filterCourse={filterCourse}
              filterCourseLive={filterCourseLive}
              radioHandleChange={radioHandleChange}
              setCategoriesFilter={setCategoriesFilter}
              categoriesFilter={categoriesFilter}
              searchBtnHandler={searchBtnHandler}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={9}
            lg={9}
            className=" p-1 p-md-3 mb-3 align-items-center"
          >
            {" "}
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="h3 subtitle_course__info_title pl-0 mb-2 fw-700">
                  Webinar
                </h3>
                <div className="subtitle_course__info mb-3 pl-0">
                  Temukan pengalaman baru belajar bersama instruktur handal
                  dalam memberikan materi, Anda dapat memberikan pertanyaan
                  terkait konteks dari materi pembelajaran secara langsung.
                </div>
                <div
                  className={`d-flex flex-column flex-md-row ${
                    viewCert === "grid"
                      ? "justify-content-between"
                      : "justify-content-end"
                  } mb-4`}
                >
                  {viewCert === "grid" && (
                    <div className="d-flex">
                      <div className="d-flex align-items-center mr-3 mb-3 mb-md-0">
                        <h3 className="h3 fw-700 mr-3 mb-0">Urutkan</h3>
                        <Form.Group className="mb-0">
                          <Form.Control
                            onChange={(e) => handleFilterWebinar(e)}
                            as="select"
                            className="form-select"
                            placeholder="Berdasarkan Bulan"
                          >
                            <option hidden>Berdasarkan Bulan</option>
                            {months.map((item, index) => {
                              return (
                                <option key={index} value={item.list}>
                                  {item.month}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <Form.Group
                        className="mb-0"
                        controlId="exampleForm.ControlSelect1"
                      >
                        <Form.Control
                          onChange={(e) => handleChangeSort(e)}
                          as="select"
                          className="form-select"
                        >
                          <option value="latest">Terbaru</option>
                          <option value="popular">Terpopuler</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}

                  <div>
                    <Form.Group
                      className="mb-0 d-flex align-items-center"
                      controlId="exampleForm.ControlSelect1"
                    >
                      <Form.Label className="mb-0 mr-3">Tampilan</Form.Label>
                      <Form.Control
                        onChange={(e) => handleChangeView(e)}
                        as="select"
                        className="form-select"
                      >
                        <option value="grid">Card</option>
                        <option value="calendar">Kalender</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
                <div className="d-flex pl-3 mb-3">
                  {filterCourseLive?.length > 1 && (
                    <div
                      onClick={handleRemoveAllFilter}
                      className="mb-4 mr-2 badge-filter__course-reset"
                    >
                      Hapus Semua
                    </div>
                  )}
                  {filterCourseLive?.length > 1 &&
                    filterCourseLive.map((item, index) => {
                      return (
                        <div
                          className="mb-4 mr-2 badge-filter__course"
                          key={index}
                        >
                          {item === "webinar" ? "Webinar" : "Konsultasi"}
                          <span
                            onClick={() => handleRemoveLiveFilter(index)}
                            className="badge-filter__remove"
                          >
                            ×
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="d-md-none ml-0 ml-md-3">
              <Accordion className="mb-4 text-right">
                <Accordion.Toggle
                  className="show_filter__accordion btn btn-info"
                  eventKey="0"
                  onClick={handleShow}
                >
                  Filter
                </Accordion.Toggle>
              </Accordion>
            </div>
            {show ? (
              <Row>
                <Col xs={12}>
                  <Accordion.Collapse className="show mb-4" eventKey="0">
                    <FilterCard
                      dataType={dataType}
                      handleFilterCourse={handleFilterCourse}
                      handleFilterCourseLive={handleFilterCourseLive}
                      filterCourse={filterCourse}
                      filterCourseLive={filterCourseLive}
                      radioHandleChange={radioHandleChange}
                      setCategoriesFilter={setCategoriesFilter}
                      categoriesFilter={categoriesFilter}
                      searchBtnHandler={searchBtnHandler}
                    />
                  </Accordion.Collapse>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              {viewCert === "grid" ? (
                webinarsData?.items ? (
                  <div className="course-result__container" id="course_search">
                    {webinarsData.items.map((item, i) => {
                      return (
                        <CourseCard
                          key={i}
                          item={item}
                          widthFit="fit-content"
                          isWebinar={true}
                        />
                      );
                    })}
                  </div>
                ) : null
              ) : (
                <TimelineCalendar
                  padding={"pt-3"}
                  data={webinarsData?.items}
                  className="py-0"
                  dateChanges={dateChanges}
                />
              )}
            </Row>
            {webinarsPagination && viewCert === "grid" ? (
              <Pagination
                size={size}
                totalRows={webinarsPagination.totalItems}
                totalPages={webinarsPagination.totalPages}
                paginationSelect={paginationSelect}
                active={active}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </StyledWrapperMain>
  );
}

export default WebinarSearch;

function filterTemplate(counter, data) {
  return counter <= 0 ? data : counter === 1 ? `?${data}` : `&${data}`;
}

function getCurrentState(str) {
  const splitted = str.split(/=|&/);
  let newData = {};
  for (let i = 0; i < (splitted.length - 1) / 2; i++) {
    newData[splitted[i * 2]] = splitted[i * 2 + 1];
  }
  return newData;
}

const StyledWrapperMain = styled.section`
  .form-check-label {
    overflow: hidden;
    display: -webkit-box;
  }
`;
