import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLessonsDataThunk } from "../../redux/thunks/LessonsThunks";
import { removeCompleteState } from "../../redux/actions/CoursesActions";
import starDisable from "./../../assets/images/star-disable.png";
import star from "./../../assets/images/star.png";
import {
  getCoursesDataThunk,
  postCompleteCourseThunk,
  postCompleteMaterialThunk,
} from "../../redux/thunks/CoursesThunks";
import { getAssignmentDataThunk } from "../../redux/thunks/AssignmentThunks";
import { getWebinarsDataThunk } from "../../redux/thunks/WebinarsThunks";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { slugify } from "../../helper/functions";
import AssignmentPage from "../Courses/AssignmentPage";
import chevronleft from "./../../assets/images/chevron_left.png";
import chevronright from "./../../assets/images/chevron_right.png";
import illustration1 from "./../../assets/images/warning/1.png";
import illustration2 from "./../../assets/images/warning/2.png";
import QuizPage from "../../pages/Courses/QuizPage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DM_COURSE_ID, DM_COURSE_ID_POST } from "../../helper/constant";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  getQuizAttemptThunk,
  getQuizDataThunk,
  postStartQuizThunk,
} from "../../redux/thunks/QuizThunks";
import { removeAttemptScoreQuiz } from "../../redux/actions/QuizActions";
import CourseDetailTitle from "../../components/CoursePage/CourseDetailTitle";
import CourseTitle from "../../components/CoursePage/CourseDetail/CourseTitle";
import AccordionCourse from "../../components/CoursePage/AccordionCourse";
import TabsCourseDetail from "../../components/CoursePage/TabsCourseDetail";
import PDFLoaderLesson from "../../components/CoursePage/CourseDetail/PDFLoaderLesson";
import VideoLoader from "../../components/CoursePage/CourseDetail/VideoLoader";
import AudioLoader from "../../components/CoursePage/CourseDetail/AudioLoader";
import RatingCourse from "../../components/RatingCourse";
import BreadcrumbPageNonDashboard from "../../components/BreadcrumbPageNonDashboard";
import RelatedMaterialSection from "../../components/CoursePage/RelatedMaterialSection";
import WhatPeopleSay from "../../components/CoursePage/WhatPeopleSay";
import Sertificate from "../../components/CoursePage/CourseFront/Sertificate";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import ScormPage from "./ScormPage";
import { HTTP_SERVICE } from "../../services/HTTPService";
import { useRef } from "react";
import { courseService } from "../../http/CourseLengthHttp";
import { SkValidate } from "../../components/common/SkValidate";
import { FeedbackService } from "../../http/FeedbackHttp";

const MySwal = withReactContent(Swal);

function CourseDetail() {
  const { course_id, id_material, material_type, slug, slug_m } = useParams();
  const [onNextClicked, setOnNextClicked] = useState(false);
  // const [generateCertif, setGenerateCertif] = useState(false);
  const [attempts, setAttempts] = useState(["empty"]);
  const [feedbackSend, setFeedbackSend] = useState();
  const buttonRef = useRef();
  const [postComplete, setPostComplete] = useState(false);
  const [disclaimer, setDisclaimer] = useState(true);
  const [quizPass, setQuizPass] = useState(true);
  const [data, setData] = useState([]);
  const [scormDone, setScormDone] = useState(true);
  const [hideButton, setHideButton] = useState(false);
  const [agree, setAgree] = useState(false);
  const [idSelected, setIdSelected] = useState();
  const [feedback, setFeedback] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [membershipData, setMembershipData] = useState();
  const [detailData, setDetailData] = useState([]);
  const [modalPages, setModalPages] = useState(1);
  const [material, setMaterial] = useState(id_material.split("_"));
  const [topics, setTopics] = useState([]);
  const [scormsData, setScormsData] = useState(null);
  const [lessonType, setLessonType] = useState([false, false, false]); // video|audio|pdf, else text
  const lessons = useSelector((state) => state.lessons);
  const courses = useSelector((state) => state.courses);
  const assignments = useSelector((state) => state.assignments);
  const webinars = useSelector((state) => state.webinars);
  const quizzes = useSelector((state) => state.quizzes);
  const dispatch = useDispatch();
  const course = courses?.coursesDataId;
  const lesson = lessons.lessonsDataId;
  const assignment = assignments.assignmentDataId;
  const webinar = webinars.webinarsDataId;
  const quiz = quizzes.quizDataId;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (courses?.coursesDataId?.is_enrolled === 0) {
      let arr = [];
      let urlSplit = location.pathname.split("/");
      for (let i = 0; i <= 3; i++) {
        arr.push(urlSplit[i]);
      }
      let joinUrl = arr.join();
      let finalUrl = joinUrl.replaceAll(",", "/");

      history.push(finalUrl);
    }
    // console.log(courses?.coursesDataId?.is_enrolled, "<=")
  }, [courses]);

  useEffect(() => {
    if (quizzes.quizDataId?.attempts) {
      setAttempts(quizzes.quizDataId.attempts);
    }
    if (quizzes.quizStartData?.status === "success") {
      setHideButton(true);
      dispatch(removeAttemptScoreQuiz());
    }
    if (quizzes.quizScoreData?.status === "success") {
      setAttempts(["empty"]);
    }
    if (quizzes.quizDataId?.status === "success") {
      setHideButton(false);
    }
  }, [quizzes]);

  useEffect(() => {
    AuthIsLogin(history);
    if (material_type === "lessons") {
      dispatch(getLessonsDataThunk(id_material.split("_")[0]));
    }
    if (material_type === "assignments") {
      dispatch(getAssignmentDataThunk(id_material.split("_")[0]));
    }
    if (material_type === "webinars") {
      dispatch(
        getWebinarsDataThunk({
          type: "id",
          param: `/${id_material.split("_")[0]}`,
        })
      );
    }
    if (material_type === "quiz") {
      dispatch(getQuizDataThunk(id_material.split("_")[0]));
    }
    if (material_type === "scorms") {
      getScormsData();
    }
    if (onNextClicked) {
      setOnNextClicked(false);
    } else {
      setMaterial(id_material.split("_"));
    }
    setQuizPass(true);
  }, [, location]);

  useEffect(() => {
    const memberships = membershipData;
    if (memberships) {
      if (memberships?.length > 0) {
        if (memberships[0].status !== "active") {
          displayPopup(false, "Membership Expired!", "Oke");
          history.push(`/course/${slug}/${course_id}`);
        }
      } else {
        displayPopup(false, "Pelatihan Belum Dibeli!", "Oke");
        history.push(`/course/${slug}/${course_id}`);
      }
    }
  }, [membershipData]);

  useEffect(() => {
    if (lesson.url_video) {
      let newLessonType = lessonType.splice(0, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson.url_audio) {
      let newLessonType = lessonType.splice(1, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson.url_pdf) {
      let newLessonType = lessonType.splice(2, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson) setDetailData(lesson);
  }, [lesson]);

  useEffect(() => {
    if (assignment) setDetailData(assignment);
  }, [assignment]);

  useEffect(() => {
    if (webinar) setDetailData(webinar);
  }, [webinar]);

  useEffect(() => {
    if (quiz) setDetailData(quiz);
  }, [quiz]);

  useEffect(() => {
    if (scormsData?.id) {
      setScormDone(scormsData?.score == 0 || scormsData?.score ? true : false);
    }
  }, [scormsData]);

  useEffect(() => {
    const topics = course?.topics;
    const idx = id_material.split("_");
    if (topics) {
      setTopics(topics);
      if (
        topics?.length > 0 &&
        topics?.length > 0 &&
        topics[idx[1]]?.materials[idx[2]]?.title
      ) {
        setDetailData(topics?.length > 0 && topics[idx[1]]?.materials[idx[2]]);
      }
    }
  }, [course]);

  useEffect(() => {
    if (quizzes.quizDataId?.attempts) {
      let newQuizPass = false;
      const Pass = quizzes?.quizDataId?.attempts?.some(
        (attempt) => attempt.result === "Pass"
      );
      if (Pass) {
        newQuizPass = true;
      }
      setQuizPass(newQuizPass);
    }
  }, [quizzes]);

  useEffect(() => {
    if (AuthIsLogin("", true)) {
      getMembership();
    }
    if (!course?.course_id)
      dispatch(getCoursesDataThunk({ type: "id", param: `/${course_id}` }));
  }, []);

  useEffect(() => {
    if (postComplete) {
      onComplete();
    }
  }, [postComplete]);

  useEffect(() => {
    let checkURLCertif = courses.coursesDataId.certificate_template_url;

    if (courses.completeMaterialData === "success") {
      if (isLast) {
        onCompleteCourse();
      }
      dispatch(removeCompleteState());
      const newTopics = [...topics];
      const index1 = parseInt(material[1]?.replace(/[^0-9]/, ""));
      const index2 = parseInt(material[2]?.replace(/[^0-9]/, ""));
      newTopics[index1].materials[index2].completed = 1;
      setTopics(newTopics);
    }
    setMaterial(id_material.split("_"));

    if (courses.completeCourseData === "success") {
      displayPopup(
        false,
        checkURLCertif
          ? "Berhasil Menyelesaikan English Course dan Silahkan Cek Sertifikasi Kamu! (Sertifikat akan siap dalam waktu sampai dengan 15 menit)"
          : "Berhasil Menyelesaikan Course!",
        "Oke",
        checkURLCertif ? "redirect" : ""
      );
      setFeedback(false);
      dispatch(removeCompleteState());
    }
  }, [courses.completeMaterialData, courses.completeCourseData]);

  const getMembership = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getMembership();
      setMembershipData(response.memberships);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  const starRatingHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(id);
  };
  /**
   * @function made a function for give an feedback to course
   * @param {*} e
   * @param {*} idx
   */
  const starRatingDisableHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(idSelected + id);
  };

  const getScormsData = async () => {
    try {
      const response = await HTTP_SERVICE.get(
        "v2/scorms/" + id_material.split("_")[0]
      );
      if (response?.status == 200) {
        setScormsData(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleNext = (e, page) => {
    e.preventDefault();
    if (page === 2) {
      if (agree) {
        setDisclaimer(false);
      }
      buttonRef.current.disabled = true;
    } else {
      buttonRef.current.disabled = false;
    }
    setModalPages(page);
  };
  function onCompleteMaterialandCourse(e, course) {
    // await
    e.preventDefault();
    setFeedback(true);
    setData(course);
    setIsLast(true);
  }

  async function onCompleteCourse() {
    try {
      setIsLast(false);
      const response = await courseService.getCourse(`/${course_id}`);
      const payloadC = {
        course_id: course_id,
        user_id: AuthData().userId,
      };
      if (response?.is_completed == 1) {
        dispatch(postCompleteCourseThunk(payloadC));
      } else {
        displayPopup(
          false,
          "Selesaikan semua pelatihan terlebih dahulu!",
          "Oke"
        );
      }
    } catch (err) {
      displayPopup(false, "Terjadi kesalahan! Silahkan hubungi admin", "Oke");
      console.log(err);
    }
  }

  function onComplete() {
    setOnNextClicked(true);
    const payload = {
      material_id: id_material.split("_")[0],
      user_id: AuthData().userId,
    };
    dispatch(postCompleteMaterialThunk(payload));
  }

  const getNextLink = (current) => {
    const arrTopics = topics;
    let i = parseInt(current[1]);
    let j = parseInt(current[2]);

    let result;
    let next = arrTopics[i]?.materials[j + 1];
    if (
      arrTopics?.length - 1 === i &&
      arrTopics[arrTopics?.length - 1].materials.length - 1 === j
    ) {
      result = `/${material_type}/${slug_m}/${id_material}`;
      return result;
    }
    if (next) {
      if (next.type === "lessons") {
        result = `/lessons/${slugify(next.title)}/${next.lesson_id}`;
      } else if (next.type === "quizzes") {
        result = `/quiz/${slugify(next.title)}/${next.quiz_id}`;
      } else if (next.type === "assignments") {
        result = `/assignments/${slugify(next.title)}/${next.assignment_id}`;
      } else if (next.type === "webinars") {
        result = `/webinars/${slugify(next.title)}/${next.webinar_id}`;
      } else if (next.type === "scorms") {
        result = `/scorms/${slugify(next.title)}/${next.id}`;
      }

      result += `_${i}_${j + 1}`;
      return result;
    } else {
      j = 0;
      next = arrTopics[i + 1];
      if (next) {
        next = arrTopics[i + 1].materials[j];

        if (next) {
          if (next.type === "lessons") {
            result = `/lessons/${slugify(next.title)}/${next.lesson_id}`;
          } else if (next.type === "quizzes") {
            result = `/quiz/${slugify(next.title)}/${next.quiz_id}`;
          } else if (next.type === "assignments") {
            result = `/assignments/${slugify(next.title)}/${
              next.assignment_id
            }`;
          } else if (next.type === "webinars") {
            result = `/webinars/${slugify(next.title)}/${next.webinar_id}`;
          } else if (next.type === "scorms") {
            result = `/scorms/${slugify(next.title)}/${next.id}`;
          }
          result += `_${i + 1}_${j}`;
          return result;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const handleCheckAgree = () => {
    if (agree) {
      buttonRef.current.disabled = true;
    } else {
      buttonRef.current.disabled = false;
    }
    setAgree(!agree);
  };
  const getPreviousLink = (current) => {
    const arrTopics = topics;
    let i = parseInt(current[1]);
    let j = parseInt(current[2]);
    // check if next item exist
    let result;

    let previous = arrTopics[i]?.materials[j - 1];
    if (previous) {
      if (previous?.type === "lessons") {
        result = `lessons/${slugify(previous?.title)}/${previous?.lesson_id}`;
      } else if (previous?.type === "quizzes") {
        result = `quiz/${slugify(previous?.title)}/${previous?.quiz_id}`;
      } else if (previous?.type === "assignments") {
        result = `assignments/${slugify(previous?.title)}/${
          previous?.assignment_id
        }`;
      } else if (previous?.type === "webinars") {
        result = `webinars/${slugify(previous?.title)}/${previous?.webinar_id}`;
      } else if (previous?.type === "scorms") {
        result = `scorms/${slugify(previous?.title)}/${previous?.id}`;
      }
      result += `_${i}_${j - 1}`;
      return result;
    } else {
      previous = arrTopics[i - 1];
      if (previous) {
        i = i - 1;
        j = previous?.materials.length - 1;
        previous = arrTopics[i]?.materials[j];
        if (previous?.type === "lessons") {
          result = `lessons/${slugify(previous?.title)}/${previous?.lesson_id}`;
        } else if (previous?.type === "quizzes") {
          result = `quiz/${slugify(previous?.title)}/${previous?.quiz_id}`;
        } else if (previous?.type === "assignments") {
          result = `assignments/${slugify(previous?.title)}/${
            previous?.assignment_id
          }`;
        } else if (previous?.type === "webinars") {
          result = `webinars/${slugify(previous?.title)}/${
            previous?.webinar_id
          }`;
        } else if (previous?.type === "scorms") {
          result = `scorms/${slugify(previous?.title)}/${previous?.id}`;
        }
        result += `_${i}_${j}`;
        return result;
      } else {
        return false;
      }
    }
  };
  function displayPopup(cancel, title, confirm, command) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (command === "redirect") {
        if (value.isConfirmed) {
          history.push(`/download/${slug}/${course_id}/`);
        }
      }
    });
  }

  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setFeedbackSend(newData);
  };

  function startAttempt() {
    if (attempts[0]?.status === "ongoing") {
      dispatch(getQuizAttemptThunk(attempts[0].attempt_id));
    } else if (attempts[0]?.status === "completed" || attempts.length <= 0) {
      displayPopupQuiz(
        true,
        `Anda ingin Mulai ${
          courses?.coursesDataId?.course_type === "Try Out"
            ? "Ujian Try Out?"
            : "Ujian Sertifikasi?"
        }`,
        true,
        "Ya"
      );
    }
  }

  function displayPopupQuiz(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      cancelButtonText: "Tidak",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (value.isConfirmed) {
        const qids = quizzes.quizDataId.questions.map(function (item) {
          return item["question_id"];
        });
        const payload = {
          question_ids: qids,
          quiz_id: id_material,
          user_id: AuthData().userId,
        };
        dispatch(postStartQuizThunk(payload));
      }
    });
  }

  function checkIfLast() {
    const idx = id_material.split("_");
    return (
      topics?.length > 0 &&
      topics[topics?.length - 1]?.materials[
        topics[topics?.length - 1]?.materials?.length - 1
      ]?.title === topics[idx[1]]?.materials[idx[2]]?.title
    );
  }

  const constraints = {
    review_suggestion: {
      presence: true,
      length: {
        minimum: 20,
      },
    },
  };

  const generateCertifHandler = async (e) => {
    e.preventDefault();
    const form = e.target;
    let payload = {};
    payload.user_id = AuthData().userId;
    payload.course_id = course_id;
    payload.review_suggestion = feedbackSend?.review_suggestion;
    payload.score_satisfaction = idSelected === undefined ? 5 : idSelected;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      try {
        const response = await FeedbackService.postFeedback(payload);
        if (response !== undefined) {
          setPostComplete(true);
        }
      } catch (error) {
        console.log(error);
        setPostComplete(true);
      }
    }
  };

  return (
    <div className="middle-sidebar-bottom">
      <Container fluid="xs" className="dashboard_page__container">
        <Row className="no-gutters-xs">
          <Col
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md={{ span: 4, order: 1 }}
            lg={3}
            className={`${lesson && "accordion-sticky"}`}
          >
            {/* show the lesson list on the left sidebar */}
            <AccordionCourse
              enrolled={true}
              courseTitle={course.title}
              id={lesson}
              topics={topics}
              courseId={course_id}
              slug={slug}
              detail={true}
              type={course?.course_type}
              activeKey={material[1]}
              selectedIndex={`${material[1]}${material[2]}`}
            />
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 8, order: 2 }}
            lg={9}
          >
            <div className="d-flex justify-content-between align-items-center">
              <BreadcrumbPageNonDashboard
                type={course.course_type}
                title={course.title}
                course_id={course.course_id}
                slugify={slugify}
              />
              <div>
                {course.course_id === DM_COURSE_ID ||
                course.course_id === DM_COURSE_ID_POST
                  ? null
                  : topics?.length > 0 && (
                      <>
                        <Col className="d-flex justify-content-between">
                          <Link
                            to={`/course/${slug}/${course_id}/${getPreviousLink(
                              material
                            )}
                `}
                            className="previous-button mr-5 mt-0"
                          >
                            {getPreviousLink(material) && (
                              <div className="prev-course">
                                <span className="mr-3">
                                  <img src={chevronleft} />
                                </span>
                                Kembali
                              </div>
                            )}
                          </Link>
                          {/* Check if the course topics in the last list or not and compare it with title material*/}
                          {checkIfLast() ? (
                            <>
                              {quizPass && scormDone && (
                                <div
                                  onClick={(e) =>
                                    onCompleteMaterialandCourse(e, course)
                                  }
                                  className="next-button mt-0"
                                >
                                  <div className="next-course">
                                    Selesaikan Materi
                                    <span className="ml-3">
                                      <img
                                        src={chevronright}
                                        alt="arrow-right"
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <Link
                              to={`/course/${slug}/${course_id}${getNextLink(
                                material
                              )}`}
                              onClick={onComplete}
                              className="next-button mt-0"
                            >
                              {getNextLink(material) &&
                                scormDone &&
                                quizPass && (
                                  <div className="next-course">
                                    Selanjutnya
                                    <span className="ml-3">
                                      <img
                                        src={chevronright}
                                        alt="arrow-right"
                                      />
                                    </span>
                                  </div>
                                )}
                            </Link>
                          )}
                        </Col>
                      </>
                    )}
              </div>
            </div>

            {/* when material type is lesson */}
            {material_type === "lessons" && (
              <>
                {/* load this component when lesson type is video h5p or video dyntube */}
                {lesson.material_type === "video_dyntube" ||
                lesson.material_type === "video_h5p" ? (
                  <VideoLoader
                    url={lesson.material_url}
                    type={lesson.material_type}
                  />
                ) : null}
                {/* load this component when lesson type is audio */}
                {lesson.material_type === "audio" ? (
                  <AudioLoader title={lesson.title} url={lesson.material_url} />
                ) : null}
                {/* load this component when lesson type is pdf */}
                {lesson.material_type === "pdf" ? (
                  <PDFLoaderLesson url={lesson.material_url} />
                ) : null}
              </>
            )}
            {material_type !== "quiz" && (
              <div className="container-video__information">
                <CourseTitle
                  hideButton={hideButton}
                  startAttempt={startAttempt}
                  quizzes={quizzes}
                  attempts={attempts}
                  id={detailData}
                  course={course}
                  material_type={material_type}
                  titles={detailData?.title}
                />
                <Container>
                  <Row>
                    <Col md={12}>
                      <RatingCourse item={course} />
                      <Link
                        to={`/${
                          course.course_type === "webinar" ||
                          course.course_type === "konsultasi"
                            ? "webinar"
                            : "course"
                        }/${slugify(course.title)}/${course.course_id}`}
                      >
                        <div>{course.title}</div>
                      </Link>
                    </Col>
                  </Row>
                </Container>
                <CourseDetailTitle
                  id={detailData}
                  item={course}
                  titles={detailData?.title}
                />
              </div>
            )}

            {/* load this component when material type is assignment */}
            {material_type === "assignments" && <AssignmentPage />}
            {/* load this component when material type is quiz */}
            {material_type === "quiz" && (
              <QuizPage
                startAttempt={startAttempt}
                onComplete={onComplete}
                setHideButton={setHideButton}
                getNextLink={getNextLink}
                material={material}
                quizPass={quizPass}
                detailData={detailData}
                setMaterial={setMaterial}
              />
            )}

            {/* load this component when material type is scorm */}
            {material_type === "scorms" && <ScormPage data={scormsData} item={course}/>}

            {course.course_id === DM_COURSE_ID ||
            course.course_id === DM_COURSE_ID_POST ? null : (
              <>
                <TabsCourseDetail
                  id={detailData}
                  item={course}
                  content={lesson.content}
                  membershipData={membershipData}
                />
                {course.course_type === "Sertifikasi" ? (
                  <Sertificate
                    title="Bagian dari Sertifikasi"
                    type="sertifikasi"
                    detail={true}
                    courseName={course.title}
                    idTemp={course?.certificate_template_id}
                    thumbTemp={course?.certificate_template_url}
                  />
                ) : course.course_type === "Studi Kasus" ||
                  course.course_type === "Try Out" ||
                  (course?.certificate_template_url === "" &&
                    course?.certificate_template_id === "") ? null : (
                  <Sertificate
                    title="Sertifikasi Partisipasi"
                    type="pelatihan"
                    detail={true}
                    courseName={course.title}
                    idTemp={course?.certificate_template_id}
                    thumbTemp={course?.certificate_template_url}
                  />
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} md={11}>
                      <WhatPeopleSay courseId={course_id} />
                    </Col>
                  </Row>
                </Container>
                <RelatedMaterialSection
                  title="Pelatihan Online Terkait"
                  related={course}
                  disableHover={true}
                  more="Lihat Selengkapnya"
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
      {material_type !== "scorms" &&
        course.course_type === "Sertifikasi" &&
        course.is_completed === 0 && (
          <Modal show={disclaimer} centered id="certification_modal">
            <Modal.Header>
              <Modal.Title>
                <div style={{ fontWeight: 700 }}>Perhatian !</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="text-center mb-3">
                  {modalPages === 1 && (
                    <Image src={illustration1} alt="illustration1" />
                  )}
                  {modalPages === 2 && (
                    <Image src={illustration2} alt="illustration2" />
                  )}
                </div>
                <div className="mb-4">
                  {modalPages === 1 && (
                    <>
                      <ol>
                        <li>
                          Jangan melakukan refresh saat melakukan ujian karena
                          akan mengacak soal kembali dan membuat nilai anda
                          menjadi tidak terhitung.
                        </li>
                        <li>
                          Persiapkan segala persiapan seperti anda sedang
                          melakukan ujian sertifkasi tatap muka. Jangan menunda
                          dan menutup halaman sebelum ujian selesai,
                        </li>
                        <li>
                          Apabila Anda menutup dan tidak melanjutkan ujian
                          sertifikasi (termasuk dengan masalah koneksi dari sisi
                          anda), Anda harus mengulangi sesi ujian kembali.
                        </li>
                      </ol>
                    </>
                  )}
                  {modalPages === 2 && (
                    <div>
                      <div className="mb-2">
                        Dengan mengakses dan menggunakan layanan sertifikasi
                        brevet online di SatuKelas.com, Anda menyetujui dan
                        memahami bahwa:
                      </div>
                      <ol>
                        <li>
                          Setiap informasi yang Anda berikan pada saat
                          pendaftaran harus akurat dan lengkap.
                        </li>
                        <li>
                          Anda akan bertanggung jawab penuh atas segala tindakan
                          yang dilakukan dengan menggunakan akun Anda.
                        </li>
                        <li>
                          Segala bentuk kecurangan dalam proses sertifikasi
                          adalah dilarang dan dapat mengakibatkan pembatalan
                          sertifikasi, serta tindakan hukum yang sesuai.
                        </li>
                        <li>
                          SatuKelas.com berhak untuk membatalkan sertifikasi
                          Anda jika terbukti melakukan kecurangan atau melanggar
                          ketentuan yang berlaku.
                        </li>
                        <li>
                          SatuKelas.com tidak bertanggung jawab atas kerugian
                          yang timbul akibat penggunaan layanan sertifikasi
                          brevet secara online, termasuk namun tidak terbatas
                          pada kerugian finansial, kehilangan data, atau
                          kerusakan perangkat keras maupun lunak.
                        </li>
                        <li>
                          Anda setuju untuk memberikan hak non-eksklusif,
                          gratis, dan tanpa batasan waktu kepada SatuKelas.com
                          untuk menggunakan nama dan logo Anda sebagai pemegang
                          sertifikasi.
                        </li>
                        <li>
                          SatuKelas.com berhak untuk mengubah syarat dan
                          ketentuan layanan ini kapan saja tanpa pemberitahuan
                          terlebih dahulu.
                        </li>
                      </ol>
                      <Form>
                        Dengan menyetujui User Agreement ini, Anda menyatakan
                        bahwa Anda telah membaca, memahami, dan setuju untuk
                        mematuhi segala ketentuan dan syarat yang berlaku dalam
                        layanan sertifikasi online di SatuKelas.com, serta
                        menanggung semua tanggung jawab yang timbul akibat
                        penggunaan layanan ini.
                        <div className="py-3">
                          <Form.Check
                            onClick={handleCheckAgree}
                            type="checkbox"
                            id="default-checkbox"
                            label="Saya setuju dengan persetujuan diatas"
                          />
                        </div>
                      </Form>
                    </div>
                  )}
                </div>

                <div
                  style={{ fontWeight: 700 }}
                  className="w-100 text-right mb-3"
                >
                  {modalPages}/2
                </div>
                <div className="w-100 text-right">
                  {modalPages === 2 && (
                    <Button
                      onClick={(e) => handleNext(e, 1)}
                      className="btn next-button__modal py-2 mr-3 px-4 btn-primary"
                    >
                      Sebelumnya
                    </Button>
                  )}

                  <Button
                    ref={buttonRef}
                    onClick={(e) => handleNext(e, 2)}
                    className="btn next-button__modal py-2 px-4 btn-primary"
                  >
                    Selanjutnya
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      <Modal show={feedback} onHide={() => setFeedback(!feedback)} centered>
        <div className="card_inside__padding">
          <h3 className="text-center">
            Isi feedback lalu dapatkan sertifikatnya!
          </h3>

          <div className="d-flex flex-row justify-content-center my-4 mb-5">
            {/* this condition will be start when user want to select the score */}
            <>
              {idSelected !== undefined &&
                [...Array(idSelected)].map((item, i) => {
                  return (
                    <div onClick={(e) => starRatingHandler(e, i)} key={i}>
                      <Image
                        className="mx-1"
                        style={{ width: "32px" }}
                        src={star}
                      />
                    </div>
                  );
                })}
            </>

            {/* this condition will be start if the score under 5 */}
            <>
              {idSelected !== undefined &&
                [...Array(5 - idSelected)].map((item, i) => {
                  return (
                    <div
                      onClick={(e) => starRatingDisableHandler(e, i)}
                      key={i}
                    >
                      <Image
                        className="mx-1"
                        style={{ width: "32px" }}
                        src={starDisable}
                      />
                    </div>
                  );
                })}
            </>
            {/* this condition will be start if idSelected have a undefined value */}
            {idSelected === undefined &&
              [...Array(5)].map((value, index) => {
                return (
                  <div onClick={(e) => starRatingHandler(e, index)} key={index}>
                    <Image
                      className="mx-1"
                      style={{ width: "32px" }}
                      src={star}
                    />
                  </div>
                );
              })}
          </div>
          <Form onSubmit={generateCertifHandler}>
            {/* <Form.Group> */}
            {/* </Form.Group> */}

            <div className="mb-4 form-group">
              <Form.Control
                style={{ margin: "0 auto " }}
                className="p-3 d-flex"
                as="textarea"
                rows={5}
                placeholder="Isi feedback disini, Contoh: course ini sangat berguna untuk saya terimakasih!"
                id="review_suggestion"
                name="review_suggestion"
                onChange={handleChange}
              />
              <div className="messages"></div>
            </div>

            <Button className="button-next w-100" type="submit">
              Kirim
            </Button>

            {/* <Button className="button-next" type="submit">
                        Kirim dan Unduh Sertifikat
                      </Button>
                     */}
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default CourseDetail;
