import { satukelasApi } from "../constants";

export const LearningPathService = {
  getDetailLearningPath(param) {
    return satukelasApi.get(`/learning_paths${param ? param : ""}`);
  },
  postClaimCertificate(payload) {
    return satukelasApi.post(`/learning_paths/claim_certificate`, payload);
  },
  getClaimCertificateScorm(id) {
    return satukelasApi.get(`/certificates/scorm/claim/${id}`);
  },

  getLearningPathCategory(param) {
    return satukelasApi.get(`/learning_paths?category=${param}`);
  },
  getChroPathId(param) {
    return satukelasApi.get(`/courses/${param}`);
  },
};
