// CONFIG
export const LOADING_START = "LOADING_START";
export const LOADING_FINISH = "LOADING_FINISH";

// ACTION
export const loadingStart = () => ({
  type: LOADING_START,
});
export const loadingFinish = () => ({
  type: LOADING_FINISH,
});

// STATE
const intialState = {
  isLoading: false,
};

// REDUCERS
export const CommonReducers = function (state = intialState, action) {
  switch (action.type) {
    case LOADING_START:
      return { ...state, isLoading: true };
    case LOADING_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
