import {
  //get
  USERS_DATA,
  USERS_DATA_ID_SUCCESS,
  USERS_DATA_SUCCESS,
  USERS_DATA_FAIL,
  //post
  USERS_DATA_POST,
  USERS_DATA_POST_SUCCESS,
  USERS_DATA_POST_FAIL,
  //put
  USERS_DATA_PUT,
  USERS_DATA_PUT_SUCCESS,
  USERS_DATA_PUT_FAIL,
  //get instructor
  USERS_GET_INSTRUCTOR_DATA,
  USERS_GET_INSTRUCTOR_DATA_SUCCESS,
  USERS_GET_INSTRUCTOR_DATA_FAIL,
  //verify token
  USERS_VERIFY_TOKEN,
  USERS_VERIFY_TOKEN_SUCCESS,
  USERS_VERIFY_TOKEN_FAIL,

  //resend verification
  USERS_RESEND_VERIFICATION,
  USERS_RESEND_VERIFICATION_SUCCESS,
  USERS_RESEND_VERIFICATION_FAIL,
  //remove user data
  USERS_DATA_ID_REMOVE,
  // change password
  USERS_CHANGE_PASSWORD,
  USERS_CHANGE_PASSWORD_SUCCESS,
  USERS_CHANGE_PASSWORD_FAIL,
  // reset password
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,

  //verify password
  VERIFY_PASSWORD_TOKEN,
  VERIFY_PASSWORD_TOKEN_SUCCESS,
  VERIFY_PASSWORD_TOKEN_FAIL,

  // new password
  NEW_PASSWORD_ACCOUNT,
  NEW_PASSWORD_ACCOUNT_SUCCESS,
  NEW_PASSWORD_ACCOUNT_FAIL,
} from "../config/UsersConfig";

//get
export const getUsersData = (value) => ({
  type: USERS_DATA,
  value,
});
export const getUsersDataIdSuccess = (value) => ({
  type: USERS_DATA_ID_SUCCESS,
  value,
});
export const getUsersDataSuccess = (value) => ({
  type: USERS_DATA_SUCCESS,
  value,
});
export const getUsersDataFail = (value) => ({
  type: USERS_DATA_FAIL,
  value,
});

//post
export const postUsersData = (value) => ({
  type: USERS_DATA_POST,
  value,
});
export const postUsersDataSuccess = (value) => ({
  type: USERS_DATA_POST_SUCCESS,
  value,
});
export const postUsersDataFail = (value) => ({
  type: USERS_DATA_POST_FAIL,
  value,
});

//put
export const putUsersData = (value) => ({
  type: USERS_DATA_PUT,
  value,
});
export const putUsersDataSuccess = (value) => ({
  type: USERS_DATA_PUT_SUCCESS,
  value,
});
export const putUsersDataFail = (value) => ({
  type: USERS_DATA_PUT_FAIL,
  value,
});

//get instructor
export const getInstructorData = (value) => ({
  type: USERS_GET_INSTRUCTOR_DATA,
  value,
});
export const getInstructorDataSuccess = (value) => ({
  type: USERS_GET_INSTRUCTOR_DATA_SUCCESS,
  value,
});
export const getInstructorDataFail = (value) => ({
  type: USERS_GET_INSTRUCTOR_DATA_FAIL,
  value,
});

//verify token
export const verifyToken = (value) => ({
  type: USERS_VERIFY_TOKEN,
  value,
});
export const verifyTokenSuccess = (value) => ({
  type: USERS_VERIFY_TOKEN_SUCCESS,
  value,
});
export const verifyTokenFail = (value) => ({
  type: USERS_VERIFY_TOKEN_FAIL,
  value,
});

//resend verification
export const resendVerification = (value) => ({
  type: USERS_RESEND_VERIFICATION,
  value,
});
export const resendVerificationSuccess = (value) => ({
  type: USERS_RESEND_VERIFICATION_SUCCESS,
  value,
});
export const resendVerificationFail = (value) => ({
  type: USERS_RESEND_VERIFICATION_FAIL,
  value,
});

//remove user data
export const removeUsersDataId = (value) => ({
  type: USERS_DATA_ID_REMOVE,
  value,
});

// change password
export const changePassword = (value) => ({
  type: USERS_CHANGE_PASSWORD,
  value,
});
export const changePasswordSuccess = (value) => ({
  type: USERS_CHANGE_PASSWORD_SUCCESS,
  value,
});
export const changePasswordFail = (value) => ({
  type: USERS_CHANGE_PASSWORD_FAIL,
  value,
});

// forgot password
export const forgotPassword = (value) => ({
  type: FORGOT_PASSWORD,
  value,
});
export const forgotPasswordSuccess = (value) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  value,
});
export const forgotPasswordFail = (value) => ({
  type: FORGOT_PASSWORD_FAIL,
  value,
});

//verify password
export const verifyPasswordToken = (value) => ({
  type: VERIFY_PASSWORD_TOKEN,
  value,
});
export const verifyPasswordTokenSuccess = (value) => ({
  type: VERIFY_PASSWORD_TOKEN_SUCCESS,
  value,
});
export const verifyPasswordTokenFail = (value) => ({
  type: VERIFY_PASSWORD_TOKEN_FAIL,
  value,
});

//new password
export const newPasswordAccount = (value) => ({
  type: NEW_PASSWORD_ACCOUNT,
  value,
});
export const newPasswordAccountSuccess = (value) => ({
  type: NEW_PASSWORD_ACCOUNT_SUCCESS,
  value,
});
export const newPasswordAccountFail = (value) => ({
  type: NEW_PASSWORD_ACCOUNT_FAIL,
  value,
});
