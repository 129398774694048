import {
  //get provinces
  ORDERS_PROVINCES,
  ORDERS_PROVINCES_SUCCESS,
  ORDERS_PROVINCES_FAIL,
  //get cities
  ORDERS_CITIES,
  ORDERS_CITIES_SUCCESS,
  ORDERS_CITIES_FAIL,
  //get subdistricts
  ORDERS_SUBDISTRICTS,
  ORDERS_SUBDISTRICTS_SUCCESS,
  ORDERS_SUBDISTRICTS_FAIL,
  //get couriers
  ORDERS_COURIERS,
  ORDERS_COURIERS_SUCCESS,
  ORDERS_COURIERS_FAIL,
  //get price
  ORDERS_PRICE,
  ORDERS_PRICE_SUCCESS,
  ORDERS_PRICE_FAIL,
  //post order
  ORDERS_POST,
  ORDERS_POST_SUCCESS,
  ORDERS_POST_FAIL,
} from "../config/OrdersConfig";

const intialState = {
  ordersProvinces: null,
  ordersCities: null,
  ordersSubdistricts: null,
  ordersCouriers: null,
  ordersPrice: null,
  ordersPostData: null,
  isLoading: false,
};

export const OrdersReducers = function (state = intialState, action) {
  switch (action.type) {
    //get provinces
    case ORDERS_PROVINCES:
      return { ...state, isLoading: true };
    case ORDERS_PROVINCES_SUCCESS:
      return { ...state, isLoading: false, ordersProvinces: action.value };
    case ORDERS_PROVINCES_FAIL:
      return { ...state, isLoading: false, ordersProvinces: "error" };
    //get cities
    case ORDERS_CITIES:
      return { ...state, isLoading: true };
    case ORDERS_CITIES_SUCCESS:
      return { ...state, isLoading: false, ordersCities: action.value };
    case ORDERS_CITIES_FAIL:
      return { ...state, isLoading: false, ordersCities: "error" };
    //get subdistricts
    case ORDERS_SUBDISTRICTS:
      return { ...state, isLoading: true };
    case ORDERS_SUBDISTRICTS_SUCCESS:
      return { ...state, isLoading: false, ordersSubdistricts: action.value };
    case ORDERS_SUBDISTRICTS_FAIL:
      return { ...state, isLoading: false, ordersSubdistricts: "error" };
    //get couriers
    case ORDERS_COURIERS:
      return { ...state, isLoading: true };
    case ORDERS_COURIERS_SUCCESS:
      return { ...state, isLoading: false, ordersCouriers: action.value };
    case ORDERS_COURIERS_FAIL:
      return { ...state, isLoading: false, ordersCouriers: "error" };
    //get price
    case ORDERS_PRICE:
      return { ...state, isLoading: true };
    case ORDERS_PRICE_SUCCESS:
      return { ...state, isLoading: false, ordersPrice: action.value };
    case ORDERS_PRICE_FAIL:
      return { ...state, isLoading: false, ordersPrice: "error" };
    //post orders
    case ORDERS_POST:
      return { ...state, isLoading: true };
    case ORDERS_POST_SUCCESS:
      return { ...state, isLoading: false, ordersPostData: "success" };
    case ORDERS_POST_FAIL:
      return { ...state, isLoading: false, ordersPostData: "fail" };

    default:
      return state;
  }
};
