import {
  WEBINARS_DATA,
  WEBINARS_DATA_SUCCESS,
  WEBINARS_DATA_ID_SUCCESS,
  WEBINARS_DATA_FAIL,
  WEBINARS_TYPE_LENGTH_DATA,
  WEBINARS_TYPE_LENGTH_DATA_SUCCESS,
  WEBINARS_TYPE_LENGTH_DATA_FAIL,
  WEBINARS_CONSULTATION_LENGTH_DATA,
  WEBINARS_CONSULTATION_LENGTH_DATA_SUCCESS,
  WEBINARS_CONSULTATION_LENGTH_DATA_FAIL,
} from "../config/WebinarsConfig";

const intialState = {
  webinarsData: null,
  webinarsDataId: null,
  webinarTypeLengthData: null,
  webinarConsultationLengthData: null,
  isLoading: false,
};

export const WebinarsReducers = function (state = intialState, action) {
  switch (action.type) {
    case WEBINARS_DATA:
      return { ...state, isLoading: true };
    case WEBINARS_DATA_SUCCESS:
      return { ...state, isLoading: false, webinarsData: action.value };
    case WEBINARS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, webinarsDataId: action.value };
    case WEBINARS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        webinarsDataId: "error",
        webinarsData: "error",
      };

    case WEBINARS_TYPE_LENGTH_DATA:
      return { ...state, isLoading: true };
    case WEBINARS_TYPE_LENGTH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        webinarTypeLengthData: action.value,
      };

    case WEBINARS_TYPE_LENGTH_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        webinarTypeLengthData: "error",
      };

    case WEBINARS_CONSULTATION_LENGTH_DATA:
      return { ...state, isLoading: true };
    case WEBINARS_CONSULTATION_LENGTH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        webinarConsultationLengthData: action.value,
      };

    case WEBINARS_CONSULTATION_LENGTH_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        webinarConsultationLengthData: "error",
      };
    default:
      return state;
  }
};
