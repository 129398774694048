/* eslint-disable jsx-a11y/anchor-is-valid */
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Form, Nav, Row } from 'react-bootstrap';
import notificationIcon from '../../assets/images/notification.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../../assets/css/Layout/header.css';
import '../../assets/css/Layout/newHeader.css';
import { AuthIsLogin, AuthLogout } from '../../helper/auth';
import { DM_COURSE_ID } from '../../helper/constant';
import { slugify } from '../../helper/functions';
import { courseService } from '../../http/CourseLengthHttp';
import { removeLoginData } from '../../redux/actions/LoginActions';
import { removeUsersDataId } from '../../redux/actions/UsersActions';
import search from './../../assets/images/search.png';
import send from './../../assets/images/send.svg';
// import { getHeadersDataThunk } from "../../redux/thunks/HeadersThunks";

const MySwal = withReactContent(Swal);

function Header({
  isSidebar,
  collapsed,
  handleCollapsedChange,
  id,
  searchModal,
  setSearchModal,
  handleSearch,
  myRef,
}) {
  // declare state in react
  const [showMenuCertification, setShowMenuCertification] = useState(false);
  const [statusCertification, setStatusCertification] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [keyword, setKeyword] = useState('');
  const [liveSearchData, setLiveSearchData] = useState(null);
  const history = useHistory();
  // call state from redux
  const header = useSelector((state) => state.header);
  const login = useSelector((state) => state.login);
  const headerData = header.headersData;
  const isUploadIjazah = useSelector((state) => state.diploma.hasUploaded);
  const dispatch = useDispatch();

  let address = useLocation();
  let splitted = address.pathname.split('/');

  useEffect(() => {
    console.log(isSidebar);
  }, [isSidebar]);

  // recall api to update the data
  useEffect(() => {
    if (login.loginData) {
      // dispatch(getHeadersDataThunk());
      dispatch(removeLoginData());
    }
  }, [login]);

  // useEffect(() => {
  //   const nameUser = headerData?.user?.name;
  //   const email = headerData?.user?.email;
  //   setName(nameUser);
  //   setEmail(email);
  // }, [headerData]);

  useEffect(() => {
    if (!statusCertification) {
      setShowMenuCertification(false);
    }
  }, [statusCertification, showMenuCertification]);

  useEffect(() => {
    const delayTiming = setTimeout(() => {
      searchCourse();
    }, 1000);

    return () => clearTimeout(delayTiming);
  }, [keyword]);

  // useEffect(() => {
  //   if (searchModal) {
  //     courseOnlinePopularLength(`?sort=populer&size=5`);
  //   }
  // }, [searchModal]);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.target.blur();
      history.push(`/search/semua/keyword=${keyword}`);
      setKeyword('');
      setSearchModal(!searchModal);
    }
  };

  const searchCourse = async () => {
    try {
      const response = await courseService.getCourse(
        `?keyword=${keyword}&size=5`
      );
      if (response?.items?.length > 0) setLiveSearchData(response?.items);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleStatusCertification = (e) => {
  //   setStatusCertification(e);
  // };
  const handleMouse = (e) => {
    if (e.type === 'mouseover') {
      setSearchModal(false);
    }
  };
  const handleLogoutClick = () =>
    MySwal.fire({
      title: 'Apakah anda yakin ingin keluar?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Tidak',
      confirmButtonText: 'Ya',
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(removeLoginData());
        dispatch(removeUsersDataId());
        // dispatch(removeHeaderData());
        AuthLogout(history);
      }
    });
  return (
    <StyledWrapperMain>
      <Container>
        <div className="main-content">
          <div
            className={`middle-sidebar-header bg-white ${
              splitted[1] === 'bnsp' || splitted[3] === DM_COURSE_ID
                ? 'd-flex justify-content-between'
                : 'null'
            }`}
          >
            {!isSidebar ? (
              <div className="d-flex align-items-center header_left__wrapper">
                {/* redirect if user login or not */}
                {AuthIsLogin('', true) ? (
                  <>
                    <Link to="/">
                      <div
                        alt="Satukelas"
                        className="satu-kelas-logo__main"
                      ></div>
                    </Link>
                  </>
                ) : (
                  <Link to="/">
                    <div
                      alt="Satukelas"
                      className="satu-kelas-logo__main"
                    ></div>
                  </Link>
                )}
                <>
                  {splitted[3] === DM_COURSE_ID ||
                  splitted[1] === 'agreement-user' ||
                  splitted[1] === 'bnsp-digital-marketing' ? null : (
                    <>
                      <Nav>
                        {splitted[1] !== 'dashboard' &&
                          AuthIsLogin('', true) && (
                            <NavLink
                              to="/dashboard/course/"
                              className="main header_left__item "
                            >
                              Dashboard
                            </NavLink>
                          )}
                        <NavLink
                          className="main header_left__item "
                          to="/about-us"
                        >
                          Tentang Kami
                        </NavLink>
                        <NavLink
                          id="katalog"
                          className={`position-inherit main header_left__item ${
                            id === 'katalog'
                              ? 'interaction-active'
                              : id === 'search'
                              ? ''
                              : ''
                          } dropdown mx-1`}
                          onMouseOver={handleMouse}
                          to="#"
                        >
                          Katalog Pelatihan
                          <Container id="mega" className="mega-menu__container">
                            <Row noGutters={true}>
                              <Col md={6} lg={3} className="title_megamenu">
                                <div className="p-4">
                                  <div className="mb-3 catalogue_megamenu">
                                    Katalog Pelatihan
                                  </div>
                                  <div className="subtitle_megamenu">
                                    Kembangkan skill Anda dengan pelatihan yang
                                    disediakan oleh Satukelas.
                                  </div>
                                </div>
                              </Col>
                              <Col
                                md={6}
                                lg={3}
                                className="second_title__megamenu"
                              >
                                {' '}
                                <div className="p-4 last_menu">
                                  <div>
                                    <div className="mb-3 title_menu_list__mega">
                                      Pelatihan
                                    </div>
                                    <Link
                                      className="mr-3"
                                      to="/search/pelatihan/"
                                    >
                                      <div className="menu_list__mega">
                                        Pelatihan Mandiri
                                      </div>
                                    </Link>
                                    <Link
                                      className="mr-3"
                                      to="/search/studi%20kasus/"
                                    >
                                      <div className="menu_list__mega">
                                        Studi Kasus
                                      </div>
                                    </Link>
                                    <Link
                                      className="mr-3"
                                      to="/search/try%20out/"
                                    >
                                      <div className="menu_list__mega">
                                        Try Out
                                      </div>
                                    </Link>
                                  </div>
                                  <Link className="mr-3" to="/search/semua/">
                                    <div className="menu_list__mega_more mb-5">
                                      Lihat semua pelatihan (477+)
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                              <Col
                                md={6}
                                lg={3}
                                className="second_title__megamenu"
                              >
                                <div className="p-4 last_menu">
                                  <div>
                                    <div className="mb-3 title_menu_list__mega">
                                      Live Class
                                    </div>
                                    <Link
                                      className="mr-3"
                                      to="/webinar-list/webinar"
                                    >
                                      <div className="menu_list__mega">
                                        Webinar
                                      </div>
                                    </Link>
                                    <Link
                                      className="mr-3"
                                      to="/webinar-list/konsultasi"
                                    >
                                      <div className="menu_list__mega">
                                        Konsultasi
                                      </div>
                                    </Link>

                                    {/* <Link to={`/${slugify("zoomverify")}`}>
                                      <div className="mb-3 menu_list__mega">ZoomVerify</div>
                                    </Link> */}
                                  </div>

                                  <Link
                                    className="header_left__item mr-3"
                                    to="/webinar-list/semua/"
                                  >
                                    <div className="menu_list__mega_more mb-5">
                                      Lihat semua Live
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                              <Col
                                md={6}
                                lg={3}
                                className="second_title__megamenu"
                              >
                                <div className="p-4 last_menu">
                                  <div>
                                    <div className="mb-3 title_menu_list__mega">
                                      Sertifikasi
                                    </div>
                                    <Link to="/search/sertifikasi/keyword=brevet">
                                      <div className="mb-3 menu_list__mega">
                                        Brevet Pajak
                                      </div>
                                    </Link>
                                    <div className="d-flex mb-3 menu_list__mega">
                                      <div>
                                        Badan Nasional Sertifikasi Profesi(BNSP)
                                      </div>
                                      <div>
                                        <Badge>Soon</Badge>
                                      </div>
                                    </div>
                                    <Link to={`/english-program`}>
                                      <div className="mb-3 menu_list__mega">
                                        Program Bahasa Inggris{' '}
                                        <span id="new">New</span>
                                      </div>
                                    </Link>
                                    {/* <div>
                                      <Link to={`/scorm/${slugify("Kickstart Your TOEFL Preparation")}/lp46808336`}>
                                        <div className="mb-3 menu_list__mega">Kickstart Your TOEFL Preparation</div>
                                      </Link>
                                    </div> */}
                                  </div>
                                  <Link
                                    className="header_left__item mr-3"
                                    to="/search/sertifikasi"
                                  >
                                    <div className="menu_list__mega_more mb-5">
                                      Lihat semua sertifikasi (7)
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </NavLink>
                        <NavLink
                          id="lainnya"
                          className={`third header_left__item ${
                            id === 'lainnya'
                              ? 'interaction-active'
                              : id === 'search'
                              ? ''
                              : ''
                          } dropdown mx-1`}
                          to="#"
                          onMouseOver={handleMouse}
                        >
                          Lainnya
                          <div id="menu" className="menu__container p-3">
                            <div className="">
                              <Link to="/blog" className="title_product">
                                <div className="mb-3">Blog</div>
                              </Link>
                              <Link to="/webstore" className="title_product">
                                <div className="mb-3">Webstore</div>
                              </Link>
                              {/* <Link to="/indonesia-tax-review" className="title_product">
                                <div className="mb-3">Majalah ITR</div>
                              </Link> */}
                              <Link
                                to="#"
                                className="title_product d-flex justify-content-between"
                              >
                                {' '}
                                <div className="mb-3">Kumpulan Peraturan</div>
                                <div>
                                  <Badge variant="primary">Soon</Badge>
                                </div>
                              </Link>
                              <Link
                                to="/corporate-digital-learning-center"
                                className="title_product d-flex justify-content-between"
                              >
                                <div className="mr-2">
                                  Digital Learning Center
                                </div>
                              </Link>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/terbaru"
                          className="main header_left__item "
                        >
                          Terbaru
                        </NavLink>
                      </Nav>
                    </>
                  )}
                </>
                {/* )} */}

                {/* <div className="header-bnsp">
                  bnsp menu in user isBnsp status
                  {headerData?.user?.is_bnsp === 1 && isBnspUser(1)}
                  {headerData?.user?.is_bnsp === 2 && isBnspUser(2)}
                  {headerData?.user?.is_bnsp === 3 && isBnspUser(3)}
                </div> */}
              </div>
            ) : (
              <div className="d-flex align-items-center header_left__wrapper">
                <div onClick={() => handleCollapsedChange(!collapsed)}></div>
                <Link to="/">
                  <img
                    data-src="https://satukelas.com/wp-content/uploads/2021/02/png-satukelas-296x63.png"
                    alt="Satukelas"
                    width="148"
                    className="satu-kelas-logo lazyload"
                  />
                </Link>
                <>
                  <Nav>
                    {splitted[1] !== 'dashboard' ? (
                      AuthIsLogin('', true) ? (
                        <NavLink
                          to="/dashboard/course/"
                          className="header_left__item "
                        >
                          Dashboard
                        </NavLink>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    <NavLink className="header_left__item " to="/about-us">
                      Tentang Kami
                    </NavLink>
                    <NavLink
                      id="katalog"
                      className={`position-inherit main header_left__item ${
                        id === 'katalog'
                          ? 'interaction-active'
                          : id === 'search'
                          ? ''
                          : ''
                      } dropdown mx-1`}
                      onMouseOver={handleMouse}
                      to="#"
                    >
                      Katalog Pelatihan
                      <Container id="mega" className="mega-menu__container">
                        <Row noGutters={true}>
                          <Col md={6} lg={3} className="title_megamenu">
                            <div className="p-4">
                              <div className="mb-3 catalogue_megamenu">
                                Katalog Pelatihan
                              </div>
                              <div className="subtitle_megamenu">
                                Kembangkan skill Anda dengan pelatihan yang
                                disediakan oleh Satukelas.
                              </div>
                            </div>
                          </Col>
                          <Col md={6} lg={3} className="second_title__megamenu">
                            {' '}
                            <div className="p-4 last_menu">
                              <div>
                                <div className="mb-3 title_menu_list__mega">
                                  Pelatihan
                                </div>
                                <Link className="mr-3" to="/search/pelatihan/">
                                  <div className="menu_list__mega">
                                    Pelatihan Mandiri
                                  </div>
                                </Link>
                                <Link
                                  className="mr-3"
                                  to="/search/studi%20kasus/"
                                >
                                  <div className="menu_list__mega">
                                    Studi Kasus
                                  </div>
                                </Link>
                                <Link className="mr-3" to="/search/try%20out/">
                                  <div className="menu_list__mega">Try Out</div>
                                </Link>
                              </div>
                              <Link className="mr-3" to="/search/semua/">
                                <div className="menu_list__mega_more mb-5">
                                  Lihat semua pelatihan (477+)
                                </div>
                              </Link>
                            </div>
                          </Col>
                          <Col md={6} lg={3} className="second_title__megamenu">
                            <div className="p-4 last_menu">
                              <div>
                                <div className="mb-3 title_menu_list__mega">
                                  Live Class
                                </div>
                                <Link
                                  className="mr-3"
                                  to="/webinar-list/webinar"
                                >
                                  <div className="menu_list__mega">Webinar</div>
                                </Link>
                                <Link
                                  className="mr-3"
                                  to="/webinar-list/konsultasi"
                                >
                                  <div className="menu_list__mega">
                                    Konsultasi
                                  </div>
                                </Link>
                              </div>
                              <Link
                                className="header_left__item mr-3"
                                to="/webinar-list/semua/"
                              >
                                <div className="menu_list__mega_more mb-5">
                                  Lihat semua Live
                                </div>
                              </Link>
                            </div>
                          </Col>
                          <Col md={6} lg={3} className="second_title__megamenu">
                            <div className="p-4 last_menu">
                              <div>
                                <div className="mb-3 title_menu_list__mega">
                                  Sertifikasi
                                </div>
                                <Link to="/search/sertifikasi/keyword=brevet">
                                  <div className="mb-3 menu_list__mega">
                                    Brevet Pajak
                                  </div>
                                </Link>
                                <div className="d-flex mb-3 menu_list__mega">
                                  <div>
                                    Badan Nasional Sertifikasi Profesi(BNSP)
                                  </div>
                                  <div>
                                    <Badge>Soon</Badge>
                                  </div>
                                </div>
                                {/* <div>
                                  <Link to={`/scorm/${slugify("Kickstart Your TOEFL Preparation")}/lp46808336`}>
                                    <div className="mb-3 menu_list__mega">Kickstart Your TOEFL Preparation</div>
                                  </Link>
                                </div> */}
                              </div>

                              <Link
                                className="header_left__item mr-3"
                                to="/search/sertifikasi"
                              >
                                <div className="menu_list__mega_more mb-5">
                                  Lihat semua sertifikasi (7)
                                </div>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </NavLink>
                    <NavLink
                      id="lainnya"
                      className={`third header_left__item ${
                        id === 'lainnya'
                          ? 'interaction-active'
                          : id === 'search'
                          ? ''
                          : ''
                      } dropdown mx-1`}
                      to="#"
                      onMouseOver={handleMouse}
                    >
                      Lainnya
                      <div id="menu" className="menu__container p-3">
                        <div className="">
                          <Link to="/blog" className="title_product">
                            <div className="mb-3">Blog</div>
                          </Link>
                          <Link to="/webstore" className="title_product">
                            <div className="mb-3">Webstore</div>
                          </Link>
                          {/* <Link to="/indonesia-tax-review" className="title_product">
                            <div className="mb-3">Majalah ITR</div>
                          </Link> */}
                          <Link
                            to="#"
                            className="title_product d-flex justify-content-between"
                          >
                            <div className="mb-3">Kumpulan Peraturan</div>
                            <div>
                              <Badge variant="primary">Soon</Badge>
                            </div>
                          </Link>
                          <Link
                            to="/corporate-digital-learning-center"
                            className="title_product d-flex justify-content-between"
                          >
                            <div className="mr-2">Digital Learning Center</div>
                          </Link>
                        </div>
                      </div>
                    </NavLink>
                  </Nav>
                </>
              </div>
            )}
            {/* bnsp page condition */}
            {splitted[1] === 'bnsp' || splitted[3] === DM_COURSE_ID ? (
              AuthIsLogin('', true) ? (
                <ul className="d-flex ml-auto right-menu-icon m-0">
                  <li>
                    <div>
                      {/* {headerData?.user?.avatar === "" ? ( */}
                      <div className="container-user grey">
                        <span className="icon-user size-profile"></span>
                      </div>
                      {/* ) : (
                        <img
                           data-src={`${headerData?.user?.avatar}`}
                           alt="user"
                          width="40"
                          className="w40 rounded-circle mt--1 lazyload"
                         />
                       )} */}
                      <div className="menu-dropdown menu-dropdown-profile">
                        <div className="menu_name">{name}</div>
                        <br />
                        <div className="email-text mb-3">{email}</div>
                        {/* {headerData?.user?.is_bnsp !== 0 ? null : (
                          <>
                            <Link
                              className="dropdown-item"
                              to="/dashboard/profile/"
                            >
                              <span className="icon-user mr-2"></span>
                              Profil
                            </Link>

                            <Link
                              className="dropdown-item"
                              to="/company-profile"
                            >
                              <span className="icon-build mr-2"></span>
                              Perusahaan Saya
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="/dashboard/settings/"
                            >
                              <span className="icon-cog mr-2"></span>
                              Pengaturan
                            </Link>
                          </>
                        )} */}

                        <div className="separator pb-3" />
                        <a
                          className="dropdown-item logout-text"
                          onClick={() => handleLogoutClick()}
                        >
                          <span className="icon-logout mr-1"></span>
                          Keluar
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              ) : (
                <div>
                  {splitted[1] === 'bnsp-digital-marketing' ? (
                    <Link to="/login" className="login-button__header mr-3">
                      Login
                    </Link>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        className="login-button__header px-2 mr-4"
                      >
                        Login
                      </Link>
                      <Link
                        to="/bnsp/bnsp-digital-marketing/register"
                        className="btn btn-primary login-button__header px-2"
                      >
                        Register
                      </Link>
                    </>
                  )}
                </div>
              )
            ) : (
              <ul className="d-flex ml-auto pl-0 align-items-center right-menu-icon m-0">
                <div
                  className={`${
                    AuthIsLogin('', true)
                      ? 'search-icon__hover mr-0'
                      : 'search-icon__hover mr-3 mr-xl-4'
                  }`}
                  id="search"
                  onClick={(e) => handleSearch(e)}
                >
                  <img className="lazyload" data-src={search} alt="search" />
                </div>

                {AuthIsLogin('', true) && (
                  <li>
                    <div className="notification_container">
                      <span className="dot-count bg-warning"></span>
                      <span>
                        <img src={notificationIcon} alt="notif" />
                      </span>
                      {/* <span className="icon-notification"></span> */}
                      <div className="menu-dropdown">
                        <h4 className="notification_text mb-4">Notifikasi</h4>
                        {/* {headerData?.notifications?.length > 0 ? (
                          <>
                            {headerData?.notifications?.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div className="card box-none bg-transparent-card w-100 border-0  mb-3">
                                    <div
                                      className={`${item.is_read === 0
                                        ? `name_notification__detail_active`
                                        : `name_notification__detail`
                                        } mb-1 mt-0`}
                                    >
                                      {item.message}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <Link
                              className="see_all__notification"
                              to="/dashboard/notification"
                            >
                              Lihat Semua
                            </Link>
                          </>
                        ) : ( */}
                        <p className="text-center name_notification__detail_none mb-1 mt-0 d-block">
                          Belum Ada Notifikasi
                        </p>
                        {/* )} */}
                      </div>
                    </div>
                  </li>
                )}
                {AuthIsLogin('', true) ? (
                  <li>
                    <div>
                      {/* {headerData?.user?.avatar === "" ? ( */}
                      <div className="container-user grey">
                        <span className="icon-user size-profile"></span>
                      </div>
                      {/* ) : (
                        <img
                          data-src={`${headerData?.user?.avatar}`}
                          alt="user"
                          width="40"
                          className="w40 rounded-circle mt--1 lazyload"
                        />
                      )} */}
                      <div className="menu-dropdown menu-dropdown-profile">
                        {/* <div className="menu_name">{name}</div>
                        <div className="email-text mb-3">{email}</div> */}
                        <Link
                          className="dropdown-item"
                          to="/dashboard/profile/"
                        >
                          <span className="icon-user mr-2"></span>
                          Profil
                        </Link>
                        {/* {(headerData?.user?.diploma?.diploma_id_sma || headerData?.user?.diploma?.diploma_id_s1) && (
                          <Link className="dropdown-item" to="/atpi/ijazah/">
                            <span className="icon-file mr-2"></span>
                            Ijazah
                          </Link>
                        )} */}
                        <Link className="dropdown-item" to="/company-profile">
                          <span className="icon-build mr-2"></span>
                          Perusahaan Saya
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/dashboard/settings/"
                        >
                          <span className="icon-cog mr-2"></span>
                          Pengaturan
                        </Link>
                        <a
                          className="dropdown-item"
                          href="https://form.asana.com/?k=gQs4Q3qV2qipkvrpbNOhOA&d=1200494741159113"
                        >
                          <img data-src={send} className="lazyload mr-2" />
                          {/* <span className="icon-file mr-2"></span> */}
                          Kirim Feedback
                        </a>
                        <div className="separator pb-3" />
                        <a
                          className="dropdown-item logout-text"
                          onClick={() => handleLogoutClick()}
                        >
                          <span className="icon-logout mr-1"></span>
                          Keluar
                        </a>
                      </div>
                    </div>
                  </li>
                ) : (
                  <>
                    <div className="mr-3">
                      <Link
                        to={`/login?targetUrl=${address?.pathname}`}
                        className="btn login-button__header-left px-2 "
                      >
                        Masuk Akun
                      </Link>
                    </div>
                    <div>
                      <Link
                        to={`/register`}
                        className="btn btn-primary login-button__header px-2"
                      >
                        Coba Gratis
                      </Link>
                    </div>
                  </>
                )}
              </ul>
            )}
          </div>
        </div>
      </Container>
      {searchModal && (
        <div className="search-container">
          <Container>
            <Row>
              <Col>
                <div className="d-flex align-items-center">
                  <div className="anim-search"></div>
                  <div className="input-wrap">
                    <span className="icon-search__white ml-3"></span>
                    <Form.Control
                      id="search-placeholder"
                      className="satukelas_input__search mr-3 p-0"
                      type="text"
                      placeholder="Hari ini mau belajar apa?"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      value={keyword}
                      ref={myRef}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {liveSearchData &&
                  liveSearchData?.map((item, index) => {
                    return (
                      <div>
                        <Link
                          to={`/course/${slugify(item.title)}/${
                            item.course_id
                          }`}
                          key={index}
                          className="popular-course__title"
                        >
                          {item.title}
                        </Link>
                      </div>
                    );
                  })}
              </Col>
            </Row>
          </Container>
          <div
            className="close-search__button position-absolute"
            onClick={handleSearch}
          >
            ×
          </div>
        </div>
      )}
    </StyledWrapperMain>
  );
}

export default Header;

const StyledWrapperMain = styled.section`
  svg {
    height: 32px;
    width: 32px;
  }
  .main-content {
    padding: 24px 0;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #fff;
  }
`;
