import { PromiseService } from "../../services/PromiseService";
import { CERTIFICATES_DATA_LINK } from "../config/CertificatesConfig";
import {
  //get
  getCertificatesData,
  getCertificatesDataIdSuccess,
  //fail
  getCertificatesDataFail,
} from "../actions/CertificatesActions";

export const getCertificatesDataThunk = (data) => async (dispatch) => {
  dispatch(getCertificatesData());
  try {
    const response = await PromiseService.getData(
      `${CERTIFICATES_DATA_LINK}/${data.param}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getCertificatesDataIdSuccess(response.data));
    } else {
      dispatch(getCertificatesDataFail(response));
    }
  } catch (error) {
    dispatch(getCertificatesDataFail(error));
  }
};
