import {
  //get
  INBOX_DATA,
  INBOX_DATA_ID_SUCCESS,
  INBOX_DATA_SUCCESS,
  INBOX_DATA_FAIL,
  //post
  INBOX_DATA_POST,
  INBOX_DATA_POST_SUCCESS,
  INBOX_DATA_POST_FAIL,
  //put
  INBOX_DATA_PUT,
  INBOX_DATA_PUT_SUCCESS,
  INBOX_DATA_PUT_FAIL,
  //delete
  INBOX_DATA_DELETE,
  INBOX_DATA_DELETE_SUCCESS,
  INBOX_DATA_DELETE_FAIL,
  // start
  INBOX_DATA_START,
  INBOX_DATA_START_SUCCESS,
  INBOX_DATA_START_FAIL,
} from "../config/InboxConfig";

const intialState = {
  inboxDataPost: "",
  inboxDataPut: "",
  inboxDataDelete: "",
  inboxData: [],
  inboxDataId: [],
  inboxDataStart: "",
  isLoading: false,
};

export const InboxReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case INBOX_DATA:
      return { ...state, isLoading: true };
    case INBOX_DATA_SUCCESS:
      return { ...state, isLoading: false, inboxData: action.value };
    case INBOX_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, inboxDataId: action.value };
    case INBOX_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        inboxData: "error",
        inboxDataId: "error",
      };
    //post
    case INBOX_DATA_POST:
      return { ...state, isLoading: true, inboxDataPost: "" };
    case INBOX_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, inboxDataPost: "success" };
    case INBOX_DATA_POST_FAIL:
      return { ...state, isLoading: false, inboxDataPost: "error" };

    //put
    case INBOX_DATA_PUT:
      return { ...state, isLoading: true, inboxDataPut: "" };
    case INBOX_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, inboxDataPut: "success" };
    case INBOX_DATA_PUT_FAIL:
      return { ...state, isLoading: false, inboxDataPut: "error" };

    //delete
    case INBOX_DATA_DELETE:
      return { ...state, isLoading: true, inboxDataDelete: "" };
    case INBOX_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, inboxDataDelete: "success" };
    case INBOX_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, inboxDataDelete: "error" };
      
    default:
      return state;
  }
};
