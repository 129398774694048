import React, { useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  Tabs,
  Tab,
  Accordion,
} from "react-bootstrap";
import { company_list, faqUser, learnBootcamp } from "../../helper/constant";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import contract from "./../../assets/images/bootcamp/contract.png";
import exam from "./../../assets/images/bootcamp/exam.png";
import reportCard from "./../../assets/images/bootcamp/report-card.png";
import training from "./../../assets/images/bootcamp/training.png";
import finance from "./../../assets/images/bootcamp/finance.png";
import person from "./../../assets/images/bootcamp/person.png";
import "./../../assets/css/StaticPages/bootcamp.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { MembershipService } from "../../http/MembershipHttp";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { AuthData, AuthIsLogin } from "../../helper/auth";
import {
  checkElementMembership,
  checkMembership,
} from "../../helper/functions";
import { UserService } from "../../http/UserHttp";
const MySwal = withReactContent(Swal);

function Bootcamp() {
  const router = useHistory();
  const [idx, setIdx] = useState([]);
  const [membershipData, setMembershipData] = useState();
  const [listMembership, setListMembership] = useState();
  const header = useSelector((state) => state.header);
  const idUrl = router?.location?.hash;
  const dispatch = useDispatch();

  useEffect(() => {
    if (AuthData().userId) {
      getMembership()
    }
  }, []);

  const getMembership = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getMembership();
      setMembershipData(response.memberships);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  const menuNavigation = [
    {
      text: "Client",
      anchor: "#client",
    },
    {
      text: "Benefit",
      anchor: "#benefit",
    },
    {
      text: "Learning Experience",
      anchor: "#learning-experience",
    },
    {
      text: "Metode dan Biaya",
      anchor: "#metode",
    },
    {
      text: "FAQ",
      anchor: "#faq",
    },
  ];
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", () => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          if (entry.intersectionRatio > 0) {
            document
              .querySelector(`nav a[href="#${id}"]`)
              ?.parentElement?.childNodes[0].classList.add("active-menu");
          } else {
            document
              .querySelector(`nav a[href="#${id}"]`)
              ?.parentElement?.childNodes[0].classList.remove("active-menu");
          }
        });
      });

      // Track all sections that have an `id` applied
      document.querySelectorAll("section[id]").forEach((section) => {
        observer.observe(section);
      });
    });
  }, []);

  const getBenefit = (icon, title, desc) => {
    return (
      <div className="benefit-section__container">
        <div className="benefit-section__circle">
          <img className="lazyload" data-src={icon} />
        </div>
        <div className="benefit-section__text">
          <div className="benefit-section__card-title">{title}</div>
          <div className="benefit-section__card-desc">{desc}</div>
        </div>
      </div>
    );
  };
  const learningExperience = (illustration, title, desc) => {
    return (
      <div className="learning-section__card">
        <div className="text-center">
          <img className="lazyload" data-src={illustration} />
        </div>
        <div className="learning-section__card-title">{title}</div>
        <div className="learning-section__card-desc">{desc}</div>
      </div>
    );
  };
  const expertInAWeek = (title, desc) => {
    return (
      <div className="metode-section__card">
        <div className="metode-section__card-title">{title}</div>
        <div className="metode-section__card-subtitle">{desc}</div>
      </div>
    );
  };
  const handleShow = (index) => {
    if (idx.includes(index)) setIdx(idx.filter((i) => i !== index));
    else setIdx([...idx, index]);
  };

  const isMembership1Tahun = (arrayMembership) => {
    let flag = false;
    for (let i = 0; i < arrayMembership.length; i++) {
      const duration = checkElementMembership(arrayMembership[i]);
      const status = arrayMembership[i].status;
      if (duration >= 330 && status === "active") {
        flag = true;
      }
    }
    return flag;
  };

  const handleRegistBootcamp = (e) => {
    e.preventDefault();
    if (!AuthData().userId) {
      displayPopup(false, "Anda perlu login untuk mendaftar bootcamp.", true, "Ok");
      return;
    }
    if (membershipData !== undefined && membershipData?.length > 0) {
      let flag = isMembership1Tahun(membershipData);
      if (flag) {
        let dateNow = new Date();
        displayPopup(false, "Terima kasih sudah mendaftar!", true, "Ok");
        let payload = {
          bill_amount: 0,
          catalogue_id: "ca21964319",
          expired_at: moment(dateNow)
            .add(30, "days")
            .format("YYYY-MM-DD HH:MM:ss")
            .toString(),
          user_id: header?.headersData?.user?.user_id,
        };
        addMembershipToUser(payload);
      } else {
        displayPopup(
          false,
          "Silahkan berlangganan satukelas terlebih dahulu",
          true,
          "Mulai Berlangganan"
        );
      }
    } else {
      displayPopup(
        false,
        "Silahkan berlangganan satukelas 1 tahun terlebih dahulu",
        true,
        "Mulai Berlangganan"
      );
    }
  };

  /**
   * @function to add membership to a user with API
   * @param {Object} payload
   * @returns nothing
   */
  const addMembershipToUser = async (payload) => {
    dispatch(loadingStart());
    try {
      const response = await MembershipService.addMembershipToUser(payload);
      if (response) {
        displayPopup(false, "Sukses Daftar Bootcamp", true, "OK");
      }
    } catch (error) {
      console.log(error);
      displayPopup(false, "Terjadi kesalahan", true, "OK");
    }
    dispatch(loadingFinish());
  };
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (value.isConfirmed) {
        if (membershipData?.length > 0) {
          let flag = isMembership1Tahun(membershipData);
          if (flag) {
            dispatch(getUsersDataThunk(AuthData().userId));
            getMembership();
            router.push("/bootcamp/list");
          } else {
            if (AuthIsLogin("", true)) {
              router.push("/dashboard/membership/");
            } else {
              router.push(`/login?targetUrl=/bootcamp`);
            }
          }
        } else {
          router.push("/dashboard/membership/");
        }
      }
    });
  }
  const buttonCTA = (type) => {
    let membershipList = membershipData?.map((item, index) => {
      return item.catalogue_id;
    });
    return membershipList && membershipList.includes("ca21964319") ? (
      <Button
        href="/bootcamp/list"
        className={`hero-shot__cta px-4 ${type === "full" && "w-100"}`}
      >
        Lihat Jadwal
      </Button>
    ) : (
      <Button onClick={handleRegistBootcamp} className="hero-shot__cta px-4">
        Daftar Bootcamp
      </Button>
    );
  };
  return (
    <>
      <div className="hero-shot__bootcamp-image">
        <div className="hero-shot__bootcamp">
          <Container>
            <Row className="hero-shot__wrapper">
              <Col lg={7}>
                <div className="hero-shot__header">
                  Tingkatkan Kompetensi dalam Dunia Kerja dengan Mengikuti{" "}
                  <span>Bootcamp Problem Solving and Decision Making</span>
                </div>
                <div className="hero-shot__subheader">
                  Asah kemampuan pola pikir dengan pelajari Problem Solving &
                  Decision Making sehingga dapat tingkatkan kualitas kerja demi
                  tercapainya tujuan individu dan perusahaan
                </div>
                <div>{buttonCTA("not-full")}</div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col lg={3}>
            <div className="navigation-card__section mb-5">
              <nav>
                {menuNavigation?.map((item, index) => {
                  return (
                    <div key={index} className={`navigation-card__list `}>
                      <a
                        className={`${idUrl === item?.anchor ||
                          (idUrl === "" && index === 0 && "active-menu")
                          }`}
                        href={`${item?.anchor}`}
                        key={index}
                      >
                        {item?.text}
                      </a>
                    </div>
                  );
                })}
              </nav>
              {buttonCTA("full")}
            </div>
          </Col>
          <Col lg={8} className="offset-lg-1">
            <section id="client">
              <div className="client-section__title text-left">Client</div>
              <Row className="my-5 align-items-center justify-content-center">
                {company_list.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      className="w-100"
                    >
                      <img
                        key={index}
                        className="lazyload w-100"
                        data-src={item.img}
                      />
                    </Col>
                  );
                })}
              </Row>
              <div className="client-section__main-title">
                400+ Perusahaan Telah Percaya dengan satukelas
              </div>
              <div className="client-section__secondary-title">
                Gabung bersama 400 Perusahaan lainnya yang telah mempercayai
                satukelas untuk meningkatkan keterampilan pegawainya!
              </div>
            </section>
            <section id="benefit">
              <div className="benefit-section__title">Benefit</div>
              <div className="benefit-section__main-title">
                Apa saja yang akan Anda dapatkan?
              </div>
              <div className="mb-5">
                <Row>
                  <Col lg={6}>
                    {getBenefit(
                      training,
                      "Materi yang Disampaikan Oleh Trainer Terpercaya",
                      "Belajar melalui materi yang disampaikan oleh trainer yang kredibel"
                    )}
                  </Col>
                  <Col lg={6}>
                    {getBenefit(
                      exam,
                      "Tugas dengan Output Portfolio",
                      "Tugas yang menghasilkan portfolio sebagai bukti keterampilan dan kompetensi"
                    )}
                  </Col>
                  <Col lg={6}>
                    {getBenefit(
                      contract,
                      "Sertifikat Sebagai Bukti Kompetensi",
                      "Sertifikat yang dapat membantu perjalanan karier Anda"
                    )}
                  </Col>
                  <Col lg={6}>
                    {getBenefit(
                      reportCard,
                      "Laporan Performa Hasil Belajar",
                      "Laporan belajar sebagai bukti performa Anda dalam proses belajar"
                    )}
                  </Col>
                </Row>
              </div>
            </section>
            <section id="learning-experience">
              <div className="learning-section__title">Learning Experience</div>
              <div className="learning-section__main-title">
                Untuk Siapa Program Ini Dirancang?
              </div>
              <div className="learning-section__wrapper">
                <Row className="mb-5">
                  <Col sm={12} lg={6} className="mb-4">
                    {learningExperience(
                      finance,
                      "Mereka yang ingin meningkatkan kompetensi di bidang Soft Skills",
                      "Anda tidak hanya ingin bekerja keras, tetapi juga bekerja cerdas. Program ini dirancang untuk meningkatkan kompetensi Anda di bidang Soft Skills. Dibimbing oleh trainer berpengalaman, satukelas akan membantu memaksimalkan performa Anda di dunia kerja."
                    )}
                  </Col>
                  <Col sm={12} lg={6} className="mb-4">
                    {learningExperience(
                      finance,
                      "Mereka yang ingin memiliki bekal Soft Skills sebelum memulai karier",
                      "Ingin beralih karier, tapi bingung harus mulai dari mana? Bootcamp satukelas akan membekali Anda dengan keterampilan yang relevan untuk bersaing di industri."
                    )}
                  </Col>
                </Row>
              </div>
              <div className="learning-section__main-title">
                Tahapan Belajar yang akan Anda Ikuti
              </div>
              <div>
                <Tabs defaultActiveKey="pelatihan" id="learning-section">
                  <Tab eventKey="pelatihan" title="Pelatihan" className="mb-5">
                    <div id="learning-section__listing">
                      <ul>
                        <li className="learning-section__title-list">
                          Pre-Reading
                        </li>
                        <div className="learning-section__desc-list">
                          sehari sebelum setiap sesi berlangsung, peserta akan
                          memperoleh handout/video berisi gambaran materi yang
                          akan dipelajari dalam sesi
                        </div>
                        <li className="learning-section__title-list">
                          Pre-Test
                        </li>
                        <div className="learning-section__desc-list">
                          sehari sebelum setiap sesi berlangsung, peserta
                          diminta mengisi pre-test terkait materi yang akan
                          dipelajari
                        </div>
                        <li className="learning-section__title-list">Live</li>
                        <div className="learning-section__desc-list">
                          peserta mengikuti kelas live berupa webinar interaktif
                          yang berdurasi sekitar 3 jam
                        </div>
                        <li className="learning-section__title-list">
                          Post-Test
                        </li>
                        <div className="learning-section__desc-list">
                          peserta diminta mengisi post-test setelah setiap sesi
                          pembelajaran berakhir
                        </div>
                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="penugasan" title="Penugasan" className="mb-5">
                    <div id="learning-section__listing">
                      <ul>
                        <li className="learning-section__title-list">
                          Assignment
                        </li>
                        <div className="learning-section__desc-list">
                          di setiap akhir sesi, terdapat tugas yang harus
                          dikerjakan oleh peserta dan dikumpulkan (insert
                          deadline pengumpulan tugas)
                        </div>
                        <li className="learning-section__title-list">
                          Submission
                        </li>
                        <div className="learning-section__desc-list">
                          tugas dikumpulkan dalam format dokumen PDF di laman
                          yang telah disediakan
                        </div>
                        <li className="learning-section__title-list">
                          Discussion
                        </li>
                        <div className="learning-section__desc-list">
                          pembahasan tugas yang telah dikumpulkan akan dilakukan
                          di awal sesi berikutnya
                        </div>
                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="pasca" title="Pasca Program" className="mb-5">
                    <div id="learning-section__listing">
                      <ul>
                        <li className="learning-section__title-list">
                          Networking
                        </li>
                        <div className="learning-section__desc-list">
                          setelah rangkaian kegiatan berakhir, setiap peserta
                          memiliki kesempatan untuk berjejaring dengan alumni
                          bootcamp lainnya
                        </div>
                        <li className="learning-section__title-list">Reward</li>
                        <div className="learning-section__desc-list">
                          terdapat reward untuk peserta teraktif/terbaik, jadi,
                          tunjukkan performa terbaik Anda!s
                        </div>
                      </ul>
                    </div>
                  </Tab>
                </Tabs>
              </div>
              <div className="learning-section__main-title">
                Apa Saja yang akan Anda Pelajari?
              </div>
              <div className="learning-section__main-wrapper">
                <div className="learning-section__title-blue">
                  Materi Pelatihan
                </div>
                {learnBootcamp?.map((item, index) => {
                  return (
                    <Accordion
                      className="learning-section__accordion"
                      key={`${index}`}
                    >
                      <Accordion.Toggle
                        className="p-0 w-100"
                        as={Button}
                        variant="link"
                        onClick={() => handleShow(index)}
                        eventKey={`${index}`}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <div className="learning-section__accordion-text">
                            {item.title}
                          </div>
                          <div>
                            {idx.includes(index) ? (
                              <span className="icon-chevronup"></span>
                            ) : (
                              <span className="icon-chevrondown"></span>
                            )}
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${index}`}>
                        <div
                          className="w-100 mt-3"
                          style={{ backgroundColor: "#F8F6F6" }}
                        >
                          {item?.list?.map((e, i) => {
                            return (
                              <div
                                key={i}
                                className="learning-section__accordion-desc__list"
                              >
                                {e}
                              </div>
                            );
                          })}
                        </div>
                      </Accordion.Collapse>
                    </Accordion>
                  );
                })}
              </div>
              <div className="learning-section__main-title">
                Belajar Bersama Trainer Terpercaya di Bidangnya
              </div>
              <div className="learning-section__card-instructor">
                <a
                  href="https://www.linkedin.com/in/putiretnoali-agilist/"
                  target="_blank"
                >
                  <img className="lazyload" data-src={person} />
                  <div className="learning-section__card-instructor__detail">
                    <div>Puti Retno Ali</div>
                    <div>ICAgile - Certified Profesional Agile Coach</div>
                  </div>
                </a>
              </div>
            </section>
            <section id="metode">
              <div className="metode-section__title">Metode dan Biaya</div>
              <div className="metode-section__main-title">
                Jadi Ahli Dalam 2 Minggu
              </div>
              <div className="mb-5">
                {expertInAWeek(
                  "Critical Thinking",
                  "Kelas Mulai 29 Juli 2022 - 18.30 s/d 21.30 WIB"
                )}
                {expertInAWeek(
                  "Analytical Thinking",
                  "Kelas Mulai 5 Agustus 2022 - 18.30 s/d 21.30 WIB"
                )}
                {expertInAWeek(
                  "Problem Solving",
                  "Kelas Mulai 12 Agustus 2022 - 18.30 s/d 21.30 WIB"
                )}
                {expertInAWeek(
                  "Effective Decision Making",
                  "Kelas Mulai 19 Agustus 2022 - 18.30 s/d 21.30 WIB"
                )}
              </div>
              <div className="metode-section__main-title">
                Saya ingin mendaftar, bagaimana caranya?
              </div>
              <div className="position-relative">
                <Row className="metode-section__circle-wrapper">
                  <Col
                    xs={12}
                    md={4}
                    lg={4}
                    className="metode-section__cirlce-container"
                  >
                    <div className="metode-section_circle-yellow">1</div>
                    <div className="mb-4">
                      <div className="metode-section__circle-title">
                        Tentukan Program
                      </div>
                      <div className="metode-section__circle-sub">
                        Pilih program sesuai kebutuhan Anda atau perusahaan
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    lg={4}
                    className="metode-section__cirlce-container"
                  >
                    <div className="metode-section_circle-yellow">2</div>
                    <div className="mb-4">
                      <div className="metode-section__circle-title">
                        Registrasi
                      </div>
                      <div className="metode-section__circle-sub">
                        Lengkapi data pendaftaran agar langsung terintegrasi
                        dengan LMS yang telah kami siapkan
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    lg={4}
                    className="metode-section__cirlce-container"
                  >
                    <div className="metode-section_circle-yellow">3</div>
                    <div className="mb-4">
                      <div className="metode-section__circle-title">
                        Bayar dan Mulai Belajar
                      </div>
                      <div className="metode-section__circle-sub">
                        Lakukan pembayaran dan mulai belajar sesuai dengan
                        jadwal yang telah ditentukan
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="w-100 d-flex justify-content-center">
                  <div className="metode-section__line"></div>
                </div>
              </div>
              <div className="metode-section__main-title">Biaya Kelas</div>
              <div className="metode-section__wrapper-main">
                <div>
                  <div className="metode-section__wrapper-main__title">
                    Bootcamp Soft Skills
                  </div>
                  <div className="metode-section__wrapper-main__sub">
                    Daftar sekarang untuk ikuti bootcamp.
                  </div>
                  <div className="metode-section__wrapper-main__paragraph">
                    Tidak ada pungutan biaya untuk pelanggan satukelas.
                  </div>
                </div>
                <div>
                  <div className="metode-section__wrapper-main__price">
                    Rp1.200.000
                  </div>
                  {buttonCTA("full")}
                </div>
              </div>
            </section>
            <section id="faq">
              <div className="faq-section__title">
                Pertanyaan yang sering muncul terkait bootcamp satukelas
              </div>
              <div className="mb-5">
                {faqUser?.map((item, index) => {
                  return (
                    <Accordion
                      className="faq-section__accordion"
                      key={`${index}`}
                    >
                      <Accordion.Toggle
                        className="p-0 w-100"
                        as={Button}
                        variant="link"
                        onClick={() => handleShow(index)}
                        eventKey={`${index}`}
                      >
                        <div
                          style={{ textAlign: "initial" }}
                          className="d-flex w-100 justify-content-between"
                        >
                          <div className="faq-section__accordion-text">
                            {item.title}
                          </div>
                          <div>
                            {idx.includes(index) ? (
                              <span className="icon-chevronup"></span>
                            ) : (
                              //
                              <span className="icon-chevrondown"></span>
                            )}
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${index}`}>
                        <div className="w-100 mt-3">{item.desc}</div>
                      </Accordion.Collapse>
                    </Accordion>
                  );
                })}
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <div className="closing-section__bootcamp">
        <Container>
          <Row className="align-items-center">
            <Col>
              <div className="closing-section__bootcamp-title">
                Maksimalkan Perkembangan Karyawan Anda Sekarang!
              </div>
              <div className="closing-section__bootcamp-subtitle">
                Cari Tahu Lebih Lanjut Dengan Konstultasi Langsung.
              </div>
            </Col>
            <Col className="text-right">
              <Button
                href="https://wa.me/6282130070040"
                className="closing-section__bootcamp-button"
              >
                Konsultasi Sekarang
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Bootcamp;
