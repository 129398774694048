import { Col, Container, Image, Row } from "react-bootstrap";
import EnglishCourseCard from "../../components/MiniBrevet/EnglishCourseCard";
// import group1 from "./../../assets/images/english/group-1.webp";
// import group2 from "./../../assets/images/english/group-2.webp";
import { membershipIdEnglish, testimoniEnglish } from "../../helper/constant";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React, { useEffect, useState } from "react";
import Heroshot from "../../components/MiniBrevet/Heroshot";
import Benefit from "../../components/MiniBrevet/Benefit";
import { courseService } from "../../http/CourseLengthHttp";
import { AuthData } from "../../helper/auth";
import Loading from "../../components/Layout/Loading";
import Promo from "../../components/MiniBrevet/Promo";
import CourseList from "../../components/MiniBrevet/CourseList";
import PromoBottom from "../../components/MiniBrevet/PromoBottom";
import PromoMiddle from "../../components/MiniBrevet/PromoMiddle";
import { UserService } from "../../http/UserHttp";

export default function MiniBrevetPage() {
  const [loaded, setLoaded] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userMembership, setUserMembership] = useState(null);
  const [otherCourse, setOtherCourse] = useState();
  const isLogin = AuthData();

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    getOtherCourse();
    if (isLogin) {
      getMembershipUser();
    }
  }, []);

  useEffect(() => {
    if (userMembership?.length > 0) {
      // let isSubscribe = false;
      for (let i = 0; i < userMembership.length; i++) {
        if (userMembership[i]?.catalogue_id === membershipIdEnglish && userMembership[i]?.status === "active") {
          setIsSubscribe(true);
        }
      }
    }
  }, [userMembership]);

  const getMembershipUser = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      const response = await UserService.getMembership();
      setUserMembership(response.memberships);
    } catch (error) {}
  };

  function renderSplideTestimoni() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-4 mb-5" id="testimoni">
          <Splide
            options={{
              rewind: true,
              perPage: 1,
              perMove: 1,
              gap: "1.5rem",
              pagination: true,
              arrows: false,
            }}>
            {testimoniEnglish?.length > 0
              ? testimoniEnglish?.map((value, index) => (
                  <SplideSlide key={index}>
                    <div className="testimoni-container">
                      <div className="testimoni-profile">
                        {value.photo === "" ? (
                          <div className="img-testimoni d-flex align-items-center justify-content-center align-items-center">
                            <span className="icon-user"></span>
                          </div>
                        ) : (
                          <img alt="Profile User" className="lazyload" data-src={value.photo} />
                        )}
                      </div>
                      <div className="testimoni-content">
                        <div className="text">{value.testimoni}</div>
                        <div className="info">
                          <div className="name">
                            {value.name} - {value.company}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              : null}
          </Splide>
        </Col>
      </Row>
    );
  }

  function renderSplideOther() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-2 mb-2">
          <div id="explain" className="category-card overflow-visible ">
            <div>
              <Splide
                id="custom-height"
                options={{
                  rewind: true,
                  perPage: 4,
                  perMove: 1,
                  gap: "1rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}>
                {otherCourse &&
                  otherCourse.map((value, index) => {
                    return (
                      <SplideSlide key={index}>
                        <EnglishCourseCard item={value} type="other" />
                      </SplideSlide>
                    );
                  })}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  const getOtherCourse = async () => {
    setIsLoading(true);
    try {
      const response = await courseService.getCourse(`?size=8`);
      if (response) {
        setOtherCourse(response?.items);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Heroshot />
      <Container>
        <Benefit />
        <Promo isLogin={isLogin} />
        <CourseList isSubscribe={isSubscribe} setIsLoading={setIsLoading} typePage="minibrevet" />
      </Container>
      <PromoMiddle isLogin={isLogin} />
      <Container>
        <PromoBottom />
        <Row className="desc-wrap align-items-center">
          <Col lg={6}>
            <Image src="./../../assets/images/english/group-1.webp" alt="group1" />
          </Col>
          <Col lg={6}>
            <div className="desc-promo">Ini Title, butuh brief wording untuk melengkapi section ini</div>
            <div id="src" className="src-promo">
              Ini penjelasan dari title, butuh brief wording untuk melengkapi ini
            </div>
          </Col>
        </Row>
        <Row className="desc-wrap align-items-center">
          <Col lg={6} className="order-2 order-lg-1">
            <div className="desc-promo">Ini Title, butuh brief wording untuk melengkapi section ini</div>
          </Col>
          <Col lg={6} className="order-1 order-lg-2">
            <Image src="./../../assets/images/english/group-2.webp" alt="group2" />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col lg={11}>
            <div className="title-testimoni">
              <span>Ini Title</span> <span className="blue-text">Butuh Brief Wording</span>
            </div>
            <div>{loaded ? renderSplideTestimoni() : null}</div>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: `#F5F8FB` }}>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="other-course">Pelatihan Lainnya</div>
            </Col>
          </Row>
          {loaded ? renderSplideOther() : null}
        </Container>
      </div>
    </>
  );
}
