import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { startAssigmentThunk } from "../../redux/thunks/AssignmentThunks";
import { removeCourseDataId } from "../../redux/actions/CoursesActions";
import TodoList from "../../components/DashboardComponent/MyTask/ToDoList";
import InProgress from "../../components/DashboardComponent/MyTask/InProgress";
import Done from "../../components/DashboardComponent/MyTask/Done";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { UserService } from "../../http/UserHttp";
import Info from "../../components/common/Info";

const MySwal = withReactContent(Swal);

function MyTask() {
  const history = useHistory();
  const [detailModal, setDetailModal] = useState(false);
  const dispatch = useDispatch();
  const assignments = useSelector((state) => state.assignments);
  const [trigger, setTrigger] = useState(true);
  const [triggerRedux, setTriggerRedux] = useState(false);
  const [myTaskNew, setMyTaskNew] = useState({
    todo: [],
    ongoing: [],
    completed: [],
  });

  useEffect(() => {
    AuthIsLogin(history);
    dispatch(removeCourseDataId());
  }, []);

  useEffect(() => {
    if (triggerRedux && assignments.assignmentDataStart === "success") {
      setTriggerRedux(false);
      setTrigger(true);
    }
  }, [assignments]);

  useEffect(() => {
    getMyTask();
  }, []);

  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };

  // get task from API
  const getMyTask = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getTask();
      const taskList = response.my_tasks;
      const taskGrouped = { todo: [], ongoing: [], completed: [] };

      if (taskList && trigger) {
        taskList.forEach((e) => {
          if (e.status === "todo") {
            taskGrouped.todo.push(e);
          }
          if (e.status === "ongoing") {
            taskGrouped.ongoing.push(e);
          }
          if (e.status === "completed") {
            taskGrouped.completed.push(e);
          }
        });

        setTrigger(false);
        setMyTaskNew(taskGrouped);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  // handle change progress from todo to on progress
  function changeProgress(title, id) {
    displayPopup(
      true,
      `Ubah status tugas ${title} menjadi 'Sedang Dilakukan'?`,
      true,
      id
    );
  }

  // handle display popup
  function displayPopup(cancel, title, confirm, id) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      cancelButtonText: "Batal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: "Oke",
    }).then((value) => {
      if (value.isConfirmed) {
        const payload = {
          assignment_id: id,
          user_id: AuthData().userId,
        };
        setTriggerRedux(true);
        dispatch(startAssigmentThunk(payload));
        getMyTask();
      }
    });
  }

  return (
    <>
      {/* <Info
        detailModal={detailModal}
        setDetailModal={setDetailModal}
        seeMoreInformation={seeMoreInformation}
      /> */}
      <Container className="dashboard_page__container">
        <BreadcrumbPage title="Tugas Saya" />
        <h2 className="h2 dashboard__section_title mb-3">Tugas Saya</h2>
        <Row className="height_task">
          <Col lg={6} xl={4} md={6} className=" mb-2 mt-2">
            <TodoList data={myTaskNew.todo} changeProgress={changeProgress} />
          </Col>
          <Col lg={6} xl={4} md={6} className="mb-2 mt-2">
            <InProgress data={myTaskNew.ongoing} />
          </Col>
          <Col lg={6} xl={4} md={6} className="mb-2 mt-2">
            <Done data={myTaskNew.completed} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyTask;
