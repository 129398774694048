import React from "react";
import { Row, Col, Container} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/Account/index.css";
import { blog_detail } from "../../helper/constant";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

function BlogList() {
  return (
    <Container className="my-5">
      <Row>
        <Col lg={8}>
          <Link to={`/blog/${blog_detail[0].slug}/${blog_detail[0].id}`}>
            <div className="blog_container__headline">
              <div className="blog_container__thumbnail">
                <img
                  alt="Hero Thumbnail"
                  data-src={blog_detail[0].thumbnail}
                  className="w-100 mb-3 lazyload"
                />
                <div className="blog_container__title">
                  {blog_detail[0].title}
                </div>
              </div>
              <div className="blog_container__subtitle">
                Publikasi : {blog_detail[0].date} | Penulis :{" "}
                {blog_detail[0].writer}
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={4}>
          <div className="blog_container__more">
            <div className="blog_title__more">Artikel Lainnya</div>
            {blog_detail.map((item, index) => {
              return item[0] ? null : (
                <div key={index} className="blog_container__more_article">
                  <Link
                    className="more_article__list "
                    to={`/blog/${item.slug}/${item.id}`}
                  >
                    <div className="blog_container__img">
                      {" "}
                      <img
                        width="110"
                        alt="Thumbnail Blog"
                        data-src={item.thumbnail}
                        className="blog_image__size lazyload"
                      />
                    </div>
                    <div className="blog_container__title_more">
                      {item.title}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default BlogList;
