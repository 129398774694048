import React from "react";
import { Breadcrumb } from "react-bootstrap";

function BreadcrumbPageNonDashboard({
  type,
  title,
  course_id,
  slugify,
  lastText,
}) {
  const checkBreadcrumb = (type) => {
    return type !== "Webinar" && type !== "Konsultasi"
      ? `/course/${slugify(title)}/${course_id}`
      : null;
  };
  return (
    <Breadcrumb className="pb-0 pt-4 mb-0">
      <Breadcrumb.Item href="/">Beranda</Breadcrumb.Item>
      <Breadcrumb.Item
        href={`${
          type !== "Webinar" && type !== "Konsultasi"
            ? `/search/${type?.toLowerCase()}/`
            : `/webinar-list/${type?.toLowerCase()}/`
        }`}
      >
        {type}
      </Breadcrumb.Item>
      <Breadcrumb.Item href={`${checkBreadcrumb(type)}`}>
        {title}
      </Breadcrumb.Item>
      <Breadcrumb.Item active href={`${checkBreadcrumb(type)}`}>
        {lastText}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}
export default BreadcrumbPageNonDashboard;
