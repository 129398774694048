import React, { useEffect, useState } from "react";
import { MembershipService } from "../../http/MembershipHttp";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingStart,
  loadingFinish,
} from "./../../redux/reducers/CommonReducers";
import { displayPopup } from "../../helper/functions";
import { paymentsMethod } from "../../helper/payments";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { postPaymentsVerifyDataThunk } from "../../redux/thunks/PaymentsThunks";
import { removePostPaymentsData } from "../../redux/actions/PaymentsActions";
import { AuthData } from "../../helper/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Topup from "../Topup";
import ModalPembayaran from "../ModalPembayaran";
import { UserService } from "../../http/UserHttp";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);

function CTAButtonMembership({ item, userMembership, getMembership }) {
  const [showTopup, setShowTopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [modalContent, setModalContent] = useState({
    show: false,
    componentBody: "",
  });
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();
  const usersDataId = useSelector((state) => state.users.usersDataId);
  const payments = useSelector((state) => state.payments);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      show: false,
    });
    if (payments.paymentsVerifyDataPost) {
      if (payments.paymentsVerifyDataPost === "fail" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi kesalahan, coba lagi", true, "Oke");
        dispatch(removePostPaymentsData());
      } else if (trigger) {
        setTrigger(false);
        dispatch(removePostPaymentsData());
        displayPopup(
          false,
          `Status Transaksi Anda : ${payments.paymentsVerifyDataPost?.transaction_status}`,
          true,
          "Oke"
        );
      }
    }
  }, [payments.paymentsVerifyDataPost]);

  useEffect(() => {
    if (payments.paymentsDataPost?.order_id) {
      setModalContent({
        ...modalContent,
        show: true,
        body: payments.paymentsDataPost,
      });
    }
    if (payments.paymentsDataPost === "fail") {
      displayPopup(
        false,
        `Gagal Topup. Silahkan coba lagi setelah beberapa saat!`,
        true,
        "Oke"
      );
    }
  }, [payments.paymentsDataPost]);

  /**
   * @function to check if a user has certain active membership
   * @param {number} catalogue_id
   * @returns boolean
   */
  const isUserHasMembership = (catalogue_id) => {
    let result = false;
    if (userMembership?.length > 0) {
      result = userMembership.find((e) => {
        return e.catalogue_id === catalogue_id && e.status === "active";
      });
    }
    return result;
  };

  /**
   * @function to check if a user can buy free membership
   * @param {Array} userMembership
   * @returns boolean
   */
  const canUserBuyFreeMembership = (userMembership) => {
    if (userMembership?.length > 0) return false;
    return true;
  };
  function handleCopy() {
    let copyText = document.getElementById("va-number");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert(copyText.value);
  }
  /**
   * @function to handle click to buy membership
   * @param {string} catalogue
   * @returns nothing
   */
  const handleBuyMembership = (catalogue) => {
    let dateNow = new Date();

    let payload = {
      bill_amount: catalogue.price,
      catalogue_id: catalogue.catalogue_id,
      expired_at: moment(dateNow)
        .add(catalogue.duration, "days")
        .format("YYYY-MM-DD HH:MM:ss")
        .toString(),
      user_id: usersDataId.user_id,
    };

    let html = `
    <div> Harga: ${payload.bill_amount}</div>
    <div> Expired: ${payload.expired_at}</div>
    `;
    MySwal.fire({
      title: "Beli membership?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: true,
      confirmButtonText: "OK",
      html: html,
    }).then((result) => {
      if (result.isConfirmed) {
        addMembershipToUser(payload);
      }
    });
  };

  /**
   * @function to add membership to a user with API
   * @param {Object} payload
   * @returns nothing
   */
  const addMembershipToUser = async (payload) => {
    dispatch(loadingStart());
    try {
      const response = await MembershipService.addMembershipToUser(payload);
      dispatch(getUsersDataThunk(AuthData().userId));
      getMembership();
      displayPopup(false, "Berhasil membeli membership.", true, "OK");
    } catch (error) {
      console.log(error);
      displayPopup(false, "Terjadi kesalahan.", true, "OK");
    }
    dispatch(loadingFinish());
  };

  const handleTopup = () => {
    setShowTopup(true);
  };
  const handleVerify = (value) => {
    setTrigger(true);
    const dataSend = {
      order_id: value,
    };
    dispatch(postPaymentsVerifyDataThunk(dataSend));
  };

  return (
    <>
      {/* CTA membership */}
      {AuthData().userId ? (
        <>
          {/* check if current membership is free, and if user eligible to buy */}
          {item.price === 0 ? (
            <>
              {canUserBuyFreeMembership(userMembership) && (
                <div
                  className="btn btn-primary"
                  onClick={() => handleBuyMembership(item)}
                >
                  Klaim!
                </div>
              )}
            </>
          ) : (
            <>
              {/* current item is not free membership... */}

              {/* check if current user has bought this membership */}
              {isUserHasMembership(item.catalogue_id) ? (
                <div className="btn btn-outline-primary disabled">
                  Sudah aktif
                </div>
              ) : (
                <>
                  {/* check if current saldo less than membership price */}
                  {usersDataId.balance < item.price ? (
                    <div
                      onClick={handleTopup}
                      className="btn btn-outline-danger"
                    >
                      Isi Saldo
                    </div>
                  ) : (
                    <div
                      className="btn btn-primary"
                      onClick={() => handleBuyMembership(item)}
                    >
                      Beli
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Link to="/login">
          <div className="btn btn-primary">Login</div>
        </Link>
      )}
      <Modal show={showTopup} onClose={() => setShowTopup(false)} centered>
        <Topup setTopup={setShowTopup} />
      </Modal>
      <ModalPembayaran
        id="tp"
        content={modalContent}
        handleVerify={handleVerify}
        paymentsMethod={paymentsMethod}
        handleCopy={handleCopy}
      />
    </>
  );
}
export default CTAButtonMembership;
