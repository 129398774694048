import { Col, Container, Row } from "react-bootstrap";
import { testiomial } from "./Data";
import { useEffect, useState } from "react";
import React from "react";

function Testimonial({ type }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function renderTestimonial() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Splide
        options={{
          rewind: true,
          perPage: 1,
          perMove: 1,
          gap: "1.5rem",
          pagination: true,
          arrows: false,
          breakpoints: {
            992: {
              perPage: 1,
            },
            768: {
              perPage: 1,
            },
            500: {
              perPage: 1,
            },
          },
        }}>
        {testiomial?.map((value, index) => (
          <SplideSlide key={index}>
            <div className="wrap__testi p-3 h-100 d-flex gap-3 justify-content-between">
              <div className="d-flex align-items-center">
                <img src={value.image} className="img-fluid" alt="" width={300} height={"100%"} style={{ objectFit: "contain" }} />
              </div>
              <div className="d-flex align-items-center flex-column text-justify justify-content-between" style={{ marginLeft: "5rem" }}>
                <div>
                  <em>{value.description}</em>
                </div>
                <div className="d-flex w-100">
                  <p className="mb-0 fw-700">{value.name}</p>
                  <span className="mx-2 fw-700 fs-3">-</span>
                  <p className="mb-0 fw-700">{value.role}</p>
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    );
  }

  return (
    <section className="my-5 position-relative">
      <Container className="ml-xl-5">
        <Col xl={8} className="mt-4" id="testimoni">
          <h4 className="fw-700">Testimoni</h4>
          {loaded ? <>{renderTestimonial()}</> : null}
        </Col>
      </Container>
    </section>
  );
}
export default Testimonial;
