import { Col, Image, Row } from "react-bootstrap";
import country from './../../assets/images/english/country.png';
import React from "react";

export default function PromoBottom() {
    return (
        <Row className="promo-wrap">
            <Col lg={6}>
                <div className="title-promo">
                    <span>Masih Ragu?</span> Ini Alasan-Alasan Mengapa Mahir Bahasa Inggris Itu Penting!
                </div>
                <div className="dev-promo">
                    Lebih dari <span>55%</span> konten di internet berbahasa Inggris. Konten yang berbahasa Indonesia hanya ada sekitar <span>0.7%</span>
                </div>
                <div className="src-promo">
                    *Sumber: www.statista.com
                </div>
            </Col>
            <Col lg={6}>
                <Image src={country} alt="country" />
            </Col>
        </Row>
    )
}