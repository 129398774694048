import { sha256 } from "js-sha256";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Carousel,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../assets/css/Layout/header.css";
import light from "../../assets/images/light.svg";
import googleSign from "../../assets/images/sign-google.png";
import imageLogin from "../../assets/images/login/carousel-login.png";
import { Clone } from "../../helper/functions";
import { AuthService } from "../../http/AuthHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { SkValidate } from "../common/SkValidate";
const MySwal = withReactContent(Swal);

const ModalAuth = (props) => {
  const { closeModal, onSubmitHandler, data, onChangeHandler } = props;

  const [show, setShow] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showAuthForm, setShowAuthForm] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showRegister, setShowRegister] = useState(true);
  const [showConfirmRe, setShowConfirmRe] = useState(true);
  const [forgotPassword, setForgotPassword] = useState({
    email: "",
  });
  const [dataRegis, setDataRegis] = useState({
    email: "",
    name: "",
    password: "",
    repassword: "",
    terms: false,
    sex: "laki-laki",
  });

  const handleShowPassword = () => {
    setShow(!show);
    if (show) {
      document.getElementById("password").type = "text";
    } else {
      document.getElementById("password").type = "password";
    }
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const openForgotPasswordForm = (e) => {
    e.preventDefault();
    setShowForgotPasswordForm(true); // Langkah 2
    setShowLoginForm(false);
  };

  const openLoginForm = (e) => {
    e.preventDefault();
    setShowForgotPasswordForm(false); // Langkah 2
    setShowLoginForm(true);
  };
  const openAuthForm = (e) => {
    e.preventDefault();
    setShowAuthForm(true);
    setShowRegisterForm(false);
  };
  const openRegisterForm = (e) => {
    e.preventDefault();
    setShowRegisterForm(true);
    setShowAuthForm(false);
  };

  const onSubmitHandlerForgot = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraintsForgot);
    if (isValid) {
      let param = `?email=${forgotPassword?.email}`;
      try {
        const response = await AuthService.getResetPassword(param);
        if (response) {
          displayPopup(
            false,
            `Berhasil mengirimkan konfirmasi ke email`,
            true,
            "Oke"
          );
        }
      } catch (error) {
        displayPopup(false, `Gagal mengirim email`, true, "Oke");
      }
    }
    dispatch(loadingFinish());
  };

  const onChangeHandlerForgot = (e) => {
    const newData = { ...forgotPassword };
    newData["email"] = e.target.value;
    setForgotPassword(newData);
  };

  const constraintsForgot = {
    email: {
      presence: true,
      email: true,
    },
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }

  const onSubmitHandlerRegister = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraintsRegister);

    if (isValid) {
      let body = Clone(dataRegis);
      delete body.terms;
      delete body.repassword;
      body.password = sha256(dataRegis.password);
      body.role = "student";
      try {
        const response = await AuthService.postRegister(body);
        if (response) {
          displayPopupRegister(false, "Registrasi Berhasil!", "Oke", true);
        }
      } catch (error) {
        displayPopupRegister(
          false,
          error
            ? error?.data?.includes("already taken")
              ? "Email sudah terpakai"
              : error?.data
            : "Registrasi Gagal!",
          "Oke"
        );
      }
    }
    dispatch(loadingFinish());
  };
  function displayPopupRegister(cancel, title, confirm, success) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (success) history.push("/check-email/");
    });
  }

  const handleChangeRegister = (e) => {
    const newData = { ...dataRegis };
    newData[e.target.id] = e.target.value;
    console.log(newData);
    setDataRegis(newData);
  };
  const handleShowPasswordRegister = (value) => {
    if (value === "1") {
      setShowRegister(!showRegister);
      showRegister
        ? (document.querySelector(".passwordRegister").type = "text")
        : (document.querySelector(".passwordRegister").type = "password");
    } else {
      setShowConfirmRe(!showConfirmRe);
      showConfirmRe
        ? (document.querySelector(".repasswordRegister").type = "text")
        : (document.querySelector(".repasswordRegister").type = "password");
    }
  };

  const constraintsRegister = {
    email: {
      presence: {
        message: "harus terisi dengan alamat email yang benar",
      },
      email: true,
    },

    kata_sandi: {
      presence: {
        message: "tidak boleh kosong",
      },
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
    konfirmasi_kata_sandi: {
      presence: {
        message: "harus sesuai dengan kata sandi",
      },
      length: {
        minimum: 6,
        maximum: 100,
      },
      equality: "kata_sandi",
    },
    bagian: {
      presence: {
        message: " ini wajib terisi",
      },
    },
  };
  return (
    <div className="modal d-block login_popup_modal">
      <Container className="modal-content modal__auth">
        <Row
          style={{
            paddingLeft: "unset",
            paddingRight: "unset",
            height: "100%",
            overflowX: "hidden",
          }}
        >
          {showAuthForm && (
            <div className="d-flex flex-wrap flex-lg-row bd-highlight w-100">
              <div
                className="col-12 col-lg-7 order-2 order-lg-0"
                style={{
                  paddingLeft: "unset",
                  paddingRight: "unset",
                  height: "100%",
                }}
              >
                <Carousel
                  controls={false}
                  indicators={true}
                  style={{ height: "100%" }}
                >
                  <Carousel.Item interval={1000} style={{ height: "100%" }}>
                    <Link to="/english-program">
                      <Image
                        src={imageLogin}
                        alt="First slide"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Link>
                  </Carousel.Item>
                </Carousel>
              </div>
              {showLoginForm && (
                <div className="order-1 order-lg-0 position-relative col-12 col-lg-5">
                  <span
                    onClick={closeModal}
                    className="btn_close-modal"
                    style={{ cursor: "pointer" }}
                  >
                    &#10005;
                  </span>
                  <h3 className="header_modal_title text-center justify-content-center">
                    Masuk
                  </h3>
                  <div className="mb-3">
                    <Form onSubmit={onSubmitHandler} className="login-form">
                      <div className="form-group">
                        <label htmlFor="username" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          id="username"
                          name="email"
                          value={data.username}
                          onChange={onChangeHandler}
                          className="form-input w-100"
                          placeholder="Masukan Email Anda"
                        />
                        <div className="messages"></div>
                      </div>
                      <div className="form-group position-relative">
                        <label htmlFor="password" className="form-label">
                          Kata Sandi
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          value={data.password}
                          onChange={onChangeHandler}
                          className="form-input w-100"
                          placeholder="Masukan Kata Sandi Anda"
                        />
                        {show ? (
                          <span
                            onClick={handleShowPassword}
                            className="icon-eye position_eye_login"
                          ></span>
                        ) : (
                          <span
                            onClick={handleShowPassword}
                            className="icon-eye-coret position_eye_login"
                          ></span>
                        )}
                        <div className="messages"></div>
                      </div>
                      <div className="form-group_checkbox">
                        <input
                          type="checkbox"
                          id="rememberMe"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                          className="form-checkbox"
                        />
                        <label
                          htmlFor="rememberMe"
                          className="form-checkbox-label_remember"
                        >
                          Ingat saya
                        </label>
                      </div>
                      <Button
                        type="submit"
                        className="form-submit-button w-100"
                      >
                        Masuk
                      </Button>
                    </Form>
                    <div className="d-flex flex-column mt-3">
                      <Link
                        className="mb-2 link_reset_password"
                        to="/forgot-password"
                        onClick={openForgotPasswordForm}
                      >
                        Lupa Kata Sandi?
                      </Link>
                      <span>
                        Belum Berlangganan?{" "}
                        <span>
                          <Link
                            className="link_register"
                            to="/register"
                            onClick={openRegisterForm}
                          >
                            Daftar Sekarang
                          </Link>
                        </span>
                      </span>
                    </div>
                  </div>
                  <p className="mb-0 text-center text_footer_popup">
                    Satukelas by <br /> PT. Satukelas Adhyapana Nusantara. All
                    Rights Reserved
                  </p>
                </div>
              )}
              {showForgotPasswordForm && (
                <Col
                  lg={5}
                  className="order-1 position-relative d-flex justify-content-between flex-column pb-3"
                >
                  <span
                    onClick={closeModal}
                    className="btn_close-modal"
                    style={{ cursor: "pointer" }}
                  >
                    &#10005;
                  </span>
                  <h3 className="header_modal_title text-center justify-content-center">
                    Lupa Kata Sandi
                  </h3>
                  <div className="mb-3">
                    <Form
                      onSubmit={onSubmitHandlerForgot}
                      className="login-form"
                    >
                      <div className="form-group">
                        <label htmlFor="username" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          id="usernameForgot"
                          name="email"
                          value={forgotPassword.email}
                          onChange={onChangeHandlerForgot}
                          className="form-input w-100"
                          placeholder="Masukan Email Anda"
                        />
                        <div className="messages"></div>
                      </div>
                      <Button
                        type="submit"
                        className="form-submit-button w-100 my-3"
                      >
                        Kirim
                      </Button>
                    </Form>
                    <div className="d-flex flex-column mt-3">
                      <Link
                        className="mb-2"
                        to="/forgot-password"
                        onClick={openLoginForm}
                      >
                        <a className="link_reset_password">
                          Masuk ke akun anda
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <span>
                      Belum Berlangganan?{" "}
                      <span>
                        <Link
                          className="link_register"
                          to="/register"
                          onClick={openRegisterForm}
                        >
                          Daftar Sekarang
                        </Link>
                      </span>
                    </span>
                    <p className="mb-0 text-center text_footer_popup">
                      Satukelas by <br /> PT. Satukelas Adhyapana Nusantara. All
                      Rights Reserved
                    </p>
                  </div>
                </Col>
              )}
            </div>
          )}
          {showRegisterForm && (
            <Container className="container__register">
              <span
                onClick={closeModal}
                className="btn_close-modal"
                style={{ cursor: "pointer" }}
              >
                &#10005;
              </span>
              <Form onSubmit={onSubmitHandlerRegister}>
                <h1 className="wording__title_register">Buat Akun Anda</h1>
                <div>
                  <Col lg={12}>
                    <div className="login-label">Nama</div>
                  </Col>
                  <div className="d-flex align-items-center">
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="sex"
                          id="sex"
                          value={dataRegis.sex}
                          onChange={handleChangeRegister}
                        >
                          <option value="laki-laki" key="1">
                            Tn.
                          </option>
                          <option value="perempuan" key="2">
                            Ny.
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={9}>
                      <Form.Group>
                        <Form.Control
                          aria-describedby="basic-addon1"
                          className="form-name"
                          name="name_user"
                          id="name"
                          placeholder="Siapa nama anda?"
                          value={dataRegis.name}
                          onChange={handleChangeRegister}
                        />
                      </Form.Group>
                    </Col>
                  </div>
                  <Col lg={12}>
                    <Form.Group>
                      <div className="login-label">Email</div>
                      <Form.Control
                        className="mb-3 input100"
                        type="text"
                        placeholder="Masukkan Email Anda"
                        id="email"
                        name="email"
                        value={dataRegis.email}
                        onChange={handleChangeRegister}
                      />
                      <div className="messages"></div>
                    </Form.Group>
                    <Form.Group>
                      <div className="login-label">Kata Sandi</div>
                      <div className="position-absolute p-3 d-flex">
                        {/* <FaLock />{" "} */}
                      </div>
                      <div className="password_input">
                        <Form.Control
                          className="mb-3 input100 passwordRegister"
                          type="password"
                          placeholder="Masukkan Kata Sandi Anda"
                          id="password"
                          name="kata_sandi"
                          value={dataRegis.password}
                          onChange={handleChangeRegister}
                        />
                        {showRegister ? (
                          <span
                            onClick={() => {
                              handleShowPasswordRegister("1");
                            }}
                            className="icon-eye position_eye"
                          ></span>
                        ) : (
                          <span
                            onClick={() => {
                              handleShowPasswordRegister("1");
                            }}
                            className="icon-eye-coret position_eye"
                          ></span>
                        )}
                      </div>
                      <div className="messages"></div>
                    </Form.Group>
                    <Form.Group>
                      <div className="login-label">Konfirmasi Kata Sandi</div>
                      <div className="position-absolute p-3">
                        {" "}
                        {/* <FaLock /> */}
                      </div>
                      <div className="password_input">
                        <Form.Control
                          className="mb-3 input100 repasswordRegister"
                          type="password"
                          placeholder="Konfirmasi Kata Sandi"
                          id="repassword"
                          name="konfirmasi_kata_sandi"
                          value={dataRegis.repassword}
                          onChange={handleChangeRegister}
                        />
                        {showConfirmRe ? (
                          <span
                            onClick={() => {
                              handleShowPasswordRegister("2");
                            }}
                            className="icon-eye position_eye"
                          ></span>
                        ) : (
                          <span
                            onClick={() => {
                              handleShowPasswordRegister("2");
                            }}
                            className="icon-eye-coret position_eye"
                          ></span>
                        )}
                      </div>
                      <div className="messages"></div>
                    </Form.Group>
                    <Form.Group>
                      <div className="d-flex">
                        <Form.Check
                          type="checkbox"
                          id="terms"
                          name="bagian"
                          value={dataRegis.terms}
                          onChange={handleChangeRegister}
                        />
                        <label htmlFor="terms">
                          Saya setuju dengan
                          <a
                            href="/term"
                            target="_blank"
                            className="text-decoration-none login-register-text__span"
                          >
                            {" "}
                            peraturan dan ketentuan
                          </a>{" "}
                          yang berlaku
                        </label>
                      </div>
                      <div className="messages"></div>
                    </Form.Group>
                  </Col>
                  <Col className="mb-4">
                    <Alert variant="warning" className="register-alert__msg">
                      <span className="mr-2">
                        <Image src={light} width={18} height={18} />
                      </span>
                      Kami membutuhkan info dari anda untuk mengidentifikasi
                      pengguna dan memberikan akses ke layanan kami dan demi
                      menjaga keamanan. Informasi tambahan juga membantu kami
                      dalam menyediakan experience yang disesuaikan untuk
                      pengguna
                    </Alert>
                  </Col>
                </div>
                <div className="register_button__container w-100 mb-3">
                  <Button
                    type="submit"
                    className="register-next__button position_text__button w-100"
                  >
                    Selanjutnya
                  </Button>
                </div>
                {/* <div className="w-100 text-center py-4">ATAU</div>
                <div className="register_button__container w-100 mb-4">
                  <Button type="submit" variant="outline-primary" className="register-next__button position_text__button w-100">
                    <Image src={googleSign} width={18} height={18} className="mr-3" />
                    Sign In with Google
                  </Button>
                </div> */}
                <span>
                  Sudah punya akun?{" "}
                  <span>
                    <Link
                      className="link_register"
                      to="/register"
                      onClick={openAuthForm}
                    >
                      Masuk Sekarang
                    </Link>
                  </span>
                </span>
              </Form>
            </Container>
          )}
        </Row>
      </Container>
    </div>
  );
};

ModalAuth.propTypes = {};

export default ModalAuth;
