import React from "react";
import { Button } from "react-bootstrap";

export default function LoginCTA(props) {
  const { openModal } = props;
  return (
    <>
      <div className="mr-3">
        <a
          href={`https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas`}
          className="btn btn-primary call_sales_btn-header"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hubungi Sales
        </a>
      </div>
      <div>
        <Button className="btn btn_login_header" onClick={openModal}>
          Masuk
        </Button>
      </div>
    </>
  );
}
