import React from "react";
import { Carousel } from "react-bootstrap";
import { staticList, staticListReverse } from "./static-data";

const BannerLp = (props) => {
  const { lpType } = props;
  return (
    <div>
      {lpType === "cms" ? (
        <Carousel controls={false} indicators={false} className="position-relative wrapp__banner-katalog-cdlc">
          <Carousel.Item interval={4000} className="hero__banner-lp-reverse-cdlc">
            <div className="px-xl-5" style={{ marginBottom: "210px" }}>
              <div className="col-12">
                <span className="wording__subtitle-banner-lp-reverse-cdlc">Content Management System</span>
                <h2 className="wording__title-banner-lp-reverse-cdlc">Platform personal yang sesuai dengan kebutuhan organisasi</h2>
                <div className="row">
                  <div className="col-6 d-none d-lg-block"></div>
                  <div className="col-12 col-lg-6 content__banner-lp-reverse-cdlc">
                    {staticListReverse.map((item, index) => (
                      <div key={index}>
                        <h6 className="subheading__banner-lp-reverse">{item.title}</h6>
                        <ul>
                          {item.listItem.map((list, index) => (
                            <li key={index} className="list__banner-lp-reverse">
                              {list.list}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      ) : (
        <Carousel controls={false} indicators={false} className="position-relative wrapp__banner-katalog-cdlc">
          <Carousel.Item interval={4000} className="hero__banner-lp-cdlc">
            <div className="px-xl-5 my-5">
              <div className="col-12">
                <span className="wording__subtitle-banner-lp-cdlc">Learning Platform</span>
                <h2 className="wording__title-banner-lp-cdlc">Kebutuhan belajar, berlatih, dan evaluasi dalam satu klik</h2>
                <div className="col-12 col-lg-6 content__banner-lp-cdlc">
                  {staticList.map((item, index) => (
                    <div key={index}>
                      <h6 className="subheading__banner-lp">{item.title}</h6>
                      <ul>
                        {item.listItem.map((list, index) => (
                          <li key={index} className="list__banner-lp">
                            {list.list}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      )}
    </div>
  );
};

export default BannerLp;
