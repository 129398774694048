import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Card } from "react-bootstrap";
import { Clone } from "../../helper/functions";
import "../../assets/css/Account/index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SkValidate } from "../../components/common/SkValidate";
import RegistStep from "../../components/Register/RegistStep";
import { AuthIsNotLogin } from "../../helper/auth";
import { loadingFinish, loadingStart } from "../../redux/reducers/CommonReducers";
import { AuthService } from "../../http/AuthHttp";
const MySwal = withReactContent(Swal);
const sha256 = require("js-sha256");

function Register() {
  const [data, setData] = useState({
    company: "",
    email: "",
    name: "",
    password: "",
    repassword: "",
    username: "",
    date: "",
    terms: false,
    address_street: "",
    province: "",
    city: "",
    subdistrict: "",
    job: "",
    education_level: "",
    division: "",
    university: "",
    major: "",
    sex: "laki-laki",
    position: "",
    favorite_topics: [],
    birthday: "",
    is_bnsp: 0,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    AuthIsNotLogin(history);
  }, []);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  const onChangeHandler = (e) => {
    const newData = { ...data };
    const id = e.target.id;
    if (id === "terms") {
      newData[id] = !data.terms;
    } else if (id === "is_bnsp") {
      newData[id] = 1;
    } else newData[id] = e.target.value;
    if (id === "day") {
    }
    setData(newData);
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let body = Clone(data);
      delete body.terms;
      delete body.repassword;
      body.password = sha256(data.password);
      body.role = "student";
      try {
        const response = await AuthService.postRegister(body);
        if (response) {
          displayPopup(false, "Registrasi Berhasil!", "Oke", true);
        }
      } catch (error) {
        displayPopup(false, error ? (error?.data?.includes("already taken") ? "Email sudah terpakai" : error?.data) : "Registrasi Gagal!", "Oke");
      }
    }
    dispatch(loadingFinish());
  };
  function displayPopup(cancel, title, confirm, success) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (success) history.push("/check-email/");
    });
  }

  const constraints = {
    email: {
      presence: {
        message: "harus terisi dengan alamat email yang benar",
      },
      email: true,
    },

    kata_sandi: {
      presence: {
        message: "tidak boleh kosong",
      },
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
    konfirmasi_kata_sandi: {
      presence: {
        message: "harus sesuai dengan",
      },
      length: {
        minimum: 6,
        maximum: 100,
      },
      equality: "kata_sandi",
    },
    bagian: {
      presence: {
        message: " ini wajib terisi",
      },
    },
  };

  // const SignWithGoogle = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     try {
  //       const response = await axios.get(
  //         "https://www.googleapis.com/oauth2/v3/userinfo",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${tokenResponse?.access_token}`,
  //           },
  //         }
  //       );
  //       const data = response?.data;
  //       const payload = {
  //         email: data?.email,
  //         fullname: data?.name,
  //         role: "Pekarya",
  //         google_id: data?.sub,
  //       };
  //       setIsLoading(true);
  //       handleSignWithGoogle(payload);
  //     } catch (err) {
  //       setIsLoading(false);
  //       displayPopup(
  //         "error",
  //         false,
  //         "Terjadi Kesalahan",
  //         "Email atau password salah",
  //         true,
  //         "OK"
  //       );
  //       console.log(err);
  //     }
  //   },
  // });

  // const handleSignWithGoogle = async (payload) => {
  //   try {
  //     const response = await LoginService.postSignWithGoogle(payload);
  //     if (response !== undefined) {
  //       setIsLoading(false);
  //       //   console.log("response", response);
  //       let uid = response?.data?.id;
  //       let token = response?.token;
  //       //   console.log(uid, token);
  //       authService.setLogin(uid, token);
  //       let splitUrl = router.asPath?.split("?");
  //       if (splitUrl[1]?.includes("targetUrl")) {
  //         router.push(`${splitUrl[1]?.split("=")[1]}`);
  //       } else {
  //         router.push("/dashboard/overview");
  //       }
  //     }
  //   } catch (err) {
  //     setIsLoading(false);
  //     displayPopup(
  //       "error",
  //       false,
  //       "Terjadi Kesalahan",
  //       "Email atau password salah",
  //       true,
  //       "OK"
  //     );
  //     console.log(err);
  //   }
  // };

  return (
    <Container className="register-inherit">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={9} lg={6} xl={6} className="wrapper-register">
          <Card className="card-padding__register">
            <Form onSubmit={onSubmitHandler}>
              <div className="mb-5  d-flex justify-content-center flex-column padding_regist" id="step_8">
                <RegistStep email={data.email} name={data.name} sex={data.sex} handleChange={handleChange} password={data.password} repassword={data.repassword} terms={data.terms} onChangeHandler={onChangeHandler} />
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
