import React from "react";
import { Col, Row } from "react-bootstrap";
export default function BenefitHome() {
  const benefit = [
    {
      title: "Siap Pakai",
      desc: "Termasuk content library dan live sessions yang tersedia di <a href='#'>satukelas.com</a>",
    },
    {
      title: "Mudah Digunakan",
      desc: "Tak perlu pusing maintenance dan biaya hosting.",
    },
    {
      title: "Atur & Pantau Pelatihan",
      desc: "Tambah atau kurangi pelatihan sesuai kebutuhan Anda",
    },
    {
      title: "Layaknya Milik Sendiri",
      desc: "Kelola pengguna dan konten belajar dengan tampilan warna dan logo yang sesuai kemauan Anda.",
    },
  ];
  return (
    <Row className="justify-content-center w-100 gap-3 flex-xl-nowrap" style={{ gap: "1rem" }}>
      {benefit.map((item, idx) => {
        return (
          <div className="wrapp__benefit-home" key={idx}>
            <div className="p-3">
              <div className="text text-center">
                <div className="title__benefit-home">{item.title}</div>
                <div className="desc__benefit-home" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
              </div>
            </div>
          </div>
        );
      })}
    </Row>
  );
}
