/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Image from "./../../assets/images/home-certificationpage/image-contact.png";
import Bg from "../../assets/images/magang/BG.png";
import sertif1 from "../../assets/images/magang/sertif1.png";
import sertif2 from "../../assets/images/magang/sertif2.png";
import sertif3 from "../../assets/images/magang/sertif3.png";
import thumb from "../../assets/images/magang/thumbdigimar.png";
import CompleteProfile from "../../components/Register/CompleteProfile";
import { AuthData } from "../../helper/auth";
import { env, membershipIdAll } from "../../helper/constant";
import {
  SanitizeMaterialTypeId,
  convertHours,
  slugify,
  displayPopup,
} from "../../helper/functions";
import { courseService } from "../../http/CourseLengthHttp";
import { UserService } from "../../http/UserHttp";
import {
  getCoursesDataThunk,
  postEnrollCoursesThunk,
} from "../../redux/thunks/CoursesThunks";
import Benefit from "../CourseFront/Benefit";
import Certficate from "../CourseFront/Certficate";
import Client from "../CourseFront/Client";
import Contact from "../CourseFront/Contact";
import CoursesCardSlider from "../CourseFront/CoursesCardSlider";
import {
  benefitBrevet,
  persiapanSertifkasiAB,
  testiomialBrevet,
  benefitChro,
  benefitMagang,
} from "../CourseFront/Data";
import Home from "../CourseFront/Home";
import MateriAccordion from "../CourseFront/MateriAccordion";
import Purpose from "../CourseFront/Purpose";
import "./../../assets/css/Certification/CertificationPage.css";
import Circle from "./../../assets/images/home-certificationpage/Ellipse3.png";
// import Image from "./../../assets/images/home-certificationpage/bg-heroshot.png";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import imageChro from "../../../src/assets/images/certif-chro.png";
import Button1 from "../CourseFront/Button/Button1";
import { removeCourseDataEnroll } from "../../redux/actions/CoursesActions";

function MagangPage() {
  const { title, co_id } = useParams();
  const prereqRef = useRef();
  const history = useHistory();
  const [prereqNew, setPrereqNew] = useState([]);
  const [userMembership, setUserMembership] = useState(null);
  const [listSelected, setListSelected] = useState(benefitMagang);
  const addressURL = history.location.pathname.split("/");
  const detailAddress = addressURL[2].split("-");
  const dispatch = useDispatch();
  const [idx, setIdx] = useState();
  const [currCourse, setCurrCourse] = useState();
  const [activeKey, setActiveKey] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [openModalVideo, setOpenModalVideo] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isPassed, setIsPassed] = useState(true);
  const [show, setShow] = useState(false);
  const [coursesExplain, setCoursesExplain] = useState();
  // const [totalDurationPreq, setTotalDurationPreq] = useState(0);
  const [newCoursesExplain, setNewCoursesExplain] = useState([]);
  const [courseData, setCourseData] = useState({});
  const courses = useSelector((state) => state.courses);
  // const courseData = courses?.coursesDataId;
  const isLogin = AuthData();
  const [lpIdList, setLpIdList] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const dataSchemes = courseData?.schemes?.map((item) => item.scheme_id);
  const courseCodesProd = [
    "co50216942",
    "co53200844",
    "co38681805",
    "co65154037",
    "co41812069",
    "co11822930",
    "co98219759",
    "co91356438",
    "co38126869",
    "co71256854",
    "co45920202",
    "co27250891",
    "co13864083",
    "co59107702",
    "co24733298",
    "co11326272",
    "co47540066",
    "co58990087",
    "co86116316",
    "co68520833",
    "co26239314",
    "co56599665",
    "co95244245",
    "co92477251",
    "co11432436",
    "co65960754",
    "co24164353",
    "co96605043",
    "co13399155",
    "co65701951",
  ];
  // console.log(prereqNew);
  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (userMembership?.length > 0) {
      let idMembership = env === "production" ? "ca33006573" : "ca57925020";
      if (
        userMembership.filter(
          (membership) =>
            membership.catalogue_id === idMembership &&
            membership.status.toLowerCase() === "active"
        ).length > 0
      ) {
        setIsSubscribe(true);
      } else {
        setIsSubscribe(false);
      }
    }
  }, [userMembership]);

  useEffect(() => {
    // Check learning path by learning path id II
    if (lpIdList && lpIdList?.length > 0) {
      if (courseData) {
        for (let i = 0; i < courseData?.learning_paths?.length; i++) {
          for (let j = 0; j < lpIdList?.length; j++) {
            if (courseData?.learning_paths[i]?.path_id === lpIdList[j]) {
              setIsSubscribe(true);
              return false;
            }
          }
        }
        // for (let i = 0; i < lpIdList?.length; i++) {
        //   for (let j = 0; j < courseData?.learning_paths.length; j++) {
        //     if (courseData.learning_paths[j].path_id === lpIdList[i]) {
        //       setIsSubscribe(true);
        //     }
        //     console.log(courseData.learning_paths[j].path_id === lpIdList[i]);
        //   }
        // }
      }
    }
  }, [lpIdList, courseData]);

  useEffect(() => {
    const preq = courseData?.prerequisites;
    console.log(preq);
    if (preq?.length > 0) {
      // looping di prasyarat course
      let tmp = [];
      let total = 0;
      for (let i = 0; i < preq.length; i++) {
        let currPreq = preq[i];
        let status;
        let enroll = true;
        let done = false;
        total += preq[i]?.total_duration;

        if (currPreq.course_type.toLowerCase() === "pelatihan") {
          // let flag = false;
          if (currPreq?.is_enrolled) {
            // user sudah enroll
            if (currPreq?.is_completed) {
              status = "Pelatihan selesai";
              done = true;
            } else {
              status = "Lanjutkan pelatihan";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti pelatihan"
            status = "Ikuti pelatihan";
            enroll = false;
          }
        }
        let obj = {
          ...currPreq,
          text: status,
          enroll,
          done,
        };
        tmp.push(obj);
      }
      let sortedCourse = tmp;

      if (env === "production") {
        sortedCourse = sortCoursesByCode(tmp, courseCodesProd);
      }
      setPrereqNew(sortedCourse);
      // setTotalDurationPreq(total);
    }
  }, [courseData.prerequisites]);

  useEffect(() => {
    getCourse();
    // getCoursesDataId();
    getMembershipUser();
  }, [co_id]);

  function sortCoursesByCode(courses, courseCodes) {
    // Buat objek dari courses dengan course_id sebagai key
    const coursesDict = {};
    courses.forEach((course) => {
      coursesDict[course.course_id] = course;
    });

    // Susun ulang daftar courses sesuai dengan urutan courseCodes
    const sortedCourses = courseCodes
      .map((code) => coursesDict[code])
      .filter((course) => course !== undefined);
    return sortedCourses;
  }

  const getCourse = async () => {
    dispatch(loadingStart());
    try {
      const response = await courseService.getCourse(`/${co_id}`);
      if (response) {
        setCourseData(response);
        dispatch(loadingFinish());
      }
    } catch (err) {
      console.log(err);
      dispatch(loadingFinish());
    }
  };

  useEffect(() => {
    let passed = true;
    const preq = courseData?.prerequisites;

    if (preq?.length > 0) {
      for (let i = 0; i < preq?.length; i++) {
        if (preq[i].is_enrolled && !preq[i].is_completed) {
          // console.log(preq[i]);
          setCurrCourse(preq[i]);
          passed = false;
          break;
        } else if (!preq[i].is_enrolled && !preq[i].is_completed) {
          setCurrCourse(preq[0]);
          passed = false;
          break;
        } else if (!preq[i].is_completed) {
          passed = false;
          break;
        }
      }
      setIsPassed(passed);
    }
  }, [courseData]);

  useEffect(() => {
    if (courses.courseDataEnroll === "success" && trigger) {
      setTrigger(false);
      getCourse();
      dispatch(removeCourseDataEnroll());
    }
    if (courses.courseDataEnroll === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Memulai Kursus!", "Oke");
      dispatch(removeCourseDataEnroll());
    }
  }, [courses]);

  const getMembershipUser = async () => {
    try {
      const response = await UserService.getMembership();
      setUserMembership(response.memberships);
    } catch (error) {
      console.log(error);
    }
  };

  function enrollCourse() {
    // check if user profile is complete
    if (courseData.profile_completeness === 0) {
      setShow(true);
      return false;
    } else {
      setTrigger(true);
      dispatch(
        postEnrollCoursesThunk({
          user_id: AuthData().userId,
          course_id: co_id,
        })
      );
    }
  }

  const handleScroll = () => {
    prereqRef.current.scrollIntoView();
  };

  function getCoursesDataId() {
    dispatch(getCoursesDataThunk({ type: "id", param: `/${co_id}` }));
  }

  function getIndex() {
    const topics = courseData.topics;
    for (let i = 0; i < topics.length; i++) {
      const materials = topics[i].materials;
      for (let j = 0; j < materials.length; j++) {
        if (materials[j].completed === 0) {
          const data = { index: i, indexL: j };
          return data;
        } else return { index: 0, indexL: 0 };
      }
    }
  }

  function continueCourse(isCompletedPreq) {
    if (!courseData?.profile_completeness) {
      setShow(true);
      return false;
    }
    if (courseData?.is_completed) {
      if (isCompletedPreq) {
        displayPopup(
          false,
          "Gagal Memulai Course, Selesaikan Pelatihan Persiapan!",
          true,
          "Ok"
        );
        return;
      }
      const sanitizedMaterial = SanitizeMaterialTypeId(
        courseData.topics[0].materials[0]
      );
      history.push(
        `/course/${title}/${co_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_0_0`
      );
    } else {
      const idxs = getIndex();

      if (isCompletedPreq) {
        displayPopup(
          false,
          "Gagal Memulai Course, Selesaikan Pelatihan Persiapan!",
          true,
          "Ok"
        );
        return;
      }

      if (idxs) {
        const sanitizedMaterial = SanitizeMaterialTypeId(
          courseData.topics[idxs.index].materials[idxs.indexL]
        );
        history.push(
          `/course/${title}/${co_id}/${sanitizedMaterial?.type}/${slugify(
            sanitizedMaterial?.title
          )}/${sanitizedMaterial.id}_${idxs.index}_${idxs.indexL}`
        );
      } else {
        console.log("topic not found");
        // history.push(`/course/${title}/${co_id}`);
      }
    }
  }

  function renderSplideTestimoniBrevet() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <section className="my-5 position-relative">
        <Container className="ml-xl-5">
          <Col xl={9} className="mt-4 mb-5" id="testimoni">
            <Splide
              options={{
                rewind: true,
                perPage: 1,
                perMove: 1,
                gap: "1.5rem",
                pagination: true,
                arrows: false,
              }}
            >
              {testiomialBrevet?.length > 0
                ? testiomialBrevet?.map((value, index) => (
                    <SplideSlide key={index}>
                      <div className="testimoni-container">
                        <div className="testimoni-profile mb-3">
                          {value.photo === "" ? (
                            <div className="img-testimoni d-flex align-items-center justify-content-center align-items-center">
                              <span className="icon-user"></span>
                            </div>
                          ) : (
                            <img
                              alt="Profile User"
                              className="lazyload"
                              data-src={value.photo}
                            />
                          )}
                        </div>
                        <div className="testimoni-content">
                          <div className="text">{value.testimoni}</div>
                          <div className="info">
                            <div className="name">
                              {value.name} {value.company}
                            </div>
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  ))
                : null}
            </Splide>
          </Col>
        </Container>
      </section>
    );
  }

  const handleOpenVideo = (e) => {
    e.preventDefault();
    setOpenModalVideo(!openModalVideo);
  };

  return (
    <Container className="lesson-container _magang-page position-relative">
      <Home image={Bg}>
        <Home.Header title={courseData?.title}>
          {courseData?.description}
        </Home.Header>
        <Home.Content
          type="magang"
          title={courseData?.instructors && courseData.instructors[0]?.name}
          instructur="Instruktur"
          // durationTitle="Total Durasi ditambah Ujian:"
          // time={
          //   courseData?.total_duration &&
          //   convertHours(courseData?.total_duration)
          // }
          image={courseData?.instructors && courseData.instructors[0]?.avatar}
        />
        <Home.Footer rating={courseData?.rating} type="magang" />
      </Home>
      {persiapanSertifkasiAB.benefit.map((item, index) => (
        <Benefit type="magang" image={courseData?.thumbnail} key={index}>
          <>
            <Benefit.Header
              isSubscribe={isSubscribe}
              type="magang"
              price={600000}
              prereqNew={prereqNew}
            >
              {isSubscribe ? (
                <>
                  <h4 className="mb-3">
                    Magang Mandiri Digital Marketing <b>Bersertifikat BNSP</b>
                  </h4>
                  {courseData?.is_enrolled === 0 ? (
                    ""
                  ) : (
                    <>
                      <div className="wrap__progress mb-3">
                        <div
                          className="progress__bar-materi"
                          style={{
                            width: `${
                              (prereqNew.filter((e) => {
                                return e.done;
                              }).length /
                                prereqNew.length) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>

                      <div className="text-right w-100 d-flex flex-wrap">
                        <span
                          className="text-right col-8 fw-400"
                          style={{ fontFamily: "Poppins" }}
                        >
                          Progress Anda:
                        </span>
                        <p className="text-right col-4 fw-600 mb-0">
                          <span
                            style={{
                              color: "#306CCC",
                              fontFamily: "Poppins",
                            }}
                          >
                            {
                              prereqNew.filter((e) => {
                                return e.done;
                              }).length
                            }
                            /
                          </span>
                          {prereqNew.length} Materi
                        </p>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <i>
                  Bekerja sama dengan <b>LSP MKN</b> dan <b>BNSP</b> untuk
                  mengeluarkan <b>Sertifikasi Kompetensi Digital Marketing</b>
                </i>
              )}
            </Benefit.Header>
            <Benefit.Content
              type="magang"
              isLogin={isLogin}
              isSubscribe={isSubscribe}
              currCourse={currCourse}
              isPassed={isPassed}
              certification={courseData}
              enrollCourse={enrollCourse}
            >
              {!isSubscribe && (
                <>
                  <div className="title_floating-card">
                    Keunggulan Program Ini :
                  </div>
                  {listSelected &&
                    listSelected?.map((item, index) => (
                      <div className="d-flex my-3" key={index}>
                        <img
                          src={item.img}
                          className="mr-2 mt-1 img__icon-wrap"
                          alt=""
                          width={16}
                          height={16}
                        />
                        <p className="fw-400 mb-0 desc__benefit-sticky">
                          {item.title}
                        </p>
                      </div>
                    ))}
                  <p className="footer__benefit-chro w-100">
                    Temukan pertanyaan yang kamu cari{" "}
                    <span>
                      <a
                        href="https://wa.me/6289616680929?text=saya%20ingin%20konsultasi%20mengenai%20satukelas"
                        target="_blank"
                      >
                        Disini!
                      </a>
                    </span>
                  </p>
                </>
              )}
            </Benefit.Content>
          </>
        </Benefit>
      ))}
      <Purpose title="Tujuan Pembelajaran">
        {courseData?.objectives?.length > 0 ? (
          <>
            <Row>
              {showMore
                ? courseData?.objectives?.map((e, i) => {
                    return (
                      <Col md={6} key={i}>
                        <div className="d-block my-3">
                          <div
                            dangerouslySetInnerHTML={{ __html: e }}
                            className="purpose__content"
                          />
                        </div>
                      </Col>
                    );
                  })
                : courseData?.objectives?.slice(0, 6)?.map((e, i) => {
                    return (
                      <Col md={6} key={i}>
                        <div className="d-block my-3">
                          <div
                            dangerouslySetInnerHTML={{ __html: e }}
                            className="purpose__content"
                          />
                        </div>
                      </Col>
                    );
                  })}
              {}
            </Row>
            <a
              onClick={() => setShowMore(!showMore)}
              className="text-decoration-none highlight_text pl-3 fw-600"
            >
              {showMore ? "Lihat Lebih Sedikit" : "Lihat Selengkapnya"}
            </a>
          </>
        ) : (
          <Col md={6}>
            <div className="d-block my-3">Belum ada data</div>
          </Col>
        )}
      </Purpose>
      <Client page="courses" col={8} />
      <section className="my-5">
        <div className="container ml-xl-5">
          <h4 className="fw-700">Benefit Yang Akan Didapatkan</h4>
          <Row>
            <Col lg={8}>
              <Row>
                {listSelected &&
                  listSelected?.map((item, index) => (
                    <Col lg={6}>
                      <div className="d-flex my-3" key={index}>
                        <img
                          src={item.img}
                          className="mr-2 mt-1 img__icon-wrap"
                          alt=""
                          width={18}
                          height={18}
                        />
                        <p className="fw-400 mb-0 desc__benefit-sticky">
                          {item.title}
                        </p>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <div ref={prereqRef}>
        <MateriAccordion
          data={prereqNew}
          exam={courseData}
          title="Persiapan Magang & Sertifikasi"
          subtitle="Selesaikan semua pelatihan persiapan untuk mengikuti ujian sertifikasi."
          // totalDuration={totalDurationPreq}
          type="magang"
          isSubscribe={isSubscribe}
          isLogin={isLogin}
          getCourse={getCourse}
          continueCourse={continueCourse}
          coId={co_id}
        />
      </div>
      {isSubscribe && (
        <section className="my-5">
          <Container className="ml-xl-5">
            <div className="pl-0 pr-0 col-xl-8">
              {/* <h4 className="fw-700">{title}</h4> */}
              <div
                className={`d-block d-md-flex align-items-center w-100 p-4 p-md-3 bg__tryout
            `}
              >
                <div className="col-12 col-md-8 col-lg-8 px-0">
                  <h5 className="ml-3 fw-700 text-white">
                    Unduh Materi Pendukung Magang
                  </h5>
                  <div
                    className="ml-3 text-white"
                    dangerouslySetInnerHTML={{
                      __html:
                        "Seluruh dokumen ini wajib kamu unduh untuk menunjang praktek magangmu.",
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-4 px-0 d-flex justify-content-end">
                  <a
                    target="_blank"
                    href={`https://drive.google.com/drive/folders/1nevcnkPL7uXknkjo0KOt9J04IUmlCXoc?usp=sharing`}
                  >
                    <Button1
                      clas="btn bg-white rounded-3 fw-700 btn__2 btn_custom p-3 border-0"
                      disabled={false}
                      title="Unduh Sekarang"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </section>
      )}

      <section className="my-5">
        <Container className="ml-xl-5">
          <h4 className="fw-700">Contoh Sertifikat</h4>
          <div className="col-xl-8 p-0 mb-4">
            Ada beberapa jenis sertifikat yang akan didapatkan peserta setelah
            mengikuti rangkaian kegiatan pada program ini
          </div>
          <div className="col-xl-8 p-3 p-md-5 shadow-md">
            <div className="shadow-sm">
              <div className="mb-2">Sertifikat Partisipasi</div>
              <img className="w-100 h-100" src={sertif3} alt="" />
            </div>
          </div>
        </Container>
        <Container className="ml-xl-5">
          <div className="col-xl-8 p-3 p-md-5 shadow-md">
            <div className="shadow-sm">
              <div className="mb-2">Sertifikat Magang</div>

              <img className="w-100 h-100" src={sertif1} alt="" />
            </div>
          </div>
        </Container>
        <Container className="ml-xl-5">
          <div className="col-xl-8 p-3 p-md-5 shadow-md">
            <div className="shadow-sm">
              <div className="mb-2">Sertifikat Magang</div>

              <img className="w-100 h-100" src={sertif2} alt="" />
            </div>
          </div>
        </Container>
      </section>

      {/* <Testimonial type={"brevet"} /> */}
      <div>{loaded ? renderSplideTestimoniBrevet() : null}</div>
      {/* {co_id === courseChro ? null : (
        <TutorialCard type="brevet" data={coursesTutorial} />
      )} */}
      {/* <CoursesCardSlider data={courseData?.related_material} /> */}

      <section className="my-5">
        <Container className="ml-xl-5">
          <div className="pl-0 pr-0 col-xl-8">
            <div className="bg__contact">
              <div className="col-12 col-md-6 col-lg-8">
                <h5 className="fw-600 text-white mb-3">
                  Ada pertanyaan terkait pelatihan ini?
                </h5>
                <a
                  href="https://wa.me/6289616680929?text=saya%20ingin%20konsultasi%20mengenai%20satukelas"
                  target="_blank"
                  className="btn bg-white rounded-3 fw-700 btn__contact p-3 border-0"
                >
                  Hubungi Sekarang
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <img src={Image} alt="" />
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Modal
        show={openModalVideo}
        onHide={() => setOpenModalVideo(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Body>
          <div>
            <iframe
              src="https://drive.google.com/file/d/1fPTIle82evmbz5ACC5-p5MHwJVmvH1-C/preview"
              className="w-100"
              height="480"
              allow="autoplay"
              frameBorder="0"
              allowFullScreen
              title="video"
              // allow="autoplay"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Body>
          <CompleteProfile
            par={[]}
            handleClose={() => setShow(false)}
            refreshCourseData={getCourse}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Batal
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default MagangPage;
