import React, { useEffect, useState } from "react";
import { Card, Modal, Row, Col, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import start from "./../assets/images/start_date.png";
import end from "./../assets/images/end_date.png";
import book from "./../assets/images/book-open.png";
import starDisable from "./../assets/images/star-disable.png";
import star from "./../assets/images/star.png";
import clock from "./../assets/images/clock.png";
import ProgressCourse from "./ProgressCourse";
import { convertHours, displayPopup, slugify } from "../helper/functions";
import "../assets/css/Courses/courseCard.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import moment from "moment";
import { defaulThumbnail, env } from "../helper/constant";
import { SkValidate } from "./common/SkValidate";
import { AuthData } from "../helper/auth";
import { FeedbackService } from "../http/FeedbackHttp";
import { postCompleteCourseThunk } from "../redux/thunks/CoursesThunks";
import { useDispatch, useSelector } from "react-redux";
import { removeCompleteState } from "../redux/actions/CoursesActions";

function CourseCard({ item, id, border, progress, assId, status, className, changeProgress, isWebinar, hidden, mandatory, wishlist, typeCourse, widthFit, disable, isCourseRelate, typeWebinar, related, disableHover }) {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const [param, setParam] = useState(id);
  const [idSelected, setIdSelected] = useState();
  const [type, setType] = useState("course");
  const [modal, setModal] = useState(false);
  const [postComplete, setPostComplete] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (postComplete) {
      const payloadC = {
        course_id: item?.course_id,
        user_id: AuthData().userId,
      };
      dispatch(postCompleteCourseThunk(payloadC));
      setPostComplete(false);
    }
  }, [postComplete]);

  useEffect(() => {
    let checkURLCertif = courses.coursesDataId.certificate_template_url;
    if (courses.completeCourseData === "success") {
      dispatch(removeCompleteState());
      displayPopup(false, checkURLCertif ? "Berhasil Menyelesaikan English Course dan Silahkan Cek Sertifikasi Kamu! (Sertifikat akan siap dalam waktu sampai dengan 15 menit)" : "Berhasil Menyelesaikan Course!", "Oke");
      setFeedback(false);
    }

    if (courses.completeCourseData === "fail") {
      dispatch(removeCompleteState());
      setFeedback(false);
      displayPopup(false, "Gagal Memberikan Feedback", true, "Ok");
    }
  }, [courses]);

  useEffect(() => {
    if (assId) {
      setParam(`test/${id}`);
    }
    if (isWebinar) setType("webinar");
  }, []);

  const handleModal = (value) => {
    setModal(!modal);
    setData(value);
  };

  const handleFeedback = (e, item) => {
    e.preventDefault();
    setFeedback(true);
  };
  const [feedbackSend, setFeedbackSend] = useState();
  let address = useLocation();
  let splitted = address.pathname.split("/");

  const progressCourse = () => {
    return (
      <div id="progress-course">
        <ProgressCourse now={item?.progress} />
      </div>
    );
  };

  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setFeedbackSend(newData);
  };
  
  const constraints = {
    review_suggestion: {
      presence: true,
      length: {
        minimum: 20,
      },
    },
  };

  const generateCertifHandler = async (e) => {
    e.preventDefault();
    const form = e.target;
    let payload = {};
    payload.user_id = AuthData().userId;
    payload.course_id = item?.course_id;
    payload.review_suggestion = feedbackSend?.review_suggestion;
    payload.score_satisfaction = idSelected === undefined ? 5 : idSelected;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      try {
        const response = await FeedbackService.postFeedback(payload);
        if (response !== undefined) {
          setPostComplete(true);
        }
      } catch (error) {
        setPostComplete(true);
        // const payloadC = {
        //   course_id: course_id,
        //   user_id: AuthData().userId,
        // };
        // dispatch(postCompleteCourseThunk(payloadC));
      }
    }
  };

  const assignmentView = () => {
    return (
      <div className="d-flex flew-row">
        <Link
          to={{
            pathname: `/handler/task`,
            state: { assignment_id: assId, course_id: item.course_id },
          }}
          style={{ textDecoration: "none", flex: 9 }}>
          <div className="course_card__wrapper_two__section p-4">
            <div className="course_card__lesson_title h4 mb-0">{item?.assignment !== undefined && item?.assignment}</div>
            {progress !== undefined && progressCourse()}
          </div>
        </Link>
        <div
          style={{
            flex: 1,
            backgroundColor: "#00000011",
            borderRadius: "16px",
          }}
          onClick={() => changeProgress(item?.assignment, item?.assignment_id)}>
          <div className="course_card__learning_path vertical-text-wrapper">
            <p className="vertical-text mb-0">{item?.status}</p>
          </div>
        </div>
      </div>
    );
  };

  const commonCourseCard = () => {
    return (
      <Link
        to={{
          pathname: `${`${
            item?.title?.toLowerCase().includes("brevet") && item?.course_type === "Sertifikasi"
              ? `/brevet/${slugify(item.title ? item.title : item.course)}/${item.course_id}/`
              : `/${type}/${slugify(item.title ? item.title : item.course)}/${item.course_id}/`
          }`}`,
          state: { assId: assId },
        }}
        style={{ textDecoration: "none" }}>
        <div className="position-relative">
          {isWebinar &&
            (moment().isBetween(moment(item.time_starts).format("YYYY-MM-DD HH:mm:ss"), moment(item.time_ends).format("YYYY-MM-DD HH:mm:ss")) ? (
              <label style={{ zIndex: "1", top: "15px", left: "15px" }} className="position-absolute course_card__count">
                Sedang Berlangsung
              </label>
            ) : (
              <label style={{ zIndex: "1", top: "15px", left: "15px" }} className="position-absolute course_card__count">
                {moment(item.time_starts).utc().startOf("hours").fromNow()}
              </label>
            ))}
          <div className="course_card__thumbnail_wrapper">
            <img data-src={item?.thumbnail ? item.thumbnail : defaulThumbnail} className="lazyload course_card__thumbnail" style={{ border: `${border}` }} width="255" height="153" alt={item?.thumbnail ? item.thumbnail : defaulThumbnail} />

            <div className="course_card__infocourse_wrapper"></div>
          </div>
        </div>
      </Link>
    );
  };

  /**
   * @function made a function for give an feedback to course
   * @param {*} e
   * @param {*} idx
   */
  const starRatingHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(id);
  };
  /**
   * @function made a function for give an feedback to course
   * @param {*} e
   * @param {*} idx
   */
  const starRatingDisableHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(idSelected + id);
  };

  const dashboardCourseCard = () => {
    return (
      <div style={{ cursor: "pointer" }} onClick={() => handleModal(item)}>
        <div className="course_card__thumbnail_wrapper">
          <img style={{ border: `${border}` }} data-src={item?.thumbnail ? item.thumbnail : defaulThumbnail} className="lazyload course_card__thumbnail " width="255" height="153" alt={item?.thumbnail ? item.thumbnail : defaulThumbnail} />

          <div className="course_card__infocourse_wrapper"></div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card className={`${className} course-card  ${disable ? "w-100 mx-0 my-4 mx-sm-3 " : "course_card__wrapper"} ${widthFit !== undefined ? "fit-content" : ""}`}>
        {/* Condition in task page */}
        {item?.assignment === undefined ? (
          <>
            {progress === undefined ? <>{commonCourseCard()}</> : <>{dashboardCourseCard()}</>}

            <div className="course_card__info_wrapper">
              <div className="course_card__lp_price__wrapper">
                {isWebinar && <label className="mr-2 course_card__webinar">{item?.course_type}</label>}
                <label className="course_card__learning_path">{status === undefined ? (item?.categories?.length > 0 ? item?.categories[0] : "-") : item?.status}</label>
              </div>
              <div className="course_card__wrapper_two__section">
                <Link
                  to={{
                    pathname: `${`/${type}/${isCourseRelate ? slugify(item.course) : slugify(item.title ? item.title : item.course)}/${item.course_id}/`}`,
                    state: { assId: assId },
                  }}
                  style={{ textDecoration: "none" }}>
                  <div className="h4 course_card__lesson_title">
                    {mandatory === true ? item.course : status === undefined ? item?.title : item?.course}
                    {isCourseRelate && item.course}
                  </div>
                </Link>
                {progress !== undefined && (
                  <div id="progress-course">
                    <ProgressCourse now={item?.progress} />
                  </div>
                )}
              </div>
              {!isWebinar && (
                <>
                  <div className="d-flex align-items-center">
                    {hidden === !undefined ? (
                      ""
                    ) : (
                      <div className="instructor-img__container">
                        {item?.instructors[0]?.avatar === "" ? (
                          item?.instructors[0]?.name === "Admin In-house" ? (
                            <Image className="logo-satukelas__course course_detail__tutor_avatar__white" roundedCircle />
                          ) : (
                            item?.instructors[0]?.name.charAt(0)
                          )
                        ) : (
                          <Image className="logo-satukelas__course course_detail__tutor_avatar__white" roundedCircle src={item?.instructors[0]?.avatar} />
                        )}
                      </div>
                    )}

                    <div className="container-instructor__name">
                      {item?.instructors?.length > 0 ? (
                        <>
                          <div className="instructor-name__text">
                            {item?.instructors[0]?.name === "Admin In-house" ? "Satukelas" : item?.instructors[0]?.name?.length > 15 ? item?.instructors[0]?.name.substring(0, 15) + "..." : item?.instructors[0]?.name}
                          </div>
                          <div className="position-name__text">{item?.instructors[0]?.position === "" || item?.instructors[0]?.position === "-" ? "Instruktur" : item?.instructors[0]?.position}</div>
                        </>
                      ) : (
                        <>
                          <div className="instructor-name__text">Satukelas</div>
                          <div className="position-name__text">Instruktur</div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
              {isWebinar && <div className="remaining-seats__text">{item?.remaining_seats} Kursi Tersedia</div>}
              <div className={`${isWebinar ? null : "course_card__info_top"} `}>
                {mandatory === true || wishlist === true ? null : (
                  <>
                    {/* Condition when webinar list showing */}
                    {/* {isWebinar ? (
                      <>
                        <div className="course_card__lesson_num mb-1">
                          <img alt="start" src={start} className="mr-1" />
                          Mulai :{moment(item?.time_starts).format(" HH:mm")} WIB, {moment(item?.time_starts).format("DD MMMM YYYY")}
                        </div>
                        <div className="course_card__lesson_hours mb-1">
                          <img alt="end" src={end} className="mr-1" />
                          Berakhir :{moment(item?.time_ends).format(" HH:mm")} WIB, {moment(item?.time_ends).format("DD MMMM YYYY")}
                        </div>
                      </>
                    ) : null} */}
                  </>
                )}
              </div>
              {splitted[2] !== "webinar" && (
                <Link
                  to={{
                    pathname:
                      item?.title?.toLowerCase().includes("brevet") && item?.course_type === "Sertifikasi"
                        ? `/brevet/${slugify(item.title ? item.title : item.course)}/${item.course_id}/`
                        : `/${type}/${slugify(item.title ? item.title : item.course)}/${item.course_id}/`,
                    state: { assId: assId },
                  }}>
                  <div className="see-detail__button">Lihat Detail</div>
                </Link>
              )}
            </div>
            {typeWebinar === "pass" && isWebinar && (
              <div style={{ height: "70px" }} className="p-3 w-100">
                {splitted[2] === "webinar" && item?.feedback === 0 && (
                  <Button onClick={(e) => handleFeedback(e, item)} className="w-100 ">
                    <span>Isi Feedback</span>
                  </Button>
                )}
              </div>
            )}
          </>
        ) : (
          <>{assignmentView()}</>
        )}
        {hidden === !undefined || disableHover ? (
          ""
        ) : item?.assignment === undefined ? (
          <div className="course-hover__container">
            <div className="course-hover__wrapper">
              <div>
                <img style={{ borderRadius: "8px", objectFit: "cover" }} className="lazyload w-100 mb-1" width="255" height="153" data-src={item?.thumbnail ? item?.thumbnail : defaulThumbnail} />
              </div>

              <div className="course-category__hover mt-2 mb-0">
                <label className="mr-2 mb-0 course_card__webinar">{item?.course_type}</label>
                {item?.categories &&
                  item.categories.map((items, index) => (
                    <label key={index} className="mb-0 course_card__learning_path">
                      {items}
                    </label>
                  ))}
              </div>
              <div className="course-hover__topInfo">
                <div className="course-hover__title-wrapper">
                  <div className="title">{item?.title ? item?.title : item?.course}</div>
                  <div className="course-hover__tutor-wrapper">
                    dengan{" "}
                    <span className="tutor-name">
                      {item?.instructors &&
                        item?.instructors?.length > 0 &&
                        item?.instructors.map((e, i, { length }) => {
                          if (length - 1 === i) return <span key={i}>{e.name}</span>;
                          else return <span key={i}>{e.name},</span>;
                        })}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="course-hover__summary">Tujuan Pembelajaran</div>
              <div className="course-hover__objectives">
                <ul>
                  {item?.objectives &&
                    item.objectives?.length > 0 &&
                    item.objectives
                      .slice(0, 4)
                      .map((e, i) => (e === "" ? "" : <li key={i}>{e}</li>))}
                </ul>
              </div> */}
              {/* <div className="course-hover__bottomInfo mb-2">
                <div className="d-flex align-items-center">
                  <div className="instructor-img__container">
                    {item?.instructors && (
                      <>
                        {item?.instructors[0]?.avatar === "" ?
                          item?.instructors[0]?.name === "Admin In-house"
                            ?
                            <Image
                              className="logo-satukelas__course course_detail__tutor_avatar__white"
                              roundedCircle
                            />
                            : item?.instructors[0]?.name.charAt(0)
                          : <Image
                            className="logo-satukelas__course course_detail__tutor_avatar__white"
                            roundedCircle
                            src={item?.instructors[0]?.avatar}
                          />
                        }
                      </>
                    )}
                  </div>

                  <div className="container-instructor__name">
                    {item?.instructors && (
                      <>
                        <div className="instructor-name__text">
                          {item?.instructors[0]?.name === "Admin In-house"
                            ? "Satukelas"
                            : item?.instructors[0]?.name?.length > 15
                              ? item?.instructors[0]?.name.substring(0, 15) +
                              "..."
                              : item?.instructors[0]?.name}
                        </div>
                        <div className="position-name__text">
                          {item?.instructors[0]?.position === "" || item?.instructors[0]?.position === "-"
                            ? "Instruktur"
                            : item?.instructors[0]?.position}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
              {splitted[2] !== "webinar" && (
                <Link
                  to={`${
                    item?.title?.toLowerCase().includes("brevet") && item?.course_type === "Sertifikasi"
                      ? `/brevet/${slugify(item.title ? item.title : item.course)}/${item.course_id}/`
                      : `/${type}/${slugify(item.title ? item.title : item.course)}/${item.course_id}/`
                  }`}>
                  <div className="see-detail__button hoverable">Lihat Detail</div>
                </Link>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </Card>
      <Modal dialogClassName="modal_progress" show={modal} onHide={() => setModal(!modal)} centered>
        <div className="card_inside__padding">
          <Row>
            <Col lg={12}>
              <div className="course_card__lesson_title course_modal__title">{item?.course}</div>
            </Col>
            <Col lg={6}>
              <div className="wrapper_start__date_course">
                <div className="started_time__text">Mulai</div>
                <div className="data_time">Belum tersedia</div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="wrapper_start__date_course">
                <div className="started_time__text">Terakhir dilihat</div>
                <div className="data_time">Belum tersedia</div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="progress_wrapper__title_small">Progress Pelatihan</div>
              <div id="progress-course" className="py-2 mb-5">
                <ProgressCourse className="mb-0" detail={true} now={item?.progress} />
              </div>
            </Col>
          </Row>

          <div className="text-right">
            <Link to={`/${type}/${slugify(item.course)}/${item.course_id}`} className="detail_front__page px-5">
              Lihat Pelatihan
            </Link>
          </div>
        </div>
      </Modal>
      <Modal show={feedback} onHide={() => setFeedback(!feedback)} centered>
        <div className="card_inside__padding">
          <div className="d-flex flex-row justify-content-center my-4 mb-5">
            {/* this condition will be start when user want to select the score */}
            <>
              {idSelected !== undefined &&
                [...Array(idSelected)].map((item, i) => {
                  return (
                    <div onClick={(e) => starRatingHandler(e, i)} key={i}>
                      <Image className="mx-1" style={{ width: "32px" }} src={star} />
                    </div>
                  );
                })}
            </>

            {/* this condition will be start if the score under 5 */}
            <>
              {idSelected !== undefined &&
                [...Array(5 - idSelected)].map((item, i) => {
                  return (
                    <div onClick={(e) => starRatingDisableHandler(e, i)} key={i}>
                      <Image className="mx-1" style={{ width: "32px" }} src={starDisable} />
                    </div>
                  );
                })}
            </>
            {/* this condition will be start if idSelected have a undefined value */}
            {idSelected === undefined &&
              [...Array(5)].map((value, index) => {
                return (
                  <div onClick={(e) => starRatingHandler(e, index)} key={index}>
                    <Image className="mx-1" style={{ width: "32px" }} src={star} />
                  </div>
                );
              })}
          </div>
          <Form onSubmit={generateCertifHandler}>
            {/* <Form.Group> */}
            {/* </Form.Group> */}
            <div className="mb-4 form-group">
              <Form.Control
                style={{ margin: "0 auto " }}
                className="p-3 d-flex"
                as="textarea"
                rows={5}
                placeholder="Isi feedback disini, Contoh: course ini sangat berguna untuk saya terimakasih!"
                id="review_suggestion"
                name="review_suggestion"
                onChange={handleChange}
              />
              <div className="messages"></div>
            </div>

            <Button className="button-next w-100" type="submit">
              Kirim
            </Button>

            {/* <Button className="button-next" type="submit">
                        Kirim dan Unduh Sertifikat
                      </Button>
                     */}
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default CourseCard;
