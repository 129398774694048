/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Icon from "../../assets/images/home-certificationpage/icon-tryout.png";
import Icon2 from "../../assets/images/home-certificationpage/icon-ujian.png";
import CompleteProfile from "../../components/Register/CompleteProfile";
import { AuthData } from "../../helper/auth";
import { env, membershipIdAll } from "../../helper/constant";
import {
  SanitizeMaterialTypeId,
  convertHours,
  slugify,
  displayPopup,
} from "../../helper/functions";
import { brevetService } from "../../http/BrevetHttp";
import { courseService } from "../../http/CourseLengthHttp";
import { UserService } from "../../http/UserHttp";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";
import Benefit from "../CourseFront/Benefit";
import Certficate from "../CourseFront/Certficate";
import Client from "../CourseFront/Client";
import Contact from "../CourseFront/Contact";
import CoursesCardSlider from "../CourseFront/CoursesCardSlider";
import {
  benefitBrevet,
  persiapanSertifkasiAB,
  testiomialBrevet,
  benefitChro,
} from "../CourseFront/Data";
import Home from "../CourseFront/Home";
import MateriAccordion from "../CourseFront/MateriAccordion";
import Purpose from "../CourseFront/Purpose";
import Testimonial from "../CourseFront/Testimonial";
import TryOut from "../CourseFront/TryOut";
import TutorialCard from "../CourseFront/TutorialCard";
import "./../../assets/css/Certification/CertificationPage.css";
import Circle from "./../../assets/images/home-certificationpage/Ellipse3.png";
import Image from "./../../assets/images/home-certificationpage/bg-heroshot.png";
import CertficateATPI from "../CourseFront/CertificateATPI";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import imageChro from "../../../src/assets/images/certif-chro.png";

function BrevetPage() {
  const { title, co_id } = useParams();
  const prereqRef = useRef();
  const history = useHistory();
  const [prereqNew, setPrereqNew] = useState([]);
  const [userMembership, setUserMembership] = useState(null);
  const addressURL = history.location.pathname.split("/");
  const detailAddress = addressURL[2].split("-");
  const dispatch = useDispatch();
  const [idx, setIdx] = useState();
  const [currCourse, setCurrCourse] = useState();
  const [activeKey, setActiveKey] = useState(false);
  const [openModalVideo, setOpenModalVideo] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isPassed, setIsPassed] = useState(true);
  const [show, setShow] = useState(false);
  const [coursesExplain, setCoursesExplain] = useState();
  // const [totalDurationPreq, setTotalDurationPreq] = useState(0);
  const [newCoursesExplain, setNewCoursesExplain] = useState([]);
  const [coursesTutorial, setCoursesTutorial] = useState();
  const [courseData, setCourseData] = useState({});
  // const courseData = courses?.coursesDataId;
  const isLogin = AuthData();
  const [lpIdList, setLpIdList] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const dataSchemes = courseData?.schemes?.map((item) => item.scheme_id);
  const titleTryoutAb =
    co_id === "co92113052" || co_id === "co73258264"
      ? "Try Out Brevet AB"
      : co_id === "co34028467" || co_id === "co97744206"
      ? "Try Out Brevet C"
      : "";
  const courseIdTryoutAb = env === "development" ? "co57253482" : "co42433570";
  const courseIdTryoutC = env === "development" ? "co90492888" : "co66130030";
  const courseChro = env === "development" ? "co68651931" : "co78129874";
  const coIdC = env === "development" ? "co34028467" : "co97744206";
  const coIdAb = env === "development" ? "co92113052" : "co73258264";

  // console.log(prereqNew);
  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (userMembership?.length > 0) {
      for (let i = 0; i < userMembership.length; i++) {
        // Check membership by catalogue id
        if (membershipIdAll.includes(userMembership[i]?.catalogue_id)) {
          // console.log("tes 1");
          setIsSubscribe(true);
        } else {
          // console.log("tes 2");
          // Check membership by learning path id I
          let arr = userMembership[i]?.learning_path_ids?.split(";");
          setLpIdList(arr);
        }
        // if (userMembership[i]?.catalogue_id === membershipIdAll) {
        //   setIsSubscribe(true);
        // } else {
        //   // Check membership by learning path id I
        //   let arr = userMembership[i]?.learning_path_ids?.split(";");
        //   setLpIdList(arr);
        // }
      }
    }
  }, [userMembership]);

  useEffect(() => {
    // Check learning path by learning path id II
    if (lpIdList && lpIdList?.length > 0) {
      if (courseData) {
        for (let i = 0; i < courseData?.learning_paths?.length; i++) {
          for (let j = 0; j < lpIdList?.length; j++) {
            if (courseData?.learning_paths[i]?.path_id === lpIdList[j]) {
              setIsSubscribe(true);
              return false;
            }
          }
        }
        // for (let i = 0; i < lpIdList?.length; i++) {
        //   for (let j = 0; j < courseData?.learning_paths.length; j++) {
        //     if (courseData.learning_paths[j].path_id === lpIdList[i]) {
        //       setIsSubscribe(true);
        //     }
        //     console.log(courseData.learning_paths[j].path_id === lpIdList[i]);
        //   }
        // }
      }
    }
  }, [lpIdList, courseData]);

  useEffect(() => {
    const preq = courseData?.prerequisites;
    if (preq?.length > 0) {
      // looping di prasyarat course
      let tmp = [];
      let total = 0;
      for (let i = 0; i < preq.length; i++) {
        let currPreq = preq[i];
        let status;
        let enroll = true;
        let done = false;
        total += preq[i]?.total_duration;
        // compare dengan my webinar
        if (currPreq.course_type.toLowerCase() === "webinar") {
          if (currPreq?.is_enrolled) {
            // user sudah enroll
            if (
              moment().isAfter(
                moment(currPreq.time_ends).format("YYYY-MM-DD HH:mm:ss")
              )
            ) {
              status = "Webinar selesai";
              done = true;
            } else {
              // webinar belum mulai atau sedang berlangsung
              status = "Lihat webinar";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti webinar"
            status = "Ikuti webinar";
            enroll = false;
          }
        }

        if (currPreq.course_type.toLowerCase() === "pelatihan") {
          // let flag = false;
          if (currPreq?.is_enrolled) {
            // user sudah enroll
            if (currPreq?.is_completed) {
              status = "Pelatihan selesai";
              done = true;
            } else {
              status = "Lanjutkan pelatihan";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti pelatihan"
            status = "Ikuti pelatihan";
            enroll = false;
          }
        }
        let obj = {
          ...currPreq,
          text: status,
          enroll,
          done,
        };
        tmp.push(obj);
      }
      setPrereqNew(tmp);
      // setTotalDurationPreq(total);
    }
  }, [courseData.prerequisites]);

  // useEffect(() => {
  //   if (coursesExplain) {
  //     let tmp = [];
  //     for (let i = 0; i < coursesExplain.length; i++) {
  //       let currPreq = coursesExplain[i];
  //       let status;
  //       let enroll = true;
  //       let done = false;

  //       // let flag = false;
  //       if (currPreq?.is_enrolled) {
  //         // user sudah enroll
  //         if (currPreq?.is_completed) {
  //           status = "Pelatihan selesai";
  //           done = true;
  //         } else {
  //           status = "Lanjutkan pelatihan";
  //         }
  //       } else {
  //         // user belum enroll
  //         // then status => "Ikuti pelatihan"
  //         status = "Ikuti pelatihan";
  //         enroll = false;
  //       }
  //       let obj = {
  //         ...currPreq,
  //         text: status,
  //         enroll,
  //         done,
  //       };
  //       tmp.push(obj);
  //     }
  //     setNewCoursesExplain(tmp);
  //   }
  // }, [coursesExplain]);

  useEffect(() => {
    getCourse();
    // getCoursesDataId();
    getMembershipUser();
  }, [co_id]);

  const getCourse = async () => {
    dispatch(loadingStart());
    try {
      const response = await courseService.getCourse(`/${co_id}`);
      if (response) {
        setCourseData(response);
        dispatch(loadingFinish());
      }
    } catch (err) {
      console.log(err);
      dispatch(loadingFinish());
    }
  };

  useEffect(() => {
    let passed = true;
    const preq = courseData?.prerequisites;

    if (preq?.length > 0) {
      for (let i = 0; i < preq?.length; i++) {
        if (preq[i].is_enrolled && !preq[i].is_completed) {
          // console.log(preq[i]);
          setCurrCourse(preq[i]);
          passed = false;
          break;
        } else if (!preq[i].is_enrolled && !preq[i].is_completed) {
          setCurrCourse(preq[0]);
          passed = false;
          break;
        } else if (!preq[i].is_completed) {
          passed = false;
          break;
        }
      }
      setIsPassed(passed);
    }
  }, [courseData]);

  useEffect(() => {
    // if (env === "development") {
    //   getExplainDetail("pembahasan%20soal", "soal", detailAddress[3]);
    // } else {
    //   getExplainDetail(
    //     `?keyword=brevet%20${detailAddress[4]}&course_type=pembahasan%20soal`
    //   );
    // }
    getTutorialDetail("tutorial", "tutorial", detailAddress[3]);
  }, [detailAddress[4]]);

  const getMembershipUser = async () => {
    try {
      const response = await UserService.getMembership();
      setUserMembership(response.memberships);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * function get data course by course type, discussion topic, and scheme
   */
  const getExplainDetail = async (type, discussion, value) => {
    try {
      if (env === "development") {
        const response = await brevetService.getCourseBrevetList(
          type,
          discussion,
          value,
          5,
          1
        );
        if (response.items.length > 0) {
          let tempStatus = response.items;
          setCoursesExplain(tempStatus);
        }
      } else {
        const response = await brevetService.getCourseList(type);
        if (response.items.length > 0) {
          let tempStatus = response.items;
          setCoursesExplain(tempStatus);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * function get data course by course type, discussion topic, and scheme
   */
  const getTutorialDetail = async (type, discussion, value) => {
    try {
      const response = await brevetService.getCourseBrevetList(
        type,
        discussion,
        value,
        5,
        1
      );
      if (response.items.length > 0) {
        let tempStatus = response.items;
        setCoursesTutorial(tempStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScroll = () => {
    prereqRef.current.scrollIntoView();
  };

  function getCoursesDataId() {
    dispatch(getCoursesDataThunk({ type: "id", param: `/${co_id}` }));
  }

  function getIndex() {
    const topics = courseData.topics;
    for (let i = 0; i < topics.length; i++) {
      const materials = topics[i].materials;
      for (let j = 0; j < materials.length; j++) {
        if (materials[j].completed === 0) {
          const data = { index: i, indexL: j };
          return data;
        } else return { index: 0, indexL: 0 };
      }
    }
  }

  function continueCourse(isCompletedPreq) {
    if (!courseData?.profile_completeness) {
      setShow(true);
      return false;
    }
    if (courseData?.is_completed) {
      if (isCompletedPreq) {
        displayPopup(
          false,
          "Gagal Memulai Course, Selesaikan Pelatihan Persiapan!",
          true,
          "Ok"
        );
        return;
      }
      const sanitizedMaterial = SanitizeMaterialTypeId(
        courseData.topics[0].materials[0]
      );
      history.push(
        `/course/${title}/${co_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_0_0`
      );
    } else {
      const idxs = getIndex();

      if (isCompletedPreq) {
        displayPopup(
          false,
          "Gagal Memulai Course, Selesaikan Pelatihan Persiapan!",
          true,
          "Ok"
        );
        return;
      }

      if (idxs) {
        const sanitizedMaterial = SanitizeMaterialTypeId(
          courseData.topics[idxs.index].materials[idxs.indexL]
        );
        history.push(
          `/course/${title}/${co_id}/${sanitizedMaterial?.type}/${slugify(
            sanitizedMaterial?.title
          )}/${sanitizedMaterial.id}_${idxs.index}_${idxs.indexL}`
        );
      } else {
        console.log("topic not found");
        // history.push(`/course/${title}/${co_id}`);
      }
    }
  }

  function renderSplideTestimoniBrevet() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <section className="my-5 position-relative">
        <Container className="ml-xl-5">
          <Col xl={9} className="mt-4 mb-5" id="testimoni">
            <Splide
              options={{
                rewind: true,
                perPage: 1,
                perMove: 1,
                gap: "1.5rem",
                pagination: true,
                arrows: false,
              }}
            >
              {testiomialBrevet?.length > 0
                ? testiomialBrevet?.map((value, index) => (
                    <SplideSlide key={index}>
                      <div className="testimoni-container">
                        <div className="testimoni-profile mb-3">
                          {value.photo === "" ? (
                            <div className="img-testimoni d-flex align-items-center justify-content-center align-items-center">
                              <span className="icon-user"></span>
                            </div>
                          ) : (
                            <img
                              alt="Profile User"
                              className="lazyload"
                              data-src={value.photo}
                            />
                          )}
                        </div>
                        <div className="testimoni-content">
                          <div className="text">{value.testimoni}</div>
                          <div className="info">
                            <div className="name">
                              {value.name} {value.company}
                            </div>
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  ))
                : null}
            </Splide>
          </Col>
        </Container>
      </section>
    );
  }

  const handleOpenVideo = (e) => {
    e.preventDefault();
    setOpenModalVideo(!openModalVideo);
  };

  return (
    <Container className="lesson-container position-relative">
      <Home image={Image}>
        <Home.Header title={courseData?.title}>
          {courseData?.description}
        </Home.Header>
        <Home.Content
          title={courseData?.instructors && courseData.instructors[0]?.name}
          instructur="Instruktur"
          // durationTitle="Total Durasi ditambah Ujian:"
          // time={
          //   courseData?.total_duration &&
          //   convertHours(courseData?.total_duration)
          // }
          image={Circle}
        />
        <Home.Footer rating={courseData?.rating} />
      </Home>
      {persiapanSertifkasiAB.benefit.map((item, index) => (
        <Benefit type="nonmember" image={courseData?.thumbnail} key={index}>
          <>
            <Benefit.Header
              isSubscribe={isSubscribe}
              type="brevet"
              price={courseData?.price}
            >
              <>
                {!isSubscribe ? (
                  co_id === courseChro ? (
                    <p className="benefit__header-chro-nosub">
                      Pelatihan dan assesment dilakukan secara daring sehingga
                      fleksibel
                    </p>
                  ) : (
                    <></>
                    // <i>
                    //   <b>Gratis: Gelar Profesi Certified Tax Technician</b> dari{" "}
                    //   <span className="highlight_text">
                    //     Asosiasi Teknisi Perpajakan Indonesia
                    //   </span>{" "}
                    //   jika lulus dengan nilai minimal 80.01.
                    // </i>
                  )
                ) : (
                  <>
                    <h4 className="mb-3">
                      <b>
                        {currCourse?.title
                          ? currCourse.title
                          : currCourse?.course}
                      </b>
                    </h4>
                    <div className="wrap__progress mb-3">
                      <div
                        className="progress__bar-materi"
                        style={{
                          width: `${
                            (prereqNew.filter((e) => {
                              return e.done;
                            }).length /
                              prereqNew.length) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>

                    {co_id === courseChro ? (
                      <div className="d-flex">
                        <span className="w-100 text-right col-6">
                          Progress Anda:{" "}
                        </span>
                        <p className="text-right w-50 col-6">
                          <span style={{ color: "#306CCC" }}>
                            {
                              prereqNew.filter((e) => {
                                return e.done;
                              }).length
                            }
                            /
                          </span>
                          {prereqNew.length} Materi
                        </p>
                      </div>
                    ) : (
                      <div className="text-right w-100 d-flex flex-wrap">
                        <span
                          className="text-right col-8 fw-400"
                          style={{ fontFamily: "Poppins" }}
                        >
                          Progress Anda:
                        </span>
                        <p className="text-right col-4 fw-600 mb-0">
                          <span
                            style={{
                              color: "#306CCC",
                              fontFamily: "Poppins",
                            }}
                          >
                            {
                              prereqNew.filter((e) => {
                                return e.done;
                              }).length
                            }
                            /
                          </span>
                          {prereqNew.length} Materi
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            </Benefit.Header>
            <Benefit.Content
              isLogin={isLogin}
              isSubscribe={isSubscribe}
              type="brevet"
              currCourse={currCourse}
              isPassed={isPassed}
              certification={courseData}
            >
              {co_id === courseChro ? (
                <>
                  {!isSubscribe ? (
                    <>
                      <h6 className="fw-700">Keunggulan Program ini :</h6>
                      {benefitChro.listBenefit?.map((item, index) => (
                        <div className="d-flex my-3" key={index}>
                          <img
                            src={item.image}
                            className="mr-2 mt-1 img__icon-wrap"
                            alt=""
                            width={20}
                            height={20}
                          />
                          <p className="fw-400 mb-0 desc__benefit-sticky">
                            {item.desc}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : null}
                  <label className="small-notes">
                    Asesmen dan sertifikat profesi BNSP diberikan oleh
                    <b> LSP Unggul Indonesia</b>
                  </label>
                </>
              ) : (
                <>
                  {!isSubscribe ? (
                    <>
                      <h6 className="fw-700">Keunggulan Program ini :</h6>
                      {benefitBrevet.listBenefit?.map((item, index) => (
                        <div className="d-flex my-3" key={index}>
                          <img
                            src={item.image}
                            className="mr-2 mt-1 img__icon-wrap"
                            alt=""
                            width={20}
                            height={20}
                          />
                          <p className="fw-400 mb-0 desc__benefit-sticky">
                            {item.desc}
                          </p>
                        </div>
                      ))}
                      <div style={{ fontSize: "12px" }}>
                        <b>*S&K Berlaku.</b> Selengkapnya? Hubungi kami.
                      </div>
                    </>
                  ) : (
                    <label className="small-notes">
                      Anda harus menyelesaikan semua pelatihan persiapan untuk
                      mengikuti ujian sertifikasi.
                    </label>
                  )}
                </>
              )}
            </Benefit.Content>
          </>
        </Benefit>
      ))}
      <Purpose title="Tujuan Pembelajaran">
        {courseData?.objectives?.length > 0 ? (
          <>
            {courseData?.objectives?.map((e, i) => {
              return (
                <Col md={6} key={i}>
                  <div className="d-block my-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: e }}
                      className="purpose__content"
                    />
                  </div>
                </Col>
              );
            })}
            {/* <a
              href="#"
              className="text-decoration-none highlight_text pl-3 fw-600"
            >
              Lihat lebih banyak <span className=" icon-chevrondown"></span>
            </a> */}
          </>
        ) : (
          <Col md={6}>
            <div className="d-block my-3">Belum ada data</div>
          </Col>
        )}
      </Purpose>
      <Client page="courses" col={8} />
      {co_id === courseChro && (
        <div className="container ml-xl-5">
          <Col xl={8} className="px-0">
            <div className="mechanism-chro__wrap">
              <div className="mechanism-chro__content">
                <div className="mechanism-chro__title">
                  Mekanisme Pelatihan dan Asesi Sertifikasi MSDM Level Staf
                </div>
                <div className="mechanism-chro__desc">
                  Yuk, tonton video panduan cara mengikuti pelatihan dan
                  pendaftaran asesi untuk mendapatkan Sertifikat BNSP MSDM Level
                  Staf!
                </div>
              </div>
              <div className="mechanism-chro__button">
                <Button onClick={handleOpenVideo}>Pelajari Mekanisme</Button>
              </div>
            </div>
          </Col>
        </div>
      )}
      <div ref={prereqRef}>
        {co_id === courseChro ? (
          <MateriAccordion
            data={prereqNew}
            exam={courseData}
            title="Sertifikasi Manajemen Sumber Daya Manusia: Level Staf"
            // totalDuration={totalDurationPreq}
            type="chro"
            isSubscribe={isSubscribe}
            isLogin={isLogin}
            continueCourse={continueCourse}
            coId={co_id}
            co_chro={courseChro}
          />
        ) : (
          <MateriAccordion
            data={prereqNew}
            exam={courseData}
            title={courseData?.title}
            subtitle="Selesaikan semua pelatihan persiapan untuk mengikuti ujian sertifikasi."
            // totalDuration={totalDurationPreq}
            type="prereq"
            isSubscribe={isSubscribe}
            isLogin={isLogin}
            getCourse={getCourse}
            continueCourse={continueCourse}
            coId={co_id}
          />
        )}
      </div>
      {co_id === courseChro && (
        <div className="container ml-xl-5">
          <Col xl={8} className="px-0">
            <div className="mechanism-chro__wrap">
              <div className="mechanism-chro__content">
                <div className="mechanism-chro__title">
                  Pendaftaran Asesi Sertifikasi Profesi BNSP
                </div>
                <div className="mechanism-chro__desc">
                  Setelah menyelesaikan semua pelatihan persiapan, kamu bisa
                  mendaftarkan diri untuk mengikuti asesi sertifikasi profesi
                  BNSP dengan LSP yang menjadi mitra Satukelas. Yuk, intip apa
                  saja informasi yang harus kamu isi untuk mendaftarkan diri!
                </div>
              </div>
              <div className="mechanism-chro__button">
                <Button disabled={isPassed ? false : true}>Daftar Asesi</Button>
              </div>
            </div>
          </Col>
        </div>
      )}
      {co_id === courseChro ? null : (
        <>
          <TryOut
            title="Try Out (Materi Pengayaan)"
            icon={Icon}
            heading={`Ikuti Try Out Brevet ${co_id === coIdAb ? "AB" : "C"}`}
            subheading={`Jangan lupa mengikuti <i>Tryout</i> sebagai latihan sebelum mengikuti <i>ujian</i> yang sebenarnya! Hasil <i>Tryout</i> dan <i>pembahasan soal</i> akan langsung tersedia setelah meyelesaikan <i>Tryout</i>`}
            titleButton="Try Out Sekarang"
            type="brevet"
            titleTryout={titleTryoutAb}
            coIdTryout={
              co_id === "co92113052" || co_id === "co73258264"
                ? courseIdTryoutAb
                : co_id === "co34028467" || co_id === "co97744206"
                ? courseIdTryoutC
                : ""
            }
          />
          {/* <MateriAccordion
              data={newCoursesExplain}
              title="Pembahasan Soal (Materi Pengayaan)"
              type="pembahasan"
              isSubscribe={isSubscribe}
              isLogin={isLogin}
              coId={co_id}
            /> */}
          {/* <TryOut
            title="Ujian Sertifikasi"
            icon={Icon2}
            heading="Habis Belajar, Saatnya Ujian!"
            subheading="Ujian berdurasi 200 menit. Siapkan diri Anda. Ujian akan diulang jika Anda meninggalkan laman ujian. Selamat ujian!"
            titleButton="Mulai Ujian!"
            type="certification_brevet"
            isPassed={isPassed}
            continueCourse={continueCourse}
            handleScroll={handleScroll}
            enrolled={courseData?.is_enrolled}
          /> */}
        </>
      )}
      <Certficate
        title={
          co_id === coIdC
            ? "Brevet Pajak C"
            : co_id === coIdAb
            ? "Brevet Pajak AB"
            : "CHRO"
        }
        image={
          co_id === courseChro
            ? imageChro
            : courseData?.certificate_template_url
        }
      />
      {co_id === courseChro ? null : <CertficateATPI />}
      {/* <Testimonial type={"brevet"} /> */}
      <div>
        {co_id === courseChro
          ? null
          : loaded
          ? renderSplideTestimoniBrevet()
          : null}
      </div>
      {/* {co_id === courseChro ? null : (
        <TutorialCard type="brevet" data={coursesTutorial} />
      )}
      <CoursesCardSlider data={courseData?.related_material} /> */}
      <Contact />
      <Modal
        show={openModalVideo}
        onHide={() => setOpenModalVideo(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Body>
          <div>
            <iframe
              src="https://drive.google.com/file/d/1fPTIle82evmbz5ACC5-p5MHwJVmvH1-C/preview"
              className="w-100"
              height="480"
              allow="autoplay"
              frameBorder="0"
              allowFullScreen
              title="video"
              // allow="autoplay"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Body>
          <CompleteProfile
            par={[]}
            handleClose={() => setShow(false)}
            refreshCourseData={getCourse}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Batal
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default BrevetPage;
