export const staticList = [
  {
    title: "Content Library",
    listItem: [
      { list: "Nikmati ratusan pelatihan digital interaktif dan  belasan live webinar  dan live consultation rutin yang tersedia di satukelas.com" },
      {
        list: "Nikmati ratusan pelatihan digital interaktif dan  belasan live webinar  dan live consultation rutin yang tersedia di satukelas.com",
      },
    ],
  },
  {
    title: "Interactive Learning Content and Quiz ",
    listItem: [
      { list: "Video pembelajaran interaktif (H5P Friendly)" },
      {
        list: "Ada pre-test dan post-test",
      },
      {
        list: "Ada ujian sertifikasi",
      },
      {
        list: "Soal ujian diacak dalam setiap percobaan ujian.",
      },
    ],
  },
  {
    title: "Student Dashboard",
    listItem: [
      { list: "Pantau progres pelatihan" },
      {
        list: "Lihat sertifikat yang bisa diklaim",
      },
      {
        list: "Dapatkan rekomendasi kelas terkait riwayat belajar",
      },
    ],
  },
];
export const staticListReverse = [
  {
    title: "Kustomisasi Tampilan",
    listItem: [
      { list: "Membuat tampilan berbeda dengan template yang disediakan" },
      {
        list: "Menggunakan widget yang sesuai kebutuhan",
      },
      {
        list: "Melakukan perubahan warna dan logo ",
      },
    ],
  },
  {
    title: "Pengumuman",
    listItem: [
      { list: "Membuat pengumuman dari organisasi" },
      {
        list: "Mengunggah pengumuman dalam bentuk tulisan, gambar, video, maupun animasi",
      },
    ],
  },
  {
    title: "Moderasi Komentar",
    listItem: [
      { list: "Mengatur komentar yang masuk ke dalam website" },
      {
        list: "Melakukan approval dan delete pada komentar yang masuk",
      },
    ],
  },
];
export const staticListLms = [
  {
    title: "Administrasi Pengguna",
    listItem: [
      { list: "Input pengguna secara individu dan masal" },
      {
        list: "Membuat rombongan belajar",
      },
    ],
  },
  {
    title: "Pengelolaan Konten",
    listItem: [
      { list: "Membuat dan mengunggah konten " },
      {
        list: "Membuat pre/post test",
      },
      {
        list: "Membuat ujian evaluasi",
      },
    ],
  },
  {
    title: "Manajemen Penugasan",
    listItem: [
      { list: "Memberikan penugasan pelatihan kepada pengguna" },
      {
        list: "Mendapatkan progress pelatihan yang dikerjakan",
      },
    ],
  },
  {
    title: "Laporan dan Analitik Performa",
    listItem: [
      { list: "Mengunduh laporan capaian pembelajaran" },
      {
        list: "Mengetahui analitik dari aktivitas pengguna",
      },
    ],
  },
];
export const staticListStep = [
  {
    title: "Traning Need Analysis",
    desc: "Setiap pelatihan dirancang khusus dengan menganalisa kebutuhan kompetensi industri. satukelas juga menyediakan pembelajaran dengan materi yang mendalam.",
  },
  {
    title: "Learning Materials Production",
    desc: "Setiap materi yang sudah dirancang akan melewati tahapan produksi yang dilakukan oleh tim satukelas bersama para mentor yang ahli dalam bidangnya.",
  },
  {
    title: "Traning Need Analysis",
    desc: "Pelatihan yang telah diproduksi akan melewati proses Quality Control untuk memastikan bahwa setiap materi yang akan disediakan memenuhi spesifikasi standar kualifikasi yang telah ditetapkan.",
  },
];
