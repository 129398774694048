import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/Courses/RelatedMaterialSection.css";
import CourseCard from "../CourseCard";

function RelatedMaterialSection(props) {
  const { title, related, more, disableHover } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function renderSplideMore() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-4">
          <div className=" category-card overflow-visible ">
            <div>
              <Splide
                hasTrack={false}
                options={{
                  rewind: true,
                  perPage: 4,
                  perMove: 1,
                  gap: "1.5rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}
              >
                {related?.related_material?.length > 0 ? (
                  related?.related_material?.map((value, index) => {
                    return (
                      <SplideSlide key={index}>
                        <div style={{ width: "auto" }}>
                          <Row className="item">
                            <CourseCard
                              key={index}
                              typeCourse="popular-course"
                              widthFit="fit"
                              item={value}
                              isCourseRelate={true}
                              related={true}
                              disableHover={disableHover}
                            />
                          </Row>
                        </div>
                      </SplideSlide>
                    );
                  })
                ) : (
                  <p>Tidak ada pelatihan serupa</p>
                )}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
  return (
    <div className="related_material__container">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="related_material__title h2">{title}</h2>

        <div className="related-webinar__more">
          <Link to="/search/semua/">{more !== undefined && more}</Link>
        </div>
      </div>
      {/* {[...Array(3)].map((item, i) => {
        return ( */}
      <div id="remove_border">
        <>{loaded ? renderSplideMore() : null}</>
      </div>
      {/* {related?.related_material &&
        related?.related_material?.map((item, index) => {
          return (
            <Link
              to={`/${
                item?.course_type === "Webinar" ||
                item?.course_type === "Konsultasi"
                  ? "webinar"
                  : "course"
              }/${slugify(item.title)}/${item.course_id}`}
              key={index}
              className="related_material__item_wrapper"
            >
              {" "}
              {item?.thumbnail === "" ? (
                <div className="related_material__img_wrapper"></div>
              ) : (
                <div className="related_material__img_wrapper">
                  <img
                    className="related_material__thumbnail"
                    src={item?.thumbnail}
                  />
                </div>
              )}
              <div className="recommend_wrap">
                <div>
                  <div className="related_material__item_title">
                    {item?.course}
                  </div>
                </div>
              </div>
            </Link>
          );
        })} */}
      {/* <div className="related_material__item_wrapper">
        <div className="related_material__img_wrapper"></div>
        <div className="recommend_wrap">
        Belum Tersedia
        <div className="">
            <div className="related_material__item_title">Learning Path</div>
            <div className="related_material__course_num">32 Course</div>
          </div>
          <Button className="show_all__button">Lihat Semua</Button>
        </div>
      </div>
      <div className="related_material__item_wrapper">
        <div className="related_material__img_wrapper"></div>
        <div className="recommend_wrap">
        Belum Tersedia
        <div className="">
            <div className="related_material__item_title">Webinar</div>
            <div className="related_material__course_num">32 Course</div>
          </div>
          <Button className="show_all__button">Lihat Semua</Button>
        </div>
      </div>
      <div className="related_material__item_wrapper">
        <div className="related_material__img_wrapper"></div>
        <div className="recommend_wrap">
        Belum Tersedia
        <div className="">
            <div className="related_material__item_title">Konsultasi</div>
            <div className="related_material__course_num">32 Course</div>
          </div>
          <Button className="show_all__button">Lihat Semua</Button>
        </div>
      </div>
      <div className="related_material__item_wrapper">
        <div className="related_material__img_wrapper"></div>
        <div className="recommend_wrap">
        Belum Tersedia
        <div className="">
            <div className="related_material__item_title">Try Out</div>
            <div className="related_material__course_num">32 Course</div>
          </div>
          <Button className="show_all__button">Lihat Semua</Button>
        </div>
      </div>
      <div className="related_material__item_wrapper">
        <div className="related_material__img_wrapper"></div>
        <div className="recommend_wrap">
        Belum Tersedia
        <div className="">
            <div className="related_material__item_title">Sertifikasi</div>
            <div className="related_material__course_num">32 Course</div>
          </div>
          <Button className="show_all__button">Lihat Semua</Button>
        </div>
      </div> */}
      {/* <div className="related_material__item_wrapper">
        <div className="related_material__img_wrapper"></div>
        <div className="">
          <div className="related_material__item_title">Materi PT. Naura</div>
          <div className="related_material__course_num">32 Course</div>
        </div>
      </div> */}
      {/* );
      })} */}
    </div>
  );
}
export default RelatedMaterialSection;
