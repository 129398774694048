import React from "react";
import { Container } from "react-bootstrap";
import { env } from "../../helper/constant";
import { useParams } from "react-router-dom";
import imageAb from "../../../src/assets/images/brevet-example-ab.png";
import imageC from "../../../src/assets/images/brevet-example-c.png";

const Certficate = (props) => {
  const { image, title } = props;
  const { co_id } = useParams();
  const imgCertifAb = env === "development" ? "co92113052" : "co73258264";
  const imgCertifC = env === "development" ? "co34028467" : "co97744206";

  return (
    <section className="my-5">
      <Container className="ml-xl-5">
        <h4 className="fw-700">Contoh Sertifikat {title ? "Kompetensi " + title : ""}</h4>
        <div className="col-xl-8 p-3 p-md-5 shadow-md">
          <div className="shadow-sm">
            {co_id === imgCertifAb ? <img className="w-100 h-100" src={imageAb} alt="" /> : co_id === imgCertifC ? <img className="w-100 h-100" src={imageC} alt="" /> : <img className="w-100 h-100" src={image} alt="" />}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Certficate;
