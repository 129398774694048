import { PromiseService } from "../../services/PromiseService";
import {
  PAYMENTS_DATA_LINK,
  PAYMENTS_VERIFY_DATA_LINK,
} from "../config/PaymentsConfig";
import {
  //verify
  postPaymentsVerifyData,
  postPaymentsVerifyDataSuccess,
  postPaymentsVerifyDataFail,
  //post
  postPaymentsData,
  postPaymentsDataSuccess,
  //fail
  postPaymentsDataFail,
} from "../actions/PaymentsActions";

export const postPaymentsDataThunk = (data) => async (dispatch) => {
  dispatch(postPaymentsData());
  const body = data;
  try {
    const response = await PromiseService.postData(PAYMENTS_DATA_LINK, body);
    if (response.status === 200 && response.data) {
      dispatch(postPaymentsDataSuccess(response.data));
    } else {
      dispatch(postPaymentsDataFail(response));
    }
  } catch (error) {
    dispatch(postPaymentsDataFail(error));
  }
};
export const postPaymentsVerifyDataThunk = (data) => async (dispatch) => {
  dispatch(postPaymentsVerifyData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(
      `${PAYMENTS_VERIFY_DATA_LINK}/verify/${body.order_id}`,
      body
    );
    if ((response.status === 201 || response.status === 200) && response.data) {
      dispatch(postPaymentsVerifyDataSuccess(response.data));
    } else {
      dispatch(postPaymentsVerifyDataFail(response));
    }
  } catch (error) {
    dispatch(postPaymentsVerifyDataFail(error));
  }
};
