import { Col, Container, Image, Row } from "react-bootstrap";
// import person3 from './../../assets/images/english/person3.webp';
import polkadot from './../../assets/images/english/polkadot.png';
import { Link } from "react-router-dom";
import React from "react";

export default function PromoMiddle(props) {
    const { isLogin } = props;
    return (
        <div className="subs-promo__wrap">
            <div className="circle-ornament"></div>
            <div className="circle-ornament__purple"></div>
            <Container>
                <Row>
                    <Col lg={5} className="position-relative">
                        <Image src="./../../assets/images/english/person3.webp" id="person" />
                        <Image src={polkadot} id="polkadot" />
                    </Col>
                    <Col lg={6} className="offset-lg-1">
                        <div className="title-subs__promo">
                            Ambil <span>Semua Paket, </span>Jadi <span> Lebih Murah!</span>
                        </div>
                        <div className="subtitle-subs__promo">
                            Dapatkan <span>seluruh program pembelajaran</span> Bahasa Inggris dan tes prediksi TOEFL & TOEIC di Satukelas hanya dengan <span className="orange">Rp 600.000</span> <span>per akun</span>
                            untuk durasi belajar <span>12 bulan.</span>
                        </div>
                        <div className="cta-subs__promo">
                            <Link to={isLogin?.userId ? "/dashboard/membership" : "/login"}>
                                <button>Belajar Sekarang</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}