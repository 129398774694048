import {
  PURCHASES_DATA_POST,
  PURCHASES_DATA_POST_SUCCESS,
  PURCHASES_DATA_POST_FAIL,
  REMOVE_PURCHASED_DATA,
} from "../config/PurchasesConfig";

const intialState = {
  purchasesDataPost: [],
  isLoading: false,
};

export const PurchasesReducers = function (state = intialState, action) {
  switch (action.type) {
    case PURCHASES_DATA_POST:
      return { ...state, isLoading: true };
    case PURCHASES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, purchasesDataPost: "success" };
    case PURCHASES_DATA_POST_FAIL:
      return { ...state, isLoading: false, purchasesDataPost: "fail" };
    case REMOVE_PURCHASED_DATA:
      return { ...state, isLoading: false, purchasesDataPost: [] };
    default:
      return state;
  }
};
