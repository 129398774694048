import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeLoginData } from "../../redux/actions/LoginActions";
import { removeUsersDataId } from "../../redux/actions/UsersActions";
import { AuthLogout, AuthData, AuthIsLogin } from "../../helper/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { removeHeaderData } from "../../redux/actions/HeadersActions";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";

const MySwal = withReactContent(Swal);
function StickyNav({ InnerComponent }) {
  // const header = useSelector((state) => state.header);
  // const headersData = header.headersData;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogoutClick = () =>
    MySwal.fire({
      title: "Apakah anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Tidak",
      confirmButtonText: "Ya",
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(removeLoginData());
        dispatch(removeUsersDataId());
        // dispatch(removeHeaderData());
        AuthLogout(history);
      }
    });
  return (
    <div className="app-footer border-0 shadow-lg px-4">
      {AuthData()?.userId ? (
        // headersData?.user?.is_bnsp !== 0 ? (
        //   <>
        //     <Link
        //       to="/bnsp/bnsp-digital-marketing"
        //       className="nav-content-bttn nav-center px-0"
        //     >
        //       <span className="icon-home size "></span>
        //     </Link>
        //     <div className="nav-content-bttn nav-center">
        //       <Dropdown drop="up">
        //         <Dropdown.Toggle variant="success" id="dropdown-custom">
        //           <span className="icon-book"></span>
        //         </Dropdown.Toggle>
        //         <Dropdown.Menu>
        //           {headersData?.user?.is_bnsp === 3 ? (
        //             <>
        //               <Dropdown.Item
        //                 href="/bnsp/digital-marketing-course/"
        //                 className="px-1 py-1"
        //                 eventKey="course"
        //               >
        //                 Course
        //               </Dropdown.Item>
        //               <Dropdown.Item
        //                 id="last-type__list"
        //                 href="/bnsp/daily-assignments/"
        //                 className="px-1 py-1"
        //                 eventKey="assignments"
        //               >
        //                 Daily Assignments
        //               </Dropdown.Item>
        //             </>
        //           ) : (
        //             <p>tidak ada pilihan tersedia</p>
        //           )}
        //         </Dropdown.Menu>
        //       </Dropdown>
        //     </div>
        //     <Link
        //       to={
        //         headersData?.user?.is_bnsp === 1
        //           ? "/course/aptitude-test/co72717771/"
        //           : headersData?.user?.is_bnsp === 2
        //             ? "/bnsp/agreement-user/"
        //             : headersData?.user?.is_bnsp === 3
        //               ? "/course/aptitude-test/co79836724/"
        //               : "/"
        //       }
        //       className="nav-content-bttn sidebar-layer px-0"
        //     >
        //       <span id="size" className="icon-file nav-sticky"></span>
        //     </Link>
        //     <div onClick={() => handleLogoutClick()}>
        //       <span className="icon-logout sticky"></span>
        //     </div>
        //   </>
        // ) : (
        <>
          <Link to="/" className="nav-content-bttn nav-center px-0">
            <span className="icon-home size "></span>
          </Link>

          <div className="nav-content-bttn nav-center">
            <Dropdown drop="up" id="mobile">
              <Dropdown.Toggle variant="success" id="dropdown-custom">
                <span className="icon-book"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/search/semua/" className="px-1 py-1" eventKey="pelatihan">
                  Pelatihan
                </Dropdown.Item>{" "}
                <Dropdown.Item href="/search/try%20out/" className="px-1 py-1" eventKey="pelatihan">
                  Try Out
                </Dropdown.Item>
                <Dropdown.Item href="/search/studi%20kasus/" className="px-1 py-1" eventKey="pelatihan">
                  Studi Kasus
                </Dropdown.Item>
                <Dropdown.Item id="last-type__list" href="/webinar-list/webinar" className="px-1 py-1" eventKey="webinar">
                  Webinar
                </Dropdown.Item>
                <Dropdown.Item id="last-type__list" href="/webinar-list/konsultasi" className="px-1 py-1" eventKey="webinar">
                  Konsultasi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {AuthIsLogin("", true) ? (
            <Link to="/dashboard/course/" className="button_dashboard__link">
              <span className="icon-grid1"></span>
            </Link>
          ) : null}

          <Link to="/dashboard/notification/" className="nav-content-bttn sidebar-layer px-0">
            {/* <span className="dot-count bg-warning"></span> */}
            <span id="size" className="icon-notification size"></span>
          </Link>
          <div className={`nav-content-bttn px-0 ${AuthData()?.userId ? "" : `p-0`}`}>
            {AuthData()?.userId ? (
              <Dropdown drop="up" id="mobile">
                <Dropdown.Toggle variant="success" id="dropdown-custom">
                  <span className="icon-user"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/dashboard/profile/" className="px-1 py-1" eventKey="pelatihan">
                    Profil
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    href="/atpi/ijazah/"
                    className="px-1 py-1"
                    eventKey="pelatihan"
                  >
                    Ijazah
                  </Dropdown.Item>{" "} */}
                  <Dropdown.Item href="/indonesia-tax-review" className="px-1 py-1" eventKey="pelatihan">
                    Majalah ITR
                  </Dropdown.Item>
                  <Dropdown.Item href="/webstore" className="px-1 py-1" eventKey="webstore">
                    Webstore
                  </Dropdown.Item>
                  <Dropdown.Item href="/company-profile" className="px-1 py-1" eventKey="webinar">
                    Perusahaan Saya
                  </Dropdown.Item>
                  <Dropdown.Item href="/dashboard/settings/" className="px-1 py-1" eventKey="webinar">
                    Pengaturan
                  </Dropdown.Item>
                  <Dropdown.Item href="https://form.asana.com/?k=gQs4Q3qV2qipkvrpbNOhOA&d=1200494741159113" className="px-1 py-1" eventKey="webinar">
                    Kirim Feedback
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLogoutClick()} className="px-1 py-1" eventKey="webinar">
                    Keluar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Link to="/login">
                <span className="icon-user p-0 size-profile"></span>
              </Link>
            )}
          </div>
        </>
      ) : (
        // )
        <>
          <Link to="/" className="nav-content-bttn nav-center px-0">
            <span className="icon-home size "></span>
          </Link>

          <div className="nav-content-bttn nav-center">
            <Dropdown drop="up">
              <Dropdown.Toggle variant="success" id="dropdown-custom">
                <span className="icon-book"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/search/semua/" className="px-1 py-1" eventKey="pelatihan">
                  Pelatihan
                </Dropdown.Item>{" "}
                <Dropdown.Item href="/search/try%20out/" className="px-1 py-1" eventKey="pelatihan">
                  Try Out
                </Dropdown.Item>
                <Dropdown.Item href="/search/studi%20kasus/" className="px-1 py-1" eventKey="pelatihan">
                  Studi Kasus
                </Dropdown.Item>
                <Dropdown.Item id="last-type__list" href="/webinar-list/webinar" className="px-1 py-1" eventKey="webinar">
                  Webinar
                </Dropdown.Item>
                <Dropdown.Item id="last-type__list" href="/webinar-list/konsultasi" className="px-1 py-1" eventKey="webinar">
                  Konsultasi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {AuthIsLogin("", true) ? (
            <Link to="/dashboard/course/" className="button_dashboard__link">
              <span className="icon-grid1"></span>
            </Link>
          ) : null}

          <div className={`nav-content-bttn px-0 ${AuthData()?.userId ? "" : `p-0`}`}>
            {AuthData()?.userId ? (
              <Dropdown drop="up">
                <Dropdown.Toggle variant="success" id="dropdown-custom">
                  <span className="icon-user"></span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/dashboard/profile/" className="px-1 py-1" eventKey="pelatihan">
                    Profil
                  </Dropdown.Item>
                  <Dropdown.Item href="/company-profile" className="px-1 py-1" eventKey="webinar">
                    Perusahaan Saya
                  </Dropdown.Item>
                  <Dropdown.Item href="/dashboard/settings/" className="px-1 py-1" eventKey="webinar">
                    Pengaturan
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLogoutClick()} className="px-1 py-1" eventKey="webinar">
                    Keluar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Link className="px-0" to="/login">
                <span className="icon-user p-0 size-profile"></span>
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default StickyNav;
