import React, { useState } from "react";
import "./../../assets/css/StaticPages/affiliation.css";
import hero1 from "./../../assets/images/mitra/hero-1.png";
import "lazysizes";

import iconDesktop from "./../../assets/images/mitra/desktop.svg";
import iconPencil from "./../../assets/images/mitra/pencil.svg";
import iconClipboard from "./../../assets/images/mitra/clipboard.svg";

import hero2 from "./../../assets/images/mitra/hero-2.png";

import click from "./../../assets/images/mitra/click.svg";
import illu1 from "./../../assets/images/mitra/illustration-1.png";
import illu2 from "./../../assets/images/mitra/illustration-2.png";
import illu3 from "./../../assets/images/mitra/illustration-3.png";
import illu4 from "./../../assets/images/mitra/illustration-4.png";

import check from "./../../assets/images/mitra/check.svg";

import vidPoster from "./../../assets/images/mitra/video-poster.png";
import graph from "./../../assets/images/mitra/graph.svg";
import arrowRight from "./../../assets/images/mitra/arrow-right.svg";
import arrowLeft from "./../../assets/images/mitra/arrow-left.svg";

// import tiktok from "./../../assets/images/mitra/tiktok.svg";
// import instagram from "./../../assets/images/mitra/instagram.svg";
// import linkedin from "./../../assets/images/mitra/linkedin.svg";

// import gplay from "./../../assets/images/mitra/googleplay.png";
import { Tab, Tabs } from "react-bootstrap";
import FooterMitra from "../../components/FooterMitra/FooterMitra";

export default function Affiliation() {
  const [penjualan, setPenjualan] = useState(5);
  const [komisi, setKomisi] = useState("480.000");
  const [penjualanSSS, setPenjualanSSS] = useState(5);
  const [komisiSSS, setKomisiSSS] = useState("200.000");
  const handleChange = (e, type) => {
    const value = Number(e.target.value);
    if (type === "sss") {
      setPenjualanSSS(getPenjualan(value));
      setKomisiSSS(getKomisi("sss", value));
    } else {
      setPenjualan(getPenjualan(value));
      setKomisi(getKomisi("satukelas", value));
    }
  };

  const getPenjualan = (num) => {
    switch (num) {
      case 1:
        return 4;
      case 2:
        return 9;
        break;
      case 3:
        return 10;
      case 4:
        return 30;
    }
  };

  const getKomisi = (type, num) => {
    switch (num) {
      case 1:
        if (type === "sss") {
          return "200.000";
        } else {
          return "480.000";
        }
      case 2:
        if (type === "sss") {
          return "900.000";
        } else {
          return "2.160.000";
        }
      case 3:
        if (type === "sss") {
          return "2.000.000";
        } else {
          return "4.800.000";
        }
      case 4:
        if (type === "sss") {
          return "6.000.000";
        } else {
          return "14.400.000";
        }
    }
  };
  return (
    <>
      {/* hero */}
      <section className="mitra hero blue">
        <div className="container">
          <div className="wording">
            <h1 className="heading-light">
              Jadilah Mitra Satukelas!{" "}
              <i>
                Just <span>Share not Sell,</span>
              </i>{" "}
              dan Dapatkan Penghasilan Tambahan!
            </h1>
            <p>
              Dapatkan Akses Belajar Sambil Berjualan Dengan Keuntungan Menarik!
            </p>
            <a href="#" className="cta">
              Konsultasi GRATIS
            </a>
          </div>
          <div className="hero-wrapper d-desktop">
            <img className="lazyload" src={hero1} width="100%" alt="hero" />
          </div>
        </div>
      </section>

      {/* benefit */}
      <section className="mitra benefit">
        <div className="container">
          <h2 className="heading">
            Manfaat dan Keuntungan Menjadi Mitra Satukelas
          </h2>

          <div className="items-container">
            <div className="items-item">
              <div className="icon-wrapper">
                <img className="lazyload" src={iconDesktop} alt="desktop" />
              </div>
              <div className="info">
                <h3 className="title">Akses ke Pelatihan Satukelas</h3>
                <p className="description p-sm">
                  Sebagai mitra satukelas anda mendapatkan akses ke konten
                  pelatihan satukelas, tanpa biaya!
                </p>
              </div>
            </div>

            <div className="items-item">
              <div className="icon-wrapper">
                <img className="lazyload" src={iconPencil} alt="pencil" />
              </div>
              <div className="info">
                <h3 className="title">Komisi Hingga 40%</h3>
                <p className="description p-sm">
                  Komisi penjualan hingga 40%! Lihat estimasi komisi penjualan
                  anda dibawah.
                </p>
              </div>
            </div>

            <div className="items-item">
              <div className="icon-wrapper">
                <img className="lazyload" src={iconClipboard} alt="clipboard" />
              </div>
              <div className="info">
                <h3 className="title">Mendukung Pendidikan Indonesia</h3>
                <p className="description p-sm">
                  Bersama satukelas Anda turut berkontribusi dalam meningkatkan
                  kualitas pendidikan di Indonesia
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* pengertian */}
      <section className="mitra definition">
        <div className="container flex">
          <div className="hero-wrapper d-desktop">
            <img className="lazyload" src={hero2} width="100%" alt="hero" />
          </div>
          <div className="wording">
            <h2 className="heading2">Apa Itu Mitra Satukelas?</h2>
            <p>
              Mitra Satukelas adalah komunitas resmi yang memiliki izin untuk
              membagikan informasi mengenai produk Satukelas dan mendapat
              keuntungan dari kegiatan tersebut.
            </p>
          </div>
        </div>
      </section>

      {/* produk */}
      <section className="mitra produk">
        <div className="container">
          <h2 className="heading">Komisi Untuk Mitra Satukelas</h2>
          <div className="items-container">
            <div className="item-produk flex">
              <div className="graph">
                <div className="square"></div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Penjualan</th>
                        <th>Komisi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1-4</td>
                        <td>10%</td>
                      </tr>
                      <tr>
                        <td>5-9</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>&gt;10</td>
                        <td>40%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="info">
                <h3 className="">
                  satukelas
                  <span>Harga Jual: Rp1.200.000</span>
                </h3>
                Jika Anda berhasil mengajak:
                <ul>
                  <li>1-4 member, mendapat komisi 10% dari harga jual.</li>
                  <li> 5-9 member, mendapat komisi 20% dari harga jual.</li>
                  <li>
                    10 member atau lebih, mendapat komisi 40% dari harga jual.
                  </li>
                </ul>
                <a href="#" className="cta blue">
                  Lihat Selengkapnya
                </a>
              </div>
            </div>
            <div className="item-produk flex">
              <div className="graph">
                <div className="circle"></div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Penjualan</th>
                        <th>Komisi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1-4</td>
                        <td>10%</td>
                      </tr>
                      <tr>
                        <td>5-9</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>&gt;10</td>
                        <td>30%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="info">
                <h3 className="">
                  Siap Sedari Sekolah
                  <span>Harga Jual: Rp500.000</span>
                </h3>
                Jika Anda berhasil mengajak:
                <ul>
                  <li>1-4 member, mendapat komisi 10% dari harga jual.</li>
                  <li>5-9 member, mendapat komisi 20% dari harga jual.</li>
                  <li>
                    10 member atau lebih, mendapat komisi 30% dari harga jual.
                  </li>
                </ul>
                <a href="#" className="cta blue">
                  Lihat Selengkapnya
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* income */}
      <section className="mitra income">
        <div className="container">
          <h2 className="heading">Estimasi Pendapatan Mitra Satukelas</h2>

          <Tabs defaultActiveKey="satukelas">
            <Tab eventKey={`satukelas`} title="Satukelas">
              <div className="slider">
                <div className="range-wrapper">
                  <input
                    type="range"
                    className="form-range _slider"
                    min="1"
                    max="4"
                    defaultValue={1}
                    onChange={(e) => handleChange(e, "satukelas")}
                  />
                  <span className="mobile-description">
                    {penjualan} Penjualan
                  </span>
                </div>
                <span className="description">4 Penjualan</span>
                <span className="description">9 Penjualan</span>
                <span className="description">10 Penjualan</span>
                <span className="description">30 Penjualan</span>
              </div>
              <div className="info">
                <p className="heading2">Total Komisi Anda</p>
                <p className="heading blue">Rp{komisi}</p>
                <p className="p-sm">
                  Total Komisi adalah pendapatan anda jika anda berhasil
                  mengajak pengguna baru bergabung bersama satukelas.
                </p>
                <p>
                  Persentase komisi bersifat akumulatif terhadap jumlah unit
                  terjual. Akumulasi dihitung per tahun, dimulai pada 1 Januari
                  dan berakhir pada 31 Desember di tahun tersebut.
                </p>
              </div>
              <a href="#" className="cta blue">
                Lihat Selengkapnya
              </a>
            </Tab>
            <Tab eventKey={`siapkerja`} title="Siapkerja Sedari Sekarang">
              <div className="slider">
                <div className="range-wrapper">
                  <input
                    type="range"
                    className="form-range _slider"
                    min="1"
                    max="4"
                    defaultValue={1}
                    onChange={(e) => handleChange(e, "sss")}
                  />
                  <span className="mobile-description">
                    {penjualanSSS} Penjualan
                  </span>
                </div>
                <span className="description">4 Penjualan</span>
                <span className="description">9 Penjualan</span>
                <span className="description">10 Penjualan</span>
                <span className="description">30 Penjualan</span>
              </div>
              <div className="info">
                <p className="heading2">Total Komisi Anda</p>
                <p className="heading blue">Rp{komisiSSS}</p>
                <p className="p-sm">
                  Total Komisi adalah pendapatan anda jika anda berhasil
                  mengajak pengguna baru bergabung bersama satukelas.
                </p>
                <p>
                  Persentase komisi bersifat akumulatif terhadap jumlah unit
                  terjual. Akumulasi dihitung per tahun, dimulai pada 1 Januari
                  dan berakhir pada 31 Desember di tahun tersebut.
                </p>
              </div>
              <a href="#" className="cta blue">
                Lihat Selengkapnya
              </a>
            </Tab>
          </Tabs>
        </div>
      </section>

      {/* kemudahan */}
      <section className="mitra kemudahan">
        <div className="container">
          <h2 className="heading">Kemudahan menjadi mitra satukelas</h2>

          <div className="step-container">
            <div className="step-item">
              <div className="graph">
                <img className="lazyload" src={click} alt="click" />
              </div>
              <p className="p-md step">Step 1</p>
              <p className="blue title">Daftar</p>
              <p className="p-sm description">
                Cukup klik “konsultasi gratis” dan isi form. Tenang, anda tidak
                akan dipungut biaya sepeserpun!
              </p>
            </div>
            <div className="illustration-wrapper d-desktop">
              <a href="#">
                <img className="lazyload" src={illu1} width="100%" alt="illustration" />
              </a>
            </div>
          </div>

          <div className="step-container">
            <div className="step-item">
              <div className="graph">
                <img className="lazyload" src={click} alt="click" />
              </div>
              <p className="p-md step">Step 2</p>
              <p className="blue title">Pelajari Satukelas</p>
              <p className="p-sm description">
                Pelajari program dan produk satukelas. Kamu juga bebas
                berdiskusi ke admin untuk memahami produk lebih dalam.
              </p>
            </div>
            <div className="illustration-wrapper d-desktop">
              <img className="lazyload" src={illu2} width="100%" alt="illustration" />
            </div>
          </div>

          <div className="step-container">
            <div className="step-item">
              <div className="graph">
                <img className="lazyload" src={click} alt="click" />
              </div>
              <p className="p-md step">Step 3</p>
              <p className="blue title">Perkenalkan Satukelas</p>
              <p className="p-sm description">
                Perkenalkan produk satukelas kepada kerabat, teman dan rekan
                kerjamu. Infonya sudah disediakan!
              </p>
            </div>
            <div className="illustration-wrapper d-desktop">
              <img className="lazyload" src={illu3} width="100%" alt="illustration" />
            </div>
          </div>

          <div className="step-container">
            <div className="step-item">
              <div className="graph">
                <img className="lazyload" src={click} alt="click" />
              </div>
              <p className="p-md step">Step 4</p>
              <p className="blue title">Transaksi</p>
              <p className="p-sm description">
                Setelah admin mengkonfirmasi pembayaran, dalam hitungan hari
                komisimu akan dikirimkan.
              </p>
            </div>
            <div className="illustration-wrapper d-desktop">
              <img className="lazyload" src={illu4} width="100%" alt="illustration" />
            </div>
          </div>
        </div>
      </section>

      {/* program affiliate */}
      <section className="mitra affiliate blue">
        <div className="container flex">
          <div className="video-wrapper">
            <img className="lazyload" src={vidPoster} width="100%" alt="video-poster" />
          </div>
          <div className="wording">
            <h2 className="heading2-light">
              Lihat video penjelasan singkat tentang program mitra satukelas
            </h2>
            {/* <p className="subheading2-light">
              Learning Management System siap pakai & customizable sesuai
              kebutuhan perusahaan Anda
            </p> */}
          </div>
        </div>
      </section>

      <section className="mitra req">
        <div className="container">
          <h2 className="heading2">Mitra Satukelas Adalah Orang yang...</h2>
          <div className="kriteria-item">
            <div className="icon">
              <img src={check} alt="check" />
            </div>
            <div className="info">
              <p className="title">Passionate Terhadap Pendidikan</p>
              <p className="summary">
                Anda merasa sistem pembelajaran di Indonesia perlu ditingkatkan
                dan tertarik menjadi salah satu penggerak.
              </p>
            </div>
          </div>
          <div className="kriteria-item right">
            <div className="icon">
              <img src={check} alt="check" />
            </div>
            <div className="info">
              <p className="title">
                Punya <i>Social Networking</i> yang Luas
              </p>
              <p className="summary">
                Anda memiliki banyak relasi dan rasa empati terhadap orang lain.
              </p>
            </div>
          </div>
          <div className="kriteria-item">
            <div className="icon">
              <img src={check} alt="check" />
            </div>
            <div className="info">
              <p className="title">Senang Berbicara di Depan Umum</p>
              <p className="summary">
                Anda tidak canggung ketika berinteraksi dengan orang baru atau
                dalam lingkup besar.
              </p>
            </div>
          </div>
          <div className="graph">
            <div className="circle-orange">
              <div className="arrowright">
                <img src={arrowRight} alt="arrow-right" />
              </div>
            </div>
            <div className="circle-blue">
              <div className="arrowleft">
                <img src={arrowLeft} alt="arrow-left" />
              </div>
            </div>
            <div className="circle-pink"></div>
          </div>
        </div>
      </section>

      {/* kenapa */}
      <section className="mitra alasan">
        <div className="container">
          <h2 className="heading2">Kenapa Harus Ikut Afiliasi satukelas?</h2>
          <div className="items-container">
            <div className="items-item">
              <div className="icon-wrapper">
                <img className="lazyload" src={graph} alt="graph" />
              </div>
              <div className="info">
                <h3 className="title">Diakui Perusahaan Besar</h3>
                <p className="description p-sm">
                  satukelas mengeluarkan sertifikasi resmi yang diakui oleh LPK
                  dan LSP-BNSP.
                </p>
              </div>
            </div>
            <div className="items-item">
              <div className="icon-wrapper">
                <img className="lazyload" src={graph} alt="graph" />
              </div>
              <div className="info">
                <h3 className="title">Memenuhi Kebutuhan Sertifikasi</h3>
                <p className="description p-sm">
                  Kebutuhan sertifikasi dari tahun 2014 hingga akhir tahun 2021
                  naik hampir 80% artinya pengembangan pelatihan sertifikasi
                  tenaga kerja memiliki banyak peminat setiap tahunnya.
                </p>
              </div>
            </div>
            <div className="items-item">
              <div className="icon-wrapper">
                <img className="lazyload" src={graph} alt="graph" />
              </div>
              <div className="info">
                <h3 className="title">Memiliki Ribuan Member</h3>
                <p className="description p-sm">
                  satukelas telah dipercaya lebih dari 2000 member.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* footer */}
      <FooterMitra />
    </>
  );
}
