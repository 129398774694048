import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../../assets/css/Account/index.css";
import { blog_detail } from "../../helper/constant";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

function Blog() {
  const { id } = useParams();

  return (
    <Container>
      <>
        <Row className="blog_container">
          <Col lg={7}>
            <div className="blog_title__container">
              <div className="blog_subtitle__container">
                <div className="blog_subtitle">BLOG</div>
              </div>
              <h2 className="h2 blog_title">{blog_detail[id]?.title}</h2>
            </div>
            <div className="blog_info__container">
              <div>Publikasi : {blog_detail[id]?.date}</div>
              <div>Penulis : {blog_detail[id]?.writer}</div>
            </div>
          </Col>
          <Col lg={12} className="mb-5">
            <div className="blog_image__container">
              {" "}
              <img
                className="w-100 lazyload"
                alt="Main Thumbnail Article"
                data-src={blog_detail[id]?.thumbnail}
              />
              <div className="mt-2 text-center src_img">
                Sumber Foto :{" "}
                <span>
                  <a href={`${blog_detail[id]?.src_img}`}>Rawpixel</a>
                </span>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="blog_article__container">
              {blog_detail[id]?.article?.first_paragraph}
            </div>
            <div className="blog_article__container">
              {" "}
              {blog_detail[id]?.article?.list_paragraph?.map((item, index) => {
                return (
                  <div key={index}>
                    <ol className="pl-3 list" id="blog">
                      <li>
                        <div>
                          <div className="title_section__paragraph">
                            {item?.title_paragraph}
                          </div>
                        </div>
                        <div>
                          {item?.detail_paragraph.detail_first_paragraph}
                        </div>
                        <div>
                          {item?.list_first_paragraph?.length > 0 ? (
                            <div>
                              {item?.list_first_paragraph.map((e, i) => {
                                return (
                                  <div key={item}>
                                    <div className="title_section__paragraph">
                                      {e?.title_list}
                                    </div>
                                    <div>{e?.desc_list}</div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}{" "}
                        </div>
                      </li>
                    </ol>
                  </div>
                );
              })}
            </div>
            <div className="blog_article__container">
              {" "}
              {blog_detail[id]?.article.last_paragraph}
            </div>
          </Col>
        </Row>
        {/* <Row>
                  <Col
                    lg={12}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="more_article__title">Artikel Lainnya</div>
                    <div className="more_article__complete">Lihat Semua</div>
                  </Col>
                  <Col lg={4} className="article_wrapper">
                    <Card className="article_container">
                      <div className="article_title">Judul 1</div>
                    </Card>
                  </Col>
                </Row> */}
      </>
    </Container>
  );
}

export default Blog;
