import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import "animate.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import "../../assets/css/Layout/main.css";
import { AuthIsLogin } from "../../helper/auth";
import InternalServer from "../../pages/Error/InternalServer";
import search from "./../../assets/images/search.png";
import Footer from "./Footer";
import Header from "./Header";
// import { getHeadersDataThunk } from "../../redux/thunks/HeadersThunks";
import { removeLoginData } from "../../redux/actions/LoginActions";
import NewHeader from "./NewHeader";
import NewFooter from "./NewFooter";
import styled from "styled-components";
import exclude from "../../assets/images/Exclude.png";
import info from "../../assets/images/information-icon.png";
import times from "../../assets/images/times.png";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import Info from "../common/Info";
const OneSignal = typeof window !== "undefined" ? window.OneSignal : [];

const Main = ({
  handleToggleSidebar,
  handleCollapsedChange,
  InnerComponent,
  isSidebar,
  collapsed,
  isError,
  noFooter,
  coursesPelatihan,
}) => {
  const myRef = useRef();
  const [totalNotif, setTotalNotif] = useState(0);
  const [mega, setMega] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [userDataId, setUserDataId] = useState();
  const [id, setId] = useState();
  const [externalId, setExternalId] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [dataCompanies, setDataCompanies] = useState([]);
  const [keyword, setKeyword] = useState("");
  const companies = useSelector((state) => state.companies);
  const courses = useSelector((state) => state.courses);
  const coursesData = courses.coursesData?.pagination?.totalItems;
  const header = useSelector((state) => state.header);
  const login = useSelector((state) => state.login);
  const headerData = header.headersData;
  const companyData = companies.companiesDataId;
  const dispatch = useDispatch();
  const [isHidden, setIsHidden] = useState(false);
  const users = useSelector((state) => state?.users);
  const usersData = users?.usersDataId;
  let address = useLocation();
  const params = useParams();
  const history = useHistory();
  let splitted = address.pathname.split("/");
  const handleChange = (e) => {
    setKeyword(e.target.value);
  };
  const handleSearch = (e) => {
    setSearchModal(!searchModal);
    setMega(false);
    setId(e.target.id);
    myRef.current?.focus();
  };
  useEffect(() => {
    if (AuthIsLogin("", true)) setIsLogin(true);
  }, []);

  useEffect(() => {
    if (login.loginData) {
      // dispatch(getHeadersDataThunk());
      dispatch(removeLoginData());
    }
  }, [login]);

  useEffect(() => {
    if (users) {
      if (users?.usersDataId) {
        setUserDataId(usersData?.user_id);
      }
    }
  }, [users]);

  // useEffect(() => {
  //   if (OneSignal && headerData?.user?.user_id) {
  //     // get external id
  //     OneSignal.getExternalUserId().then(function (externalUserId) {
  //       // compare data
  //       if (externalUserId !== userDataId) {
  //         // set external id
  //         OneSignal.push(() => {
  //           OneSignal.setExternalUserId(userDataId);
  //         });
  //       }
  //     });
  //   }
  //   // ReactGA.initialize(gaTrackingID);
  // }, [OneSignal, headerData]);

  useEffect(() => {
    setDataCompanies(companyData);
  }, [companies]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledY = window.scrollY;

      // Adjust the threshold as needed
      if (scrolledY > 100) {
        setIsHidden(true); // Add the d-none class
      } else {
        setIsHidden(false); // Remove the d-none class
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalAuth = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleModalCloseAuth = () => {
    setIsModalOpen(false);
  };

  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };

  return (
    <main>
      {/* {isLogin ? <ReactNotification /> : null} */}
      {totalNotif > 0 ? (
        <Helmet>
          <title>
            {`(${totalNotif}) Satukelas - Belajar Manajemen Terlengkap
            Se-Indonesia!`}
          </title>
        </Helmet>
      ) : (
        <Helmet>
          <title>
            {`Satukelas - Belajar Manajemen Terlengkap
            Se-Indonesia!`}
          </title>
        </Helmet>
      )}
      <header
        className={`${
          address.pathname.includes(params.co_id) ||
          address.pathname.includes(params.lp_id)
            ? "header__brevet"
            : "header__nav"
        }`}
      >
        {/* {splitted[1] === '' && (
          <Info
            detailModal={detailModal}
            setDetailModal={setDetailModal}
            seeMoreInformation={seeMoreInformation}
          />
        )} */}

        <div className="btn-toggle my-3">
          {splitted[1] !== "dashboard" ? (
            <div className="search-input__course">
              <div className="d-flex align-items-center">
                {/* {AuthIsLogin("", true) ? (
                  // logo company
                  users.usersDataId?.company_id ===
                    "" ? null : dataCompanies?.logo === "" ? null : (
                      <Link to="/company-profile/">
                        <img
                          alt="logo-company"
                          className="logo_company__size_header"
                          src={dataCompanies?.logo}
                        />
                      </Link>
                    )
                ) : null} */}

                <Link to="/">
                  <div alt="Satukelas" className="satu-kelas-logo__main"></div>
                </Link>
              </div>
              <div className="d-flex align-items-center">
                {/* <span className="icon-search"></span> */}
                <div
                  className={`${
                    AuthIsLogin("", true)
                      ? "search-icon__hover mr-0"
                      : "search-icon__hover mr-3 mr-xl-4"
                  }`}
                  onClick={handleSearch}
                >
                  <img src={search} />
                </div>

                {AuthIsLogin("", true) ? null : (
                  <Link
                    to="#"
                    className="btn btn-primary login-button__header px-3"
                    onClick={handleModalAuth}
                  >
                    Masuk Akun
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <div onClick={() => handleToggleSidebar(true)}>
              <span className="icon-bars"></span>
            </div>
          )}
        </div>

        {/* <Header
          collapsed={collapsed}
          isSidebar={isSidebar}
          searchModal={searchModal}
          handleSearch={handleSearch}
          setSearchModal={setSearchModal}
          mega={mega}
          id={id}
          coursesData={coursesData}
          myRef={myRef}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        /> */}

        <NewHeader
          collapsed={collapsed}
          isSidebar={isSidebar}
          searchModal={searchModal}
          handleSearch={handleSearch}
          setSearchModal={setSearchModal}
          mega={mega}
          id={id}
          coursesData={coursesData}
          myRef={myRef}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          handleModalAuth={isModalOpen}
          handleModalCloseAuth={handleModalCloseAuth}
        />
      </header>
      {address.pathname.includes(params.co_id) ||
      address.pathname.includes(params.lp_id) ? null : (
        <div className="space-padding"></div>
      )}
      {isError ? <InternalServer /> : <InnerComponent />}
      {/* Help button for redirect to bantuan.satukelas.com */}
      {splitted[1] === "dashboard" ? (
        <a href="http://bantuan.satukelas.com">
          <div className="help_button">
            <span className="icon-question_outline"></span>
          </div>
        </a>
      ) : null}
      {!noFooter && <NewFooter />}
      {/* {AuthData()?.userId?.includes("us") && */}
      <TawkMessengerReact
        propertyId="6440ad7e31ebfa0fe7f95a37"
        widgetId="1gueabell"
      />
      {/* } */}
    </main>
  );
};

export default Main;
