import { satukelasApi } from "../constants";

export const FeedbackService = {
  postFeedback(payload) {
    return satukelasApi.post(`/feedbacks`, payload);
  },
  getFeedback(payload) {
    return satukelasApi.get(`/feedbacks?course_id=${payload?.id}&size=${payload?.size}&page=1`);
  },
};
