import React from "react";
import { Image } from "react-bootstrap";
import ctaImage from "../../assets/images/atpi/cta/cta-image.png";

const Cta = () => {
  return (
    <div>
      <h1 className="wording__cta text-center">Gelar Profesi</h1>
      <div className="d-block d-xl-flex">
        <div className="col-12 col-xl-7 d-flex justify-content-center">
          <div>
            <p className="desc__cta">
              Para peserta yang lulus Ujian Sertifikasi Brevet Pajak di Satukelas akan diterbitkan Sertifikat kompetensi dan berhak mengajukan permohonan kepada Pengurus Pusat ATPI untuk diterbitkan sertifikat sebutan profesional sebagai
              berikut:
            </p>
            <ol>
              <li>
                Teknisi Perpajakan Pratama dan Muda dengan sebutan profesional:
                <b>
                  <em> Certified Asociate Tax Technician </em>(CATT)
                </b>
              </li>
              <li>
                Teknisi Perpajakan Madya dengan sebutan profesional: <br />
                <b>
                  <em>Certified Tax Technician </em>(CTT)
                </b>
              </li>
              <li>
                Teknisi Perpajakan Ahli dengan sebutan profesional: <br />
                <b>
                  <em>Certified Profesional Tax Technician </em>(CPTT)
                </b>
              </li>
              <li>
                Ahli Perpajakan tingkat A, B dan C dengan sebutan profesional:
                <b>
                  <em> Certified Tax Advisor </em>(CTA)
                </b>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <div className="mb-3">
            <Image src={ctaImage} width="100%" />
          </div>
          <a href="https://atpi.or.id/" className="link__cta ml-3" target="_blank" rel="noopener noreferrer">
            Kunjungi website ATPI - atpi.or.id
          </a>
        </div>
      </div>
    </div>
  );
};

export default Cta;
