import { Col, Row } from "react-bootstrap";
import EnglishCourseCard from "./EnglishCourseCard";
import React, { useEffect, useState } from "react";
import { LearningPathService } from "../../http/LearningPathHttp";
import { UserService } from "../../http/UserHttp";
import { AuthIsLogin } from "../../helper/auth";
import { membershipIdEnglish } from "../../helper/constant";

export default function CourseList(props) {
  const { setIsLoading, typePage, isSubscribe } = props;
  const [membershipActive, setMembershipActive] = useState(false);
  const [userMembership, setUserMembership] = useState(null);
  const [data, setData] = useState();

  useEffect(() => {
    getMiniBrevetCourse();
    if (AuthIsLogin("", true)) {
      getMembershipUser();
    }
  }, []);

  useEffect(() => {
    if (userMembership?.length > 0) {
      let active = false;
      for (let i = 0; i <= userMembership?.length; i++) {
        if (userMembership[i]?.catalogue_id === membershipIdEnglish && userMembership[i]?.status === "active") {
          active = true;
          break;
        }
      }
      setMembershipActive(active);
    }
  }, [userMembership]);

  const getMembershipUser = async () => {
    setIsLoading(true);
    try {
      const response = await UserService.getMembership();
      if (response) {
        setIsLoading(false);
        setUserMembership(response.memberships);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getMiniBrevetCourse = async () => {
    setIsLoading(true);
    try {
      const response = await LearningPathService.getDetailLearningPath(`${typePage === "minibrevet" ? "?scheme=brevet&size=20" : "?scheme=brevet%20ab%2Bc"}`);
      if (response) {
        setData(response?.items);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  return (
    <Row>
      <Col lg={12}>
        <div className="total-course">
          {data?.length} Pilihan Program Ini title <span>butuh brief</span> wording
        </div>
      </Col>
      {data &&
        data?.map((value, index) => {
          return (
            <Col lg={4} key={index}>
              <EnglishCourseCard status={membershipActive} item={value} type="english" />
            </Col>
          );
        })}
    </Row>
  );
}
