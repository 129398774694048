import React, { useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../assets/css/Courses/pdfloader.css";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

function PDFLoader({ url, title, selected, onSelected, index }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onClickButton(param) {
    if (param === "Prev") {
      let newPageNumber = pageNumber;
      if (newPageNumber <= 1) newPageNumber = numPages;
      else newPageNumber--;
      setPageNumber(newPageNumber);
    } else if (param === "Next") {
      let newPageNumber = pageNumber;
      if (newPageNumber >= numPages) newPageNumber = 1;
      else newPageNumber++;
      setPageNumber(newPageNumber);
    }
  }

  return (
    <div
      className={`pdf-main-div ${selected === index ? "pdf-selected" : ""}`}
      onClick={onSelected}
    >
      <Document
        file={{ url: url }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page
          pageNumber={pageNumber}
          renderMode="canvas"
          height="300"
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  );
}
export default PDFLoader;
