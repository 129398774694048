/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row } from "react-bootstrap";

const Purpose = (props) => {
  return (
    <section className="my-5">
      <div className="container ml-xl-5">
        <div className="col-xl-8 border p-4">
          <h4 className="fw-700">{props.title}</h4>
          <Row>{props.children}</Row>
        </div>
      </div>
    </section>
  );
};

export default Purpose;
