import { Container, Row } from "react-bootstrap";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import { CertificateService } from "../../http/CertificateHttp";
// import thumbnail from './../../assets/images/english/thumbnail.webp';
import CertificateCard from "../../components/CertificateCard";

export default function LearningPathSertificate() {

    const getLearningPathSertificate = async () => {
        try {
            const response = await CertificateService.getDefaultPopular();
            if (response !== undefined) {
                console.log(response)
            }
        } catch (error) {
            console.log(error);
        }
    }

    let dataDummy = {
        course: "Program Bahasa Inggris",
        certificate_url: "./../../assets/images/english/thumbnail.webp",
        course_type: "other"

    }

    return (
        <Container className="dashboard_page__container">
            <BreadcrumbPage title="Learning Path Sertifikat" />
            <div className="dashboard__title_wrapper mb-3">
                <h2 className="dashboard__section_title h2">Learning Path Sertifikat</h2>
            </div>
            <Row>
                {[...Array(4)].map((value, index) => {
                    return (
                        <CertificateCard viewlist="grid" data={dataDummy} />
                    );
                })}
                {/* <MembershipList /> */}
            </Row>
        </Container>
    )
}