import { satukelasApi } from "../constants";
export const AuthService = {
  postRegister(payload) {
    return satukelasApi.post(`/users/student/register`, payload);
  },
  getVerification(param) {
    return satukelasApi.get(`/users/send_verification${param}`);
  },
  postChangePassword(payload) {
    return satukelasApi.post(`/users/change_password`, payload);
  },
  getResetPassword(param) {
    return satukelasApi.get(`/users/send_email_reset${param}`);
  },
  postResetPassword(payload) {
    return satukelasApi.post(`/users/reset_password`, payload);
  },
};
