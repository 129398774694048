import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../assets/css/LearningPath/learningpathsingle.css";
import CourseCard from "../../components/CourseCard";
import BreadcrumbPageNonDashboard from "../../components/BreadcrumbPageNonDashboard";
import LearningPathSection from "../../components/LearningPathSection";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";
import { webinarService } from "../../http/WebinarHttp";

function LearningPathSingle() {
  const [webinarList, setWebinarList] = useState();
  const [konsultasiList, setKonsultasiList] = useState();
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const { path_name } = useParams();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const dataCourse = courses?.coursesData?.items;

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    dispatch(
      getCoursesDataThunk({
        type: "filter",
        param: `?&course_type=pelatihan&category=${
          path_name === "perpajakan" ? "pajak" : path_name
        }&size=12`,
      })
    );
  }, []);
  useEffect(() => {
    webinarListData();
    konsultasiListData();
  }, []);


  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  const webinarListData = async () => {
    try {
      const response = await webinarService.getWebinar(
        path_name === "perpajakan" ? "pajak" : path_name,
        "webinar"
      );
      if (response.items.length > 0) {
        setWebinarList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const konsultasiListData = async () => {
    try {
      const response = await webinarService.getWebinar(
        path_name === "perpajakan" ? "pajak" : path_name,
        "konsultasi"
      );
      if (response.items.length > 0) {
        setKonsultasiList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function renderSplideWebinar(data) {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-4">
          <div className="category-card overflow-visible ">
            <div>
              <Splide
                hasTrack={false}
                options={{
                  rewind: true,
                  perPage: 4,
                  perMove: 1,
                  gap: "1.5rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}
              >
                {data?.items?.length > 0
                  ? data?.items?.map((value, index) => {
                      return (
                        <SplideSlide key={index}>
                          <div style={{ width: "auto" }}>
                            <Row className="item">
                              <CourseCard
                                isWebinar={true}
                                item={value}
                                typeCourse="popular-course"
                                widthFit="fit"
                                key={index}
                              />
                              ;
                            </Row>
                          </div>
                        </SplideSlide>
                      );
                    })
                  : null}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <div className="learning-path__container">
        <Container>
          <Row className="my-4">
            <Col md={7} className="mb-3">
              <div className="learning-path__title-white">
                {path_name === "pengembangan diri"
                  ? "Pengembangan Diri"
                  : path_name.charAt(0).toUpperCase() + path_name.slice(1)}
              </div>
              <div className="learning-path__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md={10}>
            <div className="learning-path__live-black text-center">
              Belajar via Live
            </div>
            <div className="learning-path__live-desc text-center">
              Temukan pengalaman baru belajar bersama instruktur handal dalam
              memberikan materi, Anda dalam memberikan pertanyaan terkait
              konteks dari materi pembelajaran secara langsung.
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-4">
            <div className="learning-path__live-black">Pelatihan Webinar</div>
            <div className="learning-path__live-desc">
              Disini kamu akan belajar mengenai hal-hal bidang yang dapat
              membantu Anda dalam bekerja dan memberikan dampak positif terhadap
              bisnis perusahaan
            </div>
          </Col>
          <Col md={12} className="mb-4">
            <div id="remove_border">
              {loaded ? <>{renderSplideWebinar(webinarList)}</> : null}
            </div>
          </Col>
          <Col md={12} className="mb-4">
            <div className="learning-path__live-black">
              Konsultasi Bersama Instruktur
            </div>
            <div className="learning-path__live-desc">
              Disini kamu akan belajar mengenai hal-hal bidang yang dapat
              membantu Anda dalam bekerja dan memberikan dampak positif terhadap
              bisnis perusahaan
            </div>
          </Col>
          <Col md={12} className="mb-4">
            <div id="remove_border">
              {loaded ? <>{renderSplideWebinar(konsultasiList)}</> : null}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mb-5">
          <Col md={10}>
            <div className="learning-path__live-black text-center">
              Pelatihan Online
            </div>
            <div className="learning-path__live-desc text-center mb-5">
              Temukan pengalaman baru belajar bersama instruktur handal dalam
              memberikan materi, Anda dalam memberikan pertanyaan terkait
              konteks dari materi pembelajaran secara langsung.
            </div>
          </Col>
          {dataCourse?.map((item, index) => {
            return (
              // <Col md={3} key={index}>
              <CourseCard item={item} key={index} />
              // </Col>
            );
          })}
          <Link to="/search/pelatihan" className="btn-redirect__lp">
            Lihat Lebih Banyak
          </Link>
        </Row>
      </Container>
      <LearningPathSection />
    </>
  );
}

export default LearningPathSingle;
