import {
  //get
  COMPANIES_DATA,
  COMPANIES_DATA_ID_SUCCESS,
  COMPANIES_DATA_SUCCESS,
  COMPANIES_DATA_FAIL,
  COMPANIES_DATA_START_FAIL,
} from "../config/CompaniesConfig";

const intialState = {
  companiesData: null,
  companiesDataId: null,
  isLoading: false,
};

export const CompaniesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case COMPANIES_DATA:
      return { ...state, isLoading: true };
    case COMPANIES_DATA_SUCCESS:
      return { ...state, isLoading: false, companiesData: action.value };
    case COMPANIES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, companiesDataId: action.value };
    case COMPANIES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        companiesData: "error",
        companiesDataId: "error",
      };

    default:
      return state;
  }
};
