import { PromiseService } from "../../services/PromiseService";
import { NOTIFICATIONS_DATA_LINK } from "../config/NotificationConfig";
import {
  //get
  getNotificationsData,
  getNotificationsDataSuccess,
  getNotificationsDataIdSuccess,
  getNotificationsDataFail,
} from "../actions/NotificationActions";

export const getNotificationsDataThunk = (data) => async (dispatch) => {
  dispatch(getNotificationsData());
  if (data) {
    try {
      const response = await PromiseService.getData(
        `${NOTIFICATIONS_DATA_LINK}${data?.param}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getNotificationsDataIdSuccess(response.data));
      } else {
        dispatch(getNotificationsDataFail(response));
      }
    } catch (error) {
      dispatch(getNotificationsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(NOTIFICATIONS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getNotificationsDataSuccess(response.data));
      } else {
        dispatch(getNotificationsDataFail(response));
      }
    } catch (error) {
      dispatch(getNotificationsDataFail(error));
    }
  }
};
