import { Col, Image, Row } from "react-bootstrap";
import React from "react";
import icon1 from "./../../assets/images/english/icon1.png";
import icon2 from "./../../assets/images/english/icon2.png";
import icon3 from "./../../assets/images/english/icon3.png";
export default function Benefit() {
  const benefit = [
    {
      icon_benefit: icon1,
      title: "Kurikulum paling relevan",
      desc: "Sesuai dengan CEFR A1-C1",
    },
    {
      icon_benefit: icon2,
      title: ">100 Jam",
      desc: "Materi Belajar Bisa Diakses 12 Bulan",
    },
    {
      icon_benefit: icon3,
      title: "Bersertifikat",
      desc: "Bukti pendukung kompetensi",
    },
  ];
  return (
    <Row>
      {benefit.map((item, idx) => {
        return (
          <Col lg={4} key={idx}>
            <div className="info_wrap">
              <div className="icon">
                <Image src={item.icon_benefit} />
              </div>
              <div className="text">
                <div className="info_title">{item.title}</div>
                <div className="info_desc">{item.desc}</div>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
}
