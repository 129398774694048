//post verify
export const PAYMENTS_VERIFY_DATA_LINK = "payments";
export const PAYMENTS_VERIFY_DATA_POST = "PAYMENTS_VERIFY_DATA_POST";
export const PAYMENTS_VERIFY_DATA_POST_SUCCESS =
  "PAYMENTS_VERIFY_DATA_POST_SUCCESS";
export const PAYMENTS_VERIFY_DATA_POST_FAIL = "PAYMENTS_VERIFY_DATA_POST_FAIL";
//post
export const PAYMENTS_DATA_LINK = "payments/charge";
export const PAYMENTS_DATA_POST = "PAYMENTS_DATA_POST";
export const PAYMENTS_DATA_POST_SUCCESS = "PAYMENTS_DATA_POST_SUCCESS";
export const PAYMENTS_DATA_POST_FAIL = "PAYMENTS_DATA_POST_FAIL";
export const REMOVE_PAYMENTS_DATA = "REMOVE_PAYMENTS_DATA";
