import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

function BreadcrumbPage({ title }) {
  return (
    <div className="mobile_container__breadcrumb">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
            <span className="icon-home"></span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default BreadcrumbPage;
