import {
  PAYMENTS_DATA_POST,
  PAYMENTS_DATA_POST_SUCCESS,
  PAYMENTS_DATA_POST_FAIL,
  PAYMENTS_VERIFY_DATA_POST,
  PAYMENTS_VERIFY_DATA_POST_FAIL,
  PAYMENTS_VERIFY_DATA_POST_SUCCESS,
  REMOVE_PAYMENTS_DATA,
} from "../config/PaymentsConfig";

const intialState = {
  paymentsDataPost: null,
  paymentsVerifyDataPost: null,
  isLoading: false,
};

export const PaymentsReducers = function (state = intialState, action) {
  switch (action.type) {
    case PAYMENTS_DATA_POST:
      return { ...state, isLoading: true };
    case PAYMENTS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, paymentsDataPost: action.value };
    case PAYMENTS_DATA_POST_FAIL:
      return { ...state, isLoading: false, paymentsDataPost: "fail" };
    case REMOVE_PAYMENTS_DATA:
      return { ...state, isLoading: false, paymentsDataPost: null };
    //post
    case PAYMENTS_VERIFY_DATA_POST:
      return { ...state, isLoading: true, paymentsVerifyDataPost: null };
    case PAYMENTS_VERIFY_DATA_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentsVerifyDataPost: action.value,
      };
    case PAYMENTS_VERIFY_DATA_POST_FAIL:
      return { ...state, isLoading: false, paymentsVerifyDataPost: "fail" };

    default:
      return state;
  }
};
