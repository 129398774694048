import React, { useEffect, useState } from "react";
import { Image, Card, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "../../../assets/css/Courses/instructor.css";
import CourseFrontNextButton from "../../CourseFrontNextButton";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthData } from "../../../helper/auth";
import moment from "moment";
import { postCompleteCourseThunk, postCompleteMaterialThunk } from "../../../redux/thunks/CoursesThunks";
import { useParams } from "react-router-dom";
const MySwal = withReactContent(Swal);
function Instructor({ statusUser, course, courses, item, enrollCourse, purchased, continueCourse, isPassed, handleLogin, isWebinar, wbId, webinarsDataId, enrolled, anotherAttempt }) {
  const { course_id } = useParams();
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const [joinMeeting, setJoinMeeting] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (courses?.completeCourseData === "success" && courses?.completeMaterialData === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, `Selamat! Anda telah menyelesaikan Webinar ${title}, Silahkan Klaim Sertifikat Partisipasi Anda!`, true, "Ok");
    }
    if (courses?.completeCourseData === "fail" && courses?.completeMaterialData === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Menyelesaikan Webinar", true, "Oke");
    }
  }, [courses]);

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }
  const handleComplete = (title) => {
    setTitle(title);
    setTrigger(true);
    const payloadC = {
      course_id: course_id,
      user_id: AuthData()?.userId,
    };
    dispatch(postCompleteCourseThunk(payloadC));
    const payload = {
      material_id: wbId,
      user_id: AuthData()?.userId,
    };
    dispatch(postCompleteMaterialThunk(payload));
  };

  return (
    <>
      <Card className="mb-4 instructor-card">
        {/* <div > */}
        {item?.thumbnail && <img alt="thumbnail" className="mb-3 thumbnail-border" src={item?.thumbnail} />}
        {/* </div> */}
        {item?.course_type === "Sertifikasi" ? <div className="instructor-title mb-3">Penguji</div> : <div className="instructor-title mb-3">Instruktur</div>}{" "}
        <div className="instructor_card__layout">
          {item?.instructors?.length > 0 ? (
            item.instructors.map((items, index) => (
              <div key={index} className="my-2 ">
                <div className=" d-flex mr-3 mb-3 align-items-center">
                  <div className="d-flex align-self-start">
                    {items.name === "Admin In-house" ? (
                      <div className="position-relative">
                        <Image className="logo-satukelas__course course_detail__tutor_avatar__white" roundedCircle />
                        <div className="course_initial__instructor"></div>
                      </div>
                    ) : (
                      <div className="position-relative">
                        {items?.avatar === "" ? <Image className="course_detail__tutor_avatar__default" roundedCircle /> : <Image className="course_detail__tutor_avatar__default" src={items?.avatar} roundedCircle />}

                        {items?.avatar === "" ? <div className="course_initial__instructor">{items.name === "Admin In-house" ? "" : items.name.charAt(0)}</div> : null}
                      </div>
                    )}
                  </div>
                  <div id="ul" className="mx-2">
                    <div className="instructor-container d-inline-block float-right">{items.name === "Admin In-house" ? "Satukelas" : items.name}</div>
                    <div className="instructor-division">Instruktur</div>
                  </div>
                </div>
                {items.description && <div className="tutor_description">{items.description}</div>}
              </div>
            ))
          ) : (
            <div className="d-flex flex-column">
              <div className="d-flex mr-3 align-items-center">
                <div className="d-flex align-self-start position-relative">
                  <Image className="logo-satukelas__course course_detail__tutor_avatar__white" roundedCircle />
                  <div className="course_initial__instructor"></div>
                </div>
                <div id="ul" className="mx-2">
                  <div className="instructor-container mr-2  d-inline-block float-right">Satukelas</div>
                  <div className="instructor-division">Instruktur</div>
                  {/* <div className="tutor_description">Lorem ipsum </div> */}
                </div>
              </div>
              <div className="instructor-desc mb-4 mt-4">Eksklusif dari Satukelas</div>
            </div>
          )}
        </div>
        {isWebinar && (
          <>
            <div className="d-flex mb-4">
              <div className="mr-4">
                <div className="webinar_title__time">Waktu Mulai</div>
                <div>{webinarsDataId ? moment(webinarsDataId?.time_starts).format(" HH:mm") + " WIB" : ""}</div>
              </div>
              <div>
                <div className="webinar_title__duration">Durasi Webinar</div>
                <div>{course?.topics && moment(moment(course?.topics[0]?.materials[0]?.time_ends).format("YYYY-MM-DD HH:mm:ss")).diff(moment(course?.topics[0]?.materials[0]?.time_starts).format("YYYY-MM-DD HH:mm:ss"), "hours")} Jam</div>
              </div>
            </div>
            <div className="mt-4 detail_session">Detail Sesi</div>
            {isWebinar && (
              <div className="d-flex mb-5 justify-content-around height-line">
                <div className="d-flex flex-column align-items-center">
                  <div className="step1_session mb-2">
                    <div className="step_text">1</div>
                  </div>
                  <div className="step_desc">Persiapan</div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="step2_session mb-2">
                    <div className="step_text">2</div>
                  </div>
                  <div className="step_desc">Paparan Instruktur</div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="step3_session mb-2">
                    <div className="step_text">3</div>
                  </div>
                  <div className="step_desc">Tanya Jawab</div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="step4_session mb-2">
                    <div className="step_text">4</div>
                  </div>
                  <div className="step_desc">Penutupan</div>
                </div>
                <div className="line_paragrah"></div>
              </div>
            )}
          </>
        )}
        {joinMeeting ? (
          moment().isBetween(moment(course?.topics[0]?.materials[0]?.time_starts).format("YYYY-MM-DD HH:mm:ss"), moment(course?.topics[0]?.materials[0]?.time_ends).format("YYYY-MM-DD HH:mm:ss")) ? (
            <Button className="w-100 btn-complete__webinar" onClick={() => handleComplete(course.title)}>
              Saya Telah Menyelesaikan Webinar
            </Button>
          ) : null
        ) : isWebinar ? null : (
          <CourseFrontNextButton
            main="inline"
            isWebinar={false}
            handleLogin={handleLogin}
            statusUser={statusUser}
            passingPrereq={true}
            item={item}
            purchased={purchased}
            enrollCourse={enrollCourse}
            enrolled={enrolled}
            anotherAttempt={anotherAttempt}
            continueCourse={continueCourse}
            styleButton="btn_satukelas_primary font-size__button"
            isPassed={isPassed}
          />
        )}
      </Card>
    </>
  );
}

export default Instructor;
