import { satukelasApi } from "../constants";

export const webinarService = {
  getWebinar(param, type) {
    return satukelasApi.get(`/webinars?category=${param}&type=${type}`);
  },
  getWebinarById(id) {
    return satukelasApi.get(`/webinars/${id}`);
  },
};
