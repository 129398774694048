import React, { useEffect, useState } from "react";
import "../../assets/css/Courses/RelatedFileSection.css";
import file from "../../assets/images/filehandout.png";
import {
  checkElementMembership,
  isUserMembershipActive,
} from "../../helper/functions";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function SinarmasRelatedFileSection({ handouts, id, membershipData }) {
  const handleSubscribe = () => {
    if (!isUserMembershipActive(membershipData) && membershipData?.length > 0) {
      displayPopup(
        false,
        "Masa Berlaku Membership Anda Telah Habis, Silahkan Kembali Berlangganan",
        true,
        "Kembali"
      );
    } else {
      displayPopup(false, "Anda Belum Berlangganan", true, "Kembali");
    }
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }
  function download(url, filename) {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
  }
  return (
    <div className="related_material__container mb-4">
      <div className="related_file__wrapper">
        {id ? (
          ""
        ) : (
          <div className="related_material__title ">Bahan Belajar</div>
        )}

        {handouts?.length > 0 ? (
          <div className="scroll_related__file">
            {handouts?.map((item, index) => (
              <div key={index} className="related_file__item_wrapper">
                {isUserMembershipActive(membershipData) ? (
                  <a
                    onClick={() =>
                      download(
                        item.url,
                        item?.filename
                          .split("_")[1]
                          .replace(/([a-zA-Z])([A-Z])([a-z])/g, "$1 $2$3")
                      )
                    }
                    className="related_file__filename"
                  >
                    <span className="mr-3">
                      <img alt="file" src={file} />
                    </span>
                    {item?.filename
                      .split("_")[1]
                      .replace(/([a-zA-Z])([A-Z])([a-z])/g, "$1 $2$3")}
                  </a>
                ) : (
                  <div
                    onClick={handleSubscribe}
                    className="related_file__filename"
                  >
                    <span className="mr-3">
                      <img alt="file" src={file} />
                    </span>{" "}
                    {item?.filename
                      .split("_")[1]
                      .replace(/([a-zA-Z])([A-Z])([a-z])/g, "$1 $2$3")}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text_none__data">Belum Tersedia</div>
        )}
      </div>
    </div>
  );
}
export default SinarmasRelatedFileSection;
