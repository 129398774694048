export const ASSETS_DATA_LINK = "/assets/files";
export const ASSETS_DATA = "ASSETS_DATA";
export const ASSETS_DATA_SUCCESS = "ASSETS_DATA_SUCCESS";
export const ASSETS_DATA_FAIL = "ASSETS_DATA_FAIL";

export const ASSETS_DATA_POST = "ASSETS_DATA_POST";
export const ASSETS_DATA_POST_SUCCESS = "ASSETS_DATA_POST_SUCCESS";
export const ASSETS_DATA_POST_FAIL = "ASSETS_DATA_POST_FAIL";

//remove
export const REMOVE_ASSETS_DATA_POST = "REMOVE_ASSETS_DATA_POST";
