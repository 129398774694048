/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Star from "./../../assets/images/home-certificationpage/Star6.png";
import StarSet from "./../../assets/images/home-certificationpage/rating-1-2-2.png";
import rating4 from "./../../assets/images/home-certificationpage/Group179.png";
import rating0 from "./../../assets/images/home-certificationpage/rating-0.png";
import { ratings } from "./Data";
import "./../../assets/css/Certification/CertificationPage.css";
import { Image } from "react-bootstrap";

const Home = (props) => {
  return (
    <section id={props.id}>
      <div className="position-relative">
        <img className="img_certification" src={props.image} alt="Image" />
        <div className=" position-absolute px-md-5 wrapp_content">
          <div className="col-12 col-lg-10 col-xl-7">{props.children}</div>
        </div>
      </div>
    </section>
  );
};

const Header = (props) => {
  const { title, type, children } = props;
  return (
    <div className="row flex-column">
      <div className="d-md-flex justify-content-between align-items-center">
        <h4 className="fw-700 text-white mb-3 mb-md-0">{title}</h4>
        {type === "subscribe" && (
          <span className="mt-2 mt-md-0 mx-md-4 p-2 rounded-pill fw-500 bg__subscribe">
            Berlangganan
          </span>
        )}
      </div>
      <p className="text-white fw-400 text-break mt-3">{children}</p>
    </div>
  );
};

const Content = (props) => {
  return (
    <div className="d-block d-md-flex my-4 content_two">
      <div className="d-flex child_two">
        {props.type === "magang" ? (
          props.image === "" ? (
            props.title === "Admin In-house" ||
            props.title.toLowerCase() === "satukelas" ? (
              <Image className="logo-satukelas__course" roundedCircle />
            ) : (
              <div className="initial-name">{props.title.charAt(0)}</div>
            )
          ) : (
            <Image
              className="logo-satukelas__course"
              roundedCircle
              src={props.image}
            />
          )
        ) : (
          <img src={props.image} alt="" width={50} height={50} />
        )}
        <div>
          <div className="fw-700 text-white hero-brevet-size">
            {props.title}
          </div>
          <p className="fw-300 text-white">{props?.instructur}</p>
        </div>
      </div>
      <div className="ml-5">
        <div className="fw-700 text-white hero-brevet-size">
          {props.durationTitle}
        </div>
        <div className="fw-300 text-white">{props.time}</div>
      </div>
    </div>
  );
};

const Footer = (props) => {
  const { rating, type } = props;
  return (
    <div className="row d-flex justify-content-between">
      {type === "magang" && (
        <div className="d-block mb-3 mb-md-0">
          <p className="text-white">
            Telah dirating oleh{" "}
            {rating && rating.count !== undefined ? rating.count : 150} orang
          </p>
          {rating && rating.score === 0 ? (
            <>
              {[...Array(4)].map((value, index) => {
                return <img className="mr-2" src={rating0} alt="" />;
              })}
            </>
          ) : (
            <>
              {ratings.map((value, index) => {
                return (
                  <img
                    key={index}
                    className="ml-md-n2"
                    src={value.image}
                    alt=""
                  />
                );
              })}
              {rating && rating.count >= 101 ? (
                <img className="ml-md-n2" src={rating4} alt="" />
              ) : (
                <img className="ml-md-n2" src={rating4} alt="" />
              )}
            </>
          )}
        </div>
      )}
      <div className="d-flex align-items-center">
        {rating && rating !== undefined ? (
          <div style={{ gap: "20px" }} className="d-flex align-items-center">
            {rating && rating.score >= 4 && rating.score <= 5 && (
              <>
                {[...Array(4)].map((value, index) => {
                  return <img src={Star} alt="" key={index} />;
                })}
                {rating.score > 4 && rating.score < 5 && (
                  <img src={StarSet} alt="" />
                )}
                {rating.score === 5 && <img src={Star} alt="" />}
              </>
            )}
            {rating && rating.score >= 3 && rating.score < 4 && (
              <>
                {[...Array(3)].map((value, index) => {
                  return <img src={Star} alt="" key={index} />;
                })}
                {rating.score > 3 && <img src={StarSet} alt="" />}
              </>
            )}
            {rating && rating.score >= 2 && rating.score < 3 && (
              <>
                {[...Array(2)].map((value, index) => {
                  return <img src={Star} alt="" key={index} />;
                })}
                {rating.score > 2 && <img src={StarSet} alt="" />}
              </>
            )}
            {rating && rating.score >= 1 && rating.score < 2 && (
              <>
                {[...Array(1)].map((value, index) => {
                  return <img src={Star} alt="" key={index} />;
                })}
                {rating.score > 1 && <img src={StarSet} alt="" />}
              </>
            )}
            {rating && rating.score === 0 && (
              <>
                {[...Array(5)].map((value, index) => {
                  return <img src={Star} alt="" key={index} />;
                })}
                {rating.score > 1 && <img src={StarSet} alt="" />}
              </>
            )}
          </div>
        ) : (
          <div style={{ gap: "20px" }} className="d-flex align-items-center">
            {[...Array(4)].map((value, index) => {
              return <img src={Star} alt="" key={index} />;
            })}
            <img src={StarSet} alt="" />
          </div>
        )}
        <div className="d-flex ml-4 align-items-center">
          <h4 className="fw-700 text-white mb-0 d-flex align-items-end fs-3">
            {rating && rating.score !== undefined ? rating.score : 4.5}{" "}
          </h4>
          <div className="text-white ml-2">
            ({rating && rating.count !== undefined ? rating.count : 150} Orang)
          </div>
        </div>
      </div>
    </div>
  );
};

Home.Header = Header;
Home.Content = Content;
Home.Footer = Footer;
export default Home;
