import { satukelasApi } from "../constants";

export const DiplomaService = {
  getDiplomaByUserId(user_id) {
    return satukelasApi.get(
      `/diplomas?user_id=${user_id}`
    );
  },
  uploadDiploma(diploma_type, user_id, formData) {
    return satukelasApi.post(
      `/diplomas?diploma_type=${diploma_type}&user_id=${user_id}`,
      formData
    );
  },
};
