import React from "react";
import { ListGroup, Card } from "react-bootstrap";
import "../../assets/css/Courses/CardBenefit.css";

function CardBenefit({ objective }) {
  return (
    <>
      <Card className="course_front__benefit_container mb-4">
        <div className="course_front__kurikulum_title ">
          Tujuan Pembelajaran
        </div>
        <ListGroup>
          {objective?.length > 0 ? (
            objective?.map((listing, index) => (
              <div
                key={index}
                className="d-flex align-items-start course_front__benefit_item__wrapper"
              >
                {/* <span className="fw-700 mr-3">✓</span> */}
                <div
                  className="course_front__benefit_item"
                  dangerouslySetInnerHTML={{ __html: listing }}
                />
              </div>
            ))
          ) : (
            <div className="text_none__data">Belum Tersedia</div>
          )}
        </ListGroup>
      </Card>
    </>
  );
}

export default CardBenefit;
