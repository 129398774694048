import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BreadcrumbPageNonDashboard from "../../components/BreadcrumbPageNonDashboard";
import CourseCard from "../../components/CourseCard";
// import { saveAs } from 'file-saver'
import { AuthData, AuthIsLogin } from "../../helper/auth";
import { slugify } from "../../helper/functions";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { UserService } from "../../http/UserHttp";
import { LearningPathService } from "../../http/LearningPathHttp";
import axios from "axios";
import { CERTIFICATES_DATA_LINK_DOWNLOAD } from "../../redux/config/CertificatesConfig";
import { PromiseService } from "../../services/PromiseService";
import moment from "moment";
// import axios from "axios";

function DownloadCertificate() {
  const { course_name, id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [downloadCertificate, setDownloadCertificate] = useState();
  const [modalSuccessDownload, setModalSuccessDownload] = useState(false);
  const [modalContinued, setModalContinued] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [urlCertificate, setUrlCertificate] = useState();
  const [learningPathData, setLearningPathData] = useState();
  const [currCertificate, setCurrCertificate] = useState();
  const [isBrevetCourse, setIsBrevetCourse] = useState(false);
  const [isCertif, setIsCertif] = useState(false);
  // const [triggerTimeout, setTriggerTimeout] = useState();
  const [myCertificateData, setMyCertificateData] = useState();
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  const courseData = courses?.coursesDataId;
  const userData = users?.usersDataId;

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    let certifFlag = downloadCertificate?.course_type
      ? downloadCertificate?.course_type === "Sertifikasi"
      : courseData?.course_type === "Sertifikasi";
    if (certifFlag) {
      setIsCertif(true);
    } else {
      setIsCertif(false);
    }
  }, [downloadCertificate, courseData]);

  useEffect(() => {
    if (users) {
      dispatch(getUsersDataThunk(AuthData().userId));
    }
  }, []);

  useEffect(() => {
    console.log(downloadCertificate, courseData);
  }, [downloadCertificate, courseData]);

  useEffect(() => {
    if (id.includes("co")) {
      dispatch(getCoursesDataThunk({ type: "id", param: `/${id}` }));
      setIsBrevetCourse(true);
    } else if (id.includes("lp")) {
      getDetailLearningPathData(id);
    }
  }, []);

  useEffect(() => {
    let myCertificate = users?.usersDataId?.my_certificates;
    let nameCourse = myCertificate?.map((item) => {
      return slugify(item?.course);
    });
    setMyCertificateData(nameCourse);
  }, [users]);

  useEffect(() => {
    if (myCertificateData !== undefined) {
      if (myCertificateData?.includes(course_name)) {
        history.push(`/download/${course_name}/${id}`);
      } else {
        history.push("/");
      }
    }
  }, [myCertificateData]);

  useEffect(() => {
    getCertificates();
  }, [isBrevetCourse]);

  useEffect(() => {
    if (downloadCertificate) {
      setUrlCertificate(downloadCertificate.certificate_url);
    }
  }, [downloadCertificate]);

  const getDetailLearningPathData = async (id) => {
    dispatch(loadingStart());
    try {
      const response = await LearningPathService.getDetailLearningPath(
        `/${id}`
      );
      if (response) {
        dispatch(loadingFinish());
        setLearningPathData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCertificates = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getCertificate();
      const listCert = response.my_certificates;
      // const groupedCert = { ...myCertificates };

      if (listCert && trigger) {
        let dataCertificate;
        if (isBrevetCourse) {
          dataCertificate = listCert?.map((item) => item?.course_id);
        } else {
          if (id.includes("lp")) {
            dataCertificate = listCert?.map((item) => item?.learning_path_id);
          } else {
            dataCertificate = listCert?.map((item) => item?.certificate_id);
          }
        }

        let currIdx = dataCertificate?.indexOf(id);
        setCurrCertificate(listCert);
        setDownloadCertificate(listCert[currIdx]);
        setTrigger(false);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  async function downloadFile(id) {
    try {
      const response = await PromiseService.getImageData(
        `${CERTIFICATES_DATA_LINK_DOWNLOAD}/${id}`
      );
      if (response?.status == 200) {
        let srcValue = "data:image/png;base64," + response.data;

        const link = document.createElement("a");
        link.href = srcValue;
        link.download = `${moment().format("YYYYMMDD")}${id}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickShowDone = (e) => {
    setModalSuccessDownload(true);
  };
  const handleContinue = () => {
    setModalSuccessDownload(false);
    history.push();
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <BreadcrumbPageNonDashboard
            type={
              isBrevetCourse
                ? courseData?.course_type
                : downloadCertificate?.course_type
                ? downloadCertificate.course_type
                : "Sertifikasi"
            }
            slugify={slugify}
            title={
              isBrevetCourse
                ? courseData?.title
                : downloadCertificate?.course
                ? downloadCertificate.course
                : learningPathData?.title
                ? learningPathData.title
                : downloadCertificate?.learning_path
            }
            course_id={
              isBrevetCourse
                ? courseData?.course_id
                : learningPathData?.path_id
                ? learningPathData.path_id
                : downloadCertificate?.course_id
            }
            lastText="Unduh Sertifikat"
          />
        </Col>
        <Col md={12}>
          <div className="certificate-desc__title">Sertifikat Anda</div>
        </Col>
      </Row>
      <Row className="certificate-container__download">
        <Col md={7}>
          {downloadCertificate?.certificate_url ? (
            <Image
              className="w-100"
              src={downloadCertificate?.certificate_url}
            />
          ) : (
            <div className="d-flex flex-row align-items-center justify-content-center h-100">
              <p>
                Sertifikat dalam proses pembuatan oleh sistem, silahkan cek lagi
                dalam waktu 15 menit di{" "}
                <a href="/dashboard/certificate">dashboard sertifikat saya</a>
              </p>
            </div>
          )}
        </Col>
        <Col md={5}>
          <div className="certificate-desc__wrapper">
            <div>
              Judul Sertifikat:{" "}
              {isBrevetCourse
                ? courseData?.title
                : downloadCertificate?.course
                ? downloadCertificate.course
                : downloadCertificate?.learning_path}{" "}
            </div>
            <div>
              Tipe:{" "}
              {isBrevetCourse
                ? courseData?.course_type
                : downloadCertificate?.course_type
                ? downloadCertificate.course_type
                : "Sertifikasi"}
            </div>
            <div>
              Mulai Sertifikasi:{" "}
              {isBrevetCourse ? "-" : downloadCertificate?.created_at}
            </div>
            <div>Penerima: {userData?.name}</div>
            <div>
              Diterbitkan oleh:{" "}
              {courseData?.instructors?.length > 0
                ? courseData?.instructors[0]?.name
                : "Satukelas"}
            </div>
          </div>
          <div>
            {isCertif ? (
              <Button
                onClick={() => {
                  downloadFile(downloadCertificate?.certificate_id);
                  history.push(
                    `/dashboard/certificate?${
                      isBrevetCourse
                        ? courseData?.course_id
                        : learningPathData?.path_id
                        ? learningPathData.path_id
                        : downloadCertificate?.certificate_id
                    }`
                  );
                }}
                className="certificate-btn__send mr-3 mb-3 w-100"
              >
                Kirim + Unduh Sertifikat
              </Button>
            ) : (
              <Button
                onClick={handleContinue}
                className="certificate-btn__send mr-3 mb-3 w-100"
              >
                Selesai
              </Button>
            )}
            <Button
              onClick={() => {
                handleClickShowDone();
                downloadFile(downloadCertificate?.certificate_id);
              }}
              className="certificate-btn__download w-100"
              style={{ backgroundColor: "#0f59c7 !important" }}
            >
              Unduh Sertifikat
            </Button>
          </div>
        </Col>
      </Row>
      <Modal
        show={modalSuccessDownload}
        centered
        onHide={() => setModalSuccessDownload(false)}
        size="lg"
      >
        <Modal.Body>
          <div>
            <div className="text-center mb-4" style={{ fontSize: "80px" }}>
              🎁🎉
            </div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "36px",
                textAlign: "center",
                color: "#383838",
              }}
            >
              Sertifikat Berhasil Diunduh
            </div>
            <div className="text-center mt-4">
              <Button
                style={{ width: "300px" }}
                onClick={handleContinue}
                className="certificate-btn__download"
              >
                Selanjutnya
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalContinued}
        centered
        onHide={() => setModalContinued(false)}
        size="xl"
      >
        <Modal.Body>
          <div>
            <div
              className="mb-4"
              style={{
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "36px",
                textAlign: "center",
                color: "#383838",
              }}
            >
              Rekomendasi Pelatihan Untuk Anda
            </div>
            {/* <Container> */}
            <Row className="justify-content-center">
              {courseData?.related_material?.slice(0, 3).map((item, index) => {
                return (
                  <CourseCard
                    key={index}
                    typeCourse="popular-course"
                    widthFit="fit"
                    item={item}
                    hidden={true}
                    isCourseRelate={true}
                  />
                );
              })}
            </Row>
            {/* </Container> */}
            <div className="text-center mt-4">
              <Button
                href="/dashboard/course"
                className="dashboard-btn__download mr-3"
              >
                Kembali ke Dashboard
              </Button>
              <Button
                href="/search/pelatihan/"
                className="seemore-btn__download"
              >
                Lihat Pelatihan Lainnya
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
export default DownloadCertificate;
