import { Col, Container, Image, Row } from "react-bootstrap";
import './../assets/css/terbaru.css'
import thumb from './../../src/assets/images/thumb.png';
export default function Terbaru() {
    return (
        <>
            <div className="terbaru">
                <Container>
                    <Row className="terbaru-wrapper">
                        <Col lg={6} className="terbaru-content">
                            <div className="terbaru-title">
                                Terbaru dari Satukelas! ✨
                            </div>
                            <div className="terbaru-subtitle">
                                Dapatkan Akses Belajar Sambil Berjualan dengan Keuntungan Menarik!
                            </div>
                            <a href="https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas" className="terbaru-cta" target="_blank">
                                KONSULTASI GRATIS
                            </a>

                        </Col>
                        <Col lg={6} className="position-relative">
                            <Image src={thumb} className="terbaru-image" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="w-100" id="terbaru">
                <iframe src="https://tanyasatukelas.tawk.help/category/terbaru" className="w-100" />
            </div>
            {/* <Container className="my-4">
                <Row>
                    <Col lg={12}>
                    </Col>
                </Row>
            </Container> */}
        </>
    )
}