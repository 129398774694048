import React from "react";
import { Image } from "react-bootstrap";
import iconTiktik from "../../assets/images/atpi/apindo/icon-tiktik-apindo.png";
import iconGalih from "../../assets/images/atpi/apindo/icon-galih-apindo.png";
import bgCertifApindoDesktop from "../../assets/images/atpi/apindo/certif-apindo.png";
import bgCertifApindo1Mobile from "../../assets/images/atpi/apindo/certif-apindo-1.png";
import bgCertifApindo2Mobile from "../../assets/images/atpi/apindo/certif-apindo-2.png";
const CertificateApindo = () => {
  return (
    <div className="position-relative d-block d-lg-flex align-items-center justify-content-end">
      <div className="col-12 col-lg-7 d-flex justify-content-xl-center">
        <h1 className="woding__certificate-apindo">
          Sertifikat Kompetensi BNSP <em>Certified Human Resource Officer & Digital Marketing</em> dari LSP APINDO
        </h1>
      </div>
      <div className="col-12 col-lg-5">
        <div className="d-lg-flex justify-content-end mt-5 position-relative d-none ml-5" style={{ zIndex: "2" }}>
          <Image src={bgCertifApindoDesktop} width="100%" height="95%" style={{ marginLeft: "-30px" }} />
          <div className="icon__galih-apindo">
            <Image src={iconGalih} width="80%" height="100%" />
          </div>
        </div>
        <div className="d-lg-none d-block mt-5">
          <div className="mb-3 d-flex justify-content-center">
            <Image src={bgCertifApindo1Mobile} className="w-100 w-md-50-apindo" height="100%" />
          </div>
          <div className="mb-3 d-flex justify-content-center">
            <Image src={bgCertifApindo2Mobile} className="w-100 w-md-50-apindo" height="100%" />
          </div>
        </div>
      </div>
      <div className="bg__tiktik-certificate-apindo d-none d-xl-block">
        <Image src={iconTiktik} width="100%" height="100%" />
      </div>
    </div>
  );
};

export default CertificateApindo;
