import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import "../../assets/css/Account/index.css";
import { verifyTokenThunk } from "../../redux/thunks/UsersThunks";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { WEB_URL } from "./../../helper/constant";

function Verify() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [status, setStatus] = useState();

  useEffect(() => {
    const parameter = new URLSearchParams(location.search);
    const token = parameter.get("token");
    if (token) {
      dispatch(verifyTokenThunk(token));
    } else history.push("/not-found/");
  }, []);

  useEffect(() => {
    if (users.usersVerify?.status === "success") {
      setStatus(200);
    }
    if (users.usersVerify?.status === "error") {
      const msgtemp = users.usersVerify?.response?.error?.response?.data;
      if (msgtemp?.detail) {
        setStatus(msgtemp.status);
      } else history.push("/not-found/");
    }
  }, [users]);

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={10} md={4} lg={4}>
          <div className="text-center">
            <div className="login-title">Verifikasi</div>
          </div>
          {status === 200 ? (
            <>
              <div>
                Email anda telah terverifikasi. Silakan login untuk melanjutkan.
              </div>
              <div className="d-flex">
                <Button className="btn-primary mr-1" href={`${WEB_URL}login`}>
                  Login sebagai student
                </Button>
                <Button className="btn-primary ml-1" href={`${WEB_URL}login`}>
                  Login sebagai admin
                </Button>
              </div>
            </>
          ) : status === 401 ? (
            <div>
              Token Expired. Silahkan <a href="/email-verified/">kirim</a> ulang
              verifikasi
            </div>
          ) : (
            <div>Mohon menunggu...</div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Verify;
