import React, { useEffect, useState } from "react";
import { Col, Table, Button, Form, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Quiz/quizresult.css";
import { Link, useParams } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { editAuthData, AuthData } from "../../helper/auth";
import { DM_COURSE_ID, DM_COURSE_ID_POST } from "../../helper/constant";
import {
  getUsersDataThunk,
  putUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
import Swal from "sweetalert2";
import starDisable from "./../../assets/images/star-disable.png";
import star from "./../../assets/images/star.png";
import withReactContent from "sweetalert2-react-content";
import { postCompleteCourseThunk } from "../../redux/thunks/CoursesThunks";
import { removeCompleteState } from "../../redux/actions/CoursesActions";
import { FeedbackService } from "../../http/FeedbackHttp";
import { SkValidate } from "../common/SkValidate";
const MySwal = withReactContent(Swal);

function QuizResult({
  quizScore,
  displayResult,
  resetFlags,
  onComplete,
  quizDataId,
  getNextLink,
  material,
  quizPass,
  setMaterial,
  detailData,
  congratsPage,
  toCongratsPage,
}) {
  let total = 0;
  const { course_id, id_material, material_type, slug, slug_m } = useParams();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [titleName, setTitleName] = useState();
  const [idSelected, setIdSelected] = useState();
  const [urlVideo, setUrlVideo] = useState();
  const [postComplete, setPostComplete] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  const quizzes = useSelector((state) => state.quizzes);
  const course = courses.coursesDataId;
  const userData = users?.usersDataId;
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    console.log(quizScore);
  }, [quizScore]);

  function testPass() {
    // confirm to pass the test
    const req = {};
    const bnspStatus =
      course.course_id === DM_COURSE_ID
        ? 2
        : course.course_id === DM_COURSE_ID_POST
        ? 4
        : 0;
    req.body = { is_bnsp: bnspStatus };
    req.id = AuthData().userId;
    dispatch(putUsersDataThunk(req));
    editAuthData("isBnsp", bnspStatus);
    setTrigger(true);
  }

  useEffect(() => {
    if (users.usersDataPut === "error" && trigger) {
      setTrigger(false);
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      if (course.course_id === DM_COURSE_ID)
        history.push("/bnsp/agreement-user");
      else if (course.course_id === DM_COURSE_ID_POST)
        history.push("/bnsp/complete");
    }
  }, [users]);
  useEffect(() => {
    if (postComplete) {
      const payloadC = {
        course_id: course_id,
        user_id: AuthData().userId,
      };
      dispatch(postCompleteCourseThunk(payloadC));
    }
  }, [postComplete]);
  useEffect(() => {
    if (
      courses.completeMaterialData === "success" ||
      courses.completeCourseData === "success"
    ) {
      dispatch(removeCompleteState());
      if (history.location.pathname.includes("/techconnect/")) history.go(0);
      else history.push(`/download/${slug}/${course_id}/`);
    }
    setMaterial(id_material.split("_"));
  }, [courses.completeMaterialData, courses.completeCourseData]);

  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  function checkClaimCertButtonVisibility() {
    if (material_type === "quiz") {
      return (
        quizzes.quizDataId?.attempts?.length > 0 &&
        quizzes.quizDataId?.attempts[0]?.status != "ongoing"
      );
    } else return true;
  }

  const constraints = {
    review_suggestion: {
      presence: true,
      length: {
        minimum: 20,
      },
    },
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const generateCertifHandler = async (e) => {
    e.preventDefault();
    const form = e.target;
    let payload = {};
    payload.user_id = AuthData().userId;
    payload.course_id = course_id;
    payload.review_suggestion = data?.review_suggestion;
    payload.score_satisfaction = idSelected === undefined ? 5 : idSelected;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      try {
        const response = await FeedbackService.postFeedback(payload);
        if (response !== undefined) {
          setPostComplete(true);
        }
      } catch (error) {
        displayPopup(false, false, error?.data?.detail, true, "Ok");
        setPostComplete(true);
        // const payloadC = {
        //   course_id: course_id,
        //   user_id: AuthData().userId,
        // };
        // dispatch(postCompleteCourseThunk(payloadC));
      }
    }
  };
  /**
   * @function made a function for give an feedback to course
   * @param {*} e
   * @param {*} idx
   */
  const starRatingHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(id);
  };
  /**
   * @function made a function for give an feedback to course
   * @param {*} e
   * @param {*} idx
   */
  const starRatingDisableHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(idSelected + id);
  };

  const handleOpenVideo = (url) => {
    setUrlVideo(url);
    handleShow();
  };

  function displayPopup(cancel, cancelMsg, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      cancelButtonText: cancelMsg,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (value.isConfirmed) {
        if (history.location.pathname.includes("/techconnect/")) history.go(0);
        else history.push(`/download/${slug}/${course_id}/`);
        // history.push(`/course/${slug}/${course.course_id}`);
      }
    });
  }
  return (
    <>
      <Col
        sm={12}
        lg={12}
        className="quiz-quest-main-col d-flex flex-column justify-content-start align-items-start"
      >
        {displayResult ? (
          <>
            <div className="done-quiz__container w-100 mb-4">
              <div>
                {/* <Card.Title className="quiz-details-title">
                  {course?.course_type === "Try Out"
                    ? "Hasil Try Out"
                    : `Hasil ${course?.course_type}`}
                </Card.Title> */}

                {congratsPage === 0 ? (
                  <div className="text-center w-100">
                    <div className="mb-3" style={{ fontSize: "80px" }}>
                      {quizScore?.result === "Pass" ? <>🎁🎉</> : <>😞📈</>}
                    </div>
                    <div className="text-center quiz-details-subtitle">
                      Skor {quizDataId.topic} Anda
                    </div>
                    <div
                      className={`text-center quiz-details-subtitle ${
                        quizScore?.result === "Pass" ? "green-text" : "red-text"
                      }`}
                    >
                      {quizScore.earned_mark}
                    </div>

                    {detailData?.title.toLowerCase() !== "pre test" &&
                      quizScore?.result !== "Pass" && (
                        <>
                          <div className="desc-fail my-4">
                            Kegagalan bukanlah akhir dari segalanya, melainkan
                            sebuah kesempatan untuk memperbaiki dan
                            mengembangkan kemampuan Anda. Teruslah belajar dari
                            pengalaman dan tetap konsisten dalam menjalankan
                            tugas Anda sebagai profesional. Dengan begitu, Anda
                            akan mampu memberikan layanan terbaik bagi klien
                            Anda.
                          </div>
                          <div>
                            Jika anda ingin mencoba kembali, cobalah di fitur
                            tryout
                          </div>
                          <Button
                            style={{ width: "fit-content" }}
                            onClick={resetFlags}
                            className="button-next px-4"
                          >
                            {/* Lihat T {detailData.title} */}
                            Klik Disini!
                          </Button>

                          {/* <Button style={{ width: 'fit-content' }} onClick={resetFlags} className="button-next mb-4 px-4">
                            Ulangi {detailData.title}
                          </Button> */}
                        </>
                      )}

                    {course?.topics[course?.topics?.length - 1]?.materials[
                      course?.topics[course?.topics?.length - 1]?.materials
                        ?.length - 1
                    ]?.title === detailData?.title ? (
                      <>
                        {quizPass && quizScore?.result === "Pass" && (
                          <Button
                            onClick={() => toCongratsPage(1)}
                            className="button-next"
                          >
                            Lanjutkan
                          </Button>
                        )}
                      </>
                    ) : (
                      <Link
                        to={`/${
                          history.location.pathname.includes("/techconnect/")
                            ? "techconnect"
                            : "course"
                        }/${slug}/${course_id}${getNextLink(material)}`}
                        onClick={onComplete}
                        className="next-button mt-0"
                      >
                        {getNextLink(material) && quizPass && (
                          <Button onClick={resetFlags} className="button-next">
                            Selanjutnya
                          </Button>
                        )}
                      </Link>
                    )}
                  </div>
                ) : congratsPage === 1 ? (
                  <div className="text-center w-100">
                    <div className="mb-3" style={{ fontSize: "120px" }}>
                      🎉
                    </div>
                    <div className="text-center congrats-details-title">
                      Selamat, {userData?.name}!
                    </div>

                    <div className="desc-fail my-4">
                      Anda telah berhasil lulus ujian di SatuKelas.com. Kami
                      sangat bangga dengan prestasi yang Anda raih! Kami
                      berharap bahwa kesuksesan ini akan memberikan motivasi
                      baru dalam perjalanan belajar Anda. Jangan pernah berhenti
                      belajar dan teruslah berusaha menjadi yang terbaik!
                    </div>
                    <div>
                      Terima kasih atas partisipasi Anda dalam layanan kami.
                      <br />
                      <br />
                      Salam, Tim SatuKelas
                    </div>

                    {course?.topics[course?.topics?.length - 1]?.materials[
                      course?.topics[course?.topics?.length - 1]?.materials
                        ?.length - 1
                    ]?.title === detailData?.title ? (
                      <>
                        {quizPass ? (
                          <Button
                            onClick={() => toCongratsPage(2)}
                            className="button-next half"
                          >
                            Lanjutkan
                          </Button>
                        ) : (
                          <Button onClick={resetFlags} className="button-next">
                            Ulangi {detailData.title}
                          </Button>
                        )}
                      </>
                    ) : (
                      <Link
                        to={`/${
                          history.location.pathname.includes("/techconnect/")
                            ? "techconnect"
                            : "course"
                        }/${slug}/${course_id}${getNextLink(material)}`}
                        onClick={onComplete}
                        className="next-button mt-0"
                      >
                        {getNextLink(material) && quizPass && (
                          <Button onClick={resetFlags} className="button-next">
                            Selanjutnya
                          </Button>
                        )}
                      </Link>
                    )}
                  </div>
                ) : congratsPage === 2 ? (
                  <div className="text-center w-100">
                    <div className="text-center feedback-details-title mb-4">
                      Satu langkah untuk
                      <br /> mendapatkan sertifikat
                    </div>

                    <div className="d-flex flex-row justify-content-center my-4 mb-5">
                      {/* this condition will be start when user want to select the score */}
                      <>
                        {idSelected !== undefined &&
                          [...Array(idSelected)].map((item, i) => {
                            return (
                              <div onClick={(e) => starRatingHandler(e, i)}>
                                <Image
                                  className="mx-1"
                                  style={{ width: "32px" }}
                                  src={star}
                                />
                              </div>
                            );
                          })}
                      </>

                      {/* this condition will be start if the score under 5 */}
                      <>
                        {idSelected !== undefined &&
                          [...Array(5 - idSelected)].map((item, i) => {
                            return (
                              <div
                                onClick={(e) => starRatingDisableHandler(e, i)}
                              >
                                <Image
                                  className="mx-1"
                                  style={{ width: "32px" }}
                                  src={starDisable}
                                />
                              </div>
                            );
                          })}
                      </>
                      {/* this condition will be start if idSelected have a undefined value */}
                      {idSelected === undefined &&
                        [...Array(5)].map((value, index) => {
                          return (
                            <div onClick={(e) => starRatingHandler(e, index)}>
                              <Image
                                className="mx-1"
                                style={{ width: "32px" }}
                                src={star}
                              />
                            </div>
                          );
                        })}
                    </div>
                    <Form onSubmit={generateCertifHandler}>
                      {/* <Form.Group> */}
                      {/* </Form.Group> */}
                      <div className="mb-4 form-group">
                        <Form.Control
                          style={{ margin: "0 auto " }}
                          className="py-4 px-5 w-75 feedback-input d-flex"
                          as="textarea"
                          rows={5}
                          placeholder="Isi feedback disini, Contoh: course ini sangat berguna untuk saya terimakasih!"
                          id="review_suggestion"
                          name="review_suggestion"
                          onChange={handleChange}
                        />
                        <div className="messages"></div>
                      </div>

                      {course?.course_type === "Studi Kasus" ||
                      course?.course_type ===
                        "Try Out" ? null : course.course_id === DM_COURSE_ID ||
                        course.course_id ===
                          DM_COURSE_ID_POST ? null : getNextLink(material) ===
                        `/${material_type}/${slug_m}/${id_material}` ? (
                        <>
                          {checkClaimCertButtonVisibility() ? (
                            <Button className="button-next" type="submit">
                              Kirim dan Unduh Sertifikat
                            </Button>
                          ) : null}
                        </>
                      ) : null}

                      {/* <Button className="button-next" type="submit">
                        Kirim dan Unduh Sertifikat
                      </Button>
                     */}
                    </Form>
                  </div>
                ) : null}
              </div>
              {/* <Button
                className="mb-3"
                disabled
                variant={`${
                  quizScore.result === "Fail" ? "danger" : "success"
                }`}
              >
                {course.course_id === DM_COURSE_ID ||
                course.course_id === DM_COURSE_ID_POST ? (
                  <>
                    {quizScore.result === "Fail" ? (
                      "Anda gagal, coba lagi di batch selanjutnya ya!"
                    ) : (
                      <div className="redirected_mou" onClick={testPass}>
                        Anda Lulus, Silahkan klik tombol ini
                      </div>
                    )}
                  </>
                ) : (
                  <>{quizScore.result === "Fail" ? "Gagal" : "Lulus"}</>
                )}
              </Button> */}
            </div>
            <div
              className="quiz-quest-wrapper-scrollbar"
              style={{ width: "100%" }}
            >
              {course?.course_type?.toLowerCase() === "try out" ? (
                <Table responsive striped hover style={{ cursor: "default" }}>
                  <thead>
                    <tr>
                      {/*<th>Tipe</th>*/}
                      <th>No</th>
                      <th>Pertanyaan</th>
                      <th>Jawaban yang Diberikan</th>
                      <th>Status</th>
                      <th>Pembahasan Terkait</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizScore.answers.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{value.number}</td>
                          <td>{value.question}</td>
                          <td>{value.answer}</td>
                          <td>
                            <Button
                              disabled
                              variant={`${
                                value.value === "Correct" ? "success" : "danger"
                              }`}
                              style={{ width: "100%" }}
                            >
                              {value.value === "Correct" ? "Benar" : "Salah"}
                            </Button>
                          </td>
                          <td>
                            {value?.video_url ? (
                              <a
                                href="javascript:void()"
                                onClick={() =>
                                  handleOpenVideo(value?.video_url)
                                }
                              >
                                Lihat Pembahasan
                              </a>
                            ) : (
                              <p>Video Tidak Tersedia</p>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : null}
            </div>
            {/* {course.course_id === DM_COURSE_ID ||
            course.course_id === DM_COURSE_ID_POST ? (
              <Button className="mr-2" href="/bnsp/bnsp-digital-marketing">
                Kembali
              </Button>
            ) : (
              <div
                className="d-flex justify-content-between align-items-center "
                style={{ height: "100%", width: "100%" }}
              >
                <Card.Title className="quiz-details-title">
                  {course?.course_type} Selesai
                </Card.Title>
                <div>
                  <Button className="mr-2" onClick={resetFlags}>
                    Halaman Awal {course?.course_type}
                  </Button>
                  <Button
                    className="mr-2"
                    onClick={() =>
                      handleBack(course?.course_type, slugify(course?.title))
                    }
                  >
                    Keluar {course?.course_type}
                  </Button>
                </div>
              </div>
            )} */}
          </>
        ) : (
          ""
        )}
      </Col>
      {course.course_type === "Try Out" ? null : course.course_id ===
          DM_COURSE_ID || course.course_id === DM_COURSE_ID_POST ? null : (
        <Col>{/* <PrerequisitesCourse prereq={course.prerequisites} /> */}</Col>
      )}
      {/* using if user lulus from certificate */}

      {/* <Col lg={12}>
        <CertificateCard />
      </Col> */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        className={"modal-transparent"}
      >
        {/* <Modal.Header
          closeButton
          style={{ backgroundColor: "transparent", border: "transparent" }}
        ></Modal.Header> */}
        <Modal.Body>
          <div onClick={handleClose} className="btn-close__pembahasan">
            <span>×</span> Tutup
          </div>
          <div className={"w-100 h-100 d-flex align-items-center"}>
            <iframe src={urlVideo} className="iframe-transparent"></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default QuizResult;
