import React, { useRef, useEffect } from "react";
import "../../assets/css/Courses/videoloader.css";

function VideoLoader({ url, title, type }) {
  const videoPlayer = useRef(null);
  // adjust dyntube url
  // let formattedUrl = (type==="video_dyntube" ? url.replace("videos", "iframe") : url);
  let formattedUrl = url;

  useEffect(() => {
    videoPlayer.current.ownerDocument.fullscreen = true;
  }, [videoPlayer.current]);

  return (
    <div className="container lesson-container">
      {/* <div className="video-loader-title mt-3 mb-3 text-left">{title}</div> */}
      <div
        className={`dyntube-iframe-container ${type === "video_h5p" && "h5p"}`}
      >
        <iframe
          ref={videoPlayer}
          className="dyntube-responsive-iframe"
          webkitallowFullscreen
          mozallowFullscreen
          allowFullScreen
          src={formattedUrl}
          onLoad={() => {
            console.log("onLoad");
          }}
        ></iframe>
      </div>
    </div>
  );
}
export default VideoLoader;
