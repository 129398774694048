import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const FormPromise = (props) => {
  const { typePage } = props;
  const [popup, setPoup] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    no_telp: "",
    company_name: "",
    school_name: "",
    business_sector: "",
    position: "",
  });

  useEffect(() => {
    let errorMessage = "";

    if (formData.name === "") {
      errorMessage = "Nama Anda";
    }
    if (formData.email === "") {
      errorMessage += errorMessage === "" ? "Email Anda" : ", Email Anda";
    }
    if (formData.no_telp === "") {
      errorMessage += errorMessage === "" ? "Nomor Handphone Anda" : ", Nomor Handphone Anda";
    }

    if (typePage === "cdlc") {
      if (formData.company_name === "") {
        errorMessage += errorMessage === "" ? "Nama Perusahaan Anda" : ", Nama Perusahaan Anda";
      }
    }

    if (typePage === "dlcs") {
      if (formData.school_name === "") {
        errorMessage += errorMessage === "" ? "Asal Sekolah Anda" : ", Asal Sekolah Anda";
      }
    } else {
      if (formData.business_sector === "") {
        errorMessage += errorMessage === "" ? "Bidang Perusahaan Anda" : ", Bidang Perusahaan Anda";
      }
    }

    if (formData.position === "") {
      errorMessage += errorMessage === "" ? "Posisi Anda" : ", Posisi Anda";
    }
    setErrMsg(errorMessage);
  }, [formData]);
  const handlePopup = () => {
    setPoup(!popup);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, no_telp, company_name, business_sector, position, school_name } = formData;
    let whatsappMessage = "";
    if (typePage === "cdlc") {
      whatsappMessage += `Halo saya ingin berkonsultasi dengan anda\nNama : ${name}\nEmail : ${email}\nNo. Handphone : ${no_telp}\nPerusahaan : ${company_name}\nBidang Perusahaan : ${business_sector}\nPosisi : ${position}`;
    } else {
      whatsappMessage += `Halo saya ingin berkonsultasi dengan anda\nNama : ${name}\nEmail : ${email}\nNo. Handphone : ${no_telp}\nNama Sekolah : ${school_name}\nJabatan Anda : ${position}`;
    }

    if (errMsg !== "") {
      MySwal.fire({
        title: `${errMsg} Tidak Boleh Kosong`,
        showConfirmButton: false,
        icon: "error",
        showCloseButton: true,
      });
    } else {
      const whatsappLink = `https://wa.me/6282130070040/?text=${encodeURIComponent(whatsappMessage)}`;
      const newTab = window.open(whatsappLink, "_blank");
      if (newTab) newTab.focus();
    }
  };
  return (
    <div className="wrapper__heroshot-form">
      <h4 className="wording_heroshot-form">Konsultasi Sekarang</h4>
      <form className="row g-3 w-100">
        <div className="col-12 wrapp__heroshot-form">
          <label htmlFor="name" className="form-label">
            Nama
          </label>
          <input type="text" className="form-control" id="name" placeholder="Wildan Krisdiansyah" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div className="col-12 wrapp__heroshot-form">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input type="email" className="form-control" id="email" placeholder="wildankrisdiansyah@gmail.com" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div className="col-12 wrapp__heroshot-form">
          <label htmlFor="phone" className="form-label">
            No. Handphone
          </label>
          <input type="number" className="form-control" id="phone" placeholder="08756893012" name="no_telp" value={formData.no_telp} onChange={handleChange} />
        </div>
        <div className="col-12 wrapp__heroshot-form">
          <button type="button" className="btn__form-hero-cdlc" onClick={handlePopup}>
            <span>Jadwalkan Konsultasi</span>
          </button>
        </div>
      </form>
      {popup && (
        <div className="modal__popup-cdlc">
          <div className="modal__body-cdlc">
            <button type="button" className="btn-close btn__close" onClick={handlePopup}>
              <span>&times;</span>
            </button>
            <div className="modal__content-cdlc">
              <div className="mb-4 modal__header-cdlc col-12">
                <h4 className="wording_heroshot-form">Konsultasi Sekarang</h4>
              </div>
              <form className="row g-3 col-12" onSubmit={handleSubmit}>
                <div className="col-12 wrapp__heroshot-form-popup">
                  <label htmlFor="fullName" className="form-label">
                    Nama
                  </label>
                  <input type="text" className="form-control" id="fullName" placeholder="Wildan Krisdiansyah" name="name" value={formData.name} onChange={handleChange} />
                </div>
                <div className="col-12 wrapp__heroshot-form-popup">
                  <label htmlFor="inputEmail4" className="form-label">
                    Email
                  </label>
                  <input type="email" className="form-control" id="inputEmail4" placeholder="wildankrisdiansyah@gmail.com" name="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="col-12 wrapp__heroshot-form-popup">
                  <label htmlFor="phoneNumber" className="form-label">
                    No. Handphone
                  </label>
                  <input type="number" className="form-control" id="phoneNumber" placeholder="08756893012" name="no_telp" value={formData.no_telp} onChange={handleChange} />
                </div>
                {typePage === "dlcs" ? (
                  <div className="col-12 wrapp__heroshot-form-popup">
                    <label htmlFor="company" className="form-label">
                      Nama Sekolah
                    </label>
                    <input type="text" className="form-control" id="company" placeholder="Masukan Nama Sekolah" name="school_name" value={formData.school_name} onChange={handleChange} />
                  </div>
                ) : (
                  <div className="col-12 wrapp__heroshot-form-popup">
                    <label htmlFor="company" className="form-label">
                      Nama Perusahaan
                    </label>
                    <input type="text" className="form-control" id="company" placeholder="Masukan Nama Perusahaan" name="company_name" value={formData.company_name} onChange={handleChange} />
                  </div>
                )}

                {typePage === "cdlc" && (
                  <div className="col-12 wrapp__heroshot-form-popup">
                    <label htmlFor="companyField" className="form-label">
                      Bidang Perusahaan
                    </label>
                    <input type="text" className="form-control" id="companyField" placeholder="Masukan Bidang Perusahaan Anda" name="business_sector" value={formData.business_sector} onChange={handleChange} />
                  </div>
                )}
                <div className="col-12 wrapp__heroshot-form-popup">
                  <label htmlFor="position" className="form-label">
                    Jabatan
                  </label>
                  <input type="text" className="form-control" id="position" placeholder="Masukan Jabatan" name="position" value={formData.position} onChange={handleChange} />
                </div>
                <div className="col-12 wrapp__heroshot-form-popup">
                  <button type="submit" className="btn__form-cdlc">
                    <span>Jadwalkan Konsultasi</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormPromise;
