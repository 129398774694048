import {
  // get
  INBOX_DATA,
  INBOX_DATA_SUCCESS,
  INBOX_DATA_ID_SUCCESS,
  INBOX_DATA_FAIL,
  // post
  INBOX_DATA_POST,
  INBOX_DATA_POST_SUCCESS,
  INBOX_DATA_POST_FAIL,
  // put
  INBOX_DATA_PUT,
  INBOX_DATA_PUT_SUCCESS,
  INBOX_DATA_PUT_FAIL,
  // delete
  INBOX_DATA_DELETE,
  INBOX_DATA_DELETE_SUCCESS,
  INBOX_DATA_DELETE_FAIL,
} from "../config/InboxConfig";

// get
export const getInboxData = (value) => ({
  type: INBOX_DATA,
  value,
});
export const getInboxDataSuccess = (value) => ({
  type: INBOX_DATA_SUCCESS,
  value,
});
export const getInboxDataIdSuccess = (value) => ({
  type: INBOX_DATA_ID_SUCCESS,
  value,
});
export const getInboxDataFail = (value) => ({
  type: INBOX_DATA_FAIL,
  value,
});

// post
export const postInboxData = (value) => ({
  type: INBOX_DATA_POST,
  value,
});
export const postInboxDataSuccess = (value) => ({
  type: INBOX_DATA_POST_SUCCESS,
  value,
});
export const postInboxDataFail = (value) => ({
  type: INBOX_DATA_POST_FAIL,
  value,
});

// put
export const putInboxData = (value) => ({
  type: INBOX_DATA_PUT,
  value,
});
export const putInboxDataSuccess = (value) => ({
  type: INBOX_DATA_PUT_SUCCESS,
  value,
});
export const putInboxDataFail = (value) => ({
  type: INBOX_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteInboxData = (value) => ({
  type: INBOX_DATA_DELETE,
  value,
});
export const deleteInboxDataSuccess = (value) => ({
  type: INBOX_DATA_DELETE_SUCCESS,
  value,
});
export const deleteInboxDataFail = (value) => ({
  type: INBOX_DATA_DELETE_FAIL,
  value,
});
