import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { AddressService } from "../../http/AddressHttp";
import { educational, division_name, occupation } from "../../helper/constant";
import { SkValidate } from "../common/SkValidate";
import { UserService } from "../../http/UserHttp";
import { categories } from "../../helper/constant";
import moment from "moment";
import "./../../assets/css/Account/register.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthData } from "../../helper/auth";
import { useDispatch } from "react-redux";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";

const MySwal = withReactContent(Swal);

function CompleteProfile({ par, handleClose, refreshCourseData }) {
  const dispatch = useDispatch();
  const defaultDataPribadi = {
    address_street: par.address_street,
    address_province: par.address_province,
    address_province_id: "",
    address_city: par.address_city,
    address_city_id: "",
    address_subdistrict: par.address_subdistrict,
    address_subdistrict_id: "",
    birthday: moment(par.birthday).format("YYYY-MM-DD"),
  };

  const defaultDataPekerjaan = {
    occupation: par.occupation,
    company_name: par.company_name,
    division: par.division,
    position: par.position,
    favorite_topics: sanitizeFavTopics(par.favorite_topics),
  };

  const defaultDataPendidikan = {
    education_level: par.education_level,
    university: par.university,
    major: par.major,
  };

  const tabArray = ["personal", "pekerjaan", "pendidikan"];
  const [key, setKey] = useState(tabArray[0]);
  const [provinces, setProvinces] = useState(null);
  const [cities, setCities] = useState(null);
  const [subdistricts, setSubdistricts] = useState(null);
  const [dataPribadi, setDataPribadi] = useState(defaultDataPribadi);
  const [dataPekerjaan, setDataPekerjaan] = useState(defaultDataPekerjaan);
  const [dataPendidikan, setDataPendidikan] = useState(defaultDataPendidikan);
  const [selectedTopics, setSelectedTopics] = useState(
    defaultDataPekerjaan.favorite_topics
  );
  const today = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    if (key === tabArray[0]) {
      document
        .getElementsByClassName("stepper")[0]
        .classList.add("font-weight-bold");
    }
    if (key === tabArray[1]) {
      document
        .getElementsByClassName("stepper")[1]
        .classList.add("font-weight-bold");
    }
    if (key === tabArray[2]) {
      document
        .getElementsByClassName("stepper")[2]
        .classList.add("font-weight-bold");
    }
  }, [key]);

  useEffect(() => {
    if (dataPribadi.address_province && provinces) {
      const id = getProvinceId(dataPribadi.address_province);
      getCities(id);
    }
  }, [dataPribadi.address_province, provinces]);

  useEffect(() => {
    if (dataPribadi.address_city && cities) {
      const id = getCityId(dataPribadi.address_city);
      getSubdistricts(id);
    }
  }, [dataPribadi.address_city, cities]);

  // handle changes
  const handleChangeProvinsi = (e) => {
    const $curr = e.target;
    const id = $curr.value;
    const name = $curr.querySelector(":checked").getAttribute("data-name");
    setDataPribadi({
      ...dataPribadi,
      address_province_id: id,
      address_province: name,
      address_city: "",
      address_subdistrict: "",
    });
  };

  const handleChangeCity = (e) => {
    const $curr = e.target;
    const id = $curr.value;
    const name = $curr.querySelector(":checked").getAttribute("data-name");
    setDataPribadi({
      ...dataPribadi,
      address_city_id: id,
      address_city: name,
      address_subdistrict: "",
    });
  };

  const handleChangeSubdistrict = (e) => {
    const $curr = e.target;
    const id = $curr.value;
    const name = $curr.querySelector(":checked").getAttribute("data-name");
    setDataPribadi({
      ...dataPribadi,
      address_subdistrict_id: id,
      address_subdistrict: name,
    });
  };

  const handleChangeCommon = (e, step) => {
    const key = e.target.name;
    const value = e.target.value;
    let tmp;
    switch (step) {
      case 1:
        tmp = { ...dataPribadi };
        tmp[key] = value;
        setDataPribadi(tmp);
        break;
      case 2:
        tmp = { ...dataPekerjaan };
        tmp[key] = value;
        setDataPekerjaan(tmp);
        break;
      case 3:
        tmp = { ...dataPendidikan };
        tmp[key] = value;
        setDataPendidikan(tmp);
        break;
      default:
        break;
    }
  };

  const handleChangeBirthday = (e) => {
    const value = e.target.value;
    const tmp = { ...dataPribadi };
    tmp.birthday = value;
    setDataPribadi(tmp);
  };

  const handleChangeFavTopic = (e) => {
    const value = e.target.value;
    if (selectedTopics.length >= 5) {
      // if current item is 5, remove the item
      let result = [...selectedTopics];
      var index = result.indexOf(value);
      if (index !== -1) {
        result.splice(index, 1);
        setSelectedTopics(result);
      }
    } else {
      const tmp = [...selectedTopics];
      const toggled = toggleArray(tmp, value);
      setSelectedTopics(toggled);
    }
  };

  // handle form submission
  const handleSubmit = (e, step) => {
    e.preventDefault();
    const form = e.target;
    let isValid;

    switch (step) {
      case 1:
        isValid = SkValidate(form, constraintPersonal);
        if (!isValid) return;
        setKey(tabArray[1]);
        break;
      case 2:
        isValid = SkValidate(form, constraintPekerjaan);
        if (!isValid) return;
        setKey(tabArray[2]);
        let tmp = { ...dataPekerjaan };
        tmp.favorite_topics = selectedTopics;
        setDataPekerjaan(tmp);
        break;
      case 3:
        isValid = SkValidate(form, constraintPendidikan);
        if (!isValid) return;
        let payload = { ...dataPribadi, ...dataPekerjaan, ...dataPendidikan };
        updateProfile(payload);
        break;
      default:
        break;
    }
  };

  // API CALL
  const getProvinces = async () => {
    try {
      const response = await AddressService.getProvince();
      setProvinces(response);
    } catch (error) {}
  };

  const getCities = async (id) => {
    try {
      const response = await AddressService.getCities(id);
      setCities(response);
    } catch (error) {}
  };

  const updateProfile = async (payload) => {
    try {
      const response = await UserService.putUser(AuthData().userId, payload);
      if (response) {
        handleClose();
        displayPopup(false, "Sukses Melengkapi Data", true, "Tutup");
      }
    } catch (error) {
      console.log(error);
      displayPopup(false, "Gagal Melengkapi Data, Coba Lagi!", true, "Tutup");
    }
  };

  // helper
  const getSubdistricts = async (id) => {
    try {
      const response = await AddressService.getSubdistricts(id);
      setSubdistricts(response);
    } catch (error) {}
  };

  const getProvinceId = (par) => {
    for (let i = 0; i < provinces.length; i++) {
      const e = provinces[i];
      if (par === e.province) {
        return e.province_id;
      }
    }
    return false;
  };

  const getCityId = (par) => {
    for (let i = 0; i < cities.length; i++) {
      const e = cities[i];
      if (par === `${e.type} ${e.city_name}`) {
        return e.city_id;
      }
    }
    return false;
  };

  const toggleArray = (array, value) => {
    let result = array;
    var index = result.indexOf(value);
    if (index === -1) {
      result.push(value);
    } else {
      result.splice(index, 1);
    }
    return result;
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        refreshCourseData();
      }
    });
  }

  return (
    <>
      <h4 className="row justify-content-center">
        <span className="stepper">Step 1 &gt; &nbsp;</span>
        <span className="stepper">Step 2 &gt; &nbsp;</span>
        <span className="stepper">Step 3 </span>
      </h4>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="d-none">
        <Tab eventKey="personal" title="">
          <form onSubmit={(e) => handleSubmit(e, 1)}>
            {/* TANGGAL LAHIR */}
            <div className="form-group">
              <label htmlFor="myBirthday">Tanggal lahir</label>
              <input
                name="birthday"
                className="form-control"
                id="myBirthday"
                onChange={(e) => handleChangeBirthday(e)}
                value={dataPribadi.birthday}
                type={`date`}
                max={today}
              />
              <div className="messages"></div>
            </div>
            {/* PROVINSI */}
            <div className="form-group">
              <label htmlFor="myAddressProvince">Provinsi</label>
              <select
                className="form-control"
                id="myAddressProvince"
                onChange={handleChangeProvinsi}
                name="address_province"
              >
                <option value={``}>--PILIH PROVINSI--</option>;
                {provinces &&
                  provinces.map((e, i) => {
                    return (
                      <option
                        key={i}
                        value={e.province_id}
                        data-name={e.province}
                        selected={
                          e.province === dataPribadi.address_province
                            ? true
                            : false
                        }
                      >
                        {e.province}
                      </option>
                    );
                  })}
              </select>
              <div className="messages"></div>
            </div>
            {/* KABUPATEN/KOTA */}
            <div className="form-group">
              <label htmlFor="myAddressCity">Kabupaten/Kota</label>
              <select
                className="form-control"
                id="myAddressCity"
                onChange={handleChangeCity}
                name="address_city"
              >
                <option value={``}>--PILIH KABUPATEN/KOTA--</option>;
                {provinces &&
                  cities &&
                  cities.map((e, i) => {
                    return (
                      <option
                        key={i}
                        value={e.city_id}
                        data-name={`${e.type} ${e.city_name}`}
                        selected={
                          `${e.type} ${e.city_name}` ===
                          dataPribadi.address_city
                            ? true
                            : false
                        }
                      >
                        {`${e.type} ${e.city_name}`}
                      </option>
                    );
                  })}
              </select>
              <div className="messages"></div>
            </div>
            {/* KECAMATAN */}
            <div className="form-group">
              <label htmlFor="myAddressSubdistrict">Kecamatan</label>
              <select
                className="form-control"
                id="myAddressSubdistrict"
                onChange={handleChangeSubdistrict}
                name="address_subdistrict"
              >
                <option value={``}>--PILIH KECAMATAN--</option>;
                {provinces &&
                  cities &&
                  subdistricts &&
                  subdistricts.map((e, i) => {
                    return (
                      <option
                        key={i}
                        selected={
                          `${e.subdistrict_name}` ===
                          dataPribadi.address_subdistrict
                            ? true
                            : false
                        }
                        value={e.subdistrict_id}
                        data-name={e.subdistrict_name}
                      >
                        {e.subdistrict_name}
                      </option>
                    );
                  })}
              </select>
              <div className="messages"></div>
            </div>
            <div className="form-group">
              <label htmlFor="myAddressStreet">Alamat lengkap</label>
              <textarea
                className="form-control"
                value={dataPribadi.address_street}
                id="myAddressStreet"
                name="address_street"
                rows={3}
                onChange={(e) => handleChangeCommon(e, 1)}
              ></textarea>
              <div className="messages"></div>
            </div>
            <div className="form-group text-right">
              <button className="btn btn-primary" type={`submit`}>
                Selanjutnya
              </button>
            </div>
          </form>
        </Tab>
        <Tab eventKey="pekerjaan" title="">
          <form onSubmit={(e) => handleSubmit(e, 2)}>
            <div className="form-group">
              <label htmlFor="myOccupation">Pekerjaan/Profesi</label>
              <select
                className="form-control"
                id="myOccupation"
                name="occupation"
                onChange={(e) => handleChangeCommon(e, 2)}
              >
                <option value={``}>--PILIH PEKERJAAN--</option>;
                {occupation.map((e, i) => {
                  return (
                    <option
                      key={i}
                      selected={
                        e.occup === dataPekerjaan.occupation ? true : false
                      }
                    >
                      {e.occup}
                    </option>
                  );
                })}
              </select>
              <div className="messages"></div>
            </div>
            <div className="form-group">
              <label htmlFor="myCompanyName">Perusahaan</label>
              <input
                className="form-control"
                id="myCompanyName"
                name="company_name"
                value={dataPekerjaan.company_name}
                onChange={(e) => handleChangeCommon(e, 2)}
              />
              <div className="messages"></div>
            </div>
            <div className="form-group">
              <label htmlFor="myDivision">Divisi</label>
              <select
                className="form-control"
                id="myDivision"
                name="division"
                onChange={(e) => handleChangeCommon(e, 2)}
              >
                <option value={``}>--PILIH DIVISI--</option>;
                {division_name.map((e, i) => {
                  return (
                    <option
                      key={i}
                      selected={
                        e.divisi_name === dataPekerjaan.division ? true : false
                      }
                    >
                      {e.divisi_name}
                    </option>
                  );
                })}
              </select>
              <div className="messages"></div>
            </div>
            <div className="form-group">
              <label htmlFor="myPosition">Jabatan</label>
              <input
                className="form-control"
                id="myPosition"
                name="position"
                value={dataPekerjaan.position}
                onChange={(e) => handleChangeCommon(e, 2)}
              />
              <div className="messages"></div>
            </div>
            <div className="form-group">
              <label htmlFor="" className="d-block">
                Minat
              </label>
              <div className="minat-container">
                {categories.map((e, i) => {
                  return (
                    <div className="d-block position-relative minat-wrapper">
                      <input
                        key={i}
                        type="checkbox"
                        name="favorite_topics"
                        value={e.slug}
                        id={`favTopic${i}`}
                        className="minat-check-input"
                        onChange={(e) => handleChangeFavTopic(e)}
                        checked={selectedTopics?.includes(e.slug)}
                      />
                      <label
                        htmlFor={`favTopic${i}`}
                        className="button_interest__user complete-profile"
                      >
                        {e.name}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="messages"></div>
            </div>
            <div className="form-group text-right">
              <button className="btn btn-primary" type="submit">
                Selanjutnya
              </button>
            </div>
          </form>
        </Tab>
        <Tab eventKey={`pendidikan`} title={``}>
          <form onSubmit={(e) => handleSubmit(e, 3)}>
            <div className="form-group">
              <label htmlFor="myEducationLevel">Pendidikan Terakhir</label>
              <select
                name="education_level"
                className="form-control"
                id="myEducationLevel"
                onChange={(e) => handleChangeCommon(e, 3)}
              >
                <option value={``}>--PILIH PENDIDIKAN TERAKHIR--</option>;
                {educational.map((e, i) => {
                  return (
                    <option
                      key={i}
                      selected={
                        e.education === dataPendidikan.education_level
                          ? true
                          : false
                      }
                    >
                      {e.education}
                    </option>
                  );
                })}
              </select>
              <div className="messages"></div>
            </div>
            <div className="form-group">
              <label htmlFor="myUniversity">Universitas/Sekolah</label>
              <input
                className="form-control"
                value={dataPendidikan.university}
                id="myUniversity"
                name="university"
                onChange={(e) => handleChangeCommon(e, 3)}
              />
              <div className="messages"></div>
            </div>
            <div className="form-group">
              <label htmlFor="myMajor">Jurusan</label>
              <input
                className="form-control"
                value={dataPendidikan.major}
                id="myMajor"
                name="major"
                onChange={(e) => handleChangeCommon(e, 3)}
              />
              <div className="messages"></div>
            </div>
            <div className="form-group text-right">
              {/* <button
                className="btn btn-outline-primary"
                onClick={() => setKey(tabArray[1])}
              >
                Sebelumnya
              </button> */}
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </Tab>
      </Tabs>
    </>
  );
}

export default CompleteProfile;

const constraintPresence = {
  presence: true,
};

const constraintPersonal = {
  birthday: constraintPresence,
  address_province: constraintPresence,
  address_city: constraintPresence,
  address_subdistrict: constraintPresence,
  address_street: constraintPresence,
};

const constraintPekerjaan = {
  occupation: constraintPresence,
  company_name: constraintPresence,
  division: constraintPresence,
  position: constraintPresence,
  favorite_topics: constraintPresence,
};

const constraintPendidikan = {
  education_level: constraintPresence,
  university: constraintPresence,
  major: constraintPresence,
};

/**
 * function to filter fav topic
 * according to slug from
 * const categories
 * @param {userData} obj
 * @returns array
 */
const sanitizeFavTopics = (obj) => {
  let result = [];
  if (!obj) return result;
  if (obj.length === 0) return result;
  if (obj) {
    result = obj.filter((item) => {
      if (categories.find((e) => e.slug === item)) {
        return item;
      }
    });
  }
  return result;
};
