import { PromiseService } from "../../services/PromiseService";
import { PURCHASES_DATA_LINK } from "../config/PurchasesConfig";
import {
  //post
  postPurchasesData,
  postPurchasesDataSuccess,
  //fail
  postPurchasesDataFail,
} from "../actions/PurchasesActions";

export const postPurchasesDataThunk = (data) => async (dispatch) => {
  dispatch(postPurchasesData());
  const body = data;
  try {
    const response = await PromiseService.postData(PURCHASES_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postPurchasesDataSuccess(response.data));
    } else {
      dispatch(postPurchasesDataFail(response));
    }
  } catch (error) {
    dispatch(postPurchasesDataFail(error));
  }
};
