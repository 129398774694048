import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import Layout from "./Layout";
import LayoutNoSidebar from "./LayoutNoSidebar";
import { useLocation } from "react-router-dom";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Layout/template_main.css";
import InternalServer from "../../pages/Error/InternalServer";
import { hasUpload } from "../../redux/reducers/DiplomaReducers";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";

function Template({ InnerComponent, isSidebar, isHeader, noFooter }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const courses = useSelector((state) => state.courses);
  const lessons = useSelector((state) => state.lessons);
  const login = useSelector((state) => state.login);
  const quizzes = useSelector((state) => state.quizzes);
  const users = useSelector((state) => state.users);
  const orders = useSelector((state) => state.orders);
  const assets = useSelector((state) => state.assets);
  const assignments = useSelector((state) => state.assignments);
  const purchases = useSelector((state) => state.purchases);
  const payments = useSelector((state) => state.payments);
  const webinars = useSelector((state) => state.webinars);
  const history = useHistory();
  const common = useSelector((state) => state.common);
  const header = useSelector((state) => state.header);

  useEffect(() => {
    if (
      !courses.isLoading &&
      !lessons.isLoading &&
      !login.isLoading &&
      !quizzes.isLoading &&
      !users.isLoading &&
      !orders.isLoading &&
      !assets.isLoading &&
      !assignments.isLoading &&
      !purchases.isLoading &&
      !payments.isLoading &&
      !webinars.isLoading &&
      !common.isLoading &&
      !header.isLoading
    )
      setIsLoading(false);
    else setIsLoading(true);

    //check if error
    if (
      assignments.assignmentData === "error" ||
      assignments.assignmentDataId === "error" ||
      assets.assetsData === "error" ||
      courses.coursesData === "error" ||
      courses.coursesDataId === "error" ||
      lessons.lessonsDataId === "error" ||
      quizzes.quizDataId === "error" ||
      users.usersData === "error" ||
      users.usersDataId === "error" ||
      users.usersInstructorData === "error" ||
      header?.headersData === "error" ||
      orders.ordersProvinces === "error" ||
      orders.ordersCities === "error" ||
      orders.ordersSubdistricts === "error" ||
      orders.ordersCouriers === "error" ||
      orders.ordersPrice === "error" ||
      assets.assetsData === "error" ||
      webinars.webinarsData === "error" ||
      webinars.webinarsDataId === "error"
    ) {
      setIsError(true);
    }
  }, [
    courses,
    lessons,
    login,
    quizzes,
    users,
    orders,
    assets,
    assignments,
    purchases,
    payments,
    webinars,
    common,
    header
  ]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {isSidebar ? (
        <Layout
          isSidebar={isSidebar}
          InnerComponent={InnerComponent}
          isError={isError}
        />
      ) : isHeader ? (
        <LayoutNoSidebar
          InnerComponent={InnerComponent}
          isError={isError}
          noFooter={noFooter}
        />
      ) : (
        <>{isError ? <InternalServer /> : <InnerComponent />}</>
      )}
    </>
  );
}

export default Template;
