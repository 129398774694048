import {
  //get
  LAWS_DATA,
  LAWS_DATA_ID_SUCCESS,
  LAWS_DATA_SUCCESS,
  LAWS_DATA_FAIL,
} from "../config/LawsConfig";

const intialState = {
  lawsData: null,
  lawsDataId: null,
  isLoading: false,
};

export const LawsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case LAWS_DATA:
      return { ...state, isLoading: true };
    case LAWS_DATA_SUCCESS:
      return { ...state, isLoading: false, lawsData: action.value };
    case LAWS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, lawsDataId: action.value };
    case LAWS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        lawsData: "error",
        lawsDataId: "error",
      };

    default:
      return state;
  }
};
