import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Card,
  Button,
} from "react-bootstrap";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLessonsDataThunk } from "../../redux/thunks/LessonsThunks";
import { removeCompleteState } from "../../redux/actions/CoursesActions";
import {
  getCoursesDataThunk,
  postCompleteCourseThunk,
  postCompleteMaterialThunk,
  postEnrollCoursesThunk,
} from "../../redux/thunks/CoursesThunks";
import { getAssignmentDataThunk } from "../../redux/thunks/AssignmentThunks";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { AuthIsLogin, AuthData, AuthIsLoginSinarmas } from "../../helper/auth";
import { formatRupiah, slugify } from "../../helper/functions";
import AssignmentPage from "../Courses/AssignmentPage";
import chevronleft from "./../../assets/images/chevron_left.png";
import chevronright from "./../../assets/images/chevron_right.png";
import QuizPage from "./SinarmasQuizPage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DM_COURSE_ID, DM_COURSE_ID_POST } from "../../helper/constant";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  getQuizAttemptThunk,
  postStartQuizThunk,
} from "../../redux/thunks/QuizThunks";
import { removeAttemptScoreQuiz } from "../../redux/actions/QuizActions";
import CourseTitle from "./SinarmasCourseTitle";
import AccordionCourse from "./SinarmasAccordion";
import TabsCourseDetail from "./SinarmasTabsCourseDetail";
import PDFLoaderLesson from "./SinarmasPDFLoaderLesson";
import VideoLoader from "./SinarmasVideoLoader";
import AudioLoader from "./SinarmasAudioLoader";
import Sertificate from "../../components/CoursePage/CourseFront/Sertificate";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import moment from "moment";
import SinarmasCertificate from "./SinarmasCertificate";
import { removeLoginData } from "../../redux/actions/LoginActions";
import { removeUsersDataId } from "../../redux/actions/UsersActions";
import { AuthLogoutSinarmas } from "../../helper/auth";
import { postPurchasesDataThunk } from "../../redux/thunks/PurchasesThunks";
import { removePurchasesData } from "../../redux/actions/PurchasesActions";
import SinarmasPopup from "./SinarmasPopup";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Joyride from "react-joyride";
import Setting from "../Personal/Setting";

const MySwal = withReactContent(Swal);

function SinarmasCourse() {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { course_id, id_material, material_type, slug, slug_m } = useParams();
  const [onNextClicked, setOnNextClicked] = useState(false);
  const [generateCertif, setGenerateCertif] = useState(false);
  const [attempts, setAttempts] = useState(["empty"]);
  const [disclaimer, setDisclaimer] = useState(true);
  const [showAccount, setShowAccount] = useState(false);
  const [quizPass, setQuizPass] = useState(true);
  const [hideButton, setHideButton] = useState(false);
  const [lastCourse, setLastCourse] = useState();
  const [membershipData, setMembershipData] = useState();
  const [detailData, setDetailData] = useState();
  const [material, setMaterial] = useState(id_material.split("_"));
  const [lessonType, setLessonType] = useState([false, false, false]); // video|audio|pdf, else text
  const [purchased, setPurchased] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const lessons = useSelector((state) => state.lessons);
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  const assignments = useSelector((state) => state.assignments);
  const webinars = useSelector((state) => state.webinars);
  const quizzes = useSelector((state) => state.quizzes);
  const purchases = useSelector((state) => state.purchases);
  const course = courses?.coursesDataId;
  const lesson = lessons.lessonsDataId;
  const assignment = assignments.assignmentDataId;
  const webinar = webinars.webinarsDataId;
  const quiz = quizzes.quizDataId;
  const history = useHistory();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const login = useSelector((state) => state.login);
  const [run, setRun] = useState(false);

  // detect first login
  useEffect(() => {
    if (login?.loginData?.data.first_login === 1) {
      setShowPopup(true);
    }
  }, [login]);

  useEffect(() => {
    if (quizzes.quizDataId?.attempts) {
      setAttempts(quizzes.quizDataId.attempts);
    }
    if (quizzes.quizStartData?.status === "success") {
      setHideButton(true);
      dispatch(removeAttemptScoreQuiz());
    }
    if (quizzes.quizScoreData?.status === "success") {
      setAttempts(["empty"]);
    }
    if (quizzes.quizDataId?.status === "success") {
      setHideButton(false);
    }
  }, [quizzes]);

  useEffect(() => {
    // 1. if user is not logged in, then display login popup
    if (AuthIsLoginSinarmas()) {
      if (material_type === "lessons") {
        dispatch(getLessonsDataThunk(id_material.split("_")[0]));
      }
      if (material_type === "assignments") {
        dispatch(getAssignmentDataThunk(id_material.split("_")[0]));
      }
      // if (material_type === "webinars") {
      //   dispatch(
      //     getWebinarsDataThunk({
      //       type: "id",
      //       param: `/${id_material.split("_")[0]}`,
      //     })
      //   );
      // }
      if (onNextClicked) {
        setOnNextClicked(false);
      } else {
        setMaterial(id_material.split("_"));
        dispatch(getCoursesDataThunk({ type: "id", param: `/${course_id}` }));
      }
    }
    setQuizPass(true);
  }, [, location]);

  useEffect(() => {
    const myCourses = users.usersDataId.my_courses;
    const myCert = users.usersDataId.my_certificates;
    const memberships = membershipData;
    let enrolled = false;
    let isProgressFull = false;
    // if (courses.coursesDataId.price !== "Free") {
    //   if (memberships) {
    //     if (memberships?.length > 0) {
    //       if (memberships[0].status !== "active") {
    //         displayPopup(false, "Membership Expired!", "Oke");
    //         history.push(`/course/${slug}/${course_id}`);
    //       }
    //     } else {
    //       displayPopup(false, "Pelatihan Belum Dibeli!", "Oke");
    //       history.push(`/course/${slug}/${course_id}`);
    //     }
    //   }
    // }
    if (course_id && myCourses) {
      if (users.usersDataId?.purchases) {
        if (
          users.usersDataId.purchases.filter((e) => e.course_id === course_id)
            .length > 0
        ) {
          setPurchased(true);
        }
      }
    }
    if (course_id && myCert) {
      let isCertifAvailable = false;
      for (let i = 0; i < myCert.length; i++) {
        if (myCert[i].course_id === course_id) {
          isCertifAvailable = true;
        }
      }
      if (!isCertifAvailable && isProgressFull) setGenerateCertif(true);
      else setGenerateCertif(false);
      if (!enrolled) {
        // history.push(`/course/${slug}/${course_id}`);
      }
    }
  }, [users, membershipData]);

  useEffect(() => {
    if (purchases.purchasesDataPost === "fail" && trigger) {
      setTrigger(false);
      displayPopup(true, "Gagal memulai pelatihan", false);
    }
    if (purchases.purchasesDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil memulai pelatihan", "Oke");
      const payload = {
        user_id: AuthData().userId,
        course_id: course_id,
      };
      setTrigger(true);
      dispatch(postEnrollCoursesThunk(payload));
      dispatch(removePurchasesData());
      dispatch(getUsersDataThunk(AuthData().userId));
    }
  }, [purchases]);

  useEffect(() => {
    if (lesson.url_video) {
      let newLessonType = lessonType.splice(0, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson.url_audio) {
      let newLessonType = lessonType.splice(1, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson.url_pdf) {
      let newLessonType = lessonType.splice(2, 1, true);
      setLessonType((state) => [...state, newLessonType]);
    }
    if (lesson) setDetailData(lesson);
  }, [lesson]);

  useEffect(() => {
    if (assignment) setDetailData(assignment);
  }, [assignment]);

  useEffect(() => {
    if (webinar) setDetailData(webinar);
  }, [webinar]);

  useEffect(() => {
    if (quiz) setDetailData(quiz);
  }, [quiz]);

  useEffect(() => {
    if (course?.topics !== undefined) {
      setLastCourse(
        course?.topics[course?.topics.length - 1].materials[
        course?.topics[course?.topics.length - 1].materials.length - 1
        ]
      );
    }
    if (AuthIsLoginSinarmas()) {
    } else history.push(`/login?targetUrl=${history.location.pathname}`);
    if (!users?.usersDataId?.email && AuthData().userId) {
      dispatch(getUsersDataThunk(AuthData().userId));
    }
    setRun(true);
  }, []);

  useEffect(() => {
    if (quizzes.quizDataId?.attempts) {
      let newQuizPass = false;
      const Pass = quizzes?.quizDataId?.attempts?.some(
        (attempt) => attempt.result === "Pass"
      );
      if (Pass) {
        newQuizPass = true;
      }
      setQuizPass(newQuizPass);
    }
  }, [quizzes]);

  const getMembership = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getMembership();
      setMembershipData(response.memberships);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  function onCompleteMaterialandCourse() {
    const payload = {
      material_id: id_material.split("_")[0],
      user_id: AuthData().userId,
    };
    const payloadC = {
      course_id: course_id,
      user_id: AuthData().userId,
    };
    dispatch(postCompleteMaterialThunk(payload));
    dispatch(postCompleteCourseThunk(payloadC));
  }

  function onComplete() {
    setOnNextClicked(true);
    const payload = {
      material_id: id_material.split("_")[0],
      user_id: AuthData().userId,
    };
    dispatch(postCompleteMaterialThunk(payload));
  }

  useEffect(() => {
    if (
      courses.completeMaterialData === "success" ||
      courses.completeCourseData === "success"
    ) {
      dispatch(getUsersDataThunk(AuthData().userId));
      dispatch(removeCompleteState());
    }
    setMaterial(id_material.split("_"));
    dispatch(getCoursesDataThunk({ type: "id", param: `/${course_id}` }));
  }, [courses.completeMaterialData, courses.completeCourseData]);

  const getNextLink = (current) => {
    const arrTopics = course.topics;
    let i = parseInt(current[1]);
    let j = parseInt(current[2]);

    let result;
    let next = arrTopics[i]?.materials[j + 1];
    if (
      arrTopics?.length - 1 === i &&
      arrTopics[arrTopics?.length - 1].materials.length - 1 === j
    ) {
      result = `/${material_type}/${slug_m}/${id_material}`;
      return result;
    }
    if (next) {
      if (next.type === "lessons") {
        result = `/lessons/${slugify(next.title)}/${next.lesson_id}`;
      } else if (next.type === "quizzes") {
        result = `/quiz/${slugify(next.title)}/${next.quiz_id}`;
      } else if (next.type === "assignments") {
        result = `/assignments/${slugify(next.title)}/${next.assignment_id}`;
      } else if (next.type === "webinars") {
        result = `/webinars/${slugify(next.title)}/${next.webinar_id}`;
      }

      result += `_${i}_${j + 1}`;
      return result;
    } else {
      j = 0;
      next = arrTopics[i + 1];
      if (next) {
        next = arrTopics[i + 1].materials[j];

        if (next) {
          if (next.type === "lessons") {
            result = `/lessons/${slugify(next.title)}/${next.lesson_id}`;
          } else if (next.type === "quizzes") {
            result = `/quiz/${slugify(next.title)}/${next.quiz_id}`;
          } else if (next.type === "assignments") {
            result = `/assignments/${slugify(next.title)}/${next.assignment_id
              }`;
          } else if (next.type === "webinars") {
            result = `/webinars/${slugify(next.title)}/${next.webinar_id}`;
          }
          result += `_${i + 1}_${j}`;
          return result;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const getPreviousLink = (current) => {
    const arrTopics = course?.topics;
    let i = parseInt(current[1]);
    let j = parseInt(current[2]);
    // check if next item exist
    let result;

    let previous = arrTopics[i]?.materials[j - 1];
    if (previous) {
      if (previous?.type === "lessons") {
        result = `lessons/${slugify(previous?.title)}/${previous?.lesson_id}`;
      } else if (previous?.type === "quizzes") {
        result = `quiz/${slugify(previous?.title)}/${previous?.quiz_id}`;
      } else if (previous?.type === "assignments") {
        result = `assignments/${slugify(previous?.title)}/${previous?.assignment_id
          }`;
      } else if (previous?.type === "webinars") {
        result = `webinars/${slugify(previous?.title)}/${previous?.webinar_id}`;
      }
      result += `_${i}_${j - 1}`;
      return result;
    } else {
      previous = arrTopics[i - 1];
      if (previous) {
        i = i - 1;
        j = previous?.materials.length - 1;
        previous = arrTopics[i]?.materials[j];
        if (previous?.type === "lessons") {
          result = `lessons/${slugify(previous?.title)}/${previous?.lesson_id}`;
        } else if (previous?.type === "quizzes") {
          result = `quiz/${slugify(previous?.title)}/${previous?.quiz_id}`;
        } else if (previous?.type === "assignments") {
          result = `assignments/${slugify(previous?.title)}/${previous?.assignment_id
            }`;
        } else if (previous?.type === "webinars") {
          result = `webinars/${slugify(previous?.title)}/${previous?.webinar_id
            }`;
        }
        result += `_${i}_${j}`;
        return result;
      } else {
        return false;
      }
    }
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => { });
  }

  function startAttempt() {
    if (attempts[0]?.status === "ongoing") {
      dispatch(getQuizAttemptThunk(attempts[0].attempt_id));
    } else if (attempts[0]?.status === "completed" || attempts.length <= 0) {
      displayPopupQuiz(
        true,
        `Anda ingin Mulai ${courses?.coursesDataId?.course_type === "Try Out"
          ? "Ujian Try Out?"
          : "Ujian Sertifikasi?"
        }`,
        true,
        "Ya"
      );
    }
  }

  function displayPopupQuiz(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      cancelButtonText: "Tidak",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (value.isConfirmed) {
        const qids = quizzes.quizDataId.questions.map(function (item) {
          return item["question_id"];
        });
        const payload = {
          question_ids: qids,
          quiz_id: id_material,
          user_id: AuthData().userId,
        };
        dispatch(postStartQuizThunk(payload));
      }
    });
  }

  const handleLogoutClick = () =>
    MySwal.fire({
      title: "Apakah anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Tidak",
      confirmButtonText: "Ya",
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(removeLoginData());
        dispatch(removeUsersDataId());
        AuthLogoutSinarmas();
      }
    });

  const handlePurchasedCourse = () => {
    const price = course?.price?.replace(/\D+/g, "")
      ? parseInt(course?.price?.replace(/\D+/g, ""))
      : 0;
    const balance = users?.usersDataId?.balance;
    if (price <= balance) {
      const payload = {
        bill_amount: price,
        course_ids: [course_id],
        user_id: AuthData().userId,
      };
      dispatch(postPurchasesDataThunk(payload));
      setTrigger(true);
    } else {
      displayPopup(
        false,
        "Gagal mendaftar pelatihan. Silakan hubungi tim Learning untuk penjelasan lebih lanjut.",
        "Oke"
      );
    }
  };

  // onboarding tour
  const steps = [
    {
      target: ".navigasi-konten",
      content:
        "Klik panah untuk melihat konten pembelajaran. Anda dapat mencoba pelatihan ini dengan menonton 3 video gratis. Klik “Video Materi”, dan pilih video yang ingin ditonton. Jika materi dan test sudah dipelajari atau dikerjakan, akan muncul simbol centang hijau di sebelah materi terkait.",
      locale: {
        next: "Selanjutnya",
        skip: "Lewati",
      },
    },
    {
      target: ".start-course",
      content: `Dengan menekan tombol ini, Anda mengkonfirmasi bahwa Anda mendaftarkan diri mengikuti kelas ${course.title} dari Satukelas.`,
      locale: {
        back: "Kembali",
        next: "Selanjutnya",
        skip: "Lewati",
      },
    },
    {
      target: ".next-btn",
      content:
        "Jangan lupa klik tombol “Selanjutnya” agar sistem mencatat progres penyelesaian pembelajaran. Sertifikat dapat didownload ketika progress mencapai 100%",
      locale: {
        back: "Kembali",
        next: "Selanjutnya",
        skip: "Lewati",
      },
    },
    {
      target: ".download-certificate",
      content:
        "Ketika progress pembelajaran Anda mencapai 100%, tombol “Download” akan muncul dan Anda bisa mendownload sertifikat penyelesaian kelas.",
      locale: {
        back: "Kembali",
        last: "Selesai",
      },
    },
  ];

  return (
    <div className="middle-sidebar-bottom">
      <div
        className="d-flex mt-2 mb-1 align-items-center justify-content-end"
        style={{ position: "relative", height: "40px" }}
      >
        <div className="logo-sinarmas top-left"></div>
        <div className="text-right">Halo, {users?.usersDataId?.name}</div>
        <div
          className="btn btn-primary ml-4"
          onClick={() => setShowAccount(true)}
        >
          Akun
        </div>
        <Modal
          show={showAccount}
          onHide={() => setShowAccount(false)}
          className="modal_sinarmas__profile"
        >
          <Modal.Header>
            <Modal.Title>Akun</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <ul style={{ padding: "16px 8px" }}>
                <li style={{ padding: "0px 12px" }}>
                  <b>Email:</b> {users?.usersDataId?.email}
                </li>
                {/* <li>Saldo: {formatRupiah(users?.usersDataId?.balance)}</li> */}
                {/* {users?.usersDataId?.balance == 0 &&
                  "Tidak bisa mendaftar pelatihan lagi. Silakan hubungi tim Learning untuk penjelasan lebih lanjut."} */}
                <Setting
                  customClass="custom_class_change_password"
                  noTitle={true}
                />
              </ul>
            </div>
            <hr />
            <div
              className="btn btn-danger float-right"
              onClick={handleLogoutClick}
            >
              Logout
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Joyride
        steps={steps}
        continuous
        showSkipButton
        showProgress
        hideCloseButton
        disableOverlayClose={true}
        run={run}
      />

      <Container fluid="xs" className="dashboard_page__container">
        {!purchased ? (
          <>
            <Button size="sm" className="mb-2" onClick={() => setShowPopup(true)}>
              Welcome
            </Button>
            <Button
              onClick={handlePurchasedCourse}
              size="sm"
              className="mb-2 start-course"
            >
              Mulai Pelatihan
            </Button>
            {/* <Joyride
              steps={steps}
              continuous
              showSkipButton
              showProgress
              hideCloseButton
              disableOverlayClose={true}
              run={run}
            /> */}
          </>
        ) : null}
        <Row className="no-gutters-xs">
          <Col
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md={{ span: 4, order: 1 }}
            lg={3}
            className={`${lesson && "accordion-sticky"}`}
          >
            {/* show the lesson list on the left sidebar */}
            <div
              className="navigasi-konten"
              style={{ position: "absolute", top: "40px", right: "56px" }}
            ></div>
            <AccordionCourse
              courseTitle={course.title}
              id={lesson}
              topics={course.topics}
              courseId={course_id}
              slug={slug}
              detail={true}
              type={course?.course_type}
              activeKey={material[1]}
              selectedIndex={`${material[1]}${material[2]}`}
              unlock={purchased}
            />
            {/* <a
              href="https://journal.satukelas.com/techconnect-tutorial"
              target={`_blank`}
              className="btn btn-primary btn-sm mr-2"
            >
              Lihat Tutorial
            </a> */}

          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 8, order: 2 }}
            lg={9}
          >
            {course.course_id === DM_COURSE_ID ||
              course.course_id === DM_COURSE_ID_POST ? null : (
              <>
                <TabsCourseDetail
                  id={detailData}
                  item={course}
                  content={lesson.content}
                  membershipData={membershipData}
                />
              </>
            )}

            <div className="d-flex justify-content-between align-items-center">
              <h4>{course.title}</h4>

            </div>

            {/* when material type is lesson */}
            {material_type === "lessons" && (
              <>
                {/* load this component when lesson type is video h5p or video dyntube */}
                {lesson.material_type === "video_dyntube" ||
                  lesson.material_type === "video_h5p" ? (
                  <VideoLoader
                    url={lesson.material_url}
                    type={lesson.material_type}
                  />
                ) : null}
                {/* load this component when lesson type is audio */}
                {lesson.material_type === "audio" ? (
                  <AudioLoader title={lesson.title} url={lesson.material_url} />
                ) : null}
                {/* load this component when lesson type is pdf */}
                {lesson.material_type === "pdf" ? (
                  <PDFLoaderLesson url={lesson.material_url} />
                ) : null}
              </>
            )}
            <div className="mb-4 mt-3">
              {course.course_id === DM_COURSE_ID ||
                course.course_id === DM_COURSE_ID_POST
                ? null
                : course?.topics && (
                  <>
                    <Col className="d-flex justify-content-between next-btn">
                      <Link
                        to={`/techconnect/${slug}/${course_id}/${getPreviousLink(
                          material
                        )}
                `}
                        className="previous-button mr-5 mt-0"
                      >
                        {getPreviousLink(material) && (
                          <div className="prev-course">
                            <span className="mr-3">
                              <img src={chevronleft} />
                            </span>
                            Kembali
                          </div>
                        )}
                      </Link>
                      {/* Check if the course topics in the last list or not and compare it with title material*/}
                      {course?.topics[course?.topics?.length - 1]
                        ?.materials[
                        course?.topics[course?.topics?.length - 1]
                          ?.materials?.length - 1
                      ]?.title === detailData?.title ? (
                        <>
                          {quizPass && (
                            <div
                              onClick={onCompleteMaterialandCourse}
                              className="next-button mt-0"
                            >
                              <div className="next-course">
                                Selesaikan Materi
                                <span className="ml-3">
                                  <img src={chevronright} />
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <Link
                          to={`/techconnect/${slug}/${course_id}${getNextLink(
                            material
                          )}`}
                          onClick={onComplete}
                          className="next-button mt-0"
                        >
                          {getNextLink(material) && quizPass && (
                            <div className="next-course">
                              Selanjutnya
                              <span className="ml-3">
                                <img src={chevronright} />
                              </span>
                            </div>
                          )}
                        </Link>
                      )}
                    </Col>
                  </>
                )}
            </div>
            {material_type !== "quiz" && (
              <div className="container-video__information">
                <CourseTitle
                  hideButton={hideButton}
                  startAttempt={startAttempt}
                  quizzes={quizzes}
                  attempts={attempts}
                  id={detailData}
                  course={course}
                  material_type={material_type}
                  titles={detailData?.title}
                />
              </div>
            )}

            {/* load this component when material type is assignment */}
            {material_type === "assignments" && <AssignmentPage />}
            {/* load this component when material type is quiz */}
            {material_type === "quiz" && (
              <QuizPage
                startAttempt={startAttempt}
                onComplete={onComplete}
                setHideButton={setHideButton}
                getNextLink={getNextLink}
                material={material}
                quizPass={quizPass}
                detailData={detailData}
                setMaterial={setMaterial}
              />
            )}
            {course?.is_completed !== 0 &&
              <>
                {course.course_id === DM_COURSE_ID ||
                  course.course_id === DM_COURSE_ID_POST ? null : (
                  <>
                    {course.course_type === "Sertifikasi" ? (
                      <Sertificate
                        title="Bagian dari Sertifikasi"
                        type="sertifikasi"
                        detail={true}
                        courseName={course.title}
                        idTemp={course?.certificate_template_id}
                        thumbTemp={course?.certificate_template_url}
                      />
                    ) : course.course_type === "Studi Kasus" ||
                      course.course_type === "Try Out" ? null : (
                      <Sertificate
                        title="Sertifikasi Pelatihan"
                        type="pelatihan"
                        detail={true}
                        courseName={course.title}
                        idTemp={course?.certificate_template_id}
                        thumbTemp={course?.certificate_template_url}
                      />
                    )}

                    {/* section download certificate */}
                    <div
                      className="download-certificate"
                      style={{ "min-height": "160px" }}
                    >
                      <SinarmasCertificate
                        data={users?.usersDataId?.my_certificates}
                        course_id={course_id}
                      />
                    </div>
                  </>
                )}
              </>
            }
          </Col>
        </Row>
      </Container>
      {course.course_type === "Sertifikasi" && (
        <Modal show={disclaimer}>
          <Modal.Header>
            <Modal.Title>Persetujuan Pengguna</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              Dengan ini saya menyutujui dan berjanji untuk mengerjakan ujian
              sertifikasi dengan jujur dan tanpa adanya kecurangan
              <div className="py-3">
                <Form.Check
                  onClick={() => setDisclaimer(false)}
                  type="checkbox"
                  id="default-checkbox"
                  label="Saya setuju dengan persetujuan diatas"
                />
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      <SinarmasPopup
        onClose={() => {
          setShowPopup(false);
          setRun(true);
        }}
        show={showPopup}
        courseName={course.title}
      />

      <TawkMessengerReact
        propertyId="63e22594c2f1ac1e2031e00c"
        widgetId="1golm5ju1"
      />
    </div>
  );
}

export default SinarmasCourse;
