import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthIsLogin } from "../../helper/auth";
import "../../assets/css/Personal/myCourse.css";
import CourseCard from "../../components/CourseCard";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import { loadingFinish, loadingStart } from "../../redux/reducers/CommonReducers";
import { UserService } from "../../http/UserHttp";
import { scormService } from "../../http/ScormServiceHttp";

function ScormScore() {
  const [score1, setScore1] = useState(0);
  const [score2, setScore2] = useState(0);
  const [score3, setScore3] = useState(0);
  const [CEFR, setCEFR] = useState("A1");

  useEffect(() => {
    getLessonData("so61525390", 0);
    getLessonData("so74553579", 1);
    getLessonData("so54336379", 2);
  }, []);

  useEffect(() => {
    const totalPoint = (score1 + score2 + score3) / 3;
    if (totalPoint >= 20 && totalPoint <= 50) setCEFR("A2");
    else if (totalPoint >= 51 && totalPoint <= 80) setCEFR("B1");
    else if (totalPoint >= 81 && totalPoint <= 100) {
      if (score1 > 93 && score2 > 93 && score3 > 93) setCEFR("C1");
      else setCEFR("B2");
    }
  }, [score1, score2, score3]);

  const getLessonData = async (id, idx) => {
    try {
      const response = await scormService.getScorm(id);
      if (response?.score) {
        switch (idx) {
          case 0:
            setScore1(response?.score);
            break;
          case 1:
            setScore2(response?.score);
            break;
          case 2:
            setScore3(response?.score);
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(score1, score2, score3);

  return (
    <Container className="my_course__page">
      <Row>
        <BreadcrumbPage title="Wishlist Saya" />
        <div className="d-flex justify-content-between w-100 px-2">
          <div>
            <h2 className="h2 my_course__section_title mb-4">Score Scorm</h2>
          </div>
        </div>
      </Row>
      <Row>
        <div className="profile__name_wrapper">
          <div className="profile__name_container">
            <div className="profil_info">
              <div className="profile__name">Score</div>
              <div className="profile__join_date">Percentage: {((score1 + score2 + score3) / 3).toFixed(0)} %</div>
              <div className="profile_saldo__information">
                <div className="balance_title mr-1 ">CEFR: {CEFR}</div>{" "}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default ScormScore;
