import { PromiseService } from "../../services/PromiseService";
import { QUIZ_DATA_LINK } from "../config/QuizConfig";
import {
  //get
  getQuizData,
  getQuizDataSuccess,
  getQuizDataIdSuccess,
  getQuizDataFail,
  //start
  postStartQuiz,
  postStartQuizSuccess,
  postStartQuizFail,
  //attempt
  getQuizAttempt,
  getQuizAttemptSuccess,
  getQuizAttemptFail,
  //score
  postQuizScore,
  postQuizScoreSuccess,
  postQuizScoreFail,
} from "../actions/QuizActions";

export const getQuizDataThunk = (data) => async (dispatch) => {
  dispatch(getQuizData(data));
  try {
    const response = await PromiseService.getData(`${QUIZ_DATA_LINK}/${data}`);
    if (response.status === 200 && response.data) {
      dispatch(getQuizDataIdSuccess(response.data));
    } else {
      dispatch(getQuizDataFail(response));
    }
  } catch (error) {
    dispatch(getQuizDataFail(error));
  }
};

export const postStartQuizThunk = (data) => async (dispatch) => {
  dispatch(postStartQuiz(data));
  const body = data;
  try {
    const response = await PromiseService.postData(
      `${QUIZ_DATA_LINK}/start`,
      body
    );
    if (response.status === 200 && response.data) {
      dispatch(postStartQuizSuccess(response.data));
    } else {
      dispatch(postStartQuizFail(response));
    }
  } catch (error) {
    dispatch(postStartQuizFail(error));
  }
};

export const getQuizAttemptThunk = (data) => async (dispatch) => {
  dispatch(getQuizAttempt(data));
  try {
    const response = await PromiseService.getData(
      `${QUIZ_DATA_LINK}/time/${data}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getQuizAttemptSuccess(response.data));
    } else {
      dispatch(getQuizAttemptFail(response));
    }
  } catch (error) {
    dispatch(getQuizAttemptFail(error));
  }
};

export const postQuizScoreThunk = (data) => async (dispatch) => {
  dispatch(postQuizScore(data));
  const body = data;
  try {
    const response = await PromiseService.postData(
      `${QUIZ_DATA_LINK}/score`,
      body
    );
    if (response.status === 200 && response.data) {
      dispatch(postQuizScoreSuccess(response.data));
    } else {
      dispatch(postQuizScoreFail(response));
    }
  } catch (error) {
    dispatch(postQuizScoreFail(error));
  }
};
