import React, { useState, useEffect } from "react";
import { Modal, Button, Accordion } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router";
import { formatRupiah } from "../helper/functions";

function ModalPembayaran({
  content,
  paymentsMethod,
  handleVerify,
  handleCopy,
  id,
}) {
  const [show, setShow] = useState(false);
  const [idx, setIdx] = useState([]);
  let address = useLocation();
  let splitted = address.pathname.split("/");
  const handleClose = () => {
    setShow(false);
  };
  
  useEffect(() => {
    setShow(content.show);
  }, [content]);
  
  const handleShow = (index) => {
    if (idx.includes(index)) setIdx(idx.filter((i) => i !== index));
    else setIdx([...idx, index]);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <div className="card_inside__padding">
          <div className="d-flex flex-column flex-md-row justify-content-between border-bottom__modal">
            <div className="topup-item__info-top mb-2">
              Total {`${id === "tp" ? "Topup" : content.body?.description}`}
              <span>
                {content.body?.transaction_status ?? content.body?.status}
              </span>
            </div>
            <div className="topup-item__virtual-acc mr-2 mb-2">
              {formatRupiah(content.body?.amount ?? content.body?.gross_amount)}
            </div>
          </div>
          <div></div>
          <div className="topup-item__info-bottom flex-column d-flex my-4">
            <div className="topup-item__info-top">
              No. virtual akun {content.body?.bank ? content.body?.bank : null}
            </div>
            <div className="va_container">
              <input
                id="va-number"
                value={content.body?.va_number}
                className="topup-item__virtual-acc mr-3"
                disabled
              />

              <div className="topup-item__copy-virtual__acc">
                <Button className="button_copy__va" onClick={handleCopy}>
                  Copy
                </Button>
              </div>
            </div>

            <div className="d-flex mt-1 justify-content-between">
              <div className="topup-item__info-top">Berlaku Sampai</div>
              <div className="topup-item__info-top fw-700">
                {splitted[2] === "orders"
                  ? content.body?.created_at &&
                    moment(
                      moment(content.body.created_at).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    )
                      .add(1, "d")
                      .format("YYYY-MM-DD HH:mm:ss")
                  : content.body?.transaction_time &&
                    moment(
                      moment(content.body.transaction_time).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    )
                      .add(1, "d")
                      .format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </div>
          </div>
          <div className="mt-5 mb-4">
            {content.body?.payment_id === undefined
              ? content.body?.order_id && (
                  <div className=" mt-3 d-flex justify-content-between align-items-center">
                    <Button
                      className="check-payments w-100"
                      onClick={() => handleVerify(content.body?.order_id)}
                    >
                      Konfirmasi Status Pembayaran
                    </Button>
                  </div>
                )
              : content.body?.order_id === undefined
              ? content.body?.payment_id && (
                  <div className=" mt-3 d-flex justify-content-between align-items-center">
                    <Button
                      className="check-payments w-100"
                      onClick={() => handleVerify(content.body?.payment_id)}
                    >
                      Konfirmasi Status Pembayaran
                    </Button>
                  </div>
                )
              : null}
          </div>
          <div className="topup-item__info-top">
            Paduan membayar melalui bank{" "}
            {content.body?.bank ? content.body?.bank : null}
          </div>

          {content.body?.bank === "mandiri"
            ? paymentsMethod.mandiri?.map((item, index) => (
                <Accordion key={index} className="my-2">
                  <Accordion.Toggle
                    as={Button}
                    className="p-0 accordion_bank"
                    eventKey="0"
                    onClick={() => handleShow(index)}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="course_front__kurikulum__title">
                        <div className="bank_list text-left">{item.title}</div>
                      </div>
                      <div>
                        {idx.includes(index) ? (
                          <span className="icon-chevronup size"></span>
                        ) : (
                          <span className="icon-chevrondown size"></span>
                        )}
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className=" d-flex cursor-pointer flex-column">
                      <ul id="bank_accordion">
                        {item.steps.map((e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ))
            : content.body?.bank === "bca"
            ? paymentsMethod.bca?.map((item, index) => (
                <Accordion key={index} className="my-2">
                  <Accordion.Toggle
                    as={Button}
                    className="p-0 accordion_bank"
                    eventKey="0"
                    onClick={() => handleShow(index)}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="course_front__kurikulum__title">
                        <div className="bank_list text-left">{item.title}</div>
                      </div>
                      <div>
                        {idx.includes(index) ? (
                          <span className="icon-chevronup size"></span>
                        ) : (
                          <span className="icon-chevrondown size"></span>
                        )}
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className=" d-flex cursor-pointer flex-column">
                      <ul id="bank_accordion">
                        {item.steps.map((e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ))
            : content.body?.bank === "permata"
            ? paymentsMethod?.permata?.map((item, index) => (
                <Accordion key={index} className="my-2">
                  <Accordion.Toggle
                    as={Button}
                    className="p-0 accordion_bank"
                    eventKey="0"
                    onClick={() => handleShow(index)}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="course_front__kurikulum__title">
                        <div className="bank_list text-left">{item.title}</div>
                      </div>
                      <div>
                        {idx.includes(index) ? (
                          <span className="icon-chevronup size"></span>
                        ) : (
                          <span className="icon-chevrondown size"></span>
                        )}
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className=" d-flex cursor-pointer flex-column">
                      <ul id="bank_accordion">
                        {item.steps.map((e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ))
            : content.body?.bank === "bni"
            ? paymentsMethod.bni?.map((item, index) => (
                <Accordion key={index} className="my-2">
                  <Accordion.Toggle
                    as={Button}
                    className="p-0 accordion_bank"
                    eventKey="0"
                    onClick={() => handleShow(index)}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="course_front__kurikulum__title">
                        <div className="bank_list text-left">{item.title}</div>
                      </div>
                      <div>
                        {idx.includes(index) ? (
                          <span className="icon-chevronup size"></span>
                        ) : (
                          <span className="icon-chevrondown size"></span>
                        )}
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className=" d-flex cursor-pointer flex-column">
                      <ul id="bank_accordion">
                        {item.steps.map((e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ))
            : null}
        </div>
      </Modal>
    </div>
  );
}
export default ModalPembayaran;
