export const MEMBERSHIP_DATA_LINK = "memberships";

//get
export const MEMBERSHIP_DATA = "MEMBERSHIP_DATA";
export const MEMBERSHIP_DATA_SUCCESS = "MEMBERSHIP_DATA_SUCCESS";
export const MEMBERSHIP_DATA_ID_SUCCESS = "MEMBERSHIP_DATA_ID_SUCCESS";
export const MEMBERSHIP_DATA_FAIL = "MEMBERSHIP_DATA_FAIL";

//post
export const MEMBERSHIP_DATA_POST = "MEMBERSHIP_DATA_POST";
export const MEMBERSHIP_DATA_POST_SUCCESS = "MEMBERSHIP_DATA_POST_SUCCESS";
export const MEMBERSHIP_DATA_POST_FAIL = "MEMBERSHIP_DATA_POST_FAIL";

// put
export const MEMBERSHIP_DATA_PUT = "MEMBERSHIP_DATA_PUT";
export const MEMBERSHIP_DATA_PUT_SUCCESS = "MEMBERSHIP_DATA_PUT_SUCCESS";
export const MEMBERSHIP_DATA_PUT_FAIL = "MEMBERSHIP_DATA_PUT_FAIL";

// delete
export const MEMBERSHIP_DATA_DELETE = "MEMBERSHIP_DATA_DELETE";
export const MEMBERSHIP_DATA_DELETE_SUCCESS = "MEMBERSHIP_DATA_DELETE_SUCCESS";
export const MEMBERSHIP_DATA_DELETE_FAIL = "MEMBERSHIP_DATA_DELETE_FAIL";
