import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Container, Tabs, Tab, Alert, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CourseCard from "../../components/CourseCard";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import { AuthIsLogin } from "../../helper/auth";
import "../../assets/css/Personal/myCourse.css";
import "../../assets/css/Personal/progressPage.css";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import Info from "../../components/common/Info";

function MyWebinar() {
  const [detailModal, setDetailModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [myWebinarsData, setMyWebinarsData] = useState([]);

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    getCalendar();
  }, []);
  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };
  const getCalendar = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getCalendar();
      setMyWebinarsData(response?.my_calendar);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  return (
    <>
      {/* <Info
        detailModal={detailModal}
        setDetailModal={setDetailModal}
        seeMoreInformation={seeMoreInformation}
      /> */}
      <Container className="my_course__page">
        <Row>
          <Col lg={12} xl={12}>
            <BreadcrumbPage title="Webinar Saya" />
            <div className="d-flex justify-content-between">
              <h2 className="h2 my_course__section_title mb-3 ">
                Webinar Saya
              </h2>
            </div>
            <Alert variant="info">
              Mohon isi feedback terlebih dahulu untuk dapat mengklaim
              sertifikat
            </Alert>
            <Tabs defaultActiveKey="incoming" transition={false}>
              <Tab
                tabClassName="text-blacks"
                eventKey="incoming"
                title="Akan Datang"
              >
                <Row>
                  {myWebinarsData?.length > 0 ? (
                    myWebinarsData.map((item, i) => {
                      return moment().isBetween(
                        moment().format("YYYY-MM-DD HH:mm:ss"),
                        moment(item.time_starts).format("YYYY-MM-DD HH:mm:ss")
                      ) ? (
                        <CourseCard
                          key={i}
                          item={item}
                          isWebinar={true}
                          typeWebinar="incoming"
                        />
                      ) : null;
                    })
                  ) : (
                    <Col className="course_min__height mb-4 not_found__course">
                      Data Belum Tersedia
                    </Col>
                  )}
                </Row>
              </Tab>
              <Tab
                tabClassName="text-blacks"
                eventKey="pass"
                title="Sudah Berlalu"
              >
                <Row>
                  {myWebinarsData?.length > 0 ? (
                    myWebinarsData.map((item, i) => {
                      return !moment().isBetween(
                        moment().format("YYYY-MM-DD HH:mm:ss"),
                        moment(item.time_starts).format("YYYY-MM-DD HH:mm:ss")
                      ) ? (
                        <CourseCard
                          key={i}
                          item={item}
                          isWebinar={true}
                          typeWebinar="pass"
                        />
                      ) : null;
                    })
                  ) : (
                    <Col className="course_min__height mb-4 not_found__course">
                      Data Belum Tersedia
                    </Col>
                  )}
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyWebinar;
