import React, { useState } from "react";
import { Row, Col, Container, Card, ListGroup, Button } from "react-bootstrap";
import ATPIBanner from "./banner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { courseATPI } from "../../helper/ATPI";
import { AtpiService } from "../../http/AtpiHttp";
import {
  loadingStart,
  loadingFinish,
} from "../../redux/reducers/CommonReducers";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Sertificate from "../CoursePage/CourseFront/Sertificate";
import { AuthData } from "../../helper/auth";
const MySwal = withReactContent(Swal);

function ATPI() {
  const dispatch = useDispatch();
  const params = useParams();
  const isLogin = AuthData();
  const { type } = params;

  const users = useSelector((state) => state.users);
  const usersDataId = users.usersDataId;
  const [result, setResult] = useState("");
  const [statusPass, setStatusPass] = useState({
    verified_brevet_ab: 0,
    verified_ijazah: 0,
    verified_pelatihan_perpajakan: 0,
    verified_profile: 0,
    verified_brevet_c: 0,
  });
  const [isClaim, setIsClaim] = useState(false);

  /**
   * helper to show brevet link
   * used when user has not finished brevet
   *
   * take a string param
   * returns HTML
   */
  const hintBrevet = (type) => {
    return (
      <div>
        {" "}
        {type.toLowerCase() === "ab" ? (
          <>
            Anda dapat mengikuti ujian Brevet A/B
            <Link to="/search/sertifikasi/keyword=brevet%20ab"> disini.</Link>
          </>
        ) : (
          <>
            Anda dapat mengikuti ujian Brevet C
            <Link to="/search/sertifikasi/keyword=brevet%20c"> disini.</Link>
          </>
        )}
      </div>
    );
  };

  /**
   * helper to show pelatihan perpajakan link
   * used when user has not finished pelatihan perpajakan
   *
   * take a string param
   * returns HTML
   */
  const hintPelatihanPajak = () => {
    return (
      <>
        <div>
          {" "}
          Anda dapat mengikuti pelatihan perpajakan{" "}
          <Link to="/search/pelatihan/category=perpajakan">disini.</Link>
        </div>
      </>
    );
  };

  /**
   * helper to show lengkapi profile link
   * used when user has not completed profile
   *
   * take a string param
   * returns HTML
   */
  const hintLengkapiProfil = () => {
    return (
      <div>
        {" "}
        Anda dapat melengkapi profil{" "}
        <Link to="/dashboard/profile#edit">disini.</Link>
      </div>
    );
  };

  /**
   * helper to show upload ijazah link
   * used when user has not uploaded ijazah
   *
   * take a string param
   * returns HTML
   */
  const hintUploadIjazah = (par) => {
    return (
      <>
        {" "}
        <div>
          {par.toLowerCase() === "s1" ? (
            <>
              Anda dapat mengupload ijazah S1
              <Link to="/atpi/ijazah#s1"> disini.</Link>
            </>
          ) : (
            <>
              Anda dapat mengupload ijazah SMA/SMK{" "}
              <Link to="/atpi/ijazah#sma"> disini.</Link>
            </>
          )}
        </div>
      </>
    );
  };

  /**
   * function to get course_id of ATPI
   * takes string as param
   * returns string
   */
  const getCourseATPIId = (alias) => {
    let result;
    courseATPI.forEach((element) => {
      if (element.alias === alias) {
        result = element.course_id;
      }
    });
    return result;
  };

  /**
   * function to handle button claim click
   * takes string as param
   * returns nothing
   */
  const handleClaim = (course_id, type) => {
    claimCert(course_id, type);
  };

  /**
   * function to claim cert to API
   * takes string as param
   * returns nothing
   */
  const claimCert = async (course_id, type) => {
    dispatch(loadingStart());
    try {
      const response = await AtpiService.claimCertificate(course_id);
      if (response.code === 201) {
        displayPopup(
          false,
          `Selamat! Anda telah Berhasil mengklaim Sertifikat ${type}`,
          true,
          "OK"
        );
        console.log(response);
        setStatusPass(response.status);
        setResult(response.message);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 403) {
        setStatusPass(error.data.status);
        console.log(error);
        displayPopup(false, "Mohon lengkapi kelengkapan.", true, "OK");
        setIsClaim(true);
      } else {
        displayPopup(false, "Terjadi Kesalahan", true, "OK");
      }
    }
    dispatch(loadingFinish());
  };

  /**
   * function to generate section based on atpi type
   * takes string as param
   * returns HTML dom
   */
  const mainContent = (par) => {
    let courseId = getCourseATPIId(par);
    switch (par) {
      case "catt":
        return (
          <div id="catt">
            <h2 className="course_front__kurikulum_title h2">Kelengkapan </h2>
            <Card className="course_front__benefit_container">
              <ListGroup>
                {!statusPass.verified_brevet_ab && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Lulus Ujian Brevet A/B di platform satukelas dengan nilai
                      minimal 70,01.
                      {hintBrevet("ab")}
                    </div>
                  </div>
                )}
                {!statusPass.verified_pelatihan_perpajakan && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Mengikuti pelatihan dengan tema perpajakan minimal 2
                      pelatihan di platform satukelas.
                      {hintPelatihanPajak()}
                    </div>
                  </div>
                )}
                {!statusPass.verified_profile && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Melengkapi profil.
                      {hintLengkapiProfil()}
                    </div>
                  </div>
                )}
                {!statusPass.verified_ijazah && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Upload ijazah (minimal SMA/SMK). {hintUploadIjazah("sma")}
                    </div>
                  </div>
                )}
              </ListGroup>
              {/* button actions */}
              <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                <Button
                  variant="primary"
                  className="btn_satukelas_primary font-size__button"
                  onClick={() => handleClaim(courseId, par)}
                  disabled={!isLogin}
                >
                  {isLogin ? "Klaim Sertifikat" : "Login untuk Klaim"}
                </Button>
              </div>
            </Card>

            <Sertificate
              title="Bagian dari Sertifikasi"
              type="atpi"
              courseName="Sertifikat CATT"
              thumbTemp="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/Prev_ATPI.jpg"
            />
          </div>
        );
      case "ctt":
        return (
          <div id="ctt">
            <h2 className="course_front__kurikulum_title h2">Kelengkapan</h2>
            <Card className="course_front__benefit_container">
              <ListGroup>
                {!statusPass.verified_brevet_ab && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Lulus Ujian Brevet A/B di platform satukelas dengan nilai
                      minimal 80,01.{hintBrevet("ab")}
                    </div>
                  </div>
                )}

                {!statusPass.verified_pelatihan_perpajakan && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Mengikuti pelatihan dengan tema perpajakan minimal 3
                      pelatihan di platform satukelas. {hintPelatihanPajak()}
                    </div>
                  </div>
                )}

                {!statusPass.verified_profile && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Melengkapi profil. {hintLengkapiProfil()}
                    </div>
                  </div>
                )}

                {!statusPass.verified_ijazah && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Upload ijazah (minimal SMA/SMK). {hintUploadIjazah("sma")}
                    </div>
                  </div>
                )}
              </ListGroup>
              {/* button actions */}
              <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                <Button
                  variant="primary"
                  className="btn_satukelas_primary font-size__button"
                  onClick={() => handleClaim(courseId, par)}
                  disabled={!isLogin}
                >
                  {isLogin ? "Klaim Sertifikat" : "Login untuk Klaim"}
                </Button>
              </div>
            </Card>
            <Sertificate
              title="Bagian dari Sertifikasi"
              type="atpi"
              courseName="Sertifikat CTT"
              thumbTemp="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/Prev_ATPI.jpg"
            />
          </div>
        );
      case "cptt":
        return (
          <div id="cptt">
            <h2 className="course_front__kurikulum_title h2">Kelengkapan</h2>
            <Card className="course_front__benefit_container">
              <ListGroup>
                {!statusPass.verified_brevet_ab && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Lulus Ujian Brevet A/B di platform satukelas dengan nilai
                      minimal 85,01.{hintBrevet("ab")}
                    </div>
                  </div>
                )}
                {!statusPass.verified_brevet_c && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Lulus Ujian Brevet C di platform satukelas dengan nilai
                      minimal 85,01.{hintBrevet("c")}
                    </div>
                  </div>
                )}

                {!statusPass.verified_pelatihan_perpajakan && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Mengikuti pelatihan dengan tema perpajakan minimal 4
                      pelatihan di platform satukelas. {hintPelatihanPajak()}
                    </div>
                  </div>
                )}

                {!statusPass.verified_profile && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Melengkapi profil. {hintLengkapiProfil()}
                    </div>
                  </div>
                )}

                {!statusPass.verified_ijazah && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Upload ijazah (minimal S1). {hintUploadIjazah("s1")}
                    </div>
                  </div>
                )}
              </ListGroup>
              {/* button actions */}
              <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                <Button
                  variant="primary"
                  className="btn_satukelas_primary font-size__button"
                  onClick={() => handleClaim(courseId, par)}
                  disabled={!isLogin}
                >
                  {isLogin ? "Klaim Sertifikat" : "Login untuk Klaim"}
                </Button>
              </div>
            </Card>
            <Sertificate
              title="Bagian dari Sertifikasi"
              type="atpi"
              courseName="Sertifikat CPTT"
              thumbTemp="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/Prev_ATPI.jpg"
            />
          </div>
        );
      case "cta":
        return (
          <div id="cta">
            <h2 className="course_front__kurikulum_title h2">Kelengkapan</h2>
            <Card className="course_front__benefit_container">
              <ListGroup>
                {!statusPass.verified_brevet_ab && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Lulus Ujian Brevet A/B di platform satukelas dengan nilai
                      minimal 94,01.{hintBrevet("ab")}
                    </div>
                  </div>
                )}
                {!statusPass.verified_brevet_c && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Lulus Ujian Brevet C di platform satukelas dengan nilai
                      minimal 94,01.{hintBrevet("c")}
                    </div>
                  </div>
                )}

                {!statusPass.verified_pelatihan_perpajakan && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Mengikuti pelatihan dengan tema perpajakan minimal 2
                      pelatihan di platform satukelas.{hintPelatihanPajak()}
                    </div>
                  </div>
                )}

                {!statusPass.verified_profile && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Melengkapi profil.{hintLengkapiProfil()}
                    </div>
                  </div>
                )}

                {!statusPass.verified_ijazah && (
                  <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                    <span
                      className={`mr-2 mt-2 ${
                        isClaim ? "icon-cross" : "icon-minus"
                      }`}
                    ></span>
                    <div className="course_front__benefit_item">
                      Upload ijazah (minimal S1).{hintUploadIjazah("s1")}
                    </div>
                  </div>
                )}
              </ListGroup>
              {/* button actions */}
              <div className="d-flex align-items-top course_front__benefit_item__wrapper">
                <Button
                  variant="primary"
                  className="btn_satukelas_primary font-size__button"
                  onClick={() => handleClaim(courseId, par)}
                  disabled={!isLogin}
                >
                  {isLogin ? "Klaim Sertifikat" : "Login untuk Klaim"}
                </Button>
              </div>
            </Card>
            <Sertificate
              title="Bagian dari Sertifikasi"
              type="atpi"
              courseName="Sertifikat CTA"
              thumbTemp="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/Prev_ATPI.jpg"
            />
          </div>
        );
      default:
        return false;
    }
  };

  /**
   * @function to display msg in sweet alert
   * @param {boolean} cancel
   * @param {string} title
   * @param {boolean} confirm
   * @param {string} confirmMsg
   * @returns nothing
   */
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }

  return (
    <Container className="course_front__container dashboard_page__container">
      <Row>
        <Col lg={8} md={12}>
          <ATPIBanner type={type} />
          {result === "" ? (
            <>{mainContent(type)}</>
          ) : (
            <>
              <Card className="course_front__benefit_container">
                Berhasil mengklaim Sertifikat ATPI {type.toUpperCase()}
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ATPI;
