/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Accordion, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button1 from "./Button/Button1";
import { Link } from "react-router-dom";
import { slugify } from "../../helper/functions";
import { AuthData } from "../../helper/auth";

const AccordionList = (props) => {
  const {
    list,
    type,
    handleSelect,
    isSubscribe,
    handleShow,
    currIdx,
    activeKey,
    data,
    handleClick,
    topicAccordion,
    isCompleteTopic1,
    isCompleteTopic2,
    isCompleteTopic3,
    isCompleteTopic4,
    title,
    subtitle,
    idx,
    id,
  } = props;
  const isLogin = AuthData();
  console.log(data, list);

  const overlayChecker = (progress) => {
    return progress === 100 ? (
      <div className="position-relative parent__check">
        <input
          className="check__done"
          type="checkbox"
          checked={true}
          aria-label="Checkbox for following text input"
          style={{
            width: "0",
            height: "0",
          }}
        />
        <span class="checkmark__done"></span>
      </div>
    ) : (
      // <OverlayTrigger
      //   overlay={
      //     <Tooltip>
      //       Tandai ceklis ini apabila anda telah mengerti dan ingin melewati{" "}
      //       <b>pelatihan persiapan</b>
      //       ini
      //     </Tooltip>
      //   }
      // >
      <div className="position-relative parent__check">
        <input
          className="my__checkbox"
          type="checkbox"
          checked={true}
          aria-label="Checkbox for following text input"
          style={{
            width: "0",
            height: "0",
          }}
        />
        <span class="checkmark__done"></span>
      </div>
      // </OverlayTrigger>
    );
  };

  console.log(data, "<=-==");
  return (
    <>
      <Accordion className="mb-0" defaultActiveKey="0" onSelect={handleSelect}>
        <>
          <div className="border">
            <div className="information-section__accordion heading__acordion">
              <Accordion.Toggle
                className="p-0"
                as={Button}
                variant="link"
                onClick={() => handleShow(idx)}
                eventKey={type === "magang" ? 0 : list.path_id}
              >
                <div className="d-block d-md-flex w-100 justify-content-between align-items-center">
                  <div className="d-flex">
                    <div className="mr-2">
                      {idx === currIdx && activeKey ? (
                        <span className="icon-chevronup"></span>
                      ) : (
                        <span className="icon-chevrondown"></span>
                      )}
                    </div>
                    <div className="fw-700">
                      {type === "magang"
                        ? ""
                        : type === "certification" &&
                          list.path_id !== "lp74858302" &&
                          "Test - "}{" "}
                      {title}
                    </div>
                  </div>
                  {type === "magang"
                    ? ""
                    : type !== "member" && <p className="mb-0">{subtitle}</p>}
                </div>
              </Accordion.Toggle>
            </div>

            <Accordion.Collapse eventKey={type === "magang" ? 0 : list.path_id}>
              {/* <div className="container my-3 overflow-auto"> */}
              <table className="w-100 table mb-0">
                <tbody className="w-100 row flex-nowrap flex-column">
                  {data?.length > 0 &&
                    data?.map((e, i) => {
                      return (
                        <tr
                          className="w-100 d-flex justify-content-between py-3"
                          key={i}
                        >
                          <div className="col-7">
                            <th scope="row">{i + 1}.</th>
                            <td className="w-100">
                              <Link
                                to={`${`/course/${slugify(
                                  id === "magang" ? e.course : e.title
                                )}/${e.course_id}?s=${isSubscribe}`}`}
                                className="d-block fs-1 pb-2"
                                style={{ fontSize: "15px" }}
                                onClick={() => handleShow(i)}
                              >
                                {id === "magang" ? e.course : e.title}
                              </Link>
                              {id === "scorm" && isSubscribe && (
                                <div className="wrap__progress">
                                  <div
                                    className="progress__bar-materi"
                                    style={{ width: `${e.progress}%` }}
                                  ></div>
                                </div>
                              )}
                            </td>
                          </div>
                          <div
                            className={`col-4 col-lg-5 d-flex align-items-center ${
                              isLogin && isSubscribe && type === "course"
                                ? "justify-content-between"
                                : "justify-content-end"
                            }`}
                          >
                            {id === "scorm" ? (
                              <>
                                {isSubscribe ? null : (
                                  <td className="fw-400 pr-0">
                                    <p className="d-block fs-1 pb-2 fw-600">
                                      {e.subtitle}
                                    </p>
                                  </td>
                                )}

                                <td className="fw-400 pr-0">
                                  {topicAccordion ? (
                                    <>
                                      {topicAccordion === 1 ? (
                                        <Link
                                          to={`${`/course/${slugify(e.title)}/${
                                            e.course_id
                                          }?s=${isSubscribe}`}`}
                                          className="paremt__btn-detail"
                                        >
                                          <button className="btn__detail">
                                            Detail
                                          </button>
                                        </Link>
                                      ) : topicAccordion === 2 ? (
                                        <>
                                          {isCompleteTopic1 ? (
                                            <Link
                                              to={`${`/course/${slugify(
                                                e.title
                                              )}/${
                                                e.course_id
                                              }?s=${isSubscribe}`}`}
                                              className="paremt__btn-detail"
                                            >
                                              <button className="btn__detail">
                                                Detail
                                              </button>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : topicAccordion === 3 ? (
                                        <>
                                          {isCompleteTopic1 &&
                                          isCompleteTopic2 ? (
                                            <Link
                                              to={`${`/course/${slugify(
                                                e.title
                                              )}/${
                                                e.course_id
                                              }?s=${isSubscribe}`}`}
                                              className="paremt__btn-detail"
                                            >
                                              <button className="btn__detail">
                                                Detail
                                              </button>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : topicAccordion === 4 ? (
                                        <>
                                          {isCompleteTopic1 &&
                                          isCompleteTopic2 &&
                                          isCompleteTopic3 ? (
                                            <Link
                                              to={`${`/course/${slugify(
                                                e.title
                                              )}/${
                                                e.course_id
                                              }?s=${isSubscribe}`}`}
                                              className="paremt__btn-detail"
                                            >
                                              <button className="btn__detail">
                                                Detail
                                              </button>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <Link
                                      to={`${`/course/${slugify(e.title)}/${
                                        e.course_id
                                      }?s=${isSubscribe}`}`}
                                      className="paremt__btn-detail"
                                    >
                                      <button className="btn__detail">
                                        Detail
                                      </button>
                                    </Link>
                                  )}
                                </td>

                                {isSubscribe && (
                                  <>
                                    <td className="fw-400 pr-0">
                                      {topicAccordion ? (
                                        <>
                                          {topicAccordion === 1 ? (
                                            <Link
                                              to={`${`/course/${slugify(
                                                e.title
                                              )}/${e.course_id}?lp_id=${
                                                list.path_id
                                              }&s=${isSubscribe}`}`}
                                              className="paremt__btn-detail"
                                            >
                                              <Button1
                                                title={`${
                                                  e.status === "completed"
                                                    ? "Selesai"
                                                    : e.status ===
                                                      "not enrolled"
                                                    ? "Mulai"
                                                    : "Lanjutkan"
                                                }`}
                                                clas={`btn ${
                                                  e.status === "completed"
                                                    ? "btn-secondary"
                                                    : e.status ===
                                                      "not enrolled"
                                                    ? "btn__start"
                                                    : "btn__continue"
                                                }`}
                                                disabled={
                                                  e.status.toLowerCase() ===
                                                  "completed"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Link>
                                          ) : topicAccordion === 2 ? (
                                            isCompleteTopic1 ? (
                                              <Link
                                                to={`${`/course/${slugify(
                                                  e.title
                                                )}/${e.course_id}?lp_id=${
                                                  list.path_id
                                                }&s=${isSubscribe}`}`}
                                                className="paremt__btn-detail"
                                              >
                                                <Button1
                                                  title={`${
                                                    e.status === "completed"
                                                      ? "Selesai"
                                                      : e.status ===
                                                        "not enrolled"
                                                      ? "Mulai"
                                                      : "Lanjutkan"
                                                  }`}
                                                  clas={`btn ${
                                                    e.status === "completed"
                                                      ? "btn-secondary"
                                                      : e.status ===
                                                        "not enrolled"
                                                      ? "btn__start"
                                                      : "btn__continue"
                                                  }`}
                                                  disabled={
                                                    e.status.toLowerCase() ===
                                                    "completed"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </Link>
                                            ) : (
                                              <button className="btn__detail">
                                                Selesaikan Materi 1
                                              </button>
                                            )
                                          ) : topicAccordion === 3 ? (
                                            isCompleteTopic1 &&
                                            isCompleteTopic2 ? (
                                              <Link
                                                to={`${`/course/${slugify(
                                                  e.title
                                                )}/${e.course_id}?lp_id=${
                                                  list.path_id
                                                }&s=${isSubscribe}`}`}
                                                className="paremt__btn-detail"
                                              >
                                                <Button1
                                                  title={`${
                                                    e.status === "completed"
                                                      ? "Selesai"
                                                      : e.status ===
                                                        "not enrolled"
                                                      ? "Mulai"
                                                      : "Lanjutkan"
                                                  }`}
                                                  clas={`btn ${
                                                    e.status === "completed"
                                                      ? "btn-secondary"
                                                      : e.status ===
                                                        "not enrolled"
                                                      ? "btn__start"
                                                      : "btn__continue"
                                                  }`}
                                                  disabled={
                                                    e.status.toLowerCase() ===
                                                    "completed"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </Link>
                                            ) : (
                                              <button className="btn__detail">
                                                Selesaikan Materi 2
                                              </button>
                                            )
                                          ) : topicAccordion === 4 ? (
                                            isCompleteTopic1 &&
                                            isCompleteTopic2 &&
                                            isCompleteTopic3 ? (
                                              <Link
                                                to={`${`/course/${slugify(
                                                  e.title
                                                )}/${e.course_id}?lp_id=${
                                                  list.path_id
                                                }&s=${isSubscribe}`}`}
                                                className="paremt__btn-detail"
                                              >
                                                <Button1
                                                  title={`${
                                                    e.status === "completed"
                                                      ? "Selesai"
                                                      : e.status ===
                                                        "not enrolled"
                                                      ? "Mulai"
                                                      : "Lanjutkan"
                                                  }`}
                                                  clas={`btn ${
                                                    e.status === "completed"
                                                      ? "btn-secondary"
                                                      : e.status ===
                                                        "not enrolled"
                                                      ? "btn__start"
                                                      : "btn__continue"
                                                  }`}
                                                  disabled={
                                                    e.status.toLowerCase() ===
                                                    "completed"
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </Link>
                                            ) : (
                                              <button className="btn__detail">
                                                Selesaikan Materi 3
                                              </button>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <Link
                                          to={`${`/course/${slugify(e.title)}/${
                                            e.course_id
                                          }?lp_id=${
                                            list.path_id
                                          }&s=${isSubscribe}`}`}
                                          className="paremt__btn-detail"
                                        >
                                          <Button1
                                            title={`${
                                              e.status === "completed"
                                                ? "Selesai"
                                                : e.status === "not enrolled"
                                                ? "Mulai"
                                                : "Lanjutkan"
                                            }`}
                                            clas={`btn ${
                                              e.status === "completed"
                                                ? "btn-secondary"
                                                : e.status === "not enrolled"
                                                ? "btn__start"
                                                : "btn__continue"
                                            }`}
                                            disabled={
                                              e.status.toLowerCase() ===
                                              "completed"
                                                ? true
                                                : false
                                            }
                                          />
                                        </Link>
                                      )}
                                    </td>
                                    <td className="fw-400">
                                      {topicAccordion ? (
                                        <>
                                          {topicAccordion === 1 ? (
                                            overlayChecker(e.progress)
                                          ) : topicAccordion === 2 ? (
                                            <>
                                              {isCompleteTopic1
                                                ? overlayChecker(e.progress)
                                                : ""}
                                            </>
                                          ) : topicAccordion === 3 ? (
                                            <>
                                              {isCompleteTopic1 &&
                                              isCompleteTopic2
                                                ? overlayChecker(e.progress)
                                                : ""}
                                            </>
                                          ) : topicAccordion === 4 ? (
                                            <>
                                              {isCompleteTopic1 &&
                                              isCompleteTopic2 &&
                                              isCompleteTopic3
                                                ? overlayChecker(e.progress)
                                                : ""}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        overlayChecker(e.progress)
                                      )}
                                    </td>
                                  </>
                                )}
                              </>
                            ) : id === "magang" ? (
                              <td>
                                <Link
                                  to={`${`/course/${slugify(e.course)}/${
                                    e.course_id
                                  }?s=${isSubscribe}`}`}
                                >
                                  {/* <Button1
                                      title={`${
                                        e.is_completed === "completed"
                                          ? "Selesai"
                                          : e.status === "not enrolled"
                                          ? "Mulai"
                                          : "Lanjutkan"
                                      }`}
                                      clas={`btn ${
                                        e.status === "completed"
                                          ? "btn-secondary"
                                          : e.status === "not enrolled"
                                          ? "btn-primary"
                                          : "btn-success"
                                      }`}
                                      disabled={
                                        e.status.toLowerCase() === "completed"
                                          ? true
                                          : false
                                      }
                                    /> */}
                                </Link>
                              </td>
                            ) : (
                              <td>
                                {isSubscribe ? (
                                  <Link
                                    to={`${`/course/${slugify(e.title)}/${
                                      e.course_id
                                    }?lp_id=${list.path_id}&s=${isSubscribe}`}`}
                                  >
                                    <Button1
                                      title={`${
                                        e.status === "completed"
                                          ? "Selesai"
                                          : e.status === "not enrolled"
                                          ? "Mulai"
                                          : "Lanjutkan"
                                      }`}
                                      clas={`btn ${
                                        e.status === "completed"
                                          ? "btn-secondary"
                                          : e.status === "not enrolled"
                                          ? "btn-primary"
                                          : "btn-success"
                                      }`}
                                      disabled={
                                        e.status.toLowerCase() === "completed"
                                          ? true
                                          : false
                                      }
                                    />
                                  </Link>
                                ) : (
                                  <b>1j 55 m</b>
                                )}
                              </td>
                            )}
                          </div>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {/* </div> */}
            </Accordion.Collapse>
          </div>
        </>
        {/* );
        })} */}
      </Accordion>
    </>
  );
};
export default AccordionList;
