import { Container } from "react-bootstrap";
import "../../../assets/css/Courses/audioloader.css";

function AudioLoader({ url }) {
  return (
    <Container className="audio-loader-main-div lesson-container">
    </Container>
  );
}

export default AudioLoader;
