import React from "react";
import { Form, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../../assets/css/Courses/courseSearch.css";
import { COURSE_CATEGORIES } from "../../helper/constant";

function FilterCard({
  categoriesFilter,
  radioHandleChange,
  handleFilterCourse,
  filterCourse,
  dataType,
  filterCourseLive,
  handleFilterCourseLive,
  allFilter,
}) {
  let currRoute = useLocation();
  let defaultCheck = currRoute?.pathname.split("/");
  console.log(filterCourseLive, defaultCheck[2], defaultCheck);
  return (
    <>
      <Card className="w-100">
        <h3 className="course_search__flter_by h3">Filter Berdasarkan</h3>
        <div className="course_search__category_title">Pelatihan</div>
        <div className="course_search__category_wrapper">
          <Form.Group onChange={(e) => handleFilterCourse(e, "course")}>
            <div className="mb-2">
              <Form.Check
                type="checkbox"
                name="group6"
                id="pelatihan"
                checked={
                  filterCourse?.includes("pelatihan") ||
                  (defaultCheck[3] ===
                    `course_type=pelatihan&category=${categoriesFilter}` &&
                    true) ||
                  (defaultCheck[3] === "course_type=pelatihan&category=" &&
                    true) ||
                  (defaultCheck[3] === `course_type=pelatihan` && true) ||
                  defaultCheck[3]?.includes("pelatihan")
                }
                label="Pelatihan Mandiri"
              />
            </div>
            <div className="mb-2">
              <Form.Check
                type="checkbox"
                name="group6"
                id="studi%20kasus"
                label="Studi Kasus"
                checked={
                  filterCourse?.includes("studi%20kasus") ||
                  (defaultCheck[3] === "course_type=studi kasus&category=" &&
                    true) ||
                  filterCourse?.includes("studi kasus") ||
                  (defaultCheck[3] ===
                    `course_type=studi kasus&category=${categoriesFilter}` &&
                    true) ||
                  (defaultCheck[3] === `course_type=studi kasus` && true) ||
                  defaultCheck[3]?.includes("studi kasus")
                }
              />
            </div>
            <div className="mb-2">
              <Form.Check
                type="checkbox"
                name="group6"
                id="try%20out"
                label="Try Out"
                checked={
                  filterCourse?.includes("try%20out") ||
                  (defaultCheck[3] === "course_type=try out&category=" &&
                    true) ||
                  filterCourse?.includes("try out") ||
                  (defaultCheck[3] ===
                    `course_type=try out&category=${categoriesFilter}` &&
                    true) ||
                  (defaultCheck[3] === `course_type=try out` && true) ||
                  defaultCheck[3]?.includes("try out")
                }
              />
            </div>
            <div className="mb-2">
              <Form.Check
                type="checkbox"
                name="group6"
                id="sertifikasi"
                label="Sertifikasi"
                checked={
                  filterCourse?.includes("sertifikasi") ||
                  (defaultCheck[3] ===
                    `course_type=sertifikasi&category=${categoriesFilter}` &&
                    true) ||
                  (defaultCheck[3] === `course_type=sertifikasi&category=` &&
                    true) ||
                  (defaultCheck[3] === `course_type=sertifikasi` && true) ||
                  defaultCheck[3]?.includes("sertifikasi")
                }
              />
            </div>
          </Form.Group>
        </div>
        <div className="separator pt-1 pb-3"></div>
        <div className="course_search__category_title">Live Class</div>
        <div className="course_search__category_wrapper">
          <Form.Group onChange={(e) => handleFilterCourseLive(e)}>
            <div className="mb-2">
              <Form.Check
                type="checkbox"
                name="group7"
                id="webinar"
                label="Webinar"
                checked={
                  filterCourseLive?.includes("webinar") ||
                  (defaultCheck[2] === "webinar" && true)
                }
              />
            </div>

            <div className="mb-2">
              <Form.Check
                type="checkbox"
                name="group7"
                id="konsultasi"
                label="Konsultasi"
                checked={
                  filterCourseLive?.includes("konsultasi") ||
                  (defaultCheck[2] === "konsultasi" && true)
                }
              />
            </div>
          </Form.Group>
        </div>
        <div className="separator pt-1 pb-3"></div>
        <div className="course_search__category_title">
          {dataType === "semua" || dataType === "Semua" ? (
            "Semua Kategori"
          ) : (
            <>
              Topik{" "}
              {dataType === "studi kasus" || dataType === "studi%20kasus"
                ? "Studi Kasus"
                : dataType === "try out" || dataType === "try%20out"
                ? "Try Out"
                : dataType?.charAt(0)?.toUpperCase() + dataType?.slice(1)}
            </>
          )}
        </div>
        <div className="course_search__category_wrapper">
          <Form.Check
            type="radio"
            name="group2"
            id={"semua"}
            label={"Semua"}
            checked={allFilter === "semua" || categoriesFilter === "semua"}
            onChange={() => radioHandleChange("semua")}
          />
          {COURSE_CATEGORIES.map((item, i) => {
            const name = item.name.toLowerCase();
            return (
              <Form.Check
                key={name}
                type="radio"
                name="group2"
                id={name}
                label={item.name}
                checked={categoriesFilter === name}
                onChange={() => radioHandleChange(name)}
              />
            );
          })}
        </div>
      </Card>
    </>
  );
}

export default FilterCard;
