import React, { useState } from "react";
import { Alert, Button, Col, Form, Image, Row } from "react-bootstrap";
import light from "./../../assets/images/light.svg";

function RegistStep({ email, password, terms, repassword, onChangeHandler, sex, name, handleChange }) {
  const [show, setShow] = useState(true);
  const [showRe, setShowRe] = useState(true);
  const handleShowPassword = (value) => {
    if (value === "1") {
      setShow(!show);
      show ? (document.getElementById("password").type = "text") : (document.getElementById("password").type = "password");
    } else {
      setShowRe(!showRe);
      showRe ? (document.getElementById("repassword").type = "text") : (document.getElementById("repassword").type = "password");
    }
  };

  return (
    <>
      <h1 className="h1 register-step__first-title">
        Buat Akun
        <br /> Anda
      </h1>

      <Row>
        <Col lg={12}>
          <div className="login-label">Nama</div>
        </Col>
        <Col lg={3}>
          <Form.Group>
            <Form.Control required as="select" type="select" name="sex" id="sex" value={sex} onChange={handleChange}>
              <option value="laki-laki" key="1">
                Tn.
              </option>
              <option value="perempuan" key="2">
                Ny.
              </option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Control aria-describedby="basic-addon1" className="form-name" name="name_user" id="name" placeholder="Siapa nama anda?" value={name} onChange={onChangeHandler} />
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group>
            <div className="login-label">Email</div>
            <Form.Control className="mb-3 input100" type="text" placeholder="Masukkan Email Anda" id="email" name="email" value={email} onChange={onChangeHandler} />
            <div className="messages"></div>
          </Form.Group>
          <Form.Group>
            <div className="login-label">Kata Sandi</div>
            <div className="position-absolute p-3 d-flex">{/* <FaLock /> */}</div>
            <div className="password_input">
              <Form.Control className="mb-3 input100" type="password" placeholder="Masukkan Kata Sandi Anda" id="password" name="kata_sandi" value={password} onChange={onChangeHandler} />
              {show ? <span onClick={() => handleShowPassword("1")} className="icon-eye position_eye"></span> : <span onClick={() => handleShowPassword("1")} className="icon-eye-coret position_eye"></span>}
            </div>
            <div className="messages"></div>
          </Form.Group>{" "}
          <Form.Group>
            <div className="login-label">Konfirmasi Kata Sandi</div>
            <div className="position-absolute p-3">{/* <FaLock /> */}</div>
            <div className="password_input">
              <Form.Control className="mb-3 input100" type="password" placeholder="Konfirmasi Kata Sandi" id="repassword" name="konfirmasi_kata_sandi" value={repassword} onChange={onChangeHandler} />
              {showRe ? <span onClick={() => handleShowPassword("2")} className="icon-eye position_eye"></span> : <span onClick={() => handleShowPassword("2")} className="icon-eye-coret position_eye"></span>}
            </div>
            <div className="messages"></div>
          </Form.Group>
          <Form.Group>
            <div className="d-flex">
              <Form.Check type="checkbox" id="terms" name="bagian" value={terms} onChange={onChangeHandler} />
              <label htmlFor="terms">
                Saya setuju dengan
                <a href="/term" target="_blank" className="text-decoration-none login-register-text__span">
                  {" "}
                  peraturan dan ketentuan
                </a>{" "}
                yang berlaku
              </label>
            </div>
            <div className="messages"></div>
          </Form.Group>
        </Col>
        <Col className="mb-4">
          <Alert variant="warning" className="register-alert__msg">
            <span className="mr-2">
              <Image src={light} width={18} height={18} />
            </span>
            Kami membutuhkan info dari anda untuk mengidentifikasi pengguna dan memberikan akses ke layanan kami dan demi menjaga keamanan. Informasi tambahan juga membantu kami dalam menyediakan experience yang disesuaikan untuk pengguna
          </Alert>
        </Col>
      </Row>
      <div className="register_button__container w-100">
        <Button type="submit" className="register-next__button position_text__button w-100">
          Selanjutnya
        </Button>
      </div>
      <div className="w-100 text-center py-4">ATAU</div>
      <div className="register_button__container w-100">
        <Button type="submit" variant="outline-primary" className="register-next__button position_text__button w-100">
          Sign In with Google
        </Button>
      </div>
    </>
  );
}

export default RegistStep;
