import React, { useEffect, useState } from "react";
import { Row, Col, Container, Tabs, Tab, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Personal/myCertificate.css";
import CertificateCard from "../../components/CertificateCard";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import Cart from "../Transaction/Cart";
import { AuthData, AuthIsLogin } from "../../helper/auth";
import { formatRupiah, KeepSpesificKey } from "../../helper/functions";
import {
  getUsersDataThunk,
  putUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import Info from "../../components/common/Info";

const MySwal = withReactContent(Swal);

function MyCert() {
  const history = useHistory();
  const [detailModal, setDetailModal] = useState(false);
  const dispatch = useDispatch();
  const urlSearch = history.location?.search.split("?")[1];
  const [viewCert, setViewCert] = useState("grid");
  const [trigger, setTrigger] = useState(true);
  const [dataCart, setDataCart] = useState(null);
  const [myCertificates, setMyCertificates] = useState({
    all: [],
    participant: [],
    competent: [],
    profession: [],
  });
  const [addCertificate, setAddCertificate] = useState(false);
  const [triggerAdd, setTriggerAdd] = useState(false);
  const [isMinimize, setIsMinimize] = useState(true);
  const users = useSelector((state) => state.users);
  const dataCertificate = users.usersDataId.my_certificates;

  useEffect(() => {
    if (urlSearch !== undefined) {
      setIsMinimize(false);
      setAddCertificate(true);
    }
  }, [urlSearch]);

  useEffect(() => {
    if (addCertificate && dataCart) {
      // let currCertif =
      let cart = KeepSpesificKey([...dataCart?.my_cart]);
      // console.log(cart);
      // console.log(cart, "add auto");
      let req = {};
      if (!cart) cart = [];
      if (cart.includes(urlSearch)) {
        console.log("sudah ada!");
        // displayPopup(true, "Barang sudah di keranjang!", false);
      } else {
        cart.push(urlSearch);
        req.body = { cart: cart };
        req.id = AuthData().userId;
        setTriggerAdd(true);
        dispatch(putUsersDataThunk(req));
        getCarts();
      }
    }
  }, [addCertificate, dataCart]);

  useEffect(() => {
    if (users.usersDataPut === "error" && triggerAdd) {
      setTriggerAdd(false);
      // setDataCart();
      displayPopup(
        true,
        "Terjadi masalah saat Menambahkan ke Keranjang",
        false
      );
      getCarts();
    }
    if (users.usersDataPut === "success" && triggerAdd) {
      setTriggerAdd(false);
      // setDataCart();
      displayPopup(false, "Berhasil Menambahkan ke Keranjang", true, "Ok");
      getCarts();
    }
  }, [users]);

  useEffect(() => {
    console.log(dataCart, "<===");
  }, [dataCart]);

  // first render: check auth & get certificate
  useEffect(() => {
    AuthIsLogin(history);
    getCertificates();
    getCarts();
  }, []);
  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };
  const getCarts = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getCart();
      if (response) {
        dispatch(loadingFinish());
        setDataCart(response);
      }
    } catch (error) {
      dispatch(loadingFinish());
      console.log(error);
    }
  };

  // get cert from API
  const getCertificates = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getCertificate();
      const listCert = response.my_certificates;
      const groupedCert = { ...myCertificates };

      if (listCert && trigger) {
        listCert.forEach((e) => {
          if (e.course_type === "Pelatihan") groupedCert.participant.push(e);
          if (e.course_type === "Sertifikasi") groupedCert.competent.push(e);
          // if (e.status === "profession") groupedCert.profession.push(e);
          groupedCert.all.push(e);
        });
        setMyCertificates(groupedCert);
        setTrigger(false);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  // handle change view format (list and grid)
  const handleChangeView = (value) => {
    setViewCert(value);
  };

  // handle display popup
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersDataThunk(AuthData().userId));
      }
    });
  }

  /** renders when no data found */
  const renderEmptyData = () => {
    return (
      <div className="course_min__height w-100 mb-4 not_found__course">
        Data tidak ditemukan
      </div>
    );
  };

  /** renders each cert item */
  const renderCertItems = (value, index) => {
    if (viewCert === "list")
      return (
        <Col xs={12} sm={12} md={12} key={index} className="course-container">
          <CertificateCard
            viewlist={viewCert}
            data={value}
            dataCart={dataCart}
            getCarts={getCarts}
          />
        </Col>
      );
    else
      return (
        <CertificateCard
          viewlist={viewCert}
          data={value}
          dataCart={dataCart}
          getCarts={getCarts}
        />
      );
  };

  return (
    <>
      {/* <Info
        detailModal={detailModal}
        setDetailModal={setDetailModal}
        seeMoreInformation={seeMoreInformation}
      /> */}
      <Container className="dashboard_page__container">
        <BreadcrumbPage title="Sertifikat Saya" />
        <div className="certification_wrapper">
          <div className="certification_section w-100 ">
            <h2 className="certification__section_title h2">Sertifikat Saya</h2>
            <div className="certification__subtitle mb-0">
              {dataCertificate?.length} Sertifikat di Temukan
            </div>
          </div>
          <div className="d-flex">
            <div className="mr-3" onClick={() => handleChangeView("list")}>
              <span className="icon-list"></span>
            </div>
            <div className="mr-3" onClick={() => handleChangeView("grid")}>
              <span className="icon-grid"></span>
            </div>
          </div>
        </div>
        <Tabs
          defaultActiveKey="all"
          transition={false}
          id="noanim-tab-example"
          className=""
        >
          {/* ALL CERTS */}
          <Tab tabClassName="text-blacks" eventKey="all" title="Semua">
            <Row className="mt-4">
              {(myCertificates?.all.length === 0 || !myCertificates) &&
                renderEmptyData()}

              {myCertificates?.all.length > 0 &&
                myCertificates.all.map((value, index) => {
                  return renderCertItems(value, index);
                })}
            </Row>
          </Tab>

          {/* TYPE PARTISIPASI = course type pelatihan */}
          <Tab tabClassName="" eventKey="partisipasi" title="Partisipasi">
            <Row className="mt-4">
              {(myCertificates?.participant.length === 0 || !myCertificates) &&
                renderEmptyData()}

              {myCertificates?.participant.length > 0 &&
                myCertificates.participant.map((value, index) => {
                  return renderCertItems(value, index);
                })}
            </Row>
          </Tab>

          {/* TYPE KOMPETENSI = course type sertifikasi*/}
          <Tab
            tabClassName="text-blacks"
            eventKey="kompetensi"
            title="Kompetensi"
          >
            <Row className="mt-4">
              {(myCertificates?.competent.length === 0 || !myCertificates) &&
                renderEmptyData()}

              {myCertificates?.competent.length > 0 &&
                myCertificates.competent.map((value, index) => {
                  return renderCertItems(value, index);
                })}
            </Row>
          </Tab>

          {/* TYPE PROFESI */}
          {/* <Tab tabClassName="text-blacks" eventKey="profesi" title="Profesi">
          <Row className="mt-4">
            {viewCert === "list" ? (
              myCertificates?.profession?.length > 0 ? (
                myCertificates?.profession?.map((value, index) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      key={index}
                      className="course-container"
                    >
                      <CertificateCard viewlist={viewCert} />
                    </Col>
                  );
                })
              ) : (
                <div className="course_min__height w-100 mb-4 not_found__course">
                  Data tidak ditemukan
                </div>
              )
            ) : myCertificates?.profession?.length > 0 ? (
              myCertificates?.profession?.map((value, index) => {
                <CertificateCard viewlist={viewCert} key={index} />;
              })
            ) : (
              <div className="course_min__height w-100 mb-4 not_found__course">
                Data tidak ditemukan
              </div>
            )}
          </Row>
        </Tab> */}
        </Tabs>
        <div className={isMinimize ? "sticky-cart" : "sticky-cart-max"}>
          <div className="d-flex flex-row justify-content-between header__border_bottom">
            <div
              style={{ fontSize: isMinimize ? "0.9rem" : "1em" }}
              className="d-flex align-items-center"
            >
              Keranjang Saya
            </div>
            <p
              className={`balance__cart_minimize ${
                !isMinimize ? "balance__cart_maximize" : ""
              }`}
            >
              Saldo: {formatRupiah(users?.usersDataId?.balance)}
            </p>
            <div>
              <Button
                variant="warning"
                size="sm"
                onClick={() => {
                  setIsMinimize(!isMinimize);
                  history.push("/dashboard/certificate");
                  getCarts();
                  setAddCertificate(false);
                }}
                style={{ fontSize: isMinimize ? "0.7rem" : "1em" }}
              >
                {isMinimize ? "Maximize" : "Minimize"}
              </Button>
            </div>
          </div>
          <Cart
            isMinimize={isMinimize}
            dataCart={dataCart}
            getCarts={getCarts}
          />
        </div>
      </Container>
    </>
  );
}

export default MyCert;
