import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card, Form, Modal } from "react-bootstrap";
import "../../assets/css/Home/membershipLanding.css";
import {
  benefit_korporat,
  info_korporat,
  membership_korporat,
  membership_korporat_price,
  membership_size,
  testimoni,
} from "../../helper/constant";
import thumbnailporto from "./../../assets/images/thumbnail_promotion.jpg";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Link } from "react-router-dom";
function MembershipLandingKorporasi() {
  const [loaded, setLoaded] = useState(false);
  const [modal, setModal] = useState(false);
  const [membershipSize, setMembershipSize] = useState("25");
  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    setLoaded(true);
  }, []);
  function renderSplide() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Row>
        <Col lg={12} className="mt-4 mb-5">
          <div className="category-card overflow-visible ">
            <div>
              <Splide
                options={{
                  rewind: true,
                  perPage: 3,
                  perMove: 1,
                  gap: "1.5rem",
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    992: {
                      perPage: 3,
                    },
                    768: {
                      perPage: 2,
                    },
                    500: {
                      perPage: 1,
                    },
                  },
                }}
              >
                {testimoni?.map((item, i) => (
                  <SplideSlide key={i}>
                    <div className="cloned" style={{ marginRight: "15px" }}>
                      <div className="text-center">
                        <div className="card w-100 p-4 text-left border-0 shadow-xss rounded-lg max-card__height">
                          <div className="pb-3 pl-0 pt-0">
                            <div className="float-left mr-3">
                              <div>
                                {item.photo === "" ? (
                                  <div className="not-found__image">
                                    <span className="icon-user size-homepage"></span>
                                  </div>
                                ) : (
                                  <img
                                    className="image-user__testi"
                                    src={item.photo}
                                    alt="testi_photo"
                                  />
                                )}
                              </div>
                            </div>

                            <h4 className="text-grey-900 fw-700 font-xsss mt-0 pt-1">
                              {item.name}
                            </h4>
                            <h5 className="font-xssss fw-500 mb-1 text-grey-500">
                              {item?.company}
                            </h5>
                          </div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.testimoni,
                            }}
                            className="testimoni-customer mt-0 mb-0 "
                          ></p>
                          <div className="star d-block w-100 text-right mt-4 mb-0">
                            {[...Array(5)].map((item, index) => {
                              return (
                                <span
                                  key={index}
                                  className="icon-star blue-star"
                                ></span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
  const handleChangeMembership = (e) => {
    const data = e.target.value;
    setMembershipSize(data);
  };

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    // console.log(membershipSize);
  }, [membershipSize]);

  const handlePromotion = () => {
    setModal(true);
  };
  return (
    <div>
      <div className="about-wrapper pb-0 bg-1">
        <Container className="h-inherit py-3 py-md-0">
          <Row className="h-inherit align-content-center">
            <Col lg={9} className="sm-mb-4">
              <h2 className="text-grey-900 fw-700 hero-shot__title mb-0 mt-3 d-block">
                Tingkatkan kompetensi dan produktivitas karyawan Anda hanya
                dalam satu platform!
              </h2>
              <p className="hero-shot__subtitle my-4 text-white">
                Perkuat kualitas SDM yang hemat waktu dan biaya dengan beragam
                pelatihan dan materi siap pakai untuk berbagai kebutuhan
                karyawan Anda.
              </p>
              <div>
                {/* <Button href="/membership" className="w-25 button-start mr-3">
                  Pelajari
                </Button> */}
                <Link to="/contact" className="button-start px-4">
                  Hubungi Sales
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="my-5">
        <Row className="align-items-center">
          <Col xs={12} lg={7} className="my-3 my-md-5">
            <h2 className="text-grey-900 fw-700 hero-shot__title mb-0 mt-3 d-block">
              Mudah, Murah, dan Berdampak. Semua Kebutuhan Pelatihan Karyawan
              dalam Satu Platform!
            </h2>
          </Col>
          <Col xs={12} lg={5} className="my-3 my-md-5">
            <div onClick={handlePromotion}>
              <img src={thumbnailporto} className="thumbnail-wrapper" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="w-100 justify-content-center">
          <Col xs={12} lg={7} className="my-3 my-md-5 text-center">
            <h2 className="text-grey-900 fw-700 hero-shot__title mb-0 mt-3 d-block">
              Peningkatan Kompetensi dan Produktivitas Karyawan Anda Dimulai
              dari Sini!
            </h2>
          </Col>
        </Row>
        <Row>
          {benefit_korporat.map((item, index) => (
            <Col key={index} lg={4} md={4}>
              <Card className="mb-4 w-100 border-0 pb-0">
                <i className="btn-round-lg alert-success feather-layers text-success ml-0 mb-2 font-lg"></i>
                <h2 className="fw-700 text-corporate mt-1">{item.title}</h2>
                <p
                  className="text-corporate"
                  dangerouslySetInnerHTML={{
                    __html: item.subtitle,
                  }}
                ></p>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="feature-wrapper layer-after">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="section-4_img"></div>
            </Col>
            <Col lg={5} className="offset-lg-1">
              <h2 className="text-grey-900 fw-700 display1-size pb-3 mb-0 mt-3 d-block lh-3">
                Dukung Karyawan Anda Menguasai Keterampilan Kerja Sesuai dengan
                Zamannya!
              </h2>
              <p className="fw-400 info_corporate__size lh-28">
                Dengan satukelas, karyawan dapat mempelajari keahlian yang
                relevan dengan kompetensi industri masa kini. Learning
                Management System di satukelas juga didesain agar pembelajaran
                bisa disesuaikan dengan kebutuhan perusahaan. Tingkatkan
                kepuasan karyawan Anda dengan pembelajaran yang membantu
                pekerjaan mereka!
              </p>
              {info_korporat.map((item, index) => (
                <div key={index} className="d-flex">
                  <i className="ti-check font-xssss mr-2 btn-round-xs alert-danger text-danger"></i>{" "}
                  <div className="info_corporate__size fw-400 mb-3 ">
                    {item.desc}
                  </div>
                </div>
              ))}

              {/* <a
                href="#"
                className="btn border-0 bg-primary p-3 text-white fw-600 rounded-lg d-inline-block font-xssss btn-light mt-3 w150"
              >
                Subscribe
              </a> */}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="popular-wrapper pb-0 pt-5">
        <Container>
          <Row>
            <Col md={5} className="text-left mb-2 pb-0">
              <h2 className="h2 page_title">Kata mereka tentang satukelas</h2>
            </Col>

            <Col lg={12}>{loaded ? <>{renderSplide()}</> : null}</Col>
          </Row>
        </Container>
      </div>
      <div className="price-wrap pt-lg--7 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10} className="xs-mb-2 mb-3">
              <h2 className="text-grey-900 fw-700 text-center display1-size display2-md-size pb-3 mb-0 mt-3 d-block lh-3">
                Langganan paket pelatihan untuk
                <br /> perusahaan sesuai dengan kebutuhan
              </h2>
              <p className="subtitle_membership text-center">
                Kembangkan kompetensi karyawan dengan memilih paket berlangganan
                <br />
                yang tepat di satukelas sebagai one stop solution untuk
                menunjang performa perusahaan Anda!
              </p>
              {/* <div className="custom-control custom-switch pl-0">
                <p className="fw-600 font-xssss lh-28 text-grey-900 d-inline-block pr-5 mr-3">
                  Bill Monthly
                </p>
                <input
                  type="checkbox"
                  className="custom-control-input dark-mode-switch"
                  id="darkmodeswitch1"
                />
                <label
                  className="custom-control-label bg-success mt-1"
                  for="darkmodeswitch1"
                ></label>
                <p className="fw-600 font-xssss lh-28 text-grey-900 d-inline-block">
                  Bill Anually
                </p>
              </div> */}
            </Col>
            <Col lg={10}>
              <Row>
                {membership_korporat?.map((item, index) => (
                  <Col key={index} lg={6}>
                    <Card
                      className={`border-0 mb-4 ${
                        item?.membership_name === "Membership1"
                          ? "blue-card"
                          : ""
                      } shadow-xss shadow-xss rounded-lg`}
                    >
                      <Card.Body
                        className={`p-5 border-top-lg border-size-lg ${
                          item?.membership_name === "Membership1"
                            ? "border-white"
                            : "border-primary"
                        }`}
                      >
                        <h4
                          className={`h4 fw-600 ${
                            item?.membership_name === "Membership1"
                              ? "text-white"
                              : ""
                          }`}
                        >
                          {item?.jumlah_member}
                        </h4>
                        <h2
                          className={`h2 ${
                            item?.membership_name === "Membership1"
                              ? "membership-title__promo"
                              : "membership-title__unpromo"
                          }`}
                        >
                          {item.price}
                          <span
                            className={`${
                              item?.membership_name === "Membership1"
                                ? "span-month-white"
                                : "span-month"
                            }`}
                          >
                            / Tahun
                          </span>
                        </h2>
                        <div className="overflow-show">
                          {item?.benefit.map((e, i) => (
                            <div
                              key={i}
                              className={` ${
                                item?.membership_name === "Membership1"
                                  ? `price_card__price_sub__white ${
                                      i > 17 ? "line-thrg" : ""
                                    } `
                                  : "price_card__price_sub"
                              } `}
                            >
                              {" "}
                              {e.benefit_info}
                            </div>
                          ))}
                        </div>
                        <Link
                          to="/contact"
                          className={`mt-2 btn-primary ${
                            item?.membership_name === "Membership1"
                              ? "btn-sales-promo"
                              : "btn-sales"
                          }`}
                        >
                          Hubungi Sales
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
                <Col lg={6}>
                  <Card
                    className={`border-0 mb-4 
                     shadow-xss shadow-xss rounded-lg`}
                  >
                    <Card.Body
                      className={`p-5 border-top-lg border-size-lg border-primary
                      `}
                    >
                      <h4 className={`h4 fw-600`}>
                        <Form.Control
                          className="py-0 px-0 style1-input search-course__title"
                          as="select"
                          name="membership_size"
                          id="membership_size"
                          onChange={handleChangeMembership}
                        >
                          {membership_size?.map((e, i) => (
                            <option key={i} className="pl-1" value={e.size}>
                              {e.size} Pengguna
                            </option>
                          ))}
                        </Form.Control>
                      </h4>
                      <h2 className="h2 membership-title__unpromo">
                        {membershipSize === "25"
                          ? "30jt"
                          : membershipSize === "100"
                          ? "90jt"
                          : membershipSize === "500"
                          ? "360jt"
                          : "600jt"}
                        <span className="span-month">/ Tahun</span>
                      </h2>
                      <div className="overflow-show">
                        {membership_korporat_price[0]?.benefit?.map((e, i) => (
                          <div key={i} className="price_card__price_sub">
                            {e.benefit_info}
                          </div>
                        ))}
                      </div>
                      <Link
                        to="/contact"
                        className={`mt-2 btn-primary btn-sales`}
                      >
                        Hubungi Sales
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal centered size="lg" onHide={() => setModal(false)} show={modal}>
          <iframe
            className="w-100 height_video"
            src="https://www.youtube.com/embed/sU88VWw3rFY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal>
      </div>
    </div>
  );
}

export default MembershipLandingKorporasi;
