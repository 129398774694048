import {
  //get
  COURSES_DATA,
  COURSES_DATA_SUCCESS,
  COURSES_DATA_ID_SUCCESS,
  COURSES_DATA_FAIL,
  //get popular course
  COURSES_POPULAR_DATA,
  COURSES_POPULAR_DATA_SUCCESS,
  COURSES_POPULAR_DATA_FAIL,
  // brevet course
  COURSES_BREVET_DATA,
  COURSES_BREVET_DATA_SUCCESS,
  COURSES_BREVET_DATA_FAIL,
  //get by division
  COURSES_RECOMMEND_DATA,
  COURSES_RECOMMEND_DATA_SUCCESS,
  COURSES_RECOMMEND_DATA_FAIL,
  //post enroll
  COURSES_DATA_ENROLL,
  COURSES_DATA_ENROLL_SUCCESS,
  COURSES_DATA_ENROLL_FAIL,
  //complete material
  COURSES_COMPLETE_MATERIAL,
  COURSES_COMPLETE_MATERIAL_SUCCESS,
  COURSES_COMPLETE_MATERIAL_FAIL,
  //complete course
  COURSES_COMPLETE_COURSE,
  COURSES_COMPLETE_COURSE_SUCCESS,
  COURSES_COMPLETE_COURSE_FAIL,
  //complete remove
  COURSES_REMOVE_COMPLETE_STATE,
  //remove course data id
  COURSES_REMOVE_COURSE_DATA_ID,
  //remove course data
  COURSES_REMOVE_COURSE_DATA,
  //remove course data enroll
  COURSES_REMOVE_COURSE_DATA_ENROLL,
} from "../config/CoursesConfig";

//get
export const getCoursesData = (value) => ({
  type: COURSES_DATA,
  value,
});
export const getCoursesDataSuccess = (value) => ({
  type: COURSES_DATA_SUCCESS,
  value,
});
export const getCoursesDataIdSuccess = (value) => ({
  type: COURSES_DATA_ID_SUCCESS,
  value,
});
export const getCoursesDataFail = (value) => ({
  type: COURSES_DATA_FAIL,
  value,
});

export const getCoursesBrevetData = (value) => ({
  type: COURSES_BREVET_DATA,
  value,
});
export const getCoursesBrevetDataSuccess = (value) => ({
  type: COURSES_BREVET_DATA_SUCCESS,
  value,
});
export const getCoursesBrevetDataFail = (value) => ({
  type: COURSES_BREVET_DATA_FAIL,
  value,
});

// get by recommend
export const getCoursesByRecommendData = (value) => ({
  type: COURSES_RECOMMEND_DATA,
  value,
});
export const getCoursesByRecommendDataSuccess = (value) => ({
  type: COURSES_RECOMMEND_DATA_SUCCESS,
  value,
});
export const getCoursesByRecommendDataFail = (value) => ({
  type: COURSES_RECOMMEND_DATA_FAIL,
  value,
});

// get populer
export const getCoursesPopularData = (value) => ({
  type: COURSES_POPULAR_DATA,
  value,
});
export const getCoursesPopularDataSuccess = (value) => ({
  type: COURSES_POPULAR_DATA_SUCCESS,
  value,
});
export const getCoursesPopularDataFail = (value) => ({
  type: COURSES_POPULAR_DATA_FAIL,
  value,
});


//post enroll
export const postEnrollCourse = (value) => ({
  type: COURSES_DATA_ENROLL,
  value,
});
export const postEnrollCourseSuccess = (value) => ({
  type: COURSES_DATA_ENROLL_SUCCESS,
  value,
});
export const postEnrollCourseFail = (value) => ({
  type: COURSES_DATA_ENROLL_FAIL,
  value,
});

//complete material
export const postCompleteMaterial = (value) => ({
  type: COURSES_COMPLETE_MATERIAL,
  value,
});
export const postCompleteMaterialSuccess = (value) => ({
  type: COURSES_COMPLETE_MATERIAL_SUCCESS,
  value,
});
export const postCompleteMaterialFail = (value) => ({
  type: COURSES_COMPLETE_MATERIAL_FAIL,
  value,
});

//complete course
export const postCompleteCourse = (value) => ({
  type: COURSES_COMPLETE_COURSE,
  value,
});
export const postCompleteCourseSuccess = (value) => ({
  type: COURSES_COMPLETE_COURSE_SUCCESS,
  value,
});
export const postCompleteCourseFail = (value) => ({
  type: COURSES_COMPLETE_COURSE_FAIL,
  value,
});

//complete remove

export const removeCompleteState = (value) => ({
  type: COURSES_REMOVE_COMPLETE_STATE,
  value,
});

export const removeCourseDataId = (value) => ({
  type: COURSES_REMOVE_COURSE_DATA_ID,
  value,
});
export const removeCourseData = (value) => ({
  type: COURSES_REMOVE_COURSE_DATA,
  value,
});

export const removeCourseDataEnroll = (value) => ({
  type: COURSES_REMOVE_COURSE_DATA_ENROLL,
  value,
});
