import React, { useEffect, useRef, useState } from "react";
import Button1 from "./Button/Button1";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  SanitizeMaterialTypeId,
  formatRupiah,
  slugify,
} from "../../helper/functions";
import { redirectWa } from "../../helper/constant";
import { useDispatch } from "react-redux";
import { removeCourseDataId } from "../../redux/actions/CoursesActions";
import { postEnrollCoursesThunk } from "../../redux/thunks/CoursesThunks";
import { AuthData } from "../../helper/auth";

const Benefit = (props) => {
  const { image, type, children } = props;
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    let currWidth = window.innerWidth;
    var htmlElement = document.documentElement;

    // Mendapatkan tinggi konten pada halaman
    var contentHeight = htmlElement.scrollHeight;
    if (currWidth > 1199) {
      function handleScroll() {
        if (window.scrollY >= 500 && window.scrollY <= contentHeight + 300) {
          setSticky(true);
        } else if (window.scrollY >= contentHeight + 300) {
          setSticky(false);
        } else {
          setSticky(false);
        }
      }
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <section
      className={`heading__benefit my-5 ${
        sticky ? "sticky-parent__benefit" : " remove-sticky__benefit"
      }`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 shadow-md wrap__content bg-body p-0">
            {type === "nonmember" && (
              <div className={sticky ? "sticky-benefit__img" : ""}>
                <img
                  className="rounded-lg mb-4"
                  src={image}
                  alt=""
                  width="100%"
                  height="auto"
                />
              </div>
            )}
            {type === "member" && (
              <div>
                <iframe
                  src="https://www.youtube.com/embed/NgQVLbD-lQ0"
                  title="YouTube video player"
                  frameborder="0"
                ></iframe>
              </div>
            )}
            {type === "magang" && (
              <div className={sticky ? "sticky-benefit__img" : ""}>
                <img
                  className="rounded-lg mb-4"
                  src={image}
                  alt=""
                  width="100%"
                  height="auto"
                />
              </div>
            )}
            <div
              className={`container ${
                type === "magang" ? "px-4 pb-4 pt-0" : "p-4"
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Header = (props) => {
  const { price, children, isSubscribe, type, prereqNew } = props;
  return (
    <>
      <div>
        <div>
          {isSubscribe ? null : type === "magang" ? (
            <div className="d-flex align-items-center">
              <h1 className="fw-700">
                {price ? formatRupiah(price) : "Rp. 0"}
              </h1>
              <div className="fw-700 ml-2">/ akun</div>
            </div>
          ) : (
            <h3 className="fw-700">{price ? formatRupiah(price) : "Rp. 0"}</h3>
          )}

          <div className="my-3 fw-400">{children}</div>
        </div>
      </div>
    </>
  );
};

const Content = (props) => {
  const {
    isLogin,
    isSubscribe,
    handleClaim,
    learningPath,
    type,
    currCourse,
    isPassed,
    certification,
    enrollCourse,
  } = props;
  const router = useHistory();
  const dispatch = useDispatch();
  const { title, co_id } = useParams();
  const [dataOngoing, setDataOngoing] = useState("");
  const [exam, setExam] = useState(false);
  const [doneExam, setDoneExam] = useState(false);
  const [donePrereq, setDonePrereq] = useState(false);
  const [brevetExamLink, setBrevetExamLink] = useState("");
  useEffect(() => {
    if (certification) {
      const allCompleted = certification?.prerequisites?.every(
        (course) => course.is_completed === 1
      );
      setDonePrereq(allCompleted);
    }
  }, [certification]);
  useEffect(() => {
    let isStartedExam = true;
    let isDone = true;
    const coursesList = learningPath?.courses;
    if (coursesList?.length > 0) {
      // check latest course that people starts
      for (let i = 0; i < coursesList?.length; i++) {
        if (
          coursesList[i].status === "ongoing" ||
          coursesList[i].status === "not enrolled"
        ) {
          setDataOngoing(coursesList[i]);
          break;
        }
      }

      // check button started exam is already disable or no disable
      for (let i = 0; i < coursesList?.length; i++) {
        if (
          coursesList[i].status !== "completed" &&
          coursesList[i].course_type === "Pelatihan"
        ) {
          isStartedExam = false;
        }
        // else if (
        //   coursesList[i].status === "completed" &&
        //   coursesList[i].course_type === "Pelatihan"
        // ) {
        //   isStartedExam = true;
        // }
      }

      // check condition people who done the last exam
      for (let i = 0; i < coursesList?.length; i++) {
        if (coursesList[i].status !== "completed") {
          isDone = false;
          break;
        }
      }

      setDoneExam(isDone);
      setExam(isStartedExam);
    }
  }, [learningPath]);

  function getIndex() {
    const topics = certification.topics;
    for (let i = 0; i < topics.length; i++) {
      const materials = topics[i].materials;
      for (let j = 0; j < materials.length; j++) {
        if (materials[j].completed === 0) {
          const data = { index: i, indexL: j };
          return data;
        }
      }
    }
  }

  const handleGotoExam = () => {
    dispatch(removeCourseDataId());
    if (type !== "magang") {
      dispatch(
        postEnrollCoursesThunk({
          user_id: AuthData().userId,
          course_id: co_id,
        })
      );
    }
    if (certification?.is_completed) {
      const sanitizedMaterial = SanitizeMaterialTypeId(
        certification.topics[0].materials[0]
      );

      router.push(
        `/course/${title}/${co_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_0_0`
      );
    } else {
      const idxs = getIndex();
      const sanitizedMaterial = SanitizeMaterialTypeId(
        certification.topics[idxs.index].materials[idxs.indexL]
      );
      router.push(
        `/course/${title}/${co_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_${idxs.index}_${idxs.indexL}`
      );
    }

    // router.push(
    //   `${sanitizedMaterial.type}/${slugify(sanitizedMaterial.title)}/${
    //     sanitizedMaterial.id
    //   }_0_0`
    // );
  };

  // const getLatestOngoing = () => {
  // }
  // const sanitizedMaterial =
  // SanitizeMaterialTypeId();

  const [completeMaterial, setCompleteMaterial] = useState(true);

  useEffect(() => {
    if (certification) {
      let passed = true;
      const preq = certification?.prerequisites;
      if (preq?.length > 0) {
        for (let i = 0; i < preq?.length; i++) {
          if (preq[i].is_enrolled && !preq[i].is_completed) {
            passed = false;
            break;
          } else if (!preq[i].is_enrolled) {
            passed = false;
            break;
          }
        }
        setCompleteMaterial(passed);
      } else {
        setCompleteMaterial(true);
      }
    }
  }, [certification]);

  const downloadHandbook = (file) => {
    window.open(file, "_blank");
  };
  return (
    <>
      <div>
        {isLogin?.userId && isSubscribe ? (
          <>
            {type === "magang" && certification.is_enrolled === 0 ? (
              <button
                onClick={enrollCourse}
                className="btn btn-primary fw-700 w-100 p-3 btn__continue-test"
              >
                Mulai Pelatihan
              </button>
            ) : null}

            {type === "brevet" ||
            (type === "magang" && certification.is_enrolled) ? (
              isPassed ? null : (
                <Link
                  to={`/course/${slugify(
                    currCourse?.title ? currCourse.title : currCourse?.course
                  )}/${currCourse?.course_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className="btn btn-primary fw-700 w-100 p-3 btn__continue-test">
                    Lanjutkan Pelatihan
                  </button>
                </Link>
              )
            ) : null}
            {donePrereq && type === "magang" && (
              <Button1
                clas="btn btn-primary fw-700 w-100 p-3 btn__1"
                title="Pelatihan Selesai"
                disabled
              />
            )}
            {type !== "brevet" && type !== "magang" ? (
              dataOngoing ? (
                <Link
                  to={`/course/${slugify(dataOngoing.title)}/${
                    dataOngoing?.course_id
                  }`}
                >
                  <button className="btn btn-primary fw-700 w-100 p-3 btn__1">
                    Lanjutkan Pelatihan
                  </button>
                </Link>
              ) : (
                <Button1
                  clas="btn btn-primary fw-700 w-100 p-3 btn__1"
                  title="Pelatihan Selesai"
                  disabled
                />
              )
            ) : null}
          </>
        ) : (
          <a href={redirectWa} target="_blank" rel="noopener noreferrer">
            <button className="btn btn-primary fw-700 w-100 p-3 btn__1">
              Berlangganan Sekarang
            </button>
          </a>
        )}

        {isSubscribe ? (
          <>
            {type === "magang" && (
              <button
                // onClick={handleGotoExam}
                // disabled={!completeMaterial}
                disabled
                className="btn fw-700 w-100 p-3 mt-3 btn__flow-test"
              >
                Ikuti Ujian
              </button>
            )}
            {type === "brevet"
              ? // <Link
                //   to={`/course/${slugify(
                //     certification?.title
                //       ? certification.title
                //       : certification?.course
                //   )}/${certification?.course_id}`}
                //   style={{ textDecoration: "none" }}
                // >
                completeMaterial && (
                  <button
                    onClick={handleGotoExam}
                    className="btn fw-700 w-100 p-3 mt-3 btn__flow-test"
                  >
                    Ikuti Ujian
                  </button>
                )
              : // </Link>
                null}

            {type !== "brevet" && type !== "magang" ? (
              doneExam ? (
                <div>
                  <Button1
                    clas="btn btn-outline-secondary fw-700 w-100 p-3 mt-3"
                    title="Klaim Sertifikat"
                    handleClick={handleClaim}
                  />
                </div>
              ) : (
                <Link
                  to={`${`/course/${slugify(dataOngoing.title)}/${
                    dataOngoing?.course_id
                  }`}
              `}
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className={`btn btn-outline-${
                      exam ? "primary" : "secondary"
                    } fw-700 w-100 p-3 mt-3 ${exam ? "bt__mulai-ujian" : null}`}
                    disabled={exam ? false : true}
                  >
                    Mulai Ujian
                  </button>
                </Link>
              )
            ) : null}
          </>
        ) : (
          <button
            className="btn btn-outline-secondary fw-700 w-100 p-3 mt-3 btn__2"
            // disabled={currCourse?.detail ? false : true}
            disabled
            // onClick={() => downloadHandbook(currCourse?.detail)}
          >
            Unduh Detail Program
          </button>
        )}
      </div>
      <div className="mt-3">
        <div>{props.children}</div>
      </div>
    </>
  );
};

Benefit.Header = Header;
Benefit.Content = Content;

export default Benefit;
