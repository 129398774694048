import {
  //get provinces
  ORDERS_PROVINCES,
  ORDERS_PROVINCES_SUCCESS,
  ORDERS_PROVINCES_FAIL,
  //get cities
  ORDERS_CITIES,
  ORDERS_CITIES_SUCCESS,
  ORDERS_CITIES_FAIL,
  //get subdistricts
  ORDERS_SUBDISTRICTS,
  ORDERS_SUBDISTRICTS_SUCCESS,
  ORDERS_SUBDISTRICTS_FAIL,
  //get couriers
  ORDERS_COURIERS,
  ORDERS_COURIERS_SUCCESS,
  ORDERS_COURIERS_FAIL,
  //get price
  ORDERS_PRICE,
  ORDERS_PRICE_SUCCESS,
  ORDERS_PRICE_FAIL,
  //post order
  ORDERS_POST,
  ORDERS_POST_SUCCESS,
  ORDERS_POST_FAIL,
} from "../config/OrdersConfig";

//get provinces
export const getProvincesData = (value) => ({
  type: ORDERS_PROVINCES,
  value,
});
export const getProvincesDataSuccess = (value) => ({
  type: ORDERS_PROVINCES_SUCCESS,
  value,
});
export const getProvincesDataFail = (value) => ({
  type: ORDERS_PROVINCES_FAIL,
  value,
});

//get cities
export const getCitiesData = (value) => ({
  type: ORDERS_CITIES,
  value,
});
export const getCitiesDataSuccess = (value) => ({
  type: ORDERS_CITIES_SUCCESS,
  value,
});
export const getCitiesDataFail = (value) => ({
  type: ORDERS_CITIES_FAIL,
  value,
});

//get subdistricts
export const getSubdistrictsData = (value) => ({
  type: ORDERS_SUBDISTRICTS,
  value,
});
export const getSubdistrictsDataSuccess = (value) => ({
  type: ORDERS_SUBDISTRICTS_SUCCESS,
  value,
});
export const getSubdistrictsDataFail = (value) => ({
  type: ORDERS_SUBDISTRICTS_FAIL,
  value,
});

//get couriers
export const getCouriersData = (value) => ({
  type: ORDERS_COURIERS,
  value,
});
export const getCouriersDataSuccess = (value) => ({
  type: ORDERS_COURIERS_SUCCESS,
  value,
});
export const getCouriersDataFail = (value) => ({
  type: ORDERS_COURIERS_FAIL,
  value,
});

//get price
export const getPricesData = (value) => ({
  type: ORDERS_PRICE,
  value,
});
export const getPricesDataSuccess = (value) => ({
  type: ORDERS_PRICE_SUCCESS,
  value,
});
export const getPricesDataFail = (value) => ({
  type: ORDERS_PRICE_FAIL,
  value,
});

//post order
export const postOrdersData = (value) => ({
  type: ORDERS_POST,
  value,
});
export const postOrdersDataSuccess = (value) => ({
  type: ORDERS_POST_SUCCESS,
  value,
});
export const postOrdersDataFail = (value) => ({
  type: ORDERS_POST_FAIL,
  value,
});
