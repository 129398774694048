import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import {
  putUsersDataThunk,
  getUsersDataThunk,
} from "../redux/thunks/UsersThunks";
import "lazysizes";
import { AuthData } from "../helper/auth";
import { KeepSpesificKey, slugify } from "../helper/functions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function CertificateCard({ viewlist, data, dataCart, getCarts }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [trigger, setTrigger] = useState(false);
  const [cartData, setCartData] = useState([]);

  // useEffect(() => {
  //   console.log(dataCart);
  // }, [dataCart]);

  const addToCart = (e, data) => {
    // const tmp = [...cartData];
    if (dataCart) {
      let cart = KeepSpesificKey([...dataCart?.my_cart]);
      let req = {};
      if (!cart) cart = [];
      if (cart.includes(data.certificate_id)) {
        displayPopup(true, "Barang sudah di keranjang!", false);
      } else {
        cart.push(data.certificate_id);
        req.body = { cart: cart };
        req.id = AuthData().userId;
        setTrigger(true);
        dispatch(putUsersDataThunk(req));
        getCarts();
      }
    }
  };

  useEffect(() => {
    if (users.usersDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(
        true,
        "Terjadi masalah saat Menambahkan ke Keranjang",
        false
      );
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil Menambahkan ke Keranjang", true, "Ok");
    }
  }, [users]);

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersDataThunk(AuthData().userId));
      }
    });
  }

  const thumbnailContent = (data) => {
    return (
      <>
        <a
          href={`/download/${slugify(
            data?.course ? data?.course : data?.learning_path
          )}/${data?.certificate_id}`}
          target="_blank"
        >
          <img
            data-src={data?.certificate_url}
            className="lazyload course_card__thumbnail"
            width="255"
            height="153"
            alt={data?.certificate_url}
          />
        </a>
        <div className="label_sertifikat satukelas_flex success">Lulus</div>
        {data?.course_type !== "other" && (
          <div className="label_tab satukelas_flex">
            {data?.course_type === "Pelatihan"
              ? "Partisipasi"
              : data?.course_type === "Sertifikasi"
              ? "Kompetensi"
              : "Partisipasi"}{" "}
          </div>
        )}
      </>
    );
  };

  const infoContent = (data) => {
    return (
      <>
        <h4 className="course_card__lesson_title mb-0 list h4">
          {data?.course ? data?.course : data?.learning_path}
        </h4>
        {data?.course_type === "Sertifikasi" ? (
          <div>Skor: {data?.score}</div>
        ) : (
          <div style={{ visibility: "hidden" }}>-</div>
        )}
        <div className="mb-3 d-flex align-items-center">
          <span className="icon-clock mr-1"></span>
          <div>{moment(data?.created_at).format("DD MMMM YYYY")}</div>
        </div>
        <div
          className={`${
            data?.course_type !== "Sertifikasi"
              ? "d-flex justify-content-end"
              : "d-flex justify-content-between"
          }`}
        >
          {data?.course_type !== "Sertifikasi" ? null : (
            <div
              className="satukelas_flex btn mr-2"
              onClick={(e) => addToCart(e, data)}
            >
              <div className="size-icon">
                <span className="icon-file mr-2"></span>
              </div>
              Pesan Sertifikat
            </div>
          )}
          {data?.course_type !== "other" && (
            <a
              className="satukelas_flex btn transparent p-0"
              href={`/download/${slugify(
                data?.course ? data?.course : data?.learning_path
              )}/${data?.certificate_id}`}
              target={`_blank`}
            >
              <span className="icon-eye mr-1"></span>
              Preview
            </a>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {viewlist === "list" ? (
        <>
          <Card className="course-card course_card__wrapper list certificate_card__wrapper">
            <div className="d-flex p-4">
              <div className="course_card__thumbnail_wrapper listing_card mb-0">
                {thumbnailContent(data)}
              </div>
              <div className="course_card__info_wrapper card_height d-flex flex-column align-self-center mb-0">
                {infoContent(data)}
              </div>
            </div>
          </Card>
        </>
      ) : (
        <>
          <Card className="course-card course_card__wrapper certificate_card__wrapper">
            <div className="course_card__thumbnail_wrapper">
              {thumbnailContent(data)}
            </div>
            <div className="course_card__info_wrapper card_height">
              {infoContent(data)}
            </div>
          </Card>
        </>
      )}
    </>
  );
}

export default CertificateCard;
