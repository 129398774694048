import React, { useEffect, useState } from "react";
import { Button, Container, Form, Image, Modal } from "react-bootstrap";
import { withScorm } from "react-scorm-provider";
import starDisable from "./../../assets/images/star-disable.png";
import star from "./../../assets/images/star.png";
import { AuthData } from "../../helper/auth";
import { HTTP_SERVICE } from "../../services/HTTPService";
import { WEBINAR_URL } from "../../helper/constant";
import { SkValidate } from "../../components/common/SkValidate";
import { FeedbackService } from "../../http/FeedbackHttp";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import {
  postCompleteCourseThunk,
  postCompleteMaterialThunk,
} from "../../redux/thunks/CoursesThunks";
import { useDispatch, useSelector } from "react-redux";
import { courseService } from "../../http/CourseLengthHttp";
import { displayPopup } from "../../helper/functions";
import { removeCompleteState } from "../../redux/actions/CoursesActions";
import { scormService } from "../../http/ScormServiceHttp";

const ScormPage = (props) => {
  const { data, item } = props;
  const { course_id, id_material, material_type, slug, slug_m } = useParams();
  const dispatch = useDispatch();
  const [key, setKey] = useState(0);
  const [idSelected, setIdSelected] = useState();
  const [feedbackSend, setFeedbackSend] = useState();
  const [postComplete, setPostComplete] = useState(false);
  const [scoreData, setScoreData] = useState();
  const [scoresData, setScoresData] = useState();
  const courses = useSelector((state) => state.courses);
  const [feedback, setFeedback] = useState(false);
  const constraints = {
    review_suggestion: {
      presence: true,
      length: {
        minimum: 20,
      },
    },
  };

  useEffect(() => {
    if (courses.completeMaterialData === "success") {
      onCompleteCourse();
      dispatch(removeCompleteState());
    }
  }, [courses.completeMaterialData]);

  useEffect(() => {
    if (courses.completeCourseData === "success") {
      dispatch(removeCompleteState());
      displayPopup(
        false,
        "Berhasil Menyelesaikan English Course dan Silahkan Cek Sertifikasi Kamu! (Sertifikat akan siap dalam waktu sampai dengan 15 menit)",
        "Oke",
        ""
      );
    }
  }, [courses.completeCourseData]);

  useEffect(() => {
    if (postComplete) {
      onComplete();
    }
  }, [postComplete]);

  useEffect(() => {
    try {
      document.domain =
        window.location.hostname === "localhost"
          ? "localhost"
          : window.location.hostname === "satukelas.space"
          ? "satukelas.space"
          : "satukelas.com";
    } catch (err) {
      console.log(err);
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (data?.scorm_id) {
      setKey(key + 1);
    }
  }, [data]);

  const handleFinish = async () => {
    const payload = {
      id: id_material.split("_")[0],
    };
    if (scoreData) payload.score = scoreData;
    if (scoresData && Object.keys(scoresData)?.length > 0)
      payload.scores = scoresData?.scores;
    const response = await scormService.postScore(payload);
    if (response?.status == 200) {
      if (item.course_type === "Pelatihan") {
        window.history.back();
      } else {
        setFeedback(true);
      }
    }
  };

  function onComplete() {
    const payload = {
      material_id: id_material.split("_")[0],
      user_id: AuthData().userId,
    };
    dispatch(postCompleteMaterialThunk(payload));
  }

  /**
   * @function made a function for give an feedback to course
   * @param {*} e
   * @param {*} idx
   */
  const starRatingHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(id);
  };

  const handleMessage = (event) => {
    switch (event?.data?.message) {
      case "onScore":
        setScoreData(event?.data?.score);
        // myHeaders = new Headers({
        //   Authorization: "Basic " + qsa[1].substring(6),
        //   "Content-Type": "application/json",
        // });
        // fetch("https://api.satukelas.space/api/v2/scorms/score", {
        //   headers: myHeaders,
        //   method: "POST",
        //   body: JSON.stringify({
        //     id: qsa[2].substring(3),
        //     score: score,
        //   }),
        // })
        //   .then((res) => res.json())
        //   .then((finalRes) => console.log(finalRes));
        break;
      case "onSuspendData":
        setScoresData(event?.data?.score);
        // myHeaders = new Headers({
        //   Authorization: "Basic " + qsa[1].substring(6),
        //   "Content-Type": "application/json",
        // });
        // fetch("https://api.satukelas.space/api/v2/scorms/score", {
        //   headers: myHeaders,
        //   method: "POST",
        //   body: JSON.stringify({
        //     id: qsa[2].substring(3),
        //     scores: scoreData.scores,
        //   }),
        // })
        //   .then((res) => res.json())
        //   .then((finalRes) => console.log(finalRes));

        break;
      case "goBack":
        // alert(
        //   "Selamat Anda telah menyelesaikan pelatihan ini! Anda akan diarahkan kembali ke halaman awal dari pelatihan ini."
        // );
        // if (item.course_type === "Pelatihan") {
        //   console.log("pelatihan");
        //   // window.history.back();
        // } else {
        //   setFeedback(true);
        // }
        break;
      default:
        break;
    }
  };

  const starRatingDisableHandler = (e, idx) => {
    e.preventDefault();
    let id = idx + 1;
    setIdSelected(idSelected + id);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setFeedbackSend(newData);
  };

  const generateCertifHandler = async (e) => {
    e.preventDefault();
    const form = e.target;
    let payload = {};
    payload.user_id = AuthData().userId;
    payload.course_id = item?.course_id;
    payload.review_suggestion = feedbackSend?.review_suggestion;
    payload.score_satisfaction = idSelected === undefined ? 5 : idSelected;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      try {
        const response = await FeedbackService.postFeedback(payload);
        if (response !== undefined) {
          setPostComplete(true);
          setFeedback(false);
        }
      } catch (error) {
        setFeedback(false);
        setPostComplete(true);
        // const payloadC = {
        //   course_id: course_id,
        //   user_id: AuthData().userId,
        // };
        // dispatch(postCompleteCourseThunk(payloadC));
      }
    }
  };

  async function onCompleteCourse() {
    try {
      const response = await courseService.getCourse(`/${course_id}`);
      const payloadC = {
        course_id: course_id,
        user_id: AuthData().userId,
      };
      if (response?.is_completed == 1) {
        dispatch(postCompleteCourseThunk(payloadC));
      } else {
        displayPopup(
          false,
          "Selesaikan semua pelatihan terlebih dahulu!",
          "Oke"
        );
      }
    } catch (err) {
      displayPopup(false, "Terjadi kesalahan! Silahkan hubungi admin", "Oke");
      console.log(err);
    }
  }

  // const handleUploadScore = async (score) => {
  //   try {
  //     const payload = {
  //       id: data.scorm_id,
  //       score: parseInt(score),
  //     };
  //     console.log(payload);
  //     const response = await HTTP_SERVICE.post("v2/scorms/score", payload);
  //     console.log(response, "<<<<");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <Container
      className="lesson-container"
      style={{ display: "flex", flexDirection: "column", gap: "8px" }}
    >
      <div style={{ height: "700px" }}>
        {data?.scorm_id ? (
          <iframe
            id="sco"
            src={`${WEBINAR_URL}/public/scorm/scorm-lesson-1/?scorm_id=${
              data?.scorm_id
            }&token=${AuthData().authorization}&id=${data?.id}`}
            onLoad={() => {
              console.log("onLoad");
              console.log(item);
            }}
            width={"100%"}
            height={"100%"}
            key={key}
            allow="microphone"
          ></iframe>
        ) : null}
      </div>
      {scoreData || scoresData ? (
        <Button
          onClick={handleFinish}
          className="w-100"
          style={{ margin: "16px" }}
        >
          Finish
        </Button>
      ) : (
        ""
      )}
      <Modal show={feedback} onHide={() => setFeedback(!feedback)} centered>
        <div className="card_inside__padding">
          <div className="d-flex flex-row justify-content-center my-4 mb-5">
            {/* this condition will be start when user want to select the score */}
            <>
              {idSelected !== undefined &&
                [...Array(idSelected)].map((item, i) => {
                  return (
                    <div onClick={(e) => starRatingHandler(e, i)} key={i}>
                      <Image
                        className="mx-1"
                        style={{ width: "32px" }}
                        src={star}
                      />
                    </div>
                  );
                })}
            </>

            {/* this condition will be start if the score under 5 */}
            <>
              {idSelected !== undefined &&
                [...Array(5 - idSelected)].map((item, i) => {
                  return (
                    <div
                      onClick={(e) => starRatingDisableHandler(e, i)}
                      key={i}
                    >
                      <Image
                        className="mx-1"
                        style={{ width: "32px" }}
                        src={starDisable}
                      />
                    </div>
                  );
                })}
            </>
            {/* this condition will be start if idSelected have a undefined value */}
            {idSelected === undefined &&
              [...Array(5)].map((value, index) => {
                return (
                  <div onClick={(e) => starRatingHandler(e, index)} key={index}>
                    <Image
                      className="mx-1"
                      style={{ width: "32px" }}
                      src={star}
                    />
                  </div>
                );
              })}
          </div>
          <Form onSubmit={generateCertifHandler}>
            {/* <Form.Group> */}
            {/* </Form.Group> */}
            <div className="mb-4 form-group">
              <Form.Control
                style={{ margin: "0 auto " }}
                className="p-3 d-flex"
                as="textarea"
                rows={5}
                placeholder="Isi feedback disini, Contoh: course ini sangat berguna untuk saya terimakasih!"
                id="review_suggestion"
                name="review_suggestion"
                onChange={handleChange}
              />
              <div className="messages"></div>
            </div>

            <Button className="button-next w-100" type="submit">
              Kirim
            </Button>

            {/* <Button className="button-next" type="submit">
                        Kirim dan Unduh Sertifikat
                      </Button>
                     */}
          </Form>
        </div>
      </Modal>
    </Container>
  );
};

const EnhancedComponent = withScorm()(ScormPage);

export default EnhancedComponent;
