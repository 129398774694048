import React from "react";
import { Image } from "react-bootstrap";
import icon1 from "../../assets/images/cdlc/service/icon-domain.png";
import icon2 from "../../assets/images/cdlc/service/icon-ui.png";
import icon3 from "../../assets/images/cdlc/service/icon-course.png";
const ServiceCdlc = () => {
  return (
    <div className="d-flex align-items-center flex-wrap">
      <div className="col-12 col-lg-4 col-xl-4 p-4 wrapp__service-cdlc">
        <div className="mb-2">
          <Image src={icon1} alt="" />
        </div>
        <div>
          <h5 className="wording__service-cdlc">Server dan Domain yang Fleksibel</h5>
          <p>Perusahaan dapat mengubah server dan domain sesuai dengan nama perusahaan secara fleksibel.</p>
        </div>
      </div>
      <div className="col-12 col-lg-4 col-xl-4 p-4 wrapp__service-cdlc">
        <div className="mb-2">
          <Image src={icon2} alt="" />
        </div>
        <div>
          <h5 className="wording__service-cdlc">Atur Tampilan Website Sesuai Kebutuhan</h5>
          <p>Tampilan website dapat disesuaikan dengan identitas perusahaan sehingga terlihat lebih menarik.</p>
        </div>
      </div>
      <div className="col-12 col-lg-4 col-xl-4 p-4 wrapp__service-cdlc">
        <div className="mb-2">
          <Image src={icon3} alt="" />
        </div>
        <div>
          <h5 className="wording__service-cdlc">Konten Belajar Lengkap dari Berbagai Disiplin Ilmu</h5>
          <p>Belajar tanpa perlu bingung. Pilih konten sesuai kebutuhan karyawan untuk tingkatkan kompetensi.</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCdlc;
