import React from "react";
import { Card, } from "react-bootstrap";
import "../../assets/css/Courses/CourseDetailTitle.css";

function ATPIBanner({ type }) {
  return (
    <>
      <Card
        className="course_detail__container p-0"
        style={{ backgroundImage: `url(https://satukelas-thumbnails.ap-south-1.linodeobjects.com/9258117944889960.png)` }}
      >
        <div className="wrapper_course__detail">
          <div className="course_detail__wrapper">
            <div className="course_detail__learning_path__wrapper"></div>

            <h1 className="course_detail__course_title h1">Sertifikasi : {type.toUpperCase()}</h1>
            <div className="course_detail__info__wrapper"></div>

            <div className="course_detail__summary__wrapper"></div>
            <div className="d-flex justify-content-between"></div>
          </div>
        </div>
      </Card>
    </>
  );
}
export default ATPIBanner;
