import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { useParams } from "react-router";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";
import { removeCourseDataId } from "../../redux/actions/CoursesActions";
import { useLocation, useHistory } from "react-router-dom";
import { AuthIsLogin } from "../../helper/auth";
import { useDispatch, useSelector } from "react-redux";
import { SanitizeMaterialTypeId, slugify } from "../../helper/functions";

function HandlerPage() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { source } = params;
  const parameter = new URLSearchParams(location.search);
  const courseID = parameter.get("course_id")
  const courses = useSelector((state) => state.courses);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    //#1 check if trigger
    if (trigger) {
      // #2 get course_id and assigment_idd
      const assignment_id = location.state?.assignment_id;
      const course_id = location.state?.course_id;
      switch (source) {
        case "task":
          if (assignment_id && course_id) {
            //#3 find the accordion index
            if (courses.coursesDataId.topics) {
              const topics = courses.coursesDataId.topics;
              let topicIndex = 0;
              let materialIndex = 0;
              let titleCourse = slugify(courses.coursesDataId.title);
              let titleAss = "";
              for (let i = 0; i < topics.length; i++) {
                const topic = topics[i];
                for (let j = 0; j < topic.materials.length; j++) {
                  const material = topic.materials[j];
                  if (material.assignment_id == assignment_id) {
                    topicIndex = i;
                    materialIndex = j;
                    titleAss = slugify(material.title);
                    break;
                  }
                }
              }
              //#4 redirect
              history.push(
                `/course/${titleCourse}/${course_id}/assignments/${titleAss}/${assignment_id}_${topicIndex}_${materialIndex}`
              );
            }
          }
          break;

        case "attempt":
          if (courseID) {
            //#3 find nearest lesson id
            if (courses.coursesDataId.topics) {
              const topics = courses.coursesDataId.topics;
              let topicIndex = 0;
              let materialIndex = 0;
              let titleCourse = slugify(courses.coursesDataId.title);
              let title = "";
              let id = "";
              let typeMats = "";
              for (let i = 0; i < topics.length; i++) {
                const topic = topics[i];
                for (let j = 0; j < topic.materials.length; j++) {
                  const material = topic.materials[j];
                  if (material.type == "lessons") {
                    topicIndex = i;
                    materialIndex = j;
                    title = slugify(material.title);
                    id = material.lesson_id;
                    typeMats = material.type;
                    break;
                  }
                  if (
                    i >= topics.length - 1 &&
                    j >= topic.materials.length - 1
                  ) {
                    topicIndex = 0;
                    materialIndex = 0;
                    const sanitizedMaterial = SanitizeMaterialTypeId(material);
                    title = slugify(sanitizedMaterial.title);
                    id = sanitizedMaterial.id;
                    typeMats = sanitizedMaterial.type;
                    break;
                  }
                }
              }
              // #4 redirect
              history.replace(
                `/course/${titleCourse}/${course_id}/${typeMats}/${title}/${id}_${topicIndex}_${materialIndex}`
              );
            }
          }
          break;
        default:
          // redirect to 404
          break;
      }
    }
    if (!courses.coursesDataId || courses.coursesDataId?.length === 0) {
      setTrigger(true);
    }
  }, [courses.coursesDataId]);

  useEffect(() => {
    // check login
    AuthIsLogin(history);
    const course_id = location.state?.course_id ? location.state.course_id : courseID;
    // check course_id
    if (course_id) {
      dispatch(removeCourseDataId());
    } else history.push(`/not-found`);
  }, []);

  useEffect(() => {
    const course_id = location.state?.course_id ? location.state.course_id : courseID;

    if (trigger)
      dispatch(getCoursesDataThunk({ type: "id", param: `/${course_id}` }));
  }, [trigger]);

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={6}>
          <div className="text-center">
            <div className="login-title">Mengarahkan Anda ke tujuan...</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default HandlerPage;
