/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Container } from "react-bootstrap";
import Image from "./../../assets/images/home-certificationpage/image-contact.png";

const Contact = () => {
  return (
    <section className="my-5">
      <Container className="ml-xl-5">
        <div className="pl-0 pr-0 col-xl-8">
          <div className="bg__contact">
            <div className="col-12 col-md-6 col-lg-8">
              <h6 className="fw-400 text-white mb-3">
                Ada yang Anda tidak mengerti? Atau mau berlangganan? Bicara
                dengan tim kami sekarang juga!
              </h6>
              <a
                href="https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas"
                target="_blank"
                className="btn bg-white rounded-3 fw-700 btn__contact p-3 border-0"
              >
                Hubungi Sekarang
              </a>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <img src={Image} alt="" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
