import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { brevetService } from "../../../http/BrevetHttp";
import { slugify } from "../../../helper/functions";
import { defaulThumbnail } from "../../../helper/constant";
import Pagination from "../../Layout/Pagination";

export default function CourseSchemeCertification(props) {
    const { title, type, item } = props;
    // console.log(item);
    const history = useHistory();
    const size = 5;
    const addressURL = history.location.pathname.split("/");
    const detailAddress = addressURL[2].split("-");
    const [data, setData] = useState();
    const [dataPagination, setDataPagination] = useState();
    const [active, setActive] = useState(1);

    useEffect(() => {
        switch (type) {
            case 'try out':
                getCourseDetail("try%20out", "try%20out", detailAddress[3], size, active);
                break;
            case 'explain':
                getCourseDetail("pembahasan%20soal", "soal", detailAddress[3], size, active);
                break;
            case 'tutorial':
                getCourseDetail("tutorial", "tutorial", detailAddress[3], size, active);
                break;
            case 'related':
                setData(item);
                break;
            default:
                console.log('error!')
        }
    }, [type]);

    /**
   * function get data course by course type, discussion topic, and scheme
   */
    const getCourseDetail = async (type, discussion, value, size, active) => {
        try {
            const response = await brevetService.getCourseBrevetList(type, discussion, value, size, active);
            if (response.items.length > 0) {
                setData(response.items);
                setDataPagination(response.pagination);
            }
        } catch (error) {
            console.log(error);
        }
    };

    function paginationSelect(newActive) {
        setActive(newActive);
        console.log(type);
        if (type === 'try out') {
            getCourseDetail("try%20out", "try%20out", detailAddress[1], size, newActive);
        } else if (type === 'explain') {
            getCourseDetail("pembahasan%20soal", "soal", detailAddress[1], size, newActive);
        } else if (type === 'tutorial') {
            getCourseDetail("tutorial", "tutorial", detailAddress[1], size, newActive);
        } else {
            console.log('error');
        }
    }


    return (
        <div className="course_front__prereq__container">
            <div id="prereq" className="course_front__kurikulum_title h2">
                {title}
            </div>
            {data?.length > 0 &&
                data.map((items, index) => {
                    return (
                        <div key={index} className="course_front__prerequisite_item">
                            <div className="course_front__prerequisite_img__wrapper mr-3">
                                {items.thumbnail ?
                                    <img alt="prerequisite thumbnail" data-src={items.thumbnail} className="lazyload" />
                                    :
                                    <img alt="prereq thumbnail" data-src={defaulThumbnail} className="lazyload" />
                                }
                            </div>
                            <div style={{ display: "grid" }}>
                                <a
                                    href={`/course/${slugify(type === "related" ? items.course : items.title)}/${items.course_id
                                        }`}
                                    target={`_blank`}
                                    className={`course_front__prerequisite__course mb-1`}
                                >
                                    {type === "related" ? items.course : items.title}
                                </a>
                            </div>
                        </div>
                    )
                })}
            {type !== 'related' &&
                <>
                    {dataPagination ? (
                        <Pagination
                            size={size}
                            totalRows={dataPagination.totalItems}
                            totalPages={dataPagination.totalPages}
                            paginationSelect={paginationSelect}
                            active={active}
                        />
                    ) : null}
                </>
            }
        </div>
    )
}