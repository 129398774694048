import React from "react";
import LpCdlc from "../../components/LpCdlc";
const PageCdlc = () => {
  return (
    <div>
      <LpCdlc typePage="cdlc" />
    </div>
  );
};

export default PageCdlc;
