import React, { useEffect, useState } from "react";
import { Carousel, Image, Row } from "react-bootstrap";
import "../../assets/css/CdlcPage/CdlcPage.css";
import bgLms from "../../assets/images/cdlc/banner/LMS/bg-lms.png";
import iconShapeStepLeft from "../../assets/images/cdlc/banner/StepBanner/icon-shape-left.png";
import iconShapeStepRight from "../../assets/images/cdlc/banner/StepBanner/icon-shape-right.png";
import stepImage from "../../assets/images/cdlc/banner/StepBanner/step-image.png";
import circle1 from "../../assets/images/cdlc/banner/circle-1.png";
import circle2 from "../../assets/images/cdlc/banner/circle-2.png";
import circle3 from "../../assets/images/cdlc/banner/circle-3.png";
import circleSatukelas from "../../assets/images/cdlc/banner/satukelas-circle.png";
import Client from "../../pages/CourseFront/Client";
import BannerCta from "./BannerCta";
import BannerKatalog from "./BannerKatalog";
import BannerLp from "./BannerLp";
import FormPromise from "./FormPromise";
import ServiceCdlc from "./ServiceCdlc";
import { staticListLms, staticListStep } from "./static-data";

const LpCdlc = (props) => {
  const { typePage } = props;
  const [counter, setCounter] = useState(1);
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    if (counter > 0) {
      if (counter === 400) {
        return false;
      } else {
        setTimeout(() => setCounter(counter + 1), 25);
      }
    }
  }, [counter]);

  const handleScroll = () => {
    if (window.scrollY >= 1050) {
      setAddClass(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section style={{ overflowX: "hidden" }}>
      <div>
        <Carousel id="home" controls={false} indicators={false} className="position-relative">
          <Carousel.Item interval={4000} className={typePage === "cdlc" ? "hero__shot-cdlc" : "hero__shot-dlcs"}>
            <div className="h-100 px-xl-5 py-5">
              <Row className="h-inherit">
                <div className="col-12 col-xl-7 align-items-center d-flex mb-3 mb-xl-0">
                  {typePage === "cdlc" ? (
                    <div className="w-100 border-0 d-block">
                      <h2 className="title__heroshot-cdlc">
                        Tingkatkan Corporate Growth Dengan <span>Corporate Digital Learning Center (CDLC)</span>
                      </h2>
                      <p className="desc__heroshot-cdlc mt-3 ">Learning Management System yang Terpersonalisasi Menggunakan Domain Perusahaan Anda!</p>
                    </div>
                  ) : (
                    <div className="w-100 border-0 d-block">
                      <h2 className="title__heroshot-dlcs">Mudahkan kegiatan belajar mengajar di sekolah atau perguruan tinggi Anda!</h2>
                      <p className="desc__heroshot-dlcs mt-3 ">
                        Jembatani para pengajar dan pelajar untuk merencanakan, mengatur, dan melaksanakan pembelajaran melalui <em>Digital Learning Center for School (DLCS)</em>
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-12 col-xl-5 d-flex justify-content-center align-items-center">
                  <FormPromise typePage={typePage} />
                </div>
              </Row>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="parent__client-cdlc">
        <div className="px-xl-5 d-flex flex-wrap align-items-center">
          <div className="col-12 col-xl-6 my-4">
            <h2 className="wording__client-cdlc">Telah Digunakan oleh lebih dari +{counter} perusahaan</h2>
            <p>Gabung bersama 400 Perusahaan lainnya yang telah mempercayai satukelas untuk meningkatkan keterampilan pegawainya!</p>
          </div>
          <div className="col-12 col-xl-6 d-flex align-items-center justify-content-center client__cdlc-page">
            <Client col={12} />
          </div>
        </div>
      </div>
      <div className="parent__service-cdlc">
        <div className="px-xl-5 ">
          <h2 className="wording__title-service-cdlc">Miliki Learning Center untuk Perusahaan dengan CDLC</h2>
          <div className="mt-5">
            <ServiceCdlc />
          </div>
        </div>
      </div>
      <div className="parent__banner-cdlc">
        <div>
          <Carousel id="home" controls={false} indicators={false} className="position-relative">
            <Carousel.Item interval={4000} className="hero__banner-cdlc">
              <div className="h-100 px-xl-5 pt-5 pb-2">
                <div className="d-flex align-items-center flex-wrap">
                  <div className="col-12 col-xl-6 mb-3 mb-xl-0">
                    <h2 className="wording__title-banner-cdlc">
                      Beragam kemudahan dari tiga aplikasi yang dihadirkan dalam <br />
                      satu platform
                    </h2>
                  </div>
                  <div className="col-12 col-xl-6">
                    <div className={`w-100 d-flex align-items-center banner__wrapp-bulet  ${addClass ? "banner__wrapp-circle" : "justify-content-center"}`}>
                      <div className={`banner__circle-satukelas ${addClass ? "banner__display" : ""}`}>
                        <Image src={circleSatukelas} alt="" />
                      </div>
                      <a href="#lp" className={`banner__display ${addClass ? "banner__circle-3" : ""}`}>
                        <Image src={circle1} alt="" />
                      </a>
                      <a href="#lms" className={`banner__display ${addClass ? "banner__circle-2" : ""}`}>
                        <Image src={circle2} alt="" />
                      </a>
                      <a href="#cms" className={`banner__display ${addClass ? "banner__circle-1" : ""}`}>
                        <Image src={circle3} alt="" />
                      </a>
                    </div>
                    <p className="desc__banner-cdlc">klik untuk melihat selengkapnya</p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div className="parent__banner-katalog-cdlc">
        <div className="px-xl-5">
          <BannerKatalog contentType={typePage} />
        </div>
      </div>
      <div className="parent__banner-cta position-relative">
        <div className="px-xl-5 py-5">
          <BannerCta />
        </div>
      </div>
      <div className="parent__banner-reverse-cta position-relative">
        <div className="px-xl-5 py-5">
          <BannerCta contentText="reverse" />
        </div>
      </div>
      <div className="parent__banner-lp" id="lp">
        <BannerLp />
      </div>
      <div className="parent__banner-lms" id="lms">
        <div className="px-xl-5">
          <span className="wording__subtitle-banner-lms d-flex justify-content-center">Learning Management System</span>
          <h2 className="wording__title-banner-lms">Mengelola pembelajaran untuk proses belajar yang terkontrol</h2>
          <div>
            <Image src={bgLms} alt="" width="100%" />
          </div>
          <div className="content__banner-lms ml-xl-5 d-flex flex-wrap justify-content-center">
            {staticListLms.map((item, index) => (
              <div className="col-12 col-md-6" key={index}>
                <div className="ml-md-5">
                  <h6 className="subheading__banner-lms-reverse">{item.title}</h6>
                  <ul>
                    {item.listItem.map((list, i) => (
                      <li key={i} className="list__banner-lms-reverse">
                        {list.list}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="parent__banner-cms" id="cms">
        <div>
          <BannerLp lpType="cms" />
        </div>
      </div>
      <div className="parent__section-step">
        <div className="px-xl-5">
          <div className="wrapp__section-step">
            <h2 className="wording__title-section-step">Cara satukelas Mempersiapkan Pelatihan Terbaik</h2>
            <div className="col-12 d-none d-md-flex justify-content-center wrapp__image-step">
              <Image src={stepImage} style={{ objectFit: "contain" }} />
            </div>
            <div className="d-flex justify-content-center wrapp_content-step">
              {/* Mobile View */}
              <div className="col-12 col-md-4 d-md-none px-1">
                {staticListStep.map((item, index) => (
                  <div className="col-12 px-1 d-flex justify-content-center flex-wrap" key={index}>
                    <h5 className="title__desc-step">{item.title}</h5>
                    <p className="desc__step">{item.desc}</p>
                  </div>
                ))}
              </div>
              {/* Mobile View */}

              {/* Desktop View */}
              {staticListStep.map((item, index) => (
                <div className="col-12 col-md-4 d-none d-md-flex justify-content-center flex-wrap" key={index}>
                  <h5 className="title__desc-step">{item.title}</h5>
                  <p className="desc__step">{item.desc}</p>
                </div>
              ))}
              {/* Desktop View */}
            </div>
            <div>
              <div className="icon__shape-step-left">
                <Image src={iconShapeStepLeft} />
              </div>
              <div className="icon__shape-step-right">
                <Image src={iconShapeStepRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="parent__banner-bottom">
        <div className="px-xl-5 py-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-12 col-xl-7 mb-5 mb-xl-0 d-flex align-items-center">
              <div>
                <h2 className="wording__title-bottom-banner">Maksimalkan Perkembangan Karyawan Anda Sekarang!</h2>
                <p className="desc__bottom">Cari Tahu Lebih Lanjut dengan Konstultasi Langsung.</p>
              </div>
            </div>
            <div className="col-12 col-xl-5 d-flex justify-content-center">
              <FormPromise typePage={typePage} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LpCdlc;
