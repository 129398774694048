import { setCookie, getCookie, deleteCookie } from "./functions";
const OneSignal = typeof window !== "undefined" ? window.OneSignal : [];
const sha256 = require("js-sha256");

export function AuthData() {
  let a = getCookie("auth");
  if (a) a = JSON.parse(a);
  return a;
}

export function editAuthData(key, value) {
  // modif auth value
  let a = getCookie("auth");
  if (a) a = JSON.parse(a);
  const newAuth = { ...a };
  newAuth[key] = value;
  setCookie("auth", JSON.stringify(newAuth), 13); //expired on 13 days
}

export function AuthIsLogin(history, returnValue) {
  let a = getCookie("auth");
  if (!a) {
    if (returnValue) return false;
    else history.push(`/login`);
  } else return true;
}

export function AuthIsLoginSinarmas() {
  let a = getCookie("auth");
  return a;
}

export function AuthLogin(auth) {
  let newAuth = {};
  auth.password = sha256(auth.password);
  newAuth = {
    userId: auth.userId,
    isBnsp: auth.isBnsp,
    authorization: Buffer.from(`${auth.username}:${auth.password}`).toString("base64"),
  };
  setCookie("auth", JSON.stringify(newAuth), 13); //expired on 13 days
}

export async function AuthLoginRedirected(auth, destination, history) {
  let newAuth = {};
  newAuth = {
    userId: auth.userId,
    authorization: auth.authorization,
    role: auth.role,
  };
  await setCookie("auth", JSON.stringify(newAuth), 13);
  let fullDestination = "/";
  const destinations = destination.split("|");
  for (let i = 0; i < destinations.length; i++) {
    if (destinations[i]) fullDestination += destinations[i] + "/";
  }
  if (fullDestination) history.push(fullDestination);
  else history.push("/");
}

export function AuthIsNotLogin(history, checkAdmin) {
  let a = getCookie("auth");
  if (a) history.push(`/`);
}

export function AuthLogout(history) {
  if (OneSignal) {
    OneSignal.push(() => {
      OneSignal.removeExternalUserId();
    });
  }
  deleteCookie("auth");
  history.push(`/`);
}

export function AuthLogoutSinarmas() {
  if (OneSignal) {
    OneSignal.push(() => {
      OneSignal.removeExternalUserId();
    });
  }
  deleteCookie("auth");
  window.location.reload();
}
