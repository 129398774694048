import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";

const SinarmasCertificate = ({ data, course_id }) => {
  const exist = false;
  return (
    <Card className="sertificate_wrapper__container mb-4">
      <div className="course_front__kurikulum_title ">
        Download Sertifikat Saya
      </div>
      <div className="sinarmas_certificate_wrapper">
        {data?.length > 0 ? (
          data.map((item, index) => {
            if (item?.course_id == course_id) {
              return (
                <Card
                  className="course-card course_card__wrapper certificate_card__wrapper"
                  style={{ minWidth: "200px" }}
                >
                  <div className="course_card__thumbnail_wrapper">
                    {thumbnailContent(item)}
                  </div>
                  <div className="course_card__info_wrapper card_height">
                    {infoContent(item)}
                  </div>
                </Card>
              );
            } else if (index + 1 >= data?.length && !exist) {
              // return <div>Belum ada sertifikat</div>;
            }
          })
        ) : (
          <div>Belum ada sertifikat</div>
        )}
      </div>
    </Card>
  );
};

export default SinarmasCertificate;

const thumbnailContent = (data) => {
  return (
    <>
      <a href={data?.certificate_url} target="_blank">
        <img
          data-src={data?.certificate_url}
          className="lazyload course_card__thumbnail"
          width="255"
          height="153"
          alt={data?.certificate_url}
        />
      </a>
      <div className="label_sertifikat satukelas_flex success">Lulus</div>
      <div className="label_tab satukelas_flex">
        {data?.course_type === "Pelatihan"
          ? "Partisipasi"
          : data?.course_type === "Sertifikasi"
          ? "Kompetensi"
          : "Partisipasi"}{" "}
      </div>
    </>
  );
};

const infoContent = (data) => {
  return (
    <>
      <h4 className="course_card__lesson_title mb-0 list h4">{data?.course}</h4>
      <div className="mb-3 d-flex align-items-center">
        <span className="icon-clock mr-1"></span>
        <div>{moment(data?.created_at).format("DD MMMM YYYY")}</div>
      </div>
      <div
        className={`${
          data?.course_type !== "Sertifikasi"
            ? "d-flex justify-content-end"
            : "d-flex justify-content-between"
        }`}
      >
        <a href={data?.certificate_url} target="_blank">
          <div className="satukelas_flex btn transparent p-0">Download</div>
        </a>
      </div>
    </>
  );
};
