import {
  //get
  COURSES_DATA,
  COURSES_DATA_SUCCESS,
  COURSES_DATA_FAIL,
  COURSES_DATA_ID_SUCCESS,
  //get by division
  COURSES_RECOMMEND_DATA,
  COURSES_RECOMMEND_DATA_SUCCESS,
  COURSES_RECOMMEND_DATA_FAIL,
  COURSES_BREVET_DATA,
  COURSES_BREVET_DATA_SUCCESS,
  COURSES_BREVET_DATA_FAIL,
  //get popular
  COURSES_POPULAR_DATA,
  COURSES_POPULAR_DATA_SUCCESS,
  COURSES_POPULAR_DATA_FAIL,
  //post enroll
  COURSES_DATA_ENROLL,
  COURSES_DATA_ENROLL_SUCCESS,
  COURSES_DATA_ENROLL_FAIL,
  //complete material
  COURSES_COMPLETE_MATERIAL,
  COURSES_COMPLETE_MATERIAL_SUCCESS,
  COURSES_COMPLETE_MATERIAL_FAIL,
  //complete course
  COURSES_COMPLETE_COURSE,
  COURSES_COMPLETE_COURSE_SUCCESS,
  COURSES_COMPLETE_COURSE_FAIL,
  //complete remove
  COURSES_REMOVE_COMPLETE_STATE,
  COURSES_REMOVE_COURSE_DATA_ID,
  COURSES_REMOVE_COURSE_DATA,
  COURSES_REMOVE_COURSE_DATA_ENROLL,
} from "../config/CoursesConfig";

const intialState = {
  coursesData: [],
  coursePelatihanLengthData: null,
  courseCaseStudyLengthData: null,
  courseTryOutLengthData: null,
  coursesPopularData: null,
  courseLiveLengthData: null,
  courseBrevetData: null,
  coursesByRecommendData: null,
  coursesDataId: [],
  courseDataEnroll: null,
  completeMaterialData: "",
  completeCourseData: "",
  isLoading: false,
};

export const CoursesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case COURSES_DATA:
      return { ...state, isLoading: true, coursesData: [] };
    case COURSES_DATA_SUCCESS:
      return { ...state, isLoading: false, coursesData: action.value };
    case COURSES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, coursesDataId: action.value };
    case COURSES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        coursesData: "error",
        coursesDataId: "error",
      };

    case COURSES_BREVET_DATA:
      return { ...state, isLoading: true, courseBrevetData: null };
    case COURSES_BREVET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courseBrevetData: action.value,
      };
    case COURSES_BREVET_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        courseBrevetData: "error",
      };

    case COURSES_RECOMMEND_DATA:
      return { ...state, isLoading: true, coursesByRecommendData: null };
    case COURSES_RECOMMEND_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coursesByRecommendData: action.value,
      };
    case COURSES_RECOMMEND_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        coursesByRecommendData: "error",
      };

    //get popular
    case COURSES_POPULAR_DATA:
      return { ...state, isLoading: true, coursesPopularData: null };
    case COURSES_POPULAR_DATA_SUCCESS:
      return { ...state, isLoading: false, coursesPopularData: action.value };
    case COURSES_POPULAR_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        coursesPopularData: "error",
      };

    //post
    case COURSES_DATA_ENROLL:
      return { ...state, isLoading: true };
    case COURSES_DATA_ENROLL_SUCCESS:
      return { ...state, isLoading: false, courseDataEnroll: "success" };
    case COURSES_DATA_ENROLL_FAIL:
      return { ...state, isLoading: false, courseDataEnroll: "fail" };
    //complete material
    case COURSES_COMPLETE_MATERIAL:
      return { ...state, isLoading: true };
    case COURSES_COMPLETE_MATERIAL_SUCCESS:
      return { ...state, isLoading: false, completeMaterialData: "success" };
    case COURSES_COMPLETE_MATERIAL_FAIL:
      return { ...state, isLoading: false, completeMaterialData: "fail" };
    //complete course
    case COURSES_COMPLETE_COURSE:
      return { ...state, isLoading: true };
    case COURSES_COMPLETE_COURSE_SUCCESS:
      return { ...state, isLoading: false, completeCourseData: "success" };
    case COURSES_COMPLETE_COURSE_FAIL:
      return { ...state, isLoading: false, completeCourseData: "fail" };
    //remove
    case COURSES_REMOVE_COMPLETE_STATE:
      return { ...state, completeCourseData: "", completeMaterialData: "" };
    case COURSES_REMOVE_COURSE_DATA_ID:
      return { ...state, coursesDataId: [] };
    case COURSES_REMOVE_COURSE_DATA:
      return { ...state, coursesData: [] };
    case COURSES_REMOVE_COURSE_DATA_ENROLL:
      return { ...state, courseDataEnroll: null };
    default:
      return state;
  }
};
