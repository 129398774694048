import {
  //get
  ASSETS_DATA,
  ASSETS_DATA_SUCCESS,
  ASSETS_DATA_FAIL,
  //post
  ASSETS_DATA_POST,
  ASSETS_DATA_POST_SUCCESS,
  ASSETS_DATA_POST_FAIL,
  REMOVE_ASSETS_DATA_POST,
} from "../config/AssetsConfig";

const intialState = {
  assetsData: [],
  assetsDataPost: [],
  isLoading: false,
};

export const AssetsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case ASSETS_DATA:
      return { ...state, isLoading: true };
    case ASSETS_DATA_SUCCESS:
      return { ...state, isLoading: false, assetsData: action.value };
    case ASSETS_DATA_FAIL:
      return { ...state, isLoading: false, assetsData: "error" };
    //post
    case ASSETS_DATA_POST:
      return { ...state, isLoading: true };
    case ASSETS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, assetsDataPost: action.value };
    case ASSETS_DATA_POST_FAIL:
      return { ...state, isLoading: false, assetsDataPost: "error" }; //remove
    case REMOVE_ASSETS_DATA_POST:
      return { ...state, assetsDataPost: [] };
    default:
      return state;
  }
};
