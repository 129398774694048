import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { FormatCurrency, CheckIfEmpty } from "../../helper/functions";
import { useHistory } from "react-router-dom";
import "../../assets/css/Transaction/cardStyling.css";
import { useDispatch, useSelector } from "react-redux";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import {
  getProvincesDataThunk,
  getCitiesDataThunk,
  getSubdistrictsDataThunk,
  getCouriersDataThunk,
  getPricesDataThunk,
  postOrdersDataThunk,
} from "../../redux/thunks/OrdersThunks";
import Topup from "../../components/Topup";
import { postPaymentsVerifyDataThunk } from "../../redux/thunks/PaymentsThunks";
import { removePostPaymentsData } from "../../redux/actions/PaymentsActions";
import "./../../assets/css/Courses/CourseFront.css";
import {
  putUsersDataThunk,
  getUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
import { KeepSpesificKey } from "../../helper/functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalPembayaran from "../../components/ModalPembayaran";
import { paymentsMethod } from "../../helper/payments";

const MySwal = withReactContent(Swal);

function Cart({ isMinimize, dataCart, getCarts }) {
  const [add, setAdd] = useState(false);
  const [userStatus, setUserStatus] = useState(0);
  const [userItem, setUserItem] = useState([]);
  const [modalContent, setModalContent] = useState({
    show: false,
    componentBody: "",
  });
  const [destination, setDestination] = useState({
    address_province: "",
    address_city: "",
    address_subdistrict: "",
    address_street: "",
    courier: "",
    price: 0,
  });
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [pricesCert, setPricesCert] = useState();
  const [topup, setTopup] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const history = useHistory();
  const users = useSelector((state) => state.users);
  const orders = useSelector((state) => state.orders);
  const purchases = useSelector((state) => state.purchases);
  const payments = useSelector((state) => state.payments);
  const dispatch = useDispatch();
  let prices = 0;
  let courseIds = [];
  let countCert = 0;
  const certPrice = 500000; //certificate price

  useEffect(() => {
    AuthIsLogin(history);
    dispatch(getProvincesDataThunk());
    dispatch(getCouriersDataThunk());
  }, []);

  useEffect(() => {
    if (payments.paymentsDataPost?.order_id) {
      setModalContent({
        ...modalContent,
        show: true,
        body: payments.paymentsDataPost,
      });
    }
    if (payments.paymentsDataPost === "fail") {
      displayPopup(
        false,
        `Gagal Topup. Silahkan coba lagi setelah beberapa saat!`,
        true,
        "Oke"
      );
    }
  }, [payments]);

  const addressUsers = users.usersDataId;

  //get address_city
  useEffect(() => {
    if (destination.address_province) {
      const newDestination = { ...destination };
      newDestination.address_subdistrict = "";
      newDestination.address_city = "";
      setDestination(newDestination);
      dispatch(
        getCitiesDataThunk(destination?.address_province?.split("_")[0])
      );
    }
  }, [destination?.address_province]);

  //get address_subdistrict
  useEffect(() => {
    if (destination.address_city) {
      const newDestination = { ...destination };
      newDestination.address_subdistrict = "";
      setDestination(newDestination);
      dispatch(
        getSubdistrictsDataThunk(destination?.address_city?.split("_")[0])
      );
    }
  }, [destination.address_city]);

  //get price
  useEffect(() => {
    const newDestination = { ...destination };
    newDestination.price = "";
    setDestination(newDestination);
    if (destination?.address_subdistrict && destination?.courier) {
      dispatch(getPricesDataThunk(destination));
    }
  }, [destination?.address_subdistrict, destination?.courier]);

  const handleAdd = () => {
    setAdd(!add);
  };

  useEffect(() => {
    setModalContent({
      ...modalContent,
      show: false,
    });
    if (payments.paymentsVerifyDataPost) {
      if (payments.paymentsVerifyDataPost === "fail" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi kesalahan, coba lagi", true, "Oke");
        dispatch(removePostPaymentsData());
      } else if (trigger) {
        setTrigger(false);
        dispatch(removePostPaymentsData());
        displayPopup(
          false,
          `Status Transaksi Anda : ${payments.paymentsVerifyDataPost?.transaction_status}`,
          true,
          "Oke"
        );
        dispatch(getUsersDataThunk(AuthData().userId));
      }
    }
  }, [payments.paymentsVerifyDataPost]);

  //auto fill address if any
  useEffect(() => {
    let newAddress = addressUsers.address_street;
    const newDestination = { ...destination };
    newDestination.address_street = newAddress;
    setDestination(newDestination);
  }, [addressUsers.address_street]);

  useEffect(() => {
    if (orders.ordersProvinces) setProvinces(orders.ordersProvinces);
    if (orders.ordersCities) setCities(orders.ordersCities);
    if (orders.ordersSubdistricts) setSubdistricts(orders.ordersSubdistricts);
    if (orders.ordersCouriers) setCouriers(orders.ordersCouriers);
    if (orders.ordersPrice) setPricesCert(orders.ordersPrice);

    if (orders?.ordersPostData === "fail" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat membayar barang", false);
    }
    if (orders?.ordersPostData === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil membayar barang", true, "Oke");
      removeAllTypeFromCart("course");
    }
  }, [orders]);


  useEffect(() => {
    const balance = users.usersDataId.balance;
    const cart = dataCart?.my_cart;
    if (balance || balance === 0) setUserStatus(balance);
    if (cart) setUserItem(cart);
    if (users.usersDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(
        true,
        "Terjadi masalah saat menghapus barang di Keranjang",
        false
      );
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      getCarts();
      dispatch(getUsersDataThunk(AuthData().userId));
    }
  }, [users, dataCart]);

  useEffect(() => {
    if (purchases.purchasesDataPost === "fail" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat membayar barang", false);
    }
    if (purchases.purchasesDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil membayar barang", true, "Oke");
      removeAllTypeFromCart("certificate");
    }
  }, [purchases]);

  function ordersHandler() {
    const payload = {
      courier: destination?.courier,
      destination:
        destination?.address_street +
        "," +
        destination?.address_subdistrict?.split("_")[1] +
        "," +
        destination?.address_city?.split("_")[1] +
        "," +
        destination?.address_province?.split("_")[1],
      fee: parseInt(destination?.price) + countCert * certPrice,
      origin: "Jakarta",
      user_id: AuthData().userId,
    };
    setTrigger(true);
    dispatch(postOrdersDataThunk(payload));
  }

  const handleChange = (e) => {
    const newData = { ...destination };
    newData[e.target.id] = e.target.value;
    setDestination(newData);
  };

  const topupHandler = () => {
    setTopup(!topup);
  };

  const removeItemFromCart = (id) => {
    let cart = KeepSpesificKey([...dataCart?.my_cart]);
    let req = {};
    const index = cart.indexOf(id);
    if (index > -1) {
      cart.splice(index, 1);
    }
    req.body = { cart: cart };
    req.id = AuthData().userId;
    setTrigger(true);
    dispatch(putUsersDataThunk(req));
  };

  const removeAllTypeFromCart = (id) => {
    let cart = users.usersDataId.cart;
    let newCart = [];
    let req = {};
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].item_type === id) newCart.push(cart[i][`${id}_id`]);
    }
    req.body = { cart: newCart };
    req.id = AuthData().userId;
    setTrigger(true);
    dispatch(putUsersDataThunk(req));
  };

  function handleCopy() {
    let copyText = document.getElementById("va-number");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert(copyText.value);
  }

  const handleVerify = (value) => {
    setTrigger(true);
    const dataSend = {
      order_id: value,
    };
    dispatch(postPaymentsVerifyDataThunk(dataSend));
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => { });
  }

  function getOptionData(data, field) {
    return data
      .filter((item) => item[field] !== "")
      .map((item, index) => {
        const id = `${field}_id`;
        return (
          <option key={index} value={`${item[id]}_${item[field]}`}>
            {item[field]}
          </option>
        );
      });
  }

  function handleFilter(e) {
    const availableCouriers = [
      "anteraja",
      "ninja",
      "jet",
      "sicepat",
      "pos",
      "jne",
      "tiki",
      "jnt",
    ];
    return availableCouriers.includes(e);
  }

  return (
    <Container>
      {isMinimize ? (
        <div className="minimize__wrapper__cart_">
          {userItem?.length > 0 ? (
            userItem.map((data, index) => {
              if (data.item_type === "certificate" || data?.item_type === "course") {
                countCert++;
              }
              return (
                <div key={index}>
                  {data.item_type === "certificate" ? (
                    <div
                      className={`cart-minimize__wrapper`}
                    >
                      <div className="d-flex flex-row justify-content-between">
                        <div className="cart-minimize__title">
                          * {data.course}
                        </div>{" "}
                        <div className="card_content_button">
                          <Button
                            variant="danger"
                            onClick={() => {
                              removeItemFromCart(data.certificate_id);
                            }}
                          >
                            <span className="icon-trash-smaller"></span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`cart-minimize__wrapper`}
                    >
                      <div className="d-flex flex-row justify-content-between">
                        <div className="cart-minimize__title">
                          * {data.title}
                        </div>{" "}
                        <div className="card_content_button">
                          <Button
                            variant="danger"
                            onClick={() => {
                              removeItemFromCart(data.course_id);
                            }}
                          >
                            <span className="icon-trash-smaller"></span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className=" mb-4 not_found__course">
              Data tidak ditemukan
            </div>
          )}
          Total{" "}
          {FormatCurrency(parseInt(destination.price) + countCert * certPrice)}
        </div>
      ) : (
        <>
          <Row className="p-2">
            <Col md={8} className="maximize__wrapper__cart_">
              {userItem?.length > 0 ? (
                userItem.map((data, index) => {
                  if (data.item_type === "certificate" || data?.item_type === "course") {
                    countCert++;
                  }
                  return (
                    <div key={index}>
                      {data.item_type === "certificate" ? (
                        <Card className="cart_container__cert card__maximize">
                          <div
                            className={`${userItem.length === 1
                              ? "card_wrapper__info pb-0"
                              : "card_wrapper__info"
                              }`}
                          >
                            <div className="card_content__wrapper">
                              <div className="card_content__image">
                                <img
                                  alt="Thumbnail Certificate"
                                  className="card_thumbnail__certificate lazyload"
                                  data-src={data?.certificate_url}
                                  width="255"
                                  height="153"
                                />
                              </div>
                              <div className="card_content__title">
                                <div className="card_content__title__main">
                                  {data.course}
                                </div>
                                <div className="card_content__title__price">
                                  Rp. 500.000
                                </div>
                              </div>
                            </div>

                            <div className="card_content_button">
                              <Button
                                variant="danger"
                                className="card_content__button_danger"
                                onClick={() => {
                                  removeItemFromCart(data.certificate_id);
                                }}
                              >
                                <span className="icon-trash"></span>
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ) : (
                        <Card className="cart_container__cert card__maximize">
                          <div
                            className={`${userItem.length === 1
                              ? "card_wrapper__info pb-0"
                              : "card_wrapper__info"
                              }`}
                          >
                            <div className="card_content__wrapper">
                              <div className="card_content__image">
                                <img
                                  alt="Thumbnail Certificate"
                                  className="card_thumbnail__certificate lazyload"
                                  data-src={data?.certificate_url}
                                  width="255"
                                  height="153"
                                />
                              </div>
                              <div className="card_content__title">
                                <div className="card_content__title__main">
                                  {data.title}
                                </div>
                                <div className="card_content__title__price">
                                  Rp. 500.000
                                </div>
                              </div>
                            </div>

                            <div className="card_content_button">
                              <Button
                                variant="danger"
                                className="card_content__button_danger"
                                onClick={() => {
                                  removeItemFromCart(data.certificate_id);
                                }}
                              >
                                <span className="icon-trash"></span>
                              </Button>
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="course_min__height mb-4 not_found__course">
                  Data tidak ditemukan
                </div>
              )}
            </Col>
            <Col sm={6} md={4} className="cart__wrapper">
              <Card id="cart" className="cart_container">
                <div className="card_promo_wrapper">
                  <div className="d-flex justify-content-between">
                    <div className="card_title mb-2">Alamat Pengiriman</div>
                    {add ? (
                      <div className="pointer-cursor" onClick={handleAdd}>
                        <span className="icon-minus"></span>
                      </div>
                    ) : (
                      <div
                        className="pointer-cursor"
                        onClick={() => handleAdd()}
                      >
                        <span className="icon-plus"></span>
                      </div>
                    )}
                  </div>
                  {add ? (
                    <Form className="maximize__zoom">
                      <Form.Control
                        className="satukelas_input py-2 mb-3"
                        as="textarea"
                        placeholder="Masukkan Nama Perumahan/Blok"
                        id="address_street"
                        value={destination.address_street}
                        onChange={handleChange}
                      />
                      <Form.Group>
                        <Form.Label className="cart_label">Provinsi</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="address_province"
                          id="address_province"
                          value={destination.provinces}
                          onChange={handleChange}
                          className="border"
                        >
                          <option hidden value key="blankChoice">
                            Pilih Provinsi...
                          </option>
                          {getOptionData(provinces, "province")}
                        </Form.Control>
                      </Form.Group>
                      {destination.address_province && cities ? (
                        <Form.Group>
                          <Form.Label className="cart_label">Kota</Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            name="address_city"
                            id="address_city"
                            value={destination.address_city}
                            onChange={handleChange}
                            className="border"
                          >
                            <option hidden value key="blankChoice">
                              Pilih Kota/Kabupaten...
                            </option>
                            {cities.map((item, index) => {
                              return (
                                <option
                                  value={`${item.city_id}_${item.type} ${item.city_name}`}
                                  key={index}
                                >
                                  {item.type} {item.city_name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : null}
                      {destination.address_city && subdistricts ? (
                        <Form.Group>
                          <Form.Label className="cart_label">
                            Kecamatan
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            name="address_subdistrict"
                            id="address_subdistrict"
                            value={destination.address_subdistrict}
                            onChange={handleChange}
                            className="border"
                          >
                            <option hidden value key="blankChoice">
                              Pilih Kecamatan...
                            </option>
                            {subdistricts.map((item, index) => {
                              return (
                                <option
                                  value={`${item.subdistrict_id}_${item.subdistrict_name}`}
                                  key={index}
                                >
                                  {item.subdistrict_name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : null}
                      <Form.Group>
                        <Form.Label className="cart_label">Kurir</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          type="select"
                          name="courier"
                          id="courier"
                          value={destination.courier}
                          onChange={handleChange}
                          className="border"
                        >
                          <option hidden value key="blankChoice">
                            Pilih Kurir...
                          </option>
                          {couriers.filter(handleFilter).map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item.toUpperCase()}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                      {pricesCert ? (
                        <Form.Group>
                          <Form.Label className="cart_label">
                            Metode Pengiriman
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            name="price"
                            id="price"
                            value={destination.price}
                            onChange={handleChange}
                            className="border"
                          >
                            <option hidden value key="blankChoice">
                              Pilih Metode Pengiriman...
                            </option>
                            {pricesCert.map((item, index) => {
                              return (
                                <option value={item.cost[0].value} key={index}>
                                  {item.service}(
                                  {item.cost[0].etd
                                    ? item.cost[0].etd + " Hari"
                                    : "-"}
                                  /{FormatCurrency(item.cost[0].value)})
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : (
                        ""
                      )}
                    </Form>
                  ) : (
                    ""
                  )}
                </div>
                <div className="card_border"></div>
                <div className="card_total_wrapper mb-2">
                  <div className="card_title">Total Pembayaran</div>
                  <div className="card_subtitle mb-2">
                    {countCert} Sertifikat
                  </div>
                  <div className="card_price text-right">
                    {FormatCurrency(
                      parseInt(destination.price) + countCert * certPrice
                    )}
                  </div>
                </div>
                {countCert > 0 ? (
                  <>
                    {userStatus >
                      parseInt(destination.price) + countCert * certPrice ? (
                      <div className="card_button ">
                        <Button
                          className="w-100 button-save__address"
                          onClick={ordersHandler}
                          disabled={!countCert || CheckIfEmpty(destination)}
                        >
                          Bayar
                        </Button>
                        {CheckIfEmpty(destination) && (
                          <p className="insufficient__">Silakan pilih alamat</p>
                        )}
                      </div>
                    ) : (
                      <div className="card_button ">
                        <Button
                          onClick={topupHandler}
                          className="w-100 button-save__address"
                        >
                          Topup
                        </Button>
                        <p className="insufficient__">Saldo anda tidak cukup</p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="card_button ">
                    <Button disabled className="w-100 button-save__address">
                      Tidak ada item terpilih
                    </Button>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Modal centered show={topup} onHide={() => setTopup(false)}>
        <Topup setTopup={setTopup} />
      </Modal>

      <ModalPembayaran
        id="tp"
        content={modalContent}
        handleVerify={handleVerify}
        paymentsMethod={paymentsMethod}
        handleCopy={handleCopy}
      />
    </Container>
  );
}

export default Cart;
