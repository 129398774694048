import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import QuizDetails from "../../components/Quiz/QuizDetails";
import QuizQuestion from "../../components/Quiz/QuizQuestion";
import QuizResult from "../../components/Quiz/QuizResult";
import "../../assets/css/Courses/quiz.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuizDataThunk,
  getQuizAttemptThunk,
  postStartQuizThunk,
  postQuizScoreThunk,
} from "../../redux/thunks/QuizThunks";
import { removeAttemptScoreQuiz } from "../../redux/actions/QuizActions";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getCoursesDataThunk } from "../../redux/thunks/CoursesThunks";
import moment from "moment";
import { DM_COURSE_ID, DM_COURSE_ID_POST } from "../../helper/constant";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function QuizPage({
  startAttempt,
  setHideButton,
  onComplete,
  getNextLink,
  material,
  setMaterial,
  quizPass,
  detailData,
}) {
  const { id_material, course_id } = useParams();
  const [minimizeValue, setMinimizeValue] = useState(false);
  const [count, setCount] = useState(0);
  const [allAnswer, setAllAnswer] = useState([]);
  const [attempts, setAttempts] = useState(["empty"]);
  const [attempt, setAttempt] = useState();
  const [quizScore, setQuizScore] = useState([]);
  const [startAttemptId, setStartAttemptId] = useState(null);
  const [congratsPage, setCongratsPage] = useState(0);
  const [quizDataId, setQuizDataId] = useState([]);
  const [doneQuiz, setDoneQuiz] = useState(false);
  const [afterScore, setAfterScore] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [displayResult, setDisplayResult] = useState(false);
  const quizzes = useSelector((state) => state.quizzes);
  const courses = useSelector((state) => state.courses);
  const course = courses?.coursesDataId;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (history.location.pathname.includes("/techconnect/")) {
    } else AuthIsLogin(history);
  }, []);
  useEffect(() => {
    if (quizzes.quizDataId) {
      setQuizDataId(quizzes.quizDataId);
    }
    if (quizzes.quizDataId?.attempts) {
      setAttempts(quizzes.quizDataId.attempts);
    }
    if (quizzes.quizAttemptData?.attempt_id) {
      setAttempt(quizzes.quizAttemptData);
      setIsDisplay(true);
    }
    if (quizzes.quizScoreData?.status === "success" && afterScore) {
      setQuizScore(quizzes.quizScoreData.value);
      setDisplayResult(true);
      setAfterScore(false);
      setAllAnswer([]);

      if (quizzes.quizScoreData.value?.result !== "Fail") {
        if (course.course_type === "Sertifikasi") {
          // displayPopup(
          //   false,
          //   `Selamat! Anda telah menyelesaikan Sertifikasi ${course.title}, Silahkan Klaim Sertifikat Kompetensi Anda!`,
          //   true,
          //   "Tutup"
          // );
        } else if (course.course_type === "Pelatihan") {
          // displayPopup(
          //   false,
          //   `Selamat! Anda telah menyelesaikan Pelatihan ${course.title}, Silahkan Klaim Sertifikat Partisipasi Anda!`,
          //   true,
          //   "Tutup"
          // );
        }
      }

      dispatch(removeAttemptScoreQuiz());
      dispatch(getQuizDataThunk(id_material.split("_")[0]));
    }
    if (quizzes.quizScoreData?.status === "error" && afterScore) {
      dispatch(removeAttemptScoreQuiz());
      setAfterScore(false);
    }
    if (quizzes.quizStartData?.status === "success") {
      setStartAttemptId(quizzes.quizStartData?.value?.attempt_id); // use this attempt ID if quiz is started fistly(not continue quiz)
      setTrigger(true);
    }
    if (quizzes.quizDataId?.quiz_id && trigger) {
      dispatch(removeAttemptScoreQuiz());
      setIsDisplay(true);
      setTrigger(false);
    }
  }, [quizzes]);

  useEffect(async () => {
    // resetFlags();
    if (typeof localStorage !== "undefined") {
      const savedAnswer = await localStorage.getItem(
        `_${AuthData().userId + id_material}`
      );
      const obj = JSON.parse(savedAnswer);
      if (obj?.length > 0) {
        // console.log("=========================+>>>>>>>>", obj);
        setAllAnswer(obj);
        setCount(obj.length ? obj.length : 0);
      }
    }
  }, [location]);

  const toCongratsPage = (value) => {
    setCongratsPage(value);
    setHideButton(false);
    setAllAnswer([]);
    dispatch(removeAttemptScoreQuiz());
    setCount(0);
    setDoneQuiz(false);
    // dispatch(getQuizDataThunk(id_material.split("_")[0]));
  };

  const resetFlags = (e, answer) => {
    setHideButton(false);
    setIsDisplay(false);
    setDisplayResult(false);
    setAllAnswer([]);
    dispatch(removeAttemptScoreQuiz());
    setCount(0);
    setDoneQuiz(false);
    if (course?.course_type === "Sertifikasi") {
      history.push("/search/try%20out/course_type=try%20out");
    }
    // if(!quizDataId)
    if (AuthData().userId && answer == "noAnswer") {
      dispatch(getQuizDataThunk(id_material.split("_")[0]));
    }
  };

  const resultHistory = (newQuizScore) => {
    if (newQuizScore.status == "ongoing") {
      startAttempt();
    } else {
      setIsDisplay(true);
      setDisplayResult(true);
      setQuizScore(newQuizScore);
    }
  };

  useEffect(() => {
    if (doneQuiz) {
      localStorage.removeItem(`_${AuthData().userId + id_material}`);
      let attemptId = attempts[attempts?.length - 1]?.attempt_id;
      if (attempt?.attempt_id) attemptId = attempt?.attempt_id;
      if (startAttemptId) attemptId = startAttemptId;
      if (allAnswer.length > 0) {
        let payload = {
          answers: allAnswer,
          attempt_id: attemptId,
        };
        const quizIdQuestions = quizDataId.questions;
        let newAnswer = [];
        if (allAnswer.length < quizIdQuestions?.length) {
          for (let i = 0; i < quizIdQuestions.length; i++) {
            const answer = {
              answer: allAnswer[i] ? allAnswer[i].answer : "",
              question_id: allAnswer[i]
                ? allAnswer[i].question_id
                : quizIdQuestions[i].question_id,
            };
            newAnswer.push(answer);
          }
          payload.answers = newAnswer;
        }
        dispatch(postQuizScoreThunk(payload));
        setDoneQuiz(false); // flag to check if quiz is done(submitted)
        setAfterScore(true); // flag to check quiz state (if open score)
        setCount(0); //reset quiz question count
        setStartAttemptId(null); // reset attemptID value
      } else resetFlags(null, "noAnswer");
    }
  }, [doneQuiz]);

  const handleMinimize = (minimizeValue) => {
    setMinimizeValue(minimizeValue);
  };
  // console.log(courses?.coursesDataId?.course_type);
  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      cancelButtonText: "Tidak",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (value.isConfirmed) {
        const qids = quizzes.quizDataId.questions.map(function (item) {
          return item["question_id"];
        });
        const payload = {
          question_ids: qids,
          quiz_id: id_material.split("_")[0],
          user_id: AuthData().userId,
        };
        // console.log(payload);
        dispatch(postStartQuizThunk(payload));
      }
    });
  }
  function startAttempt() {
    if (attempts[attempts?.length - 1]?.status === "ongoing") {
      dispatch(getQuizAttemptThunk(attempts[attempts?.length - 1].attempt_id));
    } else if (
      attempts[attempts?.length - 1]?.status === "completed" ||
      attempts.length <= 0
    ) {
      displayPopup(
        true,
        `Anda ingin Mulai ${courses?.coursesDataId?.course_type}?`,
        true,
        "Ya"
      );
    }
  }
  return (
    <Container className="quiz-card lesson-container">
      {isDisplay ? (
        <Row className="quiz-parent-row align-items-center justify-content-center">
          {displayResult ? (
            <>
              {quizScore?.result ? (
                <QuizResult
                  onComplete={onComplete}
                  toCongratsPage={toCongratsPage}
                  congratsPage={congratsPage}
                  quizScore={quizScore}
                  quizDataId={quizDataId}
                  displayResult={displayResult}
                  resetFlags={resetFlags}
                  getNextLink={getNextLink}
                  material={material}
                  setMaterial={setMaterial}
                  quizPass={quizPass}
                  detailData={detailData}
                />
              ) : null}
            </>
          ) : (
            <>
              {attempt?.remaining_time || quizDataId.duration ? (
                <>
                  <QuizDetails
                    minimizevalue={minimizeValue}
                    quizTime={
                      attempt?.remaining_time
                        ? attempt.remaining_time.toFixed(0) * 1000
                        : quizDataId.duration * 1000
                    }
                    setDoneQuiz={setDoneQuiz}
                    title={quizDataId.title ? quizDataId.title : ""}
                  />
                  <QuizQuestion
                    questData={
                      quizDataId.questions
                        ? quizDataId.questions[count]
                        : data[0]
                    }
                    minimizevalue={minimizeValue}
                    count={count}
                    total={
                      quizDataId.questions ? quizDataId.questions.length : 0
                    }
                    setCount={setCount}
                    allAnswer={allAnswer}
                    setAllAnswer={setAllAnswer}
                    setDoneQuiz={setDoneQuiz}
                    quizKey={AuthData().userId + id_material}
                  />
                </>
              ) : null}
            </>
          )}
        </Row>
      ) : (
        <Col
          xs={12}
          lg={12}
          className="quiz-quest-main-col d-flex flex-column justify-content-start align-items-start"
        >
          <Button
            className="header-quiz"
            variant="info"
            style={{ width: "100%" }}
          >
            Riwayat Skor
          </Button>
          <div
            className="quiz-quest-wrapper-scrollbar"
            style={{
              width: "100%",
              minHeight: "50vh",
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            <Table responsive striped hover>
              <thead>
                <tr id="header-table__quiz">
                  {/*<th>Tipe</th>*/}
                  <th style={{ width: "140px" }}>Percobaan ke</th>
                  <th>Tgl. Dimulai</th>
                  <th>Nilai</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody id="content-table">
                {quizzes.quizDataId?.attempts
                  ? quizzes.quizDataId.attempts.map((value, index) => {
                      return (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => resultHistory(value)}
                        >
                          <td className="text-center">{index + 1}</td>
                          <td>
                            {moment(value.created_at).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </td>

                          <td>
                            {value.status === "ongoing"
                              ? "Sedang Berlangsung"
                              : value.earned_mark}
                          </td>
                          <td>
                            {value.status === "ongoing" ? (
                              <Button
                                size="sm"
                                disabled
                                variant="info"
                                style={{ width: "100%", fontSize: "0.7rem" }}
                              >
                                Sedang Berlangsung
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                disabled
                                variant={`${
                                  value.result === "Pass" ? "success" : "danger"
                                }`}
                              >
                                {value.result === "Pass" ? "Lulus" : "Gagal"}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
          </div>
          <div className="w-100">
            <Button
              className="mb-3 w-100 start-attempt__button"
              onClick={startAttempt}
              disabled={
                course_id === DM_COURSE_ID || course_id === DM_COURSE_ID_POST
                  ? quizzes.quizDataId?.attempts?.length > 0
                    ? true
                    : false
                  : false
              }
            >
              {attempts[attempts?.length - 1]?.status === "ongoing"
                ? "Lanjutkan"
                : "Mulai"}{" "}
              {courses?.coursesDataId?.course_type === "Try Out"
                ? "Try Out"
                : "Ujian"}
            </Button>
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%", width: "100%" }}
          >
            {course_id === DM_COURSE_ID || course_id === DM_COURSE_ID_POST ? (
              <Button
                className="mr-2 button_lp__bnsp"
                onClick={() => history.goBack()}
              >
                Kembali ke Landing Page
              </Button>
            ) : null}
          </div>
        </Col>
      )}
    </Container>
  );
}

export default QuizPage;

const data = [
  {
    choice_a: "null",
    choice_b: "null",
    choice_c: "null",
    choice_d: "null",
    correct_choice: "null",
    course_id: "null",
    created_at: "null",
    question: "null",
    quiz_id: "null",
    theme_id: "null",
    title: "null",
  },
];
