/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Button1 from "./Button/Button1";
import { slugify } from "../../helper/functions";
import { Link, useHistory } from "react-router-dom";
import { CertificateService } from "../../http/CertificateHttp";

const TryOut = (props) => {
  const {
    title,
    titleButton,
    type,
    icon,
    heading,
    subheading,
    isPassed,
    enrolled,
    continueCourse,
    handleScroll,
    titleTryout,
    coIdTryout,
  } = props;
  const history = useHistory();
  const [doneExam, setDoneExam] = useState(false);
  const [exam, setExam] = useState(false);
  const [dataOngoing, setDataOngoing] = useState("");

  useEffect(() => {
    let isStartedExam = false;
    let isDone = false;

    const coursesList = props.data?.courses;
    if (coursesList?.length > 0) {
      for (let i = 0; i < coursesList?.length; i++) {
        if (
          coursesList[i].status === "ongoing" ||
          coursesList[i].status === "not enrolled"
        ) {
          setDataOngoing(coursesList[i]);
          break;
        }
      }

      for (let i = 0; i < coursesList?.length; i++) {
        if (
          coursesList[i].status === "completed" &&
          coursesList[i].course_type === "Pelatihan"
        ) {
          isStartedExam = true;
        } else if (
          coursesList[i].status !== "completed" &&
          coursesList[i].course_type === "Pelatihan"
        ) {
          isStartedExam = false;
        }
      }

      for (let i = 0; i < coursesList?.length; i++) {
        if (
          coursesList[i].status === "completed" &&
          coursesList[i].course_type === "Sertifikasi"
        ) {
          isDone = true;
          break;
        }
      }

      setExam(isStartedExam);
      setDoneExam(isDone);
    }
  }, [props.data]);
  return (
    <section className="my-5">
      <Container className="ml-xl-5">
        <div className="pl-0 pr-0 col-xl-8">
        <h4 className="fw-700">{title}</h4>
          <div
            className={`d-block d-md-flex align-items-center w-100 p-4 p-md-3 ${
              type === "brevet" ? "bg__tryout" : "bg__ujian"
            }`}
          >
            <div className="col-12 col-md-2 col-lg-2 px-0">
              <img className="ml-n4 ml-lg-0" src={icon} alt="" />
            </div>
            <div className="col-12 col-md-7 col-lg-7 px-0">
              <h5 className="ml-3 fw-700 text-white">{heading}</h5>
              <div
                className="ml-3 text-white"
                dangerouslySetInnerHTML={{ __html: subheading }}
              />
            </div>
            <div className="col-12 col-md-3 col-lg-auto px-0 d-flex justify-content-center">
              {type === "certification_brevet" ? (
                <Button
                  className="btn bg-white rounded-3 fw-700 btn__2 p-3 border-0"
                  onClick={handleScroll}
                >
                  {titleButton}
                </Button>
              ) : (
                <>
                  <>
                    {titleTryout && coIdTryout !== "" ? (
                      <Link
                        to={`/course/${slugify(titleTryout)}/${coIdTryout}`}
                      >
                        <Button1
                          clas="btn bg-white rounded-3 fw-700 btn__2 p-3 border-0"
                          disabled={
                            type === "brevet"
                              ? null
                              : exam
                              ? doneExam
                                ? true
                                : false
                              : true
                          }
                          title={
                            doneExam
                              ? "Ujian Selesai"
                              : type === "brevet"
                              ? titleButton
                              : "Mulai Ujian"
                          }
                        />
                      </Link>
                    ) : (
                      <Link
                        to={`${
                          type === "brevet"
                            ? "/search/try%20out/course_type=try%20out"
                            : `/course/${slugify(dataOngoing.title)}/${
                                dataOngoing.course_id
                              }`
                        }`}
                      >
                        <Button1
                          clas="btn bg-white rounded-3 fw-700 btn__2 p-3 border-0"
                          disabled={
                            type === "brevet"
                              ? null
                              : exam
                              ? doneExam
                                ? true
                                : false
                              : true
                          }
                          title={
                            doneExam
                              ? "Ujian Selesai"
                              : type === "brevet"
                              ? titleButton
                              : "Mulai Ujian"
                          }
                        />
                      </Link>
                    )}
                  </>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TryOut;
