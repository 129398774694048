// CONFIG
export const HAS_UPLOAD = "HAS_UPLOAD";

// ACTION
export const hasUpload = () => ({
  type: HAS_UPLOAD,
});

// STATE
const intialState = {
  hasUploaded: false,
};

// REDUCERS
export const DiplomaReducers = function (state = intialState, action) {
  switch (action.type) {
    case HAS_UPLOAD:
      return { ...state, hasUploaded: true };
    default:
      return state;
  }
};
