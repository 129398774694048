import React from "react";
import { Image } from "react-bootstrap";
import certifDesktop from "../../assets/images/atpi/certificate/bg-image-certificate.png";
import iconTiktik from "../../assets/images/atpi/certificate/icon-tiktik.png";
import certif1Mobile from "../../assets/images/atpi/certificate/certif-1.png";
import certif2Mobile from "../../assets/images/atpi/certificate/certif-2.png";
import certif3Mobile from "../../assets/images/atpi/certificate/certif-3.png";
const CertificateAtpi = () => {
  return (
    <div className="position-relative">
      <h1 className="woding__certificate">
        Gelar Profesi <em>Certified Tax Technician</em> dari ATPI
      </h1>
      <em className="sub__wording-certificate">(Bisa didapatkan jika Anda lulus Brevet Pajak AB di satukelas)</em>
      <div className="d-lg-flex justify-content-center mt-5 position-relative d-none" style={{ zIndex: "2" }}>
        <Image src={certifDesktop} width="100%" height="95%" />
      </div>
      <div className="d-lg-none mt-5">
        <div className="mb-3 d-flex justify-content-center">
          <Image src={certif1Mobile} className="w-md-50-apindo w-100" height="100%" />
        </div>
        <div className="mb-3 d-flex justify-content-center">
          <Image src={certif2Mobile} className="w-md-50-apindo w-100" height="100%" />
        </div>
        <div className="mb-3 d-flex justify-content-center">
          <Image src={certif3Mobile} className="w-md-50-apindo w-100" height="100%" />
        </div>
      </div>
      <div className="bg__tiktik-certificate d-none d-lg-block">
        <Image src={iconTiktik} width="100%" height="100%" />
      </div>
    </div>
  );
};

export default CertificateAtpi;
