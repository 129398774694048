import React, { useEffect, useLayoutEffect, useState } from "react";
import "../../assets/css/Courses/zoomDisplay.css";
import { Card, Button, Row, Col, Modal, Image } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { useDispatch } from "react-redux";
import moment from "moment";
import { AuthData } from "../../helper/auth";
import { KONEKETEMU_URL } from "../../helper/constant";
import {
  getCoursesDataThunk,
  postCompleteCourseThunk,
  postCompleteMaterialThunk,
  postEnrollCoursesThunk,
} from "../../redux/thunks/CoursesThunks";
import reaction1 from "../../assets/images/feedback/reaction1.svg";
import reaction2 from "../../assets/images/feedback/reaction2.svg";
import reaction3 from "../../assets/images/feedback/reaction3.svg";
import reaction4 from "../../assets/images/feedback/reaction4.svg";
import reaction5 from "../../assets/images/feedback/reaction5.svg";
import reaction6 from "../../assets/images/feedback/reaction6.svg";
import "../../assets/css/feedback.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  removeCompleteState,
  removeCourseDataEnroll,
  removeCourseDataId,
} from "../../redux/actions/CoursesActions";
import { webinarService } from "../../http/WebinarHttp";
import { getWebinarsData } from "../../redux/actions/WebinarsActions";
import { SkValidate } from "../../components/common/SkValidate";
import { FeedbackService } from "../../http/FeedbackHttp";
const MySwal = withReactContent(Swal);
function WebinarDetail2({ course, userStatus, users, wbId, courses }) {
  const { course_id } = useParams();
  const [feedback, setFeedback] = useState(false);
  const [joinMeeting, setJoinMeeting] = useState(false);
  const [title, setTitle] = useState();
  const [webinarDetail, setWebinarDetails] = useState(null);
  const [feedbackData, setFeedbackData] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [more, setMore] = useState(false);
  const [enrollAndStart, setEnrollAndStart] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [enrollPayload, setEnrollPayload] = useState({
    user_id: "",
    course_id: "",
  });
  const [webinarsDataId, setWebinarsDataId] = useState();

  const name = users?.name;
  const email = users?.email;
  const position = users?.position ? users.position : "undefined";
  const user_id = users?.user_id ? users.user_id : "undefined";
  const [webinarId, setWebinarId] = useState(wbId);

  useEffect(() => {
    // modify this with new format !!!
    if (course?.topics[0]?.materials[0]?.webinar_id) {
      setWebinarsDataId(course?.topics[0]?.materials[0]);
      if (!wbId) {
        setWebinarId(course?.topics[0]?.materials[0]?.webinar_id);
      }
    }
  }, [course]);

  useEffect(() => {
    if (webinarId) {
      getWebinarDataId();
    }
  }, [webinarId]);

  useEffect(() => {
    setEnrollPayload({
      user_id: AuthData()?.userId,
      course_id: course_id,
    });
  }, []);

  useEffect(() => {
    if (courses.courseDataEnroll === "success" && trigger) {
      setTrigger(false);
      dispatch(getCoursesDataThunk({ type: "id", param: `/${course_id}` }));
      setEnrollAndStart(true);
      dispatch(removeCourseDataEnroll());
    }
    if (courses.courseDataEnroll === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Memulai Webinar!", "Oke");
      dispatch(removeCourseDataEnroll());
    }
  }, [courses]);

  useLayoutEffect(() => {
    return () => {
      setJoinMeeting(false);
      dispatch(removeCourseDataId());
    };
  }, []);

  useEffect(() => {
    if (
      courses.completeCourseData === "success" &&
      courses.completeMaterialData === "success" &&
      trigger
    ) {
      setTrigger(false);

      displayPopup(
        false,
        `Selamat! Anda telah memberikan feedback dan menyelesaikan Webinar ${title}, Silahkan Klaim Sertifikat Partisipasi Anda!`,
        "Ok"
      );
      dispatch(removeCompleteState());
    }
    if (
      courses.completeCourseData === "fail" &&
      courses.completeMaterialData === "fail" &&
      trigger
    ) {
      setTrigger(false);
      displayPopup(false, "Gagal Menyelesaikan Webinar", "Oke");
      dispatch(removeCompleteState());
    }
  }, [courses]);

  const getWebinarDataId = async () => {
    try {
      const response = await webinarService.getWebinarById(webinarId);
      setWebinarDetails(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleComplete = (title) => {
    setTitle(title);
    setFeedback(true);
  };

  const setMeeting = () => {
    setJoinMeeting(true);
  };

  function enrolledCourse() {
    setTrigger(true);
    dispatch(postEnrollCoursesThunk(enrollPayload));
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (courses.courseDataEnroll === "success") {
      }
    });
  }

  const handleRedirect = (redirect) => {
    history.push(`${redirect}`);
  };

  const handleSeeMore = () => {
    setMore(!more);
  };

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();
    feedbackData.user_id = AuthData().userId;
    feedbackData.course_id = course_id;
    try {
      const response = await FeedbackService.postFeedback(feedbackData);
      if (response !== undefined) {
        // setPostComplete(true);
        setFeedback(false);
        setTrigger(true);
        const payloadC = {
          course_id: course_id,
          user_id: AuthData()?.userId,
        };
        dispatch(postCompleteCourseThunk(payloadC));
        const payload = {
          material_id: wbId,
          user_id: AuthData()?.userId,
        };
        dispatch(postCompleteMaterialThunk(payload));
      }
    } catch (error) {
      if (error?.data?.detail.includes("already commented")) {
        setFeedback(false);
        setTrigger(true);
        const payloadC = {
          course_id: course_id,
          user_id: AuthData()?.userId,
        };
        dispatch(postCompleteCourseThunk(payloadC));
        const payload = {
          material_id: wbId,
          user_id: AuthData()?.userId,
        };
        dispatch(postCompleteMaterialThunk(payload));
        // displayPopup(false, "Mohon lengkapi kelengkapan.", true, "OK");
        // setIsClaim(true);
      } else {
        displayPopup(
          false,
          "Gagal Memberikan Feedback Webinar, Mohon Coba Lagi",
          "Oke"
        );
      }

      // setPostComplete(true);
      // const payloadC = {
      //   course_id: course_id,
      //   user_id: AuthData().userId,
      // };
      // dispatch(postCompleteCourseThunk(payloadC));
    }
  };

  const handleChangeFeedback = (e) => {
    const newData = { ...feedbackData };
    if (
      e.target.name === "score_competence" ||
      e.target.name === "score_satisfaction"
    ) {
      newData[e.target.name] = parseInt(e.target.value);
    } else {
      newData[e.target.name] = e.target.value;
    }
    setFeedbackData(newData);
  };

  return (
    <>
      {joinMeeting && webinarDetail ? (
        <div>
          <iframe
            src={`${KONEKETEMU_URL}/${webinarDetail?.meeting_link}`}
            width="100%"
            height="600"
            frameBorder="0"
            allow="camera;microphone"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
          {/* Show Button If the time was between time_starts and time_end */}

          {moment().isBetween(
            moment(course?.topics[0]?.materials[0]?.time_starts).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            moment(course?.topics[0]?.materials[0]?.time_ends).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          ) ? (
            <Button
              className="w-100 mb-4 mt-4 btn-complete__webinar"
              onClick={() => handleComplete(course.title)}
            >
              Saya Telah Menyelesaikan Webinar
            </Button>
          ) : null}
        </div>
      ) : (
        <Row>
          <Col md={12}>
            <div className="webinar-banner"></div>
          </Col>
          <Col md={6}>
            <Card>
              <div className="webinar-title _webinar">{course?.title}</div>
              <div className="wrapper-description mb-2">
                {course?.description?.length > 175 && (
                  <>
                    {more ? (
                      <div
                        className="course_desc__course mb-4"
                        dangerouslySetInnerHTML={{
                          __html: course?.description,
                        }}
                      />
                    ) : (
                      <div
                        className="course_desc__course mb-4"
                        dangerouslySetInnerHTML={{
                          __html: course?.description.substr(0, 180) + "...",
                        }}
                      />
                    )}

                    <div
                      className="mb-2 see_more__description"
                      onClick={handleSeeMore}
                    >
                      {more ? "Lihat Lebih Sedikit" : "Lihat Selengkapnya"}
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex mb-4">
                <div className="mr-4">
                  <div className="webinar_title__time">Waktu Mulai</div>
                  <div>
                    {webinarsDataId
                      ? moment(webinarsDataId?.time_starts).format(" HH:mm") +
                        " WIB"
                      : ""}
                  </div>
                </div>
                <div>
                  <div className="webinar_title__duration">Durasi Webinar</div>
                  <div>
                    {moment(
                      moment(course?.topics[0]?.materials[0]?.time_ends).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    ).diff(
                      moment(
                        course?.topics[0]?.materials[0]?.time_starts
                      ).format("YYYY-MM-DD HH:mm:ss"),
                      "hours"
                    )}{" "}
                    Jam
                  </div>
                </div>
              </div>
              <div className="mb-5">
                {userStatus ? (
                  userStatus === "active" ? (
                    <>
                      {/* is user enrolled the webinar? */}
                      {course?.is_enrolled ? (
                        // is date now between time start and time end ?
                        moment().isBetween(
                          moment(
                            course?.topics[0]?.materials[0]?.time_starts
                          ).format("YYYY-MM-DD HH:mm:ss"),
                          moment(
                            course?.topics[0]?.materials[0]?.time_ends
                          ).format("YYYY-MM-DD HH:mm:ss")
                        ) ? (
                          <a
                            className="w-100 button-join btn text-white"
                            href={`${webinarDetail?.meeting_link}`}
                            target="_blank"
                          >
                            Bergabung
                          </a>
                        ) : // is the date now is after than time end ?

                        moment().isAfter(
                            moment(
                              course?.topics[0]?.materials[0]?.time_ends
                            ).format("YYYY-MM-DD HH:mm:ss")
                          ) ? (
                          <Button className="w-100 button-join" disabled={true}>
                            Sesi Webinar Telah Berakhir
                          </Button>
                        ) : (
                          <Button className="w-100 button-join" disabled={true}>
                            Belum mulai
                          </Button>
                        )
                      ) : // is the webinar already past or still can be enrolled?
                      moment().isAfter(
                          moment(course?.topics[0]?.materials[0]?.time_ends)
                        ) ? (
                        <button className="w-100 button-join" disabled={true}>
                          Sesi Webinar Telah Berakhir
                        </button>
                      ) : (
                        <button
                          onClick={enrolledCourse}
                          className="w-100 button-join"
                        >
                          Ikuti Webinar
                        </button>
                      )}
                    </>
                  ) : (
                    <Button
                      onClick={() => handleRedirect("/membership")}
                      className="w-100 button-join"
                    >
                      Berlangganan Sekarang
                    </Button>
                  )
                ) : (
                  <Button
                    onClick={() => handleRedirect("/login")}
                    className=" w-100 button-join"
                  >
                    Masuk untuk mengikuti
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        show={feedback}
        onHide={() => setFeedback(false)}
        size="lg"
        centered
      >
        <form onSubmit={handleSubmitFeedback} className="_feedback py-4 px-2">
          <div className="_feedback hero_title_label mb-1 px-3">
            Form Feedback Webinar
          </div>

          <div className="_feedback sub_title_label mb-4 px-3">
            Silahkan jawab pertanyaan-pertanyaan di bawah ini sesuai dengan
            keadaan Anda.
          </div>
          <ol className="_feedback px-3">
            <li>
              <div className="_feedback title_label ">
                Seberapa puaskah Anda dengan sesi Webinar yang anda ikuti?
              </div>
              <div className="_feedback d-flex my-5 justify-content-between">
                <div className="_feedback d-flex flex-column w-100 justify-emoticon">
                  <div
                    style={{ gap: "6px" }}
                    className="d-flex justify-content-between"
                  >
                    <label for="1">
                      <input
                        className={`_feedback _feedback-input stp`}
                        name="score_satisfaction"
                        type="radio"
                        id="1"
                        value="1"
                        onChange={handleChangeFeedback}
                        required
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_satisfaction == 1
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction1}
                      />
                    </label>
                    <label for="2">
                      <input
                        className={`_feedback _feedback-input tp`}
                        name="score_satisfaction"
                        type="radio"
                        id="2"
                        onChange={handleChangeFeedback}
                        value="2"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_satisfaction == 2
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction2}
                      />
                    </label>
                    <label for="3">
                      <input
                        className={`_feedback _feedback-input n `}
                        name="score_satisfaction"
                        type="radio"
                        id="3"
                        onChange={handleChangeFeedback}
                        value="3"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_satisfaction == 3
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction3}
                      />
                    </label>
                    <label for="4">
                      <input
                        name="score_satisfaction"
                        className="_feedback-input"
                        type="radio"
                        id="4"
                        onChange={handleChangeFeedback}
                        value="4"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_satisfaction == 4
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction4}
                      />
                    </label>
                    <label for="5">
                      <input
                        name="score_satisfaction"
                        type="radio"
                        className="_feedback-input"
                        id="5"
                        onChange={handleChangeFeedback}
                        value="5"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_satisfaction == 5
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction5}
                      />
                    </label>
                    <label for="6">
                      <input
                        name="score_satisfaction"
                        type="radio"
                        className="_feedback-input"
                        id="6"
                        onChange={handleChangeFeedback}
                        value="6"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_satisfaction == 6
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction6}
                      />
                    </label>
                  </div>
                  <div className="_feedback d-flex justify-content-between">
                    <div className="_feedback title_label__sub">
                      Sangat Tidak Puas
                    </div>

                    <div className="_feedback title_label__sub">
                      Sangat Puas
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="_feedback title_label">
                Seberapa baikkah kompetensi narasumber dalam menyampaikan
                materi?
              </div>
              <div className="_feedback d-flex my-5 justify-content-between">
                <div className="_feedback d-flex flex-column w-100 justify-emoticon">
                  <div
                    style={{ gap: "6px" }}
                    className="d-flex justify-content-between"
                  >
                    <label for="7">
                      <input
                        className={`_feedback _feedback-input stp`}
                        name="score_competence"
                        type="radio"
                        id="7"
                        value="1"
                        onChange={handleChangeFeedback}
                        required
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_competence == 1
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction1}
                      />
                    </label>
                    <label for="8">
                      <input
                        className={`_feedback _feedback-input tp `}
                        name="score_competence"
                        type="radio"
                        id="8"
                        onChange={handleChangeFeedback}
                        value="2"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_competence == 2
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction2}
                      />
                    </label>
                    <label for="9">
                      <input
                        className={`_feedback _feedback-input n `}
                        name="score_competence"
                        type="radio"
                        id="9"
                        onChange={handleChangeFeedback}
                        value="3"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_competence == 3
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction3}
                      />
                    </label>
                    <label for="10">
                      <input
                        className="_feedback-input"
                        name="score_competence"
                        type="radio"
                        id="10"
                        onChange={handleChangeFeedback}
                        value="4"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_competence == 4
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction4}
                      />
                    </label>
                    <label for="11">
                      <input
                        className="_feedback-input"
                        name="score_competence"
                        type="radio"
                        onChange={handleChangeFeedback}
                        id="11"
                        value="5"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_competence == 5
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction5}
                      />
                    </label>

                    <label for="12">
                      <input
                        className="_feedback-input"
                        name="score_competence"
                        type="radio"
                        id="12"
                        onChange={handleChangeFeedback}
                        value="6"
                      />
                      <Image
                        className={`_feedback ${
                          feedbackData.score_competence == 6
                            ? "selected_feedback"
                            : ""
                        }`}
                        src={reaction6}
                      />
                    </label>
                  </div>

                  <div className="_feedback d-flex justify-content-between">
                    <div className="_feedback mr-3 title_label__sub">
                      Sangat Tidak Puas
                    </div>
                    <div className="_feedback ml-2 title_label__sub">
                      Sangat Puas
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="_feedback title_label">
                Menurut Anda, apa aspek yang sudah baik dari sesi Webinar ini?
              </div>
              <div className="_feedback mt-2 mb-3">
                <textarea
                  type="text"
                  className="_feedback w-100"
                  rows="6"
                  name="review_whats_good"
                  onChange={handleChangeFeedback}
                  placeholder="Tuliskan pendapat anda"
                  required
                ></textarea>
              </div>
            </li>
            <li>
              <div className="_feedback title_label">
                Menurut Anda, apa aspek yang masih kurang dari Webinar ini?
              </div>
              <div className="_feedback mt-2 mb-3">
                <textarea
                  type="text"
                  className="_feedback w-100"
                  onChange={handleChangeFeedback}
                  rows="6"
                  name="review_whats_missing"
                  placeholder="Tuliskan pendapat anda"
                  required
                ></textarea>
              </div>
            </li>
            <li>
              <div className="_feedback title_label ">
                Apakah terdapat aspek dari Webinar* yang menimbulkan
                kebingungan?
              </div>
              <div className="_feedback mt-2 mb-3">
                <textarea
                  type="text"
                  className="_feedback w-100"
                  rows="6"
                  name="review_whats_confusing"
                  onChange={handleChangeFeedback}
                  placeholder="Tuliskan pendapat anda"
                  required
                ></textarea>
              </div>
            </li>
            <li>
              <div className="_feedback title_label">
                Menurut Anda, apa yang dapat dilakukan untuk meningkatkan
                kualitas Webinar ke depannya?
              </div>
              <div className="_feedback mt-2 mb-3">
                <textarea
                  type="text"
                  className="_feedback w-100"
                  rows="6"
                  name="review_suggestion"
                  onChange={handleChangeFeedback}
                  placeholder="Tuliskan pendapat anda"
                  required
                ></textarea>
              </div>
            </li>
          </ol>
          <div className="_feedback text-right">
            <button
              type="submit"
              name="submit"
              className="_feedback btn btn-primary text-submit mt-3 text-right"
            >
              Kirim
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default WebinarDetail2;
