import { PromiseService } from "../../services/PromiseService";
import {
  COURSES_DATA_LINK,
  COURSE_POPULAR_DATA_LINK,
  COURSE_LATEST_DATA_LINK,
} from "../config/CoursesConfig";
import {
  //get
  getCoursesData,
  getCoursesDataSuccess,
  getCoursesDataIdSuccess,
  getCoursesDataFail,
  //get popular
  getCoursesPopularData,
  getCoursesPopularDataSuccess,
  getCoursesPopularDataFail,
  //get brevet course
  getCoursesBrevetData,
  getCoursesBrevetDataSuccess,
  getCoursesBrevetDataFail,
  //post enroll
  postEnrollCourse,
  postEnrollCourseSuccess,
  postEnrollCourseFail,
  //complete material
  postCompleteMaterial,
  postCompleteMaterialSuccess,
  postCompleteMaterialFail,
  //complete course
  postCompleteCourse,
  postCompleteCourseSuccess,
  postCompleteCourseFail,
  // get by division
  getCoursesByRecommendData,
  getCoursesByRecommendDataSuccess,
  getCoursesByRecommendDataFail,
} from "../actions/CoursesActions";

//get
export const getCoursesDataThunk = (data) => async (dispatch) => {
  dispatch(getCoursesData(data));
  if (data.type === "id") {
    try {
      const response = await PromiseService.getData(
        `${COURSES_DATA_LINK}${data.param}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getCoursesDataIdSuccess(response.data));
      } else {
        dispatch(getCoursesDataFail(response));
      }
    } catch (error) {
      dispatch(getCoursesDataFail(error));
    }
  } else if (data.type === "filter") {
    try {
      const response = await PromiseService.getData(
        `${COURSES_DATA_LINK}${data.param}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getCoursesDataSuccess(response.data));
      } else {
        dispatch(getCoursesDataFail(response));
      }
    } catch (error) {
      dispatch(getCoursesDataFail(error));
    }
  }
};

//get popular
export const getCoursesPopularDataThunk = (data) => async (dispatch) => {
  dispatch(getCoursesPopularData(data));

  try {
    const response = await PromiseService.getData(
      `${COURSE_POPULAR_DATA_LINK}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getCoursesPopularDataSuccess(response.data));
    } else {
      dispatch(getCoursesPopularDataFail(response));
    }
  } catch (error) {
    dispatch(getCoursesPopularDataFail(error));
  }
};

export const getCoursesBrevetDataThunk = (data) => async (dispatch) => {
  dispatch(getCoursesBrevetData(data));

  try {
    const response = await PromiseService.getData(
      `${COURSES_DATA_LINK}${data.param}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getCoursesBrevetDataSuccess(response.data));
    } else {
      dispatch(getCoursesBrevetDataFail(response));
    }
  } catch (error) {
    dispatch(getCoursesBrevetDataFail(error));
  }
};

// get by division
export const getCoursesByRecommendDataThunk = (data) => async (dispatch) => {
  dispatch(getCoursesByRecommendData(data));

  try {
    const response = await PromiseService.getData(
      `${COURSES_DATA_LINK}${data?.param}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getCoursesByRecommendDataSuccess(response.data));
    } else {
      dispatch(getCoursesByRecommendDataFail(response));
    }
  } catch (error) {
    dispatch(getCoursesByRecommendDataFail(error));
  }
};

export const postEnrollCoursesThunk = (data) => async (dispatch) => {
  dispatch(postEnrollCourse(data));
  const body = data;
  try {
    const response = await PromiseService.postData(
      `${COURSES_DATA_LINK}/enroll`,
      body
    );
    if ((response.status === 201 || response.status === 200) && response.data) {
      dispatch(postEnrollCourseSuccess(response.data));
    } else {
      dispatch(postEnrollCourseFail(response));
    }
  } catch (error) {
    dispatch(postEnrollCourseFail(error));
  }
};

export const postCompleteMaterialThunk = (data) => async (dispatch) => {
  const body = data;
  dispatch(postCompleteMaterial());
  try {
    const response = await PromiseService.postData(
      `${COURSES_DATA_LINK}/complete_material`,
      body
    );
    if ((response.status === 201 || response.status === 200) && response.data) {
      dispatch(postCompleteMaterialSuccess(response.data));
    } else {
      dispatch(postCompleteMaterialFail(response));
    }
  } catch (error) {
    dispatch(postCompleteMaterialFail(error));
  }
};

export const postCompleteCourseThunk = (data) => async (dispatch) => {
  const body = data;
  dispatch(postCompleteCourse());
  try {
    const response = await PromiseService.postData(
      `${COURSES_DATA_LINK}/complete_course`,
      body
    );
    if ((response.status === 201 || response.status === 200) && response.data) {
      dispatch(postCompleteCourseSuccess(response.data));
    } else {
      dispatch(postCompleteCourseFail(response));
    }
  } catch (error) {
    dispatch(postCompleteCourseFail(error));
  }
};
