import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Template from "../components/Layout/Template";
import ScrollToTop from "./ScrollToTop";
import RouteData from "./route-data";
// import { googleAnalytics } from "./functions";

export default function Routes() {
  const location = useLocation();
  useEffect(() => {
    // googleAnalytics();
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <Switch>
        {RouteData.map((d, i) => (
          <Route key={i} exact={d.exact} path={d.path}>
            {d.redirect ? (
              <Redirect to={d.redirect} />
            ) : (
              <Template
                isSidebar={d.isSidebar}
                isHeader={d.isHeader}
                InnerComponent={d.component}
                noFooter={d.noFooter}
              />
            )}
          </Route>
        ))}
      </Switch>
    </>
  );
}
