import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TimelineCalendar from "../../components/TimelineCalendar";
import { AuthIsLogin } from "../../helper/auth";
import { slugify } from "../../helper/functions";
import { UserService } from "../../http/UserHttp";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import Info from "../../components/common/Info";

function MyCalendar() {
  const [list, setList] = useState([]);
  const [detailModal, setDetailModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  // runs on the first render
  useEffect(() => {
    // check auth & get data from API
    AuthIsLogin(history);
    getCalendar();
  }, []);

  // runs when list updates
  useEffect(() => {
    // console.log("=====DEBUG=====");
    // console.log(list);
  }, [list]);

  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };

  // asynchronously hit API get calendar
  const getCalendar = async () => {
    dispatch(loadingStart());
    try {
      // when response succeed concate webinar & consultation into tmp list
      const response = await UserService.getCalendar("mandatory=true");
      let tmpList = response.my_calendar;
      // format and add tmp list into list
      tmpList = tmpList.map((e) => sanitizeItem(e));
      setList(tmpList);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  // function to modify a webinar or consultation
  // following the format of calender
  function sanitizeItem(e) {
    return {
      start: moment(e.time_starts)._d,
      end: moment(e.time_ends)._d,
      title: e.title,
      detail: e.course_id,
      course_slug: slugify(e.course),
    };
  }

  return (
    <div>
      {/* <Info
        detailModal={detailModal}
        setDetailModal={setDetailModal}
        seeMoreInformation={seeMoreInformation}
      /> */}
      <TimelineCalendar mywebinar={list} />
    </div>
  );
}

export default MyCalendar;
