import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TutorialModal from "../../pages/Tutorial/TutorialModal";
import linkedin from "./../../assets/images/logo/LinkedIn.png";
import instagram from "./../../assets/images/logo/Instagram.png";
import tiktok from "./../../assets/images/logo/TikTok.png";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { Link } from "react-router-dom";
const NewFooter = () => {
  const [show, setShow] = useState(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };
  return (
    <div className="footer-wrapper mt-0">
      <Container>
        <Row>
          <Col sm={12}>
            <Row>
              <Col md={3} sm={4} xs={12} className="xs-mb-2">
                <h5 className="title-footer">Kategori Pelatihan</h5>
                <ul>
                  <li>
                    <a href="/search/pelatihan/category=soft%20skills">
                      Soft Skills
                    </a>
                  </li>
                  <li>
                    <a href="/search/pelatihan/category=perpajakan">
                      Perpajakan
                    </a>
                  </li>
                  <li>
                    <a href="/search/pelatihan/category=akuntansi">Akuntansi</a>
                  </li>
                  <li>
                    <a href="/search/pelatihan/category=keuangan">Keuangan</a>
                  </li>
                  <li>
                    <a href="/search/pelatihan/category=manajemen%20sdm">
                      Manajemen SDM
                    </a>
                  </li>
                  <li>
                    <a href="/search/pelatihan/category=pemasaran">Pemasaran</a>
                  </li>
                  <li>
                    <a href="/search/pelatihan/category=penjualan">Penjualan</a>
                  </li>
                  <li>
                    <a href="/search/pelatihan/category=k3">K3</a>
                  </li>
                </ul>
              </Col>
              <Col md={3} sm={4} xs={12}>
                <div className="mb-5">
                  <h5 className="title-footer">Tautan Penting</h5>
                  <ul>
                    <li>
                      <Link to="/about-us">Tentang Kami</Link>
                    </li>
                    {/* <li>
                      <Link to="/membership">Langganan</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/mitra">Mitra Satukelas</Link>
                    </li> */}
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <a
                        href="https://admin.satukelas.com"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Masuk Sebagai Admin
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Hubungi Sales
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} sm={4} xs={12}>
                <div>
                  <h5 className="title-footer">Bantuan</h5>
                  <ul>
                    <li>
                      <a href="https://form.asana.com/?k=gQs4Q3qV2qipkvrpbNOhOA&d=1200494741159113">
                        Feedback
                      </a>
                    </li>
                    <li>
                      <div
                        className="tutorial_modal_footer"
                        onClick={handleShow}
                      >
                        Tutorial
                      </div>
                    </li>
                    <li>
                      <Link to="/privacy">Kebijakan Privasi</Link>
                    </li>
                    <li className="remove-whitespace">
                      <Link to="/term">Syarat dan Ketentuan</Link>
                    </li>{" "}
                    <li>
                      <Link to="#">Hubungi Customer Support</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3} sm={4} xs={12} className="xs-mb-2">
                <div id="title_footer">
                  <Link to="/">
                    <div className="logo_width">
                      <div
                        alt="Satukelas"
                        className="satu-kelas-logo__main-footer mb-2"
                      ></div>
                    </div>
                  </Link>
                </div>
                <div className="mb-2 wording__footer-satukelas">
                  PT. Satukelas Adhyapana Nusantara
                </div>

                <p className="nib__footer-satukelas">NIB: 1801220047046</p>
                <p
                  id="address_footer"
                  className="w-100 address__footer-satukelas"
                >
                  <a
                    href="http://maps.google.com/?q=Jl. Raya Ragunan No. 65C,
                  Pasar Minggu, Jakarta Selatan, DKI Jakarta, Indonesia,
                  12520."
                  >
                    Jl. Raya Ragunan No. 65C, <br />
                    Pasar Minggu, Jakarta Selatan, <br /> DKI Jakarta,
                    Indonesia, 12520. <br />
                  </a>
                  <br />
                  <a href="https://api.whatsapp.com/send?phone=6282130070040">
                    0821-3007-0040 <br />
                  </a>
                  <br />
                  <a href="mailto:ask@satukelas.com">ask@satukelas.com</a>
                  <br />
                </p>
                <h5 className="sosmed__satukelas-footer">Ikuti Kami</h5>
                <ul className="list-inline">
                  <li className="list-inline-item mr-3">
                    <a href="#">
                      <img alt="social-media-1" src={linkedin} />
                    </a>
                  </li>
                  <li className="list-inline-item mr-3">
                    <a href="#">
                      <img alt="social-media-2" src={tiktok} />
                    </a>
                  </li>
                  <li className="list-inline-item mr-3">
                    <a href="#">
                      {" "}
                      <img src={instagram} alt="instagram" />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            <div className="middle-footer mt-5 pt-4"></div>
          </Col>
          <Col sm={12} className="lower-footer pt-0"></Col>
          <Col xs={12} sm={12}>
            <div>
              <p className="copyright-text w-100 text-center">
                Technical Contact{" "}
                <span>
                  <a
                    href="https://koneksi.group/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Koneksi Group
                  </a>
                </span>{" "}
                © 2021 Satukelas by PT. Satukelas Adhyapana Nusantara. All
                Rights Reserved.
              </p>
            </div>
          </Col>
        </Row>
        <TutorialModal onClose={onClose} show={show} />
      </Container>
    </div>
  );
};
export default NewFooter;
