import {
  //get
  MEMBERSHIP_DATA,
  MEMBERSHIP_DATA_ID_SUCCESS,
  MEMBERSHIP_DATA_SUCCESS,
  MEMBERSHIP_DATA_FAIL,
  //post
  MEMBERSHIP_DATA_POST,
  MEMBERSHIP_DATA_POST_SUCCESS,
  MEMBERSHIP_DATA_POST_FAIL,
  //put
  MEMBERSHIP_DATA_PUT,
  MEMBERSHIP_DATA_PUT_SUCCESS,
  MEMBERSHIP_DATA_PUT_FAIL,
  //delete
  MEMBERSHIP_DATA_DELETE,
  MEMBERSHIP_DATA_DELETE_SUCCESS,
  MEMBERSHIP_DATA_DELETE_FAIL,
  // start
  MEMBERSHIP_DATA_START,
  MEMBERSHIP_DATA_START_SUCCESS,
  MEMBERSHIP_DATA_START_FAIL,
} from "../config/MembershipConfig";

const intialState = {
  membershipDataPost: "",
  membershipDataPut: "",
  membershipDataDelete: "",
  membershipData: [],
  membershipDataId: [],
  membershipDataStart: "",
  isLoading: false,
};

export const MembershipReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case MEMBERSHIP_DATA:
      return { ...state, isLoading: true };
    case MEMBERSHIP_DATA_SUCCESS:
      return { ...state, isLoading: false, membershipData: action.value };
    case MEMBERSHIP_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, membershipDataId: action.value };
    case MEMBERSHIP_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        membershipData: "error",
        membershipDataId: "error",
      };
    //post
    case MEMBERSHIP_DATA_POST:
      return { ...state, isLoading: true, membershipDataPost: "" };
    case MEMBERSHIP_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, membershipDataPost: "success" };
    case MEMBERSHIP_DATA_POST_FAIL:
      return { ...state, isLoading: false, membershipDataPost: "error" };

    //put
    case MEMBERSHIP_DATA_PUT:
      return { ...state, isLoading: true, membershipDataPut: "" };
    case MEMBERSHIP_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, membershipDataPut: "success" };
    case MEMBERSHIP_DATA_PUT_FAIL:
      return { ...state, isLoading: false, membershipDataPut: "error" };

    //delete
    case MEMBERSHIP_DATA_DELETE:
      return { ...state, isLoading: true, membershipDataDelete: "" };
    case MEMBERSHIP_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, membershipDataDelete: "success" };
    case MEMBERSHIP_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, membershipDataDelete: "error" };
      
    default:
      return state;
  }
};
