import React, { useEffect, useState } from "react";
const { Row, Col } = require("react-bootstrap");
const { testimoniEnglish } = require("../helper/constant");

function Testimonial() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function renderSplideTestimoni() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Splide
        options={{
          rewind: true,
          perPage: 1,
          perMove: 1,
          gap: "1.5rem",
          pagination: true,
          arrows: false,
          autoplay: true,
          interval: 3000,
        }}
      >
        {testimoniEnglish?.length > 0
          ? testimoniEnglish?.map((value, index) => (
              <SplideSlide key={index}>
                <div className="testimoni-container">
                  <div className="testimoni-profile">
                    {value.photo === "" ? (
                      <div className="img-testimoni d-flex align-items-center justify-content-center align-items-center">
                        <span className="icon-user"></span>
                      </div>
                    ) : (
                      <img
                        alt="Profile User"
                        className="lazyload"
                        data-src={value.photo}
                      />
                    )}
                  </div>
                  <div className="testimoni-content">
                    <div className="text">{value.testimoni}</div>
                    <div className="info">
                      <div className="name">
                        {value.name} - {value.company}
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            ))
          : null}
      </Splide>
    );
  }

  return (
    <Row>
      <Col lg={12} className="mt-4 mb-5" id="testimoni">
        {loaded ? <>{renderSplideTestimoni()}</> : null}
      </Col>
    </Row>
  );
}
export default Testimonial;
