import Axios from "axios";
import { ENDPOINT } from "../helper/constant";
import { AuthData } from "../helper/auth";

const timeout = 60000;

export const HTTP_SERVICE = {
  get(url, request) {
    return new Promise(async (resolve, reject) => {
      let headers = {
        "Content-Type": "application/json",
      };
      if (typeof window !== "undefined") {
        if (AuthData()) {
          headers = {
            "Content-Type": "application/json",
            Authorization: "Basic " + AuthData().authorization,
          };
        } else {
          headers = {
            "Content-Type": "application/json",
          };
        }
      }

      Axios(ENDPOINT + url, {
        method: "get",
        headers: headers,
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  post(url, request, authType) {
    return new Promise(async (resolve, reject) => {
      let authorization = "";
      if (authType) {
        authorization = Buffer.from(
          `${request.username}:${request.password}`
        ).toString("base64");
      } else {
        authorization = AuthData().authorization;
      }

      let headers = {
        "Content-Type": "application/json",
        Authorization: "Basic " + authorization,
      };
      if (authType) {
        headers = {
          "Content-Type": "application/json",
        };
      }

      Axios(ENDPOINT + url, {
        method: "post",
        headers: headers,
        data: JSON.stringify(request),
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
      // Axios(ENDPOINT + url, {
      //   method: "post",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "Basic " + authorization,
      //   },
      //   data: JSON.stringify(request),
      //   timeout,
      // }).then(
      //   (result) => {
      //     resolve(result);
      //   },
      //   function (error) {
      //     console.log("error HTTP SERVICE");
      //     reject(error);
      //   }
      // );
    });
  },
  postFormData(url, request) {
    return new Promise(async (resolve, reject) => {
      //token
      Axios(ENDPOINT + url, {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + AuthData().authorization,
        },
        data: request,
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  put(url, request) {
    return new Promise(async (resolve, reject) => {
      //token
      Axios(ENDPOINT + url, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + AuthData().authorization,
        },
        data: JSON.stringify(request),
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  getImage(url, request) {
    return new Promise(async (resolve, reject) => {
      let headers = {
        responseType: "arraybuffer",
        "Content-Type": "image/png",
        Accept: "image/png",
      };
      if (typeof window !== "undefined") {
        if (AuthData()) {
          headers = {
            responseType: "arraybuffer",
            "Content-Type": "image/png",
            Authorization: "Basic " + AuthData().authorization,
            Accept: "image/png",
          };
        } else {
          headers = {
            responseType: "arraybuffer",
            "Content-Type": "image/png",
            Accept: "image/png",
          };
        }
      }

      Axios(ENDPOINT + url, {
        method: "get",
        headers: headers,
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
};
