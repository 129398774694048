import React from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import "../../assets/css/Courses/TabsCourseDetail.css";
import RelatedFileSection from "./RelatedFileSection";
function TabsCourseDetail({ content, item, id, membershipData }) {

  return (
    <Container className="tabs_course__detail mb-4">
      <Tabs
        defaultActiveKey="deskripsi"
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab tabClassName="" eventKey="deskripsi" title="Deskripsi">
          <div className="course_description__text">Deskripsi Kelas</div>
          <div className="inside mt-2">
            <div className="info mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </div>
          </div>
        </Tab>
        <Tab
          tabClassName="text-blacks"
          eventKey="handout"
          title="Bahan Belajar"
        >
          <div className="inside my-5">
            <div className="info mt-3">
              <RelatedFileSection membershipData={membershipData} handouts={item?.handouts} id={id} />
            </div>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
}
export default TabsCourseDetail;
