import {
  NOTIFICATIONS_DATA,
  NOTIFICATIONS_DATA_ID_SUCCESS,
  NOTIFICATIONS_DATA_SUCCESS,
  NOTIFICATIONS_DATA_FAIL,
} from "../config/NotificationConfig";

const intialState = {
  notificationsData: null,
  notificationsDataId: null,
  isLoading: false,
};

export const NotificationsReducers = function (state = intialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_DATA:
      return { ...state, isLoading: true };
    case NOTIFICATIONS_DATA_SUCCESS:
      return { ...state, isLoading: false, notificationsData: action.value };
    case NOTIFICATIONS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, notificationsDataId: action.value };
    case NOTIFICATIONS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        notificationsData: "error",
        notificationsDataId: "error",
      };

    default:
      return state;
  }
};
