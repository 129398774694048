import React, { useEffect } from "react";
import { Button, Modal, Carousel } from "react-bootstrap";
import "../../assets/css/Tutorial/index.css";
function TutorialModal({ show, onClose }) {
  useEffect(() => { }, [show]);
  return (
    <Modal show={show} size="lg" onHide={onClose}>
      <Modal.Header id="tutor-header" closeButton></Modal.Header>
      <Modal.Body className="p-4">
        <Carousel className="d-flex py-3 min-height text-center">
          <Carousel.Item id="1" className="mb-4">
            <div className="d-flex justify-content-center mb-4">
              <div className="placeholder_tutorial1"></div>
            </div>
            <h4 className="tutorial-modal">
              Selamat bergabung di satukelas.com.
            </h4>
            <div>
              Selamat! Anda Mendapatkan Free Access Semua Pelatihan Selama 7
              Hari!
            </div>


            <Button href="/dashboard/membership/" className="mt-3">
              Klaim Sekarang!
            </Button>
          </Carousel.Item>
          <Carousel.Item id="2" className="mb-4">
            <div className="d-flex justify-content-center mb-4">
              <div className="placeholder_tutorial2"></div>
            </div>
            <div className="mb-3">
              <h4 className="tutorial-modal">
                Cek Katalog Pelatihan untuk Anda
              </h4>
              <span>Lihat list katalog pelatihan yang cocok untuk anda</span>
            </div>
            <Button
              className="button_visit__page mr-3"
              href="/search/semua/"
              target="_blank"
            >
              Kunjungi Halaman
            </Button>
          </Carousel.Item>
          <Carousel.Item id="3" className="mb-4">
            <div className="d-flex justify-content-center mb-4">
              <div className="placeholder_tutorial3"></div>
            </div>{" "}
            <div className="mb-3">
              <h4 className="tutorial-modal">Mulai Pelatihan Anda</h4>
              <span>Mulai pelatihan dengan menonton video pembelajaran</span>
            </div>
            <Button
              className="button_visit__page mr-3"
              href="/course/free-membership--001--akuntansi-pajak/co56183515/"
              target="_blank"
            >
              Kunjungi Halaman
            </Button>
          </Carousel.Item>

          <Carousel.Item id="4" className="mb-4">
            <div className="d-flex justify-content-center mb-4">
              <div className="placeholder_tutorial4"></div>
            </div>
            <div className="mb-4">
              <h4 className="tutorial-modal">Mulai Belajar Sekarang</h4>
              <span>Cek sekarang untuk segera menemukannya!</span>
            </div>

            <Button className="button_visit__page mr-3" href="/search/semua/">
              Kunjungi Katalog
            </Button>
            <Button onClick={onClose}>Tutup Tutorial</Button>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

export default TutorialModal;
