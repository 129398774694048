import React from "react";
import { titleBtnHeader, env } from "../../helper/constant";
import { slugify } from "../../helper/functions";
import { Link } from "react-router-dom";
import iconRight from "../../assets/images/icon-right.png";
import iconRightHover from "../../assets/images/icon-right-hover.png";
export default function CatalogMenu(props) {
  const {
    id,
    handleMouseHover,
    handleMouse,
    toggleSubMenu,
    hoverIcon,
    coursePath,
  } = props;
  return (
    <div
      id="lainnya"
      className={`third header_left__item header_left__item-sertifikasi position-relative ${
        id === "lainnya" ? "interaction-active" : id === "search" ? "" : ""
      } dropdown mx-1`}
      onMouseOver={handleMouse}
    >
      Katalog
      <ul
        id="menu"
        className={`mega-menu__container pt-3 `}
        style={{ top: "55px" }}
      >
        <li
          className="list_menu_nav border-bottom menu-hover p-3"
          onMouseEnter={() => handleMouseHover("ondemand")}
          onMouseLeave={handleMouseHover}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <Link to="/search/semua/course_type=semua">On Demand</Link>
            <span>
              <img
                src={hoverIcon === "ondemand" ? iconRightHover : iconRight}
                alt="icon-right"
              />
            </span>
          </div>
          <ul id="sub_menu" className={`submenu pt-3`}>
            {titleBtnHeader?.map((item, index) => (
              <li className="border-bottom" key={index}>
                <Link to={item.link}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li
          className="list_menu_nav border-bottom menu-hover p-3"
          onClick={() => toggleSubMenu(5)}
          onMouseEnter={() => handleMouseHover("persiapan")}
          onMouseLeave={handleMouseHover}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <p>Persiapan Sertifikasi</p>
            <span>
              <img
                src={hoverIcon === "persiapan" ? iconRightHover : iconRight}
                alt="icon-right"
              />
            </span>
          </div>
          <ul id="sub_menu" className={`submenu pt-3`} style={{ top: "16%" }}>
            <li className="border-bottom">
              <Link
                to={
                  env === "production"
                    ? "/course/try-out-brevet-ab/co42433570"
                    : "/course/tryout-brevet-ab/co57253482/"
                }
              >
                Tryout Brevet Pajak AB
              </Link>
            </li>
            <li className="border-bottom">
              <Link
                to={
                  env === "production"
                    ? "/course/try-out-brevet-c/co66130030"
                    : "/course/tryout---1/co69960176/"
                }
              >
                Tryout Brevet Pajak C
              </Link>
            </li>
          </ul>
        </li>
        <li
          className="list_menu_nav border-bottom menu-hover p-3"
          onClick={() => toggleSubMenu(6)}
          onMouseEnter={() => handleMouseHover("english")}
          onMouseLeave={handleMouseHover}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <Link to="/english-program" className="d-block text-left">
              English Course <br />
              <em
                style={{
                  fontSize: "12px",
                  opacity: "0.7",
                }}
              >
                [Powered by ReallyEnglish]
              </em>
            </Link>
            <span>
              <img
                src={hoverIcon === "english" ? iconRightHover : iconRight}
                alt="icon-right"
              />
            </span>
          </div>

          <ul id="sub_menu" className={`submenu pt-3`} style={{ top: "32%" }}>
            {coursePath?.map((item, index) => (
              <li className="border-bottom" key={index}>
                <Link to={`/${slugify(item.title)}/${item.path_id}?lp=english`}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li className="list_menu_nav border-bottom p-3">
          <div className="d-flex align-items-center justify-content-between w-100">
            <Link to="/webinar-list/webinar/type=webinar">Live Webinar</Link>
          </div>
        </li>
        <li className="list_menu_nav border-bottom p-3">
          <div className="d-flex align-items-center justify-content-between w-100">
            <Link to="/webinar-list/konsultasi/type=konsultasi">
              Live Consultation
            </Link>
          </div>
        </li>
        <li className="list_menu_nav menu-hover p-3">
          <div className="d-flex align-items-center justify-content-between w-100">
            <Link to="/indonesia-tax-review" className="d-block text-left">
              Jurnal Pajak ITR <br />
              <em
                style={{
                  fontSize: "12px",
                  opacity: "0.7",
                }}
              >
                [Indonesian Tax Review]
              </em>
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
}
