/* eslint-disable camelcase */
import { Provider } from 'react-redux';
import ReduxStore from './redux/store';
import React, { useCallback, useEffect } from 'react';
import Routes from './helper/routes';
import { oneSignalID } from './helper/credentials';
import TagManager from 'react-gtm-module';
import ScormProvider from 'react-scorm-provider';
import { useHistory } from 'react-router-dom';
import { deleteCookie } from './helper/functions';

const OneSignal = typeof window !== 'undefined' ? window.OneSignal : [];
const tagManagerArgs = {
  gtmId: 'GTM-KXRP87R',
};

function App() {
  const history = useHistory();

  useEffect(() => {
    if (OneSignal) {
      OneSignal.push(() => {
        OneSignal.init({
          appId: oneSignalID,
        });
        OneSignal.on('notificationDisplay', function (e) {
          // console.log(e);
        });
      });
    }
  }, [OneSignal]);

  // useEffect(() => {
  //   hotjar.initialize(2775529, 6);
  // TagManager.initialize(tagManagerArgs);
  // }, []);

  return (
    <ScormProvider debug={true} version={'1.2'}>
      <Provider store={ReduxStore.store}>
        <Routes />
      </Provider>
    </ScormProvider>
  );
}

export default App;
