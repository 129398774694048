import React from "react";
import { Image } from "react-bootstrap";
import bgCta from "../../assets/images/cdlc/banner/CTA/bg-cta.png";
import bgReverse from "../../assets/images/cdlc/banner/CTA/bg-reverse.png";
import iconShape from "../../assets/images/cdlc/banner/CTA/icon-shape.png";
import iconShapeReverse from "../../assets/images/cdlc/banner/CTA/icon-shape-reverse.png";
import iconTik from "../../assets/images/cdlc/banner/CTA/icon-tiktik.png";
import iconTik2 from "../../assets/images/cdlc/banner/CTA/icon-tik2.png";

const BannerCta = (props) => {
  const { contentText } = props;
  return (
    <div>
      {contentText === "reverse" ? (
        <div className="d-flex align-items-center flex-wrap flex-xl-nowrap justify-content-between position-relative z-index-1">
          <div className="col-12 col-lg-7 wrapp__content-cta ml-xl-3 mr-xl-2">
            <p className="subtitle__banner-cta">Platform untuk Admin (LMS & CMS)</p>
            <h2 className="title__banner-cta mb-3">
              Admin dashboard & <br /> Kustomisasi platform
            </h2>
            <div className="desc__banner-cta">
              <p>
                Dashboard untuk admin yang memungkinkan pengaturan pengguna, dimulai dari menambah akun pengguna, menugaskan pengguna pada pelatihan, membuat rombongan belajar (kelas), memberikan tugas, dan mengunduh laporan perkembangan
                dan hasil belajar.
              </p>
              <p>
                Layanan pengaturan platform yang dapat disesuaikan dengan kebutuhan pelanggan. Mulai dari pengaturan nama domain, logo, pengaturan dan edit layout halaman sesuai keinginan pelanggan. Pelanggan juga dapat menaruh informasi
                dalam fitur blog. 
              </p>
            </div>
            <span className="footer__banner-cta">LMS = Learning Management System CMS = Content Management System</span>
          </div>
          <div className="col-12 col-lg-5 wrapp__content-cta ">
            <div>
              <Image src={bgReverse} width="100%" />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-wrap flex-xl-nowrap align-items-center justify-content-between">
          <div className="col-12 col-lg-5 wrapp__content-cta mb-3 mb-xl-0 mr-xl-5 position-relative" style={{ zIndex: "1" }}>
            <div>
              <Image src={bgCta} width="100%" />
            </div>
          </div>
          <div className="col-12 col-lg-7 wrapp__content-cta">
            <p className="subtitle__banner-cta">Platform untuk Karyawan (LP)</p>
            <h2 className="title__banner-cta">Content library & User Dashboard</h2>
            <div className="desc__banner-cta">
              <p>Wadah untuk mengakses konten belajar yang dapat diakses dengan mudah dimana saja dan kapan saja. Selain memanfaatkan konten yang sudah disediakan satukelas, pelanggan juga dapat mengunggah konten belajar milik pribadi.</p>
              <p>Dashboard untuk pengguna yang berisi ikhtisar mengenai perkembangan dan hasil belajar. Selain belajar, melalui fitur ini pengguna juga dapat mengerjakan dan mengumpulkan tugas.</p>
            </div>
            <span className="footer__banner-cta">LP = Learning Platform</span>
          </div>
        </div>
      )}
      {contentText === "reverse" ? (
        <div>
          <div className="icon__shape-content-cta-reverse">
            <Image src={iconShapeReverse} />
          </div>
          <div className="icon__tik-content-cta-reverse">
            <Image src={iconTik2} />
          </div>
        </div>
      ) : (
        <div>
          <div className="icon__shape-content-cta">
            <Image src={iconShape} />
          </div>
          <div className="icon__tik-1-content-cta">
            <Image src={iconTik} />
          </div>
          <div className="icon__tik-2-content-cta">
            <Image src={iconTik2} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BannerCta;
