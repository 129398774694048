export const COURSES_DATA_LINK = "courses";
export const COURSE_POPULAR_DATA_LINK = "analytics/popular_courses";
export const COURSE_LATEST_DATA_LINK = "analytics/latest_courses";
//get
export const COURSES_DATA = "COURSES_DATA";
export const COURSES_DATA_SUCCESS = "COURSES_DATA_SUCCESS";
export const COURSES_DATA_ID_SUCCESS = "COURSES_DATA_ID_SUCCESS";
export const COURSES_DATA_FAIL = "COURSES_DATA_FAIL";

export const COURSES_BREVET_DATA = "COURSES_BREVET_DATA";
export const COURSES_BREVET_DATA_SUCCESS = "COURSES_BREVET_DATA_SUCCESS";
export const COURSES_BREVET_DATA_FAIL = "COURSES_BREVET_DATA_FAIL";



//get data by division
export const COURSES_RECOMMEND_DATA = "COURSES_RECOMMEND_DATA";
export const COURSES_RECOMMEND_DATA_SUCCESS = "COURSES_RECOMMEND_DATA_SUCCESS";
export const COURSES_RECOMMEND_DATA_FAIL = "COURSES_RECOMMEND_DATA_FAIL";

//get popular
export const COURSES_POPULAR_DATA = "COURSES_POPULAR_DATA";
export const COURSES_POPULAR_DATA_SUCCESS = "COURSES_POPULAR_DATA_SUCCESS";
export const COURSES_POPULAR_DATA_FAIL = "COURSES_POPULAR_DATA_FAIL";

//post enroll
export const COURSES_DATA_ENROLL = "COURSES_DATA_ENROLL";
export const COURSES_DATA_ENROLL_SUCCESS = "COURSES_DATA_ENROLL_SUCCESS";
export const COURSES_DATA_ENROLL_FAIL = "COURSES_DATA_ENROLL_FAIL";
//complete material
export const COURSES_COMPLETE_MATERIAL = "COURSES_COMPLETE_MATERIAL";
export const COURSES_COMPLETE_MATERIAL_SUCCESS =
  "COURSES_COMPLETE_MATERIAL_SUCCESS";
export const COURSES_COMPLETE_MATERIAL_FAIL = "COURSES_COMPLETE_MATERIAL_FAIL";
//complete course
export const COURSES_COMPLETE_COURSE = "COURSES_COMPLETE_COURSE";
export const COURSES_COMPLETE_COURSE_SUCCESS =
  "COURSES_COMPLETE_COURSE_SUCCESS";
export const COURSES_COMPLETE_COURSE_FAIL = "COURSES_COMPLETE_COURSE_FAIL";
//remove complete
export const COURSES_REMOVE_COMPLETE_STATE = "COURSES_REMOVE_COMPLETE_STATE";
//remove courseId
export const COURSES_REMOVE_COURSE_DATA_ID = "COURSES_REMOVE_COURSE_DATA_ID";
//remove course data
export const COURSES_REMOVE_COURSE_DATA = "COURSES_REMOVE_COURSE_DATA";
//remove course data enroll
export const COURSES_REMOVE_COURSE_DATA_ENROLL =
  "COURSES_REMOVE_COURSE_DATA_ENROLL";
