import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
// import person from "./../../assets/images/english/person.webp";
import reallyenglish from "./../../assets/images/english/reallyenglish.png";

export default function Heroshot() {
  return (
    <div className="hero_course">
      <Container>
        <Row>
          <Col lg={8}>
            <div className="heading_course">
              Mahir <span>Berbahasa Inggris Makin Mudah</span> bersama
              Satukelas!
            </div>
            <div className="description_course">
              Sekarang kamu bisa mengikuti Kursus dan Test Prediksi 
              <span>TOEFL, TOEIC</span> dan Persiapan <span>IELTS</span> di
              Satukelas dari mana saja dan kapan saja.
            </div>
            <div className="description_course mb-4">
              Sertifikasi hasil tes kemampuan berbahasa Inggris seperti{" "}
              <span>TOEFL</span>, <span>TOEIC</span>, atau <span>IELTS</span>{" "}
              penting untuk kamu miliki ketika kamu mau melanjutkan studi atau
              mencari pekerjaan.
            </div>
            <div className="description_course yellow">
              <span>Tunggu apalagi?</span> Persiapkan dirimu agar mahir
              berbahasa Inggris bersama Satukelas sekarang juga!
            </div>
            <div className="author_course">
              <div className="logo">
                <Image src={reallyenglish} />
              </div>
              <div className="text">
                Course materials are developed by <b>Reallyenglish.com</b> in
                partnership with
                <b> Pearson, British Council, Cambridge University Press</b>
                and other leading ELT experts.
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <Image className="hero_img" src="./../../assets/images/english/person.webp" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
