export const env = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : "development";

// connectycube
export const ccAppId = env === "development" ? 5433 : 5433;
export const ccAuthKey =
  env === "development" ? "XRTCwBTOC3HaKds" : "XRTCwBTOC3HaKds";
export const ccAuthSecret =
  env === "development" ? "CGdhdRJgfOCTzEF" : "CGdhdRJgfOCTzEF";
export const ccUID = env === "development" ? 4957569 : 4957569;
export const ccCredEmail =
  env === "development" ? "student@student.com" : "student@student.com";
export const ccCredPassword =
  env === "development" ? "student@student.com" : "student@student.com";
// export const mailchimpURL =
//   "https://satukelas.us18.list-manage.com/subscribe/post?u=160749e8dff8201621cd4fb1f&amp;id=610f38e4bc";
// onesignal
export const oneSignalID =
  env === "development"
    ? "20d2e7db-c434-44cf-85cc-2c54b1b98a32"
    : "fc98ce0e-bdea-41c3-8edd-056617812ceb";

// ga
export const gaTrackingID =
  env === "development" ? "UA-208623727-1" : "UA-211801051-1";
