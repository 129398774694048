import React, { useEffect } from "react";
import "./../../assets/css/StaticPages/dlc.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import client1 from "./../../assets/images/cdlc/client1.png";
import client2 from "./../../assets/images/cdlc/client2.png";
import client3 from "./../../assets/images/cdlc/client3.png";
import client4 from "./../../assets/images/cdlc/client4.png";
import client5 from "./../../assets/images/cdlc/client5.png";
import client6 from "./../../assets/images/cdlc/client6.png";
import illustration1 from "./../../assets/images/cdlc/illustration1.png";
import illustration2 from "./../../assets/images/cdlc/illustration2.png";
import illustration3 from "./../../assets/images/cdlc/illustration3.png";
import seeCatalogue from "./../../assets/images/cdlc/see-catalogue.png";
import cdlcDesc1 from "./../../assets/images/cdlc/cdlc_desc1.png";
import cdlcDesc2 from "./../../assets/images/cdlc/cdlc_desc2.png";
import cdlcDesc3 from "./../../assets/images/cdlc/cdlc_desc3.png";
import slide1 from "./../../assets/images/cdlc/slide1.png";
import slide2 from "./../../assets/images/cdlc/slide2.png";
import slide3 from "./../../assets/images/cdlc/slide3.png";
// import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Button, Col, Container, Row } from "react-bootstrap";
// import { mailchimpURL } from "../../helper/credentials";
import NewsletterForm from "./NewsLetterForm";
import { useHistory } from "react-router-dom";

export default function DigitalLearningCenter() {
  const router = useHistory();
  /**
   * @function for reusable section "get benefit"
   * @param {*} src
   * @param {*} title
   * @param {*} description
   * @returns
   */
  const getBenefit = (src, title, description) => {
    return (
      <div className="benefit-box__container">
        <div className="benefit-circle__illustration">
          <img alt="benefit" className="lazyload" data-src={src} />
        </div>
        <div className="benefit-title">{title}</div>
        <div className="benefit-description">{description}</div>
      </div>
    );
  };
  /**
   * @function for reusable section "how to"
   * @param {*} title
   * @param {*} description
   * @returns
   */
  const getHowTo = (title, description) => {
    return (
      <div>
        <div>
          <div className="how-title">{title}</div>
        </div>
        <div className="how-description">{description}</div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", () => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");

          if (entry.intersectionRatio > 0) {
            document
              .querySelector(`nav a[href="#${id}"] div`)
              .parentElement.childNodes[0].classList.add("big-circle");
          } else {
            document
              .querySelector(`nav a[href="#${id}"] div`)
              .parentElement.childNodes[0].classList.remove("big-circle");
          }
        });
      });

      // Track all sections that have an `id` applied
      document.querySelectorAll("section[id]").forEach((section) => {
        observer.observe(section);
      });
    });
  }, []);
  return (
    <div className="first">
      <div className="hero-shot__container-cdlc">
        <div className="hero-shot__container-cdlc__illustration">
          <Container>
            <Row className="align-items-center py-5">
              <Col md={12} lg={6}>
                <div className="hero-shot__title">
                  Tingkatkan Corporate Growth{" "}
                  <span>Dengan Corporate Digital Learning Center (CDLC)</span>
                </div>
                <div className="hero-shot__subtitle">
                  Learning Management System yang Terpersonalisasi Menggunakan
                  Domain Perusahaan Anda!
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                {/* <MailchimpSubscribe
                  url={mailchimpURL}
                  render={(props) => {
                    const { subscribe, status, message } = props || {};
                    return (
                      <NewsletterForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    );
                  }}
                /> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <Row className="client-container">
          <Col md={12} lg={6}>
            <div className="mb-5 client-list">
              <div>
                <img alt="client" className="lazyload" data-src={client1} />
                <img alt="client" className="lazyload" data-src={client2} />
                <img alt="client" className="lazyload" data-src={client3} />
              </div>
              <div>
                <img alt="client" className="lazyload" data-src={client4} />
                <img alt="client" className="lazyload" data-src={client5} />
                <img alt="client" className="lazyload" data-src={client6} />
              </div>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="client-title">
              400+ Perusahaan Telah Percaya dengan satukelas
            </div>
            <div className="client-description">
              Gabung bersama 400 Perusahaan lainnya yang telah mempercayai
              satukelas untuk meningkatkan keterampilan pegawainya!
            </div>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col md={12}>
          <div className="cdlc-title">
            Miliki Learning Center untuk Perusahaan dengan CDLC
          </div>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col md={12} lg={4}>
            {getBenefit(
              illustration1,
              "Server dan Domain yang Fleksibel",
              "Perusahaan dapat mengubah server dan domain sesuai dengan nama perusahaan secara fleksibel."
            )}
          </Col>
          <Col md={12} lg={4}>
            {getBenefit(
              illustration2,
              "Atur Tampilan Website Sesuai Kebutuhan",
              "Tampilan website dapat disesuaikan dengan identitas perusahaan sehingga terlihat lebih menarik."
            )}
          </Col>
          <Col md={12} lg={4}>
            {getBenefit(
              illustration3,
              "Konten Belajar Lengkap dari Berbagai Disiplin Ilmu",
              "Belajar tanpa perlu bingung. Pilih konten sesuai kebutuhan karyawan untuk tingkatkan kompetensi."
            )}
          </Col>
        </Row>
      </Container>
      <div className="see-catalog__container">
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <div className="see-catalog__title">
                Ratusan Konten Belajar dari Berbagai Bidang Keilmuan
              </div>
              <div className="see-catalog__description">
                Satukelas memiliki ratusan konten pelatihan yang nyaman dan
                mudah digunakan dalam CDLC. Sehingga perusahaan dapat
                meningkatkan kinerja dan performa setiap karwayan yang sesuai
                dengan minat dan kariernya masing-masing.
              </div>
              <a href="/search/pelatihan/">
                <Button className="see-catalog__btn">Lihat Katalog</Button>
              </a>
            </Col>
            <Col md={12} lg={6}>
              <img
                alt="catalogue"
                className="lazyload"
                data-src={seeCatalogue}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="cdlc-slide__container">
        <div className="cdlc-slide__wrapper">
          <nav className="cdlc-slide__sticky">
            <a id="first-slide" href="#slide-1">
              <div
                className={`cdlc-cirlce__shape ${
                  router.location?.hash === "#slide-1" && "big-circle"
                }`}
              ></div>
            </a>
            <a id="second-slide" href="#slide-2">
              <div
                className={`cdlc-cirlce__shape ${
                  router.location?.hash === "#slide-2" && "big-circle"
                }`}
              ></div>
            </a>
            <a id="third-slide" href="#slide-3">
              <div
                className={`cdlc-cirlce__shape ${
                  router.location?.hash === "#slide-3" && "big-circle"
                }`}
              ></div>
            </a>
          </nav>
        </div>

        <div className="cdlc-desc__container">
          <Container>
            <Row>
              <Col
                md={12}
                lg={5}
                className="mb-4 positive-relative img-container"
              >
                <section id="slide-1">
                  <div style={{ position: "inherit", zIndex: 1 }}>
                    <img
                      alt="desc"
                      className="w-100 lazyload"
                      data-src={cdlcDesc1}
                    />
                  </div>
                  <div
                    style={{ bottom: -8, left: -16, zIndex: -2 }}
                    className="position-absolute"
                  >
                    <img alt="desc" className="lazyload" data-src={slide1} />
                  </div>
                </section>
              </Col>
              <Col
                md={12}
                lg={6}
                className="offset-lg-1 d-flex flex-column align-self-center mb-0 mb-lg-5"
              >
                <div className="cdlc-desc__title">
                  Personalisasi tampilan LMS sesuai Identitas Perusahaan
                </div>
                <div className="cdlc-desc__subtitle">
                  CDLC menyediakan CMS yang dapat mengatur tampilan LMS sesuai
                  dengan kebutuhan dan identitas perusahaan.
                  <span>
                    <ul>
                      <li>Ganti Logo Perusahaan</li>
                      <li>Ganti Warna Tampilan</li>
                      <li>Pasang Penguman di halaman Beranda</li>
                    </ul>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="cdlc-desc__container">
          <Container>
            <Row>
              <Col
                md={12}
                lg={7}
                className="order-2 order-lg-1 d-flex flex-column align-self-center"
              >
                <div className="cdlc-desc__title">
                  Pantau dan Atur Pelatihan Sesuai Kebutuhan
                </div>
                <div className="cdlc-desc__subtitle">
                  CDLC memiliki fitur LMS yang memudahkan perusahaan untuk
                  mengatur pelatihan yang dibutuhkan langsung kepada karyawan
                  yang dituju.
                  <span>
                    <ul>
                      <li>Membuat Course Sesuai Kebutuhan</li>
                      <li>Membuat Grup Belajar (Per divisi/tim Khusus)</li>
                      <li>Export Laporan Analitik Kegiatan Belajar User</li>
                    </ul>
                  </span>
                </div>
              </Col>
              <Col
                md={12}
                lg={5}
                className="order-md-1 order-lg-2 position-relative"
              >
                <div style={{ position: "inherit", zIndex: 1 }}>
                  <img
                    alt="desc"
                    className="w-100 mb-3 lazyload"
                    data-src={cdlcDesc2}
                  />
                </div>
                <div
                  style={{ top: -16, left: -12 }}
                  className="position-absolute"
                >
                  <img alt="desc" className="lazyload" data-src={slide2} />
                </div>
                <section id="slide-2"></section>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="cdlc-desc__container" id="slide-3">
          <Container>
            <Row>
              <Col md={12} lg={6} className="position-relative mb-3 mb-lg-4">
                <div style={{ position: "inherit", zIndex: 1 }}>
                  <img
                    alt="desc"
                    className="lazyload w-100"
                    data-src={cdlcDesc3}
                  />
                </div>
                <div
                  style={{ bottom: "18px", left: -20, zIndex: -1 }}
                  className="position-absolute"
                >
                  <img alt="desc" className="lazyload" data-src={slide3} />
                </div>
                <section id="slide-3"></section>
              </Col>
              <Col
                md={12}
                lg={5}
                className="offset-lg-1 d-flex flex-column align-self-center"
              >
                <div className="cdlc-desc__title">
                  Jalankan Pelatihan Melalui Satu Dashboard
                </div>
                <div className="cdlc-desc__subtitle">
                  Fitur User Dashboard memudahkan karyawan untuk memantau dan
                  menjadwalkan pelatihan maupun webinar dalam tampilan yang
                  mudah digunakan.
                  <span>
                    <ul>
                      <li>Melihat Progress Pelatihan</li>
                      <li>Melakukan Pembelajaran (Course, Webinar)</li>
                      <li>Melakukan Sertifikasi</li>
                    </ul>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="cdlc-step__title">
              Cara satukelas Mempersiapkan Pelatihan Terbaik
            </div>
          </Col>
          <Col md={12} className="mb-4">
            <div className="d-sm-none d-md-flex cdlc-step__wrapper">
              {[...Array(3)].map((item, index) => {
                return (
                  <div className="cdlc-step__circle" key={index}>
                    {index + 1}
                  </div>
                );
              })}
              <div className="cdlc-step__line"></div>
            </div>
          </Col>
          <Col md={4}>
            {getHowTo(
              "Traning Need Analysis",
              "Setiap pelatihan dirancang khusus dengan menganalisa kebutuhan kompetensi industri. satukelas juga menyediakan pembelajaran dengan materi yang mendalam."
            )}
          </Col>
          <Col md={4}>
            {getHowTo(
              "Learning Materials Production",
              "Setiap materi yang sudah dirancang akan melewati tahapan produksi yang dilakukan oleh tim satukelas bersama para mentor yang ahli dalam bidangnya."
            )}
          </Col>
          <Col md={4}>
            {getHowTo(
              "Quality Control and Release",
              "Pelatihan yang telah diproduksi akan melewati proses Quality Control untuk memastikan bahwa setiap materi yang akan disediakan memenuhi spesifikasi standar kualifikasi yang telah ditetapkan."
            )}
          </Col>
        </Row>
      </Container>
      <div className="footer-dlc">
        <Container>
          <Row className="align-items-center py-5">
            <Col md={12} lg={6}>
              <div className="footer-title">
                Maksimalkan Perkembangan Karyawan Anda Sekarang!
              </div>
              <div className="footer-subtitle">
                Cari Tahu Lebih Lanjut dengan Konstultasi Langsung.
              </div>
            </Col>
            <Col md={12} lg={5} className="offset-lg-1">
              {/* <MailchimpSubscribe
                url={mailchimpURL}
                render={(props) => {
                  const { subscribe, status, message } = props || {};
                  return (
                    <NewsletterForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  );
                }}
              /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
