import {
  CERTIFICATES_DATA,
  CERTIFICATES_DATA_ID_SUCCESS,
  CERTIFICATES_DATA_FAIL,
} from "../config/CertificatesConfig";

export const getCertificatesData = (value) => ({
  type: CERTIFICATES_DATA,
  value,
});
export const getCertificatesDataIdSuccess = (value) => ({
  type: CERTIFICATES_DATA_ID_SUCCESS,
  value,
});
export const getCertificatesDataFail = (value) => ({
  type: CERTIFICATES_DATA_FAIL,
  value,
});
