import { satukelasApi } from "../constants";

export const courseService = {
  getCourse(param) {
    return param && satukelasApi.get(`/courses${param}`);
  },
  getDefaultPopular() {
    return satukelasApi.get(`/homepage`);
  },
};
