import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Tabs,
  Tab,
  Form,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import {
  getUsersDataThunk,
  getUsersDataThunkV2,
  putUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
import { postPaymentsVerifyDataThunk } from "../../redux/thunks/PaymentsThunks";
import { removePostPaymentsData } from "../../redux/actions/PaymentsActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { formatRupiah } from "../../helper/functions";
import { paymentsMethod } from "../../helper/payments";
import Topup from "../../components/Topup";
import ModalPembayaran from "../../components/ModalPembayaran";
import "../../assets/css/Personal/Profile.css";
import { defaulThumbnail } from "../../helper/constant";
import { UserService } from "../../http/UserHttp";

const MySwal = withReactContent(Swal);

function Profile() {
  const [topup, setTopup] = useState(false);
  const [modalContent, setModalContent] = useState({
    show: false,
    componentBody: "",
  });
  const defaultData = {
    name: "",
    position: "",
    phone: "",
    birthday: "",
    address_street: "",
  };
  const defaultSocmed = {
    twitter: "",
    facebook: "",
    instagram: "",
    linkedin: "",
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const payments = useSelector((state) => state.payments);
  const [usersDataId, setUsersDataId] = useState();
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState(defaultData);
  const [socmed, setSocmed] = useState(defaultSocmed);
  const [userMembership, setUserMembership] = useState();

  useEffect(() => {
    AuthIsLogin(history);
    if (users.usersDataId.length === 0) {
      dispatch(getUsersDataThunkV2(AuthData().userId));
    }
  }, []);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      show: false,
    });
    if (payments.paymentsVerifyDataPost) {
      if (payments.paymentsVerifyDataPost === "fail" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi kesalahan, coba lagi", true, "Oke");
        dispatch(removePostPaymentsData());
      } else if (trigger) {
        setTrigger(false);
        dispatch(removePostPaymentsData());
        displayPopup(
          false,
          `Status Transaksi Anda : ${payments.paymentsVerifyDataPost?.transaction_status}`,
          true,
          "Oke"
        );
      }
    }
  }, [payments.paymentsVerifyDataPost]);

  useEffect(() => {
    if (payments.paymentsDataPost?.order_id) {
      setModalContent({
        ...modalContent,
        show: true,
        body: payments.paymentsDataPost,
      });
    }
    if (payments.paymentsDataPost === "fail") {
      displayPopup(
        false,
        `Gagal Topup. Silahkan coba lagi setelah beberapa saat!`,
        true,
        "Oke"
      );
    }
  }, [payments.paymentsDataPost]);

  useEffect(() => {
    if (data.birthday)
      document.getElementById("birthday").value = moment(data.birthday).format(
        "YYYY-MM-DD"
      );
  }, [data.birthday]);

  useEffect(() => {
    getMembershipUser();
  }, []);

  useEffect(() => {
    setData(setDefaultData());
    setSocmed(setDefaultSocmed());
    setUsersDataId(users.usersDataId);
  }, [users]);

  const getMembershipUser = async () => {
    try {
      const response = await UserService.getMembership();
      setUserMembership(response.memberships);
    } catch (error) {}
  };

  function setDefaultData() {
    const newData = { ...defaultData };
    const userData = users.usersDataId;
    if (userData.name) newData.name = userData.name;
    if (userData.phone) newData.phone = userData.phone;
    if (userData.company_id) {
      if (userData.company_name) newData.company_name = userData.company_name;
    } else {
      if (userData.company) newData.company = userData.company;
    }
    if (userData.position) newData.position = userData.position;

    if (userData.birthday) newData.birthday = userData.birthday;
    if (userData.address_street)
      newData.address_street = userData.address_street;
    return newData;
  }

  function setDefaultSocmed() {
    const newData = { ...defaultSocmed };
    const userData = users.usersDataId;
    if (userData.social_media) {
      newData.instagram = userData.social_media[0];
      newData.linkedin = userData.social_media[1];
      newData.facebook = userData.social_media[2];
      newData.twitter = userData.social_media[3];
    }
    return newData;
  }

  useEffect(() => {
    if (users.usersDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat edit data", false);
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil mengubah data", true, "Ok");
    }
  }, [users]);

  const onChangeHandler = (e) => {
    const newData = { ...data };
    if (e.target.id === "price")
      newData[e.target.id] = parseInt(e.target.value);
    else if (e.target.id === "birthday")
      newData[e.target.id] = moment(e.target.value).format("YYYY-MM-DD");
    else newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const onChangeHandlerSM = (e) => {
    const newData = { ...socmed };
    let noSpace = e.target.value.replace(/\s/g, "");
    newData[e.target.id] = noSpace;
    setSocmed(newData);
  };

  const handleVerify = (value) => {
    setTrigger(true);
    const dataSend = {
      order_id: value,
    };
    dispatch(postPaymentsVerifyDataThunk(dataSend));
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersDataThunk(AuthData().userId));
        dispatch(removePostPaymentsData());
      }
    });
  }

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setTrigger(true);
    let req = {};
    req.body = data;
    req.id = usersDataId.user_id;
    dispatch(putUsersDataThunk(req));
  };

  const handleSubmitFormSM = (e) => {
    e.preventDefault();
    setTrigger(true);
    let req = {};
    let body = [];
    body.push(socmed.instagram);
    body.push(socmed.linkedin);
    body.push(socmed.facebook);
    body.push(socmed.twitter);
    req.body = { social_media: body };
    req.id = usersDataId.user_id;
    dispatch(putUsersDataThunk(req));
  };

  const handleTopup = () => {
    setTopup(!topup);
  };

  const usersSocialMedia = (data, link, icon) => {
    return (
      <div className="d-flex align-items-center">
        <div className="satukelas_flex mr-0 mb-2">
          <a
            className={`profile_icon__wrapper ${icon}`}
            target="_blank"
            href={data && data.includes(link) ? data : `${link}${data}`}
            style={{ textDecoration: "none", color: "#fff" }}
          ></a>
        </div>
      </div>
    );
  };

  const inputData = (title, data, id, placeholder) => {
    return (
      <Col md={6} className="mb-0">
        <div className="profile_label_input">{title}</div>
        <input
          className="satukelas_input"
          id={id}
          value={data}
          onChange={onChangeHandlerSM}
          placeholder={`${placeholder} (tanpa spasi)`}
        ></input>
      </Col>
    );
  };

  function handleCopy() {
    let copyText = document.getElementById("va-number");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    alert(copyText.value);
  }

  return (
    <Container className="dashboard_page__container">
      {usersDataId ? (
        <>
          <h2 className="h2 dashboard__section_title">Profile Saya</h2>
          <Row>
            <Col sm={12} md={4}>
              <div style={{ position: "sticky", top: 0 }}>
                <div className="profile__top_wrapper">
                  <div className="profile__name_wrapper">
                    <div className="profile__name_container">
                      <div className="profile__avatar mr-3">
                        {usersDataId?.avatar === "" ? (
                          <div className="container-user">
                            <span className="icon-user"></span>
                          </div>
                        ) : (
                          <img
                            alt="user"
                            width="40"
                            data-src={`${usersDataId?.avatar}`}
                            className="w40 rounded-circle mt--1 lazyload"
                          />
                        )}
                      </div>
                      <div className="profil_info">
                        <div className="profile__name">{usersDataId.name}</div>
                        <div className="profile__join_date">
                          Bergabung{" "}
                          {moment(usersDataId.created_at).format("MMMM DD")}
                        </div>
                        <div className="profile_saldo__information">
                          <div className="balance_title mr-1 ">
                            Saldo : {formatRupiah(usersDataId?.balance)}
                          </div>{" "}
                          <Button
                            className="mr-0 button_topup__profile"
                            onClick={handleTopup}
                          >
                            Topup
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile__kontak_wrapper">
                    <div className="profile__kontak">Kontak</div>
                    <div className="d-flex align-items-center mb-3">
                      <div className="profile_email">{usersDataId.email}</div>
                    </div>
                    <div className="profile__kontak">Sosial Media</div>
                    <div className="social_media__container">
                      {usersSocialMedia(
                        socmed?.instagram,
                        "https://www.instagram.com/",
                        "icon-instagram"
                      )}
                      {usersSocialMedia(
                        socmed?.linkedin,
                        "https://linkedin.com/",
                        "icon-linkedid"
                      )}
                      {usersSocialMedia(
                        socmed?.facebook,
                        "https://facebook.com/",
                        "icon-facebook"
                      )}
                      {usersSocialMedia(
                        socmed?.twitter,
                        "https://www.twitter.com/",
                        "icon-twitter"
                      )}
                    </div>
                  </div>
                </div>

                <h2 className="h2 profile_label_input">Pelatihan Saya</h2>
                <div className="profile__my_course__wrapper">
                  <div>
                    {usersDataId?.my_courses?.length > 0
                      ? usersDataId.my_courses
                          .slice(0, 3)
                          .map((data, index) => {
                            return (
                              <>
                                <div className="Grey-Pajak" key={index}>
                                  {data?.thumbnail === "" ? (
                                    <img
                                      width={40}
                                      height={40}
                                      src={defaulThumbnail}
                                    />
                                  ) : (
                                    <img
                                      width={40}
                                      height={40}
                                      src={data?.thumbnail}
                                    />
                                  )}
                                  <div className="ml-2 Pajak">
                                    {data.course}
                                  </div>
                                </div>
                              </>
                            );
                          })
                      : "Data tidak ditemukan"}
                  </div>
                  {usersDataId?.my_courses?.length > 0 ? (
                    <Link
                      className="py-3 btn btn-primary w-100 percentage__button d-flex justify-content-center"
                      to="/dashboard/course"
                    >
                      Lihat selengkapnya
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>

            <Col sm={12} md={8} id="edit">
              <Tabs
                defaultActiveKey="info"
                transition={false}
                id="noanim-tab-example"
                className=""
              >
                <Tab
                  tabClassName="text-blacks"
                  eventKey="info"
                  title="Informasi Pribadi"
                >
                  <Form className="biodata_wrapper" onSubmit={handleSubmitForm}>
                    <Row>
                      <Col sm={12} md={12} className="mb-0">
                        <div className="profile_label_input">Nama Lengkap</div>
                        <input
                          className="satukelas_input"
                          id="name"
                          value={data.name}
                          onChange={onChangeHandler}
                          required
                        ></input>
                        <div className="messages"></div>
                      </Col>
                      <Col sm={12} md={6} className="mb-0">
                        <div className="profile_label_input">Nama Kantor</div>
                        <input
                          className="satukelas_input"
                          id={data.company_id ? "company_name" : "company"}
                          value={
                            data.company_id ? data.company_name : data.company
                          }
                          onChange={onChangeHandler}
                          disabled={data.company_id}
                          required
                        ></input>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="profile_label_input">Jabatan</div>
                        <input
                          className="satukelas_input"
                          id="position"
                          value={data.position}
                          onChange={onChangeHandler}
                          required
                        ></input>
                      </Col>
                      <Col sm={12} md={6} className="mb-0">
                        <div className="profile_label_input">No. HP</div>
                        <input
                          className="satukelas_input"
                          id="phone"
                          value={data.phone}
                          onChange={onChangeHandler}
                          required
                        ></input>
                      </Col>
                      <Col sm={12} md={6} className="mb-0">
                        <div className="profile_label_input">Tanggal Lahir</div>
                        <input
                          className="satukelas_input"
                          type="date"
                          id="birthday"
                          onChange={onChangeHandler}
                          style={{ padding: "14px 16px " }}
                          required
                        />
                      </Col>
                      <Col md={12} className="mb-0">
                        <div className="profile_label_input">Alamat</div>
                        <textarea
                          className="satukelas_input"
                          id="address_street"
                          rows="3"
                          value={data.address_street}
                          onChange={onChangeHandler}
                          required
                        />
                      </Col>
                      <Col sm={12}>
                        <div className="profile_biodata_btn__wrapper d-flex">
                          <Button
                            variant="danger"
                            className="profile_cancel__btn align-items-center d-flex mr-3 py-2"
                            onClick={() => setData(setDefaultData())}
                          >
                            Batal
                          </Button>
                          <Button
                            className="profile_biodata_btn profile_edit__btn primary py-2"
                            type="submit"
                          >
                            Ubah
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Tab>

                <Tab eventKey="sosialmedia" title="Sosial Media">
                  <Form
                    onSubmit={handleSubmitFormSM}
                    className="biodata_wrapper"
                  >
                    <Row className="mt-4">
                      {inputData(
                        "Instagram",
                        socmed?.instagram,
                        "instagram",
                        "akuninstagramkamu"
                      )}
                      {inputData(
                        "Facebook",
                        socmed?.facebook,
                        "facebook",
                        "akunfacebookkamu"
                      )}
                      {inputData(
                        "Twitter",
                        socmed?.twitter,
                        "twitter",
                        "akuntwitterkamu"
                      )}
                      {inputData(
                        "LinkedIn",
                        socmed?.linkedin,
                        "linkedin",
                        "akunlinkedinkamu"
                      )}
                      <Col sm={12}>
                        <div className="profile_biodata_btn__wrapper d-flex">
                          <Button
                            variant="danger"
                            className="profile_cancel__btn align-items-center d-flex mr-3 py-2"
                            onClick={() => setSocmed(setDefaultSocmed())}
                          >
                            Batal
                          </Button>
                          <Button
                            className="profile_biodata_btn profile_edit__btn primary py-2"
                            type="submit"
                          >
                            Simpan
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Tab>
              </Tabs>
              <div className="profile_membership__wrapper">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h2 className="h2 biodata_title">Membership</h2>
                  </div>
                  {/* <div className="see_membership">
                    <Link to="/dashboard/membership">Semua Membership</Link>
                  </div> */}
                </div>
                {userMembership?.length > 0 ? (
                  <>
                    {userMembership?.map((item, idx) => {
                      return (
                        <div className="membership_card__wrapper mb-3">
                          <div>
                            <div className="membership_card__type">
                              <div className="membership_text">
                                {item?.membership_title
                                  ? item.membership_title
                                  : "-"}
                              </div>
                              <div className="membership_include">
                                <b>Dapat mengakses LP : </b>
                                {item.learning_paths?.map((value, index) => {
                                  return (
                                    <>
                                      {`${value.learning_path}${
                                        index + 1 ===
                                        item.learning_paths?.length
                                          ? ""
                                          : ", "
                                      }`}
                                    </>
                                  );
                                })}
                              </div>
                              <div className="expired_at__text">
                                Expire :{" "}
                                {moment(item?.expired_at).format(
                                  "YYYY-MM-DD HH:MM:ss"
                                )}
                              </div>
                            </div>
                            <div className="membership_card__price"></div>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  "Data tidak ditemukan"
                )}
              </div>
            </Col>
          </Row>
        </>
      ) : null}
      <Modal show={topup} onClose={() => setTopup(false)}>
        <Topup setTopup={setTopup} />
      </Modal>
      <ModalPembayaran
        id="tp"
        content={modalContent}
        handleVerify={handleVerify}
        paymentsMethod={paymentsMethod}
        handleCopy={handleCopy}
      />
    </Container>
  );
}

export default Profile;
