import {
  //get
  USERS_DATA,
  USERS_DATA_ID_SUCCESS,
  USERS_DATA_SUCCESS,
  USERS_DATA_FAIL,
  //post
  USERS_DATA_POST,
  USERS_DATA_POST_SUCCESS,
  USERS_DATA_POST_FAIL,
  //put
  USERS_DATA_PUT,
  USERS_DATA_PUT_SUCCESS,
  USERS_DATA_PUT_FAIL,
  //get instructor
  USERS_GET_INSTRUCTOR_DATA,
  USERS_GET_INSTRUCTOR_DATA_SUCCESS,
  USERS_GET_INSTRUCTOR_DATA_FAIL,
  //verify token
  USERS_VERIFY_TOKEN,
  USERS_VERIFY_TOKEN_SUCCESS,
  USERS_VERIFY_TOKEN_FAIL,
  //resend verification
  USERS_RESEND_VERIFICATION,
  USERS_RESEND_VERIFICATION_SUCCESS,
  USERS_RESEND_VERIFICATION_FAIL,
  //remove
  USERS_DATA_ID_REMOVE,
  // change password
  USERS_CHANGE_PASSWORD,
  USERS_CHANGE_PASSWORD_SUCCESS,
  USERS_CHANGE_PASSWORD_FAIL,
  // reset password
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  //verify password
  VERIFY_PASSWORD_TOKEN,
  VERIFY_PASSWORD_TOKEN_SUCCESS,
  VERIFY_PASSWORD_TOKEN_FAIL,
  //new password
  NEW_PASSWORD_ACCOUNT,
  NEW_PASSWORD_ACCOUNT_SUCCESS,
  NEW_PASSWORD_ACCOUNT_FAIL,
} from "../config/UsersConfig";

const intialState = {
  usersDataId: [],
  usersData: [],
  usersInstructorData: [],
  usersDataPost: "",
  usersDataPut: "",
  usersVerify: "",
  usersResendVerification: "",
  usersChangePassword: null,
  usersForgotPassword: null,
  verifyPasswordToken: null,
  newPasswordAccount: null,
  isLoading: false,
};

export const UsersReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case USERS_DATA:
      return {
        ...state,
        isLoading: true,
        usersData: [],
        usersDataPost: "",
        usersDataPut: "",
      };
    case USERS_DATA_SUCCESS:
      return { ...state, isLoading: false, usersData: action.value };
    case USERS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, usersDataId: action.value };
    case USERS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        usersData: "error",
        usersDataId: "error",
      };

    //post
    case USERS_DATA_POST:
      return { ...state, isLoading: true };
    case USERS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, usersDataPost: "success" };
    case USERS_DATA_POST_FAIL:
      return {
        ...state,
        isLoading: false,
        usersDataPost: { status: "error", response: action.value },
      };

    //put
    case USERS_DATA_PUT:
      return { ...state, isLoading: true };
    case USERS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, usersDataPut: "success" };
    case USERS_DATA_PUT_FAIL:
      return {
        ...state,
        isLoading: false,
        usersDataPut: "error",
      };
    // case REMOVE_USERS_DATA_PUT:

    //get instructor data
    case USERS_GET_INSTRUCTOR_DATA:
      return { ...state, isLoading: true };
    case USERS_GET_INSTRUCTOR_DATA_SUCCESS:
      return { ...state, isLoading: false, usersInstructorData: action.value };
    case USERS_GET_INSTRUCTOR_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        usersInstructorData: "error",
      };

    //verify token
    case USERS_VERIFY_TOKEN:
      return { ...state, isLoading: true, usersVerify: "" };
    case USERS_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersVerify: { status: "success", response: action.value },
      };
    case USERS_VERIFY_TOKEN_FAIL:
      return {
        ...state,
        isLoading: false,
        usersVerify: { status: "error", response: action.value },
      };

    //verify token
    case USERS_RESEND_VERIFICATION:
      return { ...state, isLoading: true, usersResendVerification: "" };
    case USERS_RESEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersResendVerification: { status: "success", response: action.value },
      };
    case USERS_RESEND_VERIFICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        usersResendVerification: { status: "error", response: action.value },
      };

    //remove user data
    case USERS_DATA_ID_REMOVE:
      return {
        ...state,
        usersDataId: [],
        usersVerify: "",
        usersResendVerification: "",
      };

    // change password
    case USERS_CHANGE_PASSWORD:
      return { ...state, isLoading: true, usersChangePassword: null };
    case USERS_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersChangePassword: { status: "success", response: action.value },
      };
    case USERS_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        usersChangePassword: { status: "error", response: action.value },
      };
    // forgot password
    case FORGOT_PASSWORD:
      return { ...state, isLoading: true, usersForgotPassword: null };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersForgotPassword: { status: "success", response: action.value },
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        usersForgotPassword: { status: "error", response: action.value },
      };

    // verify reset
    case VERIFY_PASSWORD_TOKEN:
      return { ...state, isLoading: true, verifyPasswordToken: null };
    case VERIFY_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyPasswordToken: { status: "success", response: action.value },
      };
    case VERIFY_PASSWORD_TOKEN_FAIL:
      return {
        ...state,
        isLoading: false,
        verifyPasswordToken: { status: "error", response: action.value },
      };

    // new password
    case NEW_PASSWORD_ACCOUNT:
      return { ...state, isLoading: true, newPasswordAccount: null };
    case NEW_PASSWORD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newPasswordAccount: { status: "success", response: action.value },
      };
    case NEW_PASSWORD_ACCOUNT_FAIL:
      return {
        ...state,
        isLoading: false,
        newPasswordAccount: { status: "error", response: action.value },
      };
    default:
      return state;
  }
};
