import React from "react";
import visiImage from "../../assets/images/atpi/visi-misi/visi-image.png";
import misiImage from "../../assets/images/atpi/visi-misi/misi-image.png";
import bgCircleTik from "../../assets/images/atpi/visi-misi/circle-titik.png";
import bgCircleShape from "../../assets/images/atpi/visi-misi/bg-misi.png";
import { Image } from "react-bootstrap";

const VisiMisi = (props) => {
  const { typePage } = props;
  return (
    <div>
      <h1 className="wording__visi-misi">{typePage === "atpi" ? "Visi dan Misi ATPI" : "Visi Misi APINDO"}</h1>
      <div>
        <div className="d-block d-xl-flex align-items-center mb-5 position-relative">
          <div className="col-12 col-xl-7 d-flex justify-content-center">
            <div>
              <h6 className="title__visi">Visi</h6>
              {typePage === "atpi" ? (
                <p className="desc__visi">
                  Menjadi wadah pemersatu profesi Teknisi Perpajakan yang professional, mumpuni dan mempunyai komitmen kuat untuk menjadi Teknisi Perpajakan kelas nasional dan global untuk kemajuan Bangsa dan Negara Kesatuan Republik
                  Indonesia.
                </p>
              ) : (
                <p className="desc__visi">
                  Sebagai Lembaga Sertfikasi dan Konsultasi SDM Apindo, Perusahan mempunyai visi yang selaras dengan kebijakan Perusahaan, Organisasi, dan Pemerintah dalam bidang sertifikasi dan pelatihan Sumberdaya Manusia, Visi Perusahaan
                  : “ Menjadi LSP Manajemen Sumber Daya Manusia yang Profesional dan unggul secara Nasional maupun Global “
                </p>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-5 d-flex justify-content-center">
            <Image src={visiImage} width="95%" height="95%" />
          </div>
          <div className="bg__visi d-none d-md-block">
            <Image src={bgCircleTik} width="100%" height="100%" />
          </div>
        </div>
        <div className="d-block d-xl-flex align-items-center position-relative">
          <div className="col-12 col-lg-5 mb-3 mb-xl-0 d-flex justify-content-center justify-content-lg-end">
            <Image src={misiImage} width="95%" height="95%" />
          </div>
          <div className="col-12 col-xl-7 d-flex justify-content-center">
            <div>
              <h6 className="title__misi">Misi</h6>
              {typePage === "atpi" ? (
                <ol>
                  <li>Menjadikan ATPI sebagai asosiasi Teknisi Perpajakan Indonesia yang mandiri, otonom , professional dan berpegang teguh pada etika profesi;</li>
                  <li>Mendorong ATPI sebagai asosiasi Teknisi Perpajakan Indonesia yang terus menerus melakukan perbaikan;</li>
                  <li>Membina persatuan dan kesatuan Teknisi perpajakan di Indonesia dan dunia internasional;</li>
                  <li>Menjadi mitra strategis pemerintah dalam meningkatkan penerimaan negara di bidang perpajakan dengan mendorong masyarakat sadar pajak.</li>
                </ol>
              ) : (
                <ol>
                  <li>Memberikan kepercayaan dan pengakuan kompetensi Personal dibidang Manajemen Sumberdaya Manusia yang Terpercaya, Kompeten, Profesional, dan Terdepan.</li>
                  <li>Memberikan layanan Sertifikasi Profesi dengan mengedepankan Kualitas, Profesionalitas, dan Akuntabilitas kepada klien dan mitra bisnis Perusahaan.</li>
                  <li>Meningkatkan Sumberdaya Manusia Asesor Kompetensi tersetifikasi BNSP yang kredibel, akuntabel dan mempunyai kompetensi teknis dibidang Manajemen Sumberdaya Manusia.</li>
                  <li>Meningkatan Kemampuan Keterampilan, Pengetahuan dan Pengembangan Staff LSP MSDM APINDO JAYA sesuai bidangnya secara fungsional pada tugas dan tanggung jawabnya.</li>
                </ol>
              )}
            </div>
          </div>
          {typePage === "atpi" && (
            <div className="bg__misi d-none d-md-block">
              <Image src={bgCircleShape} width="80%" height="80%" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VisiMisi;
