import {
  LESSONS_DATA,
  LESSONS_DATA_ID_SUCCESS,
  LESSONS_DATA_FAIL,
} from "../config/LessonsConfig";

export const getLessonsData = (value) => ({
  type: LESSONS_DATA,
  value,
});
export const getLessonsDataIdSuccess = (value) => ({
  type: LESSONS_DATA_ID_SUCCESS,
  value,
});
export const getLessonsDataFail = (value) => ({
  type: LESSONS_DATA_FAIL,
  value,
});
