import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../components/EnglishCourse/EnglishCourseCard.css";
import { convertHours, formatRupiah, slugify } from "../../helper/functions";
import book from "./../../assets/images/book-open.png";
import clock from "./../../assets/images/clock.png";
import instructor from "./../../assets/images/english/instructor.png";
import thumbnail from "./../../assets/images/thumbnail-chro.png";
export default function CardChro(props) {
  const { type, item } = props;

  return (
    <Link
      id="english"
      to={`/brevet/${slugify(item?.title)}/${item?.course_id}`}
    >
      <div className="english-card">
        <div className="wrap">
          <div
            className={`thumbnail-card-chro ${type === "other" ? "other" : ""}`}
          >
            <Image src={item?.thumbnail ? item.thumbnail : thumbnail} />
          </div>
          {/* {item?.price && (
            <div className="price-card-chro">
              <span className="currency">Rp</span>
              {item.price ? formatRupiah(item.price).substr(3) : 0}
              <span className="month">{item.price && "/12 bulan"}</span>
            </div>
          )} */}
        </div>
        <div className="wrap-info">
          {type === "english" && (
            <div className="d-flex">
              <div className="english-label">
                {item?.category[0]
                  ? item.category[0]?.category
                  : "Manajemen SDM"}
              </div>
              {/* <div className="english-label-subs">Berlangganan</div> */}
            </div>
          )}
          <div className="english-title">{item?.title}</div>
          <div className="english-instructor">
            <div className="image">
              <Image src={instructor} />
            </div>
            <div className="english-info">
              <div className="name">
                {item?.instructors?.length > 0
                  ? item.instructors[0]?.name
                  : "Satukelas"}
              </div>
              <div className="level">Instruktur</div>
            </div>
          </div>
          {/* <div className={`english-desc ${type === "other" && "border-total"}`}>
            <div className="total-lesson">
              <span>
                <img alt="book" src={book} className="mr-1" />
              </span>{" "}
              {item?.total_courses
                ? item.total_courses
                : item?.lessons_count
                ? item?.lessons_count
                : "-"}{" "}
              Pelajaran
            </div>
            <div className="total-duration">
              <span>
                <img alt="clock" src={clock} className="mr-1" />
              </span>{" "}
              {item?.total_duration ? convertHours(item.total_duration) : "-"}
            </div>
          </div> */}
        </div>
      </div>
    </Link>
  );
}
