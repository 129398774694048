import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import WebinarDetail2 from "../Courses/WebinarDetail2";
import { Link, useParams } from "react-router-dom";
import {
  getCoursesDataThunk,
  postEnrollCoursesThunk,
} from "../../redux/thunks/CoursesThunks";
import { useDispatch, useSelector } from "react-redux";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useLocation, useHistory } from "react-router-dom";
import RelatedFileSelection from "../../components/CoursePage/RelatedFileSection";
import CardBenefit from "../../components/CoursePage/CardBenefit";
import CourseCard from "../../components/CourseCard";
import RelatedMaterialSection from "../../components/CoursePage/RelatedMaterialSection";
import Instructor from "../../components/CoursePage/CourseFront/Instructor";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { UserService } from "../../http/UserHttp";
import PrerequisitesCourse from "../../components/CoursePage/PrerequisitesCourse";
import { SanitizeMaterialTypeId, slugify } from "../../helper/functions";
import moment from "moment";
const MySwal = withReactContent(Swal);

function WebinarFront() {
  const courses = useSelector((state) => state.courses);
  const course = courses.coursesDataId;
  const [webinarsDataId, setWebinarsDataId] = useState();
  const [isPassedPrereq, setIsPassedPrereq] = useState([]);
  const [userData, setUserData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [anotherAttempt, setAnotherAttempt] = useState(false);
  const [purchased, setPurchased] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [membershipData, setMembershipData] = useState();
  const [joinMeeting, setJoinMeeting] = useState(false);
  const [show, setShow] = useState(false);
  const [enrolled, setEnrolled] = useState(false);
  const history = useHistory();
  const { course_id, slug } = useParams();
  const wbId =
    course?.topics &&
    course?.topics[0]?.materials &&
    course?.topics[0]?.materials[0]?.webinar_id;
  const [userStatus, setUserStatus] = useState(AuthIsLogin(history, true));
  const [webinarId, setWebinarId] = useState(wbId);
  let address = useLocation();

  const dispatch = useDispatch();
  const location = useLocation();

  useLayoutEffect(() => {
    getUsersV2();
    return () => {
      setJoinMeeting(false);
    };
  }, []);
  useEffect(() => {
    const preq = course?.prerequisites;
    if (preq?.length > 0) {
      let tmp = [];
      // looping di prasyarat course
      for (let i = 0; i < preq?.length; i++) {
        let currPreq = preq[i];
        let status;
        let enroll = true;
        // compare dengan my webinar
        if (currPreq.course_type.toLowerCase() === "webinar") {
          if (currPreq?.is_enrolled) {
            // user sudah enroll
            if (
              moment().isAfter(
                moment(currPreq?.time_ends).format("YYYY-MM-DD HH:mm:ss")
              )
            ) {
              status = "Webinar selesai";
            } else {
              // webinar belum mulai atau sedang berlangsung
              status = "Lihat webinar";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti webinar"
            status = "Ikuti webinar";
            enroll = false;
          }
        }

        if (currPreq.course_type.toLowerCase() === "pelatihan") {
          if (currPreq.is_enrolled) {
            // user sudah enroll
            if (currPreq.is_completed === "completed") {
              status = "Pelatihan selesai";
            } else {
              status = "Lanjutkan pelatihan";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti pelatihan"
            status = "Ikuti pelatihan";
            enroll = false;
          }
        }

        let obj = {
          course_id: preq[i].course_id,
          course_type: preq[i].course_type,
          text: status,
          enroll,
        };
        tmp.push(obj);
      }
      setIsPassedPrereq(tmp);
    }
  }, [course]);

  useEffect(() => {
    getCoursesDataId();
  }, []);

  useEffect(() => {
    if (course?.topics) {
      setWebinarsDataId(course?.topics[0]?.materials[0]);
      if (!wbId) {
        setWebinarId(course?.topics[0]?.materials[0]?.webinar_id);
      }
    }
  }, [course]);

  useEffect(() => {
    if (membershipData) {
      if (membershipData?.length > 0) setUserStatus(membershipData[0].status);
    }
  }, [membershipData]);

  useEffect(() => {
    if (AuthData().userId) getMembership();
  }, []);

  const getMembership = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getMembership();
      setMembershipData(response.memberships);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  const getUsersV2 = async () => {
    try {
      const response = await UserService.getUserV2(AuthData().userId);
      setUserData(response);
    } catch (e) {
      console.log("error", e);
    }
  };

  const getCoursesDataId = () => {
    dispatch(getCoursesDataThunk({ type: "id", param: `/${course_id}` }));
  };

  const handleLogin = () => {
    displayPopupLogin(
      false,
      "Login Terlebih Dahulu untuk Memulai Pelatihan",
      "Login"
    );
  };
  function enrollPrerequisitesCourse(item) {
    const payload = {
      user_id: AuthData().userId,
      course_id: item,
    };

    if (!course?.is_completed) {
      setShow(true);
      return false;
    }
    setTrigger(true);
    dispatch(postEnrollCoursesThunk(payload));
  }
  function displayPopupLogin(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        history.push(`/login?targetUrl=${address?.pathname}`);
      }
    });
  }
  function continueCourse() {
    // check if user profile is complete
    if (!course?.is_completed) {
      setShow(true);
      return false;
    }
    if (anotherAttempt) {
      const sanitizedMaterial = SanitizeMaterialTypeId(
        course.topics[0].materials[0]
      );
      history.push(
        `/course/${slug}/${course_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_0_0`
      );
    } else {
      const idxs = getIndex();
      const sanitizedMaterial = SanitizeMaterialTypeId(
        course.topics[idxs.index].materials[idxs.indexL]
      );
      history.push(
        `/course/${slug}/${course_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_${idxs.index}_${idxs.indexL}`
      );
    }
  }
  function getIndex() {
    const topics = course.topics;
    for (let i = 0; i < topics?.length; i++) {
      const materials = topics[i].materials;
      for (let j = 0; j < materials?.length; j++) {
        if (materials[j].completed === 0) {
          const data = { index: i, indexL: j };
          return data;
        } else return { index: 0, indexL: 0 };
      }
    }
  }
  return (
    <>
      <div className="middle-sidebar-bottom">
        <Container className="dashboard_page__container">
          <Row className="no-gutters-xs">
            <Col lg={12}>
              {course?.topics !== undefined && wbId !== undefined && (
                <WebinarDetail2
                  courses={courses}
                  course={course}
                  userStatus={userStatus}
                  users={userData}
                  wbId={wbId}
                  setJoin={setJoinMeeting}
                  join={joinMeeting}
                />
              )}
            </Col>
            {course?.prerequisites?.length > 0 && (
              <PrerequisitesCourse
                prereq={course.prerequisites}
                type={course.course_type}
                item={course}
                enrollCourse={enrollPrerequisitesCourse}
                continueCourse={continueCourse}
                anotherAttempt={anotherAttempt}
                purchased={purchased}
                isPassedPrereq={isPassedPrereq}
                statusUser={userStatus}
                enrolled={enrolled}
              />
            )}
            <Col lg={6}>
              {/* show the learning objective from webinar */}
              <CardBenefit objective={course && course?.objectives} />
              <RelatedFileSelection
                membershipData={membershipData}
                handouts={course && course?.handouts}
              />
            </Col>
            <Col lg={6}>
              <Instructor
                webinarsDataId={webinarsDataId}
                userStatus={userStatus}
                course={course}
                courses={courses}
                isWebinar={true}
                item={course}
                wbId={wbId}
                handleLogin={handleLogin}
                enrolled={enrolled}
              />
            </Col>
            <Col lg={12}>
              <div className="my-4">
                <div className="course_more__list mb-3">
                  Tingkatkan Keahlian Lebih Lanjut
                </div>
                <div className="course_more__description mb-5">
                  Temukan pengalaman baru belajar bersama instruktur handal
                  dalam memberikan materi, Anda dalam memberikan pertanyaan
                  terkait konteks dari materi pembelajaran secara langsung.
                </div>
              </div>

              <div className="related-webinar_wrapper">
                <div className="d-flex justify-content-between">
                  <div className="related-webinar__title">Webinar Serupa</div>
                  {course?.related_courses?.length > 0 ? (
                    <div className="related-webinar__more">
                      <Link to="/webinar-list/webinar/type=webinar">
                        Lihat Selengkapnya
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <Row>
                  {course?.related_courses &&
                  course?.related_courses?.length > 0 ? (
                    course.related_courses.map((e, i, { length }) => {
                      // need to check course type, return only type = Webinar
                      return <CourseCard item={e} key={i} />;
                    })
                  ) : (
                    <Col sm={12}>Data Belum Tersedia</Col>
                  )}
                </Row>
              </div>
              <RelatedMaterialSection
                more="Lihat Selengkapnya"
                title="Pelatihan Online Serupa"
                related={course}
                disableHover={true}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WebinarFront;
