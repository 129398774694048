import {
  HEADERS_DATA,
  HEADERS_DATA_SUCCESS,
  HEADERS_DATA_FAIL,
  REMOVE_HEADER_DATA
} from "../config/HeadersConfig";

const intialState = {
  headersData: null,
  isLoading: false,
};

export const HeadersReducers = function (state = intialState, action) {
  switch (action.type) {
    case HEADERS_DATA:
      return { ...state, isLoading: true };
    case HEADERS_DATA_SUCCESS:
      return { ...state, isLoading: false, headersData: action.value };
    case HEADERS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        headersData: "error",
      };
    case REMOVE_HEADER_DATA:
      return { ...state, headersData: null };
    default:
      return state;
  }
};
