import { satukelasApi } from "../constants";

export const brevetService = {
  getCourseDetail(course_id) {
    return satukelasApi.get(`/courses/${course_id}`);
  },
  getCourseList(type) {
    return satukelasApi.get(`/courses${type}`);
  },
  getCourseBrevetList(type, discussion, value, size, active) {
    return satukelasApi.get(
      `/courses?course_type=${type}%20&discussion_topic=${discussion}%20brevet%20${value}&scheme=sertifikasi%20brevet%20${value}%20khusus&size=${size}&page=${active}`
    );
  },

  enrollCourseBrevet(payload) {
    return satukelasApi.post(`/courses/complete_courses`, payload);
  },
};
