import React, { useEffect, useState } from "react";
import { MembershipService } from "../../http/MembershipHttp";
import { Col, Card } from "react-bootstrap";
import { formatRupiah } from "../../helper/functions";
import CTAButtonMembership from "./CTAButton";
import Loading from "../Layout/Loading";
import { UserService } from "../../http/UserHttp";
import { getUsersDataThunkV2 } from "../../redux/thunks/UsersThunks";
import { AuthData } from "../../helper/auth";
import { useDispatch, useSelector } from "react-redux";

function MembershipList() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [catalogue, setCatalogue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userMembership, setUserMembership] = useState(null);
  useEffect(() => {
    getMembershipCatalogue();
    getMembershipUser();
    if (users.usersDataId.length === 0) {
      dispatch(getUsersDataThunkV2(AuthData().userId));
    }
  }, []);

  /**
   * @function to get membership catalogue from api
   * @param nothing
   * @returns nothing
   */
  const getMembershipCatalogue = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      const response = await MembershipService.getMembershipCatalogue();
      setCatalogue(response.items);
    } catch (error) {}
  };

  /**
   * @function to get membership catalogue from api
   * @param nothing
   * @returns nothing
   */
  const getMembershipUser = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      const response = await UserService.getMembership();
      setUserMembership(response.memberships);
    } catch (error) {}
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      {catalogue &&
        catalogue.map((e, i) => {
          return e.title.toLowerCase().includes("bootcamp") ? null : (
            <Col sm={12} md={6} key={`catalogueMembership${i}`}>
              <Card className="border-0 mb-4 bg-white shadow-xss shadow-xss rounded-lg">
                <Card.Body className="p-4 p-md-5 border-top-lg border-size-lg border-primary">
                  <div className="d-flex justify-content-between">
                    <div className="mr-4">
                      <h1 className="membership_title__container">
                        {formatRupiah(e.price)}
                        <span className="font-xssss text-grey-500">
                          {" "}
                          /{e.duration} hari
                        </span>
                      </h1>
                      <h2 className=" font-xs fw-700 mt-3 mb-3">{e.title}</h2>
                    </div>
                  </div>
                  <div className="mb-4">
                    <pre style={{ fontFamily: "inherit" }}>{e.description}</pre>
                  </div>
                  {/* CTA membership */}
                  <CTAButtonMembership
                    item={e}
                    userMembership={userMembership}
                    getMembership={getMembershipUser}
                  />
                </Card.Body>
              </Card>
            </Col>
          );
        })}
    </>
  );
}
export default MembershipList;
