import React from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { WEB_URL } from "../../helper/constant";

const MetaTag = (props) => {
  return <Helmet>{ReactHtmlParser(getAllMetaTags(props))}</Helmet>;
};

const getAllMetaTags = (meta = {}) => {
  meta = { ...defaultMeta, ...meta };
  let metaTags = `<title>${meta.title ?? "Satukelas"}</title>`;
  metaTags += `<meta name="description" content="${meta.description}" />`;
  metaTags += `<link rel="canonical" href="${WEB_URL}${meta.url}" />`;
  // append fb tags
  const properties = getFBPropertyMetas(meta);
  for (const name in properties) {
    metaTags += `<meta property="${name}" content="${properties[name]}" />`;
  }

  // append twitter tags
  const names = getTwitterNameMetas(meta);
  for (const name in names) {
    metaTags += `<meta name="${name}" content="${names[name]}" />`;
  }
  return metaTags;
};

const defaultMeta = {
  title: "Satukelas - Belajar Manajemen Terlengkap Se-Indonesia!",
  description:
    "We provide professional learning experience. Our clients enjoy not only a learning platform, but with sets of learning content as well.",
  url: "https://student.satukelas.com",
  image:
    "https://student.satukelas.com/static/media/logo-satukelas.f0e8d30a.jpeg",
};

const getFBPropertyMetas = (meta) => {
  return {
    "og:site_name": "Satukelas",
    "og:url": `${WEB_URL}${meta.url}/`,
    "og:type": "article",
    "og:title": meta.title,
    "og:description": meta.description,
    "og:image": meta.image,
    "og:image:width": 600,
    "og:image:height": 315,
  };
};

const getTwitterNameMetas = (meta) => {
  return {
    "twitter:card": "summary_large_image",
    "twitter:creator": "satukelascom",
    "twitter:title": meta.title,
    "twitter:description": meta.description,
    "twitter:image:src": meta.image,
  };
};

export default MetaTag;
