/* eslint-disable jsx-a11y/anchor-is-valid */
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Col, Container, Image, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../../assets/css/Account/index.css';
import '../../assets/css/Layout/header.css';
import notificationIcon from '../../assets/images/notification.svg';
import notificationBlueIcon from '../../assets/images/notificationBlue.svg';
import { AuthIsLogin, AuthLogin, AuthLogout } from '../../helper/auth';
import {
  DM_COURSE_ID,
  brevet_dev,
  brevet_prod,
  env,
  lp_dev,
  lp_prod,
  titleBtnHeader,
} from '../../helper/constant';
import { Clone, slugify } from '../../helper/functions';
import { courseService } from '../../http/CourseLengthHttp';
import { removeLoginData } from '../../redux/actions/LoginActions';
import { removeUsersDataId } from '../../redux/actions/UsersActions';
import { getLoginDataThunk } from '../../redux/thunks/LoginThunks';

import { SkValidate } from '../../components/common/SkValidate';
import ModalAuth from './ModalAuth';
import SearchCourseField from '../Header/SearchCourseField';
import LoginCTA from '../Header/LoginCTA';
import AboutMenu from '../Header/AboutMenu';
import CatalogMenu from '../Header/CatalogMenu';
import CertificateMenu from '../Header/CertificateMenu';
import ProfileMenu from '../Header/ProfileMenu';
const sha256 = require('js-sha256');

const MySwal = withReactContent(Swal);

function NewHeader({
  isSidebar,
  collapsed,
  handleCollapsedChange,
  id,
  searchModal,
  setSearchModal,
  handleSearch,
  myRef,
  handleModalAuth,
  handleModalCloseAuth,
}) {
  const [showMenuCertification, setShowMenuCertification] = useState(false);
  const [statusCertification, setStatusCertification] = useState();
  const currUrl = useLocation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [keyword, setKeyword] = useState('');
  const [liveSearchData, setLiveSearchData] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [isSinarmas, setIsSinarmas] = useState(false);

  const [data, setData] = useState({
    password: '',
    username: '',
  });
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const submenuRef = useRef(null);
  const history = useHistory();
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  // const courses = useSelector((state) => state.courses);
  const [coursePath, setCoursePath] = useState(
    env === 'development' ? lp_dev.items : lp_prod.items
  );
  const [brevetNav, setBrevetNav] = useState(
    env === 'development' ? brevet_dev : brevet_prod
  );
  let address = useLocation();
  let splitted = address.pathname.split('/');
  const [classNav, setClassNav] = useState(null);
  const path_chro = env === 'development' ? 'co68651931' : 'co78129874';
  //   const categoryIdSearch =
  //     env === "development"
  //  || "co34028467" || "co92113052"||
  //  || "co97744206" || "co73258264";

  useEffect(() => {
    if (login.loginData) dispatch(removeLoginData());
  }, [login]);

  useEffect(() => {
    if (!statusCertification) setShowMenuCertification(false);
  }, [statusCertification, showMenuCertification]);

  // useEffect(() => {
  //   if (isSubMenuVisible) {
  //     const delayTiming = setTimeout(() => {
  //       searchCourse();
  //     }, 1000);

  //     return () => clearTimeout(delayTiming);
  //   }
  // }, [isSubMenuVisible]);

  // useEffect(() => {
  //   if (courses?.courseBrevetData) setBrevetNav(courses.courseBrevetData);
  // }, [courses?.courseBrevetData]);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.target.blur();
      history.push(`/search/semua/keyword=${keyword}`);
      setKeyword('');
      setSearchModal(!searchModal);
    }
  };

  const searchCourse = async () => {
    try {
      const response = await courseService.getCourse(
        `?keyword=${keyword}&size=3`
      );
      if (response?.items?.length > 0) setLiveSearchData(response?.items);
    } catch (err) {
      console.log(err);
    }
  };
  const handleMouse = (e) => {
    e.preventDefault();
    if (e.type === 'mouseover') {
      setSearchModal(false);
    }
    setClassNav(null);
  };
  const handleLogoutClick = () =>
    MySwal.fire({
      title: 'Apakah anda yakin ingin keluar?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Tidak',
      confirmButtonText: 'Ya',
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(removeLoginData());
        dispatch(removeUsersDataId());
        // dispatch(removeHeaderData());
        AuthLogout(history);
      }
    });

  const toggleSubMenu = (menuNumber) => {
    setActiveSubMenu(menuNumber);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const _isSinarmas = history.location.search.includes('techconnect');
    setIsSinarmas(_isSinarmas);
  }, []);

  useEffect(() => {
    if (trigger && login.loginData) {
      if (login.loginData.status === 'success') {
        let newData = Clone(data);
        newData.userId = login.loginData.data.user_id;
        //   // newData.isBnsp = login.loginData.data.is_bnsp;
        AuthLogin(newData);
        var url = new URL(window.location.href);
        var targetUrl = url.searchParams.get('targetUrl');
        if (targetUrl) {
          history.push(targetUrl);
        } else {
          history.push('/dashboard/course');
        }
        setIsModalOpen(false);
      } else {
        setTrigger(false);
        displayPopup(login.loginData.data, 'error');
      }
    }
  }, [login.loginData]);

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === 'price' ? parseInt(e.target.value) : e.target.value;
    setData(newData);
  };

  const constraints = {
    email: {
      presence: true,
      email: true,
    },
    password: {
      presence: true,
    },
  };
  function onSubmitHandler(e) {
    e.preventDefault();
    const form = e.target;
    // dispatch(loadingStart());
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let body = Clone(data);
      body.password = sha256(data.password);
      setTrigger(true);
      dispatch(getLoginDataThunk(body));
    }
  }

  function displayPopup(title, icon) {
    MySwal.fire({
      title: title,
      showConfirmButton: false,
      icon: icon,
      showCloseButton: true,
    }).then((value) => {});
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setSubMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };

  // useEffect(() => {
  //   getLearningPathCategory('bahasa inggris');
  // }, []);

  // const getLearningPathCategory = async (param) => {
  //   loadingStart();
  //   try {
  //     const response = await LearningPathService.getLearningPathCategory(param);
  //     if (response) {
  //       const reverseData = response?.items.reverse();
  //       setCoursePath(reverseData);
  //       loadingFinish();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const [hoverIcon, setHoverIcon] = useState('');
  const handleMouseHover = (item) => {
    setHoverIcon(item);
  };
  const [iconDropdown, setIconDropdown] = useState('');
  const handleMouseProfile = (icon) => {
    setIconDropdown(icon);
  };

  const handleLeaveMouseProfile = () => {
    setIconDropdown('');
  };

  const logoSatukelas = () => {
    return (
      <Link to="/">
        <div alt="Satukelas" className="satu-kelas-logo__main"></div>
      </Link>
    );
  };

  const notificationSatukelasMenu = () => {
    return (
      <li>
        <div className="notification_container">
          {/* <span className="dot-count bg-warning"></span> */}
          <span
            onMouseEnter={() => handleMouseHover('notif')}
            onMouseLeave={handleMouseHover}
          >
            <Image
              src={
                hoverIcon === 'notif' ? notificationBlueIcon : notificationIcon
              }
              width={38}
              height={38}
              alt="notif"
            />
          </span>
          <div className="menu-dropdown">
            <h4 className="notification_text mb-4">Notifikasi</h4>
            <p className="text-center name_notification__detail_none mb-1 mt-0 d-block">
              Belum Ada Notifikasi
            </p>
          </div>
        </div>
      </li>
    );
  };

  const dashboardSatukelasMenu = () => {
    return (
      <NavLink to="/dashboard/course/" className="main header_left__item ">
        Dashboard
      </NavLink>
    );
  };

  return (
    <StyledWrapperMain>
      <div className="container_header d-none">
        <div
          className={`main-content ${
            currUrl.pathname.includes('dashboard') ? 'px-3' : 'px-5'
          }`}
        >
          <div
            className={`middle-sidebar-header bg-white h-inherit ${
              splitted[1] === 'bnsp' || splitted[3] === DM_COURSE_ID
                ? 'd-flex justify-content-between'
                : null
            }`}
          >
            {!isSidebar ? (
              <div className="d-flex align-items-center h-inherit header_left__wrapper">
                {/* redirect if user login or not */}
                {AuthIsLogin('', true) ? (
                  <>
                    {logoSatukelas()}
                    {splitted[1] === 'bnsp' ||
                      (splitted[3] === DM_COURSE_ID && (
                        <Nav className="align-items-center">
                          <SearchCourseField
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                            keyword={keyword}
                            myRef={myRef}
                            handleButtonClick={handleButtonClick}
                            isSubMenuVisible={isSubMenuVisible}
                            submenuRef={submenuRef}
                            liveSearchData={liveSearchData}
                          />
                          {splitted[1] !== 'dashboard' &&
                            AuthIsLogin('', true) &&
                            dashboardSatukelasMenu()}
                          <CertificateMenu
                            id={id}
                            handleMouseHover={handleMouseHover}
                            handleMouse={handleMouse}
                            toggleSubMenu={toggleSubMenu}
                            hoverIcon={hoverIcon}
                            brevetNav={brevetNav}
                          />
                          <CatalogMenu
                            id={id}
                            handleMouseHover={handleMouseHover}
                            handleMouse={handleMouse}
                            toggleSubMenu={toggleSubMenu}
                            hoverIcon={hoverIcon}
                            coursePath={coursePath}
                          />
                          <AboutMenu
                            id={id}
                            handleMouseHover={handleMouseHover}
                            handleMouse={handleMouse}
                            toggleSubMenu={toggleSubMenu}
                            hoverIcon={hoverIcon}
                          />

                          {splitted[1] === 'dashboard' &&
                            AuthIsLogin('', true) && (
                              <ul className="d-flex ml-auto pl-0 align-items-center right-menu-icon m-0">
                                {AuthIsLogin('', true) &&
                                  notificationSatukelasMenu()}
                                {AuthIsLogin('', true) ? (
                                  <ProfileMenu
                                    handleMouseHover={handleMouseHover}
                                    handleMouseProfile={handleMouseProfile}
                                    handleLeaveMouseProfile={
                                      handleLeaveMouseProfile
                                    }
                                    iconDropdown={iconDropdown}
                                    handleLogoutClick={handleLogoutClick}
                                    hoverIcon={hoverIcon}
                                  />
                                ) : (
                                  <LoginCTA openModal={openModal} />
                                )}
                              </ul>
                            )}
                        </Nav>
                      ))}
                  </>
                ) : (
                  <>
                    {logoSatukelas()}
                    {splitted[1] === 'bnsp' ||
                      (splitted[3] === DM_COURSE_ID && (
                        <Nav className="align-items-center">
                          <SearchCourseField
                            handleChange={handleChange}
                            handleKeyDown={handleKeyDown}
                            keyword={keyword}
                            myRef={myRef}
                            handleButtonClick={handleButtonClick}
                            isSubMenuVisible={isSubMenuVisible}
                            submenuRef={submenuRef}
                            liveSearchData={liveSearchData}
                          />
                          {splitted[1] !== 'dashboard' &&
                            AuthIsLogin('', true) &&
                            dashboardSatukelasMenu()}
                          <CertificateMenu
                            id={id}
                            handleMouseHover={handleMouseHover}
                            handleMouse={handleMouse}
                            toggleSubMenu={toggleSubMenu}
                            hoverIcon={hoverIcon}
                            brevetNav={brevetNav}
                          />
                          <CatalogMenu
                            id={id}
                            handleMouseHover={handleMouseHover}
                            handleMouse={handleMouse}
                            toggleSubMenu={toggleSubMenu}
                            hoverIcon={hoverIcon}
                            coursePath={coursePath}
                          />
                          <AboutMenu
                            id={id}
                            handleMouseHover={handleMouseHover}
                            handleMouse={handleMouse}
                            toggleSubMenu={toggleSubMenu}
                            hoverIcon={hoverIcon}
                          />
                          {splitted[1] === 'dashboard' &&
                            AuthIsLogin('', true) && (
                              <ul className="d-flex ml-auto pl-0 align-items-center right-menu-icon m-0">
                                {AuthIsLogin('', true) &&
                                  notificationSatukelasMenu()}
                                {AuthIsLogin('', true) ? (
                                  <ProfileMenu
                                    handleMouseHover={handleMouseHover}
                                    handleMouseProfile={handleMouseProfile}
                                    handleLeaveMouseProfile={
                                      handleLeaveMouseProfile
                                    }
                                    iconDropdown={iconDropdown}
                                    handleLogoutClick={handleLogoutClick}
                                    hoverIcon={hoverIcon}
                                  />
                                ) : (
                                  <LoginCTA openModal={openModal} />
                                )}
                              </ul>
                            )}
                        </Nav>
                      ))}
                  </>
                )}
                <>
                  {splitted[3] === DM_COURSE_ID ||
                  splitted[1] === 'agreement-user' ||
                  splitted[1] === 'bnsp-digital-marketing' ? null : (
                    <>
                      <Nav className="align-items-center">
                        <SearchCourseField
                          handleChange={handleChange}
                          handleKeyDown={handleKeyDown}
                          keyword={keyword}
                          myRef={myRef}
                          handleButtonClick={handleButtonClick}
                          isSubMenuVisible={isSubMenuVisible}
                          submenuRef={submenuRef}
                          liveSearchData={liveSearchData}
                        />
                        {splitted[1] !== 'dashboard' &&
                          AuthIsLogin('', true) &&
                          dashboardSatukelasMenu()}
                        <CertificateMenu
                          id={id}
                          handleMouseHover={handleMouseHover}
                          handleMouse={handleMouse}
                          toggleSubMenu={toggleSubMenu}
                          hoverIcon={hoverIcon}
                          brevetNav={brevetNav}
                        />
                        <CatalogMenu
                          id={id}
                          handleMouseHover={handleMouseHover}
                          handleMouse={handleMouse}
                          toggleSubMenu={toggleSubMenu}
                          hoverIcon={hoverIcon}
                          coursePath={coursePath}
                        />
                        <AboutMenu
                          id={id}
                          handleMouseHover={handleMouseHover}
                          handleMouse={handleMouse}
                          toggleSubMenu={toggleSubMenu}
                          hoverIcon={hoverIcon}
                        />
                        {splitted[1] === 'dashboard' &&
                          AuthIsLogin('', true) && (
                            <ul className="d-flex ml-auto pl-0 align-items-center right-menu-icon m-0">
                              {AuthIsLogin('', true) &&
                                notificationSatukelasMenu()}
                              {AuthIsLogin('', true) ? (
                                <ProfileMenu
                                  handleMouseHover={handleMouseHover}
                                  handleMouseProfile={handleMouseProfile}
                                  handleLeaveMouseProfile={
                                    handleLeaveMouseProfile
                                  }
                                  iconDropdown={iconDropdown}
                                  handleLogoutClick={handleLogoutClick}
                                  hoverIcon={hoverIcon}
                                />
                              ) : (
                                <LoginCTA openModal={openModal} />
                              )}
                            </ul>
                          )}
                      </Nav>
                    </>
                  )}
                </>
              </div>
            ) : (
              <div className="d-flex align-items-center header_left__wrapper">
                <div onClick={() => handleCollapsedChange(!collapsed)}></div>
                <Link to="/">
                  <img
                    data-src="https://satukelas.com/wp-content/uploads/2021/02/png-satukelas-296x63.png"
                    alt="Satukelas"
                    width="148"
                    className="satu-kelas-logo lazyload"
                  />
                </Link>
                <>
                  <Nav>
                    {splitted[1] !== 'dashboard'
                      ? AuthIsLogin('', true)
                        ? dashboardSatukelasMenu()
                        : ''
                      : ''}
                    <div
                      id="katalog"
                      className={`position-inherit main header_left__item ${
                        id === 'katalog'
                          ? 'interaction-active'
                          : id === 'search'
                          ? ''
                          : ''
                      } dropdown mx-1`}
                      onMouseOver={handleMouse}
                      onClick={handleMouse}
                    >
                      Katalog Pelatihan
                      <Container id="mega" className={`mega-menu__container`}>
                        <Row noGutters={true}>
                          <Col md={6} lg={3} className="title_megamenu">
                            <div className="p-4">
                              <div className="mb-3 catalogue_megamenu">
                                Katalog Pelatihan
                              </div>
                              <div className="subtitle_megamenu">
                                Kembangkan skill Anda dengan pelatihan yang
                                disediakan oleh Satukelas.
                              </div>
                            </div>
                          </Col>
                          <Col md={6} lg={3} className="second_title__megamenu">
                            <div className="p-4 last_menu">
                              <div>
                                <div className="mb-3 title_menu_list__mega">
                                  Pelatihan
                                </div>
                                <Link className="mr-3" to="/search/pelatihan/">
                                  <div className="menu_list__mega">
                                    Pelatihan Mandiri
                                  </div>
                                </Link>
                                <Link
                                  className="mr-3"
                                  to="/search/studi%20kasus/"
                                >
                                  <div className="menu_list__mega">
                                    Studi Kasus
                                  </div>
                                </Link>
                                <Link className="mr-3" to="/search/try%20out/">
                                  <div className="menu_list__mega">Try Out</div>
                                </Link>
                              </div>
                              <Link className="mr-3" to="/search/semua/">
                                <div className="menu_list__mega_more mb-5">
                                  Lihat semua pelatihan (477+)
                                </div>
                              </Link>
                            </div>
                          </Col>
                          <Col md={6} lg={3} className="second_title__megamenu">
                            <div className="p-4 last_menu">
                              <div>
                                <div className="mb-3 title_menu_list__mega">
                                  Live Class
                                </div>
                                <Link
                                  className="mr-3"
                                  to="/webinar-list/webinar"
                                >
                                  <div className="menu_list__mega">Webinar</div>
                                </Link>
                                <Link
                                  className="mr-3"
                                  to="/webinar-list/konsultasi"
                                >
                                  <div className="menu_list__mega">
                                    Konsultasi
                                  </div>
                                </Link>
                              </div>
                              <Link
                                className="header_left__item mr-3"
                                to="/webinar-list/semua/"
                              >
                                <div className="menu_list__mega_more mb-5">
                                  Lihat semua Live
                                </div>
                              </Link>
                            </div>
                          </Col>
                          <Col md={6} lg={3} className="second_title__megamenu">
                            <div className="p-4 last_menu">
                              <div>
                                <div className="mb-3 title_menu_list__mega">
                                  Sertifikasi
                                </div>
                                <Link to="/search/sertifikasi/keyword=brevet">
                                  <div className="mb-3 menu_list__mega">
                                    Brevet Pajak
                                  </div>
                                </Link>
                                <div className="d-flex mb-3 menu_list__mega">
                                  <div>
                                    Badan Nasional Sertifikasi Profesi(BNSP)
                                  </div>
                                  <div>
                                    <Badge>Soon</Badge>
                                  </div>
                                </div>
                                {/* <div>
                                  <Link to={`/scorm/${slugify("Kickstart Your TOEFL Preparation")}/lp46808336`}>
                                    <div className="mb-3 menu_list__mega">Kickstart Your TOEFL Preparation</div>
                                  </Link>
                                </div> */}
                              </div>

                              <Link
                                className="header_left__item mr-3"
                                to="/search/sertifikasi"
                              >
                                <div className="menu_list__mega_more mb-5">
                                  Lihat semua sertifikasi (7)
                                </div>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <div
                      id="lainnya"
                      className={`third header_left__item ${
                        id === 'lainnya'
                          ? 'interaction-active'
                          : id === 'search'
                          ? ''
                          : ''
                      } dropdown mx-1`}
                      onMouseOver={handleMouse}
                      onClick={handleMouse}
                    >
                      Lainnya
                      <div id="menu" className="menu__container p-3">
                        <div className="">
                          <Link to="/blog" className="title_product">
                            <div className="mb-3">Blog</div>
                          </Link>
                          <Link to="/webstore" className="title_product">
                            <div className="mb-3">Webstore</div>
                          </Link>
                          {/* <Link to="/indonesia-tax-review" className="title_product">
                            <div className="mb-3">Majalah ITR</div>
                          </Link> */}
                          <Link
                            to="#"
                            className="title_product d-flex justify-content-between"
                          >
                            <div className="mb-3">Kumpulan Peraturan</div>
                            <div>
                              <Badge variant="primary">Soon</Badge>
                            </div>
                          </Link>
                          <Link
                            to="/corporate-digital-learning-center"
                            className="title_product d-flex justify-content-between"
                          >
                            <div className="mr-2">Digital Learning Center</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Nav>
                </>
              </div>
            )}
            {/* bnsp page condition */}
            {splitted[1] === 'bnsp' || splitted[3] === DM_COURSE_ID ? (
              AuthIsLogin('', true) ? (
                <ul className="d-flex ml-auto right-menu-icon m-0 align-items-center">
                  {notificationSatukelasMenu()}
                  <ProfileMenu
                    handleMouseHover={handleMouseHover}
                    handleMouseProfile={handleMouseProfile}
                    handleLeaveMouseProfile={handleLeaveMouseProfile}
                    iconDropdown={iconDropdown}
                    handleLogoutClick={handleLogoutClick}
                    hoverIcon={hoverIcon}
                  />
                </ul>
              ) : (
                <div className="d-flex align-items-center">
                  {splitted[1] === 'bnsp-digital-marketing' ? (
                    <Link to="/login" className="login-button__header mr-3">
                      Login
                    </Link>
                  ) : (
                    <LoginCTA openModal={openModal} />
                  )}
                </div>
              )
            ) : (
              <ul className="d-flex ml-auto pl-0 align-items-center right-menu-icon m-0">
                {AuthIsLogin('', true) && notificationSatukelasMenu()}
                {AuthIsLogin('', true) ? (
                  <ProfileMenu
                    handleMouseHover={handleMouseHover}
                    handleMouseProfile={handleMouseProfile}
                    handleLeaveMouseProfile={handleLeaveMouseProfile}
                    iconDropdown={iconDropdown}
                    handleLogoutClick={handleLogoutClick}
                    hoverIcon={hoverIcon}
                  />
                ) : (
                  <LoginCTA openModal={openModal} />
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
      {(isModalOpen && (
        <ModalAuth
          closeModal={closeModal}
          onSubmitHandler={onSubmitHandler}
          data={data}
          onChangeHandler={onChangeHandler}
        />
      )) ||
        (handleModalAuth && (
          <ModalAuth
            closeModal={handleModalCloseAuth}
            onSubmitHandler={onSubmitHandler}
            data={data}
            onChangeHandler={onChangeHandler}
          />
        ))}
    </StyledWrapperMain>
  );
}

export default NewHeader;

const StyledWrapperMain = styled.section`
  svg {
    height: 32px;
    width: 32px;
  }
  .main-content {
    padding: 24px 0;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #fff;
  }
`;
