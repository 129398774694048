import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../../assets/css/Courses/QRCode.css";
import { convertHours } from "../../helper/functions";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { getCertificatesDataThunk } from "../../redux/thunks/CertificatesThunks";
function QRCode() {
  // id certificate from URL
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dataCertificates, setDataCertificates] = useState();
  const certificates = useSelector((state) => state.certificates);

  // get certificate data for Connect to API
  useEffect(() => {
    dispatch(getCertificatesDataThunk({ param: `${id}` }));
  }, []);

  useEffect(() => {
    setDataCertificates(certificates.certificatesDataId);
  }, [certificates]);

  return (
    <Container>
      <Row className="my-5">
        <Col lg={3}>
          <div className="summary_wrapper__certification">
            <div className="thumbnail_container">
              <img
                alt="Course Thumbnail"
                className="thumbnail_image lazyload"
                data-src={dataCertificates?.course_thumbnail}
              />
            </div>
            <div className="detail_container">
              <div className="detail_text type">
                Tipe :{" "}
                <span className="type_detail">
                  {dataCertificates?.course_type}
                </span>
              </div>
              <div className="detail_text level">
                Mulai Sertifikasi :{" "}
                <span className="level_detail">
                  {moment(dataCertificates?.created_at).format("DD/MM/YYYY")}
                </span>
              </div>
              <div className="detail_text detail">
                Penerima :{" "}
                <span className="detail_detail">
                  {dataCertificates?.recipient}
                  {/* {convertHours(dataCertificates?.duration)} */}
                </span>
              </div>
              <div className="detail_text detail">
                Skor :{" "}
                <span className="detail_detail">
                  {dataCertificates?.score}
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={9}>
          <div className="detail_wrapper__certification">
            <div className="title_container">
              <h2 className="title_section h2">{dataCertificates?.course}</h2>
              <div className="subtitle_section">
                Diterbitkan oleh{" "}
                <span>
                  <a href="#">
                    {dataCertificates?.instructors?.length > 0
                      ? dataCertificates?.instructors[0]?.name
                      : null}
                  </a>
                </span>
              </div>
              <div className="certification_preview__thumbnail">
                <div className="certification_preview__image">
                  <img
                    alt="Certificate Thumbnail"
                    className="lazyload"
                    data-src={dataCertificates?.certificate_url}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default QRCode;
