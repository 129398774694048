import React from "react";
import { Button, Carousel, Image, Row } from "react-bootstrap";
import "../../assets/css/AtpiPage/Atpi.css";
import VisiMisi from "./VisiMisi";
import Cta from "./Cta";
import CertificateAtpi from "./Certificate";
import CertificateAtpiProf from "./CertificateAtpiProf";
import CtaFooter from "./CtaFooter";
import logoApindo from "../../assets/images/atpi/apindo/logo-apindo.png";
import CertificateApindo from "./CertificateApindo";
import imageHeroshot from "../../../src/assets/images/atpi/heroshot/image-hero-right.png";

const PageAtpi = (props) => {
  const { typePage } = props;
  return (
    <section>
      <div className="parent__heroshot">
        <Carousel id="home" controls={false} indicators={false} className="position-relative">
          <Carousel.Item interval={4000} className={`${typePage === "atpi" ? "hero__shot-atpi" : "hero__shot-apindo"}`}>
            <div className="h-100 px-xl-3 py-5">
              <Row className="h-inherit w-100">
                <div className={`d-block mb-3 mb-xl-0 ${typePage === "atpi" ? "w-100" : null}`}>
                  {typePage === "atpi" ? (
                    <div className="d-flex justify-content-xl-center w-100">
                      <div className="col-12 col-xl-8 pl-xl-3 w-100">
                        <span className="badge__heroshot">Mitra Satukelas</span>
                        <div className="w-100 border-0 d-block d-md-flex align-items-center">
                          <h2 className="title__heroshot-atpi mr-3">ATPI</h2>
                          <span className="subtitle__heroshot-atpi">Asosiasi Teknisi Perpajakan Indonesia</span>
                        </div>
                        <div className="w-100">
                          <p className="desc__heroshot-atpi mt-3 ">
                            Satukelas bekerjasama dengan ATPI (Asosiasi Teknisi Perpajakan Indonesia) dalam menyediakan Gelar Profesi Teknisi Pajak pengguna Satukelas yang membeli Paket Sertifikasi Brevet Pajak dan Gelar Profesi Teknisi
                            Pajak.
                          </p>
                        </div>
                        <div className="w-100">
                          <a className="btn__heroshot-atpi" href="https://atpi.or.id/" target="_blank" rel="noreferrer">
                            Kunjungi Situs ATPI
                          </a>
                        </div>
                        <div className="wrapp__desc-footer-atpi">
                          <em className="footer__desc-atpi">
                            *ATPI sebagai Perkumpulan Berbadan Hukum yang oleh para pendiri didirikan dengan Akte Notaris Lilis Suryati, SH., MKn Nomor: 03 Tanggal 17 September Tahun 2012 dan telah dirubah oleh Notaris yang sama dengan No.
                            05 tanggal 25 Maret 2015 dengan mendapat pengesahan sebagai Badan Hukum dari Menteri Hukum dan HAM RI no. AHU-0000525.AH01.07 tahun 2015 serta terdaftar pada KPP Pratama Jakarta Kebayoran Baru Dua dengan NPWP no.
                            72.528.271.4-019.000.
                          </em>
                        </div>
                      </div>
                      <div className="col-xl-4 d-none d-xl-block">
                        <Image src={imageHeroshot} className="img__heroshot-right" />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-xl-center">
                      <div className="pl-xl-3">
                        <div className="w-100 border-0 d-block d-md-flex align-items-center">
                          <h2 className="title__heroshot-atpi mr-3">LSP</h2>
                          <span className="subtitle__heroshot-atpi">
                            <Image src={logoApindo} width="100%" height="100%" />
                          </span>
                        </div>
                        <div className="w-100">
                          <p className="desc__heroshot-atpi mt-3 ">
                            PT. Lembaga Sertifikasi Profesi Manajemen Sumberdaya Manusia <b>APINDO JAYA</b> adalah Perusahaan yang bergerak dibidang <b>Sertifikasi BNSP</b> Profesi Manajemen Sumber daya Manusia dengan Skema Staff SDM,
                            Supervisor SDM, Manager SDM.
                          </p>
                        </div>
                        <div className="w-100">
                          <Button className="btn__heroshot-atpi">{typePage === "atpi" ? "Kunjungi Situs ATPI" : "Kunjungi Situs LSP APINDO"}</Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="parent__visi-misi">
        <VisiMisi typePage={typePage} />
      </div>
      {typePage === "atpi" && (
        <div className="parent__cta">
          <Cta />
        </div>
      )}
      {typePage === "atpi" && (
        <div className="parent__certificate">
          <CertificateAtpi />
        </div>
      )}
      {typePage === "atpi" ? (
        <div className="parent__certificate-profesional">
          <CertificateAtpiProf />
        </div>
      ) : (
        <div className="parent__certificate-profesional">
          <CertificateApindo />
        </div>
      )}
      <div className="parent__cta-footer">
        <CtaFooter typePage={typePage} />
      </div>
    </section>
  );
};

export default PageAtpi;
