import { satukelasApi } from "../constants";

export const AddressService = {
  getProvince() {
    return satukelasApi.get(`/orders/provinces`);
  },
  getCities(id){
    return satukelasApi.get(`orders/cities?province_id=${id}`);
  },
  getSubdistricts(id){
    return satukelasApi.get(`orders/subdistricts?city_id=${id}`);
  },
};
