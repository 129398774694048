export const WEBINARS_DATA_LINK = "webinars";
export const WEBINARS_DATA = "WEBINARS_DATA";
export const WEBINARS_DATA_SUCCESS = "WEBINARS_DATA_SUCCESS";
export const WEBINARS_DATA_ID_SUCCESS = "WEBINARS_DATA_ID_SUCCESS";
export const WEBINARS_DATA_FAIL = "WEBINARS_DATA_FAIL";

export const WEBINARS_TYPE_LENGTH_DATA = "WEBINARS_TYPE_LENGTH_DATA";
export const WEBINARS_TYPE_LENGTH_DATA_SUCCESS =
  "WEBINARS_TYPE_LENGTH_DATA_SUCCESS";
export const WEBINARS_TYPE_LENGTH_DATA_FAIL = "WEBINARS_TYPE_LENGTH_DATA_FAIL";

export const WEBINARS_CONSULTATION_LENGTH_DATA =
  "WEBINARS_CONSULTATION_LENGTH_DATA";
export const WEBINARS_CONSULTATION_LENGTH_DATA_SUCCESS =
  "WEBINARS_CONSULTATION_LENGTH_DATA_SUCCESS";
export const WEBINARS_CONSULTATION_LENGTH_DATA_FAIL =
  "WEBINARS_CONSULTATION_LENGTH_DATA_FAIL";
