import React from "react";
import { Container } from "react-bootstrap";
import Atpi from "./../../assets/images/home-certificationpage/brevet/atpi-example-main.png";
import AtpiC from "./../../assets/images/home-certificationpage/brevet/atpi-example-main-c.png";
import Atpi_2 from "./../../assets/images/home-certificationpage/brevet/atpi-example-second.png";
import Atpi_2_c from "./../../assets/images/home-certificationpage/brevet/atpi-example-second-c.png";
import { useParams } from "react-router-dom";
import { env } from "../../helper/constant";

const CertficateATPI = (props) => {
  const { subscribe } = props;
  const { co_id } = useParams();
  const imgCertifC = env === "development" ? "co34028467" : "co97744206";
  return (
    <section className="my-5">
      <Container className="ml-xl-5">
        <h4 className="title__certicate-atpi mb-3 mb-md-4">
          {subscribe ? (
            "Sertifikat Resmi dari ATPI"
          ) : (
            <>
              {co_id === imgCertifC ? (
                <>
                  Contoh Sertifikat Gelar Profesi Certified Professional Tax <br />
                  Technician dari ATPI
                </>
              ) : (
                <>
                  Contoh Sertifikat Gelar Profesi <em>Certified Tax Technician</em> ATPI
                </>
              )}
            </>
          )}
        </h4>
        <div className="col-xl-8">
          <div className="mb-3 mb-md-4 position-relative">
            <img className="w-100 h-100" src={co_id === imgCertifC ? AtpiC : Atpi} alt="" />
          </div>
          <div className="certificate__atpi-2">
            <img className="h-100 w-100" src={co_id === imgCertifC ? Atpi_2_c : Atpi_2} alt="" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CertficateATPI;
