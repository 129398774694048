import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
function QuizQuestion({
  questData,
  count,
  total,
  setCount,
  allAnswer,
  setAllAnswer,
  setDoneQuiz,
  minimizevalue,
  quizKey,
}) {
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  // const [bnsp, setBnsp] = useState({ is_bnsp: 2 });
  const users = useSelector((state) => state.users);
  const usersData = users.usersDataId;

  useEffect(() => {}, [usersData]);
  let lastQuestion = count >= total - 1;
  useEffect(() => {
    const myDiv = document.getElementById("containerDiv");
    myDiv.scrollTop = 0;
  }, [count]);

  useEffect(() => {
    if (allAnswer[count]) {
      setSelected(allAnswer[count].answer);
    }
  }, [count]);

  function onClickButton() {
    const answer = {
      answer: selected,
      question_id: questData.question_id,
    };
    const newAnswer = allAnswer;
    if (newAnswer[count]) {
      newAnswer[count] = answer;
    } else newAnswer.push(answer);

    setAllAnswer(newAnswer);

    if (typeof localStorage !== "undefined")
      localStorage.setItem(`_${quizKey}`, JSON.stringify(newAnswer));
    if (lastQuestion) {
      setDoneQuiz(true);
    } else {
      const newState = count + 1;
      setCount(newState);
      setSelected("");
    }
  }
  function onClickBackButton() {
    const newState = count - 1;
    setCount(newState);
  }
  function onClickAnswer(param) {
    setSelected(param);
  }
  return (
    <Col
      s={12}
      lg={12}
      className="quiz-quest-main-col d-flex flex-column justify-content-center align-items-start"
    >
      <Card.Text className="quiz-quest-no">
        {questData ? questData.number : ""}. {questData ? questData.title : ""}
      </Card.Text>
      <div className="quiz-quest-wrapper-scrollbar" id="containerDiv">
        <Card.Title className="quiz-quest-question">
          {questData ? (
            <div
              dangerouslySetInnerHTML={{
                __html: questData.question,
              }}
            />
          ) : (
            ""
          )}
        </Card.Title>
      </div>
      <Col className="quiz-quest-ans-col mb-5">
        <Row
          className={`quiz-quest-ans-row  ${
            selected == "A" ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("A")}
        >
          <div className="btn-quest">
            {selected == "A" ? <div className="btn-quest__selected"></div> : ""}
          </div>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_a : ""}
            </Card.Text>
          </div>
        </Row>
        <Row
          className={`quiz-quest-ans-row ${
            selected == "B" ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("B")}
        >
          <div className="btn-quest">
            {selected == "B" ? <div className="btn-quest__selected"></div> : ""}
          </div>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_b : ""}
            </Card.Text>
          </div>
        </Row>
        <Row
          className={`quiz-quest-ans-row ${
            selected == "C" ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("C")}
        >
          <div className="btn-quest">
            {selected == "C" ? <div className="btn-quest__selected"></div> : ""}
          </div>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_c : ""}
            </Card.Text>
          </div>
        </Row>
        <Row
          className={`quiz-quest-ans-row ${
            selected == "D" ? "quiz-quest-ans-row-selected" : null
          }`}
          onClick={() => onClickAnswer("D")}
        >
          <div className="btn-quest">
            {selected == "D" ? <div className="btn-quest__selected"></div> : ""}
          </div>
          <div className="quiz-quest-ans-wrapper quiz-quest-wrapper-scrollbar">
            <Card.Text className="quiz-quest-ans-text">
              {questData ? questData.choice_d : ""}
            </Card.Text>
          </div>
        </Row>
      </Col>
      <Row className="quiz-next-submit-btn d-flex flex-row justify-content-end">
        <Button
          className="quiz-prev__button"
          onClick={onClickBackButton}
          disabled={count <= 0}
        >
          Kembali
        </Button>
        <Button
          className="quiz-next__button"
          onClick={onClickButton}
          disabled={!selected}
        >
          {lastQuestion ? "Submit" : "Selanjutnya"}
        </Button>
      </Row>
    </Col>
  );
}
export default QuizQuestion;
