import {
  //post verify
  PAYMENTS_VERIFY_DATA_POST,
  PAYMENTS_VERIFY_DATA_POST_SUCCESS,
  PAYMENTS_VERIFY_DATA_POST_FAIL,
  //post
  PAYMENTS_DATA_POST,
  PAYMENTS_DATA_POST_SUCCESS,
  PAYMENTS_DATA_POST_FAIL,
  REMOVE_PAYMENTS_DATA,
} from "../config/PaymentsConfig";

//post verify
export const postPaymentsVerifyData = (value) => ({
  type: PAYMENTS_VERIFY_DATA_POST,
  value,
});
export const postPaymentsVerifyDataSuccess = (value) => ({
  type: PAYMENTS_VERIFY_DATA_POST_SUCCESS,
  value,
});
export const postPaymentsVerifyDataFail = (value) => ({
  type: PAYMENTS_VERIFY_DATA_POST_FAIL,
  value,
});

export const postPaymentsData = (value) => ({
  type: PAYMENTS_DATA_POST,
  value,
});
export const postPaymentsDataSuccess = (value) => ({
  type: PAYMENTS_DATA_POST_SUCCESS,
  value,
});
export const postPaymentsDataFail = (value) => ({
  type: PAYMENTS_DATA_POST_FAIL,
  value,
});
export const removePostPaymentsData = (value) => ({
  type: REMOVE_PAYMENTS_DATA,
  value,
});
