import {
  CERTIFICATES_DATA,
  CERTIFICATES_DATA_ID_SUCCESS,
  CERTIFICATES_DATA_FAIL,
} from "../config/CertificatesConfig";

const intialState = {
  certificatesDataId: [],
  isLoading: false,
};

export const CertificatesReducers = function (state = intialState, action) {
  switch (action.type) {
    case CERTIFICATES_DATA:
      return { ...state, isLoading: true };
    case CERTIFICATES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, certificatesDataId: action.value };
    case CERTIFICATES_DATA_FAIL:
      return { ...state, isLoading: false, certificatesDataId: "error" };
    default:
      return state;
  }
};
