/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import "../../assets/css/Account/index.css";
import { SkValidate } from "../../components/common/SkValidate";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { AuthService } from "../../http/AuthHttp";
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal);

function ForgotPassword() {
  const [data, setData] = useState({
    email: "",
  });
  const dispatch = useDispatch();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let param = `?email=${data?.email}`;
      try {
        const response = await AuthService.getResetPassword(param);
        if (response) {
          displayPopup(
            false,
            `Berhasil mengirimkan konfirmasi ke email`,
            true,
            "Oke"
          );
        }
      } catch (error) {
        displayPopup(false, `Gagal mengirim email`, true, "Oke");
      }
    }
    dispatch(loadingFinish());
  };

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const constraints = {
    email: {
      presence: true,
      email: true,
    },
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={10} sm={10} md={4} lg={5}>
          <div className="text-center">
            <div className="login-title">Lupa Password</div>
          </div>
          <div>
            <Card>
              <Form onSubmit={onSubmitHandler}>
                <Form.Group className="mb-5">
                  <div className="position-absolute p-3 d-flex"></div>
                  <Form.Control
                    className="mb-3 input100"
                    type="text"
                    placeholder="Masukkan Email Anda"
                    id="email"
                    name="email"
                    value={data.email}
                    onChange={onChangeHandler}
                  />
                  <div className="messages"></div>
                </Form.Group>

                <Button type="submit" className="w-100 login-button mb-3">
                  Kirim
                </Button>
                <h5
                  id="emailHelp"
                  className="form-text text-center login-register-text"
                >
                  <Link to="/login">
                    <a className="text-decoration-none color-primary">
                      Masuk ke akun anda
                    </a>
                  </Link>
                </h5>
              </Form>
            </Card>
            <Form></Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
