import Login from "../pages/Account/Login";
import Register from "../pages/Account/Register";
import EmailVerified from "../pages/Account/EmailVerified";
import ForgotPassword from "../pages/Account/ForgotPassword";
import NewPassword from "../pages/Account/NewPassword";
import SuccessRegistration from "../pages/Account/SuccessRegistration";
import CourseDetail from "../pages/Courses/CourseDetail";
import SinarmasCourse from "../pages/Sinarmas/SinarmasCourse";
import CourseFront from "../pages/Courses/CourseFront";
import WebinarSearch from "../pages/Courses/WebinarSearch";
import WebinarFront from "../pages/Courses/WebinarFront";
import CourseSearch from "../pages/Courses/CourseSearch";
import Checkout from "../pages/Transaction/Checkout";
import PaymentStatus from "../pages/Transaction/PaymentStatus";
import Membership from "../pages/Personal/Membership";
import MyCalendar from "../pages/Personal/MyCalendar";
import MyCert from "../pages/Personal/MyCert";
import MyCourse from "../pages/Personal/MyCourse";
import ScormScore from "../pages/Personal/ScormScore";
import MyTask from "../pages/Personal/MyTask";
import Profile from "../pages/Personal/Profile";
import Setting from "../pages/Personal/Setting";
import TutorialModal from "../pages/Tutorial/TutorialModal";
import NotificationPage from "../pages/Personal/NotificationPage";
import Verify from "../pages/Account/Verify";
import NotFound from "../pages/Error/NotFound";
import LearningPathSingle from "../pages/LearningPath/LearningPathSingle";
import BrevetLanding from "../pages/Home/BrevetLanding";
import BootcampLanding from "../pages/Home/BootcampLanding";
import MyCompanyProfil from "../pages/MyCompany/MyCompanyProfil";
// import MyBookmark from "../pages/Personal/MyBookmark";
import MembershipLanding from "../pages/Home/MembershipLanding";
import MembershipLandingKorporasi from "../pages/Home/MembershipLandingKorporasi";
import ContactUs from "../pages/Transaction/ContactUs";
import PrivacyPolice from "../pages/Static/PrivacyPolice";
import HandlerPage from "../pages/Handler/HandlerPage";
import HandleRedirect from "../pages/Handler/HandleRedirect";
import VerifyResetPassword from "../pages/Account/VerifyResetPassword";
import QRCode from "../pages/Courses/QRCode";
import Blog from "../pages/Static/Blog";
import BlogList from "../pages/Static/BlogList";
import MyWebinar from "../pages/Personal/MyWebinar";
import TermsCondition from "../pages/Static/TermsCondition";
import { getCoursesDataThunk } from "../redux/thunks/CoursesThunks";
import TaxReview from "../pages/Static/TaxReview";
import ATPI from "../components/ATPI";
import ATPIIjazah from "../components/ATPI/ijazah";
import Affiliation from "../pages/Static/Affiliation";
import DownloadCertificate from "../pages/Courses/DownloadCertificate";
import DigitalLearningCenter from "../pages/Static/DigitalLearningCenter";
import AboutUs from "../pages/Static/AboutUs";
import Bootcamp from "../pages/Static/Bootcamp";
import EnglishCoursePage from "../pages/Courses/EnglishCoursePage";
import BrevetPage from "../pages/BrevetPage/BrevetPage";
import ScormPage from "../pages/ScormPage/ScormPage";
import LearningPathSertificate from "../pages/LearningPath/LearningPathSertificate";
import Terbaru from "../pages/Terbaru";
import ZoomVerify from "../pages/ZoomVerify/ZoomVerify";
import Webstore from "../pages/Webstore";
import NewHomePage from "../pages/Home/NewHomePage";
import MiniBrevetPage from "../pages/Courses/MiniBrevet";
import PageCdlc from "../pages/CdlcPage";
import DlcsPage from "../pages/DlcsPage";
import BrevetShowPage from "../pages/Courses/BrevetShowPage";
import LpAtpi from "../pages/AtpiPage";
import LpApindo from "../pages/ApindoPage";
import MagangPage from "../pages/MagangPage";

const RouteData = [
  // {
  //   exact: true,
  //   path: ["/", "/home/"],
  //   isSidebar: false,
  //   isHeader: true,
  //   component: HomePage,
  // },
  {
    exact: true,
    path: ["/", "/home/"],
    isSidebar: false,
    isHeader: true,
    component: NewHomePage,
  },
  {
    exact: true,
    path: ["/indonesia-tax-review"],
    isSidebar: false,
    isHeader: true,
    component: TaxReview,
  },
  {
    exact: true,
    path: ["/dashboard/membership/"],
    isSidebar: true,
    isHeader: false,
    component: Membership,
  },
  {
    exact: true,
    path: ["/dashboard/calendar/"],
    isSidebar: true,
    isHeader: false,
    component: MyCalendar,
  },
  {
    exact: true,
    path: ["/dashboard/certificate/"],
    isSidebar: true,
    isHeader: false,
    component: MyCert,
  },
  {
    exact: true,
    path: ["/dashboard/learning-path/"],
    isSidebar: true,
    isHeader: false,
    component: LearningPathSertificate,
  },
  {
    exact: true,
    path: ["/dashboard/course/"],
    isSidebar: true,
    isHeader: false,
    component: MyCourse,
  },
  {
    exact: true,
    path: ["/terbaru"],
    isSidebar: false,
    isHeader: true,
    component: Terbaru,
  },
  {
    exact: true,
    path: ["/about-us/"],
    isSidebar: false,
    isHeader: true,
    component: AboutUs,
  },
  {
    exact: true,
    path: ["/dashboard/webinar/"],
    isSidebar: true,
    isHeader: false,
    component: MyWebinar,
  },
  // {
  //   exact: true,
  //   path: ["/dashboard/my-bookmark"],
  //   isSidebar: true,
  //   isHeader: false,
  //   component: MyBookmark,
  // },
  {
    exact: true,
    path: ["/dashboard/scorm-score"],
    isSidebar: true,
    isHeader: false,
    component: ScormScore,
  },
  {
    exact: true,
    path: ["/dashboard/task/"],
    isSidebar: true,
    isHeader: false,
    component: MyTask,
  },
  {
    exact: true,
    path: ["/dashboard/profile/"],
    isSidebar: true,
    isHeader: false,
    component: Profile,
  },
  {
    exact: true,
    path: ["/dashboard/settings/"],
    isSidebar: true,
    isHeader: false,
    component: Setting,
  },
  {
    exact: true,
    path: ["/dashboard/notification/"],
    isSidebar: true,
    isHeader: false,
    component: NotificationPage,
  },
  {
    exact: true,
    path: ["/dashboard/orders/"],
    isSidebar: true,
    isHeader: false,
    component: PaymentStatus,
  },
  // dashboard ends

  // static pages
  {
    exact: true,
    path: ["/tutorial/"],
    isSidebar: true,
    isHeader: false,
    component: TutorialModal,
  },
  {
    exact: true,
    path: ["/contact"],
    isSidebar: false,
    isHeader: true,
    component: ContactUs,
  },
  {
    exact: true,
    path: ["/blog/:slug/:id"],
    isSidebar: false,
    isHeader: true,
    component: Blog,
  },
  {
    exact: true,
    path: ["/blog"],
    isSidebar: false,
    isHeader: true,
    component: BlogList,
  },
  // {
  //   exact: true,
  //   path: ["/bootcamp"],
  //   isSidebar: false,
  //   isHeader: true,
  //   component: Bootcamp,
  // },
  {
    exact: true,
    path: ["/mitra"],
    isSidebar: false,
    isHeader: true,
    noFooter: true,
    component: Affiliation,
  },
  {
    exact: true,
    path: ["/corporate-digital-learning-center"],
    isSidebar: false,
    isHeader: true,
    noFooter: true,
    component: DigitalLearningCenter,
  },

  // auth routes start
  {
    exact: true,
    path: ["/login/"],
    isSidebar: false,
    isHeader: false,
    component: Login,
  },
  {
    exact: true,
    path: ["/register/"],
    isSidebar: false,
    isHeader: false,
    component: Register,
  },
  {
    // exact: true,
    path: ["/verify_reset_password/"],
    isSidebar: false,
    isHeader: false,
    component: VerifyResetPassword,
  },
  {
    exact: true,
    path: ["/email-verified/"],
    isSidebar: false,
    isHeader: false,
    component: EmailVerified,
  },
  {
    exact: true,
    path: ["/forgot-password/"],
    isSidebar: false,
    isHeader: false,
    component: ForgotPassword,
  },
  {
    exact: true,
    path: ["/new-password/"],
    isSidebar: false,
    isHeader: false,
    component: NewPassword,
  },
  {
    exact: true,
    path: ["/check-email/"],
    isSidebar: false,
    isHeader: false,
    component: SuccessRegistration,
  },
  // auth routes ends

  {
    exact: true,
    path: ["/search"],
    redirect: "/search/courses/",
    isSidebar: false,
    isHeader: false,
    component: {},
  },
  {
    exact: true,
    path: ["/preview/certificate/:id"],
    isSidebar: false,
    isHeader: true,
    component: QRCode,
  },
  {
    exact: true,
    path: ["/search/:data_type/", "/search/:data_type/:filter_param/"],
    isSidebar: false,
    isHeader: true,
    component: CourseSearch,
  },
  {
    exact: true,
    path: ["/download/:course_name/:id/"],
    isSidebar: false,
    isHeader: true,
    component: DownloadCertificate,
  },
  {
    exact: true,
    path: [
      "/webinar-list/:data_type",
      "/webinar-list/:data_type/:filter_param/",
    ],
    isSidebar: false,
    isHeader: true,
    component: WebinarSearch,
  },
  {
    exact: true,
    path: ["/webinar/:slug/:course_id"],
    isSidebar: false,
    isHeader: true,
    component: WebinarFront,
  },
  {
    exact: true,
    path: ["/consultation/:slug/:course_id"],
    isSidebar: false,
    isHeader: true,
    component: WebinarFront,
  },
  {
    exact: true,
    path: ["/course/:slug/:course_id/"],
    isSidebar: false,
    isHeader: true,
    component: CourseFront,
    dispatches: [getCoursesDataThunk],
    name: "course-front",
  },
  {
    exact: true,
    path: [
      "/course/:slug/:course_id/:material_type(lessons|webinars|assignments|quiz|scorms)/:slug_m/:id_material/",
    ],
    isSidebar: false,
    isHeader: true,
    component: CourseDetail,
  },
  {
    exact: true,
    path: [
      "/techconnect/:slug/:course_id/:material_type(lessons|webinars|assignments|quiz|scorms)/:slug_m/:id_material/",
    ],
    isSidebar: false,
    isHeader: false,
    component: SinarmasCourse,
  },

  {
    exact: true,
    path: ["/techconnect/:slug/:course_id/"],
    isSidebar: false,
    isHeader: false,
    component: CourseFront,
  },
  {
    exact: true,
    path: ["/sinarmas_login/"],
    isSidebar: false,
    isHeader: false,
    component: SinarmasCourse,
  },
  {
    exact: true,
    path: ["/learning-path/:path_name/"],
    isSidebar: false,
    isHeader: true,
    component: LearningPathSingle,
  },
  {
    exact: true,
    path: ["/certification/:slug/"],
    isSidebar: false,
    isHeader: true,
    component: BrevetLanding,
  },
  {
    exact: true,
    path: ["/webstore"],
    isSidebar: false,
    isHeader: true,
    component: Webstore,
  },
  {
    exact: true,
    path: ["/english-program"],
    isSidebar: false,
    isHeader: true,
    component: EnglishCoursePage,
  },
  {
    exact: true,
    path: ["/mini-brevet"],
    isSidebar: false,
    isHeader: true,
    component: MiniBrevetPage,
  },
  {
    exact: true,
    path: ["/brevet-courses"],
    isSidebar: false,
    isHeader: true,
    component: BrevetShowPage,
  },
  // {
  //   exact: true,
  //   path: ["/bootcamp/list"],
  //   isSidebar: false,
  //   isHeader: true,
  //   component: BootcampLanding,
  // },
  {
    exact: true,
    path: ["/term/"],
    isSidebar: false,
    isHeader: true,
    component: TermsCondition,
  },
  {
    exact: true,
    path: ["/privacy/"],
    isSidebar: false,
    isHeader: true,
    component: PrivacyPolice,
  },
  {
    exact: true,
    path: ["/membership/"],
    isSidebar: false,
    isHeader: true,
    noFooter: true,
    component: MembershipLanding,
  },

  {
    exact: true,
    path: ["/korporasi/"],
    isSidebar: false,
    isHeader: true,
    component: MembershipLandingKorporasi,
  },
  {
    exact: true,
    path: ["/company-profile/"],
    isSidebar: false,
    isHeader: true,
    component: MyCompanyProfil,
  },
  {
    exact: true,
    path: ["/checkout/"],
    isSidebar: true,
    isHeader: false,
    component: Checkout,
  },
  {
    // exact: true,
    path: ["/verify"],
    isSidebar: false,
    isHeader: false,
    component: Verify,
  },

  // atpi routes begin
  {
    exact: true,
    path: ["/atpi/:type(catt|ctt|cptt|cta)"],
    isSidebar: false,
    isHeader: true,
    component: ATPI,
  },
  {
    exact: true,
    path: ["/atpi/ijazah"],
    isSidebar: true,
    isHeader: true,
    component: ATPIIjazah,
  },
  // atpi routes end

  {
    exact: true,
    path: ["/handler/:source(task|attempt)"],
    isSidebar: false,
    isHeader: true,
    component: HandlerPage,
  },
  {
    exact: true,
    path: ["/handler/:auth/:destination"],
    isSidebar: false,
    isHeader: false,
    component: HandleRedirect,
  },
  {
    exact: true,
    path: ["/brevet/:title/:co_id"],
    isSidebar: false,
    isHeader: true,
    component: BrevetPage,
  },
  {
    exact: true,
    path: ["/:title/:lp_id"],
    isSidebar: false,
    isHeader: true,
    component: ScormPage,
  },
  {
    exact: true,
    path: ["/magang/:title/:co_id"],
    isSidebar: false,
    isHeader: true,
    component: MagangPage,
  },
  {
    exact: true,
    path: ["/zoomverify/verifyzoom"],
    isSidebar: false,
    isHeader: false,
    component: ZoomVerify,
  },
  {
    exact: true,
    path: ["/zoomverify/verifyzoom.html"],
    isSidebar: false,
    isHeader: false,
    component: ZoomVerify,
  },
  {
    exact: true,
    path: ["/zoomverify"],
    isSidebar: false,
    isHeader: false,
    component: ZoomVerify,
  },
  {
    exact: true,
    path: ["/verifyzoom"],
    isSidebar: false,
    isHeader: false,
    component: ZoomVerify,
  },
  {
    exact: true,
    path: ["/cdlc"],
    isSidebar: false,
    isHeader: true,
    component: PageCdlc,
  },
  {
    exact: true,
    path: ["/dlcs"],
    isSidebar: false,
    isHeader: true,
    component: DlcsPage,
  },
  {
    exact: true,
    path: ["/page-atpi"],
    isSidebar: false,
    isHeader: true,
    component: LpAtpi,
  },
  {
    exact: true,
    path: ["/page-apindo"],
    isSidebar: false,
    isHeader: true,
    component: LpApindo,
  },
  {
    exact: false,
    path: "*",
    isSidebar: false,
    isHeader: false,
    component: NotFound,
  },
];

export default RouteData;
