import React from "react";
import hero1 from "./../../assets/images/mitra/hero-1.png";
import tiktok from "./../../assets/images/mitra/tiktok.svg";
import instagram from "./../../assets/images/mitra/instagram.svg";
import linkedin from "./../../assets/images/mitra/linkedin.svg";
import gplay from "./../../assets/images/mitra/googleplay.png";

export default function FooterMitra() {
    return (
        <section className="mitra blue footer">
            <div className="container">
                <div className="hero-wrapper d-desktop">
                    <img className="lazyload" src={hero1} width="100%" alt="hero"/>
                </div>
                <div className="wording">
                    <div className="heading-light">
                        Kembangkan skill Anda dan buat penghasilan tambahan bersama
                        satukelas sekarang!
                    </div>
                    <a href="#" className="cta">
                        Konsultasi GRATIS
                    </a>

                    <div className="socials">
                        <div className="socmed-container">
                            <div className="p-md light followus">Ikuti Kami</div>
                            <a href="#" className="socmed-wrapper">
                                <img className="lazyload" src={linkedin} alt="social"/>
                            </a>
                            <a href="#" className="socmed-wrapper">
                                <img className="lazyload" src={tiktok} alt="social"/>
                            </a>
                            <a href="#" className="socmed-wrapper">
                                <img className="lazyload" src={instagram} alt="social"/>
                            </a>
                        </div>
                        <div className="gplay-wrapper">
                            <img className="lazyload" src={gplay} width="100%" alt="social"/>
                        </div>
                    </div>

                    <p className="copyright">
                        {" "}
                        2021 © satukelas by PT. satukelas Adhyapana Nusantara. All Rights
                        Reserved.
                    </p>
                </div>
            </div>
        </section>
    )
}