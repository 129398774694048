import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import { clients } from "./Data";

const Client = (props) => {
  const { page, col } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function renderClients() {
    let { Splide, SplideSlide } = require("@splidejs/react-splide");
    return (
      <Splide
        className="align-items-center"
        options={{
          rewind: true,
          perPage: 4,
          perMove: 4,
          gap: "1.5rem",
          pagination: true,
          arrows: true,
          breakpoints: {
            992: {
              perPage: 3,
            },
            768: {
              perPage: 2,
            },
            500: {
              perPage: 1,
            },
          },
        }}>
        {clients?.map((value, index) => (
          <SplideSlide key={index}>
            <Row>
              <Col className="d-block">
                <img alt="Profile User" className="img__client lazyload " src={value.image} />
              </Col>
            </Row>
          </SplideSlide>
        ))}
      </Splide>
    );
  }

  return (
    <section className="position-relative pb-5 w-100">
      <div className="container ml-xl-5">
        <Col xl={col}>
          {page === "courses" ? <h4 className="fw-700 mb-4">Perusahaan Yang Mengikuti Pelatihan di Satukelas</h4> : null}
          {loaded ? <>{renderClients()}</> : null}
        </Col>
      </div>
    </section>
  );
};

export default Client;
