import React, { useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import "../../assets/css/Account/index.css";
import { SkValidate } from "../../components/common/SkValidate";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { loadingFinish, loadingStart } from "../../redux/reducers/CommonReducers";
import { AuthService } from "../../http/AuthHttp";
const MySwal = withReactContent(Swal);

function EmailVerified() {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: "",
  });
  const [complete, setComplete] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      try {
        const response = await AuthService.getVerification(`?email=${data.email}`);
        if (response) {
          setComplete(true);
          displayPopup(false, "Successfully sent the email", "Oke")
        }
      } catch (error) {
        displayPopup(
          false,
          "Gagal memproses. Silahkan coba lagi setelah beberapa saat!",
          "Oke"
        );
      }
    }
    dispatch(loadingFinish());
  };

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === "price" ? parseInt(e.target.value) : e.target.value;
    setData(newData);
  };
  const constraints = {
    email: {
      presence: true,
      email: true,
    },
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => { });
  }

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={10} sm={10} md={5} lg={5}>
          {complete ? (
            <>
              <div className="text-center">
                <div className="login-title">Berhasil Mengirim Ulang Email</div>

              </div>
              <ul id="success">
                <li>Cek email anda untuk tautan aktivasi</li>
                <li>Masuk satukelas.com dengan email dan password anda</li>
                <li>Mulai Belajar</li>
              </ul>
            </>
          ) : (
            <>
              <div className="text-center">
                <div className="login-title">Verifikasi Email</div>
              </div>
              <div>
                <Card className="p-5">
                  <Form onSubmit={onSubmitHandler}>
                    <Form.Group className="mb-5">
                      <div className="position-absolute p-3 d-flex">
                        {/* <FaUserAlt /> */}
                      </div>
                      <Form.Control
                        className="mb-3 input100"
                        type="text"
                        placeholder="Masukkan Email Anda"
                        id="email"
                        name="email"
                        value={data.email}
                        onChange={onChangeHandler}
                      />
                      <div className="messages"></div>
                    </Form.Group>
                    <Button type="submit" className="w-100 login-button mb-0">
                      <div className="login-text">Verifikasi</div>
                    </Button>
                  </Form>
                </Card>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default EmailVerified;
