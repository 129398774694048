import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Accordion, Alert, Button, Card } from "react-bootstrap";
import check from "./../../assets/images/check.png";
import {
  SanitizeMaterialTypeId,
  slugify,
  convertHours,
  displayPopup,
} from "../../helper/functions";
import playerunact from "./../../assets/images/player-unactive.png";
import playeract from "./../../assets/images/player-active.png";
import editunact from "./../../assets/images/edit-unactive.png";
import editact from "./../../assets/images/edit-active.png";
import lockBlack from "./../../assets/images/lock-black.png";
import lockGray from "./../../assets/images/lock-gray.png";
import styled from "styled-components";
import "../../assets/css/Courses/AccordionCourse.css";
import { AuthIsLogin } from "../../helper/auth";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
const MySwal = withReactContent(Swal);

function AccordionCourse({
  id,
  topics,
  courseId,
  slug,
  selectedIndex,
  detail,
  unlock,
  continueCourse,
  handleLogin,
  enrollCourse,
  enrolled,
}) {
  const [idx, setIdx] = useState([]);
  const [showMaterial, setShowMaterial] = useState(true);
  const history = useHistory();
  const address = useLocation();

  useEffect(() => {
    if (id) {
      setShowMaterial(true);
    }
  }, []);

  const handleOnClick = useCallback(
    (param, enrolled) => {
      if (history.location.pathname.includes("/techconnect/")) {
        history.push(`/techconnect/${slug}/${courseId}/${param}`);
      } else {
        if (AuthIsLogin("", true)) {
          if (enrolled) {
            if (continueCourse) {
              continueCourse();
            } else {
              history.push(`/course/${slug}/${courseId}/${param}`);
            }
          } else {
            displayPopup(false, "Gagal Memulai Course.", true, "Ok");
            // enrollCourse();
          }
        } else {
          handleLogin();
        }
      }
    },
    [history, slug, courseId]
  );
  const handleShow = (index) => {
    if (idx.includes(index)) setIdx(idx.filter((i) => i !== index));
    else setIdx([...idx, index]);
  };
  const handleShowMaterial = () => {
    setShowMaterial(!showMaterial);
  };
  return (
    <StyledWrapperMain>
      {id && (
        <>
          <div className="course_front__kurikulum_title__accordion">
            Navigasi Konten
          </div>
        </>
      )}

      <div
        className={
          id
            ? "course_front__kurikulum_wrapper__accordion_detail"
            : "course_front__kurikulum_wrapper__accordion"
        }
      >
        <Card>
          {!id && (
            <div className="px-4">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={handleShowMaterial}
              >
                <div className="course_front__kurikulum_title  pt-3">
                  Materi Pelatihan
                </div>
                <div>
                  {showMaterial ? (
                    <span className="icon-chevronup"></span>
                  ) : (
                    <span className="icon-chevrondown"></span>
                  )}
                </div>
              </div>
            </div>
          )}

          {showMaterial ? (
            <>
              {topics &&
                topics.map((topic, index) => (
                  <Accordion
                    alwaysOpen
                    key={`${index}`}
                    defaultActiveKey={`${index}`}
                    className="course_front__kurikulum_wrapper"
                  >
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={`${index}`}
                      className="course_front__kurikulum_wrapper__title"
                      onClick={() => handleShow(index)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="course_front__kurikulum__title">
                          {topic.title}
                        </div>
                        <span className="float-right">
                          {idx.includes(index) ? (
                            <span className="icon-chevronup"></span>
                          ) : (
                            //
                            <span className="icon-chevrondown"></span>
                          )}
                        </span>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <div className="course_front__material_wrapper">
                        {topic.materials.map((material, indexL) => {
                          const sanitizedMaterial =
                            SanitizeMaterialTypeId(material);
                          const checked = material.completed;
                          return (
                            <div
                              key={`material_${index}${indexL}`}
                              className={`accordion-course-main-div d-flex justify-content-between cursor-pointer ${
                                parseInt(selectedIndex) ===
                                parseInt(`${index}${indexL}`)
                                  ? "accordion-course-main-div-active"
                                  : "accordion-course-main-div"
                              }`}
                              onClick={() => {
                                handleOnClick(
                                  `${sanitizedMaterial.type}/${slugify(
                                    sanitizedMaterial.title
                                  )}/${
                                    sanitizedMaterial.id
                                  }_${index}_${indexL}`,
                                  enrolled
                                );
                              }}
                            >
                              <div className="w-100">
                                <div className="d-flex align-items-center">
                                  <div className="mr-2">
                                    {material?.type !== "quizzes" ? (
                                      parseInt(selectedIndex) ===
                                      parseInt(`${index}${indexL}`) ? (
                                        <img
                                          alt="playeract"
                                          src={playeract}
                                          id="active"
                                        />
                                      ) : (
                                        <img
                                          alt="playerunact"
                                          src={playerunact}
                                          id="unactive"
                                        />
                                      )
                                    ) : parseInt(selectedIndex) ===
                                      parseInt(`${index}${indexL}`) ? (
                                      <img
                                        alt="editact"
                                        src={editact}
                                        id="active"
                                      />
                                    ) : (
                                      <img
                                        alt="editunact"
                                        src={editunact}
                                        id="unactive"
                                      />
                                    )}
                                  </div>
                                  <div
                                    className={`course_front__material_title ${
                                      detail
                                        ? "max-width__detail"
                                        : "max-width__accordion"
                                    }`}
                                  >
                                    {material.title}
                                  </div>
                                </div>

                                {/* <div className="course_front__duration">
                                  {material?.duration === 0
                                    ? "00:00"
                                    : convertHours(
                                        material?.duration,
                                        "detail"
                                      )}
                                </div> */}
                              </div>
                              {checked === 1 && (
                                <div className="box p-1 rounded text-center font-weight-bold white-font">
                                  <img alt="check" src={check} />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                ))}
            </>
          ) : (
            ""
          )}
        </Card>
      </div>
    </StyledWrapperMain>
  );
}
export default AccordionCourse;

const StyledWrapperMain = styled.section`
  .btn-circle {
    display: none;
  }
`;
