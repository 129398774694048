import { satukelasApi } from "../constants";

export const MembershipService = {
  getMembershipCatalogue() {
    return satukelasApi.get(`/membership_catalogue?size=50`);
  },
  addMembershipToUser(payload) {
    return satukelasApi.post(`/memberships`, payload);
  }
};
