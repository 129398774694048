import { PromiseService } from "../../services/PromiseService";
import {
  USERS_DATA_LINK,
  USERS_DATA_LINK_V2,
  USERS_STUDENT_DATA_LINK,
  USERS_GET_INSTRUCTOR_LINK,
  USERS_VERIFY_TOKEN_LINK,
  USERS_RESEND_VERIFICATION_LINK,
  USERS_CHANGE_PASSWORD_LINK,
  FORGOT_PASSWORD_LINK,
  RESET_PASSWORD_LINK,
} from "../config/UsersConfig";
import {
  //get
  getUsersData,
  getUsersDataSuccess,
  getUsersDataIdSuccess,
  getUsersDataFail,
  //post
  postUsersData,
  postUsersDataSuccess,
  postUsersDataFail,
  //put
  putUsersData,
  putUsersDataSuccess,
  putUsersDataFail,
  //get instructor
  getInstructorData,
  getInstructorDataSuccess,
  getInstructorDataFail,
  //verify token
  verifyToken,
  verifyTokenSuccess,
  verifyTokenFail,
  //resend verification
  resendVerification,
  resendVerificationSuccess,
  resendVerificationFail,
  // change password
  changePassword,
  changePasswordSuccess,
  changePasswordFail,
  // forgot password
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFail,
  // reset password
  verifyPasswordToken,
  verifyPasswordTokenSuccess,
  verifyPasswordTokenFail,
  // new password
  newPasswordAccount,
  newPasswordAccountSuccess,
  newPasswordAccountFail,
} from "../actions/UsersActions";

//get
export const getUsersDataThunk = (data) => async (dispatch) => {
  dispatch(getUsersData());
  if (data) {
    try {
      const response = await PromiseService.getData(
        `${USERS_DATA_LINK_V2}/${data}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getUsersDataIdSuccess(response.data));
      } else {
        dispatch(getUsersDataFail(response));
      }
    } catch (error) {
      dispatch(getUsersDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(USERS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getUsersDataSuccess(response.data));
      } else {
        dispatch(getUsersDataFail(response));
      }
    } catch (error) {
      dispatch(getUsersDataFail(error));
    }
  }
};

// get V2
export const getUsersDataThunkV2 = (data) => async (dispatch) => {
  dispatch(getUsersData());
  if (data) {
    try {
      const response = await PromiseService.getData(
        `${USERS_DATA_LINK_V2}/${data}`
      );
      if (response.status === 200 && response.data) {
        dispatch(getUsersDataIdSuccess(response.data));
      } else {
        dispatch(getUsersDataFail(response));
      }
    } catch (error) {
      dispatch(getUsersDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(USERS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getUsersDataSuccess(response.data));
      } else {
        dispatch(getUsersDataFail(response));
      }
    } catch (error) {
      dispatch(getUsersDataFail(error));
    }
  }
};

//post
export const postUsersDataThunk = (data) => async (dispatch) => {
  dispatch(postUsersData());
  const body = data;
  try {
    const response = await PromiseService.postData(
      USERS_STUDENT_DATA_LINK,
      body,
      true
    );
    if (response.status === 201 && response.data) {
      dispatch(postUsersDataSuccess(response.data));
    } else {
      dispatch(postUsersDataFail(response));
    }
  } catch (error) {
    dispatch(postUsersDataFail(error));
  }
};

// put
export const putUsersDataThunk = (data) => async (dispatch) => {
  dispatch(putUsersData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${USERS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putUsersDataSuccess(response.data));
    } else {
      dispatch(putUsersDataFail(response));
    }
  } catch (error) {
    dispatch(putUsersDataFail(error));
  }
};

//get instructor
export const getInstructorDataThunk = (data) => async (dispatch) => {
  dispatch(getInstructorData());
  try {
    const response = await PromiseService.getData(USERS_GET_INSTRUCTOR_LINK);
    if (response.status === 200 && response.data) {
      dispatch(getInstructorDataSuccess(response.data));
    } else {
      dispatch(getInstructorDataFail(response));
    }
  } catch (error) {
    dispatch(getInstructorDataFail(error));
  }
};

export const verifyTokenThunk = (data) => async (dispatch) => {
  dispatch(verifyToken(data));
  try {
    const response = await PromiseService.getData(
      `${USERS_VERIFY_TOKEN_LINK}?token=${data}`
    );
    if (response.status === 200 && response.data) {
      dispatch(verifyTokenSuccess(response.data));
    } else {
      dispatch(verifyTokenFail(response));
    }
  } catch (error) {
    dispatch(verifyTokenFail(error));
  }
};

export const resendVerificationThunk = (data) => async (dispatch) => {
  dispatch(resendVerification(data));
  try {
    const response = await PromiseService.getData(
      `${USERS_RESEND_VERIFICATION_LINK}?email=${data}`
    );
    if (response.status === 200 && response.data) {
      dispatch(resendVerificationSuccess(response.data));
    } else {
      dispatch(resendVerificationFail(response));
    }
  } catch (error) {
    dispatch(resendVerificationFail(error));
  }
};

// change password
export const changePasswordThunk = (data) => async (dispatch) => {
  dispatch(changePassword(data));
  try {
    const response = await PromiseService.postData(
      USERS_CHANGE_PASSWORD_LINK,
      data
    );
    if (response.status === 200 && response.data) {
      dispatch(changePasswordSuccess(response.data));
    } else {
      dispatch(changePasswordFail(response));
    }
  } catch (error) {
    dispatch(changePasswordFail(error));
  }
};

// change password
export const forgotPasswordThunk = (data) => async (dispatch) => {
  dispatch(forgotPassword(data.param));
  try {
    const response = await PromiseService.getData(
      `${FORGOT_PASSWORD_LINK}?email=${data.param}`
    );
    if (response.status === 200 && response.data) {
      dispatch(forgotPasswordSuccess(response.data));
    } else {
      dispatch(forgotPasswordFail(response));
    }
  } catch (error) {
    dispatch(forgotPasswordFail(error));
  }
};

// verify reset
export const verifyPasswordThunk = (data) => async (dispatch) => {
  dispatch(verifyPasswordToken(data));
  try {
    const response = await PromiseService.getData(
      `${USERS_VERIFY_TOKEN_LINK}?token=${data}`
    );
    if (response.status === 200 && response.data) {
      dispatch(verifyPasswordTokenSuccess(response.data));
    } else {
      dispatch(verifyPasswordTokenFail(response));
    }
  } catch (error) {
    dispatch(verifyPasswordTokenFail(error));
  }
};

// new password
export const newPasswordAccountThunk = (data) => async (dispatch) => {
  dispatch(newPasswordAccount(data));
  try {
    const response = await PromiseService.postData(RESET_PASSWORD_LINK, data);
    if (response.status === 200 && response.data) {
      dispatch(newPasswordAccountSuccess(response.data));
    } else {
      dispatch(newPasswordAccountFail(response));
    }
  } catch (error) {
    dispatch(newPasswordAccountFail(error));
  }
};
