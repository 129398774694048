import React, { useEffect, useState } from "react";
import "../../assets/css/Courses/zoomDisplay.css";
import RatingCourse from "../RatingCourse";
import moment from "moment";
import { FeedbackService } from "../../http/FeedbackHttp";
const defaultSize = 5;
function WhatPeopleSay({ courseId }) {
  const [size, setSize] = useState(defaultSize);
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    getFeedbacks();
  }, []);

  useEffect(() => {
    if (size > defaultSize) {
      getFeedbacks();
    }
  }, [size]);

  const getFeedbacks = async () => {
    try {
      const payload = {
        id: courseId,
        size: size,
      };
      const response = await FeedbackService.getFeedback(payload);
      setFeedbacks(response);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div>
      <div className="title-what__people mb-3">Apa kata mereka</div>
      <div className="mb-5">
        {feedbacks?.items && feedbacks?.items?.length > 0 ? (
          feedbacks?.items?.map((value, index) => {
            return (
              <div key={index} className="container-what__people mb-4">
                <div className="d-flex mb-3">
                  <div className="mr-3 img-what__people"></div>
                  <div>
                    <div className="name-what__people">{value.name}</div>
                    <div>
                      {moment(value.created_at).format("MMMM DD, YYYY")}
                    </div>
                  </div>
                </div>
                <RatingCourse item={value.score_satisfaction} detail={true} />
                <div className="desc-what__people">
                  {value.review_suggestion}
                </div>
              </div>
            );
          })
        ) : (
          <p>belum ada review</p>
        )}
        {feedbacks?.pagination?.totalPages > 1 ? (
          <p
            className="load-more"
            onClick={() => {
              setSize(size + defaultSize);
            }}
          >
            Lebih Banyak...
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default WhatPeopleSay;
