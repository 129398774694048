import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import iconExit from "./../../assets/images/icon-exit.svg";
import iconCompany from "./../../assets/images/icon-company.svg";
import iconSettings from "./../../assets/images/icon-settings.svg";
import iconItr from "./../../assets/images/icon-books-itr.png";
import iconHoverProfile from "./../../assets/images/icon-profile-hover/profile-hover.svg";
import iconCompanyHover from "./../../assets/images/icon-profile-hover/company-hover.svg";
import iconExitProfile from "./../../assets/images/icon-profile-hover/exit-hover.svg";
import iconFeedbackProfile from "./../../assets/images/icon-profile-hover/feedback-hover.svg";
import iconSettingProfile from "./../../assets/images/icon-profile-hover/setting-hover.svg";
import iconProfleSvg from "./../../assets/images/icon-profile-svg.svg";
import iconProfile from "./../../assets/images/iconProfile.svg";
import send from "./../../assets/images/icon-feedback.svg";
import iconProfileBlue from "./../../assets/images/iconProfileBlue.svg";
export default function ProfileMenu(props) {
  const {
    handleMouseHover,
    handleMouseProfile,
    handleLeaveMouseProfile,
    iconDropdown,
    hoverIcon,
    handleLogoutClick,
  } = props;
  return (
    <li>
      <div>
        {/* {headerData?.user?.avatar === "" ? ( */}
        <div className="container-user">
          <span
            onMouseEnter={() => handleMouseHover("profile")}
            onMouseLeave={handleMouseHover}
          >
            <Image
              src={hoverIcon === "profile" ? iconProfileBlue : iconProfile}
              width={38}
              height={38}
              alt="profile"
            />
          </span>
        </div>
        <div className="menu-dropdown menu-dropdown-profile">
          <Link
            className="dropdown-item"
            to="/dashboard/profile/"
            onMouseEnter={() => handleMouseProfile("profile")}
            onMouseLeave={handleLeaveMouseProfile}
            style={iconDropdown === "profile" ? { color: "#306CCC" } : {}}
          >
            <img
              data-src={
                iconDropdown === "profile" ? iconHoverProfile : iconProfleSvg
              }
              className="lazyload mr-2"
              width="21px"
              height="31px"
              alt="profil"
            />
            Profil
          </Link>
          <Link className="dropdown-item" to="/indonesia-tax-review">
            <img
              data-src={iconItr}
              className="lazyload mr-2"
              width="25px"
              height="25px"
              style={{ opacity: "0.8" }}
              alt="itr"
            />
            Majalah ITR
          </Link>
          {/* {(headerData?.user?.diploma?.diploma_id_sma || headerData?.user?.diploma?.diploma_id_s1) && (
                          <Link className="dropdown-item" to="/atpi/ijazah/">
                            <span className="icon-file mr-2"></span>
                            Ijazah
                          </Link>
                        )} */}
          <Link
            className="dropdown-item"
            to="/company-profile"
            onMouseEnter={() => handleMouseProfile("company")}
            onMouseLeave={handleLeaveMouseProfile}
            style={iconDropdown === "company" ? { color: "#306CCC" } : {}}
          >
            <img
              data-src={
                iconDropdown === "company" ? iconCompanyHover : iconCompany
              }
              className="lazyload mr-2"
              width="25.143px"
              height="19.556px;"
              alt="company"
            />
            Perusahaan Saya
          </Link>
          <Link
            className="dropdown-item"
            to="/dashboard/settings/"
            onMouseEnter={() => handleMouseProfile("setting")}
            onMouseLeave={handleLeaveMouseProfile}
            style={iconDropdown === "setting" ? { color: "#306CCC" } : {}}
          >
            <img
              data-src={
                iconDropdown === "setting" ? iconSettingProfile : iconSettings
              }
              className="lazyload mr-2"
              width="25.143px"
              height="19.556px;"
              alt="setting"
            />
            Pengaturan
          </Link>
          <a
            className="dropdown-item"
            href="https://form.asana.com/?k=gQs4Q3qV2qipkvrpbNOhOA&d=1200494741159113"
            target="_blank"
            rel=" noreferrer"
            onMouseEnter={() => handleMouseProfile("feedback")}
            onMouseLeave={handleLeaveMouseProfile}
            style={iconDropdown === "feedback" ? { color: "#306CCC" } : {}}
          >
            <img
              data-src={
                iconDropdown === "feedback" ? iconFeedbackProfile : send
              }
              alt="send"
              className="lazyload mr-2"
              width="25.143px"
              height="19.556px;"
            />
            Kirim Feedback
          </a>
          <div
            className="dropdown-item logout-text"
            onClick={() => handleLogoutClick()}
            onMouseEnter={() => handleMouseProfile("exit")}
            onMouseLeave={handleLeaveMouseProfile}
            style={iconDropdown === "exit" ? { color: "#306CCC" } : {}}
          >
            <img
              data-src={iconDropdown === "exit" ? iconExitProfile : iconExit}
              className="lazyload mr-2"
              width="25.143px"
              height="19.556px;"
              alt="exit"
            />
            Keluar
          </div>
        </div>
      </div>
    </li>
  );
}
