import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import BreadcrumbPage from "../../components/DashboardComponent/BreadcrumbPage";
import MembershipList from "../../components/Membership/MembershipList";
import Info from "../../components/common/Info";

function Membership() {
  const history = useHistory();
  const [detailModal, setDetailModal] = useState(false);
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  const seeMoreInformation = () => {
    setDetailModal(!detailModal);
  };
  return (
    <>
      {/* <Info
        detailModal={detailModal}
        setDetailModal={setDetailModal}
        seeMoreInformation={seeMoreInformation}
      /> */}
      <Container className="dashboard_page__container">
        <BreadcrumbPage title="Membership" />
        <div className="dashboard__title_wrapper mb-3">
          <h2 className="dashboard__section_title h2">Membership</h2>
        </div>
        <Row>
          <MembershipList />
        </Row>
      </Container>
    </>
  );
}

export default Membership;
