import React from "react";
import { Row, Col, Container } from "react-bootstrap";
function TermsCondition() {
  return (
    <Container>
      <Row>
        <Col lg={12} className="py-5">
          <h3 className="h3 ">
            SYARAT DAN KETENTUAN PENGGUNAAN PLATFORM SATUKELAS
          </h3>
          <div>
            Syarat dan Ketentuan ini merupakan perjanjian antara pengguna ("
            <b>Anda</b>") dan PT Satukelas Adhyapana Nusantara ("<b>Kami</b>"),
            yaitu sebuah perseroan terbatas yang didirikan dan beroperasi secara
            sah berdasarkan hukum Negara Republik Indonesia dan berdomisili di
            DKI Jakarta, Indonesia. Syarat dan Ketentuan ini mengatur Anda saat
            mengakses dan menggunakan aplikasi, situs web (www.satukelas.com)
            dan situs web lain yang Kami kelola), fitur, teknologi, konten dan
            produk yang Kami sediakan (selanjutnya, secara Bersama-sama disebut
            sebagai "<b>Platform</b>").
            <br />
            <br />
            <b>
              Harap membaca Syarat dan Ketentuan ini secara seksama sebelum Anda
              mulai menggunakan Platform Kami, karena peraturan ini berlaku pada
              penggunaan Anda terhadap Platform Kami.
            </b>
            Syarat dan Ketentuan ini mencakup hal-hal sebagai berikut:
            <ol type="A">
              <li>Pembukaan Akun Anda</li>
              <li>Informasi Pribadi Anda</li>
              <li>Akses terhadap Platform Kami</li>
              <li>Pembelian dan Hak Akses terhadap Kelas pada Platform</li>
              <li>Sertifikat</li>
              <li>Ketentuan Pembayaran</li>
              <li>Penggunaan yang Dilarang</li>
              <li>Hak Kekayaan Intelektual</li>
              <li>Pengunggahan Konten Pada Platform Kami</li>
              <li>Laporan Kemungkinan Pelanggaran</li>
              <li>Tindakan yang Kami Anggap Perlu</li>
              <li>Membuat Tautan (Link) ke Platform Kami</li>
              <li>Tautan Pihak Ketiga Pada Platform Kami</li>
              <li>Tanggung Jawab Anda</li>
              <li>Batasan Tanggung Jawab Kami</li>
              <li>Keadaan Kahar (Force Majeure)</li>
              <li>Hukum yang Berlaku</li>
              <li>Ketentuan Lainnya</li>
              <li>Cara Menghubungi Kami</li>
            </ol>
            Anda harus berusia minimal 18 (delapan belas) tahun atau sudah
            menikah dan tidak berada di bawah perwalian atau pengampuan agar
            Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan
            diri pada Syarat dan Ketentuan ini. Jika Anda tidak memenuhi
            ketentuan tersebut,{" "}
            <b>
              maka tindakan Anda mendaftar, mengakses, menggunakan atau
              melakukan aktivitas lain dalam Platform Kami harus dalam
              sepengetahuan, pengawasan dan persetujuan yang sah dari orang tua
              atau wali atau pengampu Anda.
            </b>{" "}
            Orang tua, wali atau pengampu yang memberikan persetujuan bagi Anda
            yang berusia di bawah 18 (delapan belas) tahun bertanggung jawab
            secara penuh atas seluruh tindakan Anda dalam penggunaan dan akses
            terhadap Platform.
            <br />
            <br />
            Dengan mendaftar dan/atau menggunakan Platform Kami, maka Anda
            dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia di
            bawah 18 tahun) dianggap telah membaca, mengerti, memahami dan
            menyetujui semua isi dalam Syarat dan Ketentuan ini.
            <br />
            <br />
            Dengan menyetujui Syarat dan Ketentuan ini, Anda juga menyetujui
            Syarat dan Ketentuan tambahan dan perubahannya yang merupakan bagian
            yang tidak terpisahkan dari Syarat dan Ketentuan ini (selanjutnya,
            Syarat dan Ketentuan, Syarat dan Ketentuan tambahan, dan
            perubahannya secara bersama-sama disebut sebagai "
            <b>Syarat dan Ketentuan</b>"). Meskipun merupakan satu kesatuan,
            Syarat dan Ketentuan tambahan akan berlaku dalam hal terdapat
            perbedaan dengan Syarat dan Ketentuan.
            <br />
            <br />
            Jika Anda tidak menyetujui Syarat dan Ketentuan ini, Kami berhak
            untuk menghentikan akses dan penggunaan Anda terhadap Platform Kami.
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default TermsCondition;
