import React from "react";
import LpCdlc from "../../components/LpCdlc";

const DlcsPage = () => {
  return (
    <div>
      <LpCdlc typePage="dlcs" />
    </div>
  );
};

export default DlcsPage;
